import format from "string-format";

import { baseApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "redux/org/org.const";

class OrgService {
    static orgService = new OrgService();

    getCurrentOrgDetail = async () => {
        const response = await baseApiInstance().get(
            url.GET_CURRENT_ORG_DETAIL
        )

        return response;
    }

    getOrgThemeDetail = async ({ params }) => {
        const response = await baseApiInstance().get(
            format(url.GET_ORG_THEME_DETAIL, params)
        )

        return response;
    }
}

export default OrgService.orgService;