import { createSlice } from "@reduxjs/toolkit";

import { ORG_INITIAL_STATE } from "./org.initialState";

const org = createSlice({
    name: "org",
    initialState: ORG_INITIAL_STATE,
    reducers: {
        setPublicOrgDetailLoading: (state, { payload }) => {
            state.publicOrgDetail.isLoading = payload
        },
        setPublicOrgDetailData: (state, { payload }) => {
            state.publicOrgDetail.data = payload
            state.publicOrgDetail.message = ORG_INITIAL_STATE.publicOrgDetail.message
        },
        setPublicOrgDetailMessage: (state, { payload }) => {
            state.publicOrgDetail.message = payload
            state.publicOrgDetail.data = ORG_INITIAL_STATE.publicOrgDetail.data
        },
        resetPublicOrgDetail: (state) => {
            state.publicOrgDetail = ORG_INITIAL_STATE.publicOrgDetail
        },

        // org theme slice
        setOrgThemeDetailLoading: (state, { payload }) => {
            state.orgThemeDetail.isLoading = payload
        },
        setOrgThemeDetailData: (state, { payload }) => {
            state.orgThemeDetail.data = payload
        },
        setOrgThemeDetailMessage: (state, { payload }) => {
            state.orgThemeDetail.message = payload
            state.orgThemeDetail.data = ORG_INITIAL_STATE.orgThemeDetail.data
        },
        resetOrgThemeDetail: (state) => {
            state.orgThemeDetail = ORG_INITIAL_STATE.orgThemeDetail
        },

        // org domain slice
        setOrgDomainDetailLoading: (state, { payload }) => {
            state.orgDomainDetail.isLoading = payload
        },
        setOrgDomainDetailData: (state, { payload }) => {
            state.orgDomainDetail.data = payload
        },
        resetOrgDomainDetail: (state) => {
            state.orgDomainDetail = ORG_INITIAL_STATE.orgDomainDetail
        },
    }
})

export const {
    setPublicOrgDetailLoading,
    setPublicOrgDetailData,
    setPublicOrgDetailMessage,
    resetPublicOrgDetail,

    setOrgThemeDetailLoading,
    setOrgThemeDetailData,
    setOrgThemeDetailMessage,
    resetOrgThemeDetail,

    setOrgDomainDetailLoading,
    setOrgDomainDetailData,
    resetOrgDomainDetail,
} = org.actions;

export default org.reducer;