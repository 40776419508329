import { useAppState } from 'hooks/useStore';
import { orderItemStatusEnum } from 'redux/order/order.const';
import { productPriceModelEnum, productTypeEnum } from 'redux/product/product.const';

import { pagesInfo } from 'utils/pagesInfo';
import { currencyRateConversion, getCustomHostPath } from 'utils/generators.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const OrderItemDetailSection = ({ orderItemProductPrice }) => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { userOrderItemDetail } = useAppState((state) => state.order)

    const onHandleNavigateToProductDetail = () => {
        if ([productTypeEnum.LMS_COURSE.value]?.includes(orderItemProductPrice?.product_price?.product?.type)) {
            // window.open(`${EDULYTE_WEB_DOMAIN_URL}/course/${orderItemProductPrice?.product_price?.product?.lms_course?.active_slug}`)
            window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/course/${orderItemProductPrice?.product_price?.product?.lms_course?.active_slug}` })}`)
        } else {
            window.open(`${pagesInfo.COURSE.pagePath}/${orderItemProductPrice?.product_price?.product?.live_course?.active_slug}`)
        }
    }

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full flex items-start justify-start gap-5"}>
                <div className={"w-20 h-20 overflow-hidden rounded-md cursor-pointer"}
                    onClick={onHandleNavigateToProductDetail}
                >
                    <img
                        src={orderItemProductPrice?.product_price?.product?.thumbnail_pic_url}
                        className={"w-full h-full object-cover"}
                        alt={"order-item-thumbnail"}
                    />
                </div>
                <div className={"flex flex-col gap-2"}>
                    <span
                        className={"font-bodyPri font-semibold text-text-900 text-lg tracking-wide truncate line-clamp-1 cursor-pointer hover:!text-secondary-dark hover:underline"}
                        onClick={onHandleNavigateToProductDetail}
                    >
                        {orderItemProductPrice?.product_price?.product?.title}
                    </span>
                    <div className={"inline-flex space-x-2 font-bodyPri text-text-900 text-sm tracking-wide whitespace-nowrap"}>
                        <span className={""}>
                            {"Price Model:"}
                        </span>
                        <span className={"font-bold capitalize"}>
                            {productPriceModelEnum[orderItemProductPrice?.price_model?.toUpperCase()]?.label}
                        </span>
                    </div>
                    <div className={"inline-flex space-x-2 font-bodyPri text-text-900 text-sm tracking-wide whitespace-nowrap"}>
                        <span className={""}>
                            {"Price:"}
                        </span>
                        <span className={"font-bold capitalize"}>
                            {orderItemProductPrice?.master_currency?.code?.toUpperCase()} {parseFloat("" + (orderItemProductPrice?.total_amount / 100)).toFixed(2)}
                            {/* {currencyRateConversion(locals?.currencyCode, orderItemProductPrice?.total_amount)} */}
                        </span>
                    </div>
                </div>
            </div>
            <div className={"grid gap-3 md:grid-cols-2 opacity-70 place-items-start"}>
                <div className={"inline-flex space-x-2 font-bodyPri text-text-900 text-sm tracking-wide whitespace-nowrap"}>
                    <span className={""}>
                        {"STATUS:"}
                    </span>
                    <span className={`font-bold text-${orderItemStatusEnum[userOrderItemDetail?.data?.status?.toUpperCase()]?.darkColor}`}>
                        {orderItemStatusEnum[userOrderItemDetail?.data?.status?.toUpperCase()]?.label}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default OrderItemDetailSection;