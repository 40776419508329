import { useMemo, useState } from 'react'
import { motion } from 'framer-motion';
import { cn } from 'utils/cn.utils';
import { toast } from "react-toastify";
import { Rings, RotatingLines } from 'react-loader-spinner';
import AddIcon from '@mui/icons-material/Add';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { FiSave } from "react-icons/fi";

import { useAppDispatcher, useAppState } from 'hooks/useStore'
import Question from './question';
import { questionData } from './data';
import gptService from 'redux/gpt/gpt.service';
import { setUser } from 'redux/user/user.slice';
import { createBulkQuestions } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';
import image from 'assets/image/ai-quiz.png'

const LMSQuizCreateBulkQuestions = () => {
    const { addLmsQuizSection, lmsQuizDetail } = useAppState((state) => state.lms.lmsQuiz)
    const { lmsCreateBulkQuestions } = useAppState((state) => state.lms.lmsQuestion)
    const { user } = useAppState((state) => state.user)
    const [questions, setQuestions] = useState(questionData);
    const [isShowDelete, setIsShowDelete] = useState(null)
    const [aiQuestion, setAiQuestion] = useState({ isLoading: false, data: null, error: null })
    const [aiResponse, setAiResponse] = useState(null)
    const [isNotFilled, setIsNotFilled] = useState(false)

    const isBlankField = useMemo(() => !questions.every(question => (
        !!question?.title &&
        !!question?.feedback &&
        question?.options?.every(option => !!option?.title) &&
        question?.options?.some(option => option?.is_correct)
    )), [questions]);

    if (isBlankField === false && isNotFilled === true) setIsNotFilled(false)

    const dispatcher = useAppDispatcher();

    const handleAddQuestion = () => {
        if (aiQuestion?.isLoading || lmsCreateBulkQuestions?.isLoading) return;
        const newQuestionId = questions.length > 0 ? Math.max(...questions.map(q => q.questionId)) + 1 : 1;
        const newOptions = [
            { id: 1, title: '', is_correct: false },
            { id: 2, title: '', is_correct: false },
            { id: 3, title: '', is_correct: false },
            { id: 4, title: '', is_correct: false }
        ];
        setQuestions([...questions, { questionId: newQuestionId, title: '', options: newOptions, type: 'single_choice' }]);
    };

    const handleDeleteQuestion = (questionId) => {
        setQuestions(questions.filter(question => question.questionId !== questionId));
    };

    const handleUpdateQuestion = (updatedQuestion) => {
        setQuestions(questions.map(s => s.questionId === updatedQuestion.questionId ? updatedQuestion : s));
    };

    const handleSaveAndExit = () => {
        if (isBlankField) {
            toast.error("Please fill all required fields");
            setIsNotFilled(true)
            return;
        }
        if (aiQuestion?.isLoading || lmsCreateBulkQuestions?.isLoading || isBlankField || !questions?.length) return;
        const requestBody = {
            quiz_section_id: addLmsQuizSection?.payload?.sectionId,
            questions: questions,
        }
        dispatcher(createBulkQuestions(requestBody))
    };

    const requestData = {
        topic: lmsQuizDetail?.data?.title,
        question_type: "single_choice",
        student_level: "intermediate",
    }

    const handleCreateAi = async () => {
        if (aiQuestion?.isLoading || lmsCreateBulkQuestions?.isLoading) return;
        setAiQuestion(s => ({ ...s, isLoading: true }))
        try {
            const requestBody = {
                body: JSON?.stringify(requestData)
            }
            const response = await gptService?.createAiSingleQuestion(requestBody)
            if (response.status === 200) {
                const data = response?.data
                setAiResponse(data)

                const newAiQuestion = JSON?.parse(data?.ai_response)
                setAiQuestion(s => ({ ...s, data: newAiQuestion }))

                const newQuestionId = questions?.length > 0 ? Math.max(...questions?.map(q => q.questionId)) + 1 : 1;
                const newOptions = newAiQuestion?.options?.map((option, index) => ({
                    id: index + 1,
                    title: option?.title,
                    is_correct: option?.is_correct,
                }));
                const newQuestion = {
                    questionId: newQuestionId,
                    type: newAiQuestion?.type,
                    title: newAiQuestion?.title,
                    options: newOptions,
                    feedback: newAiQuestion?.feedback,
                };

                const findEmptyField = questions.findIndex(question =>
                    !question?.title && question?.options?.every(option => !option?.title)
                );
                let updatedQuestions;
                if (findEmptyField !== -1) {
                    updatedQuestions = questions.map((question, index) =>
                        index === findEmptyField ? { ...question, ...newQuestion } : question
                    );
                } else {
                    updatedQuestions = [...questions, newQuestion];
                }

                setQuestions(updatedQuestions);

                dispatcher(setUser({
                    ...user?.user,
                    gpt_user_token: {
                        ...user?.user?.gpt_user_token,
                        total_tokens: data?.token_balance
                    }
                }));
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error);
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong");
        } finally {
            setAiQuestion(s => ({ ...s, isLoading: false }));
        }
    };

    return (
        <div className='space-y-3 w-full p-5 bg-back-ground-lightBlue rounded-lg'>
            <div className='space-y-1 text-center'>
                <h2 className='text-primary-dark text-2xl font-semibold'>{lmsQuizDetail?.data?.title}</h2>
                {lmsQuizDetail?.data?.quiz_sections?.map((section) => {
                    if (section?.id === addLmsQuizSection?.payload?.sectionId) {
                        return (
                            <div key={section.id} className='space-y-1'>
                                <h3 className='text-back-ground-black font-semibold'>{section?.title}</h3>
                                <p className='text-sm text-text-700 font-bulletPoints'>{section?.description}</p>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
            {questions.map((question) => (
                <div
                    key={question?.questionId}
                    className='flex flex-col'
                    onMouseEnter={() => setIsShowDelete(question?.questionId)}
                    onMouseLeave={() => setIsShowDelete(null)}
                >
                    <Question
                        question={question}
                        onUpdate={handleUpdateQuestion}
                        isShowDelete={isShowDelete}
                        handleDeleteQuestion={handleDeleteQuestion}
                        isNotFilled={isNotFilled}
                    />
                </div>
            ))}
            {!questions?.length && (
                <div className='flex flex-col items-center justify-center gap-3 my-1.5 p-5 rounded-lg shadow bg-white border text-text-500'>
                    <img src={image} alt='empty' className='w-64 aspect-square hover:scale-105 ease-linear duration-300' />
                    Please add a question to create a quiz
                </div>
            )}
            <div className='flex w-full flex-col lg:flex-row items-center justify-between gap-3'>
                <div className='flex w-full flex-col lg:flex-row items-center gap-3'>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={handleCreateAi}
                        disabled={aiQuestion?.isLoading || lmsCreateBulkQuestions?.isLoading}
                        className={cn('flex items-center justify-center gap-1 px-3 py-0.5 rounded-full font-semibold font-bulletPoints text-[15px] ',
                            'shadow-sm group bg-white w-full sm:w-auto',
                            aiQuestion?.isLoading || lmsCreateBulkQuestions?.isLoading ? 'cursor-wait' : 'text-primary-dark hover:bg-primary-dark hover:text-white ',
                        )}
                    >
                        {aiQuestion?.isLoading ? (
                            <Rings
                                visible={aiQuestion?.isLoading}
                                height="30"
                                width="30"
                                color="#ff1d58"
                                ariaLabel="rings-loading"
                            />
                        ) : (
                            <AutoFixHighIcon style={{ fontSize: 18 }} className=' animate-pulse text-secondary-dark group-hover:text-white' />
                        )}
                        {aiQuestion?.isLoading ? 'Thinking...' : 'Create with ai'}
                    </motion.button>
                    <motion.button
                        whileHover={!isBlankField && { scale: 1.05 }}
                        whileTap={!isBlankField && { scale: 0.95 }}
                        onClick={handleAddQuestion}
                        disabled={aiQuestion?.isLoading || lmsCreateBulkQuestions?.isLoading || isBlankField}
                        className={cn('flex items-center justify-center gap-1 px-3 py-0.5 rounded-full font-semibold font-bulletPoints text-[15px]',
                            'shadow-sm bg-white w-full sm:w-auto',
                            isBlankField ? 'cursor-not-allowed text-text-300' : 'text-primary-dark hover:bg-primary-dark hover:text-white',
                            aiQuestion?.isLoading || lmsCreateBulkQuestions?.isLoading ? 'cursor-wait' : '',
                        )}
                    >
                        <AddIcon style={{ fontSize: 18 }} />
                        {'Add Question'}
                    </motion.button>
                    {aiResponse && (
                        <div className='hidden lg:flex gap-3 items-center'>
                            <span className='text-sm text-text-700 font-bulletPoints'>{`Token Balance : ${aiResponse?.token_balance}`}</span>
                            <span className='text-sm text-text-700 font-bulletPoints'>{`Token Used : ${aiResponse?.token_used}`}</span>
                        </div>
                    )}
                </div>
                <motion.button
                    whileHover={!isBlankField && { scale: 1.05 }}
                    whileTap={!isBlankField && { scale: 0.95 }}
                    onClick={handleSaveAndExit}
                    disabled={aiQuestion?.isLoading || lmsCreateBulkQuestions?.isLoading || !questions?.length}
                    className={cn('flex items-center justify-center px-3 py-0.5 rounded-full font-semibold font-bulletPoints text-[15px] shadow-sm',
                        'shadow-sm bg-white w-full sm:w-auto gap-2 whitespace-nowrap',
                        !questions?.length ? 'cursor-not-allowed text-text-300' : 'text-primary-dark hover:bg-primary-dark hover:text-white',
                        aiQuestion?.isLoading || lmsCreateBulkQuestions?.isLoading ? 'cursor-wait' : '',
                    )}
                >
                    {lmsCreateBulkQuestions?.isLoading ? (
                        <RotatingLines
                            visible={lmsCreateBulkQuestions?.isLoading}
                            height="20"
                            width="20"
                            strokeWidth="5"
                            animationDuration="0.75"
                        />
                    ) : (
                        <FiSave size={15} />
                    )}
                    {lmsCreateBulkQuestions?.isLoading ? 'Saving...' : 'Save & Exit'}
                </motion.button>
                {aiResponse && (
                    <div className='flex lg:hidden flex-col gap-1 items-center'>
                        <span className='text-sm text-text-700 font-bulletPoints'>{`Token Balance : ${aiResponse?.token_balance}`}</span>
                        <span className='text-sm text-text-700 font-bulletPoints'>{`Token Used : ${aiResponse?.token_used}`}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LMSQuizCreateBulkQuestions
