import { useCallback, useEffect, useState } from 'react'

import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";

import { MdEdit } from 'react-icons/md';

import ButtonLoader from 'components/loader/ButtonLoader';
import { OptionSelector } from 'components/common-components/Select';

import { productPriceDetailConst, lmsCoursePriceModel } from './data';

import { createProductPriceDetail, updateProductPriceDetail } from 'redux/product/product.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetAddProductPriceDetail, resetAddProductPriceDetailPayload, resetModifyProductPriceDetail, setAddProductPriceDetailPayload } from 'redux/product/product.slice';
import { modalConst } from 'redux/local/local.const';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { productPriceDiscountTypeEnum, productPriceModelEnum, productPriceStatusEnum, productPriceTypeEnum } from 'redux/product/product.const';

const minCharacterLength = 20
const maxCharacterLength = 100

const ProductPriceDetailModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { addProductPriceDetail, modifyProductPriceDetail } = useAppState((state) => state.product)

    const dispatcher = useAppDispatcher()

    const [isShowDescriptionContainer, setIsShowDescriptionContainer] = useState(false)
    const [isMouseHovered, setIsMouseHovered] = useState(false)

    useEffect(() => {

        return () => {
            dispatcher(resetAddProductPriceDetailPayload())
            dispatcher(resetAddProductPriceDetail())
            dispatcher(resetModifyProductPriceDetail())
        }
    }, [])

    useEffect(() => {
        if (addProductPriceDetail?.data || modifyProductPriceDetail?.data) {
            dispatcher(resetAddProductPriceDetail())
            dispatcher(resetModifyProductPriceDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.PRODUCT_PRICE_DETAIL_MODAL.stateKey]: false
            }))
        }
    }, [addProductPriceDetail?.data || modifyProductPriceDetail?.data])

    const onHandleEditPriceDetail = useCallback((key, value) => {
        if ((key === productPriceDetailConst.DISCOUNT_PRICE.value) && (value > addProductPriceDetail?.payload?.price)) {
            toast.warn("discount price cannot be greater than product price!")
            return;
        }
        if ((key === productPriceDetailConst.DISCOUNT_PCT.value) && (value > 100)) {
            toast.warn("invalid percentage!")
            return;
        }
        let payload = {
            [key]: value
        }
        if ((key === productPriceDetailConst.PRICE_MODEL.value) && (value === productPriceModelEnum.STANDARD.value)) {
            payload = {
                ...payload,
                unit: 1
            }
        }
        dispatcher(setAddProductPriceDetailPayload({
            ...addProductPriceDetail?.payload,
            ...payload
        }))
    }, [addProductPriceDetail?.payload])

    const onHandlePriceDetailForm = (event) => {
        event.preventDefault()

        if (addProductPriceDetail?.isLoading || modifyProductPriceDetail?.isLoading) return;

        // if (!addProductPriceDetail?.payload?.type) {
        //     toast.warn("Please select price type!")
        //     return;
        // }
        if (!addProductPriceDetail?.payload?.price_model) {
            toast.warn("Please select price model!")
            return;
        }
        if (![productPriceModelEnum?.FREE?.value]?.includes(addProductPriceDetail?.payload?.price_model)) {
            if (!addProductPriceDetail?.payload?.currency_code) {
                toast.warn("Please select currency code!")
                return;
            }
        }
        if (addProductPriceDetail?.payload?.price_model) {
            if ([productPriceModelEnum?.CUSTOM?.value]?.includes(addProductPriceDetail?.payload?.price_model)) {
                if (!addProductPriceDetail?.payload?.preset_price) {
                    toast.warn("please enter preset price")
                    return;
                }
                if (!addProductPriceDetail?.payload?.min_price) {
                    toast.warn("please enter minimum price")
                    return;
                }
                if (!addProductPriceDetail?.payload?.max_price) {
                    toast.warn("please enter maximum price")
                    return;
                }
            }
            if ([productPriceModelEnum?.STANDARD?.value, productPriceModelEnum?.PACKAGE?.value]?.includes(addProductPriceDetail?.payload?.price_model)) {
                if (!addProductPriceDetail?.payload?.price) {
                    toast.warn("Please enter price!")
                    return;
                }
                if (!addProductPriceDetail?.payload?.unit) {
                    toast.warn("Please enter unit!")
                    return;
                }
                if (!addProductPriceDetail?.payload?.discount_type) {
                    toast.warn("Please select discount type!")
                    return;
                }
                if (addProductPriceDetail?.payload?.discount_type) {
                    if ([productPriceDiscountTypeEnum.FLAT.value]?.includes(addProductPriceDetail?.payload?.discount_type) && !addProductPriceDetail?.payload?.discount_price) {
                        toast.warn("Please enter discount price")
                        return;
                    }
                    if ([productPriceDiscountTypeEnum.PERCENTAGE.value]?.includes(addProductPriceDetail?.payload?.discount_type) && !addProductPriceDetail?.payload?.discount_pct) {
                        toast.warn("Please enter discount percent")
                        return;
                    }
                }
            }
        }
        if (!addProductPriceDetail?.payload?.status) {
            toast.warn("please select status!")
            return;
        }

        let body = {
            product_id: addProductPriceDetail?.payload?.product_id,
            currency_code: addProductPriceDetail?.payload?.currency_code,
            type: addProductPriceDetail?.payload?.type,
            price_model: addProductPriceDetail?.payload?.price_model,
            make_default_price: addProductPriceDetail?.payload?.make_default_price
        }
        if (addProductPriceDetail?.payload?.currency_code) {
            body["currency_code"] = addProductPriceDetail?.payload?.currency_code
        }
        if (addProductPriceDetail?.payload?.price_model === productPriceModelEnum?.FREE?.value) {
            body["currency_code"] = masterCurrencyCodeEnum?.USD?.value
        }
        if (addProductPriceDetail?.payload?.description) {
            body["description"] = addProductPriceDetail?.payload?.description
        }
        if (addProductPriceDetail?.payload?.status) {
            body["status"] = addProductPriceDetail?.payload?.status
        }
        if ([productPriceModelEnum?.CUSTOM?.value]?.includes(addProductPriceDetail?.payload?.price_model)) {
            let tiered_price = {}
            tiered_price["preset_price"] = addProductPriceDetail?.payload?.preset_price
            tiered_price["min_price"] = addProductPriceDetail?.payload?.min_price
            tiered_price["max_price"] = addProductPriceDetail?.payload?.max_price

            body = {
                ...body,
                tiered_price: { ...tiered_price }
            }
        }
        if ([productPriceModelEnum?.STANDARD?.value, productPriceModelEnum?.PACKAGE?.value]?.includes(addProductPriceDetail?.payload?.price_model)) {

            let package_price = {}
            package_price["price"] = addProductPriceDetail?.payload?.price
            package_price["unit"] = addProductPriceDetail?.payload?.unit
            package_price["discount_type"] = addProductPriceDetail?.payload?.discount_type
            if ([productPriceDiscountTypeEnum.FLAT.value]?.includes(addProductPriceDetail?.payload?.discount_type)) {
                package_price["discount_price"] = addProductPriceDetail?.payload?.discount_price
            }
            if ([productPriceDiscountTypeEnum.PERCENTAGE.value]?.includes(addProductPriceDetail?.payload?.discount_type)) {
                package_price["discount_pct"] = addProductPriceDetail?.payload?.discount_pct
            }

            body = {
                ...body,
                package_price: { ...package_price }
            }
        }
        if (addProductPriceDetail?.payload?.product_price_id) {
            dispatcher(updateProductPriceDetail(addProductPriceDetail?.payload?.product_price_id, body))
        } else {
            dispatcher(createProductPriceDetail(body))
        }
    }

    return (
        <form onSubmit={onHandlePriceDetailForm} className="space-y-5 overflow-x-hidden">
            <div className={"min-h-[28rem] block w-full pb-3 border border-text-400 space-y-3"}>
                <div className={"space-y-1"}>
                    <div className='w-full px-2 py-1 bg-text-300 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
                        {"Price Details"}
                    </div>
                    <span className='p-2 font-bodyPri font-normal text-xs text-text-900'>
                        {"(Marked with * is mandatory field)"}
                    </span>
                </div>

                <div className="grid grid-cols-2 gap-5 px-2">

                    {/* price type container */}
                    {/* <div className={'col-start-1 col-span-full'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Price Type "}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(productPriceTypeEnum)}
                            className={""}
                            value={addProductPriceDetail?.payload?.type}
                            onChange={(option) => onHandleEditPriceDetail(productPriceDetailConst.PRICE_TYPE.value, option?.value)}
                        />
                    </div> */}

                    {/* price model container */}
                    <div className={'col-start-1 col-span-full'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Price Model "}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(lmsCoursePriceModel)}
                            className={""}
                            value={addProductPriceDetail?.payload?.price_model}
                            onChange={(option) => onHandleEditPriceDetail(productPriceDetailConst.PRICE_MODEL.value, option?.value)}
                        />
                    </div>

                    {/* currency code container */}
                    {![productPriceModelEnum?.FREE?.value]?.includes(addProductPriceDetail?.payload?.price_model) &&
                        <div className={'col-start-1 col-span-full'}>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Currency Code "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <OptionSelector
                                options={Object.values(masterCurrencyCodeEnum)}
                                className={""}
                                value={addProductPriceDetail?.payload?.currency_code}
                                onChange={(option) => onHandleEditPriceDetail(productPriceDetailConst.CURRENCY_CODE.value, option?.value)}
                            />
                        </div>
                    }

                    {/* price container */}
                    {[productPriceModelEnum?.STANDARD?.value, productPriceModelEnum?.PACKAGE?.value]?.includes(addProductPriceDetail?.payload?.price_model) &&
                        <div className={'col-start-1 col-span-full'}>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Price "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <input
                                type={"number"}
                                placeholder={"write price here"}
                                value={(addProductPriceDetail?.payload?.price / 100)?.toString()}
                                className={cn(
                                    "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(event) => {
                                    let safe_number = Number(event.target.value)
                                    if (isNaN(safe_number)) {
                                        safe_number = 0
                                    }
                                    onHandleEditPriceDetail(productPriceDetailConst.PRICE.value, (safe_number * 100))
                                }}
                                required={true}
                            />
                        </div>
                    }

                    {/* product unit container */}
                    {[productPriceModelEnum?.STANDARD?.value, productPriceModelEnum?.PACKAGE?.value]?.includes(addProductPriceDetail?.payload?.price_model) &&
                        <div className={'col-start-1 col-span-full'}>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Unit "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <input
                                type={"number"}
                                placeholder={"e.g. 1"}
                                value={addProductPriceDetail?.payload?.unit?.toString()}
                                className={cn(
                                    "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm disabled:bg-text-100"
                                )}
                                onChange={(event) => onHandleEditPriceDetail(productPriceDetailConst.UNIT.value, event?.target?.value)}
                                required={true}
                                disabled={[productPriceModelEnum?.STANDARD?.value]?.includes(addProductPriceDetail?.payload?.price_model) && true}
                            />
                        </div>
                    }

                    {/* discount type container */}
                    {[productPriceModelEnum?.STANDARD?.value, productPriceModelEnum?.PACKAGE?.value, productPriceModelEnum?.GRADUATED.value, productPriceModelEnum?.VOLUME.value, productPriceModelEnum?.STAIRSTEP.value]?.includes(addProductPriceDetail?.payload?.price_model) &&
                        <div className={'col-start-1 col-span-full'}>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Discount Type "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <OptionSelector
                                options={Object.values(productPriceDiscountTypeEnum)}
                                className={""}
                                value={addProductPriceDetail?.payload?.discount_type}
                                onChange={(option) => onHandleEditPriceDetail(productPriceDetailConst.DISCOUNT_TYPE.value, option?.value)}
                            />
                        </div>
                    }

                    {/* discount price container */}
                    {((![productPriceModelEnum?.CUSTOM?.value]?.includes(addProductPriceDetail?.payload?.price_model)) && (addProductPriceDetail?.payload?.discount_type === productPriceDiscountTypeEnum.FLAT.value)) &&
                        <div className={'col-start-1 col-span-full'}>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Discount Price "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <input
                                type={"number"}
                                placeholder={"e.g. $ 10"}
                                max={addProductPriceDetail?.payload?.price?.toString()}
                                value={(addProductPriceDetail?.payload?.discount_price / 100)?.toString()}
                                className={cn(
                                    "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(event) => {
                                    let safe_number = Number(event.target.value)
                                    if (isNaN(safe_number)) {
                                        safe_number = 0
                                    }
                                    onHandleEditPriceDetail(productPriceDetailConst.DISCOUNT_PRICE.value, (safe_number * 100))
                                }}
                                required={true}
                            />
                        </div>
                    }

                    {/* discount pct container */}
                    {(addProductPriceDetail?.payload?.discount_type === productPriceDiscountTypeEnum.PERCENTAGE.value) &&
                        <div className={'col-start-1 col-span-full'}>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Discount Percent(%) "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <input
                                type={"number"}
                                placeholder={"e.g. 10%"}
                                value={addProductPriceDetail?.payload?.discount_pct?.toString()}
                                className={cn(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                max={100}
                                onChange={(event) => onHandleEditPriceDetail(productPriceDetailConst.DISCOUNT_PCT.value, event?.target?.value)}
                                required={true}
                            />
                        </div>
                    }

                    {/* preset price container */}
                    {[productPriceModelEnum?.CUSTOM?.value]?.includes(addProductPriceDetail?.payload?.price_model) &&
                        <div className={'col-start-1 col-span-full'}>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Preset Price "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <input
                                type={"number"}
                                placeholder={"e.g. $ 100"}
                                value={addProductPriceDetail?.payload?.preset_price?.toString()}
                                className={cn(
                                    "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(event) => onHandleEditPriceDetail(productPriceDetailConst.PRESET_PRICE.value, event?.target?.value)}
                                required={true}
                            />
                        </div>
                    }

                    {/* minimum price container */}
                    {[productPriceModelEnum?.CUSTOM?.value]?.includes(addProductPriceDetail?.payload?.price_model) &&
                        <div className={'col-start-1 col-span-full'}>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Minimum Price "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <input
                                type={"number"}
                                placeholder={"e.g. $ 100"}
                                value={addProductPriceDetail?.payload?.min_price?.toString()}
                                className={cn(
                                    "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(event) => onHandleEditPriceDetail(productPriceDetailConst.MIN_PRICE.value, event?.target?.value)}
                                required={true}
                            />
                        </div>
                    }

                    {/* max price container */}
                    {[productPriceModelEnum?.CUSTOM?.value]?.includes(addProductPriceDetail?.payload?.price_model) &&
                        <div className={'col-start-1 col-span-full'}>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Maximum Price "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <input
                                type={"number"}
                                placeholder={"e.g. $ 100"}
                                value={addProductPriceDetail?.payload?.max_price?.toString()}
                                className={cn(
                                    "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(event) => onHandleEditPriceDetail(productPriceDetailConst.MAX_PRICE.value, event?.target?.value)}
                                required={true}
                            />
                        </div>
                    }

                    {/* price status container */}
                    <div className={'col-start-1 col-span-full'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Price Status "}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <OptionSelector
                            options={Object.values(productPriceStatusEnum)}
                            className={""}
                            value={addProductPriceDetail?.payload?.status}
                            onChange={(option) => onHandleEditPriceDetail(productPriceDetailConst.STATUS.value, option?.value)}
                        />
                    </div>

                    {/* price description */}
                    <div className={'col-start-1 col-span-full'}>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Description "}
                        </span>
                        {!isShowDescriptionContainer &&
                            <div
                                className={"w-full flex items-center justify-between gap-3 cursor-pointer pt-1"}
                                onClick={() => setIsShowDescriptionContainer(true)}
                                onMouseOver={() => setIsMouseHovered(true)}
                                onMouseOut={() => setIsMouseHovered(false)}
                            >
                                <span className={"font-bodyPri font-normal text-primary-dark text-sm tracking-wide"}>
                                    {addProductPriceDetail?.payload?.description
                                        ? addProductPriceDetail?.payload?.description
                                        : "Add Description"
                                    }
                                </span>
                                {isMouseHovered &&
                                    <MdEdit className={"text-xl text-text-900"} />
                                }
                            </div>
                        }
                        {isShowDescriptionContainer &&
                            <textarea
                                className={cn(
                                    "w-full px-5 py-3 bg-back-ground-light rounded-lg resize-none",
                                    "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                                    "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                                )}
                                minLength={minCharacterLength}
                                maxLength={maxCharacterLength}
                                rows={4}
                                name={"description"}
                                autoFocus={true}
                                placeholder="Write your text here ...."
                                value={addProductPriceDetail?.payload?.description}
                                onChange={(event) => onHandleEditPriceDetail(productPriceDetailConst?.DESCRIPTION?.value, event?.target?.value)}
                                onBlur={() => setIsShowDescriptionContainer(false)}
                            />
                        }
                    </div>


                    {/* default price container */}
                    <div className={'col-start-1 col-span-full'}>
                        <div
                            className={"w-fit flex items-center justify-start gap-2 cursor-pointer"}
                            onClick={() => onHandleEditPriceDetail(productPriceDetailConst.MAKE_DEFAULT_PRICE.value, !addProductPriceDetail?.payload?.make_default_price)}
                        >
                            <input
                                type={"checkbox"}
                                className={"w-4 h-4 rounded border border-divider-medium"}
                                checked={addProductPriceDetail?.payload?.make_default_price}
                                onChange={(event) => onHandleEditPriceDetail(productPriceDetailConst.MAKE_DEFAULT_PRICE.value, event?.target?.checked)}
                            />
                            <span className={"font-bodyPri font-normal text-text-900 text-sm tracking-wide"}>
                                {"Make Default"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* action buttons */}
            <div className={"flex justify-end px-5"}>
                <button
                    type={"submit"}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full",
                        "font-buttons font-normal text-base",
                        "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        (addProductPriceDetail?.isLoading || modifyProductPriceDetail?.isLoading) && "bg-secondary-main"
                    )}>
                    {(addProductPriceDetail?.isLoading || modifyProductPriceDetail?.isLoading) &&
                        <ButtonLoader isLoading={addProductPriceDetail?.isLoading || modifyProductPriceDetail?.isLoading} />
                    }
                    {(!addProductPriceDetail?.isLoading && !modifyProductPriceDetail?.isLoading) && "Submit"}
                </button>
            </div>
        </form>
    )
}

export default ProductPriceDetailModal;