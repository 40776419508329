import { useCallback, useState, useRef, useEffect } from 'react';

import { BsInfoCircle } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import ToolTipView from 'components/tooltipView';
import ComponentLoader from 'components/loader/ComponentLoader';
import FadedCollapse from 'components/fadedCollapse';

import LocationOptionsMenu from 'pages/auth/editAppointment/commonComponents/LocationOptionsMenu';
import { appointmentDetailTooltips, initialLocationPayload, locationsConst, setLocationDetailPayload } from 'pages/auth/editAppointment/data';

import { deleteAppointmentLocationDetail, getAppointmentLocationList } from 'redux/appointment/appointment.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetAddAppointmentLocationDetail, resetAppointmentLocationList, resetModifyAppointmentLocationDetail, setAppointmentLocationListData } from 'redux/appointment/appointment.slice';
import { modalConst } from 'redux/local/local.const';
import { locationTypeEnum } from 'redux/appointment/appointment.const';

import { cn } from 'utils/cn.utils';

const Location = () => {
    const { modal } = useAppState((state) => state.local)
    const { countryList } = useAppState((state) => state.master)
    const { userAppointmentDetail, appointmentLocationList, addAppointmentLocationDetail, modifyAppointmentLocationDetail, destroyAppointmentLocationDetail } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()

    const [isOpenDropdown, setIsOpenDropdown] = useState(false)
    const dropdownRef = useRef()

    useEffect(() => {
        if (userAppointmentDetail?.data?.result?.id) {
            dispatcher(getAppointmentLocationList(userAppointmentDetail?.data?.result?.id))
        }

        return () => {
            dispatcher(resetAppointmentLocationList())
            dispatcher(resetAddAppointmentLocationDetail())
            dispatcher(resetModifyAppointmentLocationDetail())
        }
    }, [userAppointmentDetail?.data?.result?.id])

    useEffect(() => {
        if (addAppointmentLocationDetail?.data?.result) {
            dispatcher(setAppointmentLocationListData({
                ...appointmentLocationList?.data,
                result: [addAppointmentLocationDetail?.data?.result, ...appointmentLocationList?.data?.result]
            }))
            dispatcher(resetAddAppointmentLocationDetail())
            onHandleCloseModal()
        }
    }, [addAppointmentLocationDetail?.data?.result])

    useEffect(() => {
        if (modifyAppointmentLocationDetail?.data?.result) {
            dispatcher(setAppointmentLocationListData({
                ...appointmentLocationList?.data,
                result: appointmentLocationList?.data?.result?.map((appointmentLocation) => (
                    (appointmentLocation?.id === modifyAppointmentLocationDetail?.data?.result?.id)
                        ? { ...modifyAppointmentLocationDetail?.data?.result }
                        : appointmentLocation
                ))
            }))
            dispatcher(resetModifyAppointmentLocationDetail())
            onHandleCloseModal()
        }
    }, [modifyAppointmentLocationDetail?.data?.result])

    const onHandleCloseModal = useCallback(() => {
        dispatcher(setModal({
            ...modal,
            [modalConst.appointmentLocationDetailModal.key]: {
                ...modal[modalConst.appointmentLocationDetailModal.key],
                isVisible: false,
                title: "",
                payload: null
            }
        }))
    }, [modal])

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event?.target)) {
            setIsOpenDropdown(false)
        }
    }

    const onHandleToggleDropdown = () => {
        setIsOpenDropdown(!isOpenDropdown)
        if (!isOpenDropdown) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }
    };

    const onHandleEditLocation = useCallback((locationDetail) => {
        if (locationDetail?.location_type === locationTypeEnum.edulyte_meet.key) return;

        const payload = setLocationDetailPayload(locationDetail, countryList?.countryList)
        dispatcher(setModal({
            ...modal,
            [modalConst.appointmentLocationDetailModal.key]: {
                ...modal[modalConst.appointmentLocationDetailModal.key],
                isVisible: true,
                title: "Edit Location",
                payload: { isEditEnabled: true, ...payload }
            }
        }))
    }, [modal, countryList?.countryList])

    const onHandleDeleteLocation = useCallback((selectedLocationId) => {
        if (destroyAppointmentLocationDetail?.isLoading) return;

        dispatcher(deleteAppointmentLocationDetail(userAppointmentDetail?.data?.result?.id, selectedLocationId))
    }, [destroyAppointmentLocationDetail?.isLoading, userAppointmentDetail?.data?.result?.id])

    const onHandleAddLocation = useCallback((locationItem) => {
        const payload = {
            ...initialLocationPayload,
            isEditEnabled: false,
            location_type: locationItem,
            appointment_id: userAppointmentDetail?.data?.result?.id
        }
        dispatcher(setModal({
            ...modal,
            [modalConst.appointmentLocationDetailModal.key]: {
                ...modal[modalConst.appointmentLocationDetailModal.key],
                isVisible: true,
                title: "Add Location",
                payload: payload
            }
        }))
        setIsOpenDropdown(false)
    }, [modal, userAppointmentDetail?.data?.result])

    return (
        <div ref={dropdownRef} className={"relative w-full flex flex-col gap-2"}>
            <div className={"flex items-center justify-start gap-2"}>
                <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                    {"Location *"}
                </span>
                <ToolTipView content={appointmentDetailTooltips.location}>
                    <button className={""}>
                        <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                    </button>
                </ToolTipView>
            </div>
            {appointmentLocationList &&
                <ComponentLoader isLoading={appointmentLocationList?.isLoading} />
            }
            {(!appointmentLocationList?.isLoading && !!appointmentLocationList?.data?.result?.length) &&
                <div className={"w-full flex flex-col gap-3"}>
                    <FadedCollapse
                        isShowMore={appointmentLocationList?.data?.result?.length > 5}
                        isFaded={appointmentLocationList?.data?.result?.length > 5}
                        maxHeight={appointmentLocationList?.data?.result?.length > 5 ? "7.5rem" : "100%"}
                    >
                        <div className={"w-full flex flex-col gap-3"}>
                            {appointmentLocationList?.data?.result?.map((appointmentLocation, index) => {
                                const locationInfo = locationsConst[appointmentLocation?.location_type]
                                return (
                                    <div key={index} className={"w-full flex items-center justify-between gap-3 p-2 border border-divider-medium bg-back-ground-lightBlue"}>
                                        <div className={"flex items-center justify-start gap-2.5"}>
                                            <locationInfo.icon className={"text-xl"} />
                                            <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                                {locationTypeEnum[appointmentLocation?.location_type].label}
                                            </span>
                                        </div>
                                        <div className={"flex items-center justify-start gap-2"}>
                                            <button
                                                disabled={locationInfo.value === locationTypeEnum.edulyte_meet.key}
                                                className={cn(
                                                    "font-bodyPri font-normal text-base",
                                                    (locationInfo.value === locationTypeEnum.edulyte_meet.key)
                                                        ? "text-text-300 cursor-not-allowed"
                                                        : "text-primary-main hover:underline cursor-pointer"
                                                )}
                                                onClick={() => onHandleEditLocation(appointmentLocation)}
                                            >
                                                {"Edit"}
                                            </button>
                                            <span>{"|"}</span>
                                            <ToolTipView content={"Remove location"}>
                                                <button className={""} onClick={() => onHandleDeleteLocation(appointmentLocation?.id)}>
                                                    <ClearOutlinedIcon className={"text-xl text-text-800 cursor-pointer"} />
                                                </button>
                                            </ToolTipView>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </FadedCollapse>
                    <div className={"flex flex-col gap-1"}>
                        {(appointmentLocationList?.data?.result?.length === 1) &&
                            <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                                {"Want to offer choices to your invitee? "}
                            </span>
                        }
                        <button
                            className={"w-fit font-bodyPri font-normal text-primary-main text-base hover:underline cursor-pointer"}
                            onClick={() => onHandleAddLocation(locationsConst[locationTypeEnum.custom.key])}
                        >
                            {"Add a location option"}
                        </button>
                    </div>
                </div>
            }
            {(!appointmentLocationList?.isLoading && (!appointmentLocationList?.data?.result || (appointmentLocationList?.data?.result?.length === 0))) &&
                <div className={"w-full flex items-center justify-start gap-2"}>
                    <>
                        {Object.values(locationsConst).filter((item) => item.default)?.map((locationItem, index) => (
                            <div
                                key={index}
                                className={cn(
                                    "w-[8rem] h-[7.5rem] md:w-[6rem] md:h-[5.5rem] flex flex-col items-center justify-center gap-1 group cursor-pointer text-center",
                                    "border border-divider-lightDark rounded-md hover:bg-back-ground-lightBlue font-bodyPri"
                                )}
                                onClick={() => onHandleAddLocation(locationItem)}
                            >
                                <locationItem.icon className={"text-xl"} />
                                <span className={'font-bodyPri font-normal text-text-900 text-sm'}>
                                    {locationItem?.label}
                                </span>
                            </div>
                        ))}
                        <div
                            className={"w-[8rem] h-[7.5rem] md:w-[6rem] md:h-[5.5rem] flex flex-col items-center justify-center gap-1 group cursor-pointer text-center font-bodyPri"}
                            onClick={onHandleToggleDropdown}
                        >
                            <IoIosArrowDown className={"text-xl"} />
                            <span className={'font-bodyPri font-normal text-text-900 text-sm'}>
                                {"All options"}
                            </span>
                        </div>
                    </>
                </div>
            }
            {isOpenDropdown &&
                <LocationOptionsMenu
                    isOpenDropdown={isOpenDropdown}
                    setIsOpenDropdown={setIsOpenDropdown}
                    onHandleAddLocation={onHandleAddLocation}
                />
            }
        </div>
    )
}

export default Location;