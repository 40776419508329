import { useState } from "react";
import { cn } from "utils/cn.utils"
import { motion } from "framer-motion";
import { FiEdit } from 'react-icons/fi';
import { MdAdd } from "react-icons/md";
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { FaSpinner } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa";

import { profileEditBtn } from 'pages/auth/profile/data';
import SaveAndCancelBtn from "pages/auth/profile/commonComponents/SaveAndCancelBtn";

import {
  createUserEducationDetailByUserId,
  updateEducationDetailByUserEducationId,
  deleteEducationDetailByUserEducationId
} from "redux/user/user.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";

const Qualification = ({ editBtnConst, setEditBtnConst }) => {
  const { user, userEducationList, addUserEducationDetail } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()

  const [isShowAddButton, setIsShowAddButton] = useState(false)
  const [isAddNewEducationEnabled, setIsAddNewEducationEnabled] = useState(false)
  const [userEducation, setUserEducation] = useState({
    isUpdateUserEducation: false,
    userEducation: null,
    userEducationId: null
  })

  const onHandleAddEducation = () => {
    setIsShowAddButton(false)
    setIsAddNewEducationEnabled(true)
    setUserEducation({
      isUpdateUserEducation: false,
      userEducationId: null,
      userEducation: null
    })
  }

  const removeUserEducation = (education) => {
    dispatcher(deleteEducationDetailByUserEducationId(education?.userEducationId))
  }

  const onHandleUpdateEducation = () => {
    dispatcher(updateEducationDetailByUserEducationId(
      userEducation?.userEducationId,
      {
        education: userEducation?.userEducation
      }
    ))
    setIsAddNewEducationEnabled(false)
    setIsShowAddButton(false)
    setEditBtnConst(null)
  }

  const onSaveNewQualification = () => {
    if (userEducation?.isUpdateUserEducation) {
      onHandleUpdateEducation()
      return;
    }
    if (userEducation?.userEducation?.length > 0) {
      const filteredEducationList = userEducationList?.userEducationList?.filter((education) => (education?.education === userEducation?.userEducation))
      if (filteredEducationList?.length > 0) {
        alert("Duplicate education name is not allowed!")
        return;
      }

      dispatcher(createUserEducationDetailByUserId(
        user?.user?.userId,
        {
          education: userEducation?.userEducation
        }
      ))
      setIsAddNewEducationEnabled(false)
      setIsShowAddButton(false)
      setEditBtnConst(null)
    } else {
      alert("Please enter your qualification")
    }
  }

  return (
    <div className={"space-y-3"}>
      <div className={"flex items-center justify-start gap-2"}>
        <div className='flex items-center gap-1 text-text-600 '>
          <FaGraduationCap size={16} />
          <p className='whitespace-nowrap'>My Qualifications:</p>
        </div>
        {!editBtnConst &&
          <FiEdit className="text-base text-text-500 cursor-pointer hover:text-primary-dark"
            onClick={() => {
              setEditBtnConst(profileEditBtn.educations.value)
              setIsShowAddButton(true)
            }}
          />
        }
        {addUserEducationDetail?.isLoading &&
          <FaSpinner className={"inline-flex text-primary-dark animate-spin"} />
        }
        {editBtnConst === profileEditBtn.educations.value &&
          <div
            className={cn(
              "flex item-center justify-center px-2 py-0.5 rounded-md cursor-pointer",
              "font-buttons font-normal text-base text-text-800 bg-back-ground-darkLight",
              "hover:bg-back-ground-medium hover:text-text-900"
            )}
            onClick={() => {
              setIsShowAddButton(false)
              setIsAddNewEducationEnabled(false)
              setEditBtnConst(null)
            }}
          >
            <span className={""}>
              {"Cancel"}
            </span>
          </div>
        }
      </div>

      <div className={cn("px-5 py-2",
        editBtnConst === profileEditBtn.educations.value && "shadow rounded border border-secondary-light"
      )}>
        <div className={"flex flex-col space-y-3 flex-wrap justify-start items-start"}>
          {userEducationList?.userEducationList?.length > 0
            && userEducationList?.userEducationList?.map((education, idx) => (
              <div className={"flex items-center gap-5"} key={idx}>
                <span className="w-max px-4 py-1 border-2 border-text-300 rounded-xl text-text-900 font-medium font-bodyPri">
                  {education?.education}
                </span>

                {editBtnConst === profileEditBtn.educations.value &&
                  <div className={"flex items-center justify-start gap-1.5"}>
                    <FiEdit
                      className={"text-lg text-text-500 cursor-pointer"}
                      onClick={() => {
                        setUserEducation({
                          isUpdateUserEducation: true,
                          userEducationId: education?.userEducationId,
                          userEducation: education?.education,
                        })
                        setIsShowAddButton(false)
                        setIsAddNewEducationEnabled(true)
                      }}
                    />
                    <MdOutlineDeleteOutline
                      className={"text-xl text-text-500 cursor-pointer"}
                      onClick={() => removeUserEducation(education)}
                    />
                  </div>
                }
              </div>
            ))}
          {(editBtnConst !== profileEditBtn.educations.value && userEducationList?.userEducationList?.length === 0) &&
            <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
              {"No Qualifications Added"}
            </span>
          }
          {isShowAddButton &&
            <div
              className={cn(
                "flex item-center justify-center cursor-pointer",
                "font-buttons font-normal text-base text-text-700",
                "hover:text-text-800"
              )}
              onClick={onHandleAddEducation}
            >
              <MdAdd className={"text-xl"} />
              <span className={"whitespace-nowrap"}>
                {"Add Education"}
              </span>
            </div>
          }
        </div>
        {isAddNewEducationEnabled &&
          <div className={"pt-3 flex flex-col justify-start items-start gap-3"}>
            <input
              type={"text"}
              className={cn(
                "px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                "font-bodyPri font-normal text-text-800 text-base",
                "placeholder:text-text-500 placeholder:text-sm"
              )}
              autoFocus={true}
              placeholder={"e.g. Masters, Graduate"}
              value={userEducation?.userEducation && userEducation?.userEducation?.charAt(0)?.toUpperCase() + (userEducation?.userEducation?.slice(1))}
              onChange={(e) => setUserEducation({
                ...userEducation,
                userEducation: e.target.value
              })}
            />
            <SaveAndCancelBtn
              onSave={() => onSaveNewQualification()}
              onCancel={() => {
                setUserEducation({
                  isUpdateUserEducation: false,
                  userEducationId: null,
                  userEducation: null
                })
                setIsAddNewEducationEnabled(false)
                setIsShowAddButton(true)
              }}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default Qualification;
