import { PRO_USER_PRODUCT_ID, PRO_ORG_PRODUCT_ID } from "const/default.const";

export const subscriptionPlanEnum = {
    free: {
        key: "free",
        label: "Free"
    },
    pro_user: {
        key: "pro_user",
        label: "Pro",
        productId: PRO_USER_PRODUCT_ID
    },
    pro_org: {
        key: "pro_org",
        label: "Pro Org",
        productId: PRO_ORG_PRODUCT_ID
    },
}

export const subscriptionStatusEnum = {
    active: {
        key: "active",
        label: "Active"
    },
    trialing: {
        key: "trialing",
        label: "Trial"
    },
    inactive: {
        key: "inactive",
        label: "Inactive"
    },
}

export const subscriptionIntervalEnum = {
    day: {
        key: "day",
        label: "Daily"
    },
    week: {
        key: "week",
        label: "Weekly"
    },
    month: {
        key: "month",
        label: "Monthly"
    },
    year: {
        key: "year",
        label: "Save 25% Yearly"
    }
}


export const userPlans = {
    tabs: {
        month: subscriptionIntervalEnum.month,
        year: subscriptionIntervalEnum.year,
    },
    hasFree: true,
    productIds: [subscriptionPlanEnum.pro_user.productId],
    plans: {
        [subscriptionPlanEnum.free.key]: {
            plan: subscriptionPlanEnum.free.key,
            [subscriptionIntervalEnum.month.key]: {
                interval: subscriptionIntervalEnum.month.key,
                label: subscriptionPlanEnum.free.label,
                plan: subscriptionPlanEnum.free.key,
                title: "Free",
                description: "Access Essential Tools at No Cost.",
                isPopular: false,
                isFocus: false,
                productPrice: null,
                features: [
                    {
                        item: "3000 AI tokens on signup",
                        tooltip: "Get 3000 AI tokens to instantly create quizzes and courses with Powerful AI tools."
                    },
                    {
                        item: "10% transaction fee",
                        tooltip: "Pay 10% transaction fee plus Stripe’s standard fee of 2.9% + $0.30 per transaction, keep the rest in your pocket."
                    },
                    {
                        item: "Sell any 15 products",
                        tooltip: "Offer up to 15 different products for sale on our platform."
                    },
                    {
                        item: "Access all free quizzes",
                        tooltip: "Enjoy unlimited access to a wide variety of free quizzes across multiple subjects."
                    },
                    {
                        item: "Access all free courses",
                        tooltip: "Explore an extensive range of free courses to enhance your learning and teaching skills."
                    },
                    {
                        item: "5GB storage",
                        tooltip: "Store up to 5GB of course materials and data securely."
                    },
                    {
                        item: "Free Events",
                        tooltip: "Host and attend unlimited educational events for free—enhance learning and expand your professional network."
                    },
                    {
                        item: "Downloadable Products",
                        tooltip: "Boost your earnings by selling downloadable products like ebooks, courses, and templates—feature coming soon!"
                    },
                ]
            },
            [subscriptionIntervalEnum.year.key]: {
                interval: subscriptionIntervalEnum.year.key,
                label: subscriptionPlanEnum.free.label,
                plan: subscriptionPlanEnum.free.key,
                title: "Free",
                description: "Access Essential Tools at No Cost.",
                isPopular: false,
                isFocus: false,
                productPrice: null,
                features: [
                    {
                        item: "3000 AI tokens on signup",
                        tooltip: "Get 3000 AI tokens to instantly create quizzes and courses with Powerful AI tools."
                    },
                    {
                        item: "10% transaction fee",
                        tooltip: "Pay 10% transaction fee plus Stripe’s standard fee of 2.9% + $0.30 per transaction, keep the rest in your pocket."
                    },
                    {
                        item: "Sell any 15 products",
                        tooltip: "Offer up to 15 different products for sale on our platform."
                    },
                    {
                        item: "Access all free quizzes",
                        tooltip: "Enjoy unlimited access to a wide variety of free quizzes across multiple subjects."
                    },
                    {
                        item: "Access all free courses",
                        tooltip: "Explore an extensive range of free courses to enhance your learning and teaching skills."
                    },
                    {
                        item: "5GB storage",
                        tooltip: "Store up to 5GB of course materials and data securely."
                    },
                    {
                        item: "Free Events",
                        tooltip: "Host and attend unlimited educational events for free—enhance learning and expand your professional network."
                    },
                    {
                        item: "Downloadable Products",
                        tooltip: "Boost your earnings by selling downloadable products like ebooks, courses, and templates—feature coming soon!"
                    },
                ]
            }
        },
        [subscriptionPlanEnum.pro_user.key]: {
            plan: subscriptionPlanEnum.pro_user.key,
            [subscriptionIntervalEnum.month.key]: {
                interval: subscriptionIntervalEnum.month.key,
                label: subscriptionPlanEnum.pro_user.label,
                plan: subscriptionPlanEnum.pro_user.key,
                isPopular: true,
                isFocus: true,
                productPrice: null,
                features: [
                    {
                        item: "Everything in free +",
                        tooltip: ""
                    },
                    {
                        item: "250,000 AI tokens per month",
                        tooltip: "Get 250,000 AI tokens to instantly create quizzes and courses with Powerful AI tools."
                    },
                    {
                        item: "5 % transaction fee",
                        tooltip: "Save and pay only 5% transaction fee plus Stripe’s standard fee of 2.9% + $0.30 per transaction, keep the rest in your pocket."
                    },
                    {
                        item: "Access to CRM",
                        tooltip: "Manage leads, contacts, and clients, label them, and track their activities with our easy-to-use CRM tools."
                    },
                    {
                        item: "Unlimited products",
                        tooltip: "Offer an unlimited number of products on the platform, maximising your sales potential."
                    },
                    {
                        item: "Alerts and Reminders",
                        tooltip: "Stay on top of your schedule and responsibilities with timely alerts and reminders."
                    },
                    {
                        item: "50 GB storage",
                        tooltip: "Store up to 50GB of course materials and data securely."
                    },
                    {
                        item: "Paid Events",
                        tooltip: "Host and monetize your own events, setting ticket prices and managing registrations directly."
                    },
                    {
                        item: "Access to focus groups",
                        tooltip: "Join a private focus group on LinkedIn  to collaborate, gain insights, and refine your educational strategies."
                    },
                    {
                        item: "Premium Email and chat support",
                        tooltip: "Get priority assistance with our premium email and chat support services, ensuring swift and effective solutions."
                    },
                ]
            },
            [subscriptionIntervalEnum.year.key]: {
                interval: subscriptionIntervalEnum.year.key,
                label: subscriptionPlanEnum.pro_user.label,
                plan: subscriptionPlanEnum.pro_user.key,
                isPopular: true,
                isFocus: true,
                productPrice: null,
                features: [
                    {
                        item: "Everything in free +",
                        tooltip: ""
                    },
                    {
                        item: "250,000 AI tokens per month",
                        tooltip: "Get 250,000 AI tokens to instantly create quizzes and courses with Powerful AI tools."
                    },
                    {
                        item: "5 % transaction fee",
                        tooltip: "Save and pay only 5% transaction fee plus Stripe’s standard fee of 2.9% + $0.30 per transaction, keep the rest in your pocket."
                    },
                    {
                        item: "Access to CRM",
                        tooltip: "Manage leads, contacts, and clients, label them, and track their activities with our easy-to-use CRM tools."
                    },
                    {
                        item: "Unlimited products",
                        tooltip: "Offer an unlimited number of products on the platform, maximising your sales potential."
                    },
                    {
                        item: "Alerts and Reminders",
                        tooltip: "Stay on top of your schedule and responsibilities with timely alerts and reminders."
                    },
                    {
                        item: "50 GB storage",
                        tooltip: "Store up to 50GB of course materials and data securely."
                    },
                    {
                        item: "Paid Events",
                        tooltip: "Host and monetize your own events, setting ticket prices and managing registrations directly."
                    },
                    {
                        item: "Access to focus groups",
                        tooltip: "Join a private focus group on LinkedIn  to collaborate, gain insights, and refine your educational strategies."
                    },
                    {
                        item: "Premium Email and chat support",
                        tooltip: "Get priority assistance with our premium email and chat support services, ensuring swift and effective solutions."
                    },
                ]
            }
        }
    }
}