import { useEffect, useState } from 'react'
import { MdOutlineEdit, MdOutlineCloudUpload } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { certificateStatusEnum } from '../../data';
import { cn } from 'utils/cn.utils';
import { imageFileTypeEnum, mbToKbConst, profilePicTypeConst } from 'redux/storage/storage.const';
import { uploadFileToS3 } from 'redux/storage/storage.request';
import { updateUserConfig } from 'redux/user/user.request';
import { resetUploadFile } from 'redux/storage/storage.slice';
import { resetModifyUserConfig, setUser } from 'redux/user/user.slice';
import { debounce } from 'utils/generators.utils';
import { FaSpinner } from 'react-icons/fa';

const IssuerDetails = ({ certificate, setCertificateDetails }) => {
    const { uploadFile } = useAppState((s) => s.storage)
    const { user, modifyUserConfig } = useAppState((state) => state.user)
    const [edit, setEdit] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (modifyUserConfig?.data) {
            dispatcher(setUser({
                ...user?.user,
                userConfig: {
                    ...user?.user?.userConfig,
                    sign_url: modifyUserConfig?.data?.sign_url,
                    logo_url: modifyUserConfig?.data?.logo_url,
                    display_name: modifyUserConfig?.data?.display_name,
                }
            }))
            setCertificateDetails(s => ({
                ...s,
                result: {
                    ...s.result,
                    placeholder: {
                        ...s.result.placeholder,
                        sign_url: modifyUserConfig?.data?.sign_url,
                        logo_url: modifyUserConfig?.data?.logo_url,
                        display_name: modifyUserConfig?.data?.display_name,
                    }
                }
            }))
            dispatcher(resetModifyUserConfig())
            setSelectedFile(null)
        }
    }, [modifyUserConfig?.data])

    useEffect(() => {
        if (uploadFile?.data && (uploadFile?.data?.length > 0)) {
            const requestPayload = {}
            if (selectedFile?.type === "logo") {
                requestPayload["logo_url"] = uploadFile?.data[0]?.fileUrl
            } else {
                requestPayload["sign_url"] = uploadFile?.data[0]?.fileUrl
            }
            dispatcher(updateUserConfig(user?.user?.userId, requestPayload))
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])

    useEffect(() => {
        if (selectedFile) {
            dispatcher(uploadFileToS3(selectedFile?.fileList, user?.user?.userId))
        }
    }, [selectedFile])

    const onHandleSelectSign = (event, type) => {
        const files = event?.target?.files
        if (!files?.length) return;

        let requestPayload = {};
        let errorMsg = "";

        const file = files[0];
        if (!file || (file.length === 0)) {
            alert("file not selected!")
            return;
        }
        if (imageFileTypeEnum[file?.type]) {
            if (file?.size < (2 * mbToKbConst)) {
                requestPayload = {
                    type: type,
                    file: file,
                    fileList: files,
                    fileUrl: URL.createObjectURL(file)
                }
                setSelectedFile(requestPayload)
            } else {
                errorMsg = "File size is too large!"
            }
        }
    }

    const onHandleIssueName = (value) => {
        const userConfig = {
            display_name: value
        }
        dispatcher(updateUserConfig(user?.user?.userId, userConfig))
    }

    const updateIssuerName = (e) => {
        setCertificateDetails(s => ({
            ...s,
            result: {
                ...s.result,
                placeholder: {
                    ...s.result.placeholder,
                    issued_by: e.target.value
                }
            }
        }))
        const delayFunction = debounce.debounce(onHandleIssueName)
        delayFunction(e.target.value)
    }

    return (
        <div className='flex flex-col space-y-2 w-full'>
            {edit ? (
                <div className='flex flex-col gap-2 bg-back-ground-light p-3 rounded'>
                    <div className='flex justify-between items-center gap-3'>
                        <div className='flex items-center gap-3'>
                            <label className='text-text-600'>Issuer Details</label>
                            {modifyUserConfig?.isLoading && (
                                <FaSpinner className={"animate-spin text-lg text-primary-main"} />
                            )}
                        </div>
                        <IoMdClose size={20} className='text-text-600 hover:text-secondary-dark cursor-pointer' onClick={() => setEdit(false)} />
                    </div>
                    <input
                        className={cn('w-full px-3 py-2 rounded border hover:border-primary-main outline-primary-main',
                            certificate?.status === certificateStatusEnum.issued.value && 'hover:border-text-200 outline-text-200'
                        )}
                        placeholder={"Issuer Name"}
                        disabled={certificate?.status === certificateStatusEnum.issued.value}
                        value={certificate?.placeholder?.issued_by}
                        onChange={updateIssuerName}
                    />
                    <div className='flex justify-between gap-3'>
                        <div className='flex flex-col justify-center items-center gap-2'>
                            <p className='text-text-600 font-normal'>Company Logo</p>
                            <div>
                                <label for={'logo'} className='flex gap-3 flex-col justify-center items-center cursor-pointer hover:text-secondary-dark'>
                                    <img
                                        className="h-20 w-40 object-contain"
                                        alt='logo'
                                        src={user?.user?.userConfig?.logo_url || "https://placehold.co/200?text=Logo&font=roboto"}
                                    />
                                    Upload Logo
                                </label>
                                <input
                                    id={'logo'}
                                    type={"file"}
                                    style={{ display: "none", width: "100%", height: "100%", cursor: "pointer" }}
                                    accept={profilePicTypeConst}
                                    disabled={modifyUserConfig?.isLoading}
                                    onChange={(e) => onHandleSelectSign(e, 'logo')}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center gap-2'>
                            <p className='text-text-600 font-normal'>Auth Sign</p>
                            <div>
                                <label for={'sign'} className='flex gap-3 flex-col justify-center items-center cursor-pointer hover:text-secondary-dark'>
                                    <img
                                        className="h-14 w-48 object-contain"
                                        alt='sign'
                                        src={user?.user?.userConfig?.sign_url || "https://placehold.co/200?text=Sign&font=roboto"}
                                    />
                                    Upload Sign
                                </label>
                                <input
                                    id={'sign'}
                                    type={"file"}
                                    style={{ display: "none", width: "100%", height: "100%", cursor: "pointer" }}
                                    accept={profilePicTypeConst}
                                    disabled={modifyUserConfig?.isLoading}
                                    onChange={(e) => onHandleSelectSign(e, 'sign')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='bg-back-ground-light p-3 space-y-3 rounded font-medium'>
                    <div className='flex justify-between border-b pb-1'>
                        <div className='flex gap-1'>
                            <p className='text-text-600 font-normal'>Issuer Name: </p>
                            {user?.user?.userConfig?.display_name}
                        </div>
                        <button
                            className={cn('text-text-700 hover:text-secondary-dark', certificate?.status === certificateStatusEnum.issued.value && "text-text-200 hover:text-text-200")}
                            onClick={() => setEdit(true)}
                            disabled={certificate?.status === certificateStatusEnum.issued.value}>
                            <MdOutlineEdit size={20} />
                        </button>
                    </div>
                    {user?.user?.userConfig?.logo_url?.length || user?.user?.userConfig?.sign_url?.length !== 0 ? (
                        <div className='flex justify-between gap-3'>
                            <div className='flex flex-col justify-center items-center gap-2'>
                                <p className='text-text-600 font-normal'>Company Logo</p>
                                <img
                                    className="h-20 w-40 object-contain"
                                    alt='logo'
                                    src={certificate?.placeholder?.logo_url}
                                />
                            </div>
                            <div className='flex flex-col justify-center items-center gap-2'>
                                <p className='text-text-600 font-normal'>Auth Sign</p>
                                <img
                                    className="h-14 w-48 object-contain"
                                    alt='sign'
                                    src={certificate?.placeholder?.sign_url}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='text-sm font-normal text-text-600'>
                            Please update issuer details
                        </div>
                    )
                    }
                </div>
            )
            }
        </div>
    )
}

export default IssuerDetails
