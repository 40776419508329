import { memo, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { MdContentCopy } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';

import DownloadTooltip from 'pages/auth/editDownload/commonComponents/DownloadTooltip';
import { downloadFileExtensionConst, fileItemActions } from 'pages/auth/editDownload/data';

import { deleteDownloadFileDetail } from 'redux/downloads/downloads.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { downloadFileTypeEnum } from 'redux/downloads/downloads.const';

const DownloadFileItem = memo(({ fileItem }) => {
    const destroyDownloadFileDetail = useAppState((state) => state.downloads.destroyDownloadFileDetail)

    const dispatcher = useAppDispatcher()

    const [isURLCopied, setIsURLCopied] = useState(false)

    const fileItemDetail = useMemo(() => {
        let fileDetail = {
            fileName: fileItem?.file_url,
            fileUrl: fileItem?.file_url,
            fileExtension: fileItem?.file_url?.split(".")?.pop(),
            type: fileItem?.type,
            previewFileUrl: downloadFileTypeEnum[fileItem?.type?.toUpperCase()]?.previewImage,
        }
        if ([downloadFileTypeEnum.IMAGE.value]?.includes(fileDetail?.type)) {
            if ([downloadFileExtensionConst.JPG.value]?.includes(fileDetail?.fileExtension)) {
                fileDetail["type"] = `${downloadFileExtensionConst.JPG.label} File`
                fileDetail["previewFileUrl"] = fileItem?.file_url
            } else if ([downloadFileExtensionConst.PNG.value]?.includes(fileDetail?.fileExtension)) {
                fileDetail["type"] = `${downloadFileExtensionConst.PNG.label} File`
                fileDetail["previewFileUrl"] = fileItem?.file_url
            } else if ([downloadFileExtensionConst.JPEG.value]?.includes(fileDetail?.fileExtension)) {
                fileDetail["type"] = `${downloadFileExtensionConst.JPEG.label} File`
                fileDetail["previewFileUrl"] = fileItem?.file_url
            } else {
                fileDetail["type"] = downloadFileTypeEnum[fileDetail?.type?.toUpperCase()]?.label
            }
        } else if (Object.values(downloadFileTypeEnum)?.map((item) => item?.value)?.includes(fileDetail?.type)) {
            fileDetail["type"] = `${downloadFileTypeEnum[fileDetail?.type?.toUpperCase()]?.label}`
        } else {
            fileDetail["type"] = `${downloadFileTypeEnum.OTHER.label}`
        }

        return fileDetail;
    }, [fileItem])

    const onHandlePreviewItem = useCallback(() => {
        window.open(fileItem?.file_url, "_blank")
    }, [fileItem])

    const onHandleCopyLink = useCallback((event) => {
        event.stopPropagation()

        navigator.clipboard.writeText(fileItem?.file_url);
        setIsURLCopied(true);
        toast.success("Copied!")
        setTimeout(() => {
            setIsURLCopied(false);
        }, 2000);
    }, [isURLCopied, fileItem?.file_url])

    const onHandleDeleteLink = useCallback((event) => {
        event.stopPropagation()

        if (destroyDownloadFileDetail?.isLoading) return;

        dispatcher(deleteDownloadFileDetail(fileItem?.id))
    }, [fileItem, destroyDownloadFileDetail?.isLoading])

    return (
        <div className={"w-full flex flex-col border border-text-200 rounded-md shadow hover:bg-back-ground-darkLight group px-2 py-1 cursor-pointer"}>
            <div
                className={"w-full flex flex-col items-center sm:items-start justify-center sm:justify-between sm:flex-row gap-3"}
                onClick={onHandlePreviewItem}
            >
                <img
                    src={fileItemDetail?.previewFileUrl}
                    className={"w-[6rem] h-[6rem] md:w-[4rem] md:h-[4rem] lg:w-[6rem] lg:h-[6rem] object-cover"}
                    alt={fileItemDetail?.fileUrl}
                />
                <div className={"flex flex-col gap-1 overflow-hidden"}>
                    <span
                        className={"font-bodyPri font-normal group-hover:text-primary-dark text-base group-hover:underline cursor-pointer line-clamp-2"}
                        onClick={onHandlePreviewItem}
                    >
                        {fileItemDetail?.fileUrl}
                    </span>
                    <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                        {fileItemDetail?.type}
                    </span>
                </div>
            </div>
            <div className={"w-full flex justify-end items-end mt-2 sm:mt-0"}>
                {Object.values(fileItemActions)?.map((itemIcon, idx) => (
                    <DownloadTooltip
                        key={idx}
                        tooltip={itemIcon?.tooltip}
                        children={
                            <span className={"space-x-2"}>
                                {[fileItemActions.COPY.value]?.includes(itemIcon?.value) &&
                                    <button className={"p-2 rounded-full hover:bg-back-ground-lightBlue group"} onClick={onHandleCopyLink}>
                                        {isURLCopied
                                            ? <AiOutlineCheckCircle className={"text-lg text-green-500"} />
                                            : <MdContentCopy className={"text-lg text-text-700 group-hover:text-primary-dark"} />
                                        }
                                    </button>
                                }
                                {[fileItemActions.DELETE.value]?.includes(itemIcon?.value) &&
                                    <button className={"p-2 rounded-full hover:bg-back-ground-lightBlue group"} onClick={onHandleDeleteLink}>
                                        <RiDeleteBin6Line className={"text-lg text-text-700 group-hover:text-red-500 cursor-pointer"} />
                                    </button>
                                }
                            </span>
                        }
                    />
                ))}
            </div>
        </div>
    )
})

export default DownloadFileItem;