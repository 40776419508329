import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import { AiOutlineClose } from 'react-icons/ai';
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiRefresh } from 'react-icons/bi';

import ToolTipView from 'components/tooltipView';

import Breadcrumb from '../../commonComponents/Breadcrumb';
import { breadcrumbItemsConst } from '../../product.data';

import { getProductDetail } from 'redux/product/product.request';

import { useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const ProductHeader = () => {

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()
    const { productId } = useParams()

    const onHandleClose = () => {
        const from = location.state?.from?.url || `${pagesInfo.TUTOR_PRODUCTS.pagePath}/courses`;
        navigate(from)
    }

    const onHandleRefreshProduct = () => {
        dispatcher(getProductDetail(productId))
    }

    return (
        <div className={"sticky top-0 z-20 bg-white px-5 py-3 flex items-center justify-between drop-shadow-sm rounded-lg"}>
            <div className={'flex flex-col items-start'}>
                <div className={"flex justify-center items-center gap-2"}>
                    <AiOutlineClose
                        onClick={onHandleClose}
                        className={'text-lg cursor-pointer text-back-ground-black'}
                    />
                    <AiOutlineShoppingCart
                        className={'text-2xl md:text-3xl text-primary-dark'}
                    />
                    <span className={'font-tagLine text-2xl'}>
                        {"Edit product"}
                    </span>
                </div>
                <div className={'flex'}>
                    <Breadcrumb items={breadcrumbItemsConst} />
                </div>
            </div>
            <ToolTipView content={"Refresh"}>
                <button
                    className={cn(
                        "p-2 flex items-center justify-center rounded-full",
                        'bg-back-ground-lightDark hover:bg-back-ground-dark text-white ease-in-out duration-200'
                    )}
                    onClick={onHandleRefreshProduct}
                >
                    <BiRefresh className={"text-xl cursor-pointer"} />
                </button>
            </ToolTipView>
        </div>
    )
}

export default ProductHeader;;