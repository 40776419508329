import { useCallback, useEffect, useMemo, useState } from 'react'
import { cn } from "utils/cn.utils"
import { Link } from 'react-router-dom'

import { FaSpinner } from "react-icons/fa";

import QuillEditor from "components/textEditor/QuillEditor";

import { createLmsCourseStudentNote } from 'redux/edulyteLms/lmsCourse/lmsCourse.request'

import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { resetAddLmsCourseStudentNoteDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice'

import { pagesInfo } from 'utils/pagesInfo'

const Editor = {
  modules: {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'size': [] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline'],
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }]
    ],
    clipboard: {
      matchVisual: false,
    }
  },
  formats: [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'blockquote', 'code-block',
    'list', 'bullet', 'indent',
    'color', 'background',
  ]
}


const AddNoteView = ({ lms_course, lms_course_student, activeSection, activeLesson, setIsAddNoteVisible, getLesson }) => {
  const { addLmsCourseStudentNoteDetail, modifyLmsCourseStudentNoteDetail, destroyLmsCourseStudentNoteDetail } = useAppState(state => state.lms.lmsCourse)

  const [noteInput, setNoteInput] = useState("")

  const dispatcher = useAppDispatcher()

  const is_loading = addLmsCourseStudentNoteDetail?.isLoading || modifyLmsCourseStudentNoteDetail?.isLoading || destroyLmsCourseStudentNoteDetail?.isLoading

  const noteText = useMemo(() => new DOMParser()?.parseFromString(`<div>${noteInput || ""}</div>`, "text/html")?.querySelector("div")?.textContent, [noteInput])

  useEffect(() => {
    if (addLmsCourseStudentNoteDetail?.data) {
      dispatcher(resetAddLmsCourseStudentNoteDetail())
      setIsAddNoteVisible(false)
    }
  }, [addLmsCourseStudentNoteDetail?.data])

  const onHandleSave = useCallback(() => {
    if (!noteText?.trim() || !activeLesson?.current?.id || !lms_course_student?.id) return;

    let requestBody = {
      lms_course_student_id: lms_course_student?.id,
      lms_course_section_lesson_id: activeLesson?.current?.id,
      note: noteInput?.trim()
    }

    dispatcher(createLmsCourseStudentNote(requestBody))
  }, [activeLesson?.current?.id, noteText])


  return (
    <div className={"space-y-3"}>
      <div className={cn(
        "group border border-text-500"
      )}>
        <div className={cn(
          "px-5 py-2 flex justify-between items-center border-b border-text-500 bg-back-ground-darkLight"
        )}>
          <div className={"flex-1 space-y-1"}>
            <p className={"font-bodyPri font-normal text-sm text-text-800 line-clamp-1"}>
              {activeSection?.current?.title}
            </p>
            <Link to={`${pagesInfo.STUDENT_LMS_COURSE.pagePath}/${lms_course?.active_slug}/learn/lesson/${activeLesson?.current?.id}`}
              className={"w-fit font-bodyPri font-medium text-md text-text-800 group-hover:text-secondary-dark group-hover:underline line-clamp-1"}
            >
              {getLesson(activeLesson?.current)?.title}
            </Link>
          </div>
        </div>
        <QuillEditor
          editorToolModules={Editor?.modules}
          editorToolFormats={Editor?.formats}
          className={cn("border-none")}
          editorText={noteInput}
          onHandleChangeText={(content) => setNoteInput(content)}
        />
      </div>
      <div className={"flex items-center justify-between gap-5"}>
        <div className={"flex items-center justify-start gap-3"}>
          <button
            className={cn(
              "px-3 py-1 bg-back-ground-darkLight hover:bg-bg-back-ground-medium",
              "font-buttons font-medium text-md text-text-700 hover:text-text-900",
              "transition-all ease-in-out duration-200 delay-100"
            )}
            onClick={() => setIsAddNoteVisible(false)}
            disabled={is_loading}
          >
            {"Cancel"}
          </button>
          <button
            className={cn(
              "px-5 py-1 flex flex-nowrap items-center gap-2 bg-primary-dark hover:opacity-90",
              "font-buttons font-medium text-md text-text-50",
              "transition-all ease-in-out duration-200 delay-100"
            )}
            onClick={onHandleSave}
            disabled={is_loading}
          >
            {is_loading ? "Loading... " : "Save"}
            {is_loading && <FaSpinner className={"text-xl animate-spin text-text-50"} />}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddNoteView