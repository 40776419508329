import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import ButtonLoader from "components/loader/ButtonLoader";
import Accordion from "components/accordion/Accordion";
import Footer1 from "components/footer/footer1/Footer1";

import { dataFAQ } from "pages/auth/preOnboard/data";
// import WaitListNotice from "pages/auth/preOnboard/components/WaitListNotice";

import { setPageInfo } from "redux/pageInfo/pageInfo.request";
import { createTutorDetail } from "redux/tutor/tutor.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setLocals, setModal } from "redux/local/local.slice";
import { resetAddTutorDetail } from "redux/tutor/tutor.slice";
import { localsConst, modalConst } from "redux/local/local.const";
import { userRoles } from "redux/auth/auth.const";

import { pagesInfo } from "utils/pagesInfo";

import { useTitle } from "hooks/useTitle";
import { BsInfoCircle } from "react-icons/bs";
import ToolTipView from "components/tooltipView";

const PreOnboardingPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { modal, locals } = useAppState((state) => state.local)
    const { addTutorDetail } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.PRE_ONBOARDING))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Pre-Onboarding | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        return () => {
            dispatcher(resetAddTutorDetail())
        }
    }, [])

    useEffect(() => {
        if (addTutorDetail?.data) {
            dispatcher(setLocals({
                ...locals,
                [localsConst.USER_ROLE.value]: userRoles?.TUTOR?.value
            }))
            const userTutorId = addTutorDetail?.data?.id
            dispatcher(resetAddTutorDetail())
            navigate(`${pagesInfo.TUTOR_ONBOARDING_S1.pagePath}/${userTutorId}/s1`)
        }
    }, [addTutorDetail?.data])

    const onSubmit = () => {
        if (addTutorDetail?.isLoading) return;

        if (!user?.user?.isEmailVerified) {
            dispatcher(setModal({
                ...modal,
                [modalConst.VALIDATION_BAR_EMAIL_VERIFY_MODAL.stateKey]: true
            }))
            return;
        }
        if (!user?.user?.isMobileNoVerified) {
            dispatcher(setModal({
                ...modal,
                [modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey]: true
            }))
            return;
        }

        if (!user?.user?.tutor?.tutorId) {
            dispatcher(createTutorDetail({ userId: user?.user?.userId }))
            return;
        }

        if (user?.user?.tutor?.tutorId) {
            dispatcher(setLocals({
                ...locals,
                [localsConst.USER_ROLE.value]: userRoles?.TUTOR?.value
            }))

            navigate(`${pagesInfo.TUTOR_ONBOARDING_S1.pagePath}/${user?.user?.tutor?.tutorId}/s1`)
        }
    }

    return (
        <div className={""}>
            <div className={"grid grid-cols-12 p-0 md:p-8 w-full bg-white"}>

                {/* header container */}
                <div className={"grid col-start-2 col-span-10 py-10 gap-12 md:col-start-3 md:col-span-8"}>
                    <div className={"w-full flex flex-col items-center justify-center gap-5"}>
                        <div className={"font-heading font-bold text-4xl text-primary-dark text-center"}>
                            {'Public Listing: Educate, Empower, Excel'}
                        </div>
                        <div className={"w-full flex flex-col items-center justify-center gap-0.5"}>
                            <div className={"flex items-center gap-1 font-bodyPri font-medium text-base text-text-900 text-center leading-6 px-0 md:px-8 lg:px-32"}>
                                {"Provide us with some details to craft your professional profile."}
                                <ToolTipView content="As an unverified educator, you can utilise our software to offer your services with ease. Enjoy the freedom of managing your tutor profile and classes privately, away from public search results on Edulyte or search engines. What's even better? Our platform fee is just 10% of your sales. Easily share links to your expertly crafted profile or classes with anyone you want.">
                                    <div>
                                        <BsInfoCircle className="inline text-md text-text-900 hover:text-text-900 cursor-pointer" />
                                    </div>
                                </ToolTipView>
                            </div>
                            <span className={"font-bodyPri font-normal text-sm text-center tracking-wide text-text-700"}>
                                {"Preview your profile on the next page and be amazed!"}
                            </span>
                        </div>
                    </div>

                    {/* wait list notice */}
                    {/* {(isWaitingListEnabled) &&
                        <div className={"flex flex-col justify-center md:px-20 lg:px-40"}>
                            <WaitListNotice />
                        </div>
                    } */}

                    {/* continue button */}
                    <div className={"w-full flex flex-col items-start justify-center gap-5 mt-5"}>
                        <div className={"w-full flex justify-center items-center"}>
                            <span className={cn("px-6 sm:px-12 py-4 rounded-lg shadow ease-in-out duration-200",
                                "font-bodyPri font-medium text-text-50 text-lg text-center tracking-wide uppercase",
                                "bg-secondary-dark hover:bg-primary-dark cursor-pointer",
                            )}
                                onClick={onSubmit}
                            >
                                {(addTutorDetail?.isLoading) &&
                                    <ButtonLoader isLoading={addTutorDetail?.isLoading} />
                                }
                                {(!addTutorDetail?.isLoading && (!user?.user?.tutor?.tutorId)) && "Continue Your Application"}
                                {(!addTutorDetail?.isLoading && user?.user?.tutor?.tutorId) && "Continue"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* video container */}
            {/* <div className={"col-start-2 col-span-10 md:col-start-1 md:col-span-12 bg-white shadow-xl"}>
                <EdulyteVideoGuides />
            </div> */}

            {/* FAQ's container */}
            <div className={"grid grid-cols-12 gap-3 bg-back-ground-light"}>
                <div className={"col-start-1 col-span-full md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 py-10"}>
                    <Accordion
                        title={'Frequently Asked Questions?'}
                        dataFAQ={dataFAQ}
                    />
                </div>
            </div>
            {/* <Footer1 /> */}
        </div>
    )
}

export default PreOnboardingPage