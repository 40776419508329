import { useCallback, useEffect, memo, useMemo } from 'react';

import { IoIosArrowBack } from 'react-icons/io';
import { BsInfoCircle } from 'react-icons/bs';

import { toast } from 'react-toastify';

import ButtonLoader from 'components/loader/button-loader';
import ToolTipView from 'components/tooltipView';
import QuillEditor from 'components/textEditor/QuillEditor';
import { Toggle } from 'components/common-components/Toggle';

import { appointmentDetailTooltips, setAppointmentDetailPayload, validateAppointmentDetailPayload } from 'pages/auth/editAppointment/data';
import SaveAndCancelBtn from 'pages/auth/editAppointment/commonComponents/SaveAndCancelBtn';

import SessionDuration from 'pages/auth/editAppointment/detail/edit/basicDetail/SessionDuration';
import Location from 'pages/auth/editAppointment/detail/edit/basicDetail/Location';
import Schedule from 'pages/auth/editAppointment/detail/edit/basicDetail/Schedule';

import { updateAppointmentDetail } from 'redux/appointment/appointment.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModifyAppointmentDetailPayload } from 'redux/appointment/appointment.slice';
import { appointmentStatusEnum, appointmentVisibilityEnum } from 'redux/appointment/appointment.const';

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';

const Editor = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ],
        clipboard: {
            matchVisual: false,
        }
    },
    formats: [
        'bold', 'italic', 'underline', 'blockquote', 'code-block',
        'list', 'bullet', 'indent',
    ]
}

const inputLengths = {
    title: {
        minLength: 10,
        maxLength: 120
    },
    subtitle: {
        minLength: 10,
        maxLength: 500
    }
}

const EditBasicDetail = memo(({ onHandleCloseSection }) => {
    const { user } = useAppState((state) => state.user)
    const { userAppointmentDetail, modifyAppointmentDetail } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()

    const isSaveBtnDisabled = useMemo(() => {
        const isDetailSimilarOrValid = validateAppointmentDetailPayload(userAppointmentDetail?.data?.result, modifyAppointmentDetail?.payload)

        return isDetailSimilarOrValid;
    }, [modifyAppointmentDetail?.payload, userAppointmentDetail?.data])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (!isSaveBtnDisabled) {
                const message = "Are you sure you want to leave?";
                event.preventDefault();
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isSaveBtnDisabled]);

    const onHandleGoBack = useCallback(() => {
        if (!isSaveBtnDisabled) {
            onHandleSaveAndCloseBtn()
        } else {
            onHandleCancelBtn()
        }
    }, [isSaveBtnDisabled, userAppointmentDetail?.data])

    const onHandleChangeText = useCallback((key, value) => {
        dispatcher(setModifyAppointmentDetailPayload({
            ...modifyAppointmentDetail?.payload,
            [key]: value
        }))
    }, [modifyAppointmentDetail?.payload])

    const onHandleSaveAndCloseBtn = useCallback(() => {
        if (modifyAppointmentDetail?.isLoading || isSaveBtnDisabled) return;

        if (modifyAppointmentDetail?.payload?.duration < 30) {
            toast.warn("The duration must be at least 30 minutes.")
        }
        const body = {
            title: modifyAppointmentDetail?.payload?.title,
            subtitle: modifyAppointmentDetail?.payload?.subtitle,
            duration: modifyAppointmentDetail?.payload?.duration,
            availability_id: modifyAppointmentDetail?.payload?.availability?.id,
            instant_session_schedule: modifyAppointmentDetail?.payload?.instant_session_schedule
        }
        // if (modifyAppointmentDetail?.payload?.subtitle) {
        //     body["subtitle"] = modifyAppointmentDetail?.payload?.subtitle
        // }
        if (modifyAppointmentDetail?.payload?.description) {
            body["description"] = modifyAppointmentDetail?.payload?.description
        }
        if (modifyAppointmentDetail?.payload?.visibility) {
            body["visibility"] = modifyAppointmentDetail?.payload?.visibility
        }
        dispatcher(updateAppointmentDetail(modifyAppointmentDetail?.payload?.id, body))
    }, [modifyAppointmentDetail])

    const onHandleCancelBtn = useCallback(() => {
        const payload = setAppointmentDetailPayload(userAppointmentDetail?.data?.result)
        dispatcher(setModifyAppointmentDetailPayload(payload))
        onHandleCloseSection()
    }, [userAppointmentDetail?.data])

    const onHandleNavigateToAppointment = useCallback(() => {
        if ([appointmentStatusEnum.PUBLISHED.value]?.includes(userAppointmentDetail?.data?.result?.status)) {
            window.open(`${window.location.host}${pagesInfo.APPOINTMENT.pagePath}/${userAppointmentDetail?.data?.result?.slug}`, "_blank")
        }
    }, [userAppointmentDetail?.data])

    return (
        <div className={"w-full h-full flex flex-col"}>
            <div className={"h-28 p-5 flex flex-col gap-3 md:gap-5 bg-white border-b border-text-300 sticky top-0 z-30 font-bodyPri"}>
                {modifyAppointmentDetail?.isLoading &&
                    <div className={"w-fit"}>
                        <ButtonLoader isLoading={modifyAppointmentDetail?.isLoading} dotStyles={{ backgroundColor: "#9e9e9e" }} />
                    </div>
                }
                {!modifyAppointmentDetail?.isLoading &&
                    <div className={"w-fit flex items-center justify-start gap-2 cursor-pointer group"} onClick={onHandleGoBack}>
                        <IoIosArrowBack className={"text-xl text-text-800 group-hover:text-secondary-dark"} />
                        <span className={"font-bodyPri font-normal text-text-900 text-base underline tracking-wide line-clamp-1 group-hover:text-secondary-dark cursor-pointer"}>
                            {"Appointment Summary"}
                        </span>
                    </div>
                }
                <span className={"font-bold text-lg text-text-900 tracking-wide"}>
                    {"Appointment details"}
                </span>
            </div>

            <div className={cn(
                "p-5 w-full space-y-5 md:space-y-8 flex-grow overflow-y-auto",
                "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-light",
            )}
            >
                {/* slug */}
                {userAppointmentDetail?.data?.result?.slug &&
                    <div className={"w-full flex items-center justify-start gap-2 line-clamp-1"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Slug: "}
                        </span>
                        <span className={cn(
                            "font-bodyPri font-normal text-text-900 text-base cursor-text",
                            [appointmentStatusEnum.PUBLISHED.value]?.includes(userAppointmentDetail?.data?.result?.status) && "!text-primary-dark hover:underline cursor-pointer"
                        )}
                            onClick={onHandleNavigateToAppointment}
                        >
                            {userAppointmentDetail?.data?.result?.slug}
                        </span>
                    </div>
                }

                {/* title */}
                <div className={"w-full flex flex-col gap-2"}>
                    <div className={"flex items-center justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Title *"}
                        </span>
                        <ToolTipView content={appointmentDetailTooltips.title}>
                            <button className={""}>
                                <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                            </button>
                        </ToolTipView>
                    </div>
                    <div className={"w-full flex flex-col gap-0.5"}>
                        <input
                            type={"text"}
                            name={"title"}
                            className={cn(
                                "w-full h-10 px-3 text-text-900 bg-white rounded bg-transparent outline-none placeholder:text-text-500",
                                "border border-divider-lightDark hover:border-primary-main font-bodyPri cursor-text",
                                "focus:ring-2 focus:ring-primary-main focus:border-none transition duration-200",
                                (!modifyAppointmentDetail?.payload?.title || (modifyAppointmentDetail?.payload?.title?.length < 10) || (modifyAppointmentDetail?.payload?.title?.length > 120)) && "focus:ring-red-500 border-red-500 hover:border-red-500",
                            )}
                            placeholder={"E.g., Consultation with Dr. Smith"}
                            autoFocus={true}
                            onChange={(event) => onHandleChangeText(event?.target?.name, event?.target?.value)}
                            value={modifyAppointmentDetail?.payload?.title}
                            minLength={inputLengths.title.minLength}
                            maxLength={inputLengths.title.maxLength}
                        />
                        <div className={"w-full flex justify-end"}>
                            <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                                {`${modifyAppointmentDetail?.payload?.title ? modifyAppointmentDetail?.payload?.title?.length : 0}/${inputLengths.title.maxLength}`}
                            </span>
                        </div>
                    </div>
                </div>

                {/* subTitle */}
                <div className={"w-full flex flex-col gap-2"}>
                    <div className={"flex items-center justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Sub Header"}
                        </span>
                        <ToolTipView content={appointmentDetailTooltips.subtitle}>
                            <button className={""}>
                                <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                            </button>
                        </ToolTipView>
                    </div>
                    <div className={"w-full flex flex-col gap-0.5"}>
                        <textarea
                            type={"text"}
                            name={"subtitle"}
                            className={cn(
                                "w-full px-3 py-2 text-text-900 bg-white rounded bg-transparent outline-none placeholder:text-text-500",
                                "border border-divider-lightDark hover:border-primary-main font-bodyPri cursor-text",
                                "focus:ring-2 focus:ring-primary-main focus:border-none transition duration-200",
                                (modifyAppointmentDetail?.payload?.subtitle && (modifyAppointmentDetail?.payload?.subtitle?.length < 10)) && "focus:ring-red-500 border-red-500 hover:border-red-500",
                            )}
                            placeholder={"E.g., One-on-one expert consultation"}
                            rows={3}
                            onChange={(event) => onHandleChangeText(event?.target?.name, event?.target?.value)}
                            value={modifyAppointmentDetail?.payload?.subtitle}
                            minLength={inputLengths.subtitle.minLength}
                            maxLength={inputLengths.subtitle.maxLength}
                        />
                        <div className={"w-full flex justify-end"}>
                            <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                                {`${modifyAppointmentDetail?.payload?.subtitle ? modifyAppointmentDetail?.payload?.subtitle?.length : 0}/${inputLengths.subtitle.maxLength}`}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Duration */}
                <SessionDuration />

                {/* location */}
                <Location />

                {/* schedule */}
                <Schedule />

                {/* Description */}
                <div className={"w-full flex flex-col gap-2"}>
                    <div className={"w-full flex items-center justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Description / instructions"}
                        </span>
                        <ToolTipView content={appointmentDetailTooltips.description}>
                            <button className={""}>
                                <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                            </button>
                        </ToolTipView>
                    </div>
                    <QuillEditor
                        editorToolModules={Editor?.modules}
                        editorToolFormats={Editor?.formats}
                        placeholder={"Write a summary and any details your invitee should know."}
                        className={"w-full focus:outline-none focus-within:border-primary-main border-1"}
                        editorText={modifyAppointmentDetail?.payload?.description}
                        onHandleChangeText={(content) => onHandleChangeText("description", content)}
                    />
                </div>

                {/* visibility */}
                <div className={"w-full flex items-center justify-between gap-3"}>
                    <div className={"w-full flex items-center justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Visibility"}
                        </span>
                        <ToolTipView content={appointmentDetailTooltips.visibility}>
                            <button className={""}>
                                <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                            </button>
                        </ToolTipView>
                    </div>
                    <ToolTipView content={(modifyAppointmentDetail?.payload?.visibility === appointmentVisibilityEnum.PUBLIC.value)
                        ? appointmentVisibilityEnum.PUBLIC.label
                        : appointmentVisibilityEnum.PRIVATE.label
                    }>
                        <button className={""}>
                            <Toggle
                                value={(modifyAppointmentDetail?.payload?.visibility === appointmentVisibilityEnum.PUBLIC.value) ? true : false}
                                onChange={(v) => onHandleChangeText("visibility", v ? appointmentVisibilityEnum.PUBLIC.value : appointmentVisibilityEnum.PRIVATE.value)}
                            />
                        </button>
                    </ToolTipView>
                </div>

                {/* Auto-Confirm Sessions */}
                <div className={"w-full flex items-center justify-between gap-3"}>
                    <div className={"w-full flex items-center justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Auto-Confirm Sessions"}
                        </span>
                        <ToolTipView content={appointmentDetailTooltips.autoConfirmSessions}>
                            <button className={""}>
                                <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                            </button>
                        </ToolTipView>
                    </div>
                    <Toggle
                        value={modifyAppointmentDetail?.payload?.instant_session_schedule}
                        onChange={(v) => onHandleChangeText("instant_session_schedule", v)}
                    />
                </div>
            </div>

            <div className={"flex flex-col items-end justify-end gap-5"}>
                <div className={"w-full border-b border-text-300"} />
                <div className={"px-5 pb-3"}>
                    <SaveAndCancelBtn
                        isSaveBtnLoading={modifyAppointmentDetail?.isLoading}
                        isSaveBtnDisabled={isSaveBtnDisabled}
                        saveBtnTooltip={"No changes detected. Nothing to save"}
                        onHandleCancelBtn={onHandleCancelBtn}
                        onHandleSaveAndCloseBtn={onHandleSaveAndCloseBtn}
                    />
                </div>
            </div>
        </div>
    )
});

export default EditBasicDetail;