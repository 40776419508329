import { useCallback, useState } from 'react';

import { toast } from 'react-toastify';

import { Reorder } from 'framer-motion';

import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';

import ButtonLoader from 'components/loader/ButtonLoader';
import ComponentLoader from 'components/loader/ComponentLoader';

import ShowMoreButton from 'pages/auth/edulyteLms/commonComponents/lmsButtons/ShowMoreButton';
import AddBorderButton from '../../../../../../commonComponents/AddBorderButton';

import { courseDetailNavigationOptions, lmsCourseTooltips } from '../../../../editLMSCourse.data';
import PreviousNextButtons from '../../../../../../commonComponents/lmsButtons/PreviousNextButtons';
import CourseLearningItem from './CourseLearningItem';

import { createLmsCourseLearningDetail, updateLmsCourseLearningListOrder } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setLmsCourseDetailData } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';

import { isOrderUpdated } from 'utils/generators.utils';

const CourseLearning = () => {
    const { lmsCourseDetail, addLmsCourseLearningDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [isAddLearningEnabled, setIsAddLearningEnabled] = useState(false)
    const [isShowAddLearningButton, setIsShowAddLearningButton] = useState(lmsCourseDetail?.data?.what_you_will_learns?.length > 0)
    const [learningPayload, setLearningPayload] = useState("")
    const [isLearningOrderUpdated, setIsLearningOrderUpdated] = useState(false)
    const [minLearningCount, setMinLearningCount] = useState(5)

    const onHandleAddLearning = useCallback(() => {
        setIsShowAddLearningButton(false)
        setIsAddLearningEnabled(true)
        setLearningPayload("")
    }, [isShowAddLearningButton, isAddLearningEnabled, learningPayload])

    const onHandleReorderGroup = (newOrder) => {
        if (newOrder) {
            const isOrderModified = isOrderUpdated(lmsCourseDetail?.data?.what_you_will_learns, newOrder)
            if (!isOrderModified) {
                setIsLearningOrderUpdated(false)
                return;
            }
            setIsLearningOrderUpdated(true)
            dispatcher(setLmsCourseDetailData({
                ...lmsCourseDetail?.data,
                what_you_will_learns: newOrder
            }))
        }
    }

    const onHandleUpdateLearningsOrder = () => {
        if (isLearningOrderUpdated && (lmsCourseDetail?.data?.what_you_will_learns?.length > 0)) {
            const body = {
                order: lmsCourseDetail?.data?.what_you_will_learns?.map((courseLearning) => ({
                    id: courseLearning?.id
                }))
            }
            dispatcher(updateLmsCourseLearningListOrder(body, { lms_course_id: lmsCourseDetail?.data?.id }))
            setIsLearningOrderUpdated(false)
        }
    }

    const onHandleSaveLearning = useCallback(() => {
        if (addLmsCourseLearningDetail?.isLoading) return;

        if (!learningPayload) {
            toast.warn("Please add Learning!")
            return;
        }

        dispatcher(createLmsCourseLearningDetail({
            lms_course_id: lmsCourseDetail?.data?.id,
            item: learningPayload
        }))
        setIsShowAddLearningButton(true)
        setIsAddLearningEnabled(false)
        setLearningPayload("")
    }, [learningPayload])

    const onHandleResetLearning = useCallback(() => {
        setLearningPayload("")
        if (lmsCourseDetail?.data?.what_you_will_learns?.length > 0) {
            setIsShowAddLearningButton(true)
        }
        setIsAddLearningEnabled(false)
    }, [])

    return (
        <div className={"w-full flex flex-col justify-between gap-5 rounded-lg overflow-hidden p-5 bg-white"}>
            <div className={"w-full flex flex-col gap-5"}>
                <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                    <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                        {"What students will learn (bullet points)"}
                    </span>
                </div>
                <div className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                    {lmsCourseTooltips?.studentLearning}
                </div>
                {(!lmsCourseDetail?.data?.what_you_will_learns || (lmsCourseDetail?.data?.what_you_will_learns?.length === 0) && !isAddLearningEnabled) &&
                    <AddBorderButton
                        btnTitle={"Add Learning(s)"}
                        onHandleAdd={onHandleAddLearning}
                    />
                }

                {(lmsCourseDetail?.data?.what_you_will_learns?.length > 0) &&
                    <div className={""}>
                        <Reorder.Group
                            className={'w-full flex flex-col gap-3'}
                            values={lmsCourseDetail?.data?.what_you_will_learns}
                            onReorder={onHandleReorderGroup}
                        >
                            {lmsCourseDetail?.data?.what_you_will_learns?.slice(0, minLearningCount)?.map((learningItem, index) => (
                                <Reorder.Item
                                    key={learningItem?.id}
                                    value={learningItem}
                                    onDragEnd={onHandleUpdateLearningsOrder}
                                >
                                    <CourseLearningItem
                                        key={learningItem?.id}
                                        index={index}
                                        learningItem={learningItem}
                                    />
                                </Reorder.Item>
                            ))}
                        </Reorder.Group>
                        <ShowMoreButton
                            isShowLessBtn={(lmsCourseDetail?.data?.what_you_will_learns?.length > 5) && (lmsCourseDetail?.data?.what_you_will_learns?.length === minLearningCount)}
                            isShowMoreBtn={lmsCourseDetail?.data?.what_you_will_learns?.length > minLearningCount}
                            showLessText={"Show less"}
                            showMoreText={"Show all Learnings"}
                            onHandleToggleBtn={() => setMinLearningCount(prevCount => (prevCount === 5) ? lmsCourseDetail?.data?.what_you_will_learns?.length : 5)}
                        />
                    </div>
                }

                {addLmsCourseLearningDetail?.isLoading &&
                    <ComponentLoader isLoading={addLmsCourseLearningDetail?.isLoading} />
                }

                {(isShowAddLearningButton && (lmsCourseDetail?.data?.what_you_will_learns?.length > 0)) &&
                    <div className={"w-full flex items-center justify-center"}>
                        <div
                            className={cn(
                                "w-fit flex items-center justify-center cursor-pointer",
                                "border border-divider-lightDark rounded-md px-2 py-1",
                                "font-bodyPri font-medium text-base text-text-700",
                                "hover:text-text-900 hover:border-divider-dark"
                            )}
                            onClick={onHandleAddLearning}
                        >
                            <MdAdd className={"text-xl"} />
                            <span className={"whitespace-nowrap"}>
                                {"Add Learning(s)"}
                            </span>
                        </div>
                    </div>
                }
                {isAddLearningEnabled &&
                    <div className={"w-full flex flex-col gap-5"}>
                        <div className={"w-full flex flex-col gap-2"}>
                            <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                                {"Learning(s):"}
                            </span>
                            <input
                                type={"text"}
                                placeholder={"Write learning(s) here ..."}
                                className={cn(
                                    "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                                    "h-fit px-3 py-2 border border-divider-lightDark rounded-lg",
                                    "font-bodyPri font-normal text-text-900 text-base tracking-wide first-letter:capitalize",
                                    "focus:outline-none focus:border-primary-main"
                                )}
                                autoFocus={true}
                                value={learningPayload}
                                onChange={(event) => setLearningPayload(event?.target?.value)}
                            />
                        </div>
                        <div className={"flex items-center justify-start gap-3"}>
                            <div
                                onClick={onHandleSaveLearning}
                                className={cn(
                                    "flex item-center justify-center w-20 py-1 rounded-md cursor-pointer",
                                    "font-buttons font-normal text-base text-text-50 bg-primary-main",
                                    "hover:bg-primary-dark"
                                )}>
                                {addLmsCourseLearningDetail?.isLoading &&
                                    <ButtonLoader isLoading={addLmsCourseLearningDetail?.isLoading} />
                                }
                                {!addLmsCourseLearningDetail?.isLoading &&
                                    <span className={""}>
                                        {"Save"}
                                    </span>
                                }
                            </div>
                            <div
                                onClick={onHandleResetLearning}
                                className={cn(
                                    "flex item-center justify-center w-20 py-1 rounded-md cursor-pointer",
                                    "font-buttons font-normal text-base text-text-800 bg-back-ground-darkLight",
                                    "hover:bg-back-ground-medium hover:text-text-900"
                                )}>
                                <span className={""}>
                                    {"Reset"}
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <PreviousNextButtons
                previousBtn={courseDetailNavigationOptions?.VIDEO?.to}
                nextBtn={courseDetailNavigationOptions?.FAQS?.to}
            />
        </div>
    )
}

export default CourseLearning;