import { createSlice } from "@reduxjs/toolkit";
import { LINK_TREE_INITIAL_STATE } from "./linkTree.initialState";

const linkInBio = createSlice({
    name: "linkInBio",
    initialState: LINK_TREE_INITIAL_STATE,
    reducers: {
        setAddLinkInBioLoading: (state, { payload }) => {
            state.createLinkTree.isLoading = payload
        },
        setAddLinkInBioData: (state, { payload }) => {
            state.createLinkTree.data = payload
            state.createLinkTree.errorMsg = LINK_TREE_INITIAL_STATE.createLinkTree.errorMsg
        },
        seAddLinkInBioErrorMsg: (state, { payload }) => {
            state.createLinkTree.errorMsg = payload
            state.createLinkTree.data = LINK_TREE_INITIAL_STATE.createLinkTree.data
        },
        setClearAddLinkInBio: (state) => {
            state.createLinkTree = LINK_TREE_INITIAL_STATE.createLinkTree
        },

        setUpdateLinkLoading: (state, { payload }) => {
            state.updateLink.isLoading = payload
        },
        setUpdateLinkData: (state, { payload }) => {
            state.updateLink.data = payload
            state.updateLink.errorMsg = LINK_TREE_INITIAL_STATE.updateLink.errorMsg
        },
        seUpdateLinkErrorMsg: (state, { payload }) => {
            state.updateLink.errorMsg = payload
            state.updateLink.data = LINK_TREE_INITIAL_STATE.updateLink.data
        },
        setClearUpdateLink: (state) => {
            state.updateLink = LINK_TREE_INITIAL_STATE.updateLink
        },

        setDeleteLinkLoading: (state, { payload }) => {
            state.deleteLink.isLoading = payload
        },
        seDeleteLinkErrorMsg: (state, { payload }) => {
            state.deleteLink.errorMsg = payload
        },
        setClearDeleteLink: (state) => {
            state.deleteLink = LINK_TREE_INITIAL_STATE.deleteLink
        },

        setAddLinkLoading: (state, { payload }) => {
            state.addLink.isLoading = payload
        },
        setAddLinkData: (state, { payload }) => {
            state.addLink.data = payload
            state.addLink.errorMsg = LINK_TREE_INITIAL_STATE.addLink.errorMsg
        },
        seAddLinkErrorMsg: (state, { payload }) => {
            state.addLink.errorMsg = payload
            state.addLink.data = LINK_TREE_INITIAL_STATE.addLink.data
        },
        setClearAddLink: (state) => {
            state.addLink = LINK_TREE_INITIAL_STATE.addLink
        },

        setReorderLinkLoading: (state, { payload }) => {
            state.reorderLink.isLoading = payload
        },
        seReorderLinkErrorMsg: (state, { payload }) => {
            state.reorderLink.errorMsg = payload
        },
        setClearReorderLink: (state) => {
            state.reorderLink = LINK_TREE_INITIAL_STATE.reorderLink
        },

        setGetLinkTreeLoading: (state, { payload }) => {
            state.getLinkTree.isLoading = payload
        },
        setGetLinkTreeData: (state, { payload }) => {
            state.getLinkTree.data = payload
            state.getLinkTree.errorMsg = LINK_TREE_INITIAL_STATE.getLinkTree.errorMsg
        },
        seGetLinkTreeMessage: (state, { payload }) => {
            state.getLinkTree.errorMsg = payload
            state.getLinkTree.data = LINK_TREE_INITIAL_STATE.getLinkTree.data
        },
        setClearGetLinkTree: (state) => {
            state.getLinkTree = LINK_TREE_INITIAL_STATE.getLinkTree
        },

        setUpdateLinkTreeLoading: (state, { payload }) => {
            state.updateLinkTree.isLoading = payload
        },
        setUpdateLinkTreeData: (state, { payload }) => {
            state.updateLinkTree.data = payload
            state.updateLinkTree.errorMsg = LINK_TREE_INITIAL_STATE.updateLinkTree.errorMsg
        },
        seUpdateLinkTreeErrorMsg: (state, { payload }) => {
            state.updateLinkTree.errorMsg = payload
            state.updateLinkTree.data = LINK_TREE_INITIAL_STATE.updateLinkTree.data
        },
        setClearUpdateLinkTree: (state) => {
            state.updateLinkTree = LINK_TREE_INITIAL_STATE.updateLinkTree
        },
    }
})

export const {
    setAddLinkInBioLoading,
    setAddLinkInBioData,
    seAddLinkInBioErrorMsg,
    setClearAddLinkInBio,

    setUpdateLinkLoading,
    setUpdateLinkData,
    seUpdateLinkErrorMsg,
    setClearUpdateLink,

    setDeleteLinkLoading,
    seDeleteLinkErrorMsg,
    setClearDeleteLink,

    setAddLinkLoading,
    setAddLinkData,
    seAddLinkErrorMsg,
    setClearAddLink,

    setReorderLinkLoading,
    seReorderLinkErrorMsg,
    setClearReorderLink,

    setGetLinkTreeLoading,
    setGetLinkTreeData,
    seGetLinkTreeMessage,
    setClearGetLinkTree,

    setUpdateLinkTreeLoading,
    setUpdateLinkTreeData,
    seUpdateLinkTreeErrorMsg,
    setClearUpdateLinkTree,
} = linkInBio.actions

export default linkInBio.reducer