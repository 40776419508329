import { Link } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { GiSandsOfTime } from "react-icons/gi";
import { pagesInfo } from "utils/pagesInfo";

const WaitListNotice = () => {
    return (
        <div className={cn(
            "flex md:items-center justify-center gap-5 md:gap-8",
            "p-5 border-2 border-primary-dark bg-accent-light rounded-3xl"
        )}>
            <div className={""}>
                <GiSandsOfTime className={"text-8xl text-primary-dark"} />
            </div>
            <div className={"flex flex-col items-start justify-start gap-3 md:gap-5"}>
                <div className={"w-full font-bodySec font-medium text-primary-dark text-xl text-center md:text-2xl tracking-wide"}>
                    {"You're now on waitlist!"}
                </div>
                <div className={"flex flex-col items-start justify-start md:gap-1"}>
                    <span className={"w-full font-bodyPri font-normal text-text-900 text-sm text-center tracking-wide lg:whitespace-nowrap"}>
                        {"We will get back to you when we start recruiting."}
                    </span>
                    {/* <span className={"w-full font-bodyPri font-normal text-text-900 text-sm text-start md:text-center tracking-wide lg:whitespace-nowrap"}>
                        {"Please add your details in the meantime."}
                    </span> */}
                </div>
                <Link
                    to={pagesInfo?.DASHBOARD?.pagePath}
                    className={"w-full flex items-center justify-center"}
                >
                    <div className={cn(
                        "px-16 py-1 bg-primary-dark rounded-full whitespace-nowrap hover:bg-secondary-dark",
                        "font-bodyPri font-normal text-text-50 text-sm cursor-pointer"
                    )}>
                        {"Dashboard"}
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default WaitListNotice;