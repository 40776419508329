import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
    PageContentStyle
} from "pages/global/resetPassword/ResetPassword.style";

import PageLoader from "components/loader/PageLoader"

import HeaderSection from "pages/global/resetPassword/components/HeaderSection";
import MessageSection from "pages/global/resetPassword/components/MessageSection";
import ResetPassword from "pages/global/resetPassword/components/ResetPassword";

import { useTitle } from 'hooks/useTitle';
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getPasswordReset } from "redux/auth/auth.request";

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const ResetPasswordPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { passwordReset } = useAppState((state) => state.auth)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.RESET_PASSWORD))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Reset Your New Password | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        let query = generateQueryParams(location.search)
        dispatcher(getPasswordReset({ token: query?.token }))
    }, [location?.search])

    if (passwordReset?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (passwordReset?.message) {
        return (
            <PageContentStyle>
                <HeaderSection />
                <div className={"container mx-auto mt-40 flex justify-center items-center"}>
                    <MessageSection />
                </div>
            </PageContentStyle>
        )
    }

    return passwordReset?.data ? (
        <PageContentStyle>
            <HeaderSection />
            <div className={"container mx-auto mt-40 flex justify-center items-center"}>
                <ResetPassword />
            </div>
        </PageContentStyle>
    ) : null
}

export default ResetPasswordPage