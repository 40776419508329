import { useCallback, useEffect, memo } from 'react';

import { cn } from "utils/cn.utils";

import ComponentLoader from 'components/loader/ComponentLoader';
import StatusButton from 'components/common-components/StatusButton';
import Pagination from 'components/pagination/Pagination';

import LMSTable from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTable';
import { articleTableHeaderConst, libraryTabConst } from '../data';
import LibraryActionButtons from '../components/LibraryActionButtons';

import { getLmsArticleList } from 'redux/edulyteLms/lmsArticle/lmsArticle.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setLocalTabs, setModal } from 'redux/local/local.slice';
import { resetLmsArticleList } from 'redux/edulyteLms/lmsArticle/lmsArticle.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsArticleStatusEnum } from 'redux/edulyteLms/lmsArticle/lmsArticle.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';

import { pagesInfo } from 'utils/pagesInfo';

const ArticleTab = () => {
  const { modal, localTabs } = useAppState((state) => state.local)
  const { lmsArticleList } = useAppState((state) => state.lms.lmsArticle)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getLmsArticleList({ page: 1, records: 5 }))

    return () => {
      dispatcher(resetLmsArticleList())
    }
  }, [])

  const onHandleCreateArticle = useCallback(() => {
    dispatcher(setModal({
      ...modal,
      [modalConst.CREATE_LMS_ARTICLE_MODAL.stateKey]: true,
      [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false
    }))
  }, [modal])

  const onHandlePageChange = useCallback((page) => {
    dispatcher(getLmsArticleList({ page: page, records: 5 }))
  }, [])

  const onHandleEditArticle = useCallback((articleItem) => {
    window.open(`${window.location.host}${pagesInfo.EDIT_LMS_ARTICLE.pagePath}/${articleItem?.article_id}/edit`, "_self")
  }, [])

  const onHandleInsertArticle = useCallback((articleItem) => {
    if (![lmsArticleStatusEnum?.PUBLISHED?.value]?.includes(articleItem?.article_setting?.status) || !articleItem?.title) return;

    dispatcher(setLocalTabs({
      ...localTabs,
      tabItem: {
        ...localTabs?.tabItem,
        tabType: libraryTabConst.ARTICLES.value,
        payload: {
          ...localTabs?.tabItem?.payload,
          articleId: articleItem?.article_id
        }
      }
    }))
  }, [localTabs])

  const ArticleTitleContainer = memo(({ article }) => {
    return (
      <div
        className={'flex flex-col items-start justify-start gap-1'}
        title={article?.title && article?.title}
      >
        <span className='font-bodyPri font-normal text-text-900 text-base truncate tracking-wide'>
          {article?.title
            ? (article?.title?.length > 20)
              ? article?.title?.slice(0, 20) + " ..."
              : article?.title
            : "-"
          }
        </span>
      </div>
    )
  });

  const tableRowsConst = lmsArticleList?.data?.results?.map((article) => ([
    <ArticleTitleContainer article={article} />,
    <StatusButton
      status={article?.article_setting?.status}
      className={`bg-${lmsArticleStatusEnum[article?.article_setting?.status?.toUpperCase()]?.lightColor} text-${lmsArticleStatusEnum[article?.article_setting?.status?.toUpperCase()]?.darkColor}`}
    />,
    dayjs(article?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
    <LibraryActionButtons
      editBtnTooltip={"Edit Article"}
      insertBtnTooltip={"Insert Article"}
      isEnterBtnDisabled={![lmsArticleStatusEnum?.PUBLISHED?.value]?.includes(article?.article_setting?.status) || !article?.title}
      onHandleEditBtn={() => onHandleEditArticle(article)}
      onHandleInsertBtn={() => onHandleInsertArticle(article)}
    />,
  ]))

  return (
    <div className={"flex flex-col w-full px-5 py-3 bg-white gap-2"}>
      <div className='flex items-center justify-between gap-3'>
        <span className={"font-bodyPri font-medium text-text-800 text-md tracking-wide"}>
          {"List of Articles"}
        </span>
        <div
          className={cn(
            'w-fit px-5 py-1 flex items-center justify-center border border-back-ground-black rounded ease-in-out duration-200 group cursor-pointer',
            'hover:bg-secondary-dark hover:text-white hover:border-secondary-dark'
          )}
          onClick={onHandleCreateArticle}
        >
          <span className={"font-bodyPri font-normal text-text-800 text-base group-hover:text-white"}>
            {"Create new article"}
          </span>
        </div>

      </div>
      {lmsArticleList?.isLoading &&
        <ComponentLoader isLoading={lmsArticleList?.isLoading} className={"w-full"} />
      }
      {lmsArticleList?.message &&
        <div className={"flex items-center justify-center"}>
          <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
            {lmsArticleList?.message}
          </span>
        </div>
      }
      {(!lmsArticleList?.isLoading && lmsArticleList?.data) &&
        <LMSTable
          headers={articleTableHeaderConst}
          rows={tableRowsConst}
          alternateRowColor={"bg-sky-100"}
          rowHeaderColor={"bg-sky-200"}
        />
      }
      {(!lmsArticleList?.isLoading && (lmsArticleList?.data?.records > 0) && (lmsArticleList?.data?.totalPages > 1)) &&
        <div className={'w-full flex items-center justify-center'}>
          <Pagination
            page={lmsArticleList?.data?.page}
            isScrollToTop={false}
            totalPages={lmsArticleList?.data?.totalPages}
            onChangePage={(page) => onHandlePageChange(page)}
          />
        </div>
      }
    </div>
  )
}

export default ArticleTab;