import { useState, memo, useMemo } from 'react';

import { IoIosArrowForward } from 'react-icons/io';
// import { StarBorder } from '@mui/icons-material';

import { sortSessionUserList } from './sessionDetailModal.data';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
// import { setModal } from 'redux/local/local.slice';
// import { setAddUserReviewDetailPayload, setClearAddUserReviewDetail } from 'redux/review/review.slice';
// import { userRoles } from 'redux/auth/auth.const';
// import { modalConst } from 'redux/local/local.const';
import { sessionUserTypeEnum } from 'redux/session/session.const';

import { cn } from "utils/cn.utils";

const UserProfileInfo = memo(({ userProfileInfo, sessionUserInfo }) => {
    // const { modal } = useAppState((state) => state.local)
    const { sessionUserDetail } = useAppState(s => s.session)
    // const { addUserReviewDetail } = useAppState((state) => state.review)

    // const dispatcher = useAppDispatcher()

    // const onHandleReview = useCallback(() => {
    //     dispatcher(setClearAddUserReviewDetail())
    //     dispatcher(setAddUserReviewDetailPayload({
    //         ...addUserReviewDetail?.addUserReviewDetailPayload,
    //         userId: userProfileInfo?.user?.id,
    //         receiverUserId: userProfileInfo?.user?.id,
    //         receiverRole: userProfileInfo?.role
    //     }))
    //     dispatcher(setModal({
    //         ...modal,
    //         [modalConst.SESSION_DETAIL_MODAL.stateKey]: false,
    //         [modalConst.ADD_REVIEW_MODAL.stateKey]: true
    //     }))
    // }, [userProfileInfo, addUserReviewDetail])

    return (
        <div className={"w-full flex items-center justify-between gap-3 py-2 px-5 rounded-md hover:bg-gray-50 cursor-pointer"}>
            <div className={"flex items-center justify-start gap-3"}>
                <img src={userProfileInfo?.user?.profilePicUrl} alt="" className={"w-10 h-10 rounded-full"} />
                <div className={"flex flex-col items-start justify-evenly h-full"}>
                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                        {userProfileInfo?.user?.firstName + " " + userProfileInfo?.user?.lastName.charAt(0)}
                    </span>
                    <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                        {`${sessionUserTypeEnum[userProfileInfo?.userType?.toUpperCase()]?.label}`}
                        {(sessionUserDetail?.data?.result?.owner_user?.id === userProfileInfo?.user?.id) && " (Owner)"}
                    </span>
                </div>
            </div>
            {/* {((sessionUserDetail?.data?.result?.status === sessionStatusEnum.COMPLETED.value) && (sessionUserDetail?.data?.result?.type === sessionTypeEnum?.COURSE?.value) && (sessionUserInfo?.userType !== userProfileInfo?.userType)) &&
                <div className={cn(
                    "px-1.5 py-1 flex items-center justify-center gap-1 mx-auto rounded-lg cursor-pointer",
                    "font-bodyPri font-normal text-sm text-primary-dark",
                    "bg-primary-light hover:bg-primary-dark group",
                )}
                    onClick={onHandleReview}
                >
                    <StarBorder
                        style={{ fontSize: '15px' }}
                        className={"text-primary-dark cursor-pointer group-hover:bg-primary-dark group-hover:text-text-50"}
                    />
                    <span className={"font-bodyPri font-normal text-sm text-primary-dark group-hover:bg-primary-dark group-hover:text-text-50"}>
                        {"Review"}
                    </span>
                </div>
            } */}
        </div>
    )
})


const SessionDetailParticipants = memo(({ sessionUserInfo }) => {
    const { sessionUserDetail } = useAppState(s => s.session)

    const [isShow, setIsShow] = useState(false)

    const ownerUser = useMemo(() => {
        if (!!sessionUserDetail?.data?.result?.owner_user && !!sessionUserDetail?.data?.result?.session_users?.length) {
            return sessionUserDetail?.data?.result?.session_users?.find((sessionUser) => sessionUser?.user?.id === sessionUserDetail?.data?.result?.owner_user?.id)
        }
    }, [sessionUserDetail?.data?.result?.owner_user, sessionUserDetail?.data?.result?.session_users])

    const sessionUserList = useMemo(() => {
        let filteredUserList = sessionUserDetail?.data?.result?.session_users || []

        if (sessionUserDetail?.data?.result?.session_users && ownerUser) {
            filteredUserList = filteredUserList.filter((sessionUser) => sessionUser?.user?.id !== ownerUser?.id)?.slice(0)?.sort(sortSessionUserList)
            filteredUserList = [ownerUser, ...filteredUserList]?.reduce((acc, user) => {
                const userExists = acc?.some(u => u?.user?.id === user?.user?.id)
                if (!userExists) {
                    acc?.push(user)
                }
                return acc;
            }, [])
        }

        return filteredUserList
    }, [sessionUserDetail?.data?.result?.session_users, ownerUser])

    return !!sessionUserList?.length ? (
        <div className={"space-y-1"}>
            <div onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 px-3 py-1 cursor-pointer rounded-lg hover:bg-back-ground-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Participants"}
                </span>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={cn(
                isShow
                    ? "flex flex-col transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>
                {sessionUserList?.map((userProfileInfo, index) => (
                    <UserProfileInfo key={index} userProfileInfo={userProfileInfo} sessionUserInfo={sessionUserInfo} />
                ))}
            </div>
        </div>
    ) : null
})

export default SessionDetailParticipants