import React from 'react'
import image from 'assets/image/success-icon.png'
import { cn } from 'utils/cn.utils'


const Success = ({ title, subTitle, onButtonClick, buttonText, icon = image, disabled = false, buttonClass }) => {
    return (
        <div className={cn("relative flex flex-col items-center gap-10 w-72 font-bodyPri bg-white px-5 py-8 rounded-lg shadow-lg text-center border")}>
            <h3 className='font-medium text-lg text-text-900'>{title}</h3>
            <img
                src={icon}
                className='w-20 aspect-square object-contain'
                alt={title}
            />
            <p className="text-sm text-back-ground-black">{subTitle}</p>
            <button
                onClick={onButtonClick}
                disabled={disabled}
                className={cn("border px-5 py-1 font-medium ease-in-out duration-200 rounded text-primary-dark border-primary-dark hover:text-white hover:border-secondary-dark hover:bg-secondary-dark",
                    buttonClass
                )}
            >
                {buttonText}
            </button>
        </div>
    )
}

export default Success
