import { useState, useCallback, useMemo } from "react";

import { toast } from "react-toastify";

import { cn } from "utils/cn.utils";

import ButtonLoader from "components/loader/ButtonLoader";

import Image from 'pages/auth/edulyteLms/assets/link.png';

import { createLmsResourceDetail } from "redux/edulyteLms/lmsResource/lmsResource.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { lmsResourceTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";

const AddLink = () => {
  const { user } = useAppState((state) => state.user)
  const { addLmsResourceDetail } = useAppState((state) => state.lms.lmsResource)

  const dispatcher = useAppDispatcher()

  const [linkDetail, setLinkDetail] = useState({
    title: "",
    link: ""
  })

  const isFormDisabled = useMemo(() => (!linkDetail?.title || !linkDetail?.link), [linkDetail])

  const onHandleChangeTitle = useCallback((event) => {
    setLinkDetail({
      ...linkDetail,
      title: event?.target?.value
    })
  }, [linkDetail, isFormDisabled])

  const onHandleChangeLink = useCallback((event) => {
    setLinkDetail({
      ...linkDetail,
      link: event?.target?.value
    })
  }, [linkDetail, isFormDisabled])

  const onHandleSubmitForm = useCallback((event) => {
    event.preventDefault();

    if (addLmsResourceDetail?.isLoading || isFormDisabled) return;

    if (!linkDetail?.title) {
      toast.warn("please enter title!")
      return;
    }
    if (!linkDetail?.link) {
      toast.warn("Please enter link!")
      return;
    }

    const body = {
      type: lmsResourceTypeEnum.LINK.value,
      title: linkDetail?.title,
      link: linkDetail?.link,
      resource_owner_user_id: user?.user?.userId
    }

    dispatcher(createLmsResourceDetail(body))

  }, [linkDetail, isFormDisabled])

  return (
    <div className={'flex flex-col justify-center items-center gap-5 p-5 bg-back-ground-light w-full flex-1'}>
      <img
        src={Image}
        alt={'add link'}
        className={'w-20 h-20 object-contain'}
      />
      <form
        onSubmit={onHandleSubmitForm}
        className={'flex flex-col w-full md:w-2/3 gap-5'}
      >
        <div className={"w-full flex flex-col justify-center items-center gap-5"}>
          <input
            className={cn(
              'px-3 py-2.5 flex w-full focus:outline-none rounded-lg',
              'border border-back-ground-lightDark focus:border focus:border-primary-main',
              'font-bodyPri font-normal text-text-800 text-sm placeholder:text-text-500'
            )}
            type={"text"}
            placeholder={"Enter Title"}
            value={linkDetail?.title}
            onChange={onHandleChangeTitle}
            required={true}
          />
        </div>
        <input
          className={cn(
            'px-3 py-2.5 flex w-full focus:outline-none rounded-lg',
            'border border-back-ground-lightDark focus:border focus:border-primary-main',
            'font-bodyPri font-normal text-text-800 text-sm placeholder:text-text-500'
          )}
          type={"url"}
          placeholder={"Enter Link URL"}
          value={linkDetail?.link}
          onChange={onHandleChangeLink}
          required={true}
        />
        <div className={"w-full flex items-center justify-center"}>
          <button
            type={"submit"}
            disabled={isFormDisabled}
            className={cn(
              'px-5 py-2 whitespace-nowrap rounded-lg ease-in-out duration-300 cursor-pointer disabled:bg-text-400 disabled:text-text-200 disabled:cursor-not-allowed',
              (!isFormDisabled && !addLmsResourceDetail?.isLoading) && 'bg-primary-dark hover:bg-secondary-dark text-white',
              (!isFormDisabled && addLmsResourceDetail?.isLoading) && "bg-primary-dark cursor-progress"
            )}
          >
            {addLmsResourceDetail?.isLoading &&
              <ButtonLoader isLoading={addLmsResourceDetail?.isLoading} />
            }
            {!addLmsResourceDetail?.isLoading && "Add Link"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddLink;
