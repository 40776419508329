import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "./linkTree.const";

class linkTreeService {
    createLinkInBio = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_LINK_IN_BIO),
            body
        )

        return response;
    }

    updateLink = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LINK, params),
            body
        )

        return response;
    }

    deleteLink = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LINK, params),
        )

        return response;
    }

    addLink = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ADD_LINK),
            body
        )

        return response;
    }

    reorderLink = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.REORDER_LINK, params),
            body
        )

        return response;
    }

    getLinkTree = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LINKTREE, params),
        )

        return response;
    }

    deleteLinkTree = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_LINKTREE, params),
        )

        return response;
    }

    updateLinktree = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_LINKTREE, params),
            body
        )

        return response;
    }

    getLinkTreeList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_LINKS, params),
            { params: query }
        )

        return response;
    }
}

export default new linkTreeService()