import { useEffect } from 'react';
import { cn } from "utils/cn.utils";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PageLoader from "components/loader/PageLoader";

import "./Components/sticky.css";
import { PageContentStyle } from "pages/auth/createCoursePreview/CreateCoursePage.style";

import SubmitFormBar from "./Components/SubmitFormBar";
import { SegmentCategoryView } from "./Components/CategorySelectionModal/SegmentCategoryView";
import TitleEditor from "./Components/TitleEditor";
import { AuthorProfileSection } from "./Components/AuthorProfileSection";
import HorizontalStickyCard from "./Components/HorizontalStickyCard";
import VerticalStickyCard from "./Components/VerticalStickyCard";
import EditCourseImageBar from "./Components/editCourseImageBar/EditCourseImageBar";
import { SkillsSection } from "./Components/SkillsSection";
import MoreDetailsSection from "./Components/MoreDetailsSection";
import { CourseDescriptionEditor } from "./Components/CourseDescriptionEditor";
import SelectThumbnails from './Components/SelectThumbnails';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getCoursePreviewDetail } from "redux/course/course.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearCoursePreviewDetail } from "redux/course/course.slice";

import { useTitle } from "hooks/useTitle";
import { pagesInfo } from 'utils/pagesInfo';

import { generateQueryParams } from "utils/generators.utils";

export default function CreateCoursePreview() {
    const { currentPageInfo } = useAppState(s => s.pageInfo)
    const { locals } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { coursePreviewDetail } = useAppState(s => s.course)

    const { courseId } = useParams()
    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()
    const [title, setTitle] = useTitle()


    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.CREATE_COURSE_PREVIEW))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: `Preview Class | ${coursePreviewDetail?.data?.title} | Edulyte`
        })
    }, [dispatcher, currentPageInfo, coursePreviewDetail?.data])

    useEffect(() => {
        if (courseId) {
            const query = generateQueryParams(location.search)
            if (!user?.isLoading && user?.user) {
                navigate(`${pagesInfo?.CREATE_COURSE_PREVIEW?.pagePath}/${courseId}/preview?previewAs=${locals.userRole}`)
            }
            if (query && query?.previewAs && !user?.isLoading && user?.user) {
                dispatcher(getCoursePreviewDetail(courseId, { previewAs: query?.previewAs }))
            }
        }

        return () => {
            dispatcher(setClearCoursePreviewDetail())
        }
    }, [courseId, location.search])

    if (coursePreviewDetail?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (coursePreviewDetail?.message) {
        return (
            <div className={"w-screen h-screen flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                {coursePreviewDetail?.message}
            </div>
        )
    }

    return coursePreviewDetail?.data ? (
        <PageContentStyle>
            {/* {showSegmentSelectionModal &&
                <CategorySelectionModal
                    show={showSegmentSelectionModal}
                    onClose={() => setShowSegmentSelectionModal(false)}
                />
            } */}
            {/* <div className="flex items-center justify-center py-2">
                <ProgressIndicatorV2 />
            </div> */}
            <div className="relative">
                <SubmitFormBar />

                <div className="">
                    <div className="w-full bg-back-ground-dark pt-10 pb-8">
                        {/* Course Header */}
                        <div className="relative max-w-5xl px-4 mx-auto">
                            <div className="max-w-2xl pr-4 mx-auto lg:mx-0 space-y-5">
                                <div className={"flex justify-start items-center gap-2"}>
                                    <span className={"font-bodyPri font-normal text-text-50 text-sm tracking-wide"}>
                                        {"Class ID:"}
                                    </span>
                                    <span className={"font-bodyPri font-medium text-base text-text-50"}>
                                        {coursePreviewDetail?.data?.id}
                                    </span>
                                </div>
                                <SegmentCategoryView />
                                <TitleEditor />
                                <AuthorProfileSection />
                            </div>
                            <div className="max-w-2xl mx-auto">
                                <div
                                    className={cn(
                                        "relative hidden lg:block max-w-md mt-8 lg:mt-0",
                                        "lg:absolute lg:top-[10rem] lg:right-10 lg:w-[290px]"
                                    )}
                                >
                                    <VerticalStickyCard />
                                </div>
                            </div>
                        </div>
                        {/* Course Header */}
                    </div>
                    <div className="max-w-5xl px-4 mx-auto">
                        <div className="max-w-2xl pt-6 pr-4 mx-auto space-y-3 lg:mx-0">
                            <EditCourseImageBar />
                            <div className="block lg:hidden">
                                <HorizontalStickyCard />
                            </div>
                            <div className="">
                                <SkillsSection />
                            </div>
                            <div className={"p-5 bg-white"}>
                                <MoreDetailsSection />
                            </div>
                            <div className="mb-4 ">
                                <CourseDescriptionEditor />
                            </div>
                            <div className='mb-3'>
                                <SelectThumbnails />
                            </div>
                        </div>
                        {/* <div className="w-full mt-6">
                            <SubmitFormBar />
                        </div> */}
                    </div>
                </div>
            </div>
        </PageContentStyle>
    ) : null
}