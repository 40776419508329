import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Draggable } from "react-drag-reorder";
import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';
import { FaTimes } from "react-icons/fa";

import SelectSearch, { fuzzySearch } from 'react-select-search';

import ComponentLoader from 'components/loader/ComponentLoader';

import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/createCourse/createCourse.style';
import { inputSections, languageTooltip } from "pages/auth/createCourse/data";

import { getMasterLanguageList } from 'redux/master/master.request';
import { createCourseLanguageDetail, deleteCourseLanguageDetail, getCourseLanguageList, updateCourseLanguageListOrder } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearLanguageList } from 'redux/master/master.slice';
import { setClearAddCourseLanguageDetail, setClearCourseLanguageList } from 'redux/course/course.slice';

const CourseLanguageSection = ({ clickedItem, setClickedItem }) => {
    const { languageList } = useAppState((state) => state.master)
    const { tutorCourseDetail, courseLanguageList, addCourseLanguageDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [isAddLanguageEnabled, setIsAddLanguageEnabled] = useState(false)
    const [courseLangList, setCourseLangList] = useState([])

    useEffect(() => {
        if (clickedItem === inputSections?.COURSE_LANGUAGE?.value) {
            dispatcher(getMasterLanguageList())
            dispatcher(getCourseLanguageList({ course: tutorCourseDetail?.data?.id }))
        }

        return () => {
            dispatcher(setClearLanguageList())
            dispatcher(setClearCourseLanguageList())
            dispatcher(setClearAddCourseLanguageDetail())
        }
    }, [clickedItem])

    useEffect(() => {
        if (courseLanguageList?.courseLanguageList?.results?.length > 0) {
            setCourseLangList(courseLanguageList?.courseLanguageList?.results)
            setIsAddLanguageEnabled(true)
        } else {
            setIsAddLanguageEnabled(false)
        }
    }, [courseLanguageList?.courseLanguageList])

    const isLanguageListSimilar = JSON.stringify(courseLangList) === JSON.stringify(courseLanguageList?.courseLanguageList?.results)

    const isOpen = inputSections?.COURSE_LANGUAGE?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_LANGUAGE?.value)) {
            setClickedItem(inputSections?.COURSE_LANGUAGE?.value)
        } else {
            setClickedItem(null)
            setIsAddLanguageEnabled(false)
        }
    }

    const onHandleSelectLanguage = (option) => {
        const filteredLanguageList = courseLangList?.filter((language) => language?.masterLanguage?.language === option)

        if (filteredLanguageList?.length > 0) {
            toast.warn("Duplicate languages are not allowed!")
            return;
        }
        dispatcher(createCourseLanguageDetail({ course: tutorCourseDetail?.data?.id, language: option }))
    }

    const onRemoveLanguage = (courseLanguageId) => {
        dispatcher(deleteCourseLanguageDetail(courseLanguageId))
    }

    const getChangedPos = (currentPos, newPos) => {
        var updatedList = [...courseLangList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        // Add dropped item
        updatedList.splice(newPos, 0, reorderedItem);
        // Update State
        setCourseLangList(updatedList);
    }

    const onHandleUpdateOrder = () => {
        if (isLanguageListSimilar || addCourseLanguageDetail?.isLoading) return;

        if (courseLanguageList?.courseLanguageList?.results?.length <= 1) return;

        const body = {
            order: courseLangList?.map((language) => ({
                id: language?.id
            }))
        }

        dispatcher(updateCourseLanguageListOrder(body, { course: tutorCourseDetail?.data?.id }))
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-back-ground-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Language of instruction"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        {languageTooltip}
                    </span>
                </div>
                {courseLanguageList?.isLoading &&
                    <ComponentLoader isLoading={courseLanguageList?.isLoading} />
                }
                {courseLanguageList?.ErrorMsg &&
                    <div className={"flex items-center justify-start font-bodyPri font-normal text-red-500 text-base"}>
                        {courseLanguageList?.errorMsg}
                    </div>
                }
                {(!isAddLanguageEnabled && !courseLanguageList?.isLoading) &&
                    <div className={cn(
                        "w-full px-3 py-3 my-2 flex items-center justify-center border border-dashed border-text-500 rounded-lg",
                        "font-bodyPri font-normal text-text-900 cursor-pointer"
                    )}
                        onClick={() => {
                            dispatcher(createCourseLanguageDetail({ course: tutorCourseDetail?.data?.id, language: "English" }))
                            setIsAddLanguageEnabled(true)
                        }}
                    >
                        <MdAdd className={"text-xl text-text-900"} />
                        <span className={"text-xl"}>
                            {"Add Language"}
                        </span>
                    </div>
                }
                {(isAddLanguageEnabled && !courseLanguageList?.isLoading) &&
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <div className={cn(
                            "w-full px-3 py-3 my-2 flex flex-col items-start justify-start border border-dashed border-text-500 rounded-lg",
                            "font-bodyPri font-normal text-text-900"
                        )}>
                            {addCourseLanguageDetail?.isLoading &&
                                <ComponentLoader isLoading={addCourseLanguageDetail?.isLoading} />
                            }
                            {(!courseLanguageList?.isLoading && !addCourseLanguageDetail?.isLoading) &&
                                <div className={"flex flex-wrap items-start justify-start gap-3"}>
                                    <Draggable onPosChange={getChangedPos} className={""}>
                                        {courseLanguageList?.courseLanguageList?.results?.length > 0 &&
                                            courseLanguageList?.courseLanguageList?.results?.map((courseLanguage, idx) => (
                                                <div
                                                    key={idx}
                                                    className={'w-fit px-3 py-1 border-2 border-text-300 flex items-center justify-start gap-1.5 rounded-xl'}
                                                >
                                                    <span className={"font-bodyPri font-medium text-text-900 text-base sm:whitespace-nowrap"}>
                                                        {courseLanguage?.masterLanguage?.language}
                                                    </span>
                                                    <button className="pl-3 cursor-pointer">
                                                        <FaTimes className="font-light text-secondary-dark" onClick={() => onRemoveLanguage(courseLanguage?.id)} />
                                                    </button>
                                                </div>
                                            ))}
                                    </Draggable>
                                    {(!languageList?.isLoading && languageList?.languageList) &&
                                        <div className={"w-52 h-32 md:h-48 z-[500] overflow-hidden border-2 border-divider-medium rounded-lg"}>
                                            <SelectSearch
                                                options={languageList?.languageList?.map((language) => ({
                                                    name: language?.language,
                                                    value: language?.language
                                                }))}
                                                placeholder={"Select Language"}
                                                search
                                                filterOptions={fuzzySearch}
                                                value={""}
                                                onChange={(option) => onHandleSelectLanguage(option)}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            {addCourseLanguageDetail?.errorMsg &&
                                <div className={"font-bodyPri font-normal text-red-500 text-base"}>
                                    {addCourseLanguageDetail?.errorMsg}
                                </div>
                            }
                        </div>

                        <div className={"flex items-center justify-start gap-1"}>
                            <div className={cn(
                                "w-fit px-3 py-0.5 rounded-lg",
                                "font-bodyPri font-normal text-text-800 text-sm",
                                isLanguageListSimilar && "border border-text-300 bg-text-300 cursor-not-allowed",
                                !isLanguageListSimilar && "border border-green-500 text-green-500 hover:bg-green-700 hover:text-text-50 cursor-pointer"
                            )}
                                onClick={onHandleUpdateOrder}
                            >
                                {"Save Display Order"}
                            </div>
                        </div>
                    </div>
                }
            </CollapsableContainer >
        </div >
    )
}

export default CourseLanguageSection;