import { useCallback, useState, useMemo, useEffect } from 'react';

import { motion } from "framer-motion";

import { FiChevronRight } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';

import styles from "./styles.module.css";

import ToolTipView from 'components/tooltipView';

import Drag from "pages/auth/edulyteLms/assets/drag.svg";

import SaveAndCancelBtn from "pages/auth/edulyteLms/commonComponents/lmsButtons/SaveAndCancelBtn";

import { deleteLmsCourseFaq, updateLmsCourseFaqDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetModifyLmsCourseFaqDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';

import { cn } from 'utils/cn.utils';

const FAQAccordionItem = ({ index, itemFAQ, }) => {
    const { modifyLmsCourseFaqDetail, deleteLmsCourseFaqDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [clickedItemIndex, setClickedItemIndex] = useState(null);
    const [faqPayload, setFaqPayload] = useState(null)

    const isOpen = useMemo(() => (clickedItemIndex === index), [clickedItemIndex, index]);

    useEffect(() => {
        if (modifyLmsCourseFaqDetail?.data) {
            dispatcher(resetModifyLmsCourseFaqDetail())
            setFaqPayload(null)
        }
    }, [modifyLmsCourseFaqDetail?.data])

    const onHandleEditBtn = useCallback(() => {
        setFaqPayload({
            question: itemFAQ?.question,
            answer: itemFAQ?.answer
        })
        setClickedItemIndex(index)
    }, [itemFAQ, clickedItemIndex, faqPayload])

    const onHandleChangeText = useCallback((event) => {
        setFaqPayload({
            ...faqPayload,
            [event?.target?.name]: event?.target?.value
        })
    }, [faqPayload])

    const onHandleSaveCourseFaq = useCallback(() => {
        const body = { ...faqPayload }
        dispatcher(updateLmsCourseFaqDetail(itemFAQ?.id, body))
    }, [itemFAQ, faqPayload])

    const onHandleResetFaq = useCallback(() => {
        setFaqPayload(null)
    }, [itemFAQ, faqPayload, clickedItemIndex, isOpen])

    const onHandleDeleteCourseFaq = useCallback(() => {
        if (deleteLmsCourseFaqDetail?.isLoading) return;

        dispatcher(deleteLmsCourseFaq(itemFAQ?.id))
    }, [itemFAQ, deleteLmsCourseFaqDetail?.isLoading])

    return (
        <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.2 }}
            exit={{ height: 0, opacity: 0 }}
            className={"border rounded-sm border-white border-b-divider-medium hover:border-primary-main overflow-hidden cursor-pointer"}
        >
            <div className={"w-full flex items-center justify-between gap-3 hover:bg-back-ground-lightBlue"}>
                <div className={"flex-1 p-3 flex items-center justify-start gap-2 hover:text-primary-dark"}>
                    <div className={""}>
                        <img
                            src={Drag}
                            className={"w-2.5 cursor-grab"}
                        />
                    </div>
                    <div className={"flex flex-1 items-center gap-2"}>
                        <FiChevronRight
                            size={18}
                            className={`ease-in-out duration-300 ${isOpen ? "rotate-90" : ""}`}
                            onClick={() => setClickedItemIndex(isOpen ? null : index)}
                        />
                        {faqPayload &&
                            <input
                                type={"text"}
                                name={"question"}
                                placeholder={"Write question here ..."}
                                className={cn(
                                    "flex flex-1 p-3 focus:outline-none focus:!bg-white bg-back-ground-light hover:bg-text-100",
                                    "transition ease-in-out duration-300 overflow-hidden",
                                    "font-bodyPri font-normal text-text-800 text-base tracking-wide placeholder:text-text-500"
                                )}
                                autoFocus={true}
                                value={faqPayload?.question}
                                onChange={onHandleChangeText}
                            />
                        }
                        {!faqPayload &&
                            <p
                                className={"flex-1 font-bodyPri font-semibold text-base"}
                                onClick={() => setClickedItemIndex(isOpen ? null : index)}
                            >
                                {itemFAQ?.question}
                            </p>
                        }
                    </div>
                </div>

                {!faqPayload &&
                    <div className={'flex justify-end items-center gap-1.5'}>
                        <ToolTipView content={'Edit'}>
                            <button className={"p-2 rounded-full hover:bg-divider-darkLight group"} onClick={onHandleEditBtn}>
                                <MdEdit className={"text-xl text-text-700 group-hover:text-primary-dark cursor-pointer"} />
                            </button>
                        </ToolTipView>
                        <ToolTipView content={"Delete"}>
                            <button className={"p-2 rounded-full hover:bg-divider-darkLight group"} onClick={onHandleDeleteCourseFaq}>
                                <RiDeleteBin6Line className={"text-xl text-text-700 group-hover:text-red-500 cursor-pointer"} />
                            </button>
                        </ToolTipView>
                    </div>
                }
                {faqPayload &&
                    <SaveAndCancelBtn
                        isLoading={modifyLmsCourseFaqDetail?.isLoading}
                        className={"flex items-center justify-start gap-1.5"}
                        onHandleSave={onHandleSaveCourseFaq}
                        onHandleReset={onHandleResetFaq}
                    />
                }
            </div>
            <div className={`${styles.customToggle} ${isOpen ? styles.toggleShow : ""}`}>
                {faqPayload &&
                    <textarea
                        type={"text"}
                        name={"answer"}
                        className={cn(
                            "flex w-full p-3 focus:outline-none bg-back-ground-light hover:bg-text-100",
                            "transition ease-in-out duration-300 overflow-hidden",
                            'font-bodyPri font-normal text-text-800 text-base tracking-wide placeholder:text-text-500'
                        )}
                        rows={5}
                        placeholder={"Write answer here ..."}
                        value={faqPayload?.answer}
                        onChange={onHandleChangeText}
                    />
                }
                {!faqPayload &&
                    <p className={"p-3 font-bodyPri"}>
                        {itemFAQ?.answer}
                    </p>
                }
            </div>
        </motion.div>
    )
}

export default FAQAccordionItem;