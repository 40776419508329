import { memo, useCallback } from "react";

import { cn } from "utils/cn.utils";

import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

import ToolTipView from "components/tooltipView";
import FadedCollapse from "components/fadedCollapse";

import Drag from "pages/auth/edulyteLms/assets/drag.svg";

import { deleteLmsQuizCategoryDetail } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import { useAppDispatcher } from "hooks/useStore";
import { lmsQuizCategoryEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";
import { lmsQuestionTypeEnum } from "redux/edulyteLms/lmsQuestion/lmsQuestion.const";

import { pagesInfo } from "utils/pagesInfo";

const QuizSectionQuestion = memo(({ quizSectionCategoryItem }) => {

    const dispatcher = useAppDispatcher()

    const onHandleEditQuestion = useCallback(() => {
        window.open(`${window.location.host}${pagesInfo?.EDIT_LMS_QUESTION.pagePath}/${quizSectionCategoryItem?.question?.question?.id}/edit`, "_self")
    }, [quizSectionCategoryItem])

    const onHandleDeleteQuestion = useCallback(() => {
        dispatcher(deleteLmsQuizCategoryDetail(quizSectionCategoryItem?.id, quizSectionCategoryItem?.quiz_section))
    }, [quizSectionCategoryItem])

    return quizSectionCategoryItem?.question && (
        <div className={"relative w-full px-5 py-2.5 bg-white rounded-lg space-y-5 transition-transform ease-in-out duration-300"}>
            {quizSectionCategoryItem?.question?.question?.type &&
                <div className={"w-fit px-1.5 py-0.5 absolute top-0 left-[2.7rem] flex items-center justify-center bg-divider-lightDark rounded-b-lg"}>
                    <span className={"font-bodyPri font-normal text-text-50 text-xs tracking-wide"}>
                        {`${lmsQuizCategoryEnum?.QUESTION?.label}: ${lmsQuestionTypeEnum[quizSectionCategoryItem?.question?.question?.type?.toUpperCase()]?.label}`}
                    </span>
                </div>
            }
            <div className={cn("flex flex-col md:flex-row items-end md:items-start justify-between gap-2")}>
                <div className={cn('w-full flex items-start gap-5')}>
                    <img
                        src={Drag}
                        className={"mt-1.5 w-2.5 cursor-grab"}
                    />
                    <span className={"inline-flex font-bodyPri font-medium text-text-900 text-md sm:text-lg line-clamp-2"}>
                        {quizSectionCategoryItem?.question?.question?.title}
                    </span>
                </div>
                <div className={'flex justify-end items-center gap-1.5'}>
                    <ToolTipView content={'Edit Question'}>
                        <button
                            className={"p-2 rounded-full hover:bg-divider-darkLight"}
                            onClick={onHandleEditQuestion}
                        >
                            <MdEdit className={"text-xl text-text-700 cursor-pointer"} />
                        </button>
                    </ToolTipView>
                    <ToolTipView content={"Remove Question"}>
                        <button
                            className={"p-2 rounded-full hover:bg-divider-darkLight"}
                            onClick={onHandleDeleteQuestion}
                        >
                            <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
                        </button>
                    </ToolTipView>
                </div>
            </div>
            {(quizSectionCategoryItem?.question?.question?.question_options?.length > 0) &&
                <FadedCollapse
                    isShowMore={quizSectionCategoryItem?.question?.question?.question_options?.length >= 3}
                    maxHeight={(quizSectionCategoryItem?.question?.question?.question_options?.length >= 3) ? "8.5rem" : "6.5rem"}
                >
                    <div className={"w-full flex flex-col items-start justify-start gap-3 px-5"}>
                        {quizSectionCategoryItem?.question?.question?.question_options?.map((questionOptionItem) => (
                            <div className={"flex items-center justify-start gap-3"}>
                                <input
                                    type={(questionOptionItem?.type === lmsQuestionTypeEnum?.SINGLE_CHOICE?.value) ? "radio" : "checkbox"}
                                    className={"w-4 h-4 rounded-full border border-divider-medium"}
                                    checked={questionOptionItem?.is_correct}
                                    onChange={(event) => console.log(event?.target?.checked)}
                                />
                                <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                    {questionOptionItem?.option}
                                </span>
                            </div>
                        ))}
                    </div>
                </FadedCollapse>
            }
        </div>
    )
})

export default QuizSectionQuestion;