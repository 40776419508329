import { useState } from 'react'
import Question from "pages/auth/edulyteLms/editLmsPages/editLmsQuiz/components/quizBuilder/components/bulkQuestions/question";
import { InputSwitch } from "pages/auth/edulyteLms/commonComponents/lmsInputSwitch/InputSwitch";
import { motion } from "framer-motion"
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { FiSave } from "react-icons/fi"
import { cn } from "utils/cn.utils"
import { Rings, RotatingLines } from 'react-loader-spinner';

import gptService from 'redux/gpt/gpt.service';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setUser } from 'redux/user/user.slice';

const sectionTitleStyle = "w-full px-1 text-text-900 text-xl font-semibold outline-none focus:border-b-primary-main border bg-transparent border-transparent hover:border-primary-main"
const sectionDescriptionStyle = "w-full px-1 py-0.5 text-text-800 text-sm outline-none focus:border-b-primary-main border bg-transparent border-transparent hover:border-primary-main"

const AiQuestionSections = ({
    data,
    aiQuiz,
    setAiQuiz,
    updateSection,
    lmsAiQuizDetail,
    handleCreateQuiz,
    isBlankField,
    isNotFilled,
    addAiQuiz,
    aiQuestion,
    setAiQuestion,
}) => {
    const { user } = useAppState((state) => state.user)
    const [isShowDelete, setIsShowDelete] = useState(null);
    const [aiResponse, setAiResponse] = useState(null)

    const dispatcher = useAppDispatcher();

    const handleAddQuestion = () => {
        const newQuestionId = aiQuiz.data.sections.reduce((acc, section) => acc.concat(section.questions), []).length + 1;
        const newOptions = [
            { id: 1, title: '', is_correct: false },
            { id: 2, title: '', is_correct: false },
            { id: 3, title: '', is_correct: false },
            { id: 4, title: '', is_correct: false }
        ];
        setAiQuiz({
            ...aiQuiz,
            data: {
                ...aiQuiz.data,
                sections: aiQuiz.data.sections.map(section => ({
                    ...section,
                    questions: [...section.questions, { questionId: newQuestionId, title: '', options: newOptions, type: 'single_choice' }]
                }))
            }
        });
    };

    const handleDeleteQuestion = (questionId) => {
        setAiQuiz({
            ...aiQuiz,
            data: {
                ...aiQuiz.data,
                sections: aiQuiz.data.sections.map(section => ({
                    ...section,
                    questions: section.questions.filter(question => question.questionId !== questionId)
                }))
            }

        });
    };

    const handleUpdateQuestion = (updatedQuestion) => {
        setAiQuiz({
            ...aiQuiz,
            data: {
                ...aiQuiz.data,
                sections: aiQuiz.data.sections.map(section => ({
                    ...section,
                    questions: section.questions.map(question => question.questionId === updatedQuestion.questionId ? updatedQuestion : question)
                }))
            }
        });
    };

    const requestData = {
        topic: aiQuiz?.data?.quiz_title,
        question_type: "single_choice",
        student_level: "intermediate",
    }

    const handleCreateAi = async () => {
        if (aiQuestion?.isLoading || lmsAiQuizDetail?.isLoading) return;
        setAiQuestion(s => ({ ...s, isLoading: true }))
        try {
            const requestBody = {
                body: JSON?.stringify(requestData)
            }
            const response = await gptService?.createAiSingleQuestion(requestBody)
            if (response.status === 200) {
                const data = response?.data
                setAiResponse(data)

                const newAiQuestion = JSON?.parse(data?.ai_response)
                setAiQuestion(s => ({ ...s, data: newAiQuestion }))

                let section = aiQuiz?.data?.sections[0]

                const newQuestionId = section?.questions?.length > 0 ? Math.max(...section?.questions?.map(q => q.questionId)) + 1 : 1;

                const newOptions = newAiQuestion?.options?.map((option, index) => ({
                    id: index + 1,
                    title: option?.title,
                    is_correct: option?.is_correct,
                }));
                const newQuestion = {
                    questionId: newQuestionId,
                    type: newAiQuestion?.type,
                    title: newAiQuestion?.title,
                    options: newOptions,
                    feedback: newAiQuestion?.feedback,
                };

                const findEmptyField = section?.questions.findIndex(question =>
                    !question?.title && question?.options?.every(option => !option?.title)
                );

                let updatedQuestions;
                if (findEmptyField !== -1) {
                    updatedQuestions = section?.questions?.map((question, index) =>
                        index === findEmptyField ? { ...question, ...newQuestion } : question
                    );
                } else {
                    updatedQuestions = [...section?.questions, newQuestion];
                }

                setAiQuiz({
                    ...aiQuiz,
                    data: {
                        ...aiQuiz?.data,
                        sections: [{ ...section, questions: updatedQuestions }]
                    }
                });

                dispatcher(setUser({
                    ...user?.user,
                    gpt_user_token: {
                        ...user?.user?.gpt_user_token,
                        total_tokens: data?.token_balance
                    }
                }));
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error);
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong");
        } finally {
            setAiQuestion(s => ({ ...s, isLoading: false }));
        }
    };

    return (
        <>
            {data?.sections?.map((section, sectionIndex) => (
                <div key={sectionIndex} className="flex flex-col gap-0.5 p-3 mb-3 rounded bg-back-ground-lightBlue">
                    <InputSwitch
                        inputClassName={sectionTitleStyle}
                        textClassName={sectionTitleStyle}
                        placeholder="Section Title"
                        value={section?.section_title}
                        text={<h3>{section?.section_title}</h3>}
                        onChange={(e) => updateSection(sectionIndex, 'section_title', e.target.value)}
                    />
                    <InputSwitch
                        inputClassName={sectionDescriptionStyle}
                        textClassName={sectionDescriptionStyle}
                        placeholder="Section Description"
                        value={section?.section_description}
                        text={<p>{section?.section_description}</p>}
                        onChange={(e) => updateSection(sectionIndex, 'section_description', e.target.value)}
                    />

                    {section.questions.map((question) => (
                        <div
                            key={question?.questionId}
                            className='flex flex-col'
                            onMouseEnter={() => setIsShowDelete(question?.questionId)}
                            onMouseLeave={() => setIsShowDelete(null)}
                        >
                            <Question
                                question={question}
                                onUpdate={handleUpdateQuestion}
                                isShowDelete={isShowDelete}
                                handleDeleteQuestion={handleDeleteQuestion}
                                isNotFilled={isNotFilled}
                            />
                        </div>
                    ))}
                </div>
            ))}

            <div className='flex w-full flex-col lg:flex-row items-center justify-between gap-3'>
                <div className='flex w-full flex-col lg:flex-row items-center gap-3'>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={handleCreateAi}
                        disabled={addAiQuiz?.isLoading || aiQuestion?.isLoading || lmsAiQuizDetail?.isLoading}
                        className={cn('flex items-center justify-center gap-1 px-3 py-0.5 rounded-full font-semibold font-bulletPoints text-[15px] ',
                            'shadow-sm group bg-white w-full sm:w-auto',
                            aiQuestion?.isLoading ? 'cursor-wait' : 'text-primary-dark hover:bg-primary-dark hover:text-white ',
                            lmsAiQuizDetail?.isLoading ? 'cursor-wait text-text-300 hover:bg-white hover:text-text-300' : '',
                            addAiQuiz?.isLoading ? 'cursor-not-allowed text-text-300 hover:bg-white hover:text-text-300' : ' ',
                        )}
                    >
                        {aiQuestion?.isLoading ? (
                            <Rings
                                visible={aiQuestion?.isLoading}
                                height="30"
                                width="30"
                                color="#ff1d58"
                                ariaLabel="rings-loading"
                            />
                        ) : (
                            <AutoFixHighIcon style={{ fontSize: 18 }} className={cn('animate-pulse text-secondary-dark group-hover:text-white',
                                addAiQuiz?.isLoading || lmsAiQuizDetail?.isLoading ? 'group-hover:text-text-300 text-text-300' : ' ',
                            )} />
                        )}
                        {aiQuestion?.isLoading ? 'Thinking...' : 'Create with ai'}
                    </motion.button>
                    <motion.button
                        whileHover={!isBlankField && { scale: 1.05 }}
                        whileTap={!isBlankField && { scale: 0.95 }}
                        onClick={handleAddQuestion}
                        disabled={addAiQuiz?.isLoading || aiQuestion?.isLoading || lmsAiQuizDetail?.isLoading || isBlankField}
                        className={cn('flex items-center justify-center gap-1 px-3 py-0.5 rounded-full font-semibold font-bulletPoints text-[15px]',
                            'shadow-sm bg-white w-full sm:w-auto',
                            addAiQuiz?.isLoading || isBlankField ? 'cursor-not-allowed text-text-300' : 'text-primary-dark hover:bg-primary-dark hover:text-white',
                            aiQuestion?.isLoading || lmsAiQuizDetail?.isLoading ? 'cursor-wait text-text-300 hover:bg-white hover:text-text-300' : '',
                        )}
                    >
                        <AddIcon style={{ fontSize: 18 }} />
                        {'Add Question'}
                    </motion.button>
                    {aiResponse && (
                        <div className='hidden lg:flex gap-3 items-center'>
                            <span className='text-sm text-text-700 font-bulletPoints'>{`Token Used : ${aiResponse?.token_used}`}</span>
                        </div>
                    )}
                </div>
                <motion.button
                    whileHover={!isBlankField && { scale: 1.05 }}
                    whileTap={!isBlankField && { scale: 0.95 }}
                    onClick={handleCreateQuiz}
                    disabled={addAiQuiz?.isLoading || aiQuestion?.isLoading || lmsAiQuizDetail?.isLoading || !aiQuiz?.data?.sections[0].questions.length}
                    className={cn('flex items-center justify-center px-3 py-0.5 rounded-full font-semibold font-bulletPoints text-[15px] shadow-sm',
                        'shadow-sm bg-white w-full sm:w-auto gap-2 whitespace-nowrap',
                        addAiQuiz?.isLoading || !aiQuiz?.data?.sections[0].questions.length ? 'cursor-not-allowed text-text-300' : 'text-primary-dark hover:bg-primary-dark hover:text-white',
                        aiQuestion?.isLoading ? 'cursor-wait hover:bg-white text-text-300 hover:text-text-300' : '',
                        lmsAiQuizDetail?.isLoading ? "cursor-wait hover:bg-white hover:text-primary-dark" : '',
                    )}
                >
                    {lmsAiQuizDetail?.isLoading ? (
                        <RotatingLines
                            visible={lmsAiQuizDetail?.isLoading}
                            height="20"
                            width="20"
                            strokeWidth="5"
                            animationDuration="0.75"
                        />
                    ) : (
                        <FiSave size={15} />
                    )}
                    {lmsAiQuizDetail?.isLoading ? 'Saving...' : 'Save & Next'}
                </motion.button>
                {aiResponse && (
                    <div className='flex lg:hidden flex-col gap-1 items-center'>
                        <span className='text-sm text-text-700 font-bulletPoints'>{`Token Used : ${aiResponse?.token_used}`}</span>
                    </div>
                )}
            </div>
        </>
    )
}

export default AiQuestionSections
