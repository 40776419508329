import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
    PageContentStyle,
    Header,
    Heading,
    SubHeading,
    ProgressBarContainer,
    ProgressBarText,
    TasksContainer,
    Tasks,
    WaitListNoticeContainer,
    TutorFeaturedContainer,
    FooterContainer,
    DoughnutChartContainer
} from './TutorOnboardStyle';

import PageLoader from 'components/loader/PageLoader';
import SideOverPanel from 'components/common-components/SideOverPanel';
import DoughnutChart from 'components/charts/doughnutChart/DoughnutChart';
import Footer1 from 'components/footer/footer1/Footer1';

import OnboardingCommentSideOverContent from 'pages/auth/tutorOnboard/components/onboardingCommentSideOverContent/OnboardingCommentSideOverContent';
import GoBackHeader from "pages/auth/tutorOnboard/components/goBackHeader/GoBackHeader";
import TutorFeatures from 'pages/auth/tutorOnboard/components/TutorFeatures/TutorFeaturedLink';
import SharePersonalDetails from 'pages/auth/tutorOnboard/components/sharePersonalDetails/SharePersonalDetails';
import ShareIntroVideo from 'pages/auth/tutorOnboard/components/shareIntroVideo/ShareIntroVideo';
import RelevantQuestions from 'pages/auth/tutorOnboard/components/relevantQuestions/RelevantQuestions';
import AttendVideoCall from 'pages/auth/tutorOnboard/components/attendVideoCall/AttendVideoCall';
import DocumentVerification from 'pages/auth/tutorOnboard/components/documentVerification/DocumentVerification';
import TutorPreferences from 'pages/auth/tutorOnboard/components/tutorPreferences/TutorPreferences';
import WaitListNotice from 'pages/auth/tutorOnboard/components/waitListNotice/WaitListNotice';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getUserOnboardingDetailByOnboardingIdAndUserId } from "redux/onboarding/onboarding.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearUserOnboardingDetail } from 'redux/onboarding/onboarding.slice';
import { onboardingRequestType, onBoardingStatus } from 'redux/onboarding/onboarding.const';

import { pagesInfo } from 'utils/pagesInfo';
import { useTitle } from 'hooks/useTitle';
import { FaRegComment } from 'react-icons/fa';

const TutorOnboardingS2Page = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { userOnboardingDetail } = useAppState((state) => state.onboarding)

    const [userSubmittedStatus, setUserSubmittedStatus] = useState({
        labels: ["Submitted", "Pending"],
        datasets: [
            {
                label: "Submitted",
                data: [],
                backgroundColor: ["#2196f3", "#e0e0e0"]
            },
        ]
    })
    const [userVerifiedStatus, setUserVerifiedStatus] = useState({
        labels: ["Verified", "Pending"],
        datasets: [
            {
                label: "Verified",
                data: [],
                backgroundColor: ["#4caf50", "#e0e0e0"]
            },
        ]
    })
    const [sideOverPanel, setSideOverPanel] = useState(false)

    const dispatcher = useAppDispatcher()
    const { onboardingId } = useParams()
    const [title, setTitle] = useTitle()

    const isWaitingEnabled = ((user?.user?.tutorOnboardingRequest === onboardingRequestType?.WAITING?.value) && (userOnboardingDetail?.userOnboardingDetail?.status === onBoardingStatus?.WAITING?.value))

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TUTOR_ONBOARDING_S2))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: `${user?.user?.firstName} Onboarding Progress | Edulyte`
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (onboardingId && user?.user?.userId) {
            dispatcher(getUserOnboardingDetailByOnboardingIdAndUserId(Number(onboardingId), user?.user?.userId))
        }

        return () => {
            dispatcher(setClearUserOnboardingDetail())
        }
    }, [onboardingId, user?.user])

    useEffect(() => {
        if (userOnboardingDetail?.userOnboardingDetail) {
            setUserSubmittedStatus({
                labels: ["Submitted", "Pending"],
                datasets: [
                    {
                        label: "Submitted",
                        data: [userOnboardingDetail?.userOnboardingDetail?.pctSubmitted?.toFixed(0), 100 - userOnboardingDetail?.userOnboardingDetail?.pctSubmitted?.toFixed(0)],
                        backgroundColor: ["#2196f3", "#e0e0e0"]
                    },
                ],
                hoverOffset: 4,
                hoverBackgroundColor: ["#2196f3", "#e0e0e0"]
            })
            setUserVerifiedStatus({
                labels: ["Verified", "Pending"],
                datasets: [
                    {
                        label: "Verified",
                        data: [userOnboardingDetail?.userOnboardingDetail?.pctApproved?.toFixed(0), 100 - userOnboardingDetail?.userOnboardingDetail?.pctApproved?.toFixed(0)],
                        backgroundColor: ["#4caf50", "#e0e0e0"]
                    },
                ],
                hoverOffset: 4,
                hoverBackgroundColor: ["#4caf50", "#e0e0e0"]
            })
        }

    }, [userOnboardingDetail?.userOnboardingDetail])

    if (userOnboardingDetail?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (userOnboardingDetail?.errorMsg) {
        return (
            <div className={"w-screen h-screen flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                {userOnboardingDetail?.errorMsg}
            </div>
        )
    }

    return (
        <PageContentStyle>
            <SideOverPanel
                child={<OnboardingCommentSideOverContent />}
                isOpen={sideOverPanel}
                onClose={(isShow) => setSideOverPanel(isShow)}
            />
            <div className='w-full fixed bottom-0 left-0 md:left-5 flex items-end justify-start p-2'>
                <span
                    className="p-1.5 bg-accent-light border border-accent-light rounded-full shadow-md cursor-pointer"
                    title={"Comments"}
                    onClick={() => setSideOverPanel(true)}
                >
                    <FaRegComment className={"text-text-600 hover:text-text-800 text-3xl md:text-4xl"} />
                </span>
            </div>
            <GoBackHeader />
            <Header>
                <Heading>
                    {'Apply to List on Edulyte Marketplace'}
                </Heading>
                <SubHeading>
                    {'Subject to our screening process, please review all the details and submit.'}
                </SubHeading>
            </Header>
            {isWaitingEnabled &&
                <WaitListNoticeContainer>
                    <WaitListNotice />
                </WaitListNoticeContainer>
            }

            <ProgressBarContainer>
                <ProgressBarText>
                    {"Progress"}
                </ProgressBarText>
                <DoughnutChartContainer>
                    <div className={"w-[150px] md:w-[200px]"}>
                        <DoughnutChart doughnutData={userSubmittedStatus} options={{
                            responsive: true,
                            rotation: 180,
                            plugins: {
                                legend: {
                                    display: false,
                                    position: 'bottom',
                                },
                                title: {
                                    display: true,
                                    text: `Submitted: ${userOnboardingDetail?.userOnboardingDetail?.pctSubmitted?.toFixed(0)}%`
                                }
                            }
                        }} />
                    </div>
                    <div className={"w-[150px] md:w-[200px]"}>
                        <DoughnutChart doughnutData={userVerifiedStatus} options={{
                            responsive: true,
                            rotation: 180,
                            plugins: {
                                legend: {
                                    display: false,
                                    position: 'bottom',
                                },
                                title: {
                                    display: true,
                                    text: `Verified: ${userOnboardingDetail?.userOnboardingDetail?.pctApproved?.toFixed(0)}%`
                                }
                            }
                        }} />
                    </div>
                </DoughnutChartContainer>
            </ProgressBarContainer>

            {(userOnboardingDetail?.userOnboardingDetail?.status === onBoardingStatus?.APPROVED?.value) &&
                <TutorFeaturedContainer>
                    <TutorFeatures />
                </TutorFeaturedContainer>
            }
            <TasksContainer>
                <Tasks>
                    <SharePersonalDetails />
                    <ShareIntroVideo />
                    <RelevantQuestions />
                    <DocumentVerification />
                    <TutorPreferences />
                    <AttendVideoCall />
                </Tasks>
            </TasksContainer>
            {/* <FooterContainer>
                <Footer1 />
            </FooterContainer> */}
        </PageContentStyle>
    )
}

export default TutorOnboardingS2Page
