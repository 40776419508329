import { useState, useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { FiSearch } from "react-icons/fi";
import { MdAdd } from 'react-icons/md';

import CreateCourseTooltip from "pages/auth/createCourse/CreateCourseTooltip";
import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/createCourse/createCourse.style';
import { inputSections, thumbnailTooltip } from "pages/auth/createCourse/data";

import { getCourseTagList, updateCourseDetail } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import {
    setThumbnailDetailPayloadLoading,
    setThumbnailDetailPayload,
    setClearThumbnailDetailPayload
} from 'redux/other/other.slice';
// import { setClearUploadDocument, setUploadDocumentPayload } from 'redux/document/document.slice';
import { resetUploadFile, setUploadFilePayload } from 'redux/storage/storage.slice';
import { setClearCourseTagList } from "redux/course/course.slice";
import { modalConst } from 'redux/local/local.const';
import { fileCategories } from 'redux/document/document.const';
import { orientationTypeConst } from "redux/other/other.const";

const CourseThumbnailSection = ({ clickedItem, setClickedItem }) => {
    const { tutorCourseDetail, courseTagList } = useAppState((state) => state.course)
    // const { uploadDocument } = useAppState((s) => s.document)
    const { uploadFile } = useAppState((s) => s.storage)
    const { thumbnailDetail } = useAppState((state) => state.other)
    const { modal } = useAppState(s => s.local)

    const dispatcher = useAppDispatcher()

    const [isAddCourseThumbnailEnabled, setIsAddCourseThumbnailEnabled] = useState(tutorCourseDetail?.data?.thumbnailPicUrl ? true : false)
    const [courseThumbnailDetail, setCourseThumbnailDetail] = useState(
        {
            thumbnailUrl: tutorCourseDetail?.data?.thumbnailPicUrl,
            category: tutorCourseDetail?.data?.category
        }
    )

    const isOpen = inputSections?.COURSE_THUMBNAIL?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    useEffect(() => {
        if (clickedItem === inputSections?.COURSE_THUMBNAIL?.value) {
            dispatcher(getCourseTagList({ course: tutorCourseDetail?.data?.id }))
        }

        return () => {
            dispatcher(setClearCourseTagList())
        }
    }, [clickedItem])

    useEffect(() => {
        if (uploadFile?.data && (uploadFile?.payload?.fileCategory === fileCategories.courseThumbnail.value)) {
            dispatcher(updateCourseDetail(
                tutorCourseDetail?.data?.id,
                {
                    thumbnailPicUrl: uploadFile?.data[0]?.fileUrl
                }
            ))
            setCourseThumbnailDetail({
                ...courseThumbnailDetail,
                thumbnailUrl: uploadFile?.data[0]?.fileUrl
            })
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])

    useEffect(() => {
        if (thumbnailDetail?.thumbnailDetailPayload?.url) {
            dispatcher(setThumbnailDetailPayloadLoading(true))
            dispatcher(updateCourseDetail(
                tutorCourseDetail?.data?.id,
                {
                    thumbnailPicUrl: thumbnailDetail?.thumbnailDetailPayload?.url
                }
            ))
            setCourseThumbnailDetail({
                ...courseThumbnailDetail,
                thumbnailUrl: thumbnailDetail?.thumbnailDetailPayload?.url
            })
            setTimeout(() => {
                dispatcher(setClearThumbnailDetailPayload())
                dispatcher(setModal({
                    ...modal,
                    [modalConst.PHOTO_SEARCH_MODAL.stateKey]: false
                }))
            }, 2000)
        }
    }, [thumbnailDetail?.thumbnailDetailPayload?.url])

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_THUMBNAIL?.value)) {
            setClickedItem(inputSections?.COURSE_THUMBNAIL?.value)
        } else {
            setClickedItem(null)
        }
    }

    const handleOnSelectImage = () => {
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories.courseThumbnail.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.IMAGE_PICKER_MODAL.stateKey]: true
        }))
    }

    const onHandleSearchThumbnail = () => {
        dispatcher(setThumbnailDetailPayload({
            ...thumbnailDetail?.thumbnailDetailPayload,
            keywords: courseTagList?.courseTagList?.results?.map((courseTag) => ({
                skill: courseTag?.masterTag?.tag
            })),
            query: tutorCourseDetail?.data?.category,
            orientation: orientationTypeConst.LANDSCAPE.value,
            dimensionUnit: 11
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.PHOTO_SEARCH_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-back-ground-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Thumbnail"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                {!isAddCourseThumbnailEnabled &&
                    <div className={cn(
                        "w-full px-3 py-3 my-2 flex items-center justify-center border border-dashed border-text-500 rounded-lg",
                        "font-bodyPri font-normal text-text-900 cursor-pointer"
                    )}
                        onClick={() => setIsAddCourseThumbnailEnabled(true)}
                    >
                        <MdAdd className={"text-xl text-text-900"} />
                        <span className={"text-xl"}>
                            {"Add Thumbnail"}
                        </span>
                    </div>
                }
                {isAddCourseThumbnailEnabled &&
                    <div className={"flex flex-col items-start justify-start gap-5"}>

                        <div className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {thumbnailTooltip}
                        </div>
                        <div className={"w-full mx-auto flex flex-wrap items-center justify-center gap-3 border-b pb-1.5"}>
                            <div className={cn(
                                "min-w-80 max-w-full pl-5 pr-1 py-1 flex items-center justify-between cursor-default space-x-5",
                                "font-bodyPri font-normal text-text-500 text-base",
                                "border-2 border-divider-medium rounded-full hover:outline-none hover:border-2 hover:border-text-400"
                            )}
                                onClick={onHandleSearchThumbnail}
                            >
                                <span className={"overflow-hidden truncate"}>
                                    {`e.g: ${courseThumbnailDetail?.category}`}
                                </span>
                                <span className={"flex items-center justify-center gap-1 rounded-full px-2 py-0.5 bg-primary-dark text-text-50 font-bodyPri font-light text-base"}>
                                    <FiSearch className={"text-text-50 text-base"} />
                                    <span className={"text-sm"}>
                                        {"Search"}
                                    </span>
                                </span>
                            </div>
                            <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                                <span className={""}>
                                    {"Or"}
                                </span>
                            </div>
                            <div
                                className={cn(
                                    "px-2 py-0.5 cursor-pointer border-2 border-primary-dark rounded-lg text-center",
                                    "font-bodyPri font-normal text-primary-dark text-base",
                                    "hover:bg-primary-dark hover:text-text-50"
                                )}
                                onClick={handleOnSelectImage}
                            >
                                <span className={""}>
                                    {"Upload"}
                                </span>
                            </div>
                        </div>
                        <div className={"w-full space-y-3"}>
                            <div className={"w-full font-bodyPri font-normal text-text-900 text-base text-center"}>
                                {"Current Image"}
                            </div>
                            <div className={"mx-auto w-full md:w-[50%] aspect-video rounded-lg overflow-hidden shadow-all-md"}>
                                <img
                                    src={courseThumbnailDetail?.thumbnailUrl}
                                    className={"w-full h-full object-cover"}
                                    alt={"Thumbnail-photo"}
                                />
                            </div>
                        </div>
                        {(tutorCourseDetail?.message) &&
                            <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                                {tutorCourseDetail?.message}
                            </div>
                        }
                    </div>
                }

            </CollapsableContainer>
        </div>
    )
}

export default CourseThumbnailSection;