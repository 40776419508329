
export type ThemeColor = {
    primary?: string;
    primary_dark?: string;
    primary_main?: string;
    primary_light?: string;
    secondary?: string;
    secondary_dark?: string;
    secondary_main?: string;
    secondary_light?: string;
    accent?: string;
    accent_dark?: string;
    accent_main?: string;
    accent_light?: string;
    footer?: string;
    radius?: string;
}


export type ShadCnColor = {
    "--background": string;
    "--foreground": string;
    "--card": string;
    "--card-foreground": string;
    "--popover": string;
    "--popover-foreground": string;
    "--muted": string;
    "--muted-foreground": string;
    "--primary": string;
    "--primary-foreground": string;
    "--secondary": string;
    "--secondary-foreground": string;
    "--accent": string;
    "--accent-foreground": string;
    "--destructive": string;
    "--destructive-foreground": string;
    "--border": string;
    "--input": string;
    "--ring": string;
    "--chart-1": string;
    "--chart-2": string;
    "--chart-3": string;
    "--chart-4": string;
    "--chart-5": string;
}

export type ShadCnTheme = {
    light: ShadCnColor;
    dark: ShadCnColor;
}

export type ThemeMode = string | undefined


export type TColorInstance = {
    accent: string;
    accent_dark: string;
    accent_light: string;
    accent_main: string;

    footer: string;

    primary: string;
    primary_dark: string;
    primary_light: string;
    primary_main: string;

    secondary: string;
    secondary_dark: string;
    secondary_light: string;
    secondary_main: string;
}

export const defaultColor = {
    
    primary: "#0741ad",
    primary_dark: "#0741ad",
    primary_main: "#2196f3",
    primary_light: "#e3f2fd",

    secondary: "#ff1d58",
    secondary_dark: "#ff1d58",
    secondary_main: "#FF4081",
    secondary_light: "#ffe5eb",

    accent: "#ad7307",
    accent_dark: "#ad7307",
    accent_main: "#f37f21",
    accent_light: "#fff685",

    footer: "#14212d",

    radius: "0.5rem",

    back_ground_light: "#fafafa",
    back_ground_darkLight: "#eeeeee",
    back_ground_medium: "#e0e0e0",
    back_ground_lightDark: "#9e9e9e",
    back_ground_dark: "#424242",
    back_ground_lightBlue: "#F3F7FF",
    back_ground_black: "#14212d",

    divider_light: "#fafafa",
    divider_darkLight: "#eeeeee",
    divider_medium: "#e0e0e0",
    divider_lightDark: "#9e9e9e",
    divider_dark: "#424242",

    text_50: "#fafafa",
    text_100: "#f5f5f5",
    text_200: "#eeeeee",
    text_300: "#e0e0e0",
    text_500: "#9e9e9e",
    text_600: "#757575",
    text_400: "#bdbdbd",
    text_700: "#616161",
    text_800: "#424242",
    text_900: "#212121",
}

export const shadCnDefaultColor: ShadCnTheme = {
    light: {
        "--primary": "219 92% 35%",
        "--primary-foreground": "210 92% 100%",
        "--secondary": "344 100% 65%",
        "--secondary-foreground": "344 100% 0%",
        "--accent": "39 92% 35%",
        "--accent-foreground": "39 92% 100%",
        "--ring": "219 92% 35%",
        "--border": "219 31.8% 91.4%",
        "--input": "219 31.8% 91.4%",
        "--background": "219 95% 99%",
        "--foreground": "219 95% 2%",
        "--card": "219 95% 98%",
        "--card-foreground": "219 95% 3%",
        "--popover": "219 95% 98%",
        "--popover-foreground": "219 95% 3%",
        "--muted": "219 30% 90%",
        "--muted-foreground": "219 10% 10%",
        "--destructive": "0 70% 40%",
        "--destructive-foreground": "0 50% 99%",
        "--chart-1": "12 76% 61%",
        "--chart-2": "173 58% 39%",
        "--chart-3": "197 37% 24%",
        "--chart-4": "43 74% 66%",
        "--chart-5": "27 87% 67%",
    },
    dark: {
        "--primary": "219 92% 35%",
        "--primary-foreground": "210 92% 100%",
        "--secondary": "344 100% 65%",
        "--secondary-foreground": "344 100% 0%",
        "--accent": "39 92% 35%",
        "--accent-foreground": "39 92% 100%",
        "--ring": "219 92% 35%",
        "--border": "219 20% 15%",
        "--input": "219 20% 15%",
        "--background": "219 95% 1%",
        "--foreground": "219 95% 98%",
        "--card": "219 95% 3%",
        "--card-foreground": "219 95% 98%",
        "--popover": "219 95% 3%",
        "--popover-foreground": "219 95% 98%",
        "--muted": "219 30% 15%",
        "--muted-foreground": "219 30% 80%",
        "--destructive": "0 70% 40%",
        "--destructive-foreground": "0 50% 99%",
        "--chart-1": "220 70% 50%",
        "--chart-2": "160 60% 45%",
        "--chart-3": "30 80% 55%",
        "--chart-4": "280 65% 60%",
        "--chart-5": "340 75% 55%",
    }
}

export function hexToHSL(hex: string) : number[] {

    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h = 0, s = 0, l = (max + min) / 2;

    if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100);

    return [h, s, l]
}


export function hexToHSLString(hex?: string) : string | null {
    if (!hex) return null;

    const [h, s, l] = hexToHSL(hex)

    return `${h} ${s}% ${l}%`;
}


export function setThemeColor(themeColor: ThemeColor, themeMode: ThemeMode): void {
    if (typeof window == 'undefined') return;

    document.documentElement.style.setProperty('--primary-dark', hexToHSLString(themeColor?.primary_dark) || window?.getComputedStyle(document.documentElement)?.getPropertyValue("--primary-dark"))
    document.documentElement.style.setProperty('--primary-main', hexToHSLString(themeColor?.primary_main) || window?.getComputedStyle(document.documentElement)?.getPropertyValue("--primary-main"))
    document.documentElement.style.setProperty('--primary-light', hexToHSLString(themeColor?.primary_light) || window?.getComputedStyle(document.documentElement)?.getPropertyValue("--primary-light"))
    document.documentElement.style.setProperty('--secondary-dark', hexToHSLString(themeColor?.secondary_dark) || window?.getComputedStyle(document.documentElement)?.getPropertyValue("--secondary-dark"))
    document.documentElement.style.setProperty('--secondary-main', hexToHSLString(themeColor?.secondary_main) || window?.getComputedStyle(document.documentElement)?.getPropertyValue("--secondary-main"))
    document.documentElement.style.setProperty('--secondary-light', hexToHSLString(themeColor?.secondary_light) || window?.getComputedStyle(document.documentElement)?.getPropertyValue("--secondary-light"))
    document.documentElement.style.setProperty('--accent-dark', hexToHSLString(themeColor?.accent_dark) || window?.getComputedStyle(document.documentElement)?.getPropertyValue("--accent-dark"))
    document.documentElement.style.setProperty('--accent-main', hexToHSLString(themeColor?.accent_main) || window?.getComputedStyle(document.documentElement)?.getPropertyValue("--accent-main"))
    document.documentElement.style.setProperty('--accent-light', hexToHSLString(themeColor?.accent_light) || window?.getComputedStyle(document.documentElement)?.getPropertyValue("--accent-light"))
    document.documentElement.style.setProperty('--footer', hexToHSLString(themeColor?.footer) || window?.getComputedStyle(document.documentElement)?.getPropertyValue("--footer"))
    document.documentElement.style.setProperty('--radius', hexToHSLString(themeColor?.radius) || window?.getComputedStyle(document.documentElement)?.getPropertyValue("--radius"))
    
    const shadCnTheme: any = shadCnThemeGenerator(themeColor?.primary, themeColor?.secondary, themeColor?.accent) || shadCnDefaultColor

    const shadCnColor: any = shadCnTheme[themeMode || "dark"]
    const shadCnColorKeys: string[] = Object.keys(shadCnColor || {})
    shadCnColorKeys.forEach((key: string) => {
        document.documentElement.style.setProperty(key, (key ? shadCnColor[key] : window?.getComputedStyle(document.documentElement)?.getPropertyValue(key)))
    })
}

export function shadCnThemeGenerator(primary: string | undefined, secondary: string | undefined, accent: string | undefined): ShadCnTheme | null {
    if (!primary) return null

    const primaryHsl = hexToHSL(primary)
    const secondaryHsl = hexToHSL(secondary || defaultColor.secondary)
    const accentHsl = hexToHSL(accent || defaultColor.accent)

    const shadCnThemeConst: ShadCnTheme = {
        ...shadCnDefaultColor,
        light: {
            ...shadCnDefaultColor.light,
            "--primary": `${primaryHsl[0]} ${primaryHsl[1]}% ${primaryHsl[2]}%`,
            "--primary-foreground": `${primaryHsl[0]} ${primaryHsl[1]}% ${primaryHsl[2] > 60 ? 0 : 100}%`,
            "--secondary": `${secondaryHsl[0]} ${secondaryHsl[1]}% ${secondaryHsl[2]}%`,
            "--secondary-foreground": `${secondaryHsl[0]} ${secondaryHsl[1]}% ${secondaryHsl[2] > 60 ? 0 : 100}%`,
            "--accent": `${accentHsl[0]} ${accentHsl[1]}% ${accentHsl[2]}%`,
            "--accent-foreground": `${accentHsl[0]} ${accentHsl[1]}% ${accentHsl[2] > 60 ? 0 : 100}%`,
            "--ring": `${primaryHsl[0]} ${primaryHsl[1]}% ${primaryHsl[2]}%`,
            "--border": `${primaryHsl[0]} 40% 90%`,
            "--input": `${primaryHsl[0]} 40% 90%`,
            "--background": `${primaryHsl[0]} 95% 99%`,
            "--foreground": `${primaryHsl[0]} 95% 2%`,
            "--card": `${primaryHsl[0]} 95% 98%`,
            "--card-foreground": `${primaryHsl[0]} 95% 3%`,
            "--popover": `${primaryHsl[0]} 95% 98%`,
            "--popover-foreground": `${primaryHsl[0]} 95% 3%`,
            "--muted": `${primaryHsl[0]} 30% 90%`,
            "--muted-foreground": `${primaryHsl[0]} 10% 10%`,
        },
        dark: {
            ...shadCnDefaultColor.dark,
            "--primary": `${primaryHsl[0]} ${primaryHsl[1]}% ${primaryHsl[2]}%`,
            "--primary-foreground": `${primaryHsl[0]} ${primaryHsl[1]}% ${primaryHsl[2] > 60 ? 0 : 100}%`,
            "--secondary": `${secondaryHsl[0]} ${secondaryHsl[1]}% ${secondaryHsl[2]}%`,
            "--secondary-foreground": `${secondaryHsl[0]} ${secondaryHsl[1]}% ${secondaryHsl[2] > 60 ? 0 : 100}%`,
            "--accent": `${accentHsl[0]} ${accentHsl[1]}% ${accentHsl[2]}%`,
            "--accent-foreground": `${accentHsl[0]} ${accentHsl[1]}% ${accentHsl[2] > 60 ? 0 : 100}%`,
            "--ring": `${primaryHsl[0]} ${primaryHsl[1]}% ${primaryHsl[2]}%`,
            "--border": `${primaryHsl[0]} 20% 15%`,
            "--input": `${primaryHsl[0]} 20% 15%`,
            "--background": `${primaryHsl[0]} 95% 1%`,
            "--foreground": `${primaryHsl[0]} 95% 98%`,
            "--card": `${primaryHsl[0]} 95% 3%`,
            "--card-foreground": `${primaryHsl[0]} 95% 98%`,
            "--popover": `${primaryHsl[0]} 95% 3%`,
            "--popover-foreground": `${primaryHsl[0]} 95% 98%`,
            "--muted": `${primaryHsl[0]} 30% 15%`,
            "--muted-foreground": `${primaryHsl[0]} 30% 80%`,
        }
    }

    return shadCnThemeConst
}

export const getOrgThemeColors = (colorInstance: TColorInstance) => {
    return {

        accent: colorInstance?.accent,
        accent_dark: colorInstance?.accent_dark,
        accent_light: colorInstance?.accent_light,
        accent_main: colorInstance?.accent_main,

        footer: colorInstance?.footer,

        primary: colorInstance?.primary,
        primary_dark: colorInstance?.primary_dark,
        primary_light: colorInstance?.primary_light,
        primary_main: colorInstance?.primary_main,

        secondary: colorInstance?.secondary,
        secondary_dark: colorInstance?.secondary_dark,
        secondary_light: colorInstance?.secondary_light,
        secondary_main: colorInstance?.secondary_main,
    }
}