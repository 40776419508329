import { createContext, useContext, useEffect, useReducer, useState } from "react";

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

const initialState = {
  isLoading: false,
  loadSuccess: null,
  user: null,
  loadError: null
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_USER":
      return {
        ...state,
        isLoading: true
      }
    case "SUCCESS_USER":
      return {
        ...state,
        isLoading: false,
        loadSuccess: true,
        user: action.payload,
      }
    case "ERROR_USER":
      return {
        ...state,
        isLoading: false,
        loadError: action.payload,
      }
    default:
      return state;
  }
}

export function UserContextProvider({ children }) {
  const [state, dispatch] = useReducer(userReducer, initialState)
  const [isMount, setIsMount] = useState(false)

  useEffect(() => {
    setIsMount(true)
  }, [])
  
  const value = {
    ...state,
    dispatch
  };

  if (!isMount) {
    return null
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
