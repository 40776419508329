// Public Page
import AppointmentPage from "pages/global/appointment";
import AppointmentsPage from "pages/global/appointments";
import BuyGifCardPage from "pages/global/buyGiftCard";
import CompanyPage from "pages/global/company/index";
import CoursePage from "pages/global/course/CoursePage";
import FeatureGetYourAccountPage from "pages/global/features/get-your-account";
import FindCoursesPage from "pages/global/findCourses/FindCoursesPage";
import FindTutorsPage from 'pages/global/findTutors/FindTutorsPage';
import HealthCheckPage from "pages/global/healthCheck";
import HomePage from 'pages/global/home/HomePage';
import LogInPage from "pages/global/logIn/LogInPage";
import PageNotFoundPage from "pages/global/pageNotFound/PageNotFoundPage";
import PromoSignUpPage from "pages/global/promoSignup/PromoSignUpPage";
import ReferralSignUpPage from "pages/global/referralSignUp";
import ResetPasswordPage from "pages/global/resetPassword/ResetPasswordPage"
import SegmentsPage from "pages/global/segments";
import SignUpPage from "pages/global/signUp/SignUpPage";
import TeachWithUsPage from "pages/global/teachWithUs/TeachWithUsPage";
import ThankYouPage from "pages/global/thankYou/ThankYouPage";
import TutorPage from "pages/global/tutor/TutorPage";
import TutorCourse from "pages/global/tutorCourse/TutorCourse";
import VerifySignup from "pages/global/verify-signup";

// Private Page
import AppointmentListPage from "pages/auth/appointments";
import BookingsPage from "pages/auth/bookings/list";
import BookingSummaryPage from "pages/auth/bookingSummary/BookingSummaryPage";
import BookingConfirmationPage from "pages/auth/bookingConfirmation/BookingConfirmationPage";
import CheckoutBookingPage from "pages/auth/checkoutBooking/CheckoutBookingPage";
import CheckoutReschedulePage from "pages/auth/checkoutReschedule/CheckoutReschedulePage";
import CheckoutTokenPage from "pages/auth/checkoutToken/CheckoutTokenPage";
import CreateCertificate from "pages/auth/certificate";
import CreateCoursePage from "pages/auth/createCourse/CreateCoursePage";
import CreateCoursePreviewPage from "pages/auth/createCoursePreview/CreateCoursePreview";
import CrmLayoutPage from "pages/auth/crm/CrmLayoutPage";
import DashboardPage from "pages/auth/dashboard/DashboardPage";
import DownloadInvitationPage from "pages/auth/downloadInvitation/DownloadInvitationPage";
import DownloadsPage from "pages/auth/downloads/DownloadsPage";
import DrivePage from "pages/auth/drive/DrivePage";
import EditAppointmentDetailPage from "pages/auth/editAppointment";
import EditCertificatePage from "pages/auth/certificate/editCertificate/editCertificate";
import EditCoursePage from "pages/auth/editCourse";
import EditCompanyPage from "pages/auth/editCompany";
import EditDownloadPage from "pages/auth/editDownload/EditDownloadPage";
import EditEventlyPage from "pages/auth/editEvently/EditEventlyPage";
import EditLMSArticlePage from "pages/auth/edulyteLms/editLmsPages/editLmsArticle/EditLMSArticlePage";
import EditLMSCoursePage from "pages/auth/edulyteLms/editLmsPages/editLmsCourse/EditLMSCoursePage";
import EditLMSLecturePage from "pages/auth/edulyteLms/editLmsPages/editLmsLecture/EditLMSLecturePage";
import EditLMSQuestionPage from "pages/auth/edulyteLms/editLmsPages/editLmsQuestion/EditLMSQuestionPage";
import EditLMSQuizPage from "pages/auth/edulyteLms/editLmsPages/editLmsQuiz/EditLMSQuizPage";
import EdulyteLMS from "pages/auth/edulyteLms/EdulyteLMS";
import EventlyInvitationPage from "pages/auth/eventlyInvitation/EventlyInvitationPage";
import EventsListPage from "pages/auth/eventlyList/EventsListPage";
import GeneralSettingsPage from "pages/auth/settings/GeneralSettingsPage";
import GoogleCalendarConsentPage from "pages/auth/schedule/GoogleCalendarConsentPage";
import Integrations from "pages/auth/settings/components/integrations";
import MeetingPage from "pages/auth/meeting/MeetingPage";
import LmsCourseInvitationPage from "pages/auth/edulyteLms/components/lmsCourse/LmsCourseInvitationPage";
import LmsCoursePage from "pages/auth/edulyteLms/components/lmsCourse/LMSCoursePage";
import LmsQuizInvitationPage from "pages/auth/edulyteLms/components/lmsQuiz/LmsQuizInvitationPage";
import LMSQuestionPage from "pages/auth/edulyteLms/components/lmsQuestion/LMSQuestionPage";
import LMSQuizPage from "pages/auth/edulyteLms/components/lmsQuiz/LMSQuizPage";
import MeetingRoomPage from "pages/auth/meetingRoom/MeetingRoomPage";
import MessagePage from "pages/auth/message";
import MyCoursesPage from "pages/auth/myCourses";
import MyDownloadable from "pages/auth/myDownloadable";
import MyTutorsPage from "pages/auth/myTutors";
import MyLinks from "pages/auth/myLinks";
import OfferingsPage from "pages/auth/offerings/OfferingsPage";
import OffersPage from "pages/auth/offersAndDiscount";
import OrderConfirmationPage from "pages/auth/orderConfirmation/OrderConfirmationPage";
import OrderListPage from "pages/auth/orderList/OrderListPage";
import PricingPage from "pages/auth/subscriptions/PricingPage";
import PaymentConfirmationPage from "pages/auth/paymentConfirmation/PaymentConfirmationPage";
import PreferencesPage from "pages/auth/preferences";
import PreOnboardingPage from "pages/auth/preOnboard/PreOnboardingPage";
import ProductPage from "pages/auth/product/ProductPage";
import ProfileLayoutPage from "pages/auth/profile/index";
import QuizAIPage from "pages/auth/edulyteLms/components/lmsQuiz/ai-quiz/QuizAIPage";
import ReferralPage from "pages/auth/referral/ReferralPage";
import SettingsPage from "pages/auth/settings/SettingsPage";
import SchedulePage from "pages/auth/schedule/MySchedulePage";
import StudentLmsCourse from "pages/auth/studentLms/course";
import StudentLmsCourses from "pages/auth/studentLms/courses/Courses"
import StudentLmsQuiz from "pages/auth/studentLms/quiz";
import StudentLmsQuizzes from "pages/auth/studentLms/quizzes";
import StudentCertificates from "pages/auth/certificate/studentCertificiates";
import Subscriptions from "pages/auth/subscriptions";
import SupportPage from "pages/auth/support";
import TemplatePage from "pages/auth/createTemplate/TemplatePage";
import TutorProductsPage from "pages/auth/tutorProductsPage/TutorProductsPage";
import ToolsPage from "pages/auth/tools";
import TutorOnboardingS1Page from "pages/auth/onboarding/TutorOnboardingS1Page";
import TutorOnboardingS2Page from "pages/auth/tutorOnboard/TutorOnboardingS2Page";
import TokenPaymentConfirmationPage from "pages/auth/tokenPaymentConfirmation/TokenPaymentConfirmationPage";
import TokenWalletConfirmationPage from "pages/auth/tokenWalletConfirmation/TokenWalletConfirmationPage";
import UnauthorizedPage from "pages/global/unauthorized/UnauthorizedPage";
import UserFinancePage from "pages/auth/userFinance/UserFinancePage";
import UserMarketingPage from "pages/auth/userMarketing/UserMarketingPage";
import UserResourcesPage from "pages/auth/userResources/UserResourcesPage";
import VideoGuide from 'pages/auth/videoGuide/index'
import WalletPage from "pages/auth/wallet/WalletPage";
import WalletConfirmationPage from "pages/auth/walletConfirmation/WalletConfirmationPage";

import { userRoles } from 'redux/auth/auth.const';
import StripeConnectedAccountPage from "pages/auth/stripeConnectedAccount/StripeConnectedAccountPage";
import Return from "pages/auth/stripeConnectedAccount/Return";
import Refresh from "pages/auth/stripeConnectedAccount/Refresh";

export const pagesInfo = {
    // Public pages
    APPOINTMENT: {
        pageName: 'APPOINTMENT_PAGE',
        pagePath: '/appointment',
        routePath: 'appointment/:slug',
        component: AppointmentPage,
        roles: [],
    },
    APPOINTMENTS: {
        pageName: 'APPOINTMENTS_PAGE',
        pagePath: '/appointments',
        routePath: '/appointments/*',
        component: AppointmentsPage,
        roles: [],
    },
    BUY_GIFT_CARD: {
        pageName: 'BUY_GIFT_CARD_PAGE',
        pagePath: '/buy-gift-card',
        routePath: 'buy-gift-card',
        component: BuyGifCardPage,
        roles: [],
    },
    COMPANY: {
        pageName: 'COMPANY',
        pagePath: '/company',
        routePath: '/company/:companyName',
        component: CompanyPage,
        roles: []
    },
    COURSE: {
        pageName: 'COURSE_PAGE',
        pagePath: '/class',
        routePath: 'class/:courseId',
        component: CoursePage,
        roles: [],
    },
    FEATURE_GET_YOUR_ACCOUNT: {
        pageName: 'FEATURE_GET_YOUR_ACCOUNT_PAGE',
        pagePath: '/features/get-your-account',
        routePath: 'features/get-your-account',
        component: FeatureGetYourAccountPage,
        roles: [],
    },
    FIND_COURSES: {
        pageName: 'FIND_COURSES_PAGE',
        pagePath: '/classes',
        routePath: 'classes/*',
        component: FindCoursesPage,
        roles: [],
    },
    FIND_TUTORS: {
        pageName: 'FIND_TUTORS_PAGE',
        pagePath: '/tutors',
        routePath: 'tutors/*',
        component: FindTutorsPage,
        roles: [],
    },
    HEALTH_CHECK: {
        pageName: 'HEALTH_CHECK_PAGE',
        pagePath: '/health-check',
        routePath: '/health-check',
        component: HealthCheckPage,
        roles: [],
    },
    HOME: {
        pageName: 'HOME_PAGE',
        pagePath: '/',
        routePath: '/',
        component: HomePage,
        roles: [],
    },
    LOG_IN: {
        pageName: 'LOG_IN_PAGE',
        pagePath: '/log-in',
        routePath: 'log-in',
        component: LogInPage,
        roles: [],
    },
    PAGE_NOT_FOUND: {
        pageName: 'PAGE_NOT_FOUND_PAGE',
        pagePath: '/page-not-found',
        routePath: '*',
        component: PageNotFoundPage,
        roles: [],
    },
    PROMO_SIGN_UP: {
        pageName: 'PROMO_SIGN_UP_PAGE',
        pagePath: '/promo-signup',
        routePath: 'promo-signup',
        component: PromoSignUpPage,
        roles: [],
    },
    REFERRAL_SIGN_UP: {
        pageName: 'REFERRAL_SIGN_UP_PAGE',
        pagePath: '/referral',
        routePath: 'referral/:referralCode',
        component: ReferralSignUpPage,
        roles: [],
    },
    RESET_PASSWORD: {
        pageName: 'RESET_PASSWORD_PAGE',
        pagePath: '/reset-pw',
        routePath: '/reset-pw',
        component: ResetPasswordPage,
        roles: [],
    },
    SEGMENTS: {
        pageName: 'SEGMENTS_PAGE',
        pagePath: '/skills',
        routePath: 'skills',
        component: SegmentsPage,
        roles: [],
    },
    SIGN_UP: {
        pageName: 'SIGN_UP_PAGE',
        pagePath: '/sign-up',
        routePath: 'sign-up',
        component: SignUpPage,
        roles: [],
    },
    STRIPE_CONNECTED_ACCOUNT: {
        pageName: 'STRIPE_CONNECTED_ACCOUNT',
        pagePath: '/stripe/connect',
        routePath: '/stripe/connect',
        component: StripeConnectedAccountPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    STRIPE_CONNECTED_ACCOUNT_REFRESH: {
        pageName: 'STRIPE_CONNECTED_ACCOUNT_REFRESH',
        pagePath: '/refresh/:connectedAccountId',
        routePath: '/refresh/:connectedAccountId',
        component: Refresh,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    STRIPE_CONNECTED_ACCOUNT_RETURN: {
        pageName: 'STRIPE_CONNECTED_ACCOUNT_RETURN',
        pagePath: '/return/:connectedAccountId',
        routePath: '/return/:connectedAccountId',
        component: Return,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    TEACH_WITH_US: {
        pageName: 'TEACH_WITH_US_PAGE',
        pagePath: '/teach-with-us',
        routePath: 'teach-with-us',
        component: TeachWithUsPage,
        roles: [],
    },
    THANK_YOU: {
        pageName: 'THANK_YOU_PAGE',
        pagePath: '/thankyou',
        routePath: 'thankyou',
        component: ThankYouPage,
        roles: [],
    },
    TUTOR: {
        pageName: 'TUTOR_PAGE',
        pagePath: '/tutor',
        routePath: 'tutor/:userId',
        component: TutorPage,
        roles: [],
    },
    TUTOR_COURSE: {
        pageName: 'TUTOR_COURSE',
        pagePath: '/tutor',
        routePath: 'tutor/:userId/classes',
        component: TutorCourse,
        roles: [],
    },
    VERIFY_SIGNUP: {
        pageName: 'VERIFY_SIGNUP_PAGE',
        pagePath: '/signup/verify',
        routePath: '/signup/verify',
        component: VerifySignup,
        roles: [],
    },

    // Private pages
    APPOINTMENT_LIST: {
        pageName: 'APPOINTMENT_LIST',
        pagePath: '/appointments',
        routePath: 'appointments',
        component: AppointmentListPage,
        roles: [userRoles.TUTOR.value]
    },
    BOOKINGS: {
        pageName: 'BOOKINGS_PAGE',
        pagePath: '/bookings',
        routePath: 'bookings',
        component: BookingsPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    BOOKING_CONFIRMATION: {
        pageName: 'BOOKING_CONFIRMATION_PAGE',
        pagePath: '/booking/confirmation',
        routePath: 'booking/confirmation',
        component: BookingConfirmationPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    BOOKING_SUMMARY: {
        pageName: 'BOOKING_SUMMARY_PAGE',
        pagePath: '/booking-summary',
        routePath: 'booking-summary/:bookingId',
        component: BookingSummaryPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    CHECKOUT_BOOKING: {
        pageName: 'CHECKOUT_BOOKING_PAGE',
        pagePath: '/checkout/booking',
        routePath: 'checkout/booking/:bookingId',
        component: CheckoutBookingPage,
        roles: [userRoles.STUDENT.value]
    },
    CHECKOUT_TOKEN: {
        pageName: 'CHECKOUT_TOKEN_PAGE',
        pagePath: '/checkout/tokens',
        routePath: 'checkout/tokens',
        component: CheckoutTokenPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    CHECKOUT_RESCHEDULE: {
        pageName: 'CHECKOUT_RESCHEDULE_PAGE',
        pagePath: '/checkout/reschedule',
        routePath: 'checkout/reschedule/:sessionId',
        component: CheckoutReschedulePage,
        roles: [userRoles.STUDENT.value]
    },
    CREATE_COURSE: {
        pageName: 'CREATE_COURSE',
        pagePath: '/class',
        routePath: 'class/:courseId/edit/*',
        component: CreateCoursePage,
        roles: [userRoles.TUTOR.value]
    },
    CREATE_COURSE_PREVIEW: {
        pageName: 'COURSE_PREVIEW',
        pagePath: '/class',
        routePath: 'class/:courseId/preview',
        component: CreateCoursePreviewPage,
        roles: [userRoles.TUTOR.value]
    },
    CREATE_CERTIFICATE: {
        pageName: 'CREATE_CERTIFICATE',
        pagePath: '/resources',
        routePath: 'certificate',
        component: CreateCertificate,
        roles: [userRoles.TUTOR.value]
    },
    LMS_COURSE_PAGE: {
        pageName: 'LMS_COURSE_PAGE',
        pagePath: '/courses',
        routePath: 'courses',
        component: LmsCoursePage,
        roles: [userRoles.TUTOR.value]
    },
    LMS_QUESTIONS_PAGE: {
        pageName: 'LMS_QUESTIONS_PAGE',
        pagePath: '/questions',
        routePath: 'questions',
        component: LMSQuestionPage,
        roles: [userRoles.TUTOR.value]
    },
    DASHBOARD: {
        pageName: 'DASHBOARD_PAGE',
        pagePath: '/dashboard',
        routePath: 'dashboard',
        component: DashboardPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    DOWNLOADS: {
        pageName: 'DOWNLOAD_PAGE',
        pagePath: '/products/downloads',
        routePath: 'products/downloads',
        component: DownloadsPage,
        roles: [userRoles.TUTOR.value]
    },
    DRIVE: {
        pageName: 'DRIVE_PAGE',
        pagePath: 'resources/fileBox',
        routePath: 'resources/fileBox/*',
        component: DrivePage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    EDIT_APPOINTMENT: {
        pageName: 'EDIT_APPOINTMENT_PAGE',
        pagePath: '/appointments',
        routePath: 'appointments/:appointmentId/edit',
        component: EditAppointmentDetailPage,
        roles: [userRoles.TUTOR.value]
    },
    EDIT_COMPANY: {
        pageName: 'EDIT_COMPANY_PAGE',
        pagePath: '/edit-company',
        routePath: 'edit-company/:companyName',
        component: EditCompanyPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    EDIT_COURSE: {
        pageName: 'EDIT_COURSE_PAGE',
        pagePath: '/edit-class',
        routePath: 'edit-class/:courseId',
        component: EditCoursePage,
        roles: [userRoles.TUTOR.value]
    },
    EDIT_DOWNLOAD: {
        pageName: 'EDIT_DOWNLOAD_PAGE',
        pagePath: '/downloads',
        routePath: 'downloads/:downloadId/edit/*',
        component: EditDownloadPage,
        roles: [userRoles.TUTOR.value]
    },
    EDIT_EVENTLY: {
        pageName: 'EDIT_EVENTLY_PAGE',
        pagePath: '/evently',
        routePath: 'evently/:eventlyId/edit',
        component: EditEventlyPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    EDIT_LMS_ARTICLE: {
        pageName: 'EDIT_LMS_ARTICLE_PAGE',
        pagePath: '/lms/articles',
        routePath: 'lms/articles/:articleId/edit/*',
        component: EditLMSArticlePage,
        roles: [userRoles.TUTOR.value]
    },
    EDIT_LMS_LECTURE: {
        pageName: 'EDIT_LMS_LECTURE_PAGE',
        pagePath: '/lms/lectures',
        routePath: 'lms/lectures/:lectureId/edit/*',
        component: EditLMSLecturePage,
        roles: [userRoles.TUTOR.value]
    },
    EDIT_LMS_COURSE: {
        pageName: 'EDIT_LMS_COURSE_PAGE',
        pagePath: '/lms/courses',
        routePath: 'lms/courses/:courseId/edit/*',
        component: EditLMSCoursePage,
        roles: [userRoles.TUTOR.value]
    },
    EDIT_LMS_QUIZ: {
        pageName: 'EDIT_LMS_QUIZ_PAGE',
        pagePath: '/lms/quizzes',
        routePath: 'lms/quizzes/:quizId/edit/*',
        component: EditLMSQuizPage,
        roles: [userRoles.TUTOR.value]
    },
    EDIT_CERTIFICATE: {
        pageName: 'EDIT_CERTIFICATE',
        pagePath: '/certificate',
        routePath: 'certificate/:certificateId/edit/*',
        component: EditCertificatePage,
        roles: [userRoles.TUTOR.value]
    },
    CREATE_AI_QUIZ: {
        pageName: "CREATE_AI_QUIZ_PAGE",
        pagePath: '/lms/quizzes/ai',
        routePath: '/lms/quizzes/ai',
        component: QuizAIPage,
        roles: [userRoles.TUTOR.value]
    },
    DOWNLOAD_INVITATION: {
        pageName: 'DOWNLOAD_INVITATION_PAGE',
        pagePath: '/invitation/download',
        routePath: 'invitation/download',
        component: DownloadInvitationPage,
        roles: [userRoles.STUDENT.value]
    },
    EDIT_LMS_QUESTION: {
        pageName: 'EDIT_LMS_QUESTION_PAGE',
        pagePath: '/lms/questions',
        routePath: 'lms/questions/:questionId/edit/*',
        component: EditLMSQuestionPage,
        roles: [userRoles.TUTOR.value]
    },
    EDULYTE_LMS: {
        pageName: 'EDULYTE_LMS',
        pagePath: '/lms',
        routePath: 'lms/*',
        component: EdulyteLMS,
        roles: [userRoles.TUTOR.value]
    },
    EVENTLY_INVITATION: {
        pageName: 'EVENTLY_INVITATION_PAGE',
        pagePath: '/invitation/evently',
        routePath: 'invitation/evently',
        component: EventlyInvitationPage,
        roles: [userRoles.STUDENT.value]
    },
    EVENTS_LIST: {
        pageName: 'EVENTS_LIST_PAGE',
        pagePath: '/evently',
        routePath: 'evently',
        component: EventsListPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    GENERAL_SETTINGS: {
        pageName: 'GENERAL_SETTINGS_PAGE',
        pagePath: '/settings/general',
        routePath: 'settings/general/*',
        component: GeneralSettingsPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    INTEGRATIONS: {
        pageName: 'INTEGRATIONS',
        pagePath: '/settings/integrations',
        routePath: 'settings/integrations',
        component: Integrations,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    GOOGLE_CALENDAR_CONSENT: {
        pageName: "GOOGLE_CALENDAR_CONSENT_PAGE",
        pagePath: '/calendar/settings/connect/google-calendar-auth',
        routePath: '/calendar/settings/connect/google-calendar-auth',
        component: GoogleCalendarConsentPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    LMS_COURSE_INVITATION: {
        pageName: 'LMS_COURSE_INVITATION_PAGE',
        pagePath: '/invitation/course',
        routePath: 'invitation/course',
        component: LmsCourseInvitationPage,
        roles: [userRoles.STUDENT.value]
    },
    LMS_QUIZ_INVITATION: {
        pageName: 'LMS_QUIZ_INVITATION_PAGE',
        pagePath: '/invitation/quiz',
        routePath: 'invitation/quiz',
        component: LmsQuizInvitationPage,
        roles: [userRoles.STUDENT.value]
    },
    MEETING: {
        pageName: 'MEETING_PAGE',
        pagePath: '/meeting',
        routePath: 'meeting',
        component: MeetingPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    MEETING_ROOM: {
        pageName: 'MEETING_ROOM_PAGE',
        pagePath: '/meeting',
        routePath: 'meeting/:code',
        component: MeetingRoomPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    MESSAGES: {
        pageName: 'MESSAGES_PAGE',
        pagePath: '/messages',
        routePath: 'messages/*',
        component: MessagePage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    MY_TUTORS: {
        pageName: 'MY_TUTORS_PAGE',
        pagePath: '/my-tutors',
        routePath: 'my-tutors',
        component: MyTutorsPage,
        roles: [userRoles.STUDENT.value]
    },
    MY_COURSES: {
        pageName: 'MY_COURSES_PAGE',
        pagePath: '/my-classes',
        routePath: 'my-classes',
        component: MyCoursesPage,
        roles: [userRoles.STUDENT.value]
    },
    MY_DOWNLOADS: {
        pageName: 'MY_DOWNLOAD_PAGE',
        pagePath: '/my-downloads',
        routePath: 'my-downloads',
        component: MyDownloadable,
        roles: [userRoles.STUDENT.value]
    },
    MY_LINKS: {
        pageName: 'MY_LINKS',
        pagePath: 'marketing/my-links',
        routePath: 'marketing/my-links',
        component: MyLinks,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    OFFERINGS: {
        pageName: 'OFFERINGS_PAGE',
        pagePath: '/live-classes',
        routePath: 'live-classes',
        component: OfferingsPage,
        roles: [userRoles.TUTOR.value]
    },
    OFFERS: {
        pageName: 'OFFERS',
        pagePath: '/resources/partner-offers',
        routePath: 'resources/partner-offers',
        component: OffersPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    ORDERS: {
        pageName: 'ORDERS',
        pagePath: '/finance/orders',
        routePath: 'finance/orders',
        component: OrderListPage,
        roles: [userRoles.STUDENT.value]
    },
    ORDER_CONFIRMATION: {
        pageName: 'ORDER_CONFIRMATION_PAGE',
        pagePath: '/order/confirmation',
        routePath: 'order/confirmation',
        component: OrderConfirmationPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    PAYMENT_CONFIRMATION: {
        pageName: 'PAYMENT_CONFIRMATION_PAGE',
        pagePath: '/payment/confirmation',
        routePath: 'payment/confirmation',
        component: PaymentConfirmationPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    PREFERENCES: {
        pageName: 'PREFERENCES_PAGE',
        pagePath: '/settings/preferences',
        routePath: 'settings/preferences/*',
        component: PreferencesPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    PRE_ONBOARDING: {
        pageName: 'PRE_ONBOARDING_PAGE',
        pagePath: '/tutor-onboarding',
        routePath: 'tutor-onboarding',
        component: PreOnboardingPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    PRODUCTS: {
        pageName: 'PRODUCTS_PAGE',
        pagePath: '/products',
        routePath: 'products/:productId/edit/',
        component: ProductPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    LMS_QUIZ_PAGE: {
        pageName: 'LMS_QUIZ_PAGE',
        pagePath: '/quizzes',
        routePath: 'quizzes',
        component: LMSQuizPage,
        roles: [userRoles.TUTOR.value]
    },
    PROFILE: {
        pageName: 'PROFILE_PAGE',
        pagePath: '/profile',
        routePath: 'profile/*',
        component: ProfileLayoutPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    REFERRAL: {
        pageName: 'REFERRAL_PAGE',
        pagePath: '/referral',
        routePath: 'referral',
        component: ReferralPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    SETTINGS: {
        pageName: 'SETTINGS_PAGE',
        pagePath: '/settings',
        routePath: 'settings/*',
        component: SettingsPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    SCHEDULE: {
        pageName: 'SCHEDULE_PAGE',
        pagePath: '/calendar',
        routePath: 'calendar/*',
        component: SchedulePage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    STUDENT_LMS_COURSE: {
        pageName: 'STUDENT_LMS_COURSE_PAGE',
        pagePath: '/course',
        routePath: 'course/:slug/learn',
        component: StudentLmsCourse,
        roles: [userRoles.STUDENT.value]
    },
    STUDENT_LMS_COURSES: {
        pageName: 'STUDENT_LMS_COURSES_PAGE',
        pagePath: '/my-courses',
        routePath: 'my-courses/*',
        component: StudentLmsCourses,
        roles: [userRoles.STUDENT.value]
    },
    STUDENT_LMS_QUIZ: {
        pageName: 'STUDENT_LMS_QUIZ_PAGE',
        pagePath: '/quiz',
        routePath: 'quiz/:slug/learn',
        component: StudentLmsQuiz,
        roles: [userRoles.STUDENT.value]
    },
    STUDENT_LMS_QUIZZES: {
        pageName: 'STUDENT_LMS_QUIZZES_PAGE',
        pagePath: '/my-quizzes',
        routePath: 'my-quizzes/*',
        component: StudentLmsQuizzes,
        roles: [userRoles.STUDENT.value]
    },
    STUDENT_CERTIFICATES: {
        pageName: 'STUDENT_CERTIFICATES',
        pagePath: '/my-certificates',
        routePath: 'my-certificates/*',
        component: StudentCertificates,
        roles: [userRoles.STUDENT.value]
    },
    SUBSCRIPTIONS: {
        pageName: 'SUBSCRIPTIONS_PAGE',
        pagePath: '/subscriptions',
        routePath: 'subscriptions',
        component: Subscriptions,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    PRICING: {
        pageName: 'PRICING_PAGE',
        pagePath: '/subscriptions/pricing',
        routePath: 'subscriptions/pricing',
        component: PricingPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    SUPPORT: {
        pageName: 'SUPPORT_PAGE',
        pagePath: '/support',
        routePath: 'support',
        component: SupportPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    TEMPLATE: {
        pageName: 'TEMPLATE_PAGE',
        pagePath: '/create-class',
        routePath: 'create-class',
        component: TemplatePage,
        roles: [userRoles.TUTOR.value]
    },
    TOOLS: {
        pageName: 'TOOLS_PAGE',
        pagePath: '/tools',
        routePath: 'tools',
        component: ToolsPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    TOKEN_PAYMENT_CONFIRMATION: {
        pageName: 'TOKEN_PAYMENT_CONFIRMATION_PAGE',
        pagePath: '/token/payment/confirmation',
        routePath: 'token/payment/confirmation',
        component: TokenPaymentConfirmationPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    TOKEN_WALLET_CONFIRMATION: {
        pageName: 'TOKEN_WALLET_CONFIRMATION_PAGE',
        pagePath: '/token/confirmation',
        routePath: 'token/confirmation',
        component: TokenWalletConfirmationPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    TUTOR_CRM: {
        pageName: 'TUTOR_CRM_PAGE',
        pagePath: '/crm',
        routePath: 'crm/*',
        component: CrmLayoutPage,
        roles: [userRoles.TUTOR.value]
    },
    TUTOR_ONBOARDING_S1: {
        pageName: 'TUTOR_ONBOARDING_S1_PAGE',
        pagePath: '/tutor-onboarding',
        routePath: 'tutor-onboarding/:tutorId/s1',
        component: TutorOnboardingS1Page,
        roles: [userRoles.TUTOR.value]
    },
    TUTOR_ONBOARDING_S2: {
        pageName: 'TUTOR_ONBOARDING_S2_PAGE',
        pagePath: '/tutor-onboarding',
        routePath: 'tutor-onboarding/:onboardingId/s2',
        component: TutorOnboardingS2Page,
        roles: [userRoles.TUTOR.value]
    },
    TUTOR_PRODUCTS: {
        pageName: 'TUTOR_PRODUCTS_PAGE',
        pagePath: '/products',
        routePath: 'products/*',
        component: TutorProductsPage,
        roles: [userRoles.TUTOR.value]
    },
    USER_FINANCE: {
        pageName: 'USER_FINANCE_PAGE',
        pagePath: '/finance',
        routePath: 'finance/*',
        component: UserFinancePage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    USER_MARKETING: {
        pageName: 'USER_MARKETING_PAGE',
        pagePath: '/marketing',
        routePath: 'marketing/*',
        component: UserMarketingPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    USER_RESOURCES: {
        pageName: 'USER_RESOURCES_PAGE',
        pagePath: '/resources',
        routePath: 'resources/*',
        component: UserResourcesPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    UNAUTHORIZED: {
        pageName: 'UNAUTHORIZED_PAGE',
        pagePath: '/unauthorized',
        routePath: 'unauthorized',
        component: UnauthorizedPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    VIDEO_GUIDE: {
        pageName: 'VIDEO_GUIDE',
        pagePath: '/video-guide',
        routePath: 'video-guide',
        component: VideoGuide,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    WALLET: {
        pageName: 'WALLET_PAGE',
        pagePath: '/wallet',
        routePath: 'wallet',
        component: WalletPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    WALLET_CONFIRMATION: {
        pageName: 'WALLET_CONFIRMATION_PAGE',
        pagePath: '/wallet/confirmation',
        routePath: 'wallet/confirmation',
        component: WalletConfirmationPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
}

export const sideTopBarPagesName = [
    pagesInfo.APPOINTMENT_LIST.pageName,
    pagesInfo.DASHBOARD.pageName,
    pagesInfo.DOWNLOADS.pageName,
    pagesInfo.EDULYTE_LMS.pageName,
    pagesInfo.EVENTS_LIST.pageName,
    pagesInfo.GENERAL_SETTINGS.pageName,
    pagesInfo.DRIVE.pageName,
    pagesInfo.INTEGRATIONS.pageName,
    pagesInfo.LMS_QUIZ_PAGE.pageName,
    pagesInfo.LMS_COURSE_PAGE.pageName,
    pagesInfo.SCHEDULE.pageName,
    pagesInfo.TOOLS.pageName,
    pagesInfo.WALLET.pageName,
    pagesInfo.MESSAGES.pageName,
    pagesInfo.MY_TUTORS.pageName,
    pagesInfo.MY_COURSES.pageName,
    pagesInfo.MY_DOWNLOADS.pageName,
    pagesInfo.ORDERS.pageName,
    pagesInfo.STUDENT_LMS_COURSES.pageName,
    pagesInfo.STUDENT_LMS_QUIZZES.pageName,
    pagesInfo.PROFILE.pageName,
    pagesInfo.PREFERENCES.pageName,
    pagesInfo.OFFERINGS.pageName,
    pagesInfo.BOOKINGS.pageName,
    pagesInfo.REFERRAL.pageName,
    pagesInfo.SUBSCRIPTIONS.pageName,
    pagesInfo.SETTINGS.pageName,
    pagesInfo.SUPPORT.pageName,
    pagesInfo.TEMPLATE.pageName,
    pagesInfo.TUTOR_CRM.pageName,
    pagesInfo.TUTOR_PRODUCTS.pageName,
    pagesInfo.USER_FINANCE.pageName,
    pagesInfo.USER_MARKETING.pageName,
    pagesInfo.USER_RESOURCES.pageName,
    pagesInfo.VIDEO_GUIDE.pageName,
    pagesInfo.PRE_ONBOARDING.pageName,
    pagesInfo.TUTOR_ONBOARDING_S1.pageName,
    pagesInfo.CREATE_CERTIFICATE.pageName,
    pagesInfo.LMS_QUESTIONS_PAGE.pageName,
    pagesInfo.STUDENT_CERTIFICATES.pageName,
    pagesInfo.MY_LINKS.pageName,
]

export const hiddenNavBarPageName = [
    pagesInfo.EDIT_APPOINTMENT.pageName,
    pagesInfo.VERIFY_SIGNUP.pageName,
    pagesInfo.PROMO_SIGN_UP.pageName,
    pagesInfo?.CREATE_COURSE?.pageName,
    pagesInfo?.CREATE_COURSE_PREVIEW?.pageName,
    pagesInfo?.CHECKOUT_BOOKING?.pageName,
    pagesInfo?.CHECKOUT_TOKEN?.pageName,
    pagesInfo?.EDIT_EVENTLY?.pageName,
    pagesInfo?.EDIT_LMS_COURSE?.pageName,
    pagesInfo?.EDIT_LMS_QUIZ?.pageName,
    pagesInfo?.EDIT_LMS_QUESTION?.pageName,
    pagesInfo?.EDIT_LMS_ARTICLE?.pageName,
    pagesInfo?.EDIT_LMS_LECTURE?.pageName,
    pagesInfo?.PRODUCTS?.pageName,
    pagesInfo?.RESET_PASSWORD?.pageName,
    pagesInfo?.STUDENT_LMS_COURSE?.pageName,
    pagesInfo?.STUDENT_LMS_QUIZ?.pageName,
    pagesInfo?.CREATE_AI_QUIZ?.pageName,
    pagesInfo?.EDIT_DOWNLOAD?.pageName,
    pagesInfo?.EDIT_CERTIFICATE?.pageName,
    pagesInfo?.MY_LINKS?.pageName
]

export const staticNavBarPageName = [
    ...sideTopBarPagesName,
    pagesInfo.FIND_COURSES.pageName,
    pagesInfo.FIND_TUTORS.pageName,
    pagesInfo.APPOINTMENTS.pageName,
]