import { useState, useCallback, useMemo } from 'react';

import { toast } from 'react-toastify';

import { cn } from "utils/cn.utils";

import { AiOutlineCloseCircle } from 'react-icons/ai';

import ButtonLoader from 'components/loader/ButtonLoader';

import Image from 'pages/auth/edulyteLms/assets/youtube.gif';

import { createLmsResourceDetail } from 'redux/edulyteLms/lmsResource/lmsResource.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { lmsResourceTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";

import { getYouTubeVideoIdFromUrl } from 'utils/generators.utils';

const YouTubeUpload = () => {
  const { user } = useAppState((state) => state.user)
  const { addLmsResourceDetail } = useAppState((state) => state.lms.lmsResource)

  const dispatcher = useAppDispatcher()


  const [videoDetail, setVideoDetail] = useState({
    title: "",
    videoLink: null,
    videoId: null,
    isLinkVerified: false,
  })

  const isFormDisabled = useMemo(() => (!videoDetail?.title || !videoDetail?.videoLink || !videoDetail?.isLinkVerified), [videoDetail])

  const onHandleChangeTitle = useCallback((event) => {
    setVideoDetail({
      ...videoDetail,
      title: event?.target?.value
    });
  }, [videoDetail]);

  const onHandleChangeLink = useCallback((event) => {
    setVideoDetail({
      ...videoDetail,
      videoLink: event?.target?.value
    });
  }, [videoDetail]);

  // const getYoutubeVideoId = useCallback((url) => {
  //   const videoIdRegex = /[?&]v=([^?&]+)/;
  //   const match = url.match(videoIdRegex);
  //   return match ? match[1] : null;
  // }, []);

  const getThumbnailUrl = useCallback((videoId) => {
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  }, []);

  const onHandleVerifyLink = useCallback(() => {
    if (addLmsResourceDetail?.isLoading) return;

    if (!videoDetail?.title) {
      toast.warn("Please add title!")
      return;
    }
    if (!videoDetail?.videoLink) {
      toast.warn("Please add link!")
      return;
    }

    const videoId = getYouTubeVideoIdFromUrl(videoDetail?.videoLink);
    if (videoId) {
      setVideoDetail({
        ...videoDetail,
        isLinkVerified: true,
        videoId: videoId
      });
    } else {
      toast.warn("Please enter valid youtube link!")
    }
  }, [videoDetail, isFormDisabled])

  const onHandleSubmitLink = useCallback((event) => {
    event.preventDefault();

    if (addLmsResourceDetail?.isLoading || isFormDisabled) return;

    if (!videoDetail?.title) {
      toast.warn("Please add title.")
      return;
    }

    const body = {
      type: lmsResourceTypeEnum.YOUTUBE.value,
      title: videoDetail?.title,
      link: videoDetail?.videoLink,
      resource_owner_user_id: user?.user?.userId
    }

    dispatcher(createLmsResourceDetail(body))
  }, [videoDetail, isFormDisabled]);

  const handleCancel = useCallback(() => {
    setVideoDetail({
      ...videoDetail,
      title: "",
      videoLink: null,
      videoId: null,
      isLinkVerified: false
    })
  }, []);

  return (
    <div className={'flex w-full flex-col justify-center items-center gap-5 p-5 rounded-lg'}>
      <form
        onSubmit={onHandleSubmitLink}
        className={'flex flex-col w-full md:w-2/3 gap-5'}
      >
        {!videoDetail?.videoId && (
          <div className={"w-full flex flex-col justify-center items-center gap-5"}>
            <img
              src={Image}
              alt={'add youtube link'}
              className={'w-32 h-32 object-contain'}
            />
            <input
              className={cn(
                'px-3 py-2.5 flex w-full focus:outline-none rounded-lg',
                'border border-back-ground-lightDark focus:border focus:border-primary-main',
                'font-bodyPri font-normal text-text-800 text-sm placeholder:text-text-500'
              )}
              type={"text"}
              placeholder={"Enter Title"}
              value={videoDetail?.title}
              onChange={onHandleChangeTitle}
              required={true}
            />
            <div className={cn(
              "w-full flex items-center justify-between gap-1.5",
              "px-3 py-2 flex w-full focus:outline-none rounded-lg",
              "border border-back-ground-lightDark focus:border focus:border-primary-main"
            )}>
              <input
                className={'px-2 flex w-full focus:outline-none font-bodyPri font-normal text-text-800 text-sm placeholder:text-text-500'}
                type={"text"}
                placeholder={"Enter YouTube Link"}
                value={videoDetail?.videoLink}
                onChange={onHandleChangeLink}
                required={true}
              />
              <div
                className={cn(
                  "w-fit px-2 py-0.5 flex items-center justify-center border border-secondary-dark rounded-lg whitespace-nowrap text-secondary-dark",
                  "hover:bg-secondary-dark hover:text-text-50 cursor-pointer"
                )}
                onClick={onHandleVerifyLink}
              >
                <span className={"font-bodyPri font-normal text-sm tracking-wide text-center"}>
                  {"Verify Link"}
                </span>
              </div>
            </div>
          </div>
        )}
        {videoDetail?.videoId &&
          <div className={"w-full flex items-center justify-center pt-3 sm:pt-5"}>
            <div className={'relative'}>
              <img
                src={getThumbnailUrl(videoDetail.videoId)}
                alt={'Video Thumbnail'}
                className={'h-48 object-contain rounded-lg'}
              />
              <button className={cn(
                'absolute -top-1.5 -right-3.5 text-white rounded-full',
                'bg-red-500 hover:bg-red-600 transition duration-300'
              )}
                onClick={handleCancel}
              >
                <AiOutlineCloseCircle className={"text-lg"} />
              </button>
            </div>
          </div>
        }
        <div className={"w-full flex items-center justify-center"}>
          <button
            type={"submit"}
            disabled={isFormDisabled}
            className={cn(
              'px-5 py-2 whitespace-nowrap rounded-lg ease-in-out duration-300 cursor-pointer disabled:bg-text-400 disabled:text-text-200 disabled:cursor-not-allowed',
              (!isFormDisabled && !addLmsResourceDetail?.isLoading) && 'bg-primary-dark hover:bg-secondary-dark text-white',
              (!isFormDisabled && addLmsResourceDetail?.isLoading) && "bg-primary-dark cursor-progress"
            )}
          >
            {addLmsResourceDetail?.isLoading &&
              <ButtonLoader isLoading={addLmsResourceDetail?.isLoading} />
            }
            {!addLmsResourceDetail?.isLoading && "Add Video"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default YouTubeUpload;