import { memo, useCallback, useState } from "react";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

import { FiCheckSquare, FiEdit } from "react-icons/fi";
import { MdIosShare, MdOutlineContentCopy } from "react-icons/md";
import { MdOutlineAlternateEmail } from "react-icons/md";

import ToolTipView from "components/tooltipView";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearShareSocial, setModal, setShareSocial } from "redux/local/local.slice";
import { modalConst, socialAction } from "redux/local/local.const";
import { lmsQuizStatusEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";
// import { productPriceDiscountTypeEnum, productPriceModelEnum } from "redux/product/product.const";
// import { getPrices } from "redux/product/product.utils";

import { pagesInfo } from "utils/pagesInfo";

import { EDULYTE_WEB_DOMAIN_URL } from "const/default.const";

import { cn } from "utils/cn.utils";
import { getCustomHostPath } from "utils/generators.utils";

const QuizSlugCard = memo(({ isAddLinkFormEnabled, setIsAddLinkFormEnabled }) => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { modal, shareSocial } = useAppState((state) => state.local)
    const { lmsQuizDetail } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [copiedUrl, setCopiedURL] = useState(false)

    // const product_price = lmsQuizDetail?.data?.product_quiz?.product?.product_setting?.default_product_price;
    // const productPrice = useMemo(() => {
    //     if (!product_price) return null
    //     return getPrices({ product_price });
    // }, [product_price])
    // const isPrice = !!productPrice && [productPriceModelEnum.FREE.value, productPriceModelEnum.STANDARD.value].includes(productPrice?.priceModel);

    const onHandleCopyUrl = useCallback(() => {
        if (![lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(lmsQuizDetail?.data?.quiz_setting?.status)) return;

        // navigator.clipboard.writeText(`${EDULYTE_WEB_DOMAIN_URL}/quiz/${lmsQuizDetail?.data?.active_slug}`);
        navigator.clipboard.writeText(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quiz/${lmsQuizDetail?.data?.active_slug}` })}`);
        setCopiedURL(true);
        toast.success("Copied!")
        setTimeout(() => {
            setCopiedURL(false);
        }, 2000);
    }, [lmsQuizDetail?.data])

    const onHandleShareQuiz = useCallback(() => {
        if (![lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(lmsQuizDetail?.data?.quiz_setting?.status)) return;

        // const LMS_QUIZ_URL = `${EDULYTE_WEB_DOMAIN_URL}/quiz/${lmsQuizDetail?.data?.active_slug}`
        const LMS_QUIZ_URL = `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quiz/${lmsQuizDetail?.data?.active_slug}` })}`
        dispatcher(setClearShareSocial())
        dispatcher(setShareSocial({
            ...shareSocial,
            socialAction: socialAction?.SHARE_LMS_QUIZ?.value,
            url: LMS_QUIZ_URL
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }, [modal, lmsQuizDetail?.data])

    const onHandleInviteParticipants = useCallback(() => {
        if (![lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(lmsQuizDetail?.data?.quiz_setting?.status)) return;

        navigate(`${pagesInfo?.EDIT_LMS_QUIZ?.pagePath}/${lmsQuizDetail?.data?.id}/edit/participants?action=invite-participants`)

    }, [lmsQuizDetail?.data, modal])

    return (
        <div className={`w-full md:w-[22rem] flex flex-col rounded-md overflow-hidden p-1.5 group border-2 border-${lmsQuizStatusEnum[lmsQuizDetail?.data?.quiz_setting?.status?.toUpperCase()]?.darkColor} gap-3`}>
            <a
                // href={`${EDULYTE_WEB_DOMAIN_URL}/quiz/${lmsQuizDetail?.data?.active_slug}`}
                href={`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quiz/${lmsQuizDetail?.data?.active_slug}` })}`}
                className={"w-full overflow-hidden rounded-md"}
            >
                <img
                    src={lmsQuizDetail?.data?.thumbnail_pic_url}
                    alt={lmsQuizDetail?.data?.title || "thumbnail"}
                    className={"w-full h-36 aspect-video object-cover transition-all transform scale-105 group-hover:scale-100 ease-in-out duration-200"}
                />
            </a>

            <div className="w-full flex flex-col gap-2">
                <a
                    // href={`${EDULYTE_WEB_DOMAIN_URL}/quiz/${lmsQuizDetail?.data?.active_slug}`}
                    href={`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quiz/${lmsQuizDetail?.data?.active_slug}` })}`}
                    className={"font-bodyPri font-medium text-base text-text-900 hover:text-secondary-dark hover:underline line-clamp-1"}
                >
                    {lmsQuizDetail?.data?.title}
                </a>

                <div className="flex justify-between flex-col sm:flex-row gap-5">

                    <div className={""}>
                        <p className={"font-bodyPri font-normal text-sm text-text-800 whitespace-nowrap"}>
                            {`By ${lmsQuizDetail?.data?.quiz_setting?.default_owner?.tutor?.user?.first_name}`}
                        </p>
                        <p className={"font-bodyPri font-normal text-sm text-text-800 line-clamp-1"}>
                            {lmsQuizDetail?.data?.tags?.map(tag => tag?.master_tag?.tag)?.join(", ")}
                        </p>
                    </div>
                    {/* price */}
                    {/* {lmsQuizDetail?.data?.quiz_setting?.no_checkout
                        ? (
                            <div className="flex gap-3 items-center mt-3">
                                <p className="font-semibold text-text-900 text-md lg:text-xl">
                                    {"Free"}
                                </p>
                            </div>
                        ) : (
                            <>
                                {(isPrice && productPriceModelEnum.STANDARD.value === productPrice.priceModel) && (
                                    <div className="flex gap-3 items-center">
                                        <p className="font-semibold text-text-800 text-md lg:text-xl">
                                            {productPrice?.amountText}
                                        </p>
                                        {!(productPriceDiscountTypeEnum?.NONE.value === productPrice?.discountType) && (
                                            <div className="flex flex-col text-text-900 font-medium text-sm">
                                                <p className="text-text-500 line-through">
                                                    {productPrice?.priceText}
                                                </p>
                                                <p className="text-green-600">{productPrice?.discountText}</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {(isPrice && productPriceModelEnum.FREE.value === productPrice.priceModel) && (
                                    <div className="flex gap-3 items-center mt-3">
                                        <p className="font-semibold text-text-900 text-md lg:text-xl">
                                            {productPrice?.amountText}
                                        </p>
                                    </div>
                                )}
                                {!isPrice && (
                                    <div className="flex gap-3 items-center mt-3">
                                        <p className="font-normal text-back-ground-black text-lg line-clamp-1">
                                            {"Price not available"}
                                        </p>
                                    </div>
                                )}
                            </>
                        )
                    } */}
                </div>

                <hr className={"border border-divider-medium"} />

                <div className={"w-full flex flex-col items-center justify-start sm:flex-row sm:items-center sm:justify-between gap-3"}>
                    <ToolTipView
                        content={![lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(lmsQuizDetail?.data?.quiz_setting?.status) && "Public View is for published content only."}
                        disabled={[lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(lmsQuizDetail?.data?.quiz_setting?.status)}
                    >
                        <a
                            className={cn(
                                "px-3 py-1 flex items-center justify-center rounded",
                                [lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(lmsQuizDetail?.data?.quiz_setting?.status) ? "bg-primary-light text-primary-dark hover:opacity-90" : "bg-text-200 text-text-400 cursor-not-allowed"
                            )}
                            href={[lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(lmsQuizDetail?.data?.quiz_setting?.status) &&
                                // `${EDULYTE_WEB_DOMAIN_URL}/quiz/${lmsQuizDetail?.data?.active_slug}`
                                `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quiz/${lmsQuizDetail?.data?.active_slug}` })}`
                            }
                            target={"_blank"}
                        >
                            <span className={"font-bodyPri font-medium text-sm tracking-wide lg:whitespace-nowrap"}>
                                {"Open public view"}
                            </span>
                        </a>
                    </ToolTipView>
                    <div className={"flex items-center justify-start gap-0.5"}>
                        <ToolTipView content={"Share"}>
                            <button
                                className={cn(
                                    "p-2 rounded-full hover:bg-divider-darkLight",
                                    [lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(lmsQuizDetail?.data?.quiz_setting?.status) ? "text-text-700 cursor-pointer" : "text-text-400 cursor-not-allowed"
                                )}
                                onClick={onHandleShareQuiz}
                            >
                                <MdIosShare className={"text-lg"} />
                            </button>
                        </ToolTipView>
                        <ToolTipView content={"Invite"}>
                            <button
                                className={cn(
                                    "p-2 rounded-full hover:bg-divider-darkLight",
                                    [lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(lmsQuizDetail?.data?.quiz_setting?.status) ? "text-text-700 cursor-pointer" : "text-text-400 cursor-not-allowed"
                                )}
                                onClick={onHandleInviteParticipants}
                            >
                                <MdOutlineAlternateEmail className={"text-lg"} />
                            </button>
                        </ToolTipView>
                        <ToolTipView content={copiedUrl ? "copied" : "Copy"}>
                            <button
                                className={cn(
                                    "p-2 rounded-full hover:bg-divider-darkLight",
                                    [lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(lmsQuizDetail?.data?.quiz_setting?.status) ? "text-text-700 cursor-pointer" : "text-text-400 cursor-not-allowed"
                                )}
                                onClick={onHandleCopyUrl}
                            >
                                {copiedUrl &&
                                    <FiCheckSquare className={"text-lg text-green-500"} />
                                }
                                {!copiedUrl &&
                                    <MdOutlineContentCopy className={"text-lg"} />
                                }
                            </button>
                        </ToolTipView>
                        {!isAddLinkFormEnabled &&
                            <ToolTipView content={"Edit"}>
                                <button
                                    className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"}
                                    onClick={() => setIsAddLinkFormEnabled(true)}
                                >
                                    <FiEdit className={"text-lg text-text-700 cursor-pointer"} />
                                </button>
                            </ToolTipView>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
});

export default QuizSlugCard;