import { useState, useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';

import CreateCourseTooltip from 'pages/auth/createCourse/CreateCourseTooltip';
import { priceTypeTooltip, TempCoursePriceType } from 'pages/auth/createCourse/data';
import RegularPriceTypeContainer from './RegularPriceTypeContainer';

import { getCourseAvailabilityDetail, getCoursePriceDetail } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { coursePriceTypeEnum, courseEndTypeEnum, courseIsRecurringEnum, courseType } from "redux/course/course.const";
import { setClearCourseAvailDetail, setClearCoursePriceDetail, setClearAddCoursePriceDetail } from 'redux/course/course.slice';

import { timeZone } from 'utils/dateTime.utils';

const SetPriceDetailsSection = () => {
    const { tutorCourseDetail, courseAvailDetail, coursePriceDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [isAddCoursePriceEnabled, setIsAddCoursePriceEnabled] = useState(false)
    const [coursePriceType, setCoursePriceType] = useState('')

    const isGroupCourseType = tutorCourseDetail?.data?.type === courseType?.GROUP?.value
    const isOneOnOneCourseType = tutorCourseDetail?.data?.type === courseType?.ONE_ON_ONE?.value

    useEffect(() => {
        return () => {
            dispatcher(setClearAddCoursePriceDetail())
        }
    }, [])

    useEffect(() => {
        if (tutorCourseDetail?.data?.courseAvailability?.id) {
            dispatcher(getCourseAvailabilityDetail(tutorCourseDetail?.data?.courseAvailability?.id, { timeZone: timeZone }))
        }

        return () => {
            dispatcher(setClearCourseAvailDetail())
        }
    }, [tutorCourseDetail?.data?.courseAvailability])

    useEffect(() => {
        if (tutorCourseDetail?.data?.coursePrice) {
            dispatcher(getCoursePriceDetail(tutorCourseDetail?.data?.coursePrice))
        }

        return () => {
            dispatcher(setClearCoursePriceDetail())
        }
    }, [tutorCourseDetail?.data?.coursePrice])

    useEffect(() => {
        if (coursePriceDetail?.coursePriceDetail) {
            setIsAddCoursePriceEnabled(true)
            setCoursePriceType(coursePriceDetail?.coursePriceDetail?.type)
        }
    }, [coursePriceDetail?.coursePriceDetail])

    const onHandleSelectType = (option) => {
        if (isOneOnOneCourseType && option?.value === coursePriceTypeEnum?.PLAN?.value) {
            alert("You cannot change price type for this class!")
            return;
        }
        if (isGroupCourseType) {
            // if (courseAvailDetail?.courseAvailDetail?.availability?.isRecurring === courseIsRecurringEnum?.NON_RECURRING?.value) {
            //     if (option?.value === coursePriceTypeEnum?.PLAN?.value) {
            //         alert("you cannot change price type for this class!")
            //         return;
            //     }
            // }
            if (courseAvailDetail?.courseAvailDetail?.availability?.isRecurring === courseIsRecurringEnum?.RECURRING?.value && courseAvailDetail?.courseAvailDetail?.availability?.endType === courseEndTypeEnum?.FOREVER?.value) {
                if (option?.value === coursePriceTypeEnum?.REGULAR?.value) {
                    alert("Choose subscription for recurring classes without end date.")
                    return;
                }
            }
        }
        setCoursePriceType(option.value)
    }

    return (
        <div className={cn(
            "w-full h-full bg-white rounded-lg select-none",
            isAddCoursePriceEnabled && "min-h-[60vh]"
        )}>
            <div className={"w-full px-5 py-3 bg-back-ground-darkLight rounded-lg"}>
                <span className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Price Details"}
                </span>
            </div>
            {(courseAvailDetail?.isLoading || coursePriceDetail?.isLoading)
                && <ComponentLoader isLoading={courseAvailDetail?.isLoading || coursePriceDetail?.isLoading} />
            }
            {(courseAvailDetail?.errorMsg || coursePriceDetail?.errorMsg) &&
                <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {courseAvailDetail?.errorMsg || coursePriceDetail?.errorMsg}
                </div>
            }

            {(!isAddCoursePriceEnabled && !coursePriceDetail?.isLoading) &&
                <div className={cn(
                    "w-full px-3 py-3 my-2 flex items-center justify-center border border-dashed border-text-500 rounded-lg",
                    "font-bodyPri font-normal text-text-900 cursor-pointer"
                )}
                    onClick={() => {
                        setCoursePriceType("")
                        setIsAddCoursePriceEnabled(true)
                    }}
                >
                    <MdAdd className={"text-xl text-text-900"} />
                    <span className={"text-xl"}>
                        {"Add Price"}
                    </span>
                </div>
            }
            {(isAddCoursePriceEnabled && !coursePriceDetail?.isLoading) &&
                <div className={"w-full grid grid-cols-12 gap-5 md:gap-y-8 md:gap-x-3 p-5 md:px-10 md:py-5"}>

                    {/* type according to pricing */}
                    <span className="col-start-1 col-span-full md:col-start-1 md:col-span-3">
                        <div className={"flex items-center justify-start gap-1"}>
                            <CreateCourseTooltip tooltip={priceTypeTooltip} translateX={0} />
                            <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide whitespace-nowrap"}>
                                {"Type:"}
                                <span className={"text-red-500 text-lg"}>{"*"}</span>
                            </span>
                        </div>
                    </span>
                    <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-8 w-full md:w-80"}>
                        <OptionSelector
                            options={Object.values(TempCoursePriceType)}
                            className={"w-full"}
                            value={coursePriceType}
                            onChange={(option) => onHandleSelectType(option)}
                        />
                    </div>

                    {(coursePriceType === coursePriceTypeEnum?.REGULAR?.value) &&
                        <div className={"col-start-1 col-span-full"}>
                            <RegularPriceTypeContainer setIsAddCoursePriceEnabled={setIsAddCoursePriceEnabled} />
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default SetPriceDetailsSection;