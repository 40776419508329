import { locationInputLengths } from 'pages/auth/editAppointment/data';
import { useCallback, memo } from 'react';

import { cn } from 'utils/cn.utils';

const LocationDetail = memo(({
    locationPayload,
    setLocationPayload
}) => {

    const onHandleChangeText = useCallback((event) => {
        setLocationPayload({
            ...locationPayload,
            custom: {
                [event.target.name]: event?.target?.value
            }
        })
    }, [locationPayload])

    return (
        <div className={"space-y-1"}>
            <textarea
                className={cn(
                    "w-full px-5 py-3 bg-back-ground-light rounded-lg resize-none",
                    "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                    "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                )}
                minLength={locationInputLengths.custom.minLength}
                maxLength={locationInputLengths.custom.maxLength}
                rows={4}
                name={"custom_text"}
                autoFocus={true}
                placeholder={"Custom location *"}
                value={locationPayload?.custom?.custom_text}
                onChange={onHandleChangeText}
            />
            <div className={"flex justify-end"}>
                <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                    {`${locationPayload?.custom?.custom_text ? locationPayload?.custom?.custom_text?.length : 0}/${locationInputLengths.custom.maxLength}`}
                </span>
            </div>
        </div>
    )
})

export default LocationDetail;