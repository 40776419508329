import { useCallback, memo, useMemo } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { motion } from "framer-motion";

import { IoMdArrowRoundBack } from "react-icons/io";
import { IoMdShare } from "react-icons/io";

import ToolTipView from 'components/tooltipView';
import ThreeDotMenu from "components/threeDotMenu/ThreeDotMenu";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from "utils/pagesInfo";
import { getCustomHostPath } from "utils/generators.utils";

import { EDULYTE_WEB_DOMAIN_URL } from "const/default.const";

const menuItemEnum = Object.freeze({
    dashboard: {
        label: "My Dashboard",
        value: "dashboard"
    },
    share: {
        label: "Share",
        value: "share"
    }
})


const Header = memo(({ studentLmsQuiz }) => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { modal, shareSocial } = useAppState(state => state.local)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    // const QUIZ_URL = useMemo(() => `${EDULYTE_WEB_DOMAIN_URL}/quiz/${studentLmsQuiz?.quiz?.active_slug}`, [studentLmsQuiz?.quiz?.active_slug])
    const QUIZ_URL = useMemo(() => `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quiz/${studentLmsQuiz?.quiz?.active_slug}` })}`, [studentLmsQuiz?.quiz?.active_slug])

    const handleShareCourse = useCallback(() => {
        dispatcher(setShareSocial({
            ...shareSocial,
            socialAction: "",
            url: QUIZ_URL
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }, [shareSocial, modal, QUIZ_URL])

    const handleBack = () => {
        navigate(-1)
    }

    const selectMenuOption = (option) => {
        switch (option.value) {
            case menuItemEnum.dashboard.value:
                navigate(pagesInfo.DASHBOARD.pagePath)
                break;
            case menuItemEnum.share.value:
                handleShareCourse()
                break;
            default:
                return;
        }
    }

    return (
        <header className='flex items-center justify-between w-full h-16 px-5 py-1 bg-white shadow-lg'>
            <div className='grow flex items-center gap-3 lg:gap-8'>
                <ToolTipView content={"Go Back"}>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className='p-2 text-text-300 rounded-full ease-in-out duration-200 hover:bg-back-ground-medium'
                        onClick={handleBack}
                    >
                        <IoMdArrowRoundBack className='text-text-700' style={{ fontSize: "1.5rem" }} />
                    </motion.button>
                </ToolTipView>
                <div className={"grow flex items-center md:items-start justify-start gap-3"}>
                    <div className={"w-12 md:w-16 lg:w-20 aspect-video overflow-hidden rounded"}>
                        <img
                            src={studentLmsQuiz?.quiz?.thumbnail_pic_url}
                            alt={studentLmsQuiz?.quiz?.title}
                            className={"w-full h-full object-cover"}
                        />
                    </div>
                    <div className={"grow flex flex-col items-start justify-center"}>
                        <span className={"flex items-center justify-start gap-1 font-buttons font-normal text-sm text-text-600"}>
                            <NavLink to={pagesInfo.DASHBOARD.pagePath} className={"hover:text-secondary-dark hover:underline"}>
                                {"Dashboard"}
                            </NavLink>
                            <span>{"/"}</span>
                            <NavLink to={pagesInfo.STUDENT_LMS_QUIZZES.pagePath} className={"hover:text-secondary-dark hover:underline whitespace-nowrap"}>
                                {"My Quizzes"}
                            </NavLink>
                        </span>
                        <a
                            // href={`${EDULYTE_WEB_DOMAIN_URL}/quiz/${studentLmsQuiz?.quiz?.active_slug}`}
                            href={`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quiz/${studentLmsQuiz?.quiz?.active_slug}` })}`}
                            className={"font-bodyPri font-medium text-lg text-text-800 line-clamp-1 hover:text-secondary-dark hover:underline"}
                        >
                            {studentLmsQuiz?.quiz?.title}
                        </a>
                    </div>
                </div>
            </div>
            <div className='grow-0 flex items-center gap-5'>
                <NavLink
                    to={pagesInfo.DASHBOARD.pagePath}
                    className='hidden lg:block bg-back-ground-lightBlue border border-primary-dark px-5 py-2 text-primary-dark rounded hover:bg-primary-light ease-in-out duration-200'
                >
                    {"My Dashboard"}
                </NavLink>
                <div className={"hidden lg:block"}>
                    <ToolTipView content={"Share"}>
                        <button
                            className='bg-back-ground-lightBlue border border-primary-dark px-5 py-2 text-primary-dark rounded hover:bg-primary-light ease-in-out duration-200'
                            onClick={handleShareCourse}
                        >
                            <IoMdShare className={"text-xl"} />
                        </button>
                    </ToolTipView>
                </div>
                <div className="block lg:hidden bg-back-ground-lightBlue border border-primary-dark px-3 py-2 text-primary-dark rounded hover:bg-primary-light ease-in-out duration-200 cursor-pointer">
                    <ThreeDotMenu
                        menuItems={Object.values(menuItemEnum)}
                        onHandleSelect={(option) => selectMenuOption(option)}
                    />
                </div>
            </div>
        </header>
    )
})

export default Header;