import { useState } from "react";
import { cn } from "utils/cn.utils";
import { useNavigate } from 'react-router-dom';

import { motion } from "framer-motion";

import { FaCrown } from "react-icons/fa";

import {
    Container,
    Content,
    ListItems,
    ListItem,
    ItemIcon,
    ItemName,
    RoleSwitchIcon
    // StudentIcon,
    // TutorIcon
} from './SideNavBarStyle';

import SideBarItem from './SideBarItem';

import ToolTipView from 'components/tooltipView';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setLocals, setModal } from 'redux/local/local.slice';
import { localsConst, modalConst } from 'redux/local/local.const';
import { userRoles } from 'redux/auth/auth.const';
import { userPlans } from "redux/subscription/subscription.const";

import { pagesInfo } from 'utils/pagesInfo';
import { navLinkList } from 'utils/sideAndTopNavLink.utils';

const SideNavBar = ({ visible = true }) => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { locals, modal } = useAppState((state) => state.local)
    const pageName = currentPageInfo.pageName;

    const [isHoverVisible, setIsHoverVisible] = useState(false)

    const rotateIconVariants = { student: { rotate: 0 }, teacher: { rotate: 180 } }

    const containerVariants = { close: { maxWidth: '6.5rem' }, open: { maxWidth: '14.5rem' } }
    const itemNameVariants = { close: { width: '0px' }, open: { width: '100%' } }

    const transition = { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }
    const isTutor = locals?.userRole === userRoles?.TUTOR?.value

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const handleRole = () => {
        let role = userRoles.STUDENT.value === locals.userRole
            ? userRoles.TUTOR.value
            : userRoles.STUDENT.value

        dispatcher(setLocals({
            ...locals,
            [localsConst.USER_ROLE.value]: role
        }))
        navigate(pagesInfo.DASHBOARD.pagePath, { replaceAll: true })
    }

    const onHandleUpgradePlan = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.planTableModal.key]: {
                ...modalConst.planTableModal,
                isVisible: true,
                title: "Unlock Premium Features: Subscribe to Go Pro",
                userPlans
            },
        }))
    }

    const DASHBOARD = ((locals?.userRole === userRoles.STUDENT.value) ? "Business" : (locals?.userRole === userRoles.TUTOR.value) ? "Default" : "") + " Dashboard"
    const DASHBOARDTooltip = (locals?.userRole === userRoles.STUDENT.value ? `Go to Business` : (locals?.userRole === userRoles.TUTOR.value) ? `Go to Default` : "") + " Dashboard"

    return (
        <Container pageName={pageName}
            isTutor={isTutor}
            variants={containerVariants}
            initial={'open'}
            animate={(visible || isHoverVisible) ? 'open' : 'close'}
            transition={transition}
            className={cn(
                "scrollbar-thumb-rounded-full",
                (locals?.userRole === userRoles?.TUTOR?.value) && "!bg-slate-700"
            )}
            onMouseOver={() => setIsHoverVisible(true)}
            onMouseOut={() => setIsHoverVisible(false)}
        >
            <Content className={"relative flex flex-col justify-between gap-5"}>
                <ListItems isTutor={isTutor}>
                    {!!locals && !!user?.user?.roles?.find(role => role?.role === userRoles.TUTOR.value) && (
                        <ToolTipView content={DASHBOARDTooltip} placement='right' disabled={false}>
                            <ListItem
                                isTutor={isTutor}
                                onClick={handleRole}
                            >
                                <ItemIcon>
                                    <RoleSwitchIcon
                                        variants={rotateIconVariants}
                                        initial={userRoles.STUDENT.value}
                                        animate={locals?.userRole || ""}
                                        transition={transition}
                                    />
                                </ItemIcon>
                                <ItemName
                                    variants={itemNameVariants}
                                    initial={'open'}
                                    animate={(visible || isHoverVisible) ? 'open' : 'close'}
                                    transition={transition}
                                >
                                    {DASHBOARD}
                                </ItemName>
                            </ListItem>
                        </ToolTipView>
                    )}
                    {navLinkList.map((dataItem, index) => dataItem.roles.includes(locals.userRole) && (
                        <SideBarItem
                            key={index}
                            dataItem={dataItem}
                            visible={(visible || isHoverVisible)}
                        />
                    ))}
                </ListItems>
                {!user?.user?.user_subscriptions?.length &&
                    <div className={cn(
                        "sticky bottom-0 pb-20 flex items-center justify-start",
                        (visible || isHoverVisible) ? "px-5" : "",
                        isTutor ? "bg-[#334155]" : "bg-primary-main"
                    )}>
                        <ToolTipView disabled={(visible || isHoverVisible)} placement={"right"} content={"Upgrade Plan"}>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ duration: 0.3 }}
                                className={cn(
                                    "w-fit px-5 h-8 flex justify-center items-center gap-3 cursor-pointer",
                                    "font-bodyPri font-normal text-white text-lg hover:text-primary-main",
                                    "border border-white hover:bg-white rounded-md ease-in-out duration-200",
                                    isTutor && "hover:!text-[#334155]"
                                )}
                                onClick={onHandleUpgradePlan}
                            >
                                <FaCrown className={"text-2xl text-accent-main"} />
                                <ItemName
                                    variants={itemNameVariants}
                                    initial={'open'}
                                    animate={(visible || isHoverVisible) ? 'open' : 'close'}
                                    transition={transition}
                                >
                                    {"Upgrade to Pro"}
                                </ItemName>
                            </motion.button>
                        </ToolTipView>
                    </div>
                }
            </Content>
        </Container>
    )
}

export default SideNavBar;