import { useEffect, memo, useState } from "react";
import { useParams } from "react-router-dom";

import { cn } from "utils/cn.utils";

import ComponentLoader from "components/loader/ComponentLoader";
import InfinitePagination from "components/pagination/InfinitePagination";

import { getLmsQuizPageVideoResourceList } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetLmsQuizPageVideoResourceList } from "redux/edulyteLms/lmsQuiz/lmsQuiz.slice";
import { resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";
import { lmsQuizCategoryEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";

const QuizVideoListItems = memo(({ videoSearch }) => {
    const { lmsQuizPageVideoResourceList } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const { quizId } = useParams()

    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        if (quizId) {
            const requestDataPayload = {
                page: 1,
                records: 10,
                file_type: resourceFileTypeEnum?.VIDEO?.value,
                lms_quiz_id: `!${quizId}`
            }
            if (videoSearch) {
                requestDataPayload["search"] = videoSearch
            }
            dispatcher(getLmsQuizPageVideoResourceList(requestDataPayload))
        }

        return () => {
            dispatcher(resetLmsQuizPageVideoResourceList())
        }
    }, [quizId])


    const fetchMoreData = () => {
        if (lmsQuizPageVideoResourceList?.data?.page === lmsQuizPageVideoResourceList?.data?.totalPages) return;
        const requestDataPayload = {
            page: 1,
            records: lmsQuizPageVideoResourceList?.data?.records
                ? (lmsQuizPageVideoResourceList?.data?.records + 10)
                : 10,
            file_type: resourceFileTypeEnum?.VIDEO?.value,
            lms_quiz_id: `!${quizId}`
        }
        if (videoSearch) {
            requestDataPayload["search"] = videoSearch
        }
        dispatcher(getLmsQuizPageVideoResourceList(requestDataPayload))
    }

    const onHandleDragStart = (event, resourceObject) => {
        const modifiedResourceObject = { quizCategoryType: lmsQuizCategoryEnum.VIDEO.value, ...resourceObject }
        event.dataTransfer.setData('application/json', JSON.stringify(modifiedResourceObject))
    }

    return (
        <>
            {lmsQuizPageVideoResourceList?.isLoading &&
                <ComponentLoader isLoading={lmsQuizPageVideoResourceList?.isLoading} />
            }
            {(lmsQuizPageVideoResourceList?.message || (lmsQuizPageVideoResourceList?.data?.results?.length === 0)) &&
                <div className={"flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {lmsQuizPageVideoResourceList?.message || "No video(s) found!"}
                    </span>
                </div>
            }
            {(lmsQuizPageVideoResourceList?.data && (lmsQuizPageVideoResourceList?.data?.results?.length > 0)) &&
                <InfinitePagination
                    scrollableTarget={"scrollableDiv"}
                    dataLength={lmsQuizPageVideoResourceList?.data?.totalRecords}
                    fetchData={fetchMoreData}
                    hasMore={(lmsQuizPageVideoResourceList?.data?.page != lmsQuizPageVideoResourceList?.data?.totalPages)}
                    loader={<ComponentLoader isLoading={lmsQuizPageVideoResourceList?.isLoading} />}
                    isShowEndMessage={false}
                >
                    {lmsQuizPageVideoResourceList?.data?.results?.filter((resourceItem) => {
                        return (!videoSearch || videoSearch === '')
                            ? resourceItem
                            : resourceItem?.title?.toLowerCase()?.includes(videoSearch?.toLowerCase())
                    })?.map((resourceItem) => (
                        <p
                            key={resourceItem?.id}
                            className={cn(
                                'mb-2 hover:bg-back-ground-lightBlue rounded p-1',
                                isDragging ? "cursor-grabbing" : "cursor-grab"
                            )}
                            draggable={true}
                            onDragStart={(event) => onHandleDragStart(event, resourceItem)}
                            onMouseDown={() => setIsDragging(true)}
                            onMouseUp={() => setIsDragging(false)}
                            onMouseMove={() => {
                                if (isDragging) {
                                    setIsDragging(false)
                                }
                            }}
                        >
                            {resourceItem?.title}
                        </p>
                    ))}
                </InfinitePagination>
            }
        </>
    )
})

export default QuizVideoListItems;