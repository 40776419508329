import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import { AiOutlineClose } from 'react-icons/ai';
// import { RiDeleteBin4Line } from 'react-icons/ri';
import { MdOutlineModeEdit } from 'react-icons/md';
import { GrShare } from 'react-icons/gr';

import ComponentLoader from 'components/loader/ComponentLoader';

import EventlyTitleTimeHeader from './components/EventlyTitleTimeHeader';
import EventlyLocation from './components/EventlyLocation';
import EventlyDescription from './components/EventlyDescription';
import EventlyUser from './components/EventlyUser';
import EventlyMeetingLink from './components/EventlyMeetingLink';
import EventlyOrganizer from './components/EventlyOrganizer';
import ShareEventlyDetail from './components/ShareEventlyDetail';

import { getUserEventlyDetail } from 'redux/evently/evently.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetUserEventlyDetail } from 'redux/evently/evently.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams, getCustomHostPath } from 'utils/generators.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const ViewEventlySlotPopover = ({
    maxWidth = "max-w-2xl",
    bgColor = "bg-white",
}) => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userEventlyDetail, addUserEventlyDetail, destroyUserEventlyDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const query = generateQueryParams(location.search)
        if (query && query?.eventlyId) {
            dispatcher(resetUserEventlyDetail())
            dispatcher(getUserEventlyDetail(query?.eventlyId))
        }
    }, [location.search]);

    useEffect(() => {
        return () => {
            dispatcher(resetUserEventlyDetail())
        }
    }, [])

    const isMeetingOwner = (userEventlyDetail?.data?.owner_user?.userId === user?.user?.userId)

    const onHandleOpenExternalLink = () => {
        // window.open(`${EDULYTE_WEB_DOMAIN_URL}/evently/${userEventlyDetail?.data?.id}`, "_blank", "popup")
        window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/evently/${userEventlyDetail?.data?.id}` })}`, "_blank", "popup")
    }

    const onHandleCloseModal = () => {
        const searchQueryParams = new URLSearchParams(location.search)
        searchQueryParams.delete("eventlyId")
        navigate(-1)
        dispatcher(setModal({
            ...modal,
            [modalConst.viewEventlySlotModal.key]: {
                ...modal[modalConst.viewEventlySlotModal.key],
                isVisible: false,
                title: ""
            }
        }))
    }

    const onHandleEditEvent = () => {
        if (userEventlyDetail?.isLoading || addUserEventlyDetail?.isLoading || destroyUserEventlyDetail?.isLoading) return;

        dispatcher(setModal({
            ...modal,
            [modalConst.viewEventlySlotModal.key]: {
                ...modal[modalConst.viewEventlySlotModal.key],
                isVisible: false,
                title: ""
            }
        }))
        dispatcher(resetUserEventlyDetail())
        navigate(`${pagesInfo?.EDIT_EVENTLY?.pagePath}/${userEventlyDetail?.data?.id}/edit`)
    }

    // const onHandleDeleteEvent = () => {
    //     if (userEventlyDetail?.isLoading || addUserEventlyDetail?.isLoading || destroyUserEventlyDetail?.isLoading) return;

    //     dispatcher(deleteUserEventlyDetail(userEventlyDetail?.data?.id))
    //     dispatcher(setModal({
    //         ...modal,
    //         [modalConst.viewEventlySlotModal.key]: {
    //             ...modal[modalConst.viewEventlySlotModal.key],
    //             isVisible: false,
    //             title: ""
    //         }
    //     }))
    // }

    return (
        <div className={cn(
            "inline-block w-full py-1 space-y-5 overflow-hidden",
            `transition-all transform ${maxWidth} ${bgColor}`
        )}>
            <div className={"w-full flex justify-end gap-x-4 bg-text-200 py-2 px-5 drop-shadow-sm"}>
                <GrShare
                    className={"text-md text-text-500 cursor-pointer"}
                    onClick={onHandleOpenExternalLink}
                    title={"View link"}
                />
                {isMeetingOwner &&
                    <MdOutlineModeEdit
                        className={"text-lg text-text-700 cursor-pointer"}
                        onClick={onHandleEditEvent}
                        title={"Edit"}
                    />
                }
                {/* {isMeetingOwner &&
                    <RiDeleteBin4Line
                        className={"text-lg text-text-700 cursor-pointer"}
                        onClick={onHandleDeleteEvent}
                    />
                } */}
                <AiOutlineClose
                    className={'text-text-900 text-lg cursor-pointer'}
                    onClick={onHandleCloseModal}
                    title={"Close"}
                />
            </div>

            {userEventlyDetail?.isLoading &&
                <ComponentLoader isLoading={userEventlyDetail?.isLoading} />
            }
            {userEventlyDetail?.message &&
                <div className={"w-full min-h-[12rem] flex items-center justify-center"}>
                    <span className={"font-bodyPri font-medium text-red-500 text-base tracking-wide"}>
                        {userEventlyDetail?.message}
                    </span>
                </div>
            }
            {userEventlyDetail?.data &&
                <div className={cn(
                    "w-full h-full max-h-[36rem] flex flex-col gap-y-3 py-3 overflow-hidden overflow-y-scroll",
                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                    "scrollbar-thumb-divider-lightDark scrollbar-track-divider-darkLight"
                )}>
                    <EventlyTitleTimeHeader />

                    {userEventlyDetail?.data?.link &&
                        <div className={"w-full h-0.5 bg-divider-darkLight"} />
                    }

                    {userEventlyDetail?.data?.link && <EventlyMeetingLink />}

                    {userEventlyDetail?.data?.location &&
                        <div className={"w-full h-0.5 bg-divider-darkLight"} />
                    }

                    {userEventlyDetail?.data?.location && <EventlyLocation />}

                    {userEventlyDetail?.data?.description &&
                        <div className={"w-full h-0.5 bg-divider-darkLight"} />
                    }

                    {userEventlyDetail?.data?.description && <EventlyDescription />}

                    <div className={"w-full h-0.5 bg-divider-darkLight"} />

                    <EventlyOrganizer />

                    {(userEventlyDetail?.data?.evently_users?.length > 0) &&
                        <div className={"w-full h-0.5 bg-divider-darkLight"} />
                    }

                    <EventlyUser />

                    {userEventlyDetail?.data?.id &&
                        <div className={"w-full h-0.5 bg-divider-darkLight"} />
                    }

                    {userEventlyDetail?.data?.id && <ShareEventlyDetail />}
                </div>
            }
        </div>
    )
}

export default ViewEventlySlotPopover;