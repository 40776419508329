import { useEffect } from 'react';

import { pageHeading } from 'pages/auth/message/message.data';
import { sampleCourses } from "pages/auth/message/helpers/sampleCourses";
import { ChatContextProvider } from "pages/auth/message/contexts/chatContext";

import ChatDashboard from "./components/ChatDashboard/ChatDashboard";

import PageLoader from 'components/loader/PageLoader';
import PageHeader from 'components/pageHeader/PageHeader';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';
import { useUser } from 'pages/auth/message/contexts/userContext';

import { useTitle } from 'hooks/useTitle';


const Message = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { user } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()
  const userContext = useUser()
  const [title, setTitle] = useTitle()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.MESSAGES))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    setTitle({
      ...title,
      title: "Message and Notifications | Edulyte"
    })
  }, [dispatcher, currentPageInfo])

  if (user?.isLoading || userContext?.isLoading) {
    return (
      <PageLoader />
    )
  }

  return (
    <div className='height[100vh] w-full flex flex-col gap-3 p-3'>
      <div className={"hidden md:block"}>
        <PageHeader pageHeading={pageHeading} />
      </div>
      {user?.user && userContext?.user &&
        <ChatContextProvider courses={sampleCourses}>
          <ChatDashboard />
        </ChatContextProvider>
      }
    </div>
  );
}

export default Message;