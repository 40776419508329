import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
    PageContentStyle,
    Header,
    Heading,
    SubHeading,
    ProgressBarContainer,
    ProgressBarText,
    TasksContainer,
    Tasks,
    FooterContainer,
    DoughnutChartContainer,
    ViewProfileBtnContainer,
    ViewProfileButton,
} from './TutorOnboardingS1Style';

import PageLoader from 'components/loader/PageLoader';
import ProgressBar from 'components/progressBar/ProgressBar';
import Footer1 from 'components/footer/footer1/Footer1';

import GoBackHeader from "pages/auth/onboarding/commonComponents/goBackHeader/GoBackHeader";
import ProfileDetail from 'pages/auth/onboarding/components/profileDetail/ProfileDetail';
import PreviousDashboardBtn from 'pages/auth/onboarding/commonComponents/onboardingButtons/PreviousDashboardBtn';
import CTAButtons from './commonComponents/ctaButtons/CTAButtons';


import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getTutorDetail } from 'redux/tutor/tutor.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetTutorDetail } from 'redux/tutor/tutor.slice';

import { useTitle } from 'hooks/useTitle';

import { pagesInfo } from 'utils/pagesInfo';

const TutorOnboardingS1Page = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user, userDetail, userCultureDetail, userEducationList } = useAppState((state) => state.user)
    const { tutorDetail, tutorUserDetail, tutorLanguageList, tutorTagList, tutorCategoryList } = useAppState((state) => state.tutor)

    const dispatcher = useAppDispatcher()
    const { tutorId } = useParams()
    // const navigate = useNavigate()
    const [title, setTitle] = useTitle()

    const [totalStepCount, setTotalStepCount] = useState(17)
    const [stepCount, setStepCount] = useState(0)


    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TUTOR_ONBOARDING_S1))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: `${user?.user?.firstName} Onboarding Progress | Edulyte`
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (tutorId) {
            dispatcher(getTutorDetail(Number(tutorId)))
        }

        return () => {
            dispatcher(resetTutorDetail())
        }
    }, [tutorId])

    // useEffect(() => {
    //     if ((user?.user && tutorId) && (user?.user?.tutor?.tutorId !== Number(tutorId))) {
    //         navigate(pagesInfo?.UNAUTHORIZED?.pagePath)
    //     }
    // }, [user?.user, tutorId])


    useEffect(() => {
        let count = 2;
        if (user?.user?.profilePicUrl && !user?.user?.profilePicUrl?.includes("https://api.multiavatar.com")) {
            count++
        }
        if (user?.user?.firstName) {
            count++
        }
        if (user?.user?.lastName) {
            count++
        }
        if (userDetail?.userDetail?.gender) {
            count++
        }
        if (userDetail?.userDetail?.dateOfBirth) {
            count++
        }
        if (userCultureDetail?.userCultureDetail?.fromCountry?.country) {
            count++
        }
        if (userCultureDetail?.userCultureDetail?.liveInCountry?.country) {
            count++
        }
        if (tutorUserDetail?.tutorUserDetail?.elevatorPitch) {
            count++
        }
        if (tutorUserDetail?.tutorUserDetail?.bio) {
            count++
        }
        if (tutorLanguageList?.data?.results?.length > 0) {
            count++
        }
        if (userEducationList?.userEducationList?.length > 0) {
            count++
        }
        if (tutorUserDetail?.tutorUserDetail?.teachingStyleBio) {
            count++
        }
        if (tutorTagList?.data?.results?.length > 4) {
            count++
        }
        if (tutorCategoryList?.data?.results?.length > 0) {
            count++
        }

        if (tutorUserDetail?.tutorUserDetail?.videoUrl) {
            count++
        }

        setStepCount(count)
    }, [
        user?.user,
        userDetail?.userDetail,
        userEducationList?.userEducationList,
        userCultureDetail?.userCultureDetail,
        tutorLanguageList?.data,
        tutorUserDetail?.tutorUserDetail,
        tutorTagList?.data,
        tutorCategoryList?.data,
    ])

    const onHandleViewProfilePreview = () => {
        window.open(`${window.location.host}${pagesInfo?.TUTOR?.pagePath}/${user?.user?.userId}`)
    }

    if (tutorDetail?.isLoading) {
        return (
            <PageLoader />
        )
    }
    if (tutorDetail?.message) {
        return (
            <div className={"w-screen h-screen flex items-center justify-center"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {tutorDetail?.message}
                </span>

            </div>
        )
    }

    return tutorDetail?.data && (
        <PageContentStyle>
            <GoBackHeader />
            <Header>
                <Heading>
                    {'Your Winning Profile: Inspire Knowledge Seekers'}
                </Heading>
                <SubHeading>
                    {'Create a professional tutor profile that showcases your expertise, teaching style, qualifications, and skills. Stand out with a captivating one-liner, share your story, and list the subjects you teach at different difficulty levels. You will be able to update it later in profile & preferences.'}
                </SubHeading>
            </Header>

            <ProgressBarContainer>
                <ProgressBarText>
                    {"Progress Bar"}
                </ProgressBarText>
                <DoughnutChartContainer>
                    <ProgressBar pctValue={parseFloat((stepCount / totalStepCount * 100).toFixed(2))} />
                </DoughnutChartContainer>
            </ProgressBarContainer>

            <ViewProfileBtnContainer>
                <ViewProfileButton onClick={onHandleViewProfilePreview}>
                    {"Preview My Profile"}
                </ViewProfileButton>
            </ViewProfileBtnContainer>

            <TasksContainer>
                <Tasks>
                    <ProfileDetail />
                </Tasks>
                <CTAButtons />
                <PreviousDashboardBtn />
            </TasksContainer>
            {/* <FAQContainer>
                <Accordion
                    title={'Frequently Asked Questions?'}
                    dataFAQ={dataFAQ}
                />
            </FAQContainer> */}
            {/* <FooterContainer>
                <Footer1 />
            </FooterContainer> */}
        </PageContentStyle>
    )
}

export default TutorOnboardingS1Page;
