import { toast } from "react-toastify";

import paymentService from 'redux/payment/payment.service';
import {
    setMakePaymentLoading,
    setMakePayment,
    setMakePaymentErrorMsg,

    setCreateUserCustomerPortalSessionLoading,
    setCreateUserCustomerPortalSessionData,
    setCreateUserCustomerPortalSessionMessage,

    setCreateUserCustomerSessionLoading,
    setCreateUserCustomerSessionData,
    setCreateUserCustomerSessionMessage,

    setUserPaymentMethodListLoading,
    setUserPaymentMethodListData,
    setUserPaymentMethodListMessage,

    setJoinUserPaymentMethodDetailLoading,
    setJoinUserPaymentMethodDetailMessage,

    setRemoveUserPaymentMethodDetailLoading,
    setRemoveUserPaymentMethodDetailMessage,

    setCreateUserCheckoutSessionLoading,
    setCreateUserCheckoutSessionData,
    setCreateUserCheckoutSessionMessage,

    setAddPaymentIntentDetailLoading,
    setAddPaymentIntentDetailData,
    setAddPaymentIntentDetailMessage,

    setPaymentIntentDetailLoading,
    setPaymentIntentDetailData,
    setPaymentIntentDetailMessage,
    setAddTokenThroughWalletDetailLoading,
    setAddTokenThroughWalletDetailData,
    setAddTokenThroughWalletDetailMessage,
} from 'redux/payment/payment.slice';
import { getWalletTxnList } from "redux/wallet/wallet.request";


export const addMakePayment = () => async (dispatch, getState) => {
    dispatch(setMakePaymentLoading(true))
    const { makePaymentPayload } = getState()?.payment?.makePayment
    try {
        const requestData = {
            body: {
                email: makePaymentPayload?.email,
                firstName: makePaymentPayload?.firstName,
                lastName: makePaymentPayload?.lastName,
                currency: makePaymentPayload?.currency,
                // org_id: 1,
                amount: parseInt(makePaymentPayload?.totalAmountToPay),
                description: makePaymentPayload?.description,
                metadata: {
                    processingFee: makePaymentPayload?.processingFee,
                    requiredAmount: makePaymentPayload?.amountToPay,
                    paymentType: "wallet"
                }
            }
        }
        const response = await paymentService.addMakePayment(requestData)
        if (response.status === 200) {
            dispatch(setMakePayment(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setMakePaymentErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setMakePaymentLoading(false))
    }
}

export const createUserCustomerPortalSession = (body) => async (dispatch) => {
    dispatch(setCreateUserCustomerPortalSessionLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await paymentService.createUserCustomerPortalSession(requestData)
        if (response.status === 201) {
            dispatch(setCreateUserCustomerPortalSessionData(response.data.data))
            window.location.href = response.data.data?.url
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setCreateUserCustomerPortalSessionMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setCreateUserCustomerPortalSessionLoading(false))
    }
}

export const createUserCustomerSession = (body) => async (dispatch) => {
    dispatch(setCreateUserCustomerSessionLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await paymentService.createUserCustomerSession(requestData)
        if (response.status === 201) {
            dispatch(setCreateUserCustomerSessionData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setCreateUserCustomerSessionMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setCreateUserCustomerSessionLoading(false))
    }
}

export const getUserPaymentMethodList = (userId) => async (dispatch) => {
    dispatch(setUserPaymentMethodListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await paymentService.getUserPaymentMethodList(requestData)
        if (response.status === 200) {
            dispatch(setUserPaymentMethodListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserPaymentMethodListMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserPaymentMethodListLoading(false))
    }
}

export const attachUserPaymentMethodDetail = (userId, body) => async (dispatch) => {
    dispatch(setJoinUserPaymentMethodDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await paymentService.attachUserPaymentMethodDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserPaymentMethodListData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setJoinUserPaymentMethodDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setJoinUserPaymentMethodDetailLoading(false))
    }
}

export const detachUserPaymentMethodDetail = (userId, body) => async (dispatch) => {
    dispatch(setRemoveUserPaymentMethodDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await paymentService.detachUserPaymentMethodDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserPaymentMethodListData(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setRemoveUserPaymentMethodDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setRemoveUserPaymentMethodDetailLoading(false))
    }
}


export const createUserCheckoutSession = (body) => async (dispatch) => {
    dispatch(setCreateUserCheckoutSessionLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await paymentService.createUserCheckoutSession(requestData)
        if (response.status === 201) {
            dispatch(setCreateUserCheckoutSessionData(response.data.data))
            window.location.href = response.data.data?.url
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setCreateUserCheckoutSessionMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setCreateUserCheckoutSessionLoading(false))
    }
}

export const createPaymentIntentDetail = (body) => async (dispatch) => {
    dispatch(setAddPaymentIntentDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await paymentService.createPaymentIntentDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddPaymentIntentDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddPaymentIntentDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddPaymentIntentDetailLoading(false))
    }
}

export const getPaymentIntentDetail = (paymentIntentId) => async (dispatch) => {
    dispatch(setPaymentIntentDetailLoading(true))

    try {
        const requestData = {
            params: { paymentIntentId: paymentIntentId }
        }
        const response = await paymentService.getPaymentIntentDetail(requestData)
        if (response.status === 200) {
            dispatch(setPaymentIntentDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setPaymentIntentDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setPaymentIntentDetailLoading(false))
    }
}


// add token through wallet

export const createTokenThroughWalletDetail = (body) => async (dispatch) => {
    dispatch(setAddTokenThroughWalletDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await paymentService.createTokenThroughWalletDetail(requestData)
        if (response.status === 201) {
            dispatch(setAddTokenThroughWalletDetailData(response.data.data));
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddTokenThroughWalletDetailMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddTokenThroughWalletDetailLoading(false))
    }
}