import { useState } from "react";
import { cn } from "utils/cn.utils";

import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

const LMSTableComponent = ({
    headers,
    subRowHeader,
    rows,
    title,
    alternateRowColor = "bg-primary-light",
    rowHeaderColor = "bg-back-ground-medium",
    sortHeader,
    isShowSortIcon = false,
    emptyDataMsg = "No Data Found!"
}) => {

    const [activeSortItem, setActiveSortItem] = useState('date')
    const [upDownArrow, setUpDownArrow] = useState({
        upArrow: true,
        downArrow: false
    })

    const handleUpDownArrow = (head) => {
        if (!isShowSortIcon) return;

        setUpDownArrow({
            upArrow: !upDownArrow?.upArrow,
            downArrow: !upDownArrow?.downArrow
        })
        setActiveSortItem(head.value)
        sortHeader(head?.value, {
            upArrow: !upDownArrow?.upArrow,
            downArrow: !upDownArrow?.downArrow
        })
    }

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto">
                <div className={cn(
                    "inline-block min-w-full py-2 align-middle overflow-hidden"
                )}>
                    <div className={cn(
                        "min-w-full lg:w-[48rem] border-b border-gray-200 rounded-lg shadow overflow-hidden overflow-x-scroll",
                        "scrollbar-thin scrollbar-h-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        {title && (
                            <p className="px-6 pt-6 font-bold tracking-wider text-left text-slate-700">
                                {title}
                            </p>
                        )}

                        {/* table */}
                        <table className="w-full divide-y divide-gray-200">
                            <thead className={cn(
                                rowHeaderColor
                            )}>
                                <tr className="">
                                    {headers.map((head, index) => (
                                        <th
                                            key={index}
                                            scope="col"
                                            className={cn(
                                                "py-2 text-xs tracking-wider text-left text-text-800 capitalize",
                                                "font-bold font-bodyPri whitespace-nowrap"
                                            )}
                                        >
                                            <div className="w-full flex justify-center items-center gap-1.5 cursor-pointer">
                                                <div className="flex justify-start items-center">
                                                    {head?.label}
                                                </div>
                                                {isShowSortIcon &&
                                                    <div
                                                        className={"flex flex-col items-center justify-center gap-[1px]"}
                                                        onClick={() => handleUpDownArrow(head)}
                                                    >
                                                        <TiArrowSortedUp
                                                            className={cn(
                                                                "text-xl text-text-400",
                                                                (activeSortItem === head.value && upDownArrow?.upArrow) && "text-back-ground-dark"
                                                            )}
                                                        />
                                                        <TiArrowSortedDown
                                                            className={cn(
                                                                "text-xl text-text-400",
                                                                (activeSortItem === head.value && upDownArrow?.downArrow) && "text-back-ground-dark"
                                                            )}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                                <hr className="border-2 border-divider-darkLight" />
                                {(subRowHeader?.length > 0) &&
                                    <tr className="">
                                        {subRowHeader.map((head, index) => (
                                            <th
                                                key={index}
                                                scope="col"
                                                className={cn(
                                                    "py-1 text-xs tracking-wider text-left text-text-800",
                                                    "font-bold font-bodyPri whitespace-nowrap"
                                                )}
                                            >
                                                <div className="flex justify-start items-center">
                                                    {head}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                }
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {rows?.map((row, idx) => (
                                    <tr
                                        key={idx}
                                        className={cn(
                                            idx % 2 === 0 ? "bg-white" : alternateRowColor
                                        )}
                                    >
                                        {row.map((r, index) => (
                                            <td
                                                key={index}
                                                className={"px-3 py-1 overflow-hidden"}
                                            >
                                                <div className={"text-sm text-text-800 text-center lg:whitespace-nowrap truncate"}>
                                                    {r}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {rows?.length <= 0 &&
                            <div className="w-full bg-white rounded-md p-5 flex justify-center items-center font-bodyPri font-medium text-text-800 text-base tracking-wide whitespace-nowrap">
                                {emptyDataMsg}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LMSTableComponent;