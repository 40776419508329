import { useCallback } from "react";

import { cn } from "utils/cn.utils";

import { uploadLmsMediaConst } from "../data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setLocalTabs } from "redux/local/local.slice";

const UploadMediaItemContent = () => {
    const { localTabs } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()

    const onHandleChangeTab = useCallback((tab) => {
        if (!localTabs?.activeTabList?.includes(tab?.value)) return;

        dispatcher(setLocalTabs({
            ...localTabs,
            currentActiveTab: tab?.value
        }))
    }, [localTabs])

    return (
        <div className={'flex flex-col lg:flex-row flex-1 w-full'}>
            <div className={"flex flex-row lg:flex-col w-full h-full lg:w-48 items-center bg-white overflow-y-auto scrollbar-none"}>
                {localTabs?.tabList.map((tab, index) => (
                    <button
                        key={index}
                        disabled={!localTabs?.activeTabList?.includes(tab?.value)}
                        onClick={() => onHandleChangeTab(tab)}
                        className={cn(
                            'px-5 py-2 w-full whitespace-nowrap font-bodyComp border-b',
                            'duration-200 ease-in-out',
                            (localTabs?.currentActiveTab === tab.value)
                                ? "text-primary-dark border-primary-dark bg-primary-light hover:bg-back-ground-lightBlue cursor-pointer"
                                : localTabs?.activeTabList?.includes(tab.value)
                                    ? "text-text-800 cursor-pointer hover:bg-back-ground-lightBlue"
                                    : "bg-divider-darkLight text-text-600 border-divider-medium cursor-default"
                        )}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className={'w-full flex items-center justify-center overflow-hidden'}>
                {uploadLmsMediaConst[localTabs?.currentActiveTab?.toUpperCase()]?.component}
            </div>
        </div>
    )
}

export default UploadMediaItemContent;