import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import { BsThreeDotsVertical } from "react-icons/bs";

import ComponentLoader from "components/loader/ComponentLoader";

import { dashboardStats, learnersMenuOptions, sessionCompletedMenuOptions, myBalanceMenuOptions, reviewEarnedMenuOptions, bookingMenuOptions } from 'pages/auth/dashboard/data';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal, setShareSocial } from "redux/local/local.slice";
import { modalConst, socialAction } from "redux/local/local.const";
import { userRoles } from 'redux/auth/auth.const';

import { currencyRateConversion } from 'utils/generators.utils';
import { pagesInfo } from "utils/pagesInfo";

const useStyles = makeStyles({
  root: {},
});

const DashboardStatsMenu = ({ menuOptions }) => {
  const { modal, shareSocial } = useAppState((state) => state.local)
  const { user } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()

  const REVIEW_URL = `${window.location.host}/tutor/${user?.user?.userId}?action=leave-a-review`

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (ele) => {
    setAnchorEl(null);

    if (ele.name === reviewEarnedMenuOptions?.GET_MORE_REVIEWS?.name) {
      dispatcher(setShareSocial({
        ...shareSocial,
        socialAction: socialAction.GET_MORE_REVIEWS,
        url: REVIEW_URL
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.GET_MORE_REVIEWS.stateKey]: true
      }))
    }
    if (ele.name === reviewEarnedMenuOptions?.VIEW_ALL?.name) {
      navigate(`${pagesInfo?.TUTOR?.pagePath}/${user?.user?.userId}`)
    }
    // switch (ele.value) {
    //   case menuOptions.LEARNERS.value:
    //     break;
    //   case menuOptions.SESSION_COMPLETED.value:
    //     break;
    //   case menuOptions.MY_BALANCE.value:
    //     break;
    //   case menuOptions.GET_MORE_REVIEWS.value:
    //     dispatcher(setShareSocial({
    //       ...shareSocial,
    //       socialAction: socialAction.GET_MORE_REVIEWS,
    //       url: REVIEW_URL
    //     }))
    //     dispatcher(setModal({
    //       ...modal,
    //       [modalConst.GET_MORE_REVIEWS.stateKey]: true
    //     }))
    //     break;
    //   case menuOptions.BOOKINGS.value:
    //     break;
    //   default:
    // }
  };

  return (
    <div>
      <div className="cursor-pointer hover:bg-back-ground-lightBlue hover:shadow-sm rounded-full h-10 w-10 flex justify-center items-center" onClick={handleClick}>
        <BsThreeDotsVertical size={20} color="gray" />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        classes={{
          root: classes.root,
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {Object.values(menuOptions).map((ele, idx) =>
          <MenuItem key={idx} value={ele.label} onClick={() => handleClose(ele)}>
            <NavHashLink to={ele.to} className={"cursor-pointer"}>
              {ele.label}
            </NavHashLink>
          </MenuItem>
        )
        }
      </Menu>
    </div>
  )
}


const TeacherStastics = ({ statItem }) => {
  const { locals } = useAppState(s => s.local)
  const { user } = useAppState((s) => s.user)

  switch (statItem.value) {
    case dashboardStats.HOURS_TAUGHT.value:
      statItem.data = `${locals.userRole === userRoles.TUTOR.value ? parseFloat(user?.user?.tutor?.taughtHours / 60)?.toFixed(2) : parseFloat(user?.user?.student?.learningHours / 60)?.toFixed(2)} ${statItem.unit}`
      // statItem.options = dashboardStats
      break;
    case dashboardStats.LEARNERS.value:
      statItem.data = `${user?.user?.tutor?.myStudentCount || 0} ${statItem.unit}`
      statItem.options = learnersMenuOptions
      break;
    case dashboardStats.SESSION_COMPLETED.value:
      statItem.data = `${locals.userRole === userRoles.TUTOR.value ? user?.user?.tutor?.noOfSessionTaken : user?.user?.student?.noOfSessionTaken} ${statItem.unit}`
      statItem.options = sessionCompletedMenuOptions
      break;
    case dashboardStats.MY_BALANCE.value:
      statItem.data = `${currencyRateConversion(locals?.currencyCode, user?.user?.netWalletCredit).substring(4)} ${locals?.currencyCode.toUpperCase()}`
      statItem.options = myBalanceMenuOptions
      break;
    case dashboardStats.REVIEWS_EARNED.value:
      statItem.data = `${user?.user?.tutor?.rating?.totalStars?.count || 0} ${statItem.unit}`
      statItem.options = reviewEarnedMenuOptions
      break;
    case dashboardStats.BOOKINGS.value:
      statItem.data = `${locals.userRole === userRoles.TUTOR.value ? user?.user?.tutor?.bookingCount : user?.user?.student?.bookingCount} ${statItem.unit}`
      statItem.options = bookingMenuOptions
      break;
    default:
      statItem.data = 0
  }

  return (
    <div className={"flex flex-col bg-white rounded-lg px-5 py-3 gap-3"}>
      <div className={"flex items-start justify-between gap-3"}>
        <div className={"flex justify-start items-center gap-8"}>
          <div className={"w-20 h-20 bg-text-200 rounded-md p-2 flex items-center justify-center"}>
            <div className={"w-full h-full overflow-hidden rounded-full"}>
              <img src={statItem.image} alt={statItem.value} className={"w-full h-full object-cover"} />
            </div>
          </div>
          <div className={"flex flex-col items-start justify-start gap-2"}>
            <div className={"font-bodyComp font-semibold text-lg text-text-800"}>
              {statItem.label}
            </div>
            <div className={"flex justify-start items-center gap-1"}>
              <span className="font-bodyPri font-normal text-md text-text-700">
                {statItem.data}
              </span>
            </div>
          </div>
        </div>
        {(![dashboardStats?.HOURS_TAUGHT?.value, dashboardStats?.HOURS_LEARN?.value]?.includes(statItem?.value)) &&
          <DashboardStatsMenu menuOptions={statItem?.options} />
        }
      </div>
    </div>
  )
}

export const DashboardStatistics = () => {
  const { locals } = useAppState((s) => s.local)
  const { user } = useAppState((s) => s.user)

  return (
    <div className={"grid-cols-1 md:grid-cols-2 grid gap-3 w-full"}>
      <ComponentLoader isLoading={user?.isLoading} />
      {Object.keys(dashboardStats).map((item, idx) => dashboardStats[item].roles.includes(locals.userRole) && (
        <TeacherStastics key={idx} statItem={dashboardStats[item]} />
      ))}
    </div>
  );
};
