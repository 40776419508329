import { useEffect, useState } from 'react';

import { FaInfoCircle } from 'react-icons/fa';

import MuxVideoPlayer from 'components/muxVideoPlayer/MuxVideoPlayer';
import ToolTipView from 'components/tooltipView';

import { getTutorUserDetailByUserId, updateTutorUserDetailByUserId } from "redux/tutor/tutor.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { setClearAddTutorUserDetail, setProfilePageDocumentPayload, setClearProfilePageDocumentPayload } from 'redux/tutor/tutor.slice';
import { resetUploadFile } from 'redux/storage/storage.slice';
import { modalConst } from 'redux/local/local.const';
import { userRoles } from 'redux/auth/auth.const';

import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';

const VideoSelector = () => {
    const { modal, locals } = useAppState((state) => state.local)
    const { uploadFile } = useAppState((state) => state.storage)
    const { user } = useAppState((state) => state.user)
    const { tutorUserDetail, addTutorUserDetail, profilePageDocumentUpload } = useAppState((s) => s.tutor)

    const dispatcher = useAppDispatcher()

    const [playbackId, setPlaybackId] = useState(MUX_DEMO_PLAYBACK_ID)

    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0 && profilePageDocumentUpload?.profilePageDocumentUploadPayload?.isVideoUploadEnabled) {
            dispatcher(updateTutorUserDetailByUserId(
                user?.user?.userId,
                {
                    videoUrl: uploadFile?.data[0]?.fileUrl
                }
            ))
            dispatcher(resetUploadFile())
        }

    }, [uploadFile?.data])

    useEffect(() => {
        if (addTutorUserDetail?.addTutorUserDetail?.videoUrl) {
            dispatcher(setClearProfilePageDocumentPayload())
            dispatcher(setModal({
                ...modal,
                [modalConst.RECORD_VIDEO_MODAL.stateKey]: false,
                [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: false
            }))
        }
    }, [addTutorUserDetail?.addTutorUserDetail?.videoUrl])

    useEffect(() => {
        if (tutorUserDetail?.tutorUserDetail?.videoUrl) {
            setPlaybackId(tutorUserDetail?.tutorUserDetail?.videoUrl)
        } else {
            setPlaybackId(MUX_DEMO_PLAYBACK_ID)
        }
    }, [tutorUserDetail?.tutorUserDetail?.videoUrl])

    const handleUploadVideo = () => {
        dispatcher(resetUploadFile())
        dispatcher(setClearAddTutorUserDetail())
        dispatcher(setProfilePageDocumentPayload({
            isVideoUploadEnabled: true,
            isProfilePictureUploadEnabled: false
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: true
        }))
    }

    const handleRecordVideo = () => {
        dispatcher(resetUploadFile())
        dispatcher(setClearAddTutorUserDetail())
        dispatcher(setProfilePageDocumentPayload({
            isVideoUploadEnabled: true,
            isProfilePictureUploadEnabled: false
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.RECORD_VIDEO_MODAL.stateKey]: true
        }))
    }

    const onHandleRefresh = () => {
        dispatcher(getTutorUserDetailByUserId(user?.user?.userId))
    }

    return (locals.userRole === userRoles.TUTOR.value) && (
        <div className={'space-y-5 p-5 border border-back-ground-light rounded shadow'}>
            <div className={"w-full md:w-[26rem] h-full rounded-lg space-y-1"}>
                <div className='flex justify-end'>
                    <ToolTipView content='Your intro video must focus on learners highlighting your brief background. Why should they learn from you? Talk about benefits from your sessions, What will they learn? How will they learn etc.The tone should motivate learners to sign up for your sessions.'>
                        <div><FaInfoCircle className="text-md w-fit text-blue-400 hover:text-primary-dark cursor-pointer" /></div>
                    </ToolTipView>
                </div>
                <MuxVideoPlayer
                    playbackId={playbackId}
                    isShowRefreshButton={true}
                    isShowButtons={true}
                    handleRecordVideo={handleRecordVideo}
                    handleUploadVideo={handleUploadVideo}
                    onHandleRefresh={onHandleRefresh}
                />
            </div>
        </div>
    )
}

export default VideoSelector