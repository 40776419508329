import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { libraryTabConst } from 'components/modals/lmsModals/attachLibraryModal/data';
import { uploadLmsMediaConst } from 'components/modals/lmsModals/uploadLmsMediaModal/data';

import UpdateLmsInputText from 'pages/auth/edulyteLms/commonComponents/UpdateLmsInputText';
import LmsEditorDescription from 'pages/auth/edulyteLms/commonComponents/LmsEditorDescription';
import UploadSection from 'pages/auth/edulyteLms/editLmsPages/editLmsQuestion/components/questionBuilder/uploadSection';

import SingleChoiceOption from 'pages/auth/edulyteLms/editLmsPages/editLmsQuestion/components/questionBuilder/components/singleChoiceOption/SingleChoiceOption';
import MultipleChoiceOption from 'pages/auth/edulyteLms/editLmsPages/editLmsQuestion/components/questionBuilder/components/multipleChoiceOption/MultipleChoiceOption';
import QuestionSidebar from '../questionSidebar/QuestionSidebar';

import { updateLmsQuestionDetail } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';
import { createLmsResourceDetail } from 'redux/edulyteLms/lmsResource/lmsResource.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetLocalTabs, setLocalTabs, setModal } from 'redux/local/local.slice';
import { resetAddLmsResourceDetail, setFilterProps } from 'redux/edulyteLms/lmsResource/lmsResource.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsQuestionTypeEnum } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.const';
import { lmsResourceTypeEnum, resourceFileTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

const EditLMSQuestionBuilderPage = () => {
    const { localTabs, modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { lmsQuestionDetail, updateLmsQuestion } = useAppState((state) => state.lms.lmsQuestion)
    const { addLmsResourceDetail, filterProps } = useAppState((state) => state.lms.lmsResource)

    const dispatcher = useAppDispatcher()

    const [titleInputStatus, setTitleInputStatus] = useState("")

    useEffect(() => {
        if (addLmsResourceDetail?.data) {
            dispatcher(resetLocalTabs())
            dispatcher(setLocalTabs({
                ...localTabs,
                modalTitle: "Library",
                tabList: Object.values(libraryTabConst),
                activeTabList: [libraryTabConst.RESOURCES.value],
                currentActiveTab: libraryTabConst.RESOURCES.value,
            }))
            dispatcher(setFilterProps({
                filterProps: {
                    ...filterProps,
                    resourceType: lmsResourceTypeEnum.FILE.value,
                    resourceFileType: resourceFileTypeEnum.IMAGE.value,
                    disabledFilters: [lmsResourceTypeEnum.YOUTUBE.value, lmsResourceTypeEnum.LINK.value]
                }
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_LMS_MEDIA_MODAL.stateKey]: false,
                [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true
            }))
            dispatcher(resetAddLmsResourceDetail())
        }
    }, [addLmsResourceDetail?.data])

    useEffect(() => {
        if (localTabs?.tabItem && (localTabs?.tabItem?.tabType === uploadLmsMediaConst.MY_FILES.value) && localTabs?.tabItem?.payload?.fileId) {
            const body = {
                type: lmsResourceTypeEnum.FILE.value,
                title: localTabs?.tabItem?.payload?.fileName,
                file_id: localTabs?.tabItem?.payload?.fileId,
                resource_owner_user_id: user?.user?.userId
            }
            dispatcher(createLmsResourceDetail(body))
            dispatcher(resetLocalTabs())
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_LMS_MEDIA_MODAL.stateKey]: false
            }))
        }
    }, [localTabs])

    const renderAnswers = useCallback(() => {
        switch (lmsQuestionDetail?.data?.type) {
            case lmsQuestionTypeEnum?.SINGLE_CHOICE?.value:
                return <SingleChoiceOption />;
            case lmsQuestionTypeEnum?.MULTIPLE_CHOICE?.value:
                return <MultipleChoiceOption />;
            default:
                return null;
        }
    }, [lmsQuestionDetail?.data]);

    const onHandleSaveTitle = (editedTitle) => {
        setTitleInputStatus("Saving...")
        if (!editedTitle || (editedTitle?.length === 0)) {
            toast.warn("Please enter title ...")
            setTitleInputStatus("Saved")
            return;
        }
        const body = {
            title: editedTitle
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsQuestionDetail(lmsQuestionDetail?.data?.id, body, payload))

        setTimeout(() => {
            setTitleInputStatus("")
        }, 1000)
    }

    const onHandleSaveDescription = (editedInstructions) => {
        if (editedInstructions === lmsQuestionDetail?.data?.instruction) return;

        const body = {
            instruction: editedInstructions
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsQuestionDetail(lmsQuestionDetail?.data?.id, body, payload))
    }

    const onHandleSelectOption = useCallback((option) => {
        if (updateLmsQuestion?.isLoading || (lmsQuestionDetail?.data?.type === option)) return;

        dispatcher(updateLmsQuestionDetail(lmsQuestionDetail?.data?.id, { type: option }))
    }, [lmsQuestionDetail?.data, updateLmsQuestion?.isLoading]);


    return (
        <div className={"grid grid-cols-12 gap-3 pb-5"}>
            <div className={'col-start-1 col-span-full lg:col-start-1 lg:col-span-9 space-y-3'}>
                <div className={"w-full flex flex-col gap-3"}>
                    <UpdateLmsInputText
                        isLoading={updateLmsQuestion?.isLoading}
                        isShowInputLabel={true}
                        maxLength={512}
                        containerClassName={"mt-3 mb-1"}
                        inputClassName={"font-tagLine font-semibold text-primary-dark text-lg border-b border-text-400"}
                        title={lmsQuestionDetail?.data?.title}
                        titleInputStatus={titleInputStatus}
                        setTitleInputStatus={setTitleInputStatus}
                        onHandleSaveTitle={onHandleSaveTitle}
                    />
                    <LmsEditorDescription
                        isLoading={updateLmsQuestion?.isLoading}
                        descriptionPlaceholder={"Add More Details"}
                        editorContainerClassName={"px-3 py-2 bg-white rounded-lg"}
                        description={lmsQuestionDetail?.data?.instruction}
                        onHandleSaveDescription={onHandleSaveDescription}
                    />
                </div>

                <div className={"w-full px-3 py-3 flex items-center justify-center md:justify-start gap-5 md:gap-8 bg-white rounded-lg"}>
                    {Object.values(lmsQuestionTypeEnum)?.filter((option) => [lmsQuestionTypeEnum?.SINGLE_CHOICE?.value, lmsQuestionTypeEnum?.MULTIPLE_CHOICE?.value]?.includes(option?.value))?.map((questionType, index) => (
                        <div
                            key={index}
                            className={"flex items-center justify-start gap-2 cursor-pointer"}
                            onClick={() => onHandleSelectOption(questionType?.value)}
                        >
                            <input
                                type={"radio"}
                                className={"w-4 h-4 border-2 border-divider-lightDark cursor-pointer"}
                                checked={lmsQuestionDetail?.data?.type === questionType?.value}
                            />
                            <span className={"font-bodyPri font-medium text-text-800 text-lg tracking-wide"}>
                                {questionType?.label}
                            </span>
                        </div>
                    ))}
                </div>
                <div className={''}>
                    {renderAnswers()}
                </div>
                <UploadSection />
            </div>
            <div className={"hidden lg:block col-start-10 col-span-3"}>
                <div className={"sticky top-16"}>
                    <QuestionSidebar />
                </div>
            </div>
        </div>
    )
}

export default EditLMSQuestionBuilderPage;

// option selector for question types

{/* <div className={"flex w-full flex-col items-left rounded-l"}>
    <div className={"relative flex w-full md:w-60"}>
        <button
            className={cn(
                "px-3 h-10 w-full md:w-60 flex items-center justify-between rounded",
                "bg-back-ground-black text-white ease-in-out duration-300"
            )}
            onClick={onHandleToggleDropdown}
        >
            {lmsQuestionTypeEnum[lmsQuestionDetail?.data?.type?.toUpperCase()]?.label || "Select Question Type"}
            <MdArrowDropDown
                size={20}
                className={`transform ease-in-out duration-200 transition-transform ${dropdownOpen ? 'rotate-180' : ''}`}
                onClick={onHandleToggleDropdown}
            />
        </button>
        {dropdownOpen && (
            <ul className={cn(
                "absolute top-[42px] right-0 w-full md:w-60 rounded z-10",
                "drop-shadow-md bg-white border border-gray-300"
            )}>
                {Object.values(lmsQuestionTypeEnum)?.filter((option) => [lmsQuestionTypeEnum?.SINGLE_CHOICE?.value, lmsQuestionTypeEnum?.MULTIPLE_CHOICE?.value]?.includes(option?.value))?.map((questionType, index) => (
                    <li key={index} className={"cursor-pointer w-full flex py-2 px-3 hover:text-secondary-dark hover:bg-back-ground-lightBlue"}
                        onClick={() => onHandleSelectOption(questionType?.value)}>
                        {questionType?.label}
                    </li>
                ))}
            </ul>
        )}
    </div>
</div> */}