import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";

import SelectSearch, { fuzzySearch } from "react-select-search";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import PageLoader from 'components/loader/PageLoader';
import ButtonLoader from 'components/loader/ButtonLoader';
import { OptionSelector } from 'components/common-components/Select';
import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';
import PersonalSocialMediaPresence from 'components/modals/personalDetailModal/PersonalSocialMediaPresence';

import { imageType, titleOptions, genderOptions, addressTypeConst, userSocialMediaLinksConst } from 'components/modals/personalDetailModal/data';

import {
  getMasterCountryList,
  getMasterStateList,
  getMasterCityList,
} from 'redux/master/master.request';
// import { uploadDocumentToS3 } from "redux/document/document.request";
import { uploadFileToS3 } from "redux/storage/storage.request";
import {
  getOnboardingUserDetailByOnboardingId,
  updateOnboardingUserDetailByOnboardingId
} from "redux/onboarding/onboarding.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import {
  setClearCountryList,
  setClearStateList,
  setClearCityList,
} from 'redux/master/master.slice';
import { resetUploadFile } from 'redux/storage/storage.slice';
// import { setClearUploadDocument, setUploadDocumentPayload } from "redux/document/document.slice";
import { setClearOnboardingUserDetail, setOnboardingUserDetail, setClearAddOnboardingUserDetail } from 'redux/onboarding/onboarding.slice';
import { onBoardingStatus, userDetailStatus } from "redux/onboarding/onboarding.const";
import { profilePicTypeConst } from 'redux/storage/storage.const';
import { modalConst } from 'redux/local/local.const';

import {
  validateEmail,
  validateMobileNo,
} from 'utils/validation-functions';
import { dayjs } from "utils/dateTime.utils";

const pattern = /[a-zA-z]{1}/;
const DEMO_PROFILE = "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw"


const PersonalDetailModal = () => {
  const { modal } = useAppState((state) => state.local)
  const { countryList, stateList, cityList, timeZoneCountryDetail } = useAppState(s => s.master)
  // const { uploadDocument } = useAppState(s => s.document)
  const { uploadFile } = useAppState(s => s.storage)
  const { user } = useAppState((state) => state.user)
  const { userOnboardingDetail, onboardingUserDetail, addOnboardingUserDetail } = useAppState((s) => s.onboarding)

  const dispatcher = useAppDispatcher()
  const { onboardingId } = useParams()

  const [emailErrorText, setEmailErrorText] = useState('')
  const [mobilePhoneErrorText, setMobilePhoneErrorText] = useState('')
  const [homePhoneErrorText, setHomePhoneErrorText] = useState('')
  const [workPhoneErrorText, setWorkPhoneErrorText] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [profilePicture, setProfilePicture] = useState("")
  const [birthDate, setBirthDate] = useState(new Date())
  const [myCountry, setMyCountry] = useState({
    masterCountryId: 0,
    country: "",
    countryDomain: "",
    isdCode: ""
  })
  const [isFormDisabled, setIsFormDisabled] = useState(false)

  // use effect for close modal
  useEffect(() => {
    if (addOnboardingUserDetail?.addOnboardingUserDetail) {
      dispatcher(setModal({
        ...modal,
        [modalConst.PERSONAL_DETAIL_MODAL.stateKey]: false
      }))
    }

    return () => {
      dispatcher(setClearAddOnboardingUserDetail())
    }
  }, [addOnboardingUserDetail?.addOnboardingUserDetail])

  // use effect for profile picture
  useEffect(() => {
    if (selectedFile) {
      setProfilePicture(selectedFile?.fileUrl)
    }
    if (!selectedFile && onboardingUserDetail?.onboardingUserDetail?.profilePicUrl) {
      setProfilePicture(onboardingUserDetail?.onboardingUserDetail?.profilePicUrl)
    }
    if (!selectedFile && !onboardingUserDetail?.onboardingUserDetail?.profilePicUrl) {
      setProfilePicture(DEMO_PROFILE)
    }
  }, [selectedFile, onboardingUserDetail?.onboardingUserDetail])

  // use effect for birth date
  useEffect(() => {
    if (onboardingUserDetail?.onboardingUserDetail?.dateOfBirth) {
      setBirthDate(dayjs(onboardingUserDetail?.onboardingUserDetail?.dateOfBirth)?.toDate())
    }
  }, [onboardingUserDetail?.onboardingUserDetail?.dateOfBirth])

  // get country list request
  useEffect(() => {
    dispatcher(getMasterCountryList())
    dispatcher(getOnboardingUserDetailByOnboardingId(Number(onboardingId)))

    return () => {
      dispatcher(setClearCountryList())
      dispatcher(setClearOnboardingUserDetail())
      dispatcher(resetUploadFile())
    }
  }, [])

  // country name according to timezone
  useEffect(() => {
    if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
      let myLocalCountryList = countryList?.countryList?.filter((country) => (
        country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
      ))

      if (myLocalCountryList.length === 0) {
        myLocalCountryList = countryList?.countryList?.filter((country) => (
          country.countryDomain === "US"
        ))
      }
      setMyCountry(myLocalCountryList[0])
    }
  }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

  // get state list request using country Name
  useEffect(() => {
    if (onboardingUserDetail?.onboardingUserDetail?.country?.masterCountryId) {
      dispatcher(getMasterStateList(onboardingUserDetail?.onboardingUserDetail?.country?.masterCountryId))
    }

    return () => {
      dispatcher(setClearStateList())
    }
  }, [onboardingUserDetail?.onboardingUserDetail?.country?.masterCountryId])

  // get city list request using state name
  useEffect(() => {
    if (onboardingUserDetail?.onboardingUserDetail?.state?.masterStateId) {
      dispatcher(getMasterCityList(onboardingUserDetail?.onboardingUserDetail?.state?.masterStateId))
    }

    return () => {
      dispatcher(setClearCityList())
    }
  }, [onboardingUserDetail?.onboardingUserDetail?.state?.masterStateId])

  // use effect for local country name
  useEffect(() => {
    if (myCountry && !onboardingUserDetail?.onboardingUserDetail?.country) {
      dispatcher(setOnboardingUserDetail({
        ...onboardingUserDetail?.onboardingUserDetail,
        country: {
          country: myCountry?.country,
          countryDomain: myCountry?.countryDomain,
          masterCountryId: myCountry?.masterCountryId,
        }
      }))
    }
  }, [myCountry])

  // validations for email and mobile number
  useEffect(() => {
    if (onboardingUserDetail?.onboardingUserDetail?.email && !validateEmail(onboardingUserDetail?.onboardingUserDetail?.email)) setEmailErrorText('Enter valid Email!');
    else setEmailErrorText('');

    if (onboardingUserDetail?.onboardingUserDetail?.mobileNo && !validateMobileNo(onboardingUserDetail?.onboardingUserDetail?.mobileNo)) setMobilePhoneErrorText('Enter valid MobileNo!');
    else setMobilePhoneErrorText('');
  }, [onboardingUserDetail?.onboardingUserDetail?.mobileNo, onboardingUserDetail?.onboardingUserDetail?.email])

  // validation for home phone and work phone
  useEffect(() => {
    if (onboardingUserDetail?.onboardingUserDetail?.homePhone && !validateMobileNo(onboardingUserDetail?.onboardingUserDetail?.homePhone)) {
      setHomePhoneErrorText('Enter Valid Phone Number')
    }
    else setHomePhoneErrorText('')
    if (onboardingUserDetail?.onboardingUserDetail?.workPhone && !validateMobileNo(onboardingUserDetail?.onboardingUserDetail?.workPhone)) {
      setWorkPhoneErrorText('Enter Valid Phone Number')
    }
    else setWorkPhoneErrorText('')
  }, [onboardingUserDetail?.onboardingUserDetail?.homePhone, onboardingUserDetail?.onboardingUserDetail?.workPhone])

  useEffect(() => {
    if (uploadFile?.data && uploadFile?.data?.length > 0) {
      dispatcher(setOnboardingUserDetail({
        ...onboardingUserDetail?.onboardingUserDetail,
        profilePicUrl: uploadFile?.data[0]?.fileUrl
      }))
      toast.success("Profile picture uploaded!")
      setSelectedFile(null)
    }

  }, [uploadFile?.data])

  useEffect(() => {
    if ([userDetailStatus?.APPROVED?.value, userDetailStatus?.REJECTED?.value]?.includes(onboardingUserDetail?.onboardingUserDetail?.status)
      || [onBoardingStatus?.APPROVED?.value, onBoardingStatus?.REJECTED?.value, onBoardingStatus?.BLOCKED?.value]?.includes(userOnboardingDetail?.userOnboardingDetail?.status)
    ) {
      setIsFormDisabled(true)
    }
  }, [onboardingUserDetail?.onboardingUserDetail?.status, userOnboardingDetail?.userOnboardingDetail?.status])

  const onSelectCountry = (option) => {
    const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option)
    dispatcher(setOnboardingUserDetail({
      ...onboardingUserDetail?.onboardingUserDetail,
      country: {
        country: selectedCountry?.country,
        countryDomain: selectedCountry?.countryDomain,
        masterCountryId: selectedCountry?.masterCountryId
      }
    }))
  }

  const onSelectState = (option) => {
    const selectedState = stateList?.stateList?.find((state) => state?.masterStateId === option)
    dispatcher(setOnboardingUserDetail({
      ...onboardingUserDetail?.onboardingUserDetail,
      state: {
        state: selectedState?.state,
        stateDomain: selectedState?.stateDomain,
        masterStateId: selectedState?.masterStateId
      }
    }))
  }

  const onSelectCity = (option) => {
    const selectedState = cityList?.cityList?.find((state) => state?.masterCityId === option)
    dispatcher(setOnboardingUserDetail({
      ...onboardingUserDetail?.onboardingUserDetail,
      city: {
        city: selectedState?.city,
        cityDomain: selectedState?.cityDomain,
        masterCityId: selectedState?.masterCityId,
      }
    }))
  }

  const handleOnFileChange = (event) => {
    if (isFormDisabled) return;
    const files = event.target.files;
    if (!files || files.length === 0) {
      alert("Image not selected!")
      return;
    }

    const file = files[0];
    if (file?.size <= 2 * 1048576) {
      if (profilePicTypeConst?.includes(file?.type)) {
        setSelectedFile({
          fileList: event?.target?.files,
          file: file,
          fileUrl: URL.createObjectURL(file)
        })
      } else {
        toast.warn("Please select a valid document!")
      }
    } else {
      toast.error("File size is too large!")
    }
  }

  const onHandleUpload = () => {
    // dispatcher(setUploadDocumentPayload({
    //   ...uploadDocument?.uploadDocumentPayload,
    //   fileName: selectedFile?.file.name,
    //   fileType: selectedFile?.file?.type,
    //   file: selectedFile?.file
    // }))

    dispatcher(uploadFileToS3(selectedFile?.fileList, user?.user?.userId))
  }

  const onSelectIsdCode = (option) => {
    const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
    setMyCountry(selectedCountry)
  }

  const onSubmit = (event) => {
    event.preventDefault()
    if (isFormDisabled) return;
    if (!onboardingUserDetail?.onboardingUserDetail?.profilePicUrl || onboardingUserDetail?.onboardingUserDetail?.profilePicUrl?.includes(DEMO_PROFILE) || onboardingUserDetail?.onboardingUserDetail?.profilePicUrl?.includes("https://api.multiavatar.com")) {
      toast.warn("Please Upload Profile Picture!")
      return;
    }
    if (birthDate > new Date()) {
      toast.warn("Invalid Date!")
      return;
    }
    if ((dayjs(new Date())?.format("YYYY") - dayjs(birthDate)?.format("YYYY")) < 18) {
      toast.warn("Minimum age should be 18 years!")
      return;
    }
    const body = {
      profilePicUrl: onboardingUserDetail?.onboardingUserDetail?.profilePicUrl,
      title: onboardingUserDetail?.onboardingUserDetail?.title,
      firstName: onboardingUserDetail?.onboardingUserDetail?.firstName,
      lastName: onboardingUserDetail?.onboardingUserDetail?.lastName,
      preferredName: onboardingUserDetail?.onboardingUserDetail?.preferredName,
      gender: onboardingUserDetail?.onboardingUserDetail?.gender,
      dateOfBirth: dayjs(birthDate).format("YYYY-MM-DD"),
      homePhone: onboardingUserDetail?.onboardingUserDetail?.homePhone,
      workPhone: onboardingUserDetail?.onboardingUserDetail?.workPhone,
      addressType: onboardingUserDetail?.onboardingUserDetail?.addressType || addressTypeConst?.HOME?.value,
      street: onboardingUserDetail?.onboardingUserDetail?.street,
      masterCountryId: onboardingUserDetail?.onboardingUserDetail?.country?.masterCountryId || myCountry?.masterCountryId,
      // masterStateId: onboardingUserDetail?.onboardingUserDetail?.state?.masterStateId,
      // masterCityId: onboardingUserDetail?.onboardingUserDetail?.city?.masterCityId,
      zipCode: onboardingUserDetail?.onboardingUserDetail?.zipCode,
      facebookProfileLink: onboardingUserDetail?.onboardingUserDetail?.facebookProfileLink,
      instagramProfileLink: onboardingUserDetail?.onboardingUserDetail?.instagramProfileLink,
      twitterProfileLink: onboardingUserDetail?.onboardingUserDetail?.twitterProfileLink,
      linkedinProfileLink: onboardingUserDetail?.onboardingUserDetail?.linkedinProfileLink
    }

    if (!body?.title) {
      toast.error("Please select title!")
      return;
    }
    if (!body?.gender) {
      toast.error("Please select gender!")
      return;
    }
    if (body?.facebookProfileLink && !body?.facebookProfileLink?.includes(userSocialMediaLinksConst?.FACEBOOK?.socialLinkUrl)) {
      toast.error("Invalid facebook link!")
      return;
    }
    if (body?.instagramProfileLink && !body?.instagramProfileLink?.includes(userSocialMediaLinksConst?.INSTAGRAM?.socialLinkUrl)) {
      toast.error("Invalid instagram link!")
      return;
    }
    if (body?.twitterProfileLink && !body?.twitterProfileLink?.includes(userSocialMediaLinksConst?.TWITTER?.socialLinkUrl)) {
      toast.error("Invalid twitter link!")
      return;
    }
    if (body?.linkedinProfileLink && !body?.linkedinProfileLink?.includes(userSocialMediaLinksConst?.LINKEDIN?.socialLinkUrl)) {
      toast.error("Invalid Linkedin link!")
      return;
    }

    if (!onboardingUserDetail?.onboardingUserDetail?.isEmailVerified) {
      body["email"] = onboardingUserDetail?.onboardingUserDetail?.email
    }

    if (!onboardingUserDetail?.onboardingUserDetail?.isMobileNoVerified) {
      body["mobileNo"] = myCountry?.isdCode + "-" + onboardingUserDetail?.onboardingUserDetail?.mobileNo
    }

    if (onboardingUserDetail?.onboardingUserDetail?.status === userDetailStatus?.PENDING?.value || onboardingUserDetail?.onboardingUserDetail?.status === userDetailStatus?.REJECTED?.value) {
      body["status"] = userDetailStatus?.SUBMITTED?.value
    }
    dispatcher(updateOnboardingUserDetailByOnboardingId(Number(onboardingId), body))
  }


  if (onboardingUserDetail?.isLoading) {
    return (
      <PageLoader />
    )
  }

  if (onboardingUserDetail?.errorMsg) {
    return (
      <div className={"w-screen h-screen flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
        {onboardingUserDetail?.errorMsg}
      </div>
    )
  }

  return (
    <form onSubmit={onSubmit} className={"space-y-5 overflow-x-hidden"}>
      <div className={"min-h-[24rem] block w-full border border-text-400 space-y-5"}>
        <div className={'space-y-1'}>
          <div className='w-full px-2 py-1 bg-text-300 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
            {"Personal Details"}
          </div>
          <span className='p-2 font-bodyPri font-normal text-xs text-text-900'>
            {"(Marked with * is mandatory field)"}
          </span>
          <div className='grid grid-cols-12 gap-5 px-2 pt-2'>
            <div className={"col-start-1 col-span-2 w-24 h-24 rounded-full overflow-hidden"}>
              <img
                src={profilePicture}
                className={"w-full h-full object-cover"}
              />
            </div>
            <div className={"col-start-0 col-span-full md:col-start-4 md:col-span-full flex flex-col justify-start items-start gap-2"}>
              <div className={"flex flex-col items-start justify-center gap-1 font-bodyPri font-normal text-text-800 text-sm"}>
                <span>
                  {"This will be displayed to other users when they view your profile."}
                </span>
                <span>
                  {"Max Size: 2MB"}
                </span>
              </div>
              {!selectedFile &&
                <label className={cn(
                  "px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                  "border border-text-500 text-text-500 text-sm",
                  "hover:bg-text-500 hover:text-text-50"
                )}>
                  <input
                    className={'border border-yellow-300'}
                    type={"file"}
                    id={"file"}
                    name={"image"}
                    style={{ display: "none" }}
                    multiple={false}
                    accept={imageType[uploadFile?.payload?.fileCategory].acceptFileType}
                    title={'upload'}
                    onChange={handleOnFileChange}
                    disabled={isFormDisabled}
                  />
                  {"Choose File"}
                </label>
              }
              {selectedFile &&
                <div className={"flex justify-start items-center gap-3"}>
                  <span
                    className={cn(
                      "px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                      !uploadFile?.isLoading && "border border-primary-main text-primary-main text-sm hover:bg-primary-main hover:text-text-50",
                      uploadFile?.isLoading && "bg-primary-main"
                    )}
                    onClick={onHandleUpload}
                  >
                    {uploadFile?.isLoading &&
                      <ButtonLoader isLoading={uploadFile?.isLoading} />
                    }
                    {!uploadFile?.isLoading &&
                      "Upload"
                    }
                  </span>
                  <span
                    className={cn(
                      "px-3 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                      "border border-text-500 text-text-500 text-sm",
                      "hover:bg-text-500 hover:text-text-50"
                    )}
                    onClick={() => setSelectedFile(null)}
                  >
                    {"Cancel"}
                  </span>
                </div>
              }
            </div>

            <div className='col-start-0 col-span-full md:col-start-0 md:col-span-3'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Title"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <OptionSelector
                options={Object.values(titleOptions)}
                placeholder={"Please pick a type"}
                required={true}
                value={onboardingUserDetail?.onboardingUserDetail?.title}
                onChange={(option) => {
                  if (!isFormDisabled) {
                    dispatcher(setOnboardingUserDetail({
                      ...onboardingUserDetail?.onboardingUserDetail,
                      title: option.value
                    }))
                  }
                }}
              />
            </div>
            <div className='col-start-0 col-span-full md:col-start-0 md:col-span-4'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"First Name"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <input
                type={"text"}
                placeholder={"John Doe"}
                autoFocus={true}
                value={onboardingUserDetail?.onboardingUserDetail?.firstName}
                className={cn(
                  "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => {
                  dispatcher(setOnboardingUserDetail({
                    ...onboardingUserDetail?.onboardingUserDetail,
                    firstName: (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1))
                  }))
                }}
                required={true}
                disabled={isFormDisabled}
              />
            </div>
            <div className='col-start-0 col-span-full md:col-start-0 md:col-span-5'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"last Name"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <input
                type={"text"}
                placeholder={"John Doe"}
                value={onboardingUserDetail?.onboardingUserDetail?.lastName}
                className={cn(
                  "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => dispatcher(setOnboardingUserDetail({
                  ...onboardingUserDetail?.onboardingUserDetail,
                  lastName: (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1))
                }))}
                required={true}
                disabled={isFormDisabled}
              />
            </div>
            <div className='col-start-0 col-span-full md:col-start-0 md:col-span-5'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Preferred Name"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <input
                type={"text"}
                placeholder={"Smith"}
                value={onboardingUserDetail?.onboardingUserDetail?.preferredName}
                className={cn(
                  "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => dispatcher(setOnboardingUserDetail({
                  ...onboardingUserDetail?.onboardingUserDetail,
                  preferredName: (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1))
                }))}
                required={true}
                disabled={isFormDisabled}
              />
            </div>
            <div className='col-start-0 col-span-full md:col-start-0 md:col-span-3'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Gender"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <OptionSelector
                options={Object.values(genderOptions)}
                placeholder={"Please pick a type"}
                value={onboardingUserDetail?.onboardingUserDetail?.gender}
                onChange={(option) => {
                  if (!isFormDisabled) {
                    dispatcher(setOnboardingUserDetail({
                      ...onboardingUserDetail?.onboardingUserDetail,
                      gender: option.value
                    }))
                  }
                }}
              />
            </div>
            <div className='col-start-0 col-span-full md:col-start-0 md:col-span-4'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Date Of Birth"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <DatePicker
                className={cn(
                  "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                value={birthDate}
                selected={birthDate}
                maxDate={new Date()}
                placeholderText={"Select Date"}
                scrollableYearDropdown={true}
                showYearDropdown={true}
                yearDropdownItemNumber={100}
                showMonthDropdown={true}
                onChange={(date) => setBirthDate(date)}
                required={true}
                disabled={isFormDisabled}
              />
            </div>
            <div className='col-start-1 col-span-full'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Email"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <input
                type={"text"}
                placeholder={"e.g. johndoe@gmail.com"}
                value={onboardingUserDetail?.onboardingUserDetail?.email}
                className={cn(
                  "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => dispatcher(setOnboardingUserDetail({
                  ...onboardingUserDetail?.onboardingUserDetail,
                  email: (e.target.value).toLowerCase()
                }))}
                required={true}
                disabled={isFormDisabled || onboardingUserDetail?.onboardingUserDetail?.isEmailVerified}
              />
              <span className="font-bodyPri font-normal text-xs text-red-500">
                {emailErrorText}
              </span>
            </div>
            <div className={'col-start-1 col-span-full'}>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Mobile Phone"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <div className={cn(
                "px-3 py-1 border-2 border-divider-medium bg-white rounded-md focus:outline-none flex items-center gap-3",
                "text-text-900 text-base font-bodyPri w-full"
              )}>
                {!pattern.test(onboardingUserDetail?.onboardingUserDetail?.mobileNo) && countryList?.isLoading &&
                  <FaSpinner className={"text-lg text-primary-main animate-spin"} />
                }
                {(!pattern.test(onboardingUserDetail?.onboardingUserDetail?.mobileNo) && countryList?.countryList) &&
                  <IsdCodeSelector
                    options={countryList?.countryList?.map((country) => ({
                      label: country?.isdCode,
                      value: country?.masterCountryId,
                      country: country
                    }))}
                    value={myCountry?.masterCountryId}
                    onChange={(option) => {
                      if (!onboardingUserDetail?.onboardingUserDetail?.isMobileNoVerified) {
                        onSelectIsdCode(option)
                      }
                    }}
                  />
                }
                <input
                  type={"number"}
                  required
                  placeholder={"e.g. 0123456789"}
                  value={onboardingUserDetail?.onboardingUserDetail?.mobileNo}
                  className={cn(
                    "w-full input-number-spin-none focus:outline-none",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm"
                  )}
                  onChange={(e) => dispatcher(setOnboardingUserDetail({
                    ...onboardingUserDetail?.onboardingUserDetail,
                    mobileNo: e.target.value
                  }))}
                  disabled={isFormDisabled || onboardingUserDetail?.onboardingUserDetail?.isMobileNoVerified}
                />
              </div>
              <span className="font-bodyPri font-normal text-xs text-red-500">
                {mobilePhoneErrorText}
              </span>
            </div>
            <div className='col-start-0 col-span-full md:col-start-0 md:col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Home Phone"}
              </span>
              <input
                type={"number"}
                placeholder={"e.g. (01) 10203040"}
                value={onboardingUserDetail?.onboardingUserDetail?.homePhone}
                className={cn(
                  "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => dispatcher(setOnboardingUserDetail({
                  ...onboardingUserDetail?.onboardingUserDetail,
                  homePhone: e.target.value
                }))}
                disabled={isFormDisabled}
              />
              <span className="font-bodyPri font-normal text-xs text-red-500">
                {homePhoneErrorText}
              </span>
            </div>
            <div className='col-start-0 col-span-full ms:col-start-0 md:col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Work Phone"}
              </span>
              <input
                type={"number"}
                placeholder={"(01) 10203040"}
                value={onboardingUserDetail?.onboardingUserDetail?.workPhone}
                className={cn(
                  "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => dispatcher(setOnboardingUserDetail({
                  ...onboardingUserDetail?.onboardingUserDetail,
                  workPhone: e.target.value
                }))}
                disabled={isFormDisabled}
              />
              <span className="font-bodyPri font-normal text-xs text-red-500">
                {workPhoneErrorText}
              </span>
            </div>
          </div>
        </div>


        {/* Address Section */}
        <div>
          <div className='w-full px-2 py-1 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
            {"Address Details"}
          </div>
          <div className='grid grid-cols-12 gap-5 px-2 py-1'>
            <div className='col-start-0 col-span-full flex flex-col items-start justify-start gap-2'>
              <div className={"flex items-center justify-start gap-8"}>
                {Object?.values(addressTypeConst)?.map((addressType) => (
                  <div className={"flex items-center justify-start gap-1.5"}>
                    <input
                      type={"radio"}
                      name={"address"}
                      className={"border border-text-300 hover:border hover:border-text-700 rounded-full cursor-pointer"}
                      defaultValue={addressType?.value}
                      defaultChecked={addressType?.value === "home" && true}
                      checked={addressType?.value === onboardingUserDetail?.onboardingUserDetail?.addressType && true}
                      onChange={(event) => dispatcher(setOnboardingUserDetail({
                        ...onboardingUserDetail?.onboardingUserDetail,
                        addressType: event?.target?.value
                      }))}
                      disabled={isFormDisabled}
                    />
                    <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                      {addressType?.label}
                    </span>
                  </div>
                ))}
              </div>
              <div className={'w-full font-bodyPri font-normal text-text-900 text-sm'}>
                {"Street Address"}
                <span className={"text-red-500 text-lg"}>{"*"}
                </span>
                <input
                  type={"text"}
                  placeholder={"e.g. 142 Palm Avenue"}
                  value={onboardingUserDetail?.onboardingUserDetail?.street}
                  className={cn(
                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm"
                  )}
                  onChange={(e) => dispatcher(setOnboardingUserDetail({
                    ...onboardingUserDetail?.onboardingUserDetail,
                    street: e.target.value
                  }))}
                  required={true}
                  disabled={isFormDisabled}
                />
              </div>
            </div>
            <div className='col-start-0 col-span-full md:col-start-0 md:col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Country"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
                {countryList?.isLoading &&
                  <div className={"flex items-center justify-start gap-0.5"}>
                    <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                      {"Please wait ..."}
                    </span>
                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                  </div>
                }
                <div className={cn(
                  "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}>
                  <SelectSearch
                    options={countryList?.countryList?.map((country) => ({
                      name: country?.country,
                      value: country?.masterCountryId,
                      domain: country?.countryDomain
                    })) || []}
                    search
                    autoFocus={false}
                    placeholder={"Select Country"}
                    filterOptions={fuzzySearch}
                    value={onboardingUserDetail?.onboardingUserDetail?.country?.masterCountryId}
                    onChange={(option) => onSelectCountry(option)}
                    disabled={isFormDisabled}
                  />
                </div>
                {/* {(countryList?.countryList || []) &&
                  <select
                    name='country'
                    className={cn(
                      "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                      "font-bodyPri font-normal text-text-800 text-base",
                      "placeholder:text-text-500 placeholder:text-sm"
                    )}
                    onChange={(e) => dispatcher(setOnboardingUserDetail({
                      ...onboardingUserDetail?.onboardingUserDetail,
                      country: e.target.value
                    }))}
                    disabled={isFormDisabled}
                  >
                    {!onboardingUserDetail?.onboardingUserDetail?.country &&
                      <option value={onboardingUserDetail?.onboardingUserDetail?.country}>
                        {"--Select Country--"}
                      </option>
                    }
                    {onboardingUserDetail?.onboardingUserDetail?.country &&
                      <option value={onboardingUserDetail?.onboardingUserDetail?.country}>
                        {onboardingUserDetail?.onboardingUserDetail?.country}
                      </option>
                    }
                    {countryList?.countryList?.map((country, index) => (
                      <option key={index} value={country?.country}>
                        {country?.country}
                      </option>
                    ))}
                  </select>
                } */}
                {countryList?.errorMsg &&
                  <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                    {countryList?.errorMsg}
                  </div>
                }
              </span>
            </div>
            {/* code for state and city commented in data File */}
            <div className='col-start-0 col-span-full md:col-start-0 md:col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Postal / ZIP Code"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
                <input
                  type={"text"}
                  placeholder={"e.g. CM203RX"}
                  value={onboardingUserDetail?.onboardingUserDetail?.zipCode}
                  className={cn(
                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm"
                  )}
                  onChange={(e) => dispatcher(setOnboardingUserDetail({
                    ...onboardingUserDetail?.onboardingUserDetail,
                    zipCode: e.target.value
                  }))}
                  required={true}
                  disabled={isFormDisabled}
                />
              </span>
            </div>
          </div>
        </div>


        {/* Social media online Presence */}
        <PersonalSocialMediaPresence isFormDisabled={isFormDisabled} />
      </div>

      <div className={"flex justify-end px-5"}>
        <button
          type={"submit"}
          disabled={isFormDisabled}
          className={cn(
            "w-28 py-1 flex justify-center items-center rounded-full",
            "font-buttons font-normal text-base",
            !isFormDisabled && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
            isFormDisabled && "text-text-300 border border-text-300 cursor-not-allowed",
            addOnboardingUserDetail?.isLoading && "bg-secondary-main"
          )}>
          {addOnboardingUserDetail?.isLoading &&
            <ButtonLoader isLoading={addOnboardingUserDetail?.isLoading} />
          }
          {!addOnboardingUserDetail?.isLoading &&
            "Submit"
          }
        </button>
      </div>
    </form>
  )
}

export default PersonalDetailModal;