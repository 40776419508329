import { memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import format from "string-format";

import { motion } from "framer-motion";

import { AiOutlineClose } from 'react-icons/ai';
import { FiDownload, FiShare } from 'react-icons/fi';

import ComponentLoader from 'components/loader/ComponentLoader';
import ToolTipView from 'components/tooltipView';
import FadedCollapse from 'components/fadedCollapse';

import { searchParamsInfo } from 'pages/auth/myDownloadable/data';

import { getUserDownloadDetail } from 'redux/downloads/downloads.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetUserDownloadDetail } from 'redux/downloads/downloads.slice';
import { setClearShareSocial, setModal, setShareSocial } from 'redux/local/local.slice';
import { modalConst, socialAction } from 'redux/local/local.const';

import { pagesInfo } from "utils/pagesInfo";
import { getCustomHostPath } from 'utils/generators.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

// const WEB_DOWNLOADABLE_BASE_URL = `${EDULYTE_WEB_DOMAIN_URL}/download/{slug}`;

const DownloadDetailContent = memo(({ closeModal }) => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { modal, shareSocial } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userDownloadDetail } = useAppState((state) => state.downloads)
    
    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    
    const WEB_DOWNLOADABLE_BASE_URL = `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/download/{slug}` })}`;
    let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        return () => {
            dispatcher(resetUserDownloadDetail())
        }
    }, [])

    useEffect(() => {
        if (searchQueryParams.get(searchParamsInfo.download_id.key)) {
            const downloadId = searchQueryParams.get(searchParamsInfo.download_id.key)
            dispatcher(getUserDownloadDetail(downloadId, user?.user?.userId))
        }
    }, [location.search])

    const onHandleShare = useCallback(() => {
        const publicUrl = format(WEB_DOWNLOADABLE_BASE_URL, { slug: userDownloadDetail?.data?.downloadable?.slug })
        dispatcher(setClearShareSocial())
        dispatcher(setShareSocial({
            ...shareSocial,
            socialAction: socialAction?.SHARE_DOWNLOAD?.value,
            url: publicUrl
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.myDownloadDetailModal.key]: {
                ...modalConst.myDownloadDetailModal,
                isVisible: false
            },
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
        searchQueryParams.delete(searchParamsInfo.download_id.key)
        navigate(pagesInfo.MY_DOWNLOADS.pagePath)
    }, [modal, shareSocial, userDownloadDetail?.data])

    if (userDownloadDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={userDownloadDetail?.isLoading} className={"min-h-[25rem]"} />
        )
    }

    if (userDownloadDetail?.message) {
        return (
            <div className={"flex items-center justify-center min-h-[25rem]"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {userDownloadDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <div className={"w-full flex flex-col gap-5 p-5 font-bodyPri"}>
            <div className={"w-full flex justify-end"}>
                <ToolTipView content={"Close"}>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ duration: 0.3 }}
                        className={"flex justify-center items-center h-8 w-8 text-lg hover:bg-back-ground-light rounded-full ease-in-out duration-200"}
                        onClick={closeModal}
                    >
                        <AiOutlineClose />
                    </motion.button>
                </ToolTipView>
            </div>
            <div className={"relative w-full rounded-md overflow-hidden shadow-all flex flex-col bg-white"}>
                <img
                    src={userDownloadDetail?.data?.downloadable?.preview?.preview_url}
                    alt={userDownloadDetail?.data?.downloadable?.title}
                    className="w-full aspect-video object-cover"
                />
                {!userDownloadDetail?.data?.downloadable?.preview?.preview_url &&
                    <div className={"absolute inset-0 z-50 rounded-lg overflow-hidden flex items-center justify-center"}>
                        <span className={"bg-black bg-opacity-50 px-2 py-1 rounded-sm text-text-50 font-bodyPri font-normal text-xs"}>
                            {"No Preview Available"}
                        </span>
                    </div>
                }
                <motion.button
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.8 }}
                    onClick={onHandleShare}
                    className="absolute top-3 right-3 bg-white shadow text-text-500 rounded-full h-8 w-8 flex justify-center items-center"
                >
                    <FiShare size={18} />
                </motion.button>
                <div className="flex flex-col gap-3 p-3">
                    <h4
                        onClick={() => window.open(format(WEB_DOWNLOADABLE_BASE_URL, { slug: userDownloadDetail?.data?.downloadable?.slug }), "_blank")}
                        className="font-medium text-base line-clamp-1 cursor-pointer hover:underline text-text-900 hover:text-secondary-dark">
                        {userDownloadDetail?.data?.downloadable?.title}
                    </h4>
                    <p className="text-sm line-clamp-2 text-text-700">
                        {"By:"} {`${userDownloadDetail?.data?.downloadable_user?.added_by?.first_name} ${userDownloadDetail?.data?.downloadable_user?.added_by?.last_name.charAt(0)}.`}
                    </p>
                </div>
            </div>
            <div className={"w-full flex flex-col gap-5"}>
                {(!userDownloadDetail?.data?.files || (userDownloadDetail?.data?.files?.length === 0)) &&
                    <div className={"w-full flex items-center justify-center"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                            {"No files added to download"}
                        </span>
                    </div>
                }
                {!!userDownloadDetail?.data?.files?.length &&
                    <div className={"w-full flex flex-col gap-5"}>
                        <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                            {"Download Files:"}
                        </span>
                        <FadedCollapse maxHeight={"12rem"}>
                            <div className={"w-full flex flex-col gap-5"}>
                                {userDownloadDetail?.data?.files?.map((fileItem, index) => (
                                    <a
                                        key={index}
                                        href={fileItem?.file_url}
                                        target={"_self"}
                                        download
                                        className={"w-full flex items-center justify-start gap-x-8 gap-y-5 group cursor-pointer"}
                                    >
                                        <div className={"font-bodyPri font-normal text-primary-dark group-hover:underline text-sm tracking-wide truncate"}>
                                            {fileItem?.file_url}
                                        </div>
                                        <div className={"flex justify-end"}>
                                            <FiDownload className={"text-lg text-text-800 group-hover:text-primary-dark"} />
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </FadedCollapse>
                    </div>
                }
            </div>
        </div>
    )
})

export default DownloadDetailContent;