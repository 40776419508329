import { useEffect, useMemo, useState } from 'react'
import { FaRegFilePdf, FaSpinner } from "react-icons/fa";
import { FiImage, FiShare } from "react-icons/fi";

import { motion } from 'framer-motion';
import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { useTitle } from 'hooks/useTitle'
import { useLocation, useNavigate } from 'react-router-dom'
import { setPageInfo } from 'redux/pageInfo/pageInfo.request'
import { pagesInfo } from 'utils/pagesInfo'
import PageHeader from 'components/pageHeader/PageHeader'
import { getCertificateListPayload, searchParamsInfo, studentCertificateListHeader, studentPageHeading } from '../data'
import certificateService from 'redux/certificate/certificate.service'
import dayjs from 'dayjs'
import { timeZone } from 'utils/dateTime.utils'
import LMSTable from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTable'
import Pagination from 'components/pagination/Pagination'
import ToolTipView from 'components/tooltipView'
import { EDULYTE_API_DOMAIN_URL, EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { getCustomHostPath } from 'utils/generators.utils';

const initialState = {
  isLoading: false,
  data: null,
  pagination: null,
  error: null,
}

const StudentCertificates = () => {
  const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { user } = useAppState((state) => state.user)
  const { modal, shareSocial } = useAppState((state) => state.local)

  const [certificateData, setCertificateData] = useState(initialState)
  const [downloadCertificate, setDownloadCertificate] = useState({ isLoading: false, id: null, message: null, data: null })

  const dispatcher = useAppDispatcher()
  const [title, setTitle] = useTitle()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.STUDENT_CERTIFICATES))
  }, [dispatcher])

  useEffect(() => {
    if (user?.user) {
      setTitle({
        ...title,
        title: `${user?.user?.firstName}, My Certificate | Edulyte`
      })
      geCertificateList()
    }
  }, [user?.user, location.search])

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  const geCertificateList = async () => {
    setCertificateData((s) => ({ ...s, isLoading: true }));
    const requestDataPayload = await getCertificateListPayload(searchQueryParams)
    try {
      const requestData = {
        params: { userId: user?.user?.userId },
        query: { ...requestDataPayload }
      };
      const response = await certificateService.getStudentCertificateList(requestData);
      if (response.status === 200) {
        setCertificateData((s) => ({
          ...s,
          data: response.data.data,
          isLoading: false
        }));
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error(error?.response?.data?.message || error?.response?.data?.error || error);
      setCertificateData((s) => ({
        ...s,
        error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!",
        isLoading: false,
      }));
    }
  };

  const onHandlePageChange = async (page) => {
    searchQueryParams.set(searchParamsInfo.page.key, page)
    navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
  }

  const onHandleDownloadCertificate = (certificateItem, type) => {
    if (downloadCertificate?.isLoading) return
    const certificateUrl = `${EDULYTE_API_DOMAIN_URL}/v1/certificates/verify?cert-code=${certificateItem?.certificate_code}&type=${type}`
    const fileName = type === 'pdf' ? `${certificateItem?.user_name + '_' + certificateItem?.certificate_code}.pdf` : `${certificateItem?.user_name}.png`
    setDownloadCertificate((s) => ({
      ...s,
      isLoading: true,
      id: certificateItem?.certificate_code,
    }));
    fetch(certificateUrl)
      .then((response) => {
        if (response?.status === 200) {
          return response.blob()
        } else throw new Error(response)
      })
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        if (type === 'png') {
          link.target = "_blank"
          link.click();
        } else {
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        setDownloadCertificate((s) => ({
          ...s,
          isLoading: false,
          id: null,
        }));
      })
      .catch((error) => {
        console.error("Error downloading QR code:", error)
        setDownloadCertificate((s) => ({
          ...s,
          message: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!",
          isLoading: false,
          id: null,
        }));
      });
  }

  const handleShare = (certificate) => {
    dispatcher(setShareSocial({
      ...shareSocial,
      // url: `${EDULYTE_WEB_DOMAIN_URL}/certificate?certificate_no=${certificate?.certificate_code}`
      url: `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/certificate?certificate_no=${certificate?.certificate_code}` })}`
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
    }))
  }

  const tableRowsConst = certificateData?.data?.result.map((certificate) => ([
    <a
      className='hover:underline'
      target='_blank'
      // href={`${EDULYTE_WEB_DOMAIN_URL}/certificate?certificate_no=${certificate?.certificate_code}`}
      href={`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/certificate?certificate_no=${certificate?.certificate_code}` })}`}
    >
      {certificate?.title}
    </a>,
    <span>{certificate?.type}</span>,
    dayjs(certificate?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
    <span>{certificate?.certificate_code}</span>,
    <div className={"flex items-center justify-start"}>
      <div className='flex items-center gap-2'>
        <ToolTipView content='Share Certificate'>
          <motion.button
            onClick={() => handleShare(certificate)}
            whileHover={{ scale: 1.09 }}
            whileTap={{ scale: 0.95 }}
            className='text-text-600'
          >
            <FiShare size={18} />
          </motion.button>
        </ToolTipView>
        <ToolTipView content='Download PDF'>
          <motion.button
            onClick={() => onHandleDownloadCertificate(certificate, "pdf")}
            whileHover={{ scale: 1.09 }}
            whileTap={{ scale: 0.95 }}
            className='text-[#f40f02]'
          >
            <FaRegFilePdf size={20} />
          </motion.button>
        </ToolTipView>
        <ToolTipView content='View Image'>
          <motion.button
            onClick={() => onHandleDownloadCertificate(certificate, "png")}
            whileHover={{ scale: 1.09 }}
            whileTap={{ scale: 0.95 }}
            className='text-primary-dark'
          >
            <FiImage size={20} />
          </motion.button>
        </ToolTipView>
        {downloadCertificate?.isLoading && downloadCertificate?.id === certificate?.certificate_code && (
          <FaSpinner className={"animate-spin text-lg text-primary-main"} />
        )}
      </div>
    </div>
  ]))

  return (
    <div className='h-full w-full p-3 mx-auto min-h-screen space-y-3 bg-back-ground-light font-bodyPri'>
      <PageHeader pageHeading={studentPageHeading} />
      <LMSTable
        isLoading={certificateData?.isLoading}
        headers={studentCertificateListHeader}
        rows={tableRowsConst}
        alternateRowColor={"bg-sky-100"}
        rowHeaderColor={"bg-sky-200"}
      />
      {(!certificateData?.isLoading && (certificateData?.data && (certificateData?.data?.pagination?.totalPages > 1))) &&
        <div className={"flex justify-center items-center"}>
          <Pagination
            page={certificateData?.data?.pagination?.page}
            totalPages={certificateData?.data?.pagination?.totalPages}
            onChangePage={onHandlePageChange}
          />
        </div>
      }
      {(!certificateData?.isLoading && certificateData?.error) &&
        <div className={'w-full flex items-center justify-center'}>
          <span className={'font-bodyPri font-normal text-red-500 text-base text-center'}>
            {certificateData.error}
          </span>
        </div>
      }
    </div>
  )
}

export default StudentCertificates
