import edrazorpayService from 'redux/edrazorpay/edrazorpay.service';
import {
    setCreateEdrazorpayOrder
} from "redux/edrazorpay/edrazorpay.slice";


export const createEdrazorpayOrder = (request) => async (dispatch) => {
    dispatch(setCreateEdrazorpayOrder({ isLoading: true }))

    try {
        const response = await edrazorpayService.createEdrazorpayOrder(request)
        if (response.status === 201) {
            dispatch(setCreateEdrazorpayOrder({ ...response.data.data, error: null }))
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.error || error)
        dispatch(setCreateEdrazorpayOrder({ result: null, error: error?.response?.data?.error || "Something Went Wrong" }))
    } finally {
        dispatch(setCreateEdrazorpayOrder({ isLoading: false }))
    }
}