import { useCallback } from 'react';

import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import Pagination from 'components/pagination/Pagination';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import LMSTableComponent from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTableComponent';
import { courseInstructorInvitationHeaderConst, courseInstructorInviteMenuItemsConst } from '../../../../editLMSCourse.data';

import { deleteLmsCourseInvitationDetail, getLmsCourseInvitationList } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetSendLmsCourseInvitationDetail, resetSendLmsCourseInvitationDetailPayload, setSendLmsCourseInvitationDetailPayload } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsCourseInviteStatusEnum, lmsCourseInviteToEnum, sendLmsCourseInvitationDetailPayload } from "redux/edulyteLms/lmsCourse/lmsCourse.const";

import { dayjs, timeZone } from 'utils/dateTime.utils';

const CourseInstructorInvitation = () => {
    const { modal } = useAppState((state) => state.local)
    const { lmsCourseDetail, lmsCourseInvitationList } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const onHandleInviteInstructor = useCallback(() => {
        dispatcher(resetSendLmsCourseInvitationDetail())
        dispatcher(resetSendLmsCourseInvitationDetailPayload())
        dispatcher(setSendLmsCourseInvitationDetailPayload({
            ...sendLmsCourseInvitationDetailPayload,
            lmsCourseId: lmsCourseDetail?.data?.id,
            inviteTo: lmsCourseInviteToEnum.TUTOR.value,
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.inviteLmsCourseUserModal.key]: {
                ...modal[modalConst.inviteLmsCourseUserModal.key],
                isVisible: true,
                title: "Invite Tutor"
            }
        }))
    }, [modal, lmsCourseDetail?.data])

    const onHandleSelectMenu = useCallback((menuItem, courseInvitation) => {
        switch (menuItem.value) {
            case courseInstructorInviteMenuItemsConst?.DELETE?.value:
                dispatcher(deleteLmsCourseInvitationDetail(courseInvitation?.id))
                break;

            default:
                break;
        }
    }, [lmsCourseInvitationList?.data])

    const onHandleChangePage = useCallback((page) => {
        const query = {
            page: page,
            records: 5,
            invite_to: lmsCourseInviteToEnum.TUTOR.value,
            lms_course_id: lmsCourseDetail?.data?.id
        }
        dispatcher(getLmsCourseInvitationList(query))
    }, [lmsCourseDetail?.data])

    const StudentImageContainer = ({ profilePicUrl }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={"relative w-10 h-10 overflow-hidden rounded-full"}>
                    <img
                        src={profilePicUrl}
                        className={"w-full h-full object-cover"}
                    />
                </div>
            </div>
        )
    }

    const courseInstructorInvitationRows = lmsCourseInvitationList?.data?.results?.map((courseInvitation) => [
        <StudentImageContainer profilePicUrl={courseInvitation?.user?.profile_pic_url} />,
        courseInvitation?.user?.first_name + " " + courseInvitation?.user?.last_name?.charAt(0) + ".",
        lmsCourseInviteToEnum[courseInvitation?.invite_to?.toUpperCase()]?.label,
        courseInvitation?.invite_email_sent ? "Yes" : "No",
        lmsCourseInviteStatusEnum[courseInvitation?.status?.toUpperCase()]?.label,
        dayjs(courseInvitation?.created_at)?.tz(timeZone)?.format("ddd, MMM D, YYYY"),
        <div className={"flex items-center justify-center"}>
            <ThreeDotMenu
                menuItems={Object.values(courseInstructorInviteMenuItemsConst)}
                onHandleSelect={(menuItem) => onHandleSelectMenu(menuItem, courseInvitation)}
            />
        </div>
    ])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full flex items-center justify-between gap-3"}>
                <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                    {"2. Invited Instructor(s)"}
                </span>
                <div
                    className={cn(
                        "flex items-center justify-center gap-1 py-1 px-3",
                        "border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"
                    )}
                    onClick={onHandleInviteInstructor}
                >
                    <MdAdd className={"text-lg group-hover:text-text-50"} />
                    <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                        {"Invite Instructor"}
                    </span>
                </div>
            </div>

            <div className={"px-5 space-y-3"}>
                {lmsCourseInvitationList?.isLoading &&
                    <ComponentLoader isLoading={lmsCourseInvitationList?.isLoading} />
                }
                {lmsCourseInvitationList?.message &&
                    <div className={"flex items-center justify-start gap-1"}>
                        <span className={"font-bodyPri font-medium text-red-500 text-base tracking-wide"}>
                            {lmsCourseInvitationList?.message}
                        </span>
                    </div>
                }
                {(!lmsCourseInvitationList?.isLoading && lmsCourseInvitationList?.data) &&
                    <LMSTableComponent
                        headers={Object.values(courseInstructorInvitationHeaderConst)}
                        subRowHeader={[]}
                        rows={courseInstructorInvitationRows}
                        rowHeaderColor={"bg-back-ground-medium"}
                        alternateRowColor={"bg-primary-light"}
                    />
                }
                {lmsCourseInvitationList?.data && (lmsCourseInvitationList?.data?.totalPages > 1) &&
                    <div className={"flex justify-center items-center"}>
                        <Pagination
                            page={lmsCourseInvitationList?.data?.page}
                            totalPages={lmsCourseInvitationList?.data?.totalPages}
                            onChangePage={onHandleChangePage}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default CourseInstructorInvitation;