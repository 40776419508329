import { useEffect, useMemo, useState } from 'react';
import { cn } from 'utils/cn.utils';
import { motion } from 'framer-motion';
import { Checkbox } from '@mui/material';
import { RiDeleteBin6Line } from "react-icons/ri";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import ToolTipView from 'components/tooltipView';

const Question = ({ question, onUpdate, isShowDelete, handleDeleteQuestion, isNotFilled }) => {
    const [title, setTitle] = useState(question.title);
    const [options, setOptions] = useState(question?.options);
    const [feedback, setFeedback] = useState(question.feedback);

    useEffect(() => {
        onUpdate({ ...question, title, options, feedback });
    }, [title, options, feedback]);

    const isBlankField = useMemo(() => !(
        !!title &&
        !!feedback &&
        options?.every(option => !!option?.title) &&
        options?.some(option => option?.is_correct)
    ), [title, options, feedback]);

    const handleDeleteOption = (id) => {
        setOptions(options?.filter(option => option?.id !== id));
    };

    const handleAddOption = () => {
        const newId = options?.length > 0 ? Math.max(...options?.map(option => option?.id)) + 1 : 1;
        setOptions([...options, { id: newId, title: '', is_correct: false }]);
    };

    const handleInputChange = (id, value) => {
        setOptions(options?.map(option => option?.id === id ? { ...option, title: value } : option));
    };

    const handleCheckboxChange = (id) => {
        setOptions(options?.map(option => option?.id === id ? { ...option, is_correct: true } : { ...option, is_correct: false }));
    };

    return (
        <div className={cn("relative flex flex-col gap-1 my-1.5 p-3 rounded-lg shadow bg-white border",
            isBlankField && isNotFilled ? 'border-red-300' : 'border-white'
        )}
        >
            {isBlankField && isNotFilled &&
                <ToolTipView content={"Please fill all required fields"}>
                    <div className='absolute -top-2 right-3 bg-white px-2 rounded-full text-xs text-red-400'>*Required</div>
                </ToolTipView>
            }
            <div className='flex items-center justify-between'>
                <input
                    className='w-full p-1 text-text-800 font-medium outline-none focus:border-b-primary-main border bg-transparent border-transparent hover:border-b-primary-main'
                    autoFocus={true}
                    placeholder="Enter Question Title*"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                {isShowDelete === question?.questionId && (
                    <ToolTipView content={"Delete Question"}>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => handleDeleteQuestion(question?.questionId)}
                            className={cn('w-8 h-8 flex justify-center items-center rounded-full',
                                'text-text-400 bg-white hover:text-secondary-dark  hover:shadow-md'
                            )}
                        >
                            <ClearIcon style={{ fontSize: 18 }} />
                        </motion.button>
                    </ToolTipView>
                )}
            </div>
            {options.map((option) => (
                <div key={option.id} className="flex items-center group gap-1 pr-2 rounded hover:shadow">
                    <>
                        <Checkbox
                            size="small"
                            checked={option.is_correct}
                            onChange={() => handleCheckboxChange(option.id)}
                        />
                        <input
                            className='w-full px-1 py-0.5 text-text-800 text-sm outline-none focus:border-b-primary-main border bg-transparent border-transparent'
                            placeholder="Enter Option*"
                            required
                            value={option.title}
                            onChange={(e) => handleInputChange(option.id, e.target.value)}
                        />
                    </>
                    <ToolTipView content={"Delete Option"}>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className={cn('w-5 h-5 flex justify-center items-center rounded-full',
                                'bg-transparent text-transparent group-hover:bg-white group-hover:text-text-500 hover:shadow-md hover:!text-secondary-dark')}
                            onClick={() => handleDeleteOption(option.id)}
                        >
                            <RiDeleteBin6Line size={20} />
                        </motion.button>
                    </ToolTipView>
                </div>
            ))}

            <div className="my-2">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleAddOption}
                    className={cn('flex items-center gap-1 px-3 py-0.5 rounded-full font-semibold font-bulletPoints text-sm shadow-sm',
                        'bg-white text-text-500 hover:bg-[rgba(0,0,0,0.1)] hover:text-back-ground-black'
                    )}
                >
                    <AddIcon style={{ fontSize: 18 }} />
                    {'Add New Option'}
                </motion.button>
            </div>

            <div className="flex items-start gap-0.5 text-text-800 text-sm">
                <span className="mt-1 text-text-600">Feedback:</span>
                <textarea
                    className='w-full px-1 py-0.5 h-auto scrollbar-thin text-text-800 text-sm outline-none focus:border-b-primary-main border bg-transparent border-transparent hover:border-primary-main'
                    placeholder="Enter Feedback*"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                />
            </div>
        </div>
    );
};

export default Question;
