import { useEffect } from "react";
import { cn } from "utils/cn.utils"
import { useParams } from 'react-router-dom';

import LectureView from "./lecture"
import ArticleView from "./article"
import QuizView from "./quiz"

import { getLmsCourseSectionLessonDetail } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetLmsCourseSectionLesson } from "redux/edulyteLms/lmsCourse/lmsCourse.slice";
import { lmsCourseSectionLessonTypeEnum } from "redux/edulyteLms/lmsCourse/lmsCourse.const";
import PageLoader from "components/loader/PageLoader";

const LessonView = () => {
  const { lmsCourseSectionLesson } = useAppState(state => state.lms.lmsCourse)

  const dispatcher = useAppDispatcher();
  const params = useParams();

  useEffect(() => {
    dispatcher(getLmsCourseSectionLessonDetail(Number(params?.lesson_slug)))

    return () => {
      dispatcher(resetLmsCourseSectionLesson())
    }
  }, [params?.lesson_slug])

  if (lmsCourseSectionLesson?.isLoading) {
    return (
      <PageLoader />
    )
  }

  if (!!lmsCourseSectionLesson?.message) {
    return (
      <div className={"w-full h-screen flex items-center justify-center"}>
        <div className={"flex flex-col items-center justify-center gap-8"}>
          <span className={"font-bodyPri font-semibold text-2xl text-text-700"}>{"Message"}</span>
          <span className={"font-bodyPri font-normal text-lg text-text-800"}>{lmsCourseSectionLesson?.message}</span>
        </div>
      </div>
    )
  }

  if (!Object.keys(lmsCourseSectionLessonTypeEnum)?.includes(lmsCourseSectionLesson?.data?.type?.toUpperCase())) {
    return (
      <div className={"w-full h-screen flex items-center justify-center"}>
        <div className={"flex flex-col items-center justify-center gap-8"}>
          <span className={"font-bodyPri font-semibold text-2xl text-text-700"}>{"Message"}</span>
          <span className={"font-bodyPri font-normal text-lg text-text-800"}>{"Oops, Invalid Lesson!"}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={cn(
      "grow",
      "overflow-auto scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
      "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-light"
    )}
    >
      {(lmsCourseSectionLesson?.data?.type === lmsCourseSectionLessonTypeEnum.LECTURE.value && lmsCourseSectionLesson?.data?.lecture) && (
        <LectureView lecture={lmsCourseSectionLesson?.data?.lecture} />
      )}
      {(lmsCourseSectionLesson?.data?.type === lmsCourseSectionLessonTypeEnum.ARTICLE.value && lmsCourseSectionLesson?.data?.article) && (
        <ArticleView article={lmsCourseSectionLesson?.data?.article} />
      )}
      {(lmsCourseSectionLesson?.data?.type === lmsCourseSectionLessonTypeEnum.QUIZ.value && lmsCourseSectionLesson?.data?.quiz) && (
        <QuizView quiz={lmsCourseSectionLesson?.data?.quiz} />
      )}
    </div>
  )
}

export default LessonView
