import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import PageLoader from 'components/loader/PageLoader';

import ArticleHeader from './components/articleHeader/ArticleHeader';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getLmsArticleDetail } from 'redux/edulyteLms/lmsArticle/lmsArticle.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetLmsArticleDetail } from 'redux/edulyteLms/lmsArticle/lmsArticle.slice';

import { useTitle } from 'hooks/useTitle';

import { pagesInfo } from 'utils/pagesInfo';

const EditLMSArticlePage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { lmsArticleDetail } = useAppState((state) => state.lms.lmsArticle)

    const dispatcher = useAppDispatcher()
    const { articleId } = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_LMS_ARTICLE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `${user?.user?.firstName}, Welcome to Edulyte LMS`
            })
        }
    }, [user?.user, currentPageInfo])

    useEffect(() => {
        if (articleId) {
            dispatcher(getLmsArticleDetail(articleId))
        }

        return () => {
            dispatcher(resetLmsArticleDetail())
        }
    }, [articleId])

    if (lmsArticleDetail?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (lmsArticleDetail?.message) {
        return (
            <div className={"w-full flex items-center justify-center"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {lmsArticleDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <div className={'space-y-3'}>
            <ArticleHeader />

            <div className={'w-full px-3'}>
                <Outlet />
            </div>
        </div>
    )
}

export default EditLMSArticlePage;