import { cn } from "utils/cn.utils";
import ToolTipView from 'components/tooltipView';

import { FaCheck, FaSpinner } from 'react-icons/fa'
import { ImCross } from 'react-icons/im'

const SaveAndCancelBtn = ({ isLoading = false, onHandleSave, onHandleReset, className = "" }) => {

    return (
        <>
            {isLoading &&
                <div className={"flex items-center gap-1.5"}>
                    <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                    <span className={"font-bodyPri font-normal text-xs text-primary-dark"}>
                        {"Please wait ..."}
                    </span>
                </div>
            }
            {!isLoading &&
                <div className={cn(className ? className : "flex flex-row gap-3")}>
                    <ToolTipView content={"Save"}>
                        <button className={"p-2 rounded-full hover:bg-back-ground-darkLight"} onClick={onHandleSave}>
                            <FaCheck className={"text-lg cursor-pointer text-green-500"} />
                        </button>
                    </ToolTipView>
                    <ToolTipView content={"Close"}>
                        <button className={"p-2 rounded-full hover:bg-back-ground-darkLight"} onClick={onHandleReset}>
                            <ImCross className={"text-sm text-text-600 cursor-pointer"} />
                        </button>
                    </ToolTipView>
                </div>
            }
        </>
    )
}

export default SaveAndCancelBtn;