import { memo, useMemo } from "react";

// import { OptionSelector } from "components/common-components/Select";
import FloatingLabelSelect from "components/floating/floatingLabelSelect";

import { actionButtons } from "./data";
import ActionIconButtons from "./ActionIconButtons";

import { generateEndTimeOptions, generateStartTimeOptions, dayjs, timeZone, getTimeZoneOffset, generateLocalWeekDays } from 'utils/dateTime.utils';

const TimeRange = memo(({
    isLoading = false,
    isDisabled = false,
    selectedTimeZone = timeZone,
    localDay,
    timeSlot,
    updateTimeSlot,
    deleteTimeSlot
}) => {
    const localWeekDays = useMemo(() => generateLocalWeekDays(selectedTimeZone), [selectedTimeZone])
    const timeZoneOffset = useMemo(() => getTimeZoneOffset(selectedTimeZone), [selectedTimeZone])
    const startTimeOption = useMemo(() => generateStartTimeOptions(dayjs().tz(selectedTimeZone)), [selectedTimeZone])
    const endTimeOption = useMemo(() => generateEndTimeOptions(selectedTimeZone, localDay, timeSlot?.startTime), [selectedTimeZone, localDay, timeSlot?.startTime])

    const onHandleIconButton = (selectedBtn) => {
        if (isLoading || isDisabled) return;

        if (selectedBtn.value === actionButtons.DELETE.value) {
            deleteTimeSlot(timeSlot)
        }
    }

    return (
        <div className={"w-full flex items-start justify-start md:justify-between gap-5"}>
            <div className={"w-full flex flex-col items-start justify-start gap-1"}>
                <div className={"w-full p-1 flex items-center justify-start space-x-1 rounded-lg bg-divider-darkLight"}>
                    <div className="w-[85px] sm:w-24 md:w-full">
                        <FloatingLabelSelect
                            showLabel={false}
                            showIcon={false}
                            customBtnStyle={"p-2 bg-white hover:bg-back-ground-lightBlue text-text-800 border-none border-b border-b-white focus-within:!ring-0 focus-within:border-b-primary-main focus-within:bg-back-ground-lightBlue"}
                            dropdownWrapperClassName={"w-36"}
                            dropDownContainerClassName={"max-h-52 overflow-y-auto scrollbar-thin"}
                            value={dayjs(localWeekDays[localDay?.toUpperCase()]?.date + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)?.format("hh:mma")}
                            options={startTimeOption}
                            onHandleSelect={(option) => updateTimeSlot(timeSlot, { startTime: option?.value, endTime: timeSlot?.endTime })}
                            OptionChild={({ option }) => (
                                <div className={"bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between"}>
                                    {option.label}
                                </div>
                            )}
                            isDisabled={isDisabled}
                        />
                        {/* <OptionSelector
                            options={startTimeOption}
                            onChange={(option) => updateTimeSlot(timeSlot, { startTime: option?.value, endTime: timeSlot?.endTime })}
                            value={timeSlot?.startTime}
                            disabled={isDisabled}
                        /> */}
                    </div>
                    <span className="font-bodyPri font-normal text-text-900 text-base px-1">
                        {"-"}
                    </span>
                    <div className="w-[85px] sm:w-24 md:w-full">
                        <FloatingLabelSelect
                            showLabel={false}
                            showIcon={false}
                            customBtnStyle={"p-2 bg-white hover:bg-back-ground-lightBlue text-text-800 border-none border-b border-b-white focus-within:!ring-0 focus-within:border-b-primary-main focus-within:bg-back-ground-lightBlue"}
                            dropdownWrapperClassName={"w-48"}
                            dropDownContainerClassName={"max-h-52 overflow-y-auto scrollbar-thin"}
                            value={dayjs(localWeekDays[localDay?.toUpperCase()]?.date + " " + timeSlot?.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)?.format("hh:mma")}
                            options={endTimeOption?.map((timeOption) => ({
                                label: `${timeOption?.label} (${timeOption?.duration})`,
                                value: timeOption?.value
                            }))}
                            onHandleSelect={(option) => updateTimeSlot(timeSlot, { startTime: timeSlot?.startTime, endTime: option?.value })}
                            OptionChild={({ option }) => (
                                <div className={"bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between"}>
                                    {option.label}
                                </div>
                            )}
                            isDisabled={isDisabled}
                        />
                        {/* <OptionSelector
                            options={endTimeOption?.map((timeOption) => ({
                                label: `${timeOption?.label} (${timeOption?.duration})`,
                                value: timeOption?.value
                            }))}
                            onChange={(option) => updateTimeSlot(timeSlot, { startTime: timeSlot?.startTime, endTime: option?.value })}
                            value={timeSlot?.endTime}
                            disabled={isDisabled}
                        /> */}
                    </div>
                </div>
            </div>

            <div className={"w-full flex items-center justify-end sm:justify-end"}>
                <ActionIconButtons
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    iconButtonList={Object.values(actionButtons)?.filter((iconBtn) => iconBtn?.value === actionButtons.DELETE.value)}
                    onHandleIconButton={onHandleIconButton}
                />
            </div>
        </div>

    );
});

export default TimeRange;