import { useEffect, useState } from 'react'

import { Provider } from "react-redux";

import store from "redux/store";

import { UserContextProvider } from "pages/auth/message/contexts/userContext";

type Props = {
    children: React.ReactNode
}

const StoreProvider = ({ children }: Props) => {
    const [isMount, setIsMount] = useState(false)

    useEffect(() => {
        setIsMount(true)
    }, [])

    if (!isMount) {
        return null
    }

    return (
        <Provider store={store}>
            <UserContextProvider>
                {children}
            </UserContextProvider>
        </Provider>
    )
}

export default StoreProvider