import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";
import format from 'string-format';
import { toast } from "react-toastify";

import { useStripe } from '@stripe/react-stripe-js';

import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';

import edrazorpayService from 'redux/edrazorpay/edrazorpay.service';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { initializeBooking, confirmBooking, getBookingDetailConfirmation } from 'redux/booking/booking.request';
import { createPaymentIntentDetail } from 'redux/payment/payment.request';
import { createEdrazorpayOrder as addCreateEdrazorpayOrder } from 'redux/edrazorpay/edrazorpay.request';
import { resetInitializeBookingDetail, resetBookingDetailConfirmation } from 'redux/booking/booking.slice';
import { resetCreateEdrazorpayOrder } from 'redux/edrazorpay/edrazorpay.slice';
import {
    setAddPaymentIntentDetailData,
} from 'redux/payment/payment.slice';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { bookingRegularPriceModelEnum } from 'redux/booking/booking.const';
import { paymentIntentStatusEnum, gatewayProviderEnum } from 'redux/payment/payment.const';

import { currencyRateConversion } from 'utils/generators.utils';

const paymentConfirmationUrl = "/payment/confirmation?payment_intent={payment_intent}&payment_intent_client_secret={payment_intent_client_secret}&redirect_status={redirect_status}";
const bookingConfirmationUrl = "/booking/confirmation?booking_id={booking_id}&redirect_status={redirect_status}"

const initialDataState = {
    isLoading: false,
    data: null,
    message: null
}

const PaymentSummerySection = () => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { locals } = useAppState(s => s.local)
    const { userBookingDetail, modifyUserBookingDetail, initializeBookingDetail, bookingDetailConfirmation } = useAppState(s => s.booking)
    const { addPaymentIntentDetail } = useAppState(s => s.payment)
    const { createEdrazorpayOrder } = useAppState(s => s.edrazorpay)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const stripe = useStripe();

    const [confirmCardPayment, setConfirmCardPayment] = useState(initialDataState)
    const [checkoutEdrazorpay, setCheckoutEdrazorpay] = useState(initialDataState)
    const [pendingLoading, setPendingLoading] = useState(false)

    useEffect(() => {
        if (initializeBookingDetail?.data?.result) {
            if (modifyUserBookingDetail?.payload?.isGatewayIncluded) {
                if (addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.stripe.key) {
                    let requestPayload = {
                        email: addPaymentIntentDetail?.payload?.email,
                        name: addPaymentIntentDetail?.payload?.name,
                        // org_id: publicOrgDetail?.data?.result?.id,
                        currency: addPaymentIntentDetail?.payload?.currency,
                        amount: addPaymentIntentDetail?.payload?.totalAmountToPay,
                        description: addPaymentIntentDetail?.payload?.description,
                        metadata: {
                            processingFee: addPaymentIntentDetail?.payload?.processingFee,
                            requiredAmount: addPaymentIntentDetail?.payload?.amountToPay,
                            paymentType: addPaymentIntentDetail?.payload?.paymentType,
                            bookingId: initializeBookingDetail?.data?.result?.id
                        }
                    }
                    dispatcher(createPaymentIntentDetail(requestPayload))
                } else if (addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.razorpay.key) {
                    let requestPayload = {
                        body: {
                            currency: addPaymentIntentDetail?.payload?.currency?.toUpperCase(),
                            amount: addPaymentIntentDetail?.payload?.totalAmountToPay,
                            metadata: {
                                processingFee: addPaymentIntentDetail?.payload?.processingFee,
                                requiredAmount: addPaymentIntentDetail?.payload?.amountToPay,
                                orderType: addPaymentIntentDetail?.payload?.paymentType,
                                bookingId: initializeBookingDetail?.data?.result?.id
                            }
                        }
                    }
                    dispatcher(addCreateEdrazorpayOrder(requestPayload))
                }
            } else {
                dispatcher(confirmBooking(initializeBookingDetail?.data?.result?.id))
            }
            dispatcher(resetInitializeBookingDetail())
        }
    }, [initializeBookingDetail?.data?.result])

    useEffect(() => {
        if (addPaymentIntentDetail?.data) {
            let clientSecret = addPaymentIntentDetail?.data?.client_secret
            let bookingId = addPaymentIntentDetail?.data?.metadata?.bookingId

            handlePayment({ clientSecret, bookingId })

            dispatcher(setAddPaymentIntentDetailData(null))
        }
    }, [addPaymentIntentDetail?.data])

    useEffect(() => {
        if (createEdrazorpayOrder?.result) {
            let bookingId = createEdrazorpayOrder?.result?.order?.notes?.bookingId

            handleEdrazorpay({ checkout_option: createEdrazorpayOrder?.result?.checkout_option, bookingId })
            dispatcher(resetCreateEdrazorpayOrder())
        }
    }, [createEdrazorpayOrder?.result])

    const handlePayment = async ({ clientSecret, bookingId }) => {
        setConfirmCardPayment({ ...confirmCardPayment, isLoading: true })
        try {
            const paymentIntentConfirmation = await stripe.confirmCardPayment(clientSecret, {
                payment_method: addPaymentIntentDetail?.payload?.paymentMethodId
            })

            if (paymentIntentConfirmation?.paymentIntent) {
                await handleNext(paymentIntentConfirmation?.paymentIntent, bookingId)
            } else if (paymentIntentConfirmation?.error) {
                setConfirmCardPayment({ ...confirmCardPayment, message: paymentIntentConfirmation?.error?.message })
            } else {
                throw new Error("Something went wrong!")
            }
        } catch (error) {
            console.error(error)
            setConfirmCardPayment({ ...confirmCardPayment, message: "Something went wrong!" })
        }
        setConfirmCardPayment({ ...confirmCardPayment, isLoading: false })
    }

    const handleEdrazorpay = async ({ checkout_option, bookingId }) => {
        setCheckoutEdrazorpay({ ...initialDataState, isLoading: true })
        try {
            const razorpayOptions = {
                    ...checkout_option,
                    modal: {
                        animation: true,
                        ondismiss: () => {
                            if (window.confirm("Are you sure, you want to close the form?")) {
                                setCheckoutEdrazorpay(initialDataState);
                            } else {
                                setCheckoutEdrazorpay(initialDataState);
                            }
                        }
                    },
                    handler: (response) => handleEdrazorpayNext(response, bookingId)
                };
            const razorpayInstance = new window.Razorpay(razorpayOptions)
            razorpayInstance.on("payment.failed", (response) => {
                setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false, message: response?.error?.description })
            })
            razorpayInstance.open()
        } catch (error) {
            console.error(error)
            setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false, message: "Something went wrong!" })
        }
    }

    const handleNext = async (paymentIntent, bookingId) => {
        if (paymentIntent.status === paymentIntentStatusEnum.SUCCEEDED.value) {
            dispatcher(getBookingDetailConfirmation(bookingId))
        } else {
            const query = {
                payment_intent: paymentIntent.id,
                payment_intent_client_secret: paymentIntent.client_secret,
                redirect_status: paymentIntent.status
            }
            navigate(format(paymentConfirmationUrl, query), { replace: true })
        }
    }

    const handleEdrazorpayNext = async (response, bookingId) => {
        const body = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
        };
        try {
            const response = await edrazorpayService.verifyEdrazorpayPayment({ body })
            if (response.status === 200) {
                setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false })
                dispatcher(getBookingDetailConfirmation(bookingId))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            setCheckoutEdrazorpay({ ...checkoutEdrazorpay, error: error?.response.data.error || "Something went wrong!" })
            console.error(error?.response.data.error || error)
        }
        setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false })
    }

    useEffect(() => {
        if (bookingDetailConfirmation?.data?.result) {
            setPendingLoading(true)
            const query = {
                booking_id: bookingDetailConfirmation?.data?.result?.id,
                redirect_status: bookingDetailConfirmation?.data?.result?.status,
            }
            dispatcher(resetBookingDetailConfirmation())
            setTimeout(() => {
                navigate(format(bookingConfirmationUrl, query), { replace: true })
                setPendingLoading(false)
            }, 2000)
        }
    }, [bookingDetailConfirmation?.data?.result])

    let payableAmount = 0
    let payButtonText = ""
    let isPayButtonEnable = false
    if (modifyUserBookingDetail?.payload?.isGatewayIncluded) {
        payableAmount = modifyUserBookingDetail?.payload?.gatewayAmountToPay
        payButtonText = "Add & Pay"
        isPayButtonEnable = (
            addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.razorpay.key ||
            (addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.stripe.key && modifyUserBookingDetail?.payload?.paymentMethod)) &&
            !(
                initializeBookingDetail?.isLoading ||
                addPaymentIntentDetail?.isLoading ||
                confirmCardPayment?.isLoading ||
                checkoutEdrazorpay?.isLoading ||
                bookingDetailConfirmation?.isLoading ||
                pendingLoading
            )
    } else {
        payableAmount = modifyUserBookingDetail?.payload?.walletAmountToPay + userBookingDetail?.data?.result?.bookingPrice?.promoCreditUsed
        payButtonText = "Pay From Wallet"
        isPayButtonEnable = !(
            initializeBookingDetail?.isLoading ||
            bookingDetailConfirmation?.isLoading ||
            pendingLoading
        )
    }

    const handlePay = () => {
        if (!isPayButtonEnable) return;

        dispatcher(initializeBooking(userBookingDetail?.data?.result?.id))
    }

    const getNetAmount = () => {
        let amount = userBookingDetail?.data?.result?.bookingPrice?.price
        let netAmount = 0
        if (userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.FREE.value) {
            netAmount = 0
        } else if (userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.FLAT_FEE.value) {
            netAmount = amount
        } else if (userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.PER_SESSION.value) {
            netAmount = amount * userBookingDetail?.data?.result?.bookingPrice?.sessionCount
        } else if (userBookingDetail?.data?.result?.bookingPrice?.priceModel === bookingRegularPriceModelEnum.PER_HOUR.value) {
            netAmount = (amount / 4 * userBookingDetail?.data?.result?.bookingPrice?.duration / 15) * userBookingDetail?.data?.result?.bookingPrice?.sessionCount
        }

        return netAmount
    }

    const getDiscount = () => {
        let netAmount = getNetAmount()

        let courseDiscountPrice = netAmount * (userBookingDetail?.data?.result?.bookingPrice?.discountPct / 100)
        let volumeDiscountPrice = (netAmount - courseDiscountPrice) * (userBookingDetail?.data?.result?.bookingPrice?.volumeDiscountPct / 100)

        return { courseDiscountPrice, volumeDiscountPrice }
    }

    return (
        <div className={"W-full bg-white shadow-all rounded-lg p-5 space-y-5"}>
            <div className={"w-full flex items-center justify-center"}>
                <span className={"text-center font-bodyPri font-semibold text-lg text-text-800"}>
                    {"Order Summary"}
                </span>
            </div>

            <div className={"space-y-1"}>
                {((getNetAmount() - userBookingDetail?.data?.result?.bookingPrice?.totalAmount) > 0) &&
                    <>
                        <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                            <span>
                                {"Order price:"}
                            </span>
                            <span>
                                {`${userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} ${parseFloat(getNetAmount() / 100).toFixed(2)}`}
                            </span>
                        </div>

                        {(userBookingDetail?.data?.result?.bookingPrice?.totalPrice !== 0) && <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>}

                        <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                            <div className={"flex items-center justify-center gap-1"}>
                                <span>
                                    {"Discount:"}
                                </span>
                                <span className={"relative has-tooltip cursor-pointer"}>
                                    <AiOutlineQuestionCircle className={"text-text-700"} />
                                    <span className={cn(
                                        'tooltip z-50! -translate-x-[50%] bottom-5 px-2 py-1 leading-0.5 text-center',
                                        'bg-black/90 text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide',
                                    )}>
                                        {getDiscount()?.courseDiscountPrice > 0 &&
                                            <div className={"flex items-center justify-center gap-1 whitespace-nowrap"}>
                                                <span>
                                                    {"Course discount:"}
                                                </span>
                                                <span>
                                                    {userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} {parseFloat(getDiscount()?.courseDiscountPrice / 100).toFixed(2)}
                                                </span>
                                            </div>
                                        }
                                        {getDiscount()?.volumeDiscountPrice > 0 &&
                                            <div className={"flex items-center justify-center gap-1 whitespace-nowrap"}>
                                                <span>
                                                    {"Volume discount:"}
                                                </span>
                                                <span>
                                                    {userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} {parseFloat(getDiscount()?.volumeDiscountPrice / 100).toFixed(2)}
                                                </span>
                                            </div>
                                        }
                                    </span>
                                </span>
                            </div>
                            <span>
                                {`- ${userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code}`} {parseFloat((getNetAmount() - userBookingDetail?.data?.result?.bookingPrice?.totalAmount) / 100).toFixed(2)}
                            </span>
                        </div>
                        {(userBookingDetail?.data?.result?.bookingPrice?.totalPrice !== 0) && <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>}
                    </>
                }

                <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                    <span>
                        {"Total:"}
                    </span>
                    <span>
                        {userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} {parseFloat(userBookingDetail?.data?.result?.bookingPrice?.totalAmount / 100).toFixed(2)}
                    </span>
                </div>


                {(userBookingDetail?.data?.result?.bookingPrice?.promoCreditUsed > 0) &&
                    <>
                        {(userBookingDetail?.data?.result?.bookingPrice?.totalPrice !== 0) && <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>}
                        <div className={"flex items-center justify-between font-bodyPri font-normal text-sm"}>
                            <span className={cn(userBookingDetail?.data?.result?.bookingPrice?.promoCreditUsed > 0 ? "text-green-500" : "text-text-700")}>
                                {"Promo used:"}
                            </span>
                            <span className={cn(userBookingDetail?.data?.result?.bookingPrice?.promoCreditUsed > 0 ? "text-green-500" : "text-text-700")}>
                                {userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} {parseFloat(userBookingDetail?.data?.result?.bookingPrice?.promoCreditUsed / 100).toFixed(2)}
                            </span>
                        </div>
                    </>
                }

                {(userBookingDetail?.data?.result?.bookingPrice?.totalPrice !== 0) && <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>}

                <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                    <span>
                        {"Wallet used:"}
                    </span>
                    <span>
                        {userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} {parseFloat(modifyUserBookingDetail?.payload?.walletAmountToPay / 100).toFixed(2)}
                    </span>
                </div>
            </div>

            <div className={"flex flex-col"}>
                <div className={"w-full flex items-center justify-between gap-2"}>
                    <span className={"font-bodyPri font-medium text-md text-primary-dark"}>
                        {"Sub Total:"}
                    </span>
                    <span className={"font-bodyPri font-semibold text-md text-primary-dark"}>
                        {userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} {parseFloat(payableAmount / 100).toFixed(2)}
                    </span>
                </div>
                {([masterCurrencyCodeEnum.USD.value]?.includes(userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code) && (locals.currencyCode !== masterCurrencyCodeEnum.USD.value)) &&
                    <div className={"w-full flex justify-end"}>
                        <span className={"whitespace-nowrap flex items-center gap-1 font-bodyPri font-normal text-sm text-text-800"}>
                            {"Approx"} {currencyRateConversion(locals?.currencyCode, payableAmount)}
                        </span>
                    </div>
                }
            </div>

            {modifyUserBookingDetail?.payload?.isGatewayIncluded &&
                <div className={"w-full rounded-lg border-2 border-text-300 space-y-2 p-5 transition-all ease-in-out delay-100 duration-150"}>
                    <div className={"w-full flex items-center justify-center"}>
                        <span className={"font-bodyPri font-medium text-md text-text-800 text-center"}>
                            {"Card Payment"}
                        </span>
                    </div>
                    <div className={"flex items-center justify-between font-bodyPri font-normal text-base text-text-900"}>
                        <span>{"Amount:"}</span>
                        <span>{userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} {parseFloat(addPaymentIntentDetail?.payload?.amountToPay / 100).toFixed(2)}</span>
                    </div>
                    <div className={"flex items-center justify-between font-bodyPri font-normal text-base text-text-900"}>
                        <div className={"flex items-center justify-satart gap-1"}>
                            <span>{"Processing fee:"}</span>
                            <span className={"relative has-tooltip cursor-pointer"}>
                                <AiOutlineQuestionCircle className={"text-text-700"} />
                                <span className={cn(
                                    'tooltip z-50! bottom-5 -translate-x-[50%] px-2 py-1 w-80 leading-0.5 text-center',
                                    'bg-black/90 text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide',
                                )}>
                                    {"Processing fees are charged by the third party payment providers, we recommend purchasing more sessions at one time to reduce the processing fee"}
                                </span>
                            </span>
                        </div>
                        <span>{userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} {parseFloat(addPaymentIntentDetail?.payload?.processingFee / 100).toFixed(2)}</span>
                    </div>
                    <div className={"flex itmes-start justify-between font-bodyPri font-normal text-base text-text-900"}>
                        <span>{"Total amount:"}</span>
                        <div className={"flex flex-col items-end justify-start"}>
                            <span>{userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code} {parseFloat(addPaymentIntentDetail?.payload?.totalAmountToPay / 100).toFixed(2)}</span>
                            {([masterCurrencyCodeEnum.USD.value]?.includes(userBookingDetail?.data?.result?.bookingPrice?.masterCurrency?.code) && (locals.currencyCode !== masterCurrencyCodeEnum.USD.value)) &&
                                <span className={"text-sm text-text-800"}>
                                    {"Approx "} {currencyRateConversion(locals?.currencyCode, addPaymentIntentDetail?.payload?.totalAmountToPay)}
                                </span>
                            }
                        </div>
                    </div>
                </div>
            }

            <div className={cn(
                "w-full px-4 py-2 rounded-md bg-secondary-dark hover:opacity-90 cursor-pointer",
                "text-center font-buttons font-medium text-base text-text-50 whitespace-nowrap",
                !isPayButtonEnable && "!bg-secondary-light !cursor-not-allowed relative has-tooltip"
            )} onClick={handlePay}>
                <span>{payButtonText}</span>
                {!isPayButtonEnable &&
                    <span className={cn(
                        'tooltip w-fit px-2 py-1 bg-black text-white rounded-lg',
                        "font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap",
                        "-translate-x-[80%] -translate-y-10"
                    )}>
                        {"Please check payment method"}
                    </span>
                }
            </div>

            {(initializeBookingDetail?.isLoading || bookingDetailConfirmation?.isLoading || addPaymentIntentDetail?.isLoading || confirmCardPayment?.isLoading || checkoutEdrazorpay?.isLoading || pendingLoading) &&
                <div className={cn(
                    "w-full font-bodyPri font-medium text-base text-text-800 text-start flex flex-row gap-1 items-center justify-center"
                )}>
                    <span>{"Please wait. Loading..."}</span>
                    <FaSpinner className={"text-lg text-primary-dark animate-spin"} />
                </div>
            }
            {(initializeBookingDetail?.message || bookingDetailConfirmation?.message || addPaymentIntentDetail?.message || confirmCardPayment?.message || checkoutEdrazorpay?.message) &&
                <div className={cn(
                    "w-full font-bodyPri font-normal text-xs text-red-500 text-center"
                )}>
                    <span>{initializeBookingDetail?.message || bookingDetailConfirmation?.message || addPaymentIntentDetail?.message || confirmCardPayment?.message || checkoutEdrazorpay?.message}</span>
                </div>
            }

        </div>
    )
}

export default PaymentSummerySection