import { useState, useEffect, memo, useCallback } from "react";
import { useParams } from "react-router-dom";

import { cn } from "utils/cn.utils";

import { AiOutlinePlus } from "react-icons/ai";

import ComponentLoader from "components/loader/ComponentLoader";
import InfinitePagination from "components/pagination/InfinitePagination";
import { libraryTabConst } from "components/modals/lmsModals/attachLibraryModal/data";

import { getLmsQuizPageArticleList } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetLocalTabs, setLocalTabs, setModal } from "redux/local/local.slice";
import { resetLmsQuizPageArticleList } from "redux/edulyteLms/lmsQuiz/lmsQuiz.slice";
import { modalConst } from "redux/local/local.const";
import { lmsQuizCategoryEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";
import { lmsArticleStatusEnum } from "redux/edulyteLms/lmsArticle/lmsArticle.const";

const QuizArticleListItems = memo(({ articleSearch }) => {
    const { localTabs, modal } = useAppState((state) => state.local)
    const { lmsQuizPageArticleList } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const { quizId } = useParams()

    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        if (quizId) {
            const requestDataPayload = {
                page: 1,
                records: 10,
                lms_quiz_id: `!${quizId}`
            }
            if (articleSearch) {
                requestDataPayload["search"] = articleSearch
            }
            dispatcher(getLmsQuizPageArticleList(requestDataPayload))

        }

        return () => {
            dispatcher(resetLmsQuizPageArticleList())
        }
    }, [quizId])

    const fetchMoreData = () => {
        if (lmsQuizPageArticleList?.data?.page === lmsQuizPageArticleList?.data?.totalPages) return;

        const requestDataPayload = {
            page: 1,
            records: lmsQuizPageArticleList?.data?.records
                ? (lmsQuizPageArticleList?.data?.records + 10)
                : lmsQuizPageArticleList?.data?.records || 10,
            lms_quiz_id: `!${quizId}`
        }
        if (articleSearch) {
            requestDataPayload["search"] = articleSearch
        }
        dispatcher(getLmsQuizPageArticleList(requestDataPayload))
    }

    const onHandleDragStart = (event, questionObject) => {
        const modifiedQuestionObject = { quizCategoryType: lmsQuizCategoryEnum.ARTICLE.value, ...questionObject }
        event.dataTransfer.setData('application/json', JSON.stringify(modifiedQuestionObject))
    }

    const onHandleOpenArticleModal = useCallback(() => {
        dispatcher(resetLocalTabs())
        dispatcher(setLocalTabs({
            ...localTabs,
            tabList: Object.values(libraryTabConst),
            activeTabList: [libraryTabConst.ARTICLES.value],
            currentActiveTab: libraryTabConst.ARTICLES.value,
            tabItem: {
                ...localTabs?.tabItem,
                payload: null
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true,
        }))
    }, [modal, localTabs])

    return (
        <>
            {lmsQuizPageArticleList?.isLoading &&
                <ComponentLoader isLoading={lmsQuizPageArticleList?.isLoading} />
            }
            {lmsQuizPageArticleList?.message &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-sm"}>
                        {lmsQuizPageArticleList?.message || "No Article Added"}
                    </span>
                </div>
            }
            {(lmsQuizPageArticleList?.data && (lmsQuizPageArticleList?.data?.results?.length === 0)) &&
                <div className={"w-full flex items-center justify-center"}>
                    <button
                        className={cn(
                            'w-full flex justify-center items-center gap-1 bg-white rounded-lg px-3 py-1.5',
                            'bg-text-200 hover:bg-text-300 ',
                            'font-bodyPri font-normal text-text-900 text-sm tracking-wide whitespace-nowrap'
                        )}
                        onClick={onHandleOpenArticleModal}
                    >
                        <AiOutlinePlus className={''} />
                        <span className={""}>
                            {"Add Article"}
                        </span>
                    </button>
                </div>
            }
            {(lmsQuizPageArticleList?.data && (lmsQuizPageArticleList?.data?.results?.length > 0)) &&
                <InfinitePagination
                    scrollableTarget={"scrollableDiv"}
                    dataLength={lmsQuizPageArticleList?.data?.totalRecords}
                    fetchData={fetchMoreData}
                    hasMore={(lmsQuizPageArticleList?.data?.page != lmsQuizPageArticleList?.data?.totalPages)}
                    loader={<ComponentLoader isLoading={lmsQuizPageArticleList?.isLoading} />}
                    isShowEndMessage={false}
                >
                    {lmsQuizPageArticleList?.data?.results?.map((articleItem) => (
                        <div
                            key={articleItem?.id}
                            className={cn(
                                'font-bodyPri line-clamp-1 mb-2 hover:bg-back-ground-lightBlue rounded p-1',
                                isDragging ? "cursor-grabbing" : "cursor-grab",
                                ![lmsArticleStatusEnum?.PUBLISHED?.value]?.includes(articleItem?.article_setting?.status) && "!cursor-not-allowed"
                            )}
                            draggable={true}
                            onDragStart={(event) => onHandleDragStart(event, articleItem)}
                            onMouseDown={() => setIsDragging(true)}
                            onMouseUp={() => setIsDragging(false)}
                            onMouseMove={() => {
                                if (isDragging) {
                                    setIsDragging(false)
                                }
                            }}
                        >
                            {articleItem?.article_setting?.status &&
                                <span className={`text-xs text-${lmsArticleStatusEnum[articleItem?.article_setting?.status?.toUpperCase()]?.darkColor}`}>
                                    {`(${lmsArticleStatusEnum[articleItem?.article_setting?.status?.toUpperCase()]?.label})`}
                                </span>
                            }
                            <span className={"pl-1"}>{`${articleItem?.title}`}</span>
                        </div>
                    ))}
                </InfinitePagination>
            }
        </>
    )
});

export default QuizArticleListItems;