import { useEffect, useState, useMemo, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import PageLoader from "components/loader/PageLoader";
import ToolTipView from 'components/tooltipView';

import HeaderView from './components/header';
import QuizPreview from './components/quiz-preview';
import QuizSheet from './components/quiz-sheet';
import QuizResult from './components/quiz-result';

import { useTitle } from 'hooks/useTitle';
import { useAppState, useAppDispatcher } from 'hooks/useStore'

import { resetAddLmsQuizStudentQuizResponse, resetLmsQuizResponse, resetStudentLmsQuizDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';

import { createLmsQuizStudentQuizResponseDetail, getLmsQuizResponseDetail, getStudentLmsQuizDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';


const QuizPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState(state => state.user)
    const {
        studentLmsQuizDetail,
        addLmsQuizStudentQuizResponse,
        lmsQuizResponse
    } = useAppState(state => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher();
    const params = useParams();
    const [title, setTitle] = useTitle()

    const [isQuizResume, setIsQuizResume] = useState(false)

    const quiz_student_quiz_response = useMemo(() => {
        return studentLmsQuizDetail?.data?.quiz_student?.quiz_student_quiz_responses?.find(quiz_student_quiz_response => quiz_student_quiz_response?.quiz === studentLmsQuizDetail?.data?.quiz?.id)
    }, [studentLmsQuizDetail?.data?.quiz_student?.quiz_student_quiz_responses])

    const isQuizStarted = !!quiz_student_quiz_response

    useEffect(() => {
        setTitle({
            ...title,
            title: `Edulyte Quiz Study Mode`
        })
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.STUDENT_LMS_QUIZ))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (params?.slug && user?.user?.student?.studentId) {
            dispatcher(getStudentLmsQuizDetail(params?.slug, user?.user?.student?.studentId))
        }
        return () => {
            dispatcher(resetStudentLmsQuizDetail())
        }
    }, [params?.slug, user?.user?.student])

    useEffect(() => {
        if (!!quiz_student_quiz_response?.quiz_response) {
            dispatcher(getLmsQuizResponseDetail(quiz_student_quiz_response?.quiz_response))
        }
        return () => {
            dispatcher(resetLmsQuizResponse())
        }
    }, [quiz_student_quiz_response?.quiz_response])

    useEffect(() => {
        if (!!addLmsQuizStudentQuizResponse?.data) {
            dispatcher(resetAddLmsQuizStudentQuizResponse())
            setIsQuizResume(true)
        }
    }, [addLmsQuizStudentQuizResponse?.data])

    const onHandleStartQuiz = useCallback(() => {
        if (addLmsQuizStudentQuizResponse?.isLoading) return;

        const requestBody = {
            quiz_student_id: studentLmsQuizDetail?.data?.quiz_student?.id,
            quiz_id: studentLmsQuizDetail?.data?.quiz?.id
        }
        dispatcher(createLmsQuizStudentQuizResponseDetail(requestBody))
    }, [studentLmsQuizDetail?.data, addLmsQuizStudentQuizResponse?.data, isQuizStarted])

    const onHandleResumeQuiz = useCallback(() => {
        if (!!quiz_student_quiz_response?.quiz_response) {
            setIsQuizResume(true)
        } else {
            onHandleStartQuiz()
        }
    }, [quiz_student_quiz_response?.quiz_response, isQuizStarted])

    const onHandleCancel = useCallback(() => {
        setIsQuizResume(false)
    }, [])


    if (user?.isLoading || studentLmsQuizDetail?.isLoading || lmsQuizResponse?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (studentLmsQuizDetail?.message) {
        return (
            <div className={"w-full h-screen flex items-center justify-center"}>
                <div className={"flex flex-col items-center justify-center gap-8"}>
                    <span className={"font-bodyPri font-semibold text-2xl text-text-700"}>{"Message"}</span>
                    <span className={"font-bodyPri font-normal text-lg text-text-800"}>{studentLmsQuizDetail?.message}</span>
                </div>
            </div>
        )
    }

    return (
        <main className='min-h-screen w-full relative bg-white'>
            <HeaderView studentLmsQuiz={studentLmsQuizDetail?.data} />
            <section className='w-full flex relative'>
                {(lmsQuizResponse?.data?.is_completed)
                    ? (
                        <QuizResult
                            quiz={studentLmsQuizDetail?.data?.quiz}
                            lmsQuizResponse={lmsQuizResponse?.data}
                        />
                    )
                    : (isQuizResume && !!quiz_student_quiz_response?.quiz_response)
                        ? (
                            <QuizSheet
                                quiz={studentLmsQuizDetail?.data?.quiz}
                                lmsQuizResponse={lmsQuizResponse?.data}
                                onHandleCancel={onHandleCancel}
                            />
                        ) : (
                            <QuizPreview
                                quiz={studentLmsQuizDetail?.data?.quiz}
                                isLoading={addLmsQuizStudentQuizResponse?.isLoading || lmsQuizResponse?.isLoading}
                                message={addLmsQuizStudentQuizResponse?.message || lmsQuizResponse?.message}
                                isQuizStarted={isQuizStarted}
                                onHandleStartQuiz={onHandleStartQuiz}
                                onHandleResumeQuiz={onHandleResumeQuiz}
                            />
                        )
                }
            </section>
        </main>
    )
}

export default QuizPage