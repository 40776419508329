import { Link } from 'react-router-dom';
import { cn } from "utils/cn.utils";

const LearnersResources = ({ dataLinks }) => {

    return (
        <div className={"col-span-1 flex flex-col gap-5"}>
            <span className={"font-bodyPri font-medium text-base"}>
                {dataLinks?.linksTitle}
            </span>
            <div className={"flex flex-col gap-2"}>
                {dataLinks.linksItems.map((linkItem, index) => {
                    if (linkItem.pathType === 'href') {
                        return (
                            <a
                                key={index}
                                className={cn(
                                    "font-bodyPri font-normal text-base",
                                    "self-start text-left hover:opacity-50"
                                )}
                                href={linkItem.path}
                                target={'_blank'}
                            >
                                {linkItem.name}
                            </a>
                        )
                    }
                    if (linkItem.pathType === 'onlyText') {
                        return (
                            <div
                                key={index}
                                className={cn(
                                    "font-bodyPri font-normal text-base",
                                    "self-start text-left hover:opacity-50"
                                )}>
                                {linkItem.name}
                            </div>
                        )
                    } else {
                        return (
                            <Link
                                key={index}
                                to={linkItem.path}
                                className={cn(
                                    "font-bodyPri font-normal text-base",
                                    "self-start text-left hover:opacity-50"
                                )}
                            >
                                {linkItem.name}
                            </Link>
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default LearnersResources