import { cn } from "utils/cn.utils";

import ComponentLoader from 'components/loader/ComponentLoader';
import RatingStars from 'components/ratingStars/RatingStars';

import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/createCourse/createCourse.style';
import { inputSections } from "pages/auth/createCourse/data";

import { useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const CourseTutorsSection = ({ clickedItem, setClickedItem }) => {
    const { tutorCourseDetail } = useAppState((state) => state.course)

    const isOpen = inputSections?.COURSE_TUTORS?.value === clickedItem;

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_TUTORS?.value)) {
            setClickedItem(inputSections?.COURSE_TUTORS?.value)
        } else {
            setClickedItem(null)
        }
    }

    return (
        <div className={"w-full h-full bg-white rounded-lg select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-back-ground-darkLight rounded-lg hover:shadow-all-md cursor-pointer"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Tutor(s)"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                {tutorCourseDetail?.isLoading && <ComponentLoader isLoading={tutorCourseDetail?.isLoading} />}
                {tutorCourseDetail?.message &&
                    <div className={"w-full h-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base"}>
                        {tutorCourseDetail?.message}
                    </div>
                }
                <div className={"w-full flex flex-col items-start justify-start gap-5"}>
                    {tutorCourseDetail?.data &&
                        <div className={cn(
                            "w-full md:w-[30rem] p-5 flex flex-col items-center justify-center md:flex-row md:items-start md:justify-start gap-5 border-2 border-text-300 rounded-lg"
                        )}>

                            <a
                                href={`${pagesInfo?.TUTOR?.pagePath}/${tutorCourseDetail?.data?.tutor?.user?.id}`}
                                target={"_blank"}
                                className={"relative w-24 h-24 rounded-full sm:w-28 sm:h-28"}>
                                <img
                                    src={tutorCourseDetail?.data?.tutor?.user?.profilePicUrl}
                                    alt={"profileImg"}
                                    className={"w-full h-full rounded-full object-cover"}
                                />
                                <div className={"relative has-tooltip"}>
                                    <div className={'absolute bottom-1 right-1 w-6 h-6 rounded-full overflow-hidden border-2 border-white'}>
                                        <img
                                            src={`https://flagcdn.com/16x12/${tutorCourseDetail?.data?.tutor?.user?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                            alt={"country-flag"}
                                            className={'w-full h-full object-cover'}
                                        />
                                    </div>
                                    <span
                                        className={cn(
                                            "w-fit px-1 py-0.5 font-bodyPri font-normal text-xs truncate rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-2 -right-0",
                                            "flex flex-col items-center justify-start overflow-hidden"
                                        )}
                                    >
                                        {tutorCourseDetail?.data?.tutor?.user?.fromCountry?.country}
                                    </span>
                                </div>
                            </a>

                            <div className="flex flex-col justify-center items-center md:justify-start md:items-start gap-2">

                                <div className={"flex items-center justify-start gap-2 font-bodyPri font-normal tracking-wide"}>
                                    <span className="text-text-700 text-base">
                                        {"Name:"}
                                    </span>
                                    <div className="flex items-center justify-start gap-2 font-bodyPri font-normal text-text-900 text-base capitalize">
                                        {tutorCourseDetail?.data?.tutor?.user?.firstName + " " + tutorCourseDetail?.data?.tutor?.user?.lastName?.charAt(0) + "."}
                                        {tutorCourseDetail?.data?.tutor?.user?.onlineStatus === "online" &&
                                            <div
                                                className={"w-1.5 h-1.5 rounded-full bg-green-500"}>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className={"flex items-center justify-start gap-1.5"}>
                                    <RatingStars rating={tutorCourseDetail?.data?.tutor?.rating?.avgRating} fullRating={5} />
                                    <span className="font-bold">
                                        {tutorCourseDetail?.data?.tutor?.rating?.avgRating?.toFixed(1)}({tutorCourseDetail?.data?.tutor?.rating?.totalStars?.count})
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </CollapsableContainer >
        </div >
    )
}

export default CourseTutorsSection;