import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { AiOutlineClose } from 'react-icons/ai';
import { IoIosCheckmark } from 'react-icons/io';

import DropdownMenu from '../commonComponents/DropdownMenu';

import { initialLocationPayload, isSimilarOrInValidLocationDetail, locationsConst, validateAppointmentLocationPayload } from 'pages/auth/editAppointment/data';

import LocationDetail from './components/LocationDetail';
import InPersonMeetingDetail from './components/InPersonMeetingDetail';
import PhoneCallDetail from './components/PhoneCallDetail';
import AdditionalInformation from './components/AdditionalInformation';
import UpdateAndCancelBtn from 'components/modals/appointmentModal/commonComponents/UpdateAndCancelBtn';

import { createAppointmentLocationDetail, updateAppointmentLocationDetail } from 'redux/appointment/appointment.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { locationTypeEnum } from "redux/appointment/appointment.const";

const LocationModalContent = ({ onHandleCloseModal }) => {
  const { modal } = useAppState((state) => state.local)
  const { user } = useAppState((state) => state.user)
  const { appointmentLocationList, addAppointmentLocationDetail, modifyAppointmentLocationDetail } = useAppState((state) => state.appointment)

  const dispatcher = useAppDispatcher()

  const [locationPayload, setLocationPayload] = useState({
    ...initialLocationPayload,
    ...modal?.appointmentLocationDetailModal?.payload,
  })

  const isInValidOrSimilarData = useMemo(() => isSimilarOrInValidLocationDetail(modal?.appointmentLocationDetailModal?.payload, locationPayload), [modal?.appointmentLocationDetailModal?.payload, locationPayload])

  const filteredLocationOptions = useMemo(() => {
    if (!appointmentLocationList?.data?.result) {
      return []
    }

    const alreadyAddedLocations = appointmentLocationList.data.result.map(item => item?.location_type)

    let filteredList = []

    const isGoogleCalendarEnabled = user?.user?.calendar?.google?.active

    Object.values(locationsConst)?.filter((item) => (item?.value !== locationTypeEnum.edulyte_meet.key)).forEach(item => {
      if (item.value !== locationTypeEnum.google_meet.key) {
        if ((item.value === locationTypeEnum.custom.key) || (item.value === locationTypeEnum.in_person_meeting.key)) {
          filteredList.push(item)
        } else if (!alreadyAddedLocations.includes(item.value)) {
          filteredList.push(item)
        }
      }
    })

    if (isGoogleCalendarEnabled && !alreadyAddedLocations.includes(locationTypeEnum.google_meet.key)) {
      filteredList.push(locationsConst[locationTypeEnum.google_meet.key])
    }

    return Array.from(new Set(filteredList.map(item => item.value)))
      .map(value => filteredList.find(item => item.value === value));
  }, [appointmentLocationList?.data?.result, user?.user?.calendar?.google])

  const onHandleSelectLocation = useCallback((option) => {
    setLocationPayload({
      ...modal?.appointmentLocationDetailModal?.payload,
      location_type: option
    })
  }, [locationPayload, modal?.appointmentLocationDetailModal?.payload])

  const onHandleUpdateBtn = async () => {
    if (addAppointmentLocationDetail?.isLoading || modifyAppointmentLocationDetail?.isLoading) return;

    const { requestPayload, errorMsg } = await validateAppointmentLocationPayload(locationPayload)
    if (errorMsg) {
      toast.error(errorMsg)
      return;
    }
    if (requestPayload) {
      if (locationPayload?.isEditEnabled) {
        dispatcher(updateAppointmentLocationDetail(locationPayload?.appointment_id, locationPayload?.id, requestPayload))
      } else {
        dispatcher(createAppointmentLocationDetail(locationPayload?.appointment_id, requestPayload))
      }
    }
  }

  return (
    <div className={"w-full flex flex-col gap-1 px-5 py-2 pb-5"}>
      <div className={"flex w-full justify-between items-center bg-back-ground-light h-16 drop-shadow-sm"}>
        <p className="font-bodyPri font-medium md:font-semibold text-text-900 text-lg md:text-xl">
          {modal?.appointmentLocationDetailModal?.title}
        </p>
        <AiOutlineClose
          onClick={onHandleCloseModal}
          className={'cursor-pointer'}
          size={20}
        />
      </div>
      <DropdownMenu
        options={filteredLocationOptions?.map((locationItem) => ({
          label: locationItem?.label,
          value: locationItem?.value,
          icon: locationItem?.icon,
          location: locationItem
        })) || []}
        onHandleSelect={onHandleSelectLocation}
        selectedOption={<button className={"flex items-center justify-start gap-3 font-normal text-text-800"}>
          <locationPayload.location_type.icon className={"text-xl"} />
          <span className={""}>
            {locationPayload?.location_type?.label}
          </span>
        </button>}
        selectedMenuItem={locationPayload?.location_type?.value}
        OptionChild={({ option }) => (
          <div className={"w-full flex items-center justify-between gap-3"}>
            <div className={"flex items-center justify-start gap-3"}>
              <option.icon className={"text-xl"} />
              {option?.label}
            </div>
            {(option?.value === locationPayload?.location_type?.value)
              && <IoIosCheckmark size={20} color="green" />
            }
          </div>
        )}
      />

      {[locationTypeEnum.phone_call.key]?.includes(locationPayload?.location_type?.value) &&
        <div className={"pt-5"}>
          <PhoneCallDetail locationPayload={locationPayload} setLocationPayload={setLocationPayload} />
        </div>
      }

      {[locationTypeEnum.in_person_meeting.key]?.includes(locationPayload?.location_type?.value) &&
        <div className={"pt-5"}>
          <InPersonMeetingDetail locationPayload={locationPayload} setLocationPayload={setLocationPayload} />
        </div>
      }

      {[locationTypeEnum.invitee.key]?.includes(locationPayload?.location_type?.value) &&
        <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
          {"Your invitee can type any response which will be used as the location on confirmation."}
        </span>
      }

      {[locationTypeEnum.custom.key]?.includes(locationPayload?.location_type?.value) &&
        <div className={"pt-5"}>
          <LocationDetail locationPayload={locationPayload} setLocationPayload={setLocationPayload} />
        </div>
      }

      {[locationTypeEnum.google_meet.key, locationTypeEnum.edulyte_meet.key]?.includes(locationPayload?.location_type?.value) &&
        <span className={"font-bodyPri font-normal text-text-700 text-sm tracking-wide"}>
          {"Conferencing details will be provided upon booking completion."}
        </span>
      }

      <div className={"pt-5"}>
        <AdditionalInformation locationPayload={locationPayload} setLocationPayload={setLocationPayload} />
      </div>

      <div className={"w-full pt-5"}>
        <UpdateAndCancelBtn
          updateBtnText={modal?.appointmentLocationDetailModal?.payload?.isEditEnabled ? "Update" : "Add"}
          isUpdateBtnLoading={addAppointmentLocationDetail?.isLoading || modifyAppointmentLocationDetail?.isLoading}
          isUpdateBtnDisabled={addAppointmentLocationDetail?.isLoading || modifyAppointmentLocationDetail?.isLoading || isInValidOrSimilarData}
          onHandleCancelBtn={onHandleCloseModal}
          onHandleUpdateBtn={onHandleUpdateBtn}
        />
      </div>
    </div>
  )
}

export default LocationModalContent;