import { Fragment, memo, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";

import { AiOutlineClose } from "react-icons/ai";
import { MdEdit } from "react-icons/md";

import ButtonLoader from "components/loader/ButtonLoader";
import AiTextButton from "components/createWithAi/aiTextButton";

import { createLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request'

import useAiText from "hooks/useAiText";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setAddLmsCourseDetailPayload, resetAddLmsCourseDetail, resetAddLmsCourseDetailPayload } from "redux/edulyteLms/lmsCourse/lmsCourse.slice";
import { modalConst } from "redux/local/local.const";

import { pagesInfo } from "utils/pagesInfo";
import { cn } from "utils/cn.utils";

const MAX_LENGTH = 128;

const CreateLmsCourseModal = memo(({
    openModal = false,
    onCloseModal,
    maxWidth = "max-w-2xl",
    bgColor = "bg-white",
}) => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { addLmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const [titleError, setTitleError] = useState("")
    const [isShowSubHeading, setIsShowSubHeading] = useState(false)
    const [isShowInput, setIsShowInput] = useState(false)
    const [topic, setTopic] = useState('')

    const { aiText, generateAiText } = useAiText()

    const requestData = {
        purpose: "title", // description or title 
        total_chars: 85,
        topic: topic,
        response_type: "text" // text or html 
    };

    const getAiText = () => {
        if (topic === "") {
            toast.error("Please write ai prompt");
            return
        }
        setIsShowInput(false)
        generateAiText(requestData);
    };

    const handlePrompt = (e) => {
        const value = e.target.value
        setTopic(value)
    }

    useEffect(() => {
        if (aiText?.data?.ai_response) {
            dispatcher(setAddLmsCourseDetailPayload({
                ...addLmsCourseDetail?.payload,
                title: aiText.data.ai_response,
            }))
        }
    }, [aiText?.data]);

    useEffect(() => {

        return () => {
            dispatcher(resetAddLmsCourseDetail())
            dispatcher(resetAddLmsCourseDetailPayload())
        }
    }, [])

    useEffect(() => {
        if (addLmsCourseDetail?.data) {
            const courseId = addLmsCourseDetail?.data?.id
            dispatcher(resetAddLmsCourseDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.CREATE_LMS_COURSE_MODAL.stateKey]: false
            }))
            window.open(`${window.location.host}${pagesInfo.EDIT_LMS_COURSE.pagePath}/${courseId}/edit`, "_self")
        }
    }, [addLmsCourseDetail?.data])

    const closeModal = () => {
        dispatcher(resetAddLmsCourseDetail())
        dispatcher(resetAddLmsCourseDetailPayload())
        setTitleError("")
        navigate(location.pathname)
        onCloseModal(false)
    }

    const onHandleChangeInput = useCallback((event) => {
        if (addLmsCourseDetail?.isLoading) return;

        dispatcher(setAddLmsCourseDetailPayload({
            ...addLmsCourseDetail?.payload,
            [event?.target?.name]: event?.target?.value
        }))
    }, [addLmsCourseDetail?.isLoading, addLmsCourseDetail?.payload])

    const onHandleCreateCourse = useCallback(() => {
        if (addLmsCourseDetail.isLoading) return;

        if (!addLmsCourseDetail?.payload?.title || (addLmsCourseDetail?.payload?.title?.length === 0)) {
            setTitleError("Please enter title!")
            return;
        }

        const body = {
            owner_user_id: user.user.userId,
            title: addLmsCourseDetail?.payload?.title
        }
        if (addLmsCourseDetail?.payload?.sub_title?.length > 0) {
            body["sub_title"] = addLmsCourseDetail?.payload?.sub_title
        }
        dispatcher(createLmsCourseDetail(body))
        setTitleError("")
    }, [addLmsCourseDetail?.isLoading, addLmsCourseDetail?.payload, titleError])

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed max-h-screen inset-0 z-50 overflow-y-auto"
                open={openModal}
                onClose={closeModal}
            >
                <div className="p-5 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden="true" className="inline-block h-screen align-middle">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={cn(
                            "inline-block w-full shadow-xl rounded-lg space-y-5 overflow-hidden",
                            `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                        )}>
                            <Dialog.Description as="div">
                                <div className={cn(
                                    "w-full h-full overflow-hidden overflow-y-scroll",
                                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                )}>
                                    <div className={"flex w-full justify-between items-center bg-back-ground-light h-16 px-5 drop-shadow-sm"}>
                                        <p className="font-bodyPri font-medium text-text-900 text-lg">
                                            {"Create a course"}
                                        </p>
                                        <AiOutlineClose
                                            onClick={closeModal}
                                            className={'cursor-pointer'}
                                            size={20}
                                        />
                                    </div>
                                    <div className={"w-full flex flex-col gap-5 bg-white p-10"}>
                                        <div className={"w-full flex flex-col gap-3"}>
                                            <div className="relative flex justify-between items-center">
                                                <AiTextButton
                                                    isShowInput={isShowInput}
                                                    setIsShowInput={setIsShowInput}
                                                    handlePrompt={handlePrompt}
                                                    getAiText={getAiText}
                                                    aiText={aiText}
                                                />
                                                <div className={"w-full flex justify-end"}>
                                                    {(addLmsCourseDetail?.payload?.title?.length > 0) &&
                                                        <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                                            {`${addLmsCourseDetail?.payload?.title?.length}/${MAX_LENGTH}`}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <input
                                                className={cn(
                                                    "px-3 h-12 flex w-full focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main",
                                                    "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                                                )}
                                                type={"text"}
                                                placeholder={'Add Course Title'}
                                                name={"title"}
                                                maxLength={MAX_LENGTH}
                                                value={addLmsCourseDetail?.payload?.title}
                                                onChange={onHandleChangeInput}
                                            />
                                        </div>
                                        <AnimatePresence initial={isShowSubHeading}>
                                            {!isShowSubHeading &&
                                                <div
                                                    className={"w-full flex items-center justify-between gap-3 cursor-pointer pt-1 group"}
                                                    onClick={() => setIsShowSubHeading(true)}
                                                >
                                                    <span className={"font-bodyPri font-normal text-primary-dark text-sm tracking-wide line-clamp-2"}>
                                                        {addLmsCourseDetail?.payload?.sub_title
                                                            ? addLmsCourseDetail?.payload?.sub_title
                                                            : "Sub Heading (optional)"
                                                        }
                                                    </span>
                                                    <MdEdit className={"text-xl text-text-900 hidden group-hover:flex"} />
                                                </div>
                                            }
                                            {isShowSubHeading &&
                                                <motion.div
                                                    initial={{ opacity: 0, height: 0 }}
                                                    animate={{ opacity: 1, height: 'auto' }}
                                                    exit={{ opacity: 0, height: 0 }}
                                                    transition={{ duration: 0.3 }}
                                                    className={"w-full"}
                                                >
                                                    <textarea
                                                        type={'text'}
                                                        className={cn(
                                                            'w-full border outline-none rounded-lg p-2 focus:border-primary-main opacity-100 transition-opacity duration-300',
                                                            'font-bodyPri font-normal text-text-800 text-sm tracking-wide placeholder:text-text-500'
                                                        )}
                                                        placeholder={"Sub Header (optional)"}
                                                        name={"sub_title"}
                                                        value={addLmsCourseDetail?.payload?.sub_title}
                                                        rows={3}
                                                        maxLength={180}
                                                        onChange={onHandleChangeInput}
                                                        autoFocus={true}
                                                        onBlur={() => setIsShowSubHeading(false)}
                                                    />
                                                </motion.div>
                                            }
                                        </AnimatePresence>
                                        <div className={"w-full flex items-center justify-start"}>
                                            {(addLmsCourseDetail?.message) &&
                                                <span className={"w-full font-bodyPri font-normal text-red-500 text-base tracking-wide text-center"}>
                                                    {addLmsCourseDetail?.message}
                                                </span>
                                            }
                                        </div>
                                        <motion.button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            className={cn(
                                                'self-end font-bodyPri font-normal py-2 w-36 bg-primary-dark text-white rounded-full hover:bg-secondary-dark ease-in-out duration-100',
                                                addLmsCourseDetail?.isLoading && "bg-primary-dark"
                                            )}
                                            onClick={onHandleCreateCourse}
                                        >
                                            {addLmsCourseDetail?.isLoading &&
                                                <ButtonLoader isLoading={addLmsCourseDetail?.isLoading} />
                                            }
                                            {!addLmsCourseDetail?.isLoading && "Create"}
                                        </motion.button>
                                    </div>
                                </div>
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
});

export default CreateLmsCourseModal;