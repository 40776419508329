import './styles/global.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import InitRouter from 'routers/Router';
// import RootRouter from './app/router';


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<InitRouter />} />
        {/* <Route path='/*' element={<RootRouter />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;