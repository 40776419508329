import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import { FaSpinner } from "react-icons/fa";

import { cancelSessionBookingDetail } from "redux/booking/booking.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { resetCancelBookingSessionDetail, setCancelBookingSessionDetailPayload } from "redux/booking/booking.slice";
import { modalConst } from "redux/local/local.const";
import { commentTemplateList } from "./commentTemplateList";

const MIN_CHARACTER_LENGTH = 20
const MAX_CHARACTER_LENGTH = 50

const CancelBookingSessionModal = () => {
    const { modal } = useAppState((s) => s.local)
    const { cancelBookingSessionDetail } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()

    const [inputMessage, setInputMessage] = useState("")

    const isSubmitEnable = !["", "Another reason."].includes(cancelBookingSessionDetail?.payload?.comment) || (cancelBookingSessionDetail?.payload?.comment === "Another reason." && inputMessage.length > 20 && inputMessage.length < 50)

    useEffect(() => {
        if (cancelBookingSessionDetail?.data?.result) {
            dispatcher(resetCancelBookingSessionDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.CANCEL_BOOKING_SESSION_MODAL.stateKey]: false
            }))
        }
    }, [cancelBookingSessionDetail?.data?.result])

    const onHandleSubmitComment = () => {

        if (cancelBookingSessionDetail?.isLoading || !isSubmitEnable) return;

        if (!window.confirm("This action will cancel your booking. Are you sure?")) return;

        dispatcher(cancelSessionBookingDetail(
            cancelBookingSessionDetail?.payload?.bookingId,
            {
                comment: cancelBookingSessionDetail?.payload?.comment === "Another reason." ? inputMessage : cancelBookingSessionDetail?.payload?.comment
            }
        ))
    }

    return (
        <div className={"space-y-5"}>
            <div className={'min-h-[24rem] block space-y-5 pt-5'}>
                <div className={"w-full space-y-3"}>
                    <div className={"mx-3 flex flex-col items-start justify-start gap-5"}>
                        {commentTemplateList?.map((commentTemp, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    dispatcher(setCancelBookingSessionDetailPayload({
                                        ...cancelBookingSessionDetail?.payload,
                                        comment: commentTemp.message
                                    }))
                                }}
                                className={cn(
                                    "px-3 py-1 rounded-lg border-2 border-primary-dark text-primary-dark cursor-pointer",
                                    "hover:bg-primary-dark hover:text-text-50",
                                    (cancelBookingSessionDetail?.payload?.comment === commentTemp.message) && "!bg-primary-dark !text-text-50"
                                )}
                            >
                                <span className={"font-bodyPri font-medium text-base"}>
                                    {commentTemp.message}
                                </span>
                            </div>
                        ))}
                    </div>
                    {cancelBookingSessionDetail?.payload?.comment === "Another reason." &&
                        <div className={"flex flex-col items-start gap-3 px-3"}>
                            <textarea
                                className={cn(
                                    "w-full px-5 py-3 bg-back-ground-light rounded-lg resize-none",
                                    "outline-none border-2 border-primary-dark focus:shadow-all-md focus:shadow-primary-light focus:border-primary-dark",
                                    "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                                )}
                                minLength={MIN_CHARACTER_LENGTH}
                                maxLength={MAX_CHARACTER_LENGTH}
                                rows={2}
                                placeholder="Write here ...."
                                name={"message"}
                                value={inputMessage}
                                onChange={(event) => {
                                    setInputMessage(event.target.value);
                                }}
                            />
                            <div className={"w-full flex justify-between"}>
                                <span className={cn(
                                    "font-bodyPri font-normal text-xs text-text-800",
                                    (inputMessage.length < MIN_CHARACTER_LENGTH || inputMessage.length > MAX_CHARACTER_LENGTH) && "text-red-500"
                                )}>
                                    {`Please enter between ${MIN_CHARACTER_LENGTH} and ${MAX_CHARACTER_LENGTH} characters`}
                                </span>
                                <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                                    {inputMessage.length + "/" + MAX_CHARACTER_LENGTH}
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className={"flex justify-between"}>
                <div className={"flex items-center justify-center gap-2"}>
                    {cancelBookingSessionDetail?.isLoading &&
                        <>
                            <span className={"font-bodyPri font-normal text-base text-text-700"}>
                                {"Loading"}
                            </span>
                            <FaSpinner className="text-primary-main animate-spin" />
                        </>
                    }
                    {cancelBookingSessionDetail?.message &&
                        <span className={"font-bodyPri font-normal text-base text-red-500"}>
                            {cancelBookingSessionDetail?.message}
                        </span>
                    }
                </div>
                <span
                    onClick={onHandleSubmitComment}
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                        !isSubmitEnable && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                    )}>
                    {"Submit"}
                </span>
            </div>
        </div>
    )
}

export default CancelBookingSessionModal;