import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { FaInfoCircle } from 'react-icons/fa';
import { Star, StarBorder } from '@mui/icons-material';

import ButtonLoader from 'components/loader/ButtonLoader';
import PageLoader from 'components/loader/PageLoader';

import { getUserDetailByUserId } from 'redux/user/user.request';
import { createUserReviewDetail } from 'redux/review/review.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setClearAddUserReviewDetail } from 'redux/review/review.slice';
import { setClearUserDetail } from 'redux/user/user.slice';
import { modalConst } from 'redux/local/local.const';
import { reviewVisibility } from "redux/review/review.const";

import { pagesInfo } from 'utils/pagesInfo';

const MIN_CHARACTER_LENGTH = 20
const MAX_CHARACTER_LENGTH = 500

const AddReviewModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { session, logIn, signUp } = useAppState((state) => state.auth)
    const { user, userDetail } = useAppState((state) => state.user)
    const { addUserReviewDetail } = useAppState((state) => state.review)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [characterLength, setCharacterLength] = useState(0)
    // const [starIndexes, setStarIndexes] = useState([])

    const [currentValue, setCurrentValue] = useState(0)
    const [hoverValue, setHoverValue] = useState(undefined)
    const [review, setReview] = useState("")

    const stars = new Array(5).fill(0)

    useEffect(() => {
        if (addUserReviewDetail?.addUserReviewDetail) {
            navigate(`${pagesInfo?.TUTOR?.pagePath}/${addUserReviewDetail?.addUserReviewDetailPayload?.userId}`)
            dispatcher(setModal({
                ...modal,
                [modalConst.ADD_REVIEW_MODAL.stateKey]: false
            }))
            dispatcher(setClearAddUserReviewDetail())
        }
    }, [addUserReviewDetail?.addUserReviewDetail])

    useEffect(() => {
        if (addUserReviewDetail?.addUserReviewDetailPayload?.userId) {
            dispatcher(getUserDetailByUserId(addUserReviewDetail?.addUserReviewDetailPayload?.userId))
        }

        return () => {
            dispatcher(setClearUserDetail())
        }
    }, [addUserReviewDetail?.addUserReviewDetailPayload, session?.isLoggedIn])

    const handleOnChange = (event) => {
        const myText = event.target.value
        setReview(myText)
        setCharacterLength(myText?.length)
    }

    const onHandleClick = (value) => {
        if (currentValue === 1 && value === 1) {
            setCurrentValue(0)
            return;
        }
        setCurrentValue(value)
    }

    const onHandleCancel = () => {
        setCurrentValue(0)
        setReview('')
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_REVIEW_MODAL.stateKey]: false
        }))
        dispatcher(setClearAddUserReviewDetail())
    }

    const onSubmit = () => {
        if (currentValue < 1 || ((currentValue === 1 || currentValue === 2) && characterLength < 20)) {
            toast.warn("Please add your review!")
            return;
        }
        const body = {
            receiverUserId: addUserReviewDetail?.addUserReviewDetailPayload?.receiverUserId,
            receiverRole: addUserReviewDetail?.addUserReviewDetailPayload?.receiverRole,
            providerUserId: user?.user?.userId,
            rating: currentValue,
            visibility: reviewVisibility?.PUBLIC?.value
        }
        if (review) {
            body["review"] = review
        }
        dispatcher(createUserReviewDetail(body))
    }

    if (logIn?.isLoading || signUp?.isLoading || userDetail?.isLoading) {
        return (
            <PageLoader className={"w-xl h-[28rem]"} />
        )
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[28rem] block pt-5 space-y-8"}>
                <div className={"space-y-3"}>
                    <div className={"w-full flex items-center justify-center font-bodyPri font-medium text-text-900 text-lg tracking-wide"}>
                        {userDetail?.userDetail?.firstName + " " + userDetail?.userDetail?.lastName?.charAt(0) + "."}
                    </div>
                    <div className="h-0.5 bg-gray-300 w-full"></div>
                </div>

                <div className={"flex items-center justify-start gap-3"}>
                    <div className={"w-24 h-24 rounded-full overflow-hidden"}>
                        <img
                            src={user?.user?.profilePicUrl}
                            className={"w-full h--full object-cover"}
                            alt={"profileImg"}
                        />
                    </div>
                    <div className={"flex flex-col items-start justify-start gap-2"}>
                        <span className={"font-bodyPri font-medium text-text-900 text-lg tracking-wide"}>
                            {user?.user?.firstName + " " + user?.user?.lastName?.charAt(0) + "."}
                        </span>
                        <div className={"flex items-center justify-start"}>
                            <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                                {"Posting Publicly"}
                            </span>
                            <div className={"relative has-tooltip cursor-pointer flex items-start justify-start -mt-1"}>
                                <span
                                    className={cn(
                                        "w-fit md:w-56 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 tooltip absolute -top-9",
                                        "flex flex-col items-start justify-start overflow-hidden"
                                    )}
                                >
                                    {"Your post will appear publicly with your profile name and picture."}
                                </span>
                                <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"w-full flex items-center justify-center gap-3"}>
                    {/* {stars?.map((_, index) => {
                        return (
                            <FaStar
                                key={index}
                                className={cn(
                                    "text-6xl cursor-pointer",
                                    (currentValue || hoverValue) > index ? "text-orange-400" : "text-text-300"
                                )}
                                onClick={() => onHandleClick(index + 1)}
                                onMouseOver={() => handleMouseHover(index + 1)}
                                onMouseLeave={handleMouseLeave}
                            />
                        )
                    })} */}
                    {stars?.map((_, index) => (currentValue || hoverValue) > index
                        ? <Star
                            key={index}
                            style={{ fontSize: '60px' }}
                            className={"text-6xl text-orange-400 cursor-pointer"}
                            onClick={() => onHandleClick(index + 1)}
                            onDoubleClick={() => setCurrentValue(0)}
                        />
                        : <StarBorder
                            key={index}
                            style={{ fontSize: '60px' }}
                            className={"text-6xl text-divider-lightDark cursor-pointer"}
                            onClick={() => onHandleClick(index + 1)}
                        />
                    )}
                </div>
                {/* {starIndexes?.length <= 2 && */}
                <div className={"w-full"}>
                    <textarea
                        className={cn(
                            "w-full px-5 py-3 bg-back-ground-light rounded-lg resize-none",
                            "outline-none border-2 border-text-500 focus:border-text-700 shadow-md",
                            "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                        )}
                        type={"text"}
                        placeholder="Please leave a review and help us grow. We value your opinion."
                        value={review}
                        onChange={(event) => handleOnChange(event)}
                        rows={5}
                        minLength={MIN_CHARACTER_LENGTH}
                        maxLength={MAX_CHARACTER_LENGTH}
                        autoFocus={true}
                        required={true}
                    />
                    <div className={cn("flex justify-end items-end")}>
                        {(characterLength < MIN_CHARACTER_LENGTH || characterLength === MAX_CHARACTER_LENGTH) &&
                            <span className={cn(
                                "font-bodyPri font-normal text-sm text-text-800",
                                currentValue <= 2 && (characterLength < 20 || characterLength > 500) && "text-red-500"
                            )}
                            >
                                {`${characterLength} of 500 /(20 to 500 characters)`}
                            </span>
                        }
                    </div>
                </div>
                {/* } */}

                {addUserReviewDetail?.errorMsg &&
                    <div className={"w-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                        {addUserReviewDetail?.errorMsg}
                    </div>
                }
            </div>
            <div className={"flex justify-end gap-5 px-5"}>
                <div
                    className={cn(
                        "w-28 px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-text-800",
                        "border border-text-400 hover:bg-text-400 hover:text-text-50",
                    )}
                    onClick={onHandleCancel}
                >
                    {"Cancel"}
                </div>
                <button
                    type={"submit"}
                    className={cn(
                        "w-28 px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        (currentValue > 2 || ((currentValue === 1 || currentValue === 2) && characterLength > 20)) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                        (currentValue < 1 || ((currentValue === 1 || currentValue === 2) && characterLength < 20)) && "border border-text-300 bg-text-300 text-text-50 hover:bg-text-300 cursor-not-allowed",
                        addUserReviewDetail?.isLoading && "bg-secondary-dark"
                    )}
                    onClick={onSubmit}
                >
                    {addUserReviewDetail?.isLoading && <ButtonLoader isLoading={addUserReviewDetail?.isLoading} />}
                    {!addUserReviewDetail?.isLoading && "Submit"}
                </button>
            </div>
        </div>
    )
}

export default AddReviewModal;