import { memo, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IoIosCheckmark, IoIosRadioButtonOff, IoIosRadioButtonOn } from 'react-icons/io'

import FloatingLabelSelect from 'components/floating/floatingLabelSelect'
import ButtonLoader from 'components/loader/button-loader'

import bookingService from 'redux/booking/booking.service'

import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { setLocals } from 'redux/local/local.slice'

import { userRoles } from 'redux/auth/auth.const'
import { localsConst } from 'redux/local/local.const'
import { locationPhoneCallTypeEnum, locationTypeEnum } from 'redux/appointment/appointment.const'
import { bookingOfferingTypeEnum, bookingStatusEnum } from 'redux/booking/booking.const'

import { cn } from 'utils/cn.utils'
import { pagesInfo } from 'utils/pagesInfo'
import { Controller } from 'react-hook-form'
import ToolTipView from 'components/tooltipView'

const AppointmentLocation = memo(({
    appointment,
    values,
    form
}) => {

    const { locals } = useAppState(s => s.local)
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)
    const { user } = useAppState(s => s.user)
    const { session } = useAppState(s => s.auth)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [isSubmitting, setIsSubmitting] = useState(false)

    const {
        register,
        unregister,
        control,
        formState: { errors, isLoading },
        reset,
        setError,
        setValue,
        trigger,
    } = form

    const isMyOffering = useMemo(() => user?.user?.userId === appointment?.user?.id, [user?.user, appointment])

    const createBookingDetail = async (requestData) => {
        setIsSubmitting(true)
        try {
            const response = await bookingService.createBookingDetail(requestData)
            if (response.status === 201) {
                let bookingId = response?.data?.data?.result?.id
                const bookingStatus = response?.data?.data?.result?.status
                reset({ offeringType: bookingOfferingTypeEnum.APPOINTMENT.value })
                if ([bookingStatusEnum.CONFIRMED.value]?.includes(bookingStatus)) {
                    navigate(`${pagesInfo.USER_FINANCE.pagePath}${pagesInfo.BOOKINGS.pagePath}?actionBookingId=${bookingId}`)
                } else {
                    navigate(`${pagesInfo?.CHECKOUT_BOOKING?.pagePath}/${bookingId}`)
                }
            } else {
                new Error(response)
            }
        } catch (error) {
            setError("root", { message: error?.response?.data?.error || "Something went wrong!" })
        } finally {
            setIsSubmitting(false)
        }
    }

    const handleCheckout = async () => {
        if (!session?.isLoggedIn || !user?.user) {
            window.open(`${window.location.host}/log-in?action_window=login_popup`, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=600,width=600,height=600")
            return;
        }
        const is_valid = await trigger()
        if (!is_valid) {
            setError("root", { message: "Invalid, Please Complete booking details!" })
            return
        }

        if (session?.isLoggedIn && (user?.user && locals.userRole === userRoles?.TUTOR?.value)) {
            if (!window.confirm("To proceed, please switch to the learner role")) return;
            dispatcher(setLocals({
                ...locals,
                [localsConst.USER_ROLE.value]: userRoles?.STUDENT?.value
            }))
        }

        await createBookingDetail({ body: values })
    }

    const onHandleSetLocation = useCallback((selectedLocation) => {
        if (selectedLocation?.id === values?.appointment?.appointment_location?.appointmentLocationId) return

        setValue("appointment.appointment_location.appointmentLocationId", selectedLocation?.id)
        if (selectedLocation?.location_type === locationTypeEnum.phone_call.key && selectedLocation?.phone_call?.location_phone_call_type === locationPhoneCallTypeEnum.i_will_call.key) {
            unregister("appointment.appointment_location.invitee.custom_text")
            unregister("appointment.appointment_location.invitee")
            let myCountry = countryList?.countryList?.find(countryItem => countryItem?.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId)
            setValue("appointment.appointment_location.phone_call", { location_phone_call_type: locationPhoneCallTypeEnum.i_will_call.key, phone_number: `${myCountry?.isdCode}-` })
        } else if (selectedLocation?.location_type === locationTypeEnum.invitee.key) {
            unregister("appointment.appointment_location.phone_call")
            setValue("appointment.appointment_location.invitee", { custom_text: "" })
        } else {
            unregister("appointment.appointment_location.phone_call")
            unregister("appointment.appointment_location.invitee")
        }
    }, [countryList?.countryList, setValue, timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId, unregister, values?.appointment?.appointment_location?.appointmentLocationId])

    const HandleCheckout = () => (
        <div className={"space-y-5"}>
            <div className={"w-full h-10"}>
                {(errors?.root) &&
                    <div className={"flex items-center justify-start"}>
                        <span className={"font-bodyPri font-medium text-base text-red-500 text-center"}>
                            {errors?.root?.message}
                        </span>
                    </div>
                }
            </div>
            <div className={"flex justify-end"}>
                <ToolTipView
                    disabled={!isMyOffering}
                    content={"Self-booking isn't allowed."}
                >
                    <span>
                        <button
                            type="button"
                            onClick={handleCheckout}
                            className={cn(
                                "w-40 py-3 flex justify-center items-center rounded-full cursor-pointer",
                                "font-buttons font-normal text-lg text-text-50 bg-secondary-main hover:bg-secondary-dark",
                                (isMyOffering) && "cursor-not-allowed bg-secondary-main/50 hover:bg-secondary-dark/50"
                            )}
                            disabled={isMyOffering || isSubmitting || isLoading}
                        >
                            {(isSubmitting || isLoading)
                                ? <ButtonLoader />
                                : <>{"Checkout"}</>
                            }
                        </button>
                    </span>
                </ToolTipView>
            </div>
        </div>
    )

    return (
        <div className={"pr-8 h-full flex flex-col justify-between gap-5"}>
            <div className={"space-y-5"}>
                <div className={""}>
                    <h3 className={"font-bodyPri font-semibold text-xl text-text-800 after:content-['*'] after:text-secondary-dark"}>
                        {"Select a Location"}
                    </h3>
                </div>
                <div className={"space-y-4"}>
                    {appointment?.appointment_locations?.map((location, index) => (
                        <div key={index} className={"flex items-start justify-start gap-2"}>
                            <div onClick={() => onHandleSetLocation(location)} className={"cursor-pointer"}>
                                {location?.id === values?.appointment?.appointment_location?.appointmentLocationId ? (
                                    <IoIosRadioButtonOn className="text-2xl text-primary-dark" />
                                ) : (
                                    <IoIosRadioButtonOff className="text-2xl text-text-700" />
                                )}
                            </div>
                            {location?.location_type === locationTypeEnum.custom.key && (
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.custom.icon className={"text-2xl text-text-700"} />
                                    <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                        {location?.custom?.custom_text}
                                    </p>
                                </div>
                            )}
                            {location?.location_type === locationTypeEnum.in_person_meeting.key && (
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.in_person_meeting.icon className={"text-2xl text-text-700"} />
                                    <p className={"flex flex-wrap gap-1 font-bodyPri font-medium text-md text-text-800 "}>
                                        <span>{location?.in_person_meeting?.street_line_1}</span>
                                        {location?.in_person_meeting?.street_line_2 && (<>|<span>{location?.in_person_meeting?.street_line_2}</span></>)}
                                        {location?.in_person_meeting?.city && (<>|<span>{location?.in_person_meeting?.city}</span></>)}
                                        {location?.in_person_meeting?.post_office && (<>|<span>{location?.in_person_meeting?.post_office}</span></>)}
                                        {location?.in_person_meeting?.state && (<>|<span>{location?.in_person_meeting?.state}</span></>)}
                                        {location?.in_person_meeting?.country && (<>|<span>{location?.in_person_meeting?.country}</span></>)}
                                        {location?.in_person_meeting?.zip && (<>|<span>{location?.in_person_meeting?.zip}</span></>)}
                                        {location?.in_person_meeting?.label && (<>|<span>{location?.in_person_meeting?.label}</span></>)}
                                    </p>
                                </div>
                            )}
                            {location?.location_type === locationTypeEnum.phone_call.key && (
                                <div className={"w-full space-y-0.5"}>
                                    <div className={"w-full flex items-start justify-start gap-2"}>
                                        <locationTypeEnum.phone_call.icon className={"text-2xl text-text-700"} />
                                        <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                            {locationTypeEnum.phone_call.label}
                                        </p>
                                    </div>
                                    {location?.id === values?.appointment?.appointment_location?.appointmentLocationId && location?.phone_call?.location_phone_call_type === locationPhoneCallTypeEnum.call_me.key && (
                                        <p className={"font-bodyPri font-normal text-xs text-text-800"}>
                                            {"You will call the Host. Phone number will be provided upon booking completion."}
                                        </p>
                                    )}
                                    {location?.id === values?.appointment?.appointment_location?.appointmentLocationId && location?.phone_call?.location_phone_call_type === locationPhoneCallTypeEnum.i_will_call.key && (
                                        <Controller
                                            name={"appointment.appointment_location.phone_call.phone_number"}
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                                <div className={"space-y-1"}>
                                                    <div className={"flex items-center justify-start gap-2 h-10"}>
                                                        <FloatingLabelSelect
                                                            labelItem={`phone_country`}
                                                            searchable={true}
                                                            options={countryList?.countryList?.map((country) => ({
                                                                label: `${country?.country} (+${country?.isdCode})`,
                                                                value: country?.isdCode,
                                                                country: country
                                                            })) || []}
                                                            onHandleSelect={(selectedOption) => {
                                                                onChange(
                                                                    `${selectedOption?.value}-${value?.split("-")[1]}`
                                                                )
                                                            }}
                                                            value={
                                                                <div className={"flex items-center gap-1"}>
                                                                    <img
                                                                        src={`https://flagcdn.com/16x12/${countryList?.countryList?.find(countryItem => countryItem?.isdCode === value?.split("-")[0])?.countryDomain}.png`.toLowerCase()}
                                                                        className={"w-8 h-5 object-cover"}
                                                                        alt={"country-flag"}
                                                                    />
                                                                    <span className={"text-sm"}>{` (+${value?.split("-")[0]})`}</span>
                                                                </div>
                                                            }
                                                            OptionChild={({ option }) => (
                                                                <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                                                                    <div className="flex items-center gap-3">
                                                                        <img
                                                                            src={`https://flagcdn.com/16x12/${option?.country?.countryDomain}.png`.toLowerCase()}
                                                                            className={"w-6 h-4 object-cover"}
                                                                            alt={"country-flag"}
                                                                        />
                                                                        {option?.label}
                                                                    </div>
                                                                    {(option?.value === value?.split("-")[0])
                                                                        && <IoIosCheckmark size={20} color="green" />
                                                                    }
                                                                </div>
                                                            )}
                                                            label={"Country"}
                                                            dropdownWrapperClassName={"w-full sm:w-[25.4rem]"}
                                                            dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                                                        />
                                                        <div
                                                            className={cn(
                                                                "relative group min-w-[3rem] w-fit h-full p-2 flex gap-3 items-center border rounded focus-within:shadow",
                                                                !!error
                                                                    ? 'border-secondary-dark focus-within:border-secondary-main shadow shadow-secondary-main/20 focus-within:shadow-secondary-main/20'
                                                                    : !!value
                                                                        ? 'border-primary-main focus-within:border-primary-main shadow shadow-primary-main/20 focus-within:shadow-primary-main/20'
                                                                        : 'border-text-400 focus-within:border-primary-main focus-within:shadow-primary-main/20'
                                                            )}
                                                        >
                                                            <input
                                                                type={"tel"}
                                                                className={('w-full h-full bg-transparent outline-none text-base')}
                                                                placeholder={"Your Phone number..."}
                                                                value={value?.split("-")[1]}
                                                                onChange={(e) => {
                                                                    onChange(
                                                                        `${value?.split("-")[0]}-${e.target.value}`
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {!!error && (
                                                        <div className={"flex items-center justify-start"}>
                                                            <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                                                {error?.message}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    )}
                                </div>
                            )}
                            {location?.location_type === locationTypeEnum.invitee.key && (
                                <div className={"w-full space-y-0.5"}>
                                    <div className={"w-full flex items-start justify-start gap-2"}>
                                        <locationTypeEnum.invitee.icon className={"text-2xl text-text-700"} />
                                        <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                            {"Somewhere else"}
                                        </p>
                                    </div>
                                    {location?.id === values?.appointment?.appointment_location?.appointmentLocationId && (
                                        <div className={"space-y-0.5"}>
                                            <div className={"flex items-center justify-end"}>
                                                <span className={"font-bodyPri font-normal text-xs text-text-600 text-right"}>
                                                    {`${values?.appointment?.appointment_location?.invitee?.custom_text?.length} / {10, 500} characters`}
                                                </span>
                                            </div>
                                            <div
                                                className={cn(
                                                    "relative group w-full h-full min-h-20 p-2 flex gap-3 items-center border rounded focus-within:shadow",
                                                    !!errors?.appointment?.appointment_location?.invitee?.custom_text
                                                        ? 'border-secondary-dark focus-within:border-secondary-main shadow shadow-secondary-main/20 focus-within:shadow-secondary-main/20'
                                                        : !!values?.appointment?.appointment_location?.invitee?.custom_text
                                                            ? 'border-primary-main focus-within:border-primary-main shadow shadow-primary-main/20 focus-within:shadow-primary-main/20'
                                                            : 'border-text-400 focus-within:border-primary-main focus-within:shadow-primary-main/20'
                                                )}
                                            >
                                                <textarea
                                                    className={('w-full h-full bg-transparent outline-none text-base')}
                                                    placeholder={"Write your place..."}
                                                    {...register("appointment.appointment_location.invitee.custom_text")}
                                                />
                                            </div>
                                            {!!errors?.appointment?.appointment_location?.invitee?.custom_text && (
                                                <div className={"flex items-center justify-start"}>
                                                    <span className={"font-bodyPri font-normal text-xs text-secondary-main"}>
                                                        {errors?.appointment?.appointment_location?.invitee?.custom_text?.message}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            {location?.location_type === locationTypeEnum.edulyte_meet.key && (
                                <div className={"w-full space-y-0.5"}>
                                    <div className={"w-full flex items-start justify-start gap-2"}>
                                        <locationTypeEnum.edulyte_meet.icon className={"text-2xl text-text-700"} />
                                        <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                            {locationTypeEnum.edulyte_meet?.label}
                                        </p>
                                    </div>
                                    {location?.id === values?.appointment?.appointment_location?.appointmentLocationId && (
                                        <p className={"font-bodyPri font-normal text-xs text-text-800"}>
                                            {"Web conferencing details provided upon confirmation."}
                                        </p>
                                    )}
                                </div>
                            )}
                            {location?.location_type === locationTypeEnum.google_meet.key && (
                                <div className={"w-full space-y-0.5"}>
                                    <div className={"w-full flex items-start justify-start gap-2"}>
                                        <locationTypeEnum.google_meet.icon className={"text-2xl text-text-700"} />
                                        <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                            {locationTypeEnum.google_meet?.label}
                                        </p>
                                    </div>
                                    {location?.id === values?.appointment?.appointment_location?.appointmentLocationId && (
                                        <p className={"font-bodyPri font-normal text-xs text-text-800"}>
                                            {"Web conferencing details provided upon confirmation."}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                    {(!!errors?.appointment?.appointment_location || !!errors?.appointment?.appointment_location?.appointmentLocationId) && (
                        <div className={"flex justify-start items-center"}>
                            <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                {errors?.appointment?.appointment_location?.message || errors?.appointment?.appointment_location?.appointmentLocationId?.message}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <HandleCheckout />
        </div >
    )
})

export default AppointmentLocation