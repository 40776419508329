import { useCallback, useEffect, useState } from 'react';
import { useNavigate, Link, useParams, useMatch } from 'react-router-dom';

import { IoMdArrowRoundBack } from "react-icons/io";
import { MdPlayLesson } from 'react-icons/md'

import ToolTipView from 'components/tooltipView';

import RefreshButton from 'pages/auth/edulyteLms/commonComponents/lmsButtons/RefreshButton';
import HelpBtn from 'pages/auth/edulyteLms/commonComponents/lmsButtons/help/helpBtn';
import PublishBtn from 'pages/auth/edulyteLms/commonComponents/lmsButtons/PublishBtn';
import TopTabNavigation from 'pages/auth/edulyteLms/commonComponents/navigations/TopTabNavigation';

import { lmsQuizTabConst, quizSettingsNavigationOptions } from '../../editLMSQuiz.data';

import { getLmsQuizDetail, updateLmsQuizDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetLocalTabs } from 'redux/local/local.slice';
import { resetAddLmsQuizSection, resetAddLmsQuizSectionPayload } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { lmsQuizStatusEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const';

import { pagesInfo } from 'utils/pagesInfo';

const QuizHeader = ({ startTour }) => {
    const { lmsQuizDetail, updateLmsQuiz } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const { quizId } = useParams()
    const navigate = useNavigate()
    const match = useMatch("/lms/quizzes/:quizId/edit/builder")

    const [quizStatus, setQuizStatus] = useState(lmsQuizDetail?.data?.quiz_setting?.status)

    useEffect(() => {
        if (updateLmsQuiz?.data && [lmsQuizStatusEnum?.PUBLISHED?.value]?.includes(updateLmsQuiz?.data?.quiz_setting?.status) && (quizStatus !== lmsQuizStatusEnum?.PUBLISHED?.value)) {
            navigate(`${pagesInfo?.EDIT_LMS_QUIZ?.pagePath}/${quizId}/edit/${quizSettingsNavigationOptions?.SLUG?.to}`)
        }
    }, [updateLmsQuiz?.data])

    useEffect(() => {
        if (lmsQuizDetail?.data?.quiz_setting?.status) {
            setQuizStatus(lmsQuizDetail?.data?.quiz_setting?.status)
        }
    }, [lmsQuizDetail?.data?.quiz_setting?.status])

    const onHandleBack = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const onHandleRefreshQuiz = useCallback(() => {
        if (lmsQuizDetail?.isLoading) return;

        dispatcher(resetLocalTabs())
        dispatcher(resetAddLmsQuizSection())
        dispatcher(resetAddLmsQuizSectionPayload())
        dispatcher(getLmsQuizDetail(quizId))
    }, [lmsQuizDetail])

    const onHandleUpdateStatus = (quizStatusType) => {
        if (lmsQuizDetail?.isLoading || updateLmsQuiz?.isLoading) return;

        if (quizStatusType?.value === lmsQuizStatusEnum.DRAFT.value) {
            if (!window.confirm("Are you sure?. You want to save it as draft quiz.")) return;
        }
        if (quizStatusType?.value === lmsQuizStatusEnum.PUBLISHED.value) {
            if (!window.confirm("Are you sure?. You want to publish this quiz.")) return;
        }
        if (quizStatusType?.value === lmsQuizStatusEnum.INACTIVE.value) {
            if (!window.confirm("Are you sure?. You want to deactivate this quiz.")) return;
        }
        dispatcher(updateLmsQuizDetail(lmsQuizDetail?.data?.id, { status: quizStatusType?.value }))

    };

    return (
        <>
            <div className={"sticky top-0 z-50 h-16 bg-white flex items-center justify-between drop-shadow-sm px-5"}>
                <div className={"h-10 flex justify-center items-center gap-2"}>
                    <ToolTipView content={"Back"}>
                        <button className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={onHandleBack}>
                            <IoMdArrowRoundBack
                                className={'text-back-ground-dark'}
                                size={25}
                            />
                        </button>
                    </ToolTipView>
                    <Link to={pagesInfo.TUTOR_PRODUCTS.pagePath + "/quizzes"} className={"hidden sm:flex items-center justify-center gap-2  group"}>
                        <MdPlayLesson
                            size={30}
                            className={"text-primary-dark group-hover:text-secondary-dark"}
                        />
                        <p className={'font-bodyPri font-normal text-lg md:text-2xl text-text-900 group-hover:text-secondary-dark group-hover:underline'}>
                            {"Quiz"}
                        </p>
                    </Link>
                </div>
                <div className={"hidden md:flex items-center"}>
                    <TopTabNavigation tabs={Object.values(lmsQuizTabConst)} />
                </div>
                <div className={'flex items-center justify-start gap-5'}>
                    {Boolean(match) && (
                        <HelpBtn startTour={startTour} />
                    )}
                    <RefreshButton onHandleRefreshButton={onHandleRefreshQuiz} />
                    <PublishBtn
                        isLoading={updateLmsQuiz?.isLoading}
                        loaderBtnClassName={`bg-${lmsQuizStatusEnum[lmsQuizDetail?.data?.quiz_setting?.status?.toUpperCase()]?.darkColor}`}
                        selectedBtnClassName={`text-white bg-${lmsQuizStatusEnum[lmsQuizDetail?.data?.quiz_setting?.status?.toUpperCase()]?.darkColor}`}
                        dropdownBtnClassName={`text-white bg-${lmsQuizStatusEnum[lmsQuizDetail?.data?.quiz_setting?.status?.toUpperCase()]?.darkColor}`}
                        optionsList={Object.values(lmsQuizStatusEnum)?.filter((statusItem) => (statusItem?.value != lmsQuizDetail?.data?.quiz_setting?.status))}
                        selectedOption={lmsQuizStatusEnum[lmsQuizDetail?.data?.quiz_setting?.status?.toUpperCase()]?.label}
                        onHandleUpdateStatus={onHandleUpdateStatus}
                    />
                </div>
            </div>
            <div className={"flex md:hidden justify-center items-center w-full rounded-lg"}>
                <TopTabNavigation tabs={Object.values(lmsQuizTabConst)} />
            </div>
        </>
    )
}

export default QuizHeader