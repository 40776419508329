import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import PageLoader from "components/loader/PageLoader";
import Footer1 from 'components/footer/footer1/Footer1';

import { PageContentStyle } from './style';
import SignUpReferralImage from "pages/global/referralSignUp/images/SignUpReferral.png";

import { useAppState, useAppDispatcher } from "hooks/useStore"
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { setSignUpPayload, setClearVerifyReferral } from "redux/auth/auth.slice";
import { createVerifyReferral } from "redux/auth/auth.request";

import { pagesInfo } from 'utils/pagesInfo';
import { currencyRateConversion } from 'utils/generators.utils';
import { useTitle } from 'hooks/useTitle';

function ReferralSignUpForm() {
  const { locals } = useAppState((s) => s.local)
  const { session, signUp, verifyReferral } = useAppState((s) => s.auth)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const location = useLocation()
  const { referralCode } = useParams()
  const [title, setTitle] = useTitle()

  const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

  useEffect(() => {
    if (verifyReferral?.verifyReferral?.firstName) {
      setTitle({
        ...title,
        title: `${verifyReferral?.verifyReferral?.firstName}, Invited you to Join Edulyte`
      })
    }
  }, [verifyReferral?.verifyReferral])

  useEffect(() => {
    if (referralCode) {
      dispatcher(createVerifyReferral(referralCode))
    }

    return () => {
      dispatcher(setClearVerifyReferral())
    }
  }, [referralCode])

  const handleSignUp = () => {
    dispatcher(setSignUpPayload({
      ...signUp?.payload,
      referralCode: verifyReferral?.verifyReferral?.referralCode
    }))
    navigate(pagesInfo?.SIGN_UP?.pagePath)
  }

  if (verifyReferral?.isLoading) {
    return (
      <PageLoader />
    )
  }

  if (session?.isLoggedIn) {
    navigate(from, { replace: true });
  }

  return (
    <div className={"px-4 rounded-xl shadow-2xl bg-white w-96 py-7 border border-text-300"}>
      {verifyReferral?.verifyReferral && verifyReferral?.verifyReferral?.referralCode === referralCode ?
        <h1 className={"text-4xl font-semibold text-left text-gray-900"}>
          {verifyReferral?.verifyReferral?.firstName + " " + verifyReferral?.verifyReferral?.lastName?.charAt(0) + "."} <br />
          {"invited you to join Edulyte!"}
        </h1>
        : <h1 className="text-center text-4xl font-bodyPri text-text-900 font-semibold">
          {"Invalid Referral Code"}
        </h1>
      }
      {verifyReferral?.errorMsg &&
        <div className={"w-full h-full flex justify-center items-center py-1.5 font-bodyPri font-semibold text-text-800 text-md"}>
          {verifyReferral?.errorMsg}
        </div>
      }
      <p className={"w-full py-5 font-bodyPri font-normal text-sm text-text-700 text-center"}>
        {`When you sign up on this link and pay for your first lesson, you will get ${currencyRateConversion("USD", 500)} (approx ${currencyRateConversion(locals?.currencyCode, 500)}) credit in your wallet.`}
      </p>
      {/* <div className="pb-6 space-y-2 border-b border-gray-200">
        <button
          className={cn(
            "flex w-full justify-center items-center px-3 py-3 space-x-2 rounded-sm",
            "relative  bg-gray-200 rounded-md px-14 hover:bg-opacity-90",
            "font-semibold text-slate-700 whitespace-nowrap"
          )}
        >
          <span className="absolute left-4">
            <FcGoogle size={20} />
          </span>
          {" Sign Up With Google"}
        </button>
        <button
          className={cn(
            "flex w-full justify-center items-center px-3 py-3 space-x-2 rounded-sm",
            "relative  bg-gray-200 rounded-md px-14 hover:bg-opacity-90",
            "font-semibold text-slate-700 whitespace-nowrap"
          )}
        >
          <span className="absolute left-4">
            <FaFacebook size={20} className="text-blue-600" />
          </span>
          {"Sign Up With Facebook"}
        </button>
      </div> */}
      <div className="w-10/12 mx-auto bg-gray-100 h-0.5" />
      <button
        className="w-full px-4 py-3 mt-6 text-white bg-blue-600 rounded-sm"
        onClick={handleSignUp}
      >
        {" Sign up with email"}
      </button>
      <div className="my-2 text-center">
        <p>Already signed up?{"  "}
          <Link to={pagesInfo?.LOG_IN?.pagePath} className="text-base text-gray-700 underline hover:text-purple-700">
            {"Sign In"}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default function ReferralSignUpPage() {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.REFERRAL_SIGN_UP))
  }, [dispatcher, currentPageInfo])

  return (
    <PageContentStyle>
      <div className={"w-screen min-h-screen grid grid-cols-2 gap-3 place-items-center"}>
        <div className={"col-span-2 lg:col-span-1"}>
          <ReferralSignUpForm />
        </div>
        <div className={"hidden lg:block lg:col-span-1"}>
          <img src={SignUpReferralImage} className={"w-full h-full object-cover"} alt={"refer-signup"} />
        </div>
      </div>
      <Footer1 />
    </PageContentStyle>
  )
}