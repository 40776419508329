import { memo, useMemo } from "react";

import { Link, useParams } from "react-router-dom";

const TopTabNavigation = memo(({ tabs = [] }) => {
    const params = useParams()
    const pathParam = params["*"]

    const activeTabButtonStyle = useMemo(() => ({
        color: '#0741ad',
        borderColor: '#0741ad',
        backgroundColor: '#e3f2fd'
    }), []);

    return (
        <>
            {tabs.map((tab, index) => (
                <Link
                    key={index}
                    to={`${tab?.value}`}
                    style={pathParam?.startsWith(tab?.value) ? activeTabButtonStyle : {}}
                    className={'border-b px-5 py-2 w-full whitespace-nowrap font-bodyComp border-divider-darkLight bg-back-ground-light duration-200 ease-in-out text-back-ground-black hover:bg-back-ground-lightBlue text-center md:text-left'}
                >
                    {tab.label}
                </Link>
            ))}
        </>
    )
})

export default TopTabNavigation;