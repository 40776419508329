import { useEffect, useState } from "react";

import PageLoader from "components/loader/PageLoader";

import { getCurrentOrgDetail } from "redux/org/org.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { EDULYTE_WEB_DOMAIN_URL } from "const/default.const";

type Props = {
    children: React.ReactNode
}

const OrgProvider = ({ children }: Props) => {
    const { publicOrgDetail, orgThemeDetail } = useAppState((state) => state.org)

    const dispatcher = useAppDispatcher()

    const [isMount, setIsMount] = useState(false)

    useEffect(() => {
        setIsMount(true)
    }, [])

    useEffect(() => {
        dispatcher(getCurrentOrgDetail())
    }, [])

    if (!isMount) return null;

    if (publicOrgDetail?.isLoading || orgThemeDetail?.isLoading) {
        return <PageLoader />
    }

    if (window.location?.pathname === "/") {
        window.open(`${ EDULYTE_WEB_DOMAIN_URL?.split("://")[0]}://${publicOrgDetail?.data?.result?.sub_domain}.${EDULYTE_WEB_DOMAIN_URL?.split("://")[1]}`, "_self")
        return null
    }
    
    if (!(publicOrgDetail?.isLoading || publicOrgDetail?.data) && publicOrgDetail?.message) {
        window.open(`https://edulyte.com`, "_self")
        return null
    }

    return (
        <>{children}</>
    );
}

export default OrgProvider;