import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AiOutlineLink } from 'react-icons/ai';

import { useAppState } from 'hooks/useStore';
import { userRoles } from 'redux/auth/auth.const';
import { courseStatus, courseType } from 'redux/course/course.const';
import { bookingOfferingTypeEnum, bookingScheduleIsRecurringEnum, bookingStatusEnum } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';

const BookingCourse = memo(({ newBookingDetail }) => {
    const { locals } = useAppState((state) => state.local)

    const navigate = useNavigate()

    const onHandleNavigateToCourse = useCallback(() => {
        if (newBookingDetail?.data?.result?.course?.slug && [courseStatus.PUBLISHED.value]?.includes(newBookingDetail?.data?.result?.course?.status)) {
            window.open(`${window.location.host}${pagesInfo.COURSE.pagePath}/${newBookingDetail?.data?.result?.course?.slug}`, "_blank")
        }
    }, [newBookingDetail?.data?.result])

    const onHandleCheckout = useCallback(() => {
        navigate(`${pagesInfo?.CHECKOUT_BOOKING?.pagePath}/${newBookingDetail?.data?.result?.id}`)
    }, [newBookingDetail?.data?.result?.id])

    return (
        <div className={"w-full px-2 py-3 flex flex-col gap-5 border border-divider-medium rounded-md"}>
            <div className={"w-full flex flex-col gap-1.5"}>
                <div className={cn(
                    "w-fit flex items-center justify-start gap-1.5 text-lg text-text-900",
                    [courseStatus.PUBLISHED.value]?.includes(newBookingDetail?.data?.result?.course?.status) && "!text-primary-dark hover:!text-primary-dark hover:underline cursor-pointer"
                )}
                    onClick={onHandleNavigateToCourse}
                >
                    <AiOutlineLink className={"text-lg"} />
                    <span className={"font-semibold font-bodyPri tracking-wide text-left line-clamp-1"}>
                        {newBookingDetail?.data?.result?.course?.title}
                    </span>
                </div>
                {newBookingDetail?.data?.result?.course?.subTitle &&
                    <span className={"font-bodyPri font-normal text-text-800 text-sm line-clamp-1"}>
                        {newBookingDetail?.data?.result?.course?.subTitle}
                    </span>
                }
            </div>
            <div className={"grid gap-3 text-sm md:grid-cols-2 opacity-70 place-items-start"}>
                <div className={"inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900"}>
                    <p>{"STATUS:"}</p>
                    <p className={`font-bold text-${bookingStatusEnum[newBookingDetail?.data?.result?.status?.toUpperCase()]?.darkColor}`}>
                        {bookingStatusEnum[newBookingDetail?.data?.result?.status?.toUpperCase()]?.label}
                    </p>
                </div>
                <div className={"inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900"}>
                    <p>{"OFFERING TYPE:"}</p>
                    <p className="font-bold capitalize">
                        {bookingOfferingTypeEnum.COURSE.label}
                    </p>
                </div>
                <div className={"inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900"}>
                    <p>{"CATEGORY:"}</p>
                    <p className="font-bold truncate">
                        {newBookingDetail?.data?.result?.course?.category
                            ? (newBookingDetail?.data?.result?.course?.category?.length > 17)
                                ? newBookingDetail?.data?.result?.course?.category?.slice(0, 17) + "..."
                                : newBookingDetail?.data?.result?.course?.category
                            : "-"
                        }
                    </p>
                </div>
                <div className={"inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900"}>
                    <p>{"LEVEL:"}</p>
                    {(newBookingDetail?.data?.result?.course?.courseProficiencies?.length > 0) &&
                        <p className="truncate font-bold">
                            {(newBookingDetail?.data?.result?.course?.courseProficiencies?.length > 2)
                                ? newBookingDetail?.data?.result?.course?.courseProficiencies?.slice(0, 2)?.map((proficiency) => proficiency?.masterProficiency?.proficiency)?.join(" | ") + (newBookingDetail?.data?.result?.course?.courseProficiencies?.length - 2)
                                : newBookingDetail?.data?.result?.course?.courseProficiencies?.map((proficiency) => proficiency?.masterProficiency?.proficiency)?.join(" | ")
                            }
                        </p>
                    }
                    {(!newBookingDetail?.data?.result?.course?.courseProficiencies || (newBookingDetail?.data?.result?.course?.courseProficiencies?.length === 0)) &&
                        <p className="truncate font-bold">{"-"}</p>
                    }

                </div>
                <div className="inline-flex space-x-1 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                    <p>{"SESSION(S):"}</p>
                    {(newBookingDetail?.data?.result?.course?.type === courseType?.ONE_ON_ONE.value) &&
                        <p className="font-bold">
                            {newBookingDetail?.data?.result?.bookingPrice?.sessionCount}
                        </p>
                    }
                    {(newBookingDetail?.data?.result?.course?.type === courseType?.GROUP.value) &&
                        <p className="font-bold truncate">
                            {`${newBookingDetail?.data?.result?.course?.basicDetail?.sessionCount} session(s) ${(newBookingDetail?.data?.result?.bookingSchedule?.isRecurring === bookingScheduleIsRecurringEnum.RECURRING.value) ? "per w..eek" : ""}`}
                        </p>
                    }
                </div>
                <div className={"inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900"}>
                    <p>{"LANGUAGE:"}</p>
                    {(newBookingDetail?.data?.result?.course?.courseLanguages?.length > 0) &&
                        <p className="font-bold">
                            {(newBookingDetail?.data?.result?.course?.courseLanguages?.length > 2)
                                ? newBookingDetail?.data?.result?.course?.courseLanguages?.slice(0, 2)?.map((language) => language?.masterLanguage?.language)?.join(" | ") + (newBookingDetail?.data?.result?.course?.courseLanguages?.length - 2)
                                : newBookingDetail?.data?.result?.course?.courseLanguages?.map((language) => language?.masterLanguage?.language)?.join(" | ")
                            }
                        </p>
                    }
                    {(!newBookingDetail?.data?.result?.course?.courseLanguages || (newBookingDetail?.data?.result?.course?.courseLanguages?.length === 0)) &&
                        <p className="font-bold">{"-"}</p>
                    }

                </div>
                <div className={"inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900"}>
                    <p>{"DURATION:"}</p>
                    {(newBookingDetail?.data?.result?.course?.type === courseType?.ONE_ON_ONE.value) &&
                        <p className="font-bold">
                            {newBookingDetail?.data?.result?.bookingPrice?.duration + " min."}
                        </p>
                    }
                    {(newBookingDetail?.data?.result?.course?.type === courseType?.GROUP.value) &&
                        <p className="font-bold">
                            {newBookingDetail?.data?.result?.bookingPrice?.duration + " min."}
                        </p>
                    }
                </div>
            </div>
            {((locals?.userRole === userRoles?.STUDENT?.value) && (newBookingDetail?.data?.result?.status === bookingStatusEnum?.PENDING?.value)) &&
                <div
                    className={cn(
                        "w-full px-4 py-2 mt-3 rounded-md bg-secondary-dark hover:opacity-90 cursor-pointer",
                        "text-center font-buttons font-medium text-base text-text-50 whitespace-nowrap",
                    )}
                    onClick={onHandleCheckout}
                >
                    <span>
                        {"Checkout to confirm"}
                    </span>
                </div>
            }
        </div>
    )
})

export default BookingCourse;