import { useState, useEffect } from 'react'
import { cn } from "utils/cn.utils";
import { Link, useNavigate } from 'react-router-dom'
import format from "string-format"

import { GiElectric } from "react-icons/gi";
import { MdVerified } from "react-icons/md";

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import ToolTipView from 'components/tooltipView';
import RatingStars from 'components/ratingStars/RatingStars';
import MuxVideoPlayer from 'components/muxVideoPlayer/MuxVideoPlayer';

import { getFBChat, getFBChatId } from 'redux/firebase/firebase.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setMessageTemplate } from 'redux/firebase/firebase.slice';
import { modalConst } from 'redux/local/local.const';
import { blueTickEnum } from 'redux/user/user.const';
import { userWsUrl } from "redux/user/user.const";

import { edulyteWebSocket } from "websocket/edulyte.websocket";

import { pagesInfo } from "utils/pagesInfo"
import { currencyRateConversion } from 'utils/generators.utils';

const TutorCard = ({ tutor, isFav = false, handleFav }) => {

  const { session } = useAppState(s => s.auth)
  const { user } = useAppState((s) => s.user)
  const { messageTemplate } = useAppState((s) => s.firebase)
  const { modal, locals } = useAppState((s) => s.local)

  const navigate = useNavigate()
  const dispatcher = useAppDispatcher()

  const [tab, setTab] = useState(0)
  const [lastIndex, setLastIndex] = useState(6)
  const [userDetail, setUserDetail] = useState(null)

  useEffect(() => {
    let publicUserDetailWebSocket = null

    const getPublicUserDetail = async () => {
      const wsConfig = { url: format(userWsUrl.PUBLIC_USER_DETAIL, { username: tutor?.username }) }
      publicUserDetailWebSocket = await edulyteWebSocket(wsConfig)

      publicUserDetailWebSocket.onmessage = async (event) => {
        setUserDetail(JSON.parse(event?.data))
      }
    }

    if (tutor?.username) {
      getPublicUserDetail()
    }

    return () => {
      if (publicUserDetailWebSocket?.readyState === WebSocket.OPEN) {
        publicUserDetailWebSocket?.close()
      }
    }
  }, [tutor?.username])

  const NEW_TUTOR = 2;
  const isMyOffering = user?.user?.userId === tutor?.userId

  // useEffect(() => {
  //   window.addEventListener("resize", getWindowSize())

  //   return () => {
  //     window.removeEventListener("resize")
  //   }
  // }, [])

  const handleTab = (value) => {
    setTab(value);
  }

  const handleSendMessage = async () => {
    if (!session?.isLoggedIn) {
      dispatcher(setModal({
        ...modal,
        [modalConst.AUTHORIZATION_MODAL.stateKey]: true
      }))
      return;
    }

    if (isMyOffering) return;

    const userIdOne = user?.user?.userId
    const userIdTwo = tutor?.userId

    const chatId = await getFBChatId(userIdOne, userIdTwo)
    let fbChat = await getFBChat(chatId)

    if (!fbChat || fbChat === undefined) {
      dispatcher(setMessageTemplate({
        ...messageTemplate,
        isLoading: false,
        userIdOne: userIdOne,
        userIdTwo: userIdTwo,
        tutorUserId: tutor?.userId,
        message: '',
        errorMsg: null
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
      }))
      return;
    }

    navigate(`${pagesInfo.MESSAGES.pagePath}/${fbChat.chatId}`)
  }

  const getContent = (tab) => {
    switch (tab) {
      case 0:
        return (
          <div className={'rounded-lg w-full md:w-[20rem] h-full'}>
            <MuxVideoPlayer playbackId={tutor?.videoUrl} isShowButtons={false} isShowRefreshButton={false} autoPlay={false} />
            {/* <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${tutor?.videoUrl}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
            </iframe> */}
          </div>
        );
      case 1:
        return (
          <div className='overflow-hidden w-full h-full'>
            <span className="font-normal text-base text-text-700 text-justify">
              {tutor?.bio?.slice(0, 250)}
              <Link to={`${pagesInfo.TUTOR.pagePath}/${tutor?.userId}`} className='font-medium text-sky-700 cursor-pointer'>
                {tutor?.bio?.length > 250 ? `...see more` : ""}
              </Link>
            </span>
          </div>

        );

      default:
        return null;
    }
  };

  return (
    <div className="grid grid-cols-12 bg-white rounded-lg py-5 w-full shadow-[0_0_10px_1px_rgba(0,0,0,0.1)]">
      <div className='w-full col-start-1 col-span-12 md:col-start-1 md:col-span-7 grid grid-cols-12 px-5'>
        <div className='col-start-1 col-span-11 w-full h-full grid grid-cols-12'>

          <div className='h-full col-start-1 col-span-3 flex flex-col items-center justify-start gap-8'>

            <div className='relative w-20 h-20 rounded-full'>
              <Link to={`${pagesInfo.TUTOR.pagePath}/${tutor?.userId}`}>
                <img src={tutor?.profilePicUrl} alt={"profile-pic"}
                  className="w-full h-full rounded-full object-cover"
                />
              </Link>
              <div className={'relative has-tooltip'}>
                <div className={"absolute -top-7.5 bottom-0.5 right-0.5 w-5 h-5 rounded-full border-2 border-white overflow-hidden"}>
                  <img
                    src={`https://flagcdn.com/16x12/${tutor?.fromCountry?.countryDomain}.png`.toLowerCase()}
                    alt={"country-flag"}
                    className='w-full h-full object-cover overflow-hidden'
                  />
                </div>
                <span
                  className={cn(
                    "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-1 -right-0",
                    "flex flex-col items-center justify-start overflow-hidden"
                  )}
                >
                  {tutor?.fromCountry?.country}
                </span>
              </div>
            </div>

            <div className='flex flex-wrap justify-start items-center gap-1'>
              {tutor?.rating?.avgRating > NEW_TUTOR
                && <div className={"flex flex-col items-center justify-center gap-1"}>
                  <RatingStars rating={tutor?.rating?.avgRating}
                    fullRating={5}
                  />
                  <div className="font-normal text-xs text-orange-400">
                    {parseFloat('' + tutor?.rating?.avgRating?.toFixed(1))}
                  </div>
                </div>
              }
            </div>

            {/* {tutor?.badges?.map((badge) => badge?.badge?.badgeCategory?.badgeCategory === 'Membership Tier' && (
              <div className='flex flex-col justify-center items-center gap-2'>
                <div className='w-10 h-10 overflow-hidden'>
                  <img src={badge?.badge?.badgePicUrl} alt={badge?.badge?.badge}
                    className={"w-full h-full object-cover"}
                  />
                </div>
                <span className='text-base font-bodyPri font-normal tracking-wide text-text-800'>{badge?.badge?.badge}</span>
              </div>
            ))} */}

          </div>

          <div className='w-full col-start-4 col-span-9 flex flex-col items-start gap-5 pl-5'>

            <div className='w-fit flex flex-col gap-2 overflow-hidden'>
              <div className='flex items-center gap-3'>
                <Link to={`${pagesInfo.TUTOR.pagePath}/${tutor?.userId}`}>
                  <span className='font-medium text-xl truncate capitalize'>
                    {tutor?.firstName} {tutor?.lastName.charAt(0) + "."}
                  </span>
                </Link>
                {(tutor?.blueTick === blueTickEnum?.YES?.value) &&
                  <div className='relative has-tooltip cursor-pointer'>
                    <MdVerified className={"text-lg text-primary-dark"} />
                    <span className='tooltip w-fit px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap'>
                      {"Verified Account"}
                    </span>
                  </div>
                }
                {/* {(tutor?.onlineStatus === "online") && */}
                {(userDetail?.online_status === "online") &&
                  <div className={"w-3 h-3 rounded-full bg-green-600"}></div>
                }
                {tutor?.instantBooking &&
                  <GiElectric size={25} style={{ color: 'deeppink' }} />
                }
              </div>
              <span className='font-bodyPri font-normal text-text-800 text-sm'>
                {tutor?.elevatorPitch}
              </span>
              <div className='h-1 w-5 bg-secondary-main rounded-lg'></div>
            </div>

            <div className='flex flex-row gap-2 items-center justify-center'>
              {tutor?.badges?.slice(0, lastIndex)?.map((badge, index) => (
                <div
                  key={index}
                  className='relative has-tooltip w-8 h-8 rounded-full border border-text-500 p-0.5 cursor-pointer'>
                  <img
                    src={badge?.badge?.badgePicUrl}
                    className={"w-full h-full object-cover"}
                    alt={badge?.badge?.tooltip}
                  />
                  <span className='tooltip top-8 -left-5 w-fit px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap'>
                    {badge?.badge?.tooltip}
                  </span>
                </div>
              ))}
              {tutor?.badges?.length > lastIndex &&
                <span className='relative has-tooltip text-primary-main font-medium text-lg cursor-pointer'>
                  {`+${tutor?.badges?.length - lastIndex}`}
                  <span className='tooltip top-8 -left-5 w-fit px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide'>
                    {"see more"}
                  </span>
                </span>
              }
            </div>

            <div className='flex flex-col gap-3'>

              <div className="flex flex-col text-base font-bodyPri tracking-wide">
                <span className="text-text-600">
                  {"Speaks:"}
                </span>
                <span className='font-medium whitespace-nowrap'>
                  {tutor?.languages?.slice(0, 2)?.map((language) => (language?.language))?.join(", ")}
                  {tutor?.languages?.length > 2 &&
                    <Link
                      to={`${pagesInfo.TUTOR.pagePath}/${tutor?.userId}`}
                      className='font-medium text-blue-500' >
                      {` +${tutor?.languages?.length - 2}`}
                    </Link>
                  }
                </span>
              </div>

              <div className="flex flex-col gap-1 text-base font-bodyPri tracking-wide">
                <span className="text-text-600">
                  {"Teaches:"}
                </span>
                <span className='font-medium'>
                  {tutor?.categories?.slice(0, 2)?.join(", ")}
                  {tutor?.categories?.length > 2 &&
                    <Link
                      to={`${pagesInfo.TUTOR.pagePath}/${tutor?.userId}`}
                      className='font-medium text-blue-500' >
                      {` +${tutor?.categories?.length - 2}`}
                    </Link>
                  }
                </span>
              </div>

              <div className="flex flex-col gap-1 text-base font-bodyPri tracking-wide">
                <span className="text-text-600">Student Level:</span>
                <span className='font-medium whitespace-nowrap'>
                  {tutor?.proficiencies?.slice(0, 2)?.join(", ")}
                  {tutor?.proficiencies?.length > 2 &&
                    <Link
                      to={`${pagesInfo.TUTOR.pagePath}/${tutor?.userId}`}
                      className='font-medium text-blue-500' >
                      {` +${tutor?.proficiencies?.length - 2}`}
                    </Link>
                  }
                </span>
              </div>
            </div>
          </div>
        </div>

        {session?.isLoggedIn &&
          <div className="col-start-12 col-span-1 w-full flex justify-end cursor-pointer">
            <ToolTipView content={isFav ? "Favorite" : "Make Favorite"}>
              <span
                onClick={() => handleFav(tutor?.tutorId)}
                className={"cursor-pointer"}
              >
                {isFav ?
                  (<FavoriteIcon className='text-red-500 cursor-pointer' style={{ fontSize: "1.8rem" }} />) :
                  <FavoriteBorderIcon className='text-text-500 cursor-pointer' style={{ fontSize: "1.8rem" }} />
                }
              </span>
            </ToolTipView>
          </div>
        }
      </div>

      <div className='col-start-1 col-span-12 md:col-start-8 md:col-span-5 px-2 lg:px-5 flex flex-col justify-between border-l-2 pt-5 md:pt-0'>
        <div className='w-full flex flex-col justify-start gap-0.5'>
          <div className='flex justify-center items-center gap-2'>
            <span className={`px-2 text-text-900 font-medium text-lg cursor-pointer ${tab === 0 ?
              `border-b-2 border-pink-500` : ``}`} onClick={() => handleTab(0)}>
              Intro
            </span>
            <span className={`px-2 text-text-900 font-medium text-lg cursor-pointer ${tab === 1 ?
              `border-b-2 border-pink-500` : ``}`} onClick={() => handleTab(1)}>
              About
            </span>
          </div>

          <div className={'w-full flex justify-center items-center px-0 sm:px-3 -py-5'}>
            {getContent(tab)}
          </div>
        </div>

        <div className='flex flex-col items-center justify-center gap-3'>
          <div className="flex justify-center items-center gap-2">
            <span className="font-normal text-base text-gray-500 tracking-wide">
              Hourly Fee from
            </span>
            <span className=" font-bodyPri font-medium text-lg">{currencyRateConversion(locals?.currencyCode, tutor?.price)}</span>
          </div>
          <div className='flex justify-center items-center space-x-5 '>
            <Link to={`${pagesInfo.TUTOR.pagePath}/${tutor?.userId}/classes`} className={cn(
              'w-32 py-1 flex justify-center items-center font-normal font-buttons rounded-full',
              "border border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
            )}>
              {"View Classes"}
            </Link>
            <span onClick={handleSendMessage} className={cn(
              "w-32 py-1 flex justify-center items-center rounded-full font-normal font-buttons",
              isMyOffering
                ? "border border-text-300 text-text-300"
                : "border border-text-700  text-gray-700  hover:bg-primary-main hover:text-text-50 hover:border-primary-main cursor-pointer"
            )}>
              {"Message"}
            </span>
          </div>
        </div>
      </div>
    </div >
  )
}

export default TutorCard;