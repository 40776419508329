import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { BsInfoCircleFill } from "react-icons/bs";

import { pageHeading } from "./data";

import { PageContentStyle } from './FindTutor.style';

import TutorCard from "components/tutorCard/TutorCard";
import Pagination from "components/pagination/Pagination";
import TutorCardLoader from 'components/loader/TutorCardLoader';
import Footer1 from 'components/footer/footer1/Footer1';

import { getQueryString } from 'pages/global/findTutors/data';
import Header from "pages/global/findTutors/components/Header";
import TutorPublicFilters from "pages/global/findTutors/components/tutorPublicFilters/TutorPublicFilters";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getAllCategoryList } from 'redux/category/category.request';
import { getMasterCountryList, getMasterLanguageList, getMasterProficiencyList } from 'redux/master/master.request';
import { getTutorPublicList, createFavTutorUserDetailByUserId, deleteFavTutorUserDetailByUserId } from 'redux/tutor/tutor.request';

import { useTitle } from 'hooks/useTitle';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearCategoryList } from 'redux/category/category.slice';
import { setClearCountryList, setClearLanguageList, setClearProfList } from 'redux/master/master.slice';
import { setClearTutorList, setClearFilterProps, setFilterProps } from 'redux/tutor/tutor.slice';
import { tutorFilterProps, DEFAULT_TUTOR_PAGE, DEFAULT_TUTOR_RECORDS, tutorMessageType } from 'redux/tutor/tutor.const';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';
import { generateLocalWeekDays, getTimeZoneOffset, timeZone, dayjs } from "utils/dateTime.utils";


const TutorsPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { locals } = useAppState((state) => state.local)
  const { currencyRateList } = useAppState((state) => state.master)
  const { user } = useAppState((s) => s.user)
  const { tutorList, filterProps } = useAppState((s) => s.tutor)

  const dispatcher = useAppDispatcher();
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [title, setTitle] = useTitle()

  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.FIND_TUTORS))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    setTitle({
      ...title,
      title: "Online Tutors for One-on-One & Group Lessons | Edulyte",
    })
  }, [tutorList])

  useEffect(() => {
    dispatcher(getAllCategoryList())
    dispatcher(getMasterLanguageList())
    dispatcher(getMasterCountryList())
    dispatcher(getMasterProficiencyList())

    return () => {
      dispatcher(setClearCategoryList())
      dispatcher(setClearLanguageList())
      dispatcher(setClearCountryList())
      dispatcher(setClearProfList())
    }
  }, [])

  useEffect(() => {
    if (currencyRateList?.currencyRateList) {
      const queryObject = generateQueryParams(location.search)
      const category = params["*"]

      let targetCurrencyRate = currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")]

      let timeZoneOffset = getTimeZoneOffset(timeZone)
      let localWeekDays = generateLocalWeekDays(timeZone)

      let queryParamPayload = {}
      let myFilterProps = { ...tutorFilterProps }

      myFilterProps["page"] = queryObject?.pn || DEFAULT_TUTOR_PAGE
      queryParamPayload["page"] = queryObject.pn || DEFAULT_TUTOR_PAGE

      myFilterProps["records"] = DEFAULT_TUTOR_RECORDS
      queryParamPayload["records"] = DEFAULT_TUTOR_RECORDS

      if (queryObject?.search) {
        myFilterProps["search"] = queryObject?.search?.replaceAll("-", " ")?.toString()
        queryParamPayload["search"] = queryObject.search?.replaceAll("-", " ")?.toString()
      }
      if (queryObject?.sortBy) {
        myFilterProps["sortBy"] = queryObject?.sortBy
        queryParamPayload["sortBy"] = queryObject.sortBy
      }
      if (queryObject?.level) {
        myFilterProps["proficiency"] = queryObject?.level?.split(",")?.map((items) => items?.split("-")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))
        queryParamPayload["proficiency"] = queryObject?.level?.split(",")?.map((items) => items?.split("-")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
      }
      if (queryObject?.speak) {
        myFilterProps["language"] = queryObject?.speak?.replaceAll("-", " ")?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))
        queryParamPayload["language"] = queryObject?.speak?.replaceAll("-", " ")?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
      }
      if (queryObject?.tutorFrom) {
        myFilterProps["tutorFrom"] = queryObject?.tutorFrom?.split(",")?.map((items) => items?.split("-")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))
        queryParamPayload["tutorFrom"] = queryObject?.tutorFrom?.split(",")?.map((item) => item?.split("-")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
      }
      if (queryObject?.day) {
        myFilterProps["day"] = queryObject?.day?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))
        let dayList = queryObject?.day?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
        queryParamPayload["day"] = dayList?.split(",")?.map((localDay) => dayjs(localWeekDays[localDay.toUpperCase()].date + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone)?.utc()?.format("dddd")?.toString())?.toString()
      }
      if (queryObject?.minHrsPrice) {
        let minHrsPrice = ((parseFloat(queryObject?.minHrsPrice) || 0) / targetCurrencyRate) * 100
        myFilterProps["minHrsPrice"] = queryObject?.minHrsPrice
        queryParamPayload["minHrsPrice"] = minHrsPrice
      }
      if (queryObject?.maxHrsPrice) {
        let maxHrsPrice = ((parseFloat(queryObject?.maxHrsPrice) || 0) / targetCurrencyRate) * 100
        myFilterProps["maxHrsPrice"] = queryObject?.maxHrsPrice
        queryParamPayload["maxHrsPrice"] = maxHrsPrice
      }
      if (queryObject?.segment) {
        queryParamPayload["segment"] = queryObject?.segment?.replaceAll("-", " ")?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
      }
      if (queryObject?.skill) {
        queryParamPayload["skill"] = queryObject?.skill?.replaceAll("-", " ")?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
      }
      if (category) {
        myFilterProps["category"] = category?.split("-")?.filter((items) => items !== "tutors")?.map((item) => item?.charAt(0)?.toUpperCase() + item.slice(1))?.join(' ')?.split(",")
        queryParamPayload["category"] = category?.split("-")?.filter((items) => items !== "tutors")?.join(" ")?.toString()
      }
      if (queryObject?.category) {
        let query = []
        if (!queryParamPayload["category"]) {
          query = []
        } else {
          query = myFilterProps["category"]?.split(",")
          query = queryParamPayload["category"]?.split(",")
        }
        myFilterProps["category"] = queryObject?.category?.replaceAll("-", " ")?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))
        queryParamPayload["category"] = [...query, ...queryObject?.category?.replaceAll("-", " ")?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))]?.toString()
      }

      dispatcher(getTutorPublicList(queryParamPayload))
      dispatcher(setFilterProps({ filterProps: myFilterProps }))
    }

    return () => {
      dispatcher(setClearTutorList())
      dispatcher(setClearFilterProps())
    }

  }, [location.search, params, currencyRateList?.currencyRateList])

  window.onscroll = () => {
    var filterElement = document.getElementById('filter')
    if (filterElement.offsetTop > 10) {
      setIsSticky(true)
      // document.getElementById("dropdown").style.display = "none";
    } else {
      setIsSticky(false)
      // document.getElementById("dropdown").style.display = "block";
    }
  }

  const onHandleChangePage = async (page) => {
    const query = await getQueryString({ ...filterProps, page: page, records: DEFAULT_TUTOR_RECORDS })
    navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
  }

  return (
    <PageContentStyle>
      <Header pageHeading={pageHeading} />
      <div className={"relative"}>
        <div id={"filter"} className={cn(
          "sticky top-0 z-30 select-none -mt-8 lg:-mt-10 py-0",
          isSticky && "bg-secondary-light shadow transition-all delay-20 duration-100 ease-in-out"
        )}>
          <TutorPublicFilters />
        </div>
        <div className="grid grid-cols-12 pb-5 mt-6">
          <div className='col-start-2 col-span-10 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 w-full space-y-5'>
            {tutorList?.filteredTutorList?.message &&
              <div className={"w-full px-2 py-4 flex items-center justify-center gap-3 bg-white rounded-lg"}>
                {tutorList?.filteredTutorList?.message?.type === tutorMessageType?.INFO?.value &&
                  <span className={cn(`text-${tutorMessageType?.INFO?.darkColor}`)}>
                    <BsInfoCircleFill className={"text-2xl"} />
                  </span>
                }
                <span className={"font-bodyPri font-normal text-text-900 text-base lg:text-lg"}>
                  {tutorList?.filteredTutorList?.message?.text}
                </span>
              </div>
            }
            {tutorList?.filteredTutorList?.results?.slice(0)?.sort()?.map((tutor, index) => (
              <TutorCard key={index}
                tutor={tutor}
                isFav={user?.user?.favoriteTutors?.filter((favTutor) => favTutor?.tutorId === tutor?.tutorId).length > 0}
                handleFav={(tutorId) => {
                  const favoriteTutors = user?.user?.favoriteTutors?.filter((favTutor) => favTutor?.tutorId === tutorId)
                  if (favoriteTutors.length > 0) {
                    dispatcher(deleteFavTutorUserDetailByUserId(user?.user?.userId, { tutorId: tutorId }))
                  }
                  else {
                    dispatcher(createFavTutorUserDetailByUserId(user?.user?.userId, { tutorId: tutorId }))
                  }
                }}
              />
            ))}
            {new Array(DEFAULT_TUTOR_RECORDS).fill("").map((_, index) => <TutorCardLoader key={index} isLoading={tutorList?.isLoading} />)}
            {tutorList?.filteredTutorList && tutorList?.filteredTutorList?.records > 0 &&
              <div className="w-full flex justify-center items-center">
                <Pagination
                  page={tutorList?.filteredTutorList?.page}
                  totalPages={tutorList?.filteredTutorList?.totalPages}
                  onChangePage={(page) => onHandleChangePage(page)}
                />
              </div>
            }
            {(tutorList?.filteredTutorList?.records === 0 || tutorList?.errorMsg) &&
              <span className='w-full h-full flex items-center justify-center text-center text-base font-bodyPri tracking-wide pt-5'>
                {tutorList?.errorMsg}
              </span>
            }
          </div>
        </div>
      </div>
      <Footer1 />
    </PageContentStyle>
  );
};

export default TutorsPage;