import { useEffect, useCallback, useState, useMemo } from "react";
import { Link, useLocation } from 'react-router-dom';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { GoogleLogin } from "@react-oauth/google";

import ButtonLoader from "components/loader/button-loader";

import { ENVIRONMENT } from "const/default.const";

import {
    Container,
    Content,
    TeachWithUsCard,
    TeachWithUsImage,
    CardHeader,
    Heading,
    SubHeading,
    CardFooter,
    AnchorLink,
    SocialSignUpWrapper,
} from './TeachWithUsHeroStyle';

import HDividerWithText from 'components/hDividerWithText/HDividerWithText';
import SignUpForm from 'components/signUpForm/SignUpForm';

import { createOneTapGoogleLogin, createSignUp } from 'redux/auth/auth.request';

import useWindowSize from 'hooks/useWindowSize';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { environmentEnum } from "redux/local/local.const";
import { otpStatus } from 'redux/notification/notification.const';

import { pagesInfo } from 'utils/pagesInfo';
import { timeZone } from "utils/dateTime.utils";

const teachWithUsHeroOneImageUrl = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/teach-with-us/teach-with-us-hero-one.png"

const edulyteTermsOfService = "https://www.edulyte.com/terms-of-service/"
const edulytePrivacyPolicy = "https://www.edulyte.com/privacy-policy/"

const TeachWithUsHero = () => {
    const { session, signUp } = useAppState((s) => s.auth)
    const { verifyOtp } = useAppState((s) => s.notification)
    const { user } = useAppState((s) => s.user)


    const dispatcher = useAppDispatcher()
    const { width } = useWindowSize()
    const location = useLocation()
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [stopLoading, setStopLoading] = useState(false);

    const CURRENT_ACITVE_DOMAIN = useMemo(() => {
        if (ENVIRONMENT === environmentEnum.local.key) {
            return 'localhost'
        } else if (ENVIRONMENT === environmentEnum.dev.key) {
            return 'edulyte.net'
        } else {
            return 'edulyte.com'
        }
    }, [ENVIRONMENT])

    const pageUrl = window.location.host + location.pathname

    const getRecaptchaToken = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('signup');
        if (token) {
            return token;
        }
    }, [executeRecaptcha])

    const createNewSignUpUser = async () => {
        const generatedToken = await getRecaptchaToken()

        if (generatedToken) {
            const body = {
                firstName: signUp?.payload?.firstName,
                lastName: signUp?.payload?.lastName,
                email: signUp?.payload?.email,
                referralCode: signUp?.payload?.referralCode,
                rechaptcha_token: generatedToken,
                isPromotionActive: signUp?.payload?.isPromotionActive,
                timeZone: timeZone,
                source: pageUrl
            }
            if (signUp?.payload?.mobileNo) {
                body["mobileNo"] = String(signUp?.payload?.country?.isdCode + "-" + signUp?.payload?.mobileNo)
                body["isMobileNoVerified"] = signUp?.payload?.isMobileNoVerified
            }
            dispatcher(createSignUp(body))
        }
    }

    useEffect(() => {
        if (verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            createNewSignUpUser()
        }
    }, [verifyOtp?.verifyOtp])

    const onHandleSuccess = (credentialResponse) => {
        setStopLoading(true)
        const requestPayload = {
            timeZone: timeZone,
            credentials: credentialResponse?.credential,
            source: pageUrl
        }
        dispatcher(createOneTapGoogleLogin(requestPayload))
        setTimeout(() => { setStopLoading(false) }, 1000)
    }

    return (
        <Container>
            {/* <div className='lg:-ml-[34rem] w-1/2 h-screen bg-primary-dark rounded-br-full hidden lg:block'>
            </div> */}
            {/* <img
                src={'https://edulyte-website-image.s3.ap-south-1.amazonaws.com/becomete.png'}
                alt='teach-with-us-hero'
            /> */}

            {!session?.isLoggedIn &&
                <div className={"w-full flex items-center justify-center mx-auto container"}>
                    <Content>
                        <div className={"w-full col-start-1 col-span-full flex items-end justify-center my-0 md:my-5 lg:my-0"}>
                            <span className={"w-full font-tagLine font-bold text-3xl md:text-4xl lg:text-5xl text-primary-dark text-center"}>
                                {"Educate, Empower, Excel"}
                            </span>
                        </div>
                        <TeachWithUsCard>
                            <CardHeader>
                                <Heading>{'SIGN UP TO TEACH WITH US'}</Heading>
                                <SubHeading>
                                    {'The platform that cares about your success'}
                                </SubHeading>
                            </CardHeader>
                            <SignUpForm />
                            <HDividerWithText text={'or continue with'} />
                            <SocialSignUpWrapper>
                                {(stopLoading || user?.isLoading) &&
                                    <div className='bg-primary-dark rounded-lg min-w-full'>
                                        <ButtonLoader isLoading={stopLoading} styles={{ color: 'white', height: 40 }} />
                                    </div>
                                }
                                {(!stopLoading && !user?.isLoading) &&
                                    <GoogleLogin
                                        onSuccess={onHandleSuccess}
                                        onError={(error) => {

                                            console.log('Login Failed: ', error);
                                        }}
                                        ux_mode={"popup"}
                                        width={(width > 768) ? "370" : '400'}
                                        size={'large'}
                                        theme={'outline'}
                                        shape={"rectangular"}
                                        text="signup_with"
                                        // select_account={true}
                                        auto_select={true}
                                        state_cookie_domain={CURRENT_ACITVE_DOMAIN}
                                        use_fedcm_for_prompt={true}
                                    />
                                }

                            </SocialSignUpWrapper>
                            <CardFooter>
                                {"By signing up, I agree to "}
                                <a
                                    href={edulyteTermsOfService}
                                    rel={"noreferrer"}
                                    target={"_blank"}
                                    className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                                >
                                    {"Terms of service"}
                                </a>,
                                <AnchorLink to={'#'}>
                                    {" Community Guidelines"}
                                </AnchorLink>
                                {" and "}
                                <a
                                    href={edulytePrivacyPolicy}
                                    rel={"noreferrer"}
                                    target={"_blank"}
                                    className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                                >
                                    {"Privacy Policy"}
                                </a>
                            </CardFooter>
                        </TeachWithUsCard>
                        <TeachWithUsImage>
                            <img
                                src={teachWithUsHeroOneImageUrl}
                                alt={'teach-with-us-hero-one'}
                                className={'w-full h-full object-contain'}
                            />
                        </TeachWithUsImage>
                    </Content>
                </div>
            }
            {session?.isLoggedIn &&
                <div className='absolute z-30 w-full h-[80vh] grid grid-cols-12 place-items-center gap-3'>
                    <div className='pt-5 sm:pt-10 md:pt-16 col-start-3 col-span-full lg:col-start-2 lg:col-span-3 flex flex-col justify-end gap-16'>
                        <div className='relative w-48 h-12 flex justify-center items-center bg-secondary-main rounded'>
                            <Link to={pagesInfo?.PRE_ONBOARDING?.pagePath} className='font-bodyPri font-normal text-text-50 text-xl tracking-wide'>
                                {"Become a Tutor"}
                            </Link>
                            <div className='z-[-100] absolute w-8 h-8 bg-secondary-main rounded -right-4 rotate-45'>
                            </div>
                        </div>
                        <div className='relative w-48 h-12 flex justify-center items-center bg-primary-main rounded'>
                            <Link to={"/dashboard"} className='font-bodyPri font-normal text-text-50 text-xl tracking-wide'>
                                {"Go to Dashboard"}
                            </Link>
                            <div className='z-[-100] absolute w-8 h-8 bg-primary-main rounded -right-4 rotate-45'>
                            </div>
                        </div>
                    </div>
                    <div className='col-start-3 col-span-full lg:col-start-6 lg:col-span-4 w-[22rem] h-[22rem] md:w-[36rem] md:h-[36rem] lg:h-[42rem] lg:w-[42rem] overflow-hidden'>
                        <img src={teachWithUsHeroOneImageUrl} alt={'teach-with-us-hero-one'} className={'w-full h-full object-contain'} />
                    </div>
                </div>
            }
        </Container>
    )
}

export default TeachWithUsHero;