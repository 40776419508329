import axios from "axios";
import store from "redux/store";

import axiosAuthInterceptor from 'interceptors/axiosAuth.interceptor';

import { EDULYTE_API_DOMAIN_URL, UNSPLASH_ACCESS_KEY } from 'const/default.const';

import { createApi } from "unsplash-js";

export const baseApiInstance = () => {

    const instance = axios.create({
        baseURL: `${EDULYTE_API_DOMAIN_URL}/v1`,
        responseType: "json",
        headers: { 'Content-Type': "application/json", "org": window.location.host }
    })

    return instance
};

export const baseCredApiInstance = () => {
    const instance = axios.create({
        baseURL: `${EDULYTE_API_DOMAIN_URL}/v1`,
        responseType: "json",
        headers: { 'Content-Type': "application/json", 'org': window.location.host },
        withCredentials: true
    })

    return instance
};

export const baseAuthApiInstance = () => {

    const instance = axios.create({
        baseURL: `${EDULYTE_API_DOMAIN_URL}/v1`,
        responseType: "json",
        headers: { 'Content-Type': "application/json", 'org': window.location.host },
        withCredentials: true
    })

    axiosAuthInterceptor(instance, store)

    return instance
};

export const baseUnsplashApiInstance = () => {

    const instance = createApi({
        accessKey: UNSPLASH_ACCESS_KEY
    })

    return instance
}

export const baseApiInstanceFormData = () => {

    const instance = axios.create({
        baseURL: `${EDULYTE_API_DOMAIN_URL}/v1`,
        responseType: "json",
        headers: { 'Content-Type': "multipart/form-data", 'org': window.location.host }
    })

    return instance;
};

export const baseAuthApiInstanceFormData = () => {

    const instance = axios.create({
        baseURL: `${EDULYTE_API_DOMAIN_URL}/v1`,
        responseType: "json",
        headers: { 'Content-Type': "multipart/form-data", 'org': window.location.host }
    })

    axiosAuthInterceptor(instance, store)

    return instance;
}