import { useState } from 'react';
import { toast } from 'react-toastify';
import image from 'assets/image/google-calendar.png'
import { MdChevronRight } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import { Toggle } from 'components/common-components/Toggle';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setUser } from 'redux/user/user.slice';
import { useConfirm } from 'hooks/useConfirm';
import authService from 'redux/auth/auth.service';
import { getMyProfile } from 'redux/user/user.request';

const GoogleCalendar = () => {
    const { user } = useAppState(s => s.user);
    const [loading, setLoading] = useState(false);
    const { confirm, ConfirmDialog } = useConfirm();

    const dispatcher = useAppDispatcher()

    const createRevokeGoogleCalendarAccess = async () => {
        const isConfirmed = await confirm("Are you sure? Disconnecting will remove the integration, potentially disrupting your connected experience.");
        if (!isConfirmed) return;

        setLoading(true)
        try {
            const response = await authService.createRevokeGoogleCalendarAccess()
            if (response.status === 200) {
                dispatcher(setUser({
                    ...user?.user,
                    calendar: {
                        ...user?.user?.calendar,
                        google: {
                            ...user?.user?.calendar?.google,
                            active: false
                        }
                    }
                }))
                dispatcher(getMyProfile())
                toast.success(response.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error("Google revoke error:", error)
            toast.error("An error occurred while disconnecting to Google Calendar. Please try again.")
        } finally {
            setLoading(false)
        }
    }

    const createGoogleCalendarIntegration = async () => {
        setLoading(true)

        try {
            const response = await authService.createGoogleCalendarIntegration()
            if (response.status === 200) {
                toast.success(response.data.message)
                window.location.href = response?.data?.auth_url;
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error("Google integration error:", error)
            toast.error("An error occurred while connecting to Google Calendar. Please try again.")
        } finally {
            setLoading(false)
        }
    }

    const handleToggle = () => {
        if (user?.user?.calendar?.google?.active) {
            createRevokeGoogleCalendarAccess()
        } else if (!user?.user?.calendar?.google?.active) {
            createGoogleCalendarIntegration()
        }
    }

    return (
        <>
            <div className='flex flex-col justify-between rounded shadow overflow-hidden'>
                <div className='flex items-center justify-center gap-3 p-5 bg-back-ground-light'>
                    <img
                        className='h-10 object-contain'
                        src={image}
                        alt={'Google Calendar'}
                    />
                    <h3 className='font-medium text-lg whitespace-nowrap'>{'Google Calendar'}</h3>
                </div>
                <div className='space-y-2 px-5 py-8'>
                    <h2 className='font-medium'>{"View Edulyte Events in Google Calendar"}</h2>
                    <p className='text-xs text-text-600 pb-3'>{"Connect Google Calendar to Edulyte to see your Edulyte events directly in your Google Calendar. Stay organised and never miss an important event."}</p>
                    <a
                        href="https://www.edulyte.com/"
                        target='_black'
                        className='flex items-center gap-0.5 text-xs text-primary-main hover:text-secondary-dark'
                    >
                        {"Learn more"}
                        <MdChevronRight size={16} />
                    </a>
                </div>
                <div className='flex items-center justify-between gap-3 p-5 border-t border-t-text-300'>
                    <p className='text-sm font-medium'>{"Enable Intergration"}</p>
                    {loading ? (
                        <FaSpinner className={"animate-spin text-lg text-primary-main"} />
                    ) : (
                        <Toggle value={user?.user?.calendar?.google?.active} onChange={handleToggle} />
                    )
                    }
                </div>
            </div>
            <ConfirmDialog confirmBtnText={"Disconnect now"} title='Disable Integration' />
        </>
    )
}

export default GoogleCalendar;
