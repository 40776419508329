import { useCallback, useMemo, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { MdAccessTime } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";

import FloatingLabelSelect from "components/floating/floatingLabelSelect";

import { generateStartTimeOptions, generateEndTimeOptions } from "components/modals/eventlyModals/data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAddUserEventlyDetailPayload } from "redux/evently/evently.slice";

import { dayjs, timeZone } from "utils/dateTime.utils";

const DateTimeSlots = () => {
    const { addUserEventlyDetail } = useAppState((state) => state.evently)

    const [toggle, setToggle] = useState({
        isShowEndDate: false,
        startDatePopup: false,
        endDatePopup: false
    });

    const dispatcher = useAppDispatcher()

    const startTimeOption = useMemo(() => generateStartTimeOptions(dayjs().tz(timeZone)), [])
    const endTimeOption = useMemo(() => generateEndTimeOptions(addUserEventlyDetail?.payload?.startDateTime), [addUserEventlyDetail?.payload?.startDateTime])

    const onHandleSelectStartDate = useCallback((startDate) => {
        let newEndDateTime = dayjs(addUserEventlyDetail?.payload?.endDateTime)?.tz(timeZone)
        newEndDateTime = dayjs(startDate).add(1, "hour").format("YYYY-MM-DD HH:mm:ss");
        dispatcher(setAddUserEventlyDetailPayload({
            ...addUserEventlyDetail?.payload,
            startDateTime: dayjs(startDate).tz(timeZone).format("YYYY-MM-DD HH:mm:ss"),
            endDateTime: newEndDateTime
        }))
        setToggle({
            isShowEndDate: toggle.isShowEndDate,
            startDatePopup: false,
            endDatePopup: false
        })
    }, [addUserEventlyDetail?.payload, toggle])

    const onHandleSelectEndDate = useCallback((endDate) => {
        dispatcher(setAddUserEventlyDetailPayload({
            ...addUserEventlyDetail?.payload,
            endDateTime: dayjs(endDate).tz(timeZone).format("YYYY-MM-DD HH:mm:ss")
        }))
        setToggle({
            isShowEndDate: toggle.isShowEndDate,
            startDatePopup: false,
            endDatePopup: false
        })
    }, [addUserEventlyDetail?.payload, toggle])

    const onHandleSelectStartTimeSlot = (slotInfo) => {
        let newStartDateTime = dayjs(addUserEventlyDetail?.payload?.startDateTime).tz(timeZone)
        let newEndDateTime = dayjs(addUserEventlyDetail?.payload?.endDateTime).tz(timeZone)
        let startDate = newStartDateTime?.format("YYYY-MM-DD")
        newStartDateTime = startDate + " " + slotInfo?.value
        newEndDateTime = dayjs(newStartDateTime).add(1, "hour").format("YYYY-MM-DD HH:mm:ss");

        const endDateChanged = !dayjs(newStartDateTime).isSame(dayjs(newEndDateTime), 'day');

        dispatcher(setAddUserEventlyDetailPayload({
            ...addUserEventlyDetail?.payload,
            startDateTime: newStartDateTime,
            endDateTime: newEndDateTime
        }))

        if (endDateChanged) {
            setToggle({
                isShowEndDate: true,
                startDatePopup: false,
                endDatePopup: false
            })
        } else {
            setToggle({
                isShowEndDate: false,
                startDatePopup: false,
                endDatePopup: false
            })
        }
    }

    const onHandleSelectEndTimeSlot = (slotInfo) => {
        let newStartDateTime = dayjs(addUserEventlyDetail?.payload?.startDateTime).tz(timeZone)
        let startDate = newStartDateTime?.format("YYYY-MM-DD")
        let newEndDateTime = dayjs(addUserEventlyDetail?.payload?.endDateTime).tz(timeZone)
        let endDate = newEndDateTime?.format("YYYY-MM-DD")
        newEndDateTime = endDate + " " + slotInfo?.value

        const selectedTime = dayjs(slotInfo?.value, 'HH:mm:ss');

        const startHour = newStartDateTime.hour();
        const startMinute = newStartDateTime.minute();
        const selectedHour = selectedTime.hour();
        const selectedMinute = selectedTime.minute();

        const crossesMidnight = (selectedHour < startHour) || ((selectedHour === startHour) && (selectedMinute < startMinute));

        if (crossesMidnight) {
            endDate = dayjs(startDate).add(1, "day").format("YYYY-MM-DD");
            newEndDateTime = endDate + " " + slotInfo?.value
            setToggle({
                isShowEndDate: true,
                startDatePopup: false,
                endDatePopup: false
            });
        } else {
            newEndDateTime = startDate + " " + slotInfo?.value
            setToggle({
                isShowEndDate: false,
                startDatePopup: false,
                endDatePopup: false
            });
        }
        dispatcher(setAddUserEventlyDetailPayload({
            ...addUserEventlyDetail?.payload,
            endDateTime: newEndDateTime
        }))
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={"w-full grid grid-cols-12 gap-x-2 px-5"}>
                <div className={"col-start-1 col-span-1 self-center"}>
                    <MdAccessTime className={"text-text-800 text-lg"} />
                </div>
                <div className={"main-container col-start-2 col-span-full flex flex-wrap items-center gap-3 "}>
                    {!toggle?.startDatePopup && (
                        <button
                            className={"p-2 hover:bg-back-ground-lightBlue text-text-800 rounded"}
                            onClick={() => setToggle({
                                startDatePopup: true,
                                endDatePopup: false,
                                isShowEndDate: toggle.isShowEndDate
                            })}
                        >
                            {dayjs(addUserEventlyDetail?.payload?.startDateTime)?.tz(timeZone)?.format("dddd, DD-MMM-YYYY")}
                        </button>
                    )}
                    {toggle?.startDatePopup && (
                        <div className="p-2">
                            <DatePicker
                                slotProps={{ textField: { variant: "standard", size: "small" } }}
                                format={"dddd, DD-MMM-YYYY"}
                                disablePast
                                value={dayjs(addUserEventlyDetail?.payload?.startDateTime)?.tz(timeZone)}
                                onChange={onHandleSelectStartDate}
                            />
                        </div>
                    )}
                    <div className={"flex items-center gap-1"}>
                        <FloatingLabelSelect
                            showLabel={false}
                            showIcon={false}
                            customBtnStyle={"p-2 bg-white hover:bg-back-ground-lightBlue text-text-800 border-none border-b border-b-white focus-within:!ring-0 focus-within:border-b-primary-main focus-within:bg-back-ground-lightBlue"}
                            dropdownWrapperClassName={"w-36"}
                            dropDownContainerClassName={"max-h-52 overflow-y-auto scrollbar-thin"}
                            value={dayjs(addUserEventlyDetail?.payload?.startDateTime)?.tz(timeZone)?.format("hh:mma")}
                            options={startTimeOption}
                            onHandleSelect={onHandleSelectStartTimeSlot}
                            OptionChild={({ option }) => (
                                <div className={"bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between"}>
                                    {option.label}
                                </div>
                            )}
                        />
                        <BsDashLg />
                        <FloatingLabelSelect
                            showLabel={false}
                            showIcon={false}
                            customBtnStyle={"p-2 bg-white hover:bg-back-ground-lightBlue text-text-800 border-none border-b border-b-white focus-within:!ring-0 focus-within:border-b-primary-main focus-within:bg-back-ground-lightBlue"}
                            dropdownWrapperClassName={"w-44"}
                            dropDownContainerClassName={"max-h-52 overflow-y-auto scrollbar-thin"}
                            value={dayjs(addUserEventlyDetail?.payload?.endDateTime)?.tz(timeZone)?.format("hh:mma")}
                            options={endTimeOption}
                            onHandleSelect={onHandleSelectEndTimeSlot}
                            OptionChild={({ option }) => (
                                <div className="bg-white hover:bg-gray-100 px-4 py-2 flex items-center justify-between whitespace-nowrap">
                                    {option.label}
                                </div>
                            )}
                        />
                        {/* <button className={"text-primary-main p-2 rounded bg-white hover:bg-back-ground-lightBlue whitespace-nowrap flex items-center justify-center gap-1"}>
                            <MdOutlineAdd />
                            {"Add new"}
                        </button> */}
                    </div>

                    {(toggle?.isShowEndDate && !toggle?.endDatePopup) &&
                        <button
                            className={"p-2 hover:bg-back-ground-lightBlue text-text-800 rounded"}
                            onClick={() => setToggle({
                                startDatePopup: false,
                                endDatePopup: true,
                                isShowEndDate: toggle.isShowEndDate
                            })}
                        >
                            {dayjs(addUserEventlyDetail?.payload?.endDateTime)?.tz(timeZone)?.format("dddd, DD-MMM-YYYY")}
                        </button>
                    }
                    {toggle?.endDatePopup && (
                        <div className="p-2">
                            <DatePicker
                                slotProps={{ textField: { variant: "standard", size: "small" } }}
                                format={"dddd, DD-MMM-YYYY"}
                                value={dayjs(addUserEventlyDetail?.payload?.endDateTime)?.tz(timeZone)}
                                disablePast
                                onChange={onHandleSelectEndDate}
                            />
                        </div>
                    )}
                </div>
            </div>
        </LocalizationProvider>
    );
};

export default DateTimeSlots;