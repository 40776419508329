import { useMemo } from "react";

import { formatDistanceToNow } from 'date-fns';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { EDULYTE_API_DOMAIN_URL } from 'const/default.const'

const NotificationCard = ({ notification }) => {

    const isRead = useMemo(() => {
        if (notification.status === 'read') {
            return true
        }
        return false
    }, [notification])

    const handleStatus = async () => {
        try {
            const response = await baseAuthApiInstance().post(
                `${EDULYTE_API_DOMAIN_URL}/v1/notifications/notifications/${notification.id}/read`
            );
            if (response.status === 200) {
                if (response?.data?.data?.result?.action_url) {
                    window.location.href = response?.data?.data?.result?.action_url;
                }
            } else {
                console.error('Failed to mark notification as read');
            }
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const getTimeAgo = () => {
        return formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true });
    };

    return (
        <div
            className={`p-3 font-bodyPri border rounded-lg ${isRead ? 'bg-gray-100' : 'bg-white'} cursor-pointer hover:bg-back-ground-light hover:shadow`}
            onClick={handleStatus}
        >
            <div className="flex gap-5 items-center justify-between">
                <div className="flex gap-5">
                    {!isRead && <div className="h-2 w-2 bg-blue-500 rounded-full"></div>}
                    {/* <div className={`text-sm font-semibold ${isRead ? 'text-gray-600' : 'text-orange-500'}`}>
                        {isRead ? 'Read' : 'New'}
                    </div> */}
                </div>
                <div className="text-sm text-text-700 line-clamp-2 w-full">{notification.message}</div>
                <div className="text-xs text-text-500 whitespace-nowrap">{getTimeAgo()}</div>
            </div>
        </div>
    );
};


export default NotificationCard;
