import { useCallback } from 'react';
import { useNavigate, Link } from "react-router-dom";

import { IoMdArrowRoundBack } from "react-icons/io";
import { BsQuestionLg } from 'react-icons/bs';

import ToolTipView from 'components/tooltipView';

import TopTabNavigation from 'pages/auth/edulyteLms/commonComponents/navigations/TopTabNavigation';
import RefreshButton from 'pages/auth/edulyteLms/commonComponents/lmsButtons/RefreshButton';

import { lmsQuestionTabConst } from 'pages/auth/edulyteLms/editLmsPages/editLmsQuestion/editLMSQuestion.data';

import { getLmsQuestionDetail } from 'redux/edulyteLms/lmsQuestion/lmsQuestion.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetLocalTabs, setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';

const QuestionHeader = () => {
    const { modal } = useAppState((state) => state.local)
    const { lmsQuestionDetail } = useAppState((state) => state.lms.lmsQuestion)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onHandleBack = () => {
        if (lmsQuestionDetail.data.question_options?.length > 0) {
            const filteredQuestionOptions = lmsQuestionDetail.data.question_options?.filter((questionOption) => questionOption?.is_correct === true);

            if (!filteredQuestionOptions || filteredQuestionOptions.length === 0) {
                const shouldLeave = window.confirm("Please tick a box to indicate the correct answer(s).");
                if (!shouldLeave) {
                    navigate(-1)
                } else return;
            } else {
                navigate(-1)
            }
        } else {
            navigate(-1)
        }
    }

    const onHandleAddNewQuestion = useCallback(() => {

        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_LMS_QUESTION_MODAL.stateKey]: true
        }))
    }, [modal, lmsQuestionDetail?.data])

    const onHandleRefreshQuestion = () => {
        if (lmsQuestionDetail?.isLoading) return;

        dispatcher(resetLocalTabs())
        dispatcher(getLmsQuestionDetail(lmsQuestionDetail?.data?.id))
    }

    return (
        <>
            <div className={"sticky top-0 z-50 h-16 bg-white flex items-center justify-between drop-shadow-sm px-5"}>
                <div className={"h-10 flex justify-center items-center gap-2"}>
                    <ToolTipView content={"Back"}>
                        <button className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={onHandleBack}>
                            <IoMdArrowRoundBack
                                className={'text-back-ground-dark'}
                                size={25}
                            />
                        </button>
                    </ToolTipView>
                    <Link to={pagesInfo.USER_RESOURCES.pagePath + "/questions"} className={"flex items-center justify-center gap-2  group"}>
                        <BsQuestionLg
                            size={30}
                            className={"text-primary-dark group-hover:text-secondary-dark"}
                        />
                        <p className={'font-bodyPri font-normal text-lg md:text-2xl text-text-900 group-hover:text-secondary-dark group-hover:underline'}>
                            {"Question"}
                        </p>
                    </Link>
                </div>
                <div className={"hidden md:flex items-center"}>
                    <TopTabNavigation tabs={Object.values(lmsQuestionTabConst)} />
                </div>
                <div className={'flex items-center justify-start gap-5'}>
                    <div
                        className={cn(
                            "w-fit px-5 py-1.5 flex items-center justify-center cursor-pointer bg-primary-dark rounded-lg text-center",
                            "font-bodyPri font-normal text-text-50 text-base",
                            "hover:opacity-90"
                        )}
                        onClick={onHandleAddNewQuestion}
                    >
                        <span className={""}>
                            {"Close & Add New"}
                        </span>
                    </div>
                    <RefreshButton onHandleRefreshButton={onHandleRefreshQuestion} />
                </div>
            </div>
            <div className={"flex md:hidden justify-center items-center w-full rounded-lg"}>
                <TopTabNavigation tabs={Object.values(lmsQuestionTabConst)} />
            </div>
        </>
    )
}

export default QuestionHeader;