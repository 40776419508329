import { Link } from "react-router-dom";

import {
    footerData,
    contactAndSocialData,
    coursesAndTutorsData,
    learnersResourcesData,
    socialIconsData
} from "components/footer/footer1/data";
import FooterItems from "components/footer/footer1/components/FooterItems";
import ContactAndSocial from "components/footer/footer1/components/ContactAndSocial";
import SocialMediaIcons from "components/footer/footer1/components/SocialMediaIcons";
import CoursesAndTutors from "components/footer/footer1/components/CoursesAndTutors";
import LearnersResources from "components/footer/footer1/components/LearnersResources";

const copyrightYear = new Date().getFullYear()

const Footer1 = () => {

    return (
        <div className={"grid grid-cols-12 py-20 w-full bg-footer text-white pb-10"}>
            <div className={"col-start-3 col-span-8 md:col-start-2 md:col-span-10 lg:col-start-2 lg:col-span-10 space-y-10"}>
                <div className={"w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 lg:px-16"}>
                    {footerData.map((footerItems, index) => (
                        <FooterItems key={index} footerItems={footerItems} />
                    ))}
                </div>

                <hr className={"bg-text-600 h-[2px] w-full"} />

                <div className={"grid grid-cols-1 md:grid-cols-2 gap-10 lg:px-16"}>
                    <div className={"col-span-1 grid grid-cols-1 md:grid-cols-2 gap-10 w-full"}>
                        {contactAndSocialData?.map((dataLinks, index) => (
                            <ContactAndSocial key={index} dataLinks={dataLinks} />
                        ))}
                    </div>
                    <div className={"col-span-1 flex flex-col items-center justify-start gap-8"}>
                        <span className={"font-bodyPri font-medium text-base tracking-wide"}>
                            {"Follow Edulyte on Social Media"}
                        </span>
                        <div className={"flex flex-row flex-wrap items-center justify-center gap-4"}>
                            {socialIconsData?.map((socialIcons, index) => (
                                <SocialMediaIcons key={index} socialIcons={socialIcons} />
                            ))}
                        </div>
                    </div>
                </div>

                <hr className={"bg-text-600 h-[2px] w-full"} />

                {/* <div className={"w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10"}>
                    {coursesAndTutorsData?.map((dataLinks, index) => (
                        <CoursesAndTutors key={index} dataLinks={dataLinks} />
                    ))}
                </div>

                <hr className={"bg-text-600 h-[2px] w-full"} /> */}

                <div className={"w-full flex flex-col items-start justify-start gap-8 lg:px-16"}>
                    <span className={"font-bodyPri font-medium text-base"}>
                        {"RESOURCES FOR LEARNERS"}
                    </span>
                    <div className={"w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10"}>
                        {learnersResourcesData?.map((dataLinks, index) => (
                            <LearnersResources key={index} dataLinks={dataLinks} />
                        ))}
                    </div>
                </div>

                <hr className={"bg-text-600 h-[2px] w-full"} />

                <div className={"w-full flex items-center justify-center"}>
                    <div className={"w-full flex flex-col items-start justify-start gap-2"}>
                        <div className={"w-full font-bodyPri font-normal text-sm tracking-wide text-center"}>
                            &copy;{` ${copyrightYear} Eduventure Technologies Pvt. Ltd.`}
                        </div>
                        <div className={"w-full flex flex-col items-center justify-center gap-3 md:flex-row md:gap-5 font-bodyPri font-normal text-sm"}>
                            <a className={"hover:opacity-50"} href={"https://www.edulyte.com/terms-of-service/"}>
                                {"Terms & Conditions"}
                            </a>
                            <a className={"hover:opacity-50"} href={"https://www.edulyte.com/privacy-policy/"}>
                                {"Privacy Policy"}
                            </a>
                            <a className={"hover:opacity-50"} href={"https://support.edulyte.com/portal/en/kb/articles/beta-disclaimer"}>
                                {"Beta Disclaimer"}
                            </a>
                            <a className={"hover:opacity-50"} href={"https://www.edulyte.com/disclaimer/"}>
                                {"Disclaimer"}
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Footer1;