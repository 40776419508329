import { useReducer, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import { cn } from "utils/cn.utils";

import {
    Container,
    Section,
    InputBox,
    SelectCheckBox,
    CheckBox,
    BackButton,
    EmailLabel,
    ForgotPasswordSection,
    LogInButton,
} from './LogInFormStyle'

import { BsCheckAll, BsEye, BsEyeSlash } from "react-icons/bs";
import { MdCancel } from "react-icons/md"
import { BiArrowBack } from "react-icons/bi";

import ButtonLoader from 'components/loader/ButtonLoader';
import InputOtp from 'components/inputOtp/InputOtp';

import { createSendOtp } from 'redux/notification/notification.request';
import { createVerifyUser } from 'redux/auth/auth.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setClearSendOtp, setClearVerifyOtp } from 'redux/notification/notification.slice';
import { setAddResetPasswordPayload, setClearAddResetPasswordPayload, resetLogIn, setClearVerifyUser } from 'redux/auth/auth.slice';
import { modalConst } from 'redux/local/local.const';
import { otpDeliveryMethod } from 'redux/notification/notification.const';
import { authenticateWith } from 'redux/auth/auth.const';

import { pagesInfo } from 'utils/pagesInfo';
import { validateEmail, validatePassword } from 'utils/validation-functions';

const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value,
    }
}

const pattern = /[a-zA-z]{1}/;

const LogInForm = ({ formSubmit }) => {
    const { modal } = useAppState((state) => state.local)
    // const { publicOrgDetail } = useAppState((state) => state.org)
    const { logIn, addResetPassword, verifyUser } = useAppState((s) => s.auth)
    const { sendOtp } = useAppState((s) => s.notification)
    const { user } = useAppState((s) => s.user)

    const [formData, setFromData] = useReducer(formReducer, {});
    const [checkLoginType, setCheckLoginType] = useState('otp')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [emailOrPhoneErrorText, setEmailOrPhoneErrorText] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)
    const [isShowPassword, setIsShowPassword] = useState(false)

    const dispatcher = useAppDispatcher()

    const loadingElement = logIn?.isLoading || user?.isLoading

    useEffect(() => {
        const { emailOrPhone, password } = formData;
        if (emailOrPhone && !validateEmail(emailOrPhone)) setEmailOrPhoneErrorText('Enter valid Email!');
        else setEmailOrPhoneErrorText('');
        if (password && !validatePassword(password)?.isRequiredCharValid) setPasswordError("Invalid Password")
        else setPasswordError('');

    }, [formData])

    useEffect(() => {
        if (verifyUser?.verifyUser && checkLoginType === "otp") {
            dispatcher(setClearVerifyUser())
            dispatcher(createSendOtp({
                channel: otpDeliveryMethod?.EMAIL?.value,
                email: formData?.emailOrPhone
            }))
        }
    }, [verifyUser?.verifyUser])

    useEffect(() => {
        if (sendOtp?.sendOtp && checkLoginType === "otp") {
            setIsSubmitted(true)
        }
        if (sendOtp?.errorMsg) {
            setIsSubmitted(false)
        }
    }, [sendOtp?.sendOtp])

    const submitForm = async (event) => {
        event.preventDefault();

        if (logIn?.isLoading || user?.isLoading || sendOtp?.isLoading || verifyUser?.isLoading) return;

        if (!formData.emailOrPhone || emailOrPhoneErrorText) {
            toast.warning("please Enter Valid Email!")
            return;
        }

        if (checkLoginType === authenticateWith?.PASSWORD?.value && !formData.password) {
            toast.warning("please Enter Password")
            return;
        }

        if (checkLoginType === authenticateWith?.PASSWORD?.value && passwordError) {
            toast.warn("Please Enter Valid Password!")
            return;
        }

        if (checkLoginType === authenticateWith?.PASSWORD?.value) {
            formSubmit(formData);
        }
        if (pattern.test(formData.emailOrPhone) && checkLoginType === "otp") {
            dispatcher(createVerifyUser({
                // org_id: publicOrgDetail?.data?.result?.id,
                email: formData.emailOrPhone
            }))
        }
        if (pattern.test(formData.emailOrPhone) && checkLoginType === "verified") {
            if (myOtp?.length < 6) return;
            dispatcher(setClearSendOtp())
            formSubmit({
                emailOrPhone: formData?.emailOrPhone,
                otpId: sendOtp?.sendOtp?.otpId,
                otp: myOtp
            })
        }
    }

    const handleOnChange = (event) => {
        setFromData({
            name: event.target.name,
            value: event.target.value,
        })
    }

    const handleForgetPassword = () => {
        dispatcher(setClearAddResetPasswordPayload())
        dispatcher(setClearSendOtp())
        dispatcher(setClearVerifyOtp())
        dispatcher(setClearVerifyUser())
        dispatcher(setAddResetPasswordPayload({
            ...addResetPassword?.addResetPasswordPayload,
            email: formData?.emailOrPhone
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.FORGET_PASSWORD_MODAL.stateKey]: true
        }))
    }

    if (isSubmitted) {
        return (
            <Container onSubmit={submitForm}>
                <BackButton onClick={() => {
                    setMyOtp("");
                    setIsOtpError(false)
                    setCheckLoginType("otp")
                    setIsSubmitted(false)
                }}>
                    <BiArrowBack />
                    <EmailLabel>
                        <span>
                            {"Email: "}
                        </span>
                        <span>
                            {formData.emailOrPhone}
                        </span>
                        <BsCheckAll className='text-green-500' />
                    </EmailLabel>
                </BackButton>
                <InputOtp
                    email={formData.emailOrPhone}
                    otpLength={6}
                    setMyOtp={setMyOtp}
                    isOtpError={isOtpError}
                    setIsOtpError={setIsOtpError}
                />
                <LogInButton type={'submit'} onClick={() => setCheckLoginType("verified")}>
                    {!loadingElement &&
                        <div className={" whitespace-nowrap px-5"}>
                            {'Verify Code'}
                        </div>
                    }
                    {loadingElement &&
                        <div className={""}>
                            <ButtonLoader isLoading={loadingElement} />
                        </div>
                    }
                </LogInButton>
                {logIn?.message &&
                    <span className={"w-full text-center font-bodyPri font-normal text-red-500 text-sm"}>
                        {logIn?.message}
                    </span>
                }
            </Container>
        )
    }

    return (
        <Container onSubmit={submitForm}>
            <Section>
                <InputBox type='text' name='emailOrPhone' placeholder='Enter your email' onChange={handleOnChange} value={formData.emailOrPhone} autoFocus={true} required />
                {formData.emailOrPhone && emailOrPhoneErrorText.length === 0 && <BsCheckAll className='text-green-500' />}
                {formData.emailOrPhone && emailOrPhoneErrorText.length > 0 && <MdCancel className=' text-base font-bodyPri text-red-800' />}
            </Section>
            <SelectCheckBox>
                <CheckBox onClick={() => {
                    dispatcher(resetLogIn())
                    setCheckLoginType("otp")
                }}>
                    <input
                        type="radio"
                        name="status"
                        checked={checkLoginType === "otp"}
                        onClick={() => {
                            dispatcher(resetLogIn())
                            setCheckLoginType("otp")
                        }}
                    />
                    <label
                        htmlFor='otp'
                        className={"font-bodyPri font-normal text-text-900 text-base tracking-wide cursor-pointer"}
                    >
                        {"Email a code"}
                    </label>
                </CheckBox>
                <CheckBox
                    onClick={() => {
                        dispatcher(resetLogIn())
                        setCheckLoginType("password")
                    }}
                >
                    <input
                        type="radio"
                        name="status"
                        checked={checkLoginType === "password"}
                        onClick={() => { setCheckLoginType("password") }}
                    />
                    <label
                        htmlFor='password'
                        className={"font-bodyPri font-normal text-text-900 text-base tracking-wide cursor-pointer"}
                    >
                        {"Use password"}
                    </label>
                </CheckBox>
            </SelectCheckBox>
            {(checkLoginType === "password") &&
                <>
                    <Section>
                        <InputBox
                            type={isShowPassword ? "text" : 'password'}
                            name={'password'}
                            placeholder={'Password'}
                            onChange={handleOnChange}
                            required
                        />
                        {isShowPassword &&
                            <span className={""} onClick={() => setIsShowPassword(false)}>
                                <BsEye className={"text-text-800 text-lg cursor-pointer"} />
                            </span>
                        }
                        {!isShowPassword &&
                            <span className={""} onClick={() => setIsShowPassword(true)}>
                                <BsEyeSlash className={"text-text-800 text-lg cursor-pointer"} />
                            </span>
                        }
                        {formData.password && (passwordError.length === 0) && <BsCheckAll className='text-green-500' />}
                        {formData.password && (passwordError.length > 0) && <MdCancel className=' text-base font-bodyPri text-red-800' />}
                    </Section>
                    <ForgotPasswordSection to={'#'} onClick={handleForgetPassword}>
                        {'Forgot Password?'}
                    </ForgotPasswordSection>
                    <LogInButton
                        type='submit'
                    >
                        {!loadingElement &&
                            <div className={cn(
                                " whitespace-nowrap px-5",
                            )}>
                                {'Log in'}
                            </div>
                        }
                        {loadingElement &&
                            <div className={""}>
                                <ButtonLoader isLoading={loadingElement} />
                            </div>
                        }
                    </LogInButton>
                    {logIn?.message &&
                        <span className={"w-full text-center font-bodyPri font-normal text-red-500 text-sm"}>
                            {logIn?.message}
                        </span>
                    }
                </>
            }
            {(checkLoginType === "otp") &&
                <>
                    <LogInButton type='submit'>
                        {(verifyUser?.isLoading || sendOtp?.isLoading) &&
                            <ButtonLoader isLoading={verifyUser?.isLoading || sendOtp?.isLoading} />
                        }
                        {(!verifyUser?.isLoading && !sendOtp?.isLoading) && 'Send Code'}
                    </LogInButton>
                    {verifyUser?.errorMsg &&
                        <div className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                            {"We could not find your account with this email. "}
                            <span className={"text-text-900"}>
                                {"Please"}
                            </span>
                            <Link
                                to={pagesInfo?.SIGN_UP?.pagePath}
                                className={"text-primary-main cursor-pointer"}
                            >
                                {" Sign Up "}
                            </Link>
                            <span className={"text-text-900"}>
                                {" Or "}
                            </span>
                            <a
                                href={"https://www.edulyte.com/contact-us/"}
                                target={"_blank"}
                                className={"text-primary-main cursor-pointer"}
                            >
                                {"contact us"}
                            </a>
                        </div>
                    }
                    {logIn?.message &&
                        <span className={"w-full text-center font-bodyPri font-normal text-red-500 text-sm"}>
                            {logIn?.message}
                        </span>
                    }
                </>
            }
        </Container>
    )
};

export default LogInForm;