import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageContentStyle } from './TeachWithUsPageStyle';

import TeachWithUsHero from './teachWithUsHero/TeachWithUsHero';
import TeachWithUsFeatured from './teachWithUsFeatured/TeachWithUsFeatured';
import BenifitsOfOnlineTutoring from './benifitsOfOnlineTutoring/BenifitsOfOnlineTutoring';
import TeachWithUsHowItWork from './teachWithUsHowItWork/TeachWithUsHowItWork';
import TeachWithUsFAQ from './teachWithUsFAQ/TeachWithUsFAQ';
import Footer1 from 'components/footer/footer1/Footer1';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setClearSignUpPayload } from 'redux/auth/auth.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';
import { useTitle } from 'hooks/useTitle';


const TeachWithUsPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { modal } = useAppState((state) => state.local)
    const { session } = useAppState((s) => s.auth)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TEACH_WITH_US))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Apply to Become a Tutor | Edulyte"
        })
    }, [dispatcher, currentPageInfo])


    if (session?.isLoggedIn) {
        navigate(pagesInfo.PRE_ONBOARDING.pagePath, { replace: true });
        dispatcher(setModal({
            ...modal,
            [modalConst.CODE_VERIFICATION_MODAL.stateKey]: false
        }))
        dispatcher(setClearSignUpPayload())
    }

    return (
        <PageContentStyle>
            <TeachWithUsHero />
            <TeachWithUsFeatured />
            <BenifitsOfOnlineTutoring />
            <TeachWithUsHowItWork />
            <TeachWithUsFAQ />
            <Footer1 />
        </PageContentStyle>
    )
}

export default TeachWithUsPage;
