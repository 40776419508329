import { useState } from 'react';

import { IoIosArrowForward } from 'react-icons/io';

import { createSessionLog } from 'components/modals/sessionDetailModal/sessionDetailModal.data';

import LogTimeLine from 'components/logTimeLine/LogTimeLine';

import { useAppState } from 'hooks/useStore';

import { cn } from "utils/cn.utils";

const SessionDetailSessionLog = () => {
    const { sessionUserDetail } = useAppState(s => s.session)

    const [isShow, setIsShow] = useState(false)

    return !!sessionUserDetail?.data?.result?.session_logs?.length ? (
        <div className={"space-y-3"}>
            <div onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 px-3 py-1 cursor-pointer rounded-lg hover:bg-back-ground-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Session Logs"}
                </span>
                <IoIosArrowForward className={cn(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={cn(
                isShow
                    ? "flex flex-col gap-3 px-5 transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>
                <div className={""}>
                    <LogTimeLine logList={createSessionLog(sessionUserDetail?.data?.result?.session_logs)} />
                </div>
            </div>
        </div>
    ) : null
}

export default SessionDetailSessionLog