import { useNavigate, Link } from 'react-router-dom';

import { IoMdArrowRoundBack } from 'react-icons/io';
import { MdPlayLesson } from 'react-icons/md'

import ToolTipView from 'components/tooltipView';

import TopTabNavigation from 'pages/auth/edulyteLms/commonComponents/navigations/TopTabNavigation';
import RefreshButton from 'pages/auth/edulyteLms/commonComponents/lmsButtons/RefreshButton';
import PublishBtn from 'pages/auth/edulyteLms/commonComponents/lmsButtons/PublishBtn';

import { lmsLectureTabConst } from '../../data';

import { getTutorLmsLectureDetail, updateLmsLectureDetail } from 'redux/edulyteLms/lmsLecture/lmsLecture.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { lmsLectureStatusEnum } from 'redux/edulyteLms/lmsLecture/lmsLecture.const';

import { pagesInfo } from 'utils/pagesInfo';


const LectureHeader = () => {
    const { tutorLmsLectureDetail, modifyLmsLectureDetail } = useAppState((state) => state.lms.lmsLecture)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onHandleBack = () => {
        navigate(-1)
    }

    const onHandleRefreshLecture = () => {
        if (tutorLmsLectureDetail?.isLoading) return;

        dispatcher(getTutorLmsLectureDetail(tutorLmsLectureDetail?.data?.lecture_id))
    }

    const onHandleUpdateStatus = (lectureStatusType) => {
        if (tutorLmsLectureDetail?.isLoading || modifyLmsLectureDetail?.isLoading) return;

        if (lectureStatusType?.value === lmsLectureStatusEnum.DRAFT.value) {
            if (!window.confirm("Are you sure?. You want to save it as draft lecture.")) return;
        }
        if (lectureStatusType?.value === lmsLectureStatusEnum.PUBLISHED.value) {
            if (!window.confirm("Are you sure?. You want to publish this lecture.")) return;
        }
        if (lectureStatusType?.value === lmsLectureStatusEnum.INACTIVE.value) {
            if (!window.confirm("Are you sure?. You want to deactivate this lecture.")) return;
        }
        dispatcher(updateLmsLectureDetail(tutorLmsLectureDetail?.data?.lecture_id, { status: lectureStatusType?.value }))

    }

    return (
        <>
            <div className={"sticky top-0 z-50 h-16 bg-white flex items-center justify-between drop-shadow-sm px-5"}>
                <div className={"h-10 flex justify-center items-center gap-2"}>
                    <ToolTipView content={"Back"}>
                        <button className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={onHandleBack}>
                            <IoMdArrowRoundBack
                                className={'text-back-ground-dark'}
                                size={25}
                            />
                        </button>
                    </ToolTipView>
                    <Link to={`${pagesInfo.TUTOR_PRODUCTS.pagePath}/courses`} className={"flex items-center justify-center gap-2  group"}>
                        <MdPlayLesson
                            size={30}
                            className={"text-primary-dark group-hover:text-secondary-dark"}
                        />
                        <p className={'font-bodyPri font-normal text-lg md:text-2xl text-text-900 group-hover:text-secondary-dark group-hover:underline'}>
                            {"Video Lecture"}
                        </p>
                    </Link>
                </div>
                <div className={"hidden md:flex items-center"}>
                    <TopTabNavigation tabs={Object.values(lmsLectureTabConst)} />
                </div>
                <div className={'flex items-center justify-start gap-5'}>
                    <RefreshButton onHandleRefreshButton={onHandleRefreshLecture} />
                    <PublishBtn
                        isLoading={modifyLmsLectureDetail?.isLoading}
                        loaderBtnClassName={`bg-${lmsLectureStatusEnum[tutorLmsLectureDetail?.data?.lecture_setting?.status?.toUpperCase()]?.darkColor}`}
                        selectedBtnClassName={`text-white bg-${lmsLectureStatusEnum[tutorLmsLectureDetail?.data?.lecture_setting?.status?.toUpperCase()]?.darkColor}`}
                        dropdownBtnClassName={`text-white bg-${lmsLectureStatusEnum[tutorLmsLectureDetail?.data?.lecture_setting?.status?.toUpperCase()]?.darkColor}`}
                        optionsList={Object.values(lmsLectureStatusEnum)?.filter((lectureStatus) => (lectureStatus?.value != tutorLmsLectureDetail?.data?.lecture_setting?.status))}
                        selectedOption={lmsLectureStatusEnum[tutorLmsLectureDetail?.data?.lecture_setting?.status?.toUpperCase()]?.label || lmsLectureStatusEnum.DRAFT.label}
                        onHandleUpdateStatus={onHandleUpdateStatus}
                    />
                </div>
            </div>
            <div className={"flex md:hidden justify-center items-center w-full rounded-lg"}>
                <TopTabNavigation tabs={Object.values(lmsLectureTabConst)} />
            </div>
        </>
    )
}

export default LectureHeader;