import { useEffect, useState } from 'react'
import { Reorder } from "framer-motion";
import { BsInfoCircle } from 'react-icons/bs';
import ToolTipView from 'components/tooltipView';
import { motion } from 'framer-motion';
import LinkTreeCard, { LinkTreeCardLoader } from './linkTreeCard'
import AddLink from './addLink'
import image from 'assets/image/no-item-found.png'

import { getMasterSocialList } from 'redux/master/master.request'
import { getLinkTreeRequest, reorderLink } from 'redux/linkTree/linkTree.request'
import { deleteLink } from 'redux/linkTree/linkTree.request';
import CreateLinkHug from './createLinkHug';

import { useAppState, useAppDispatcher } from 'hooks/useStore'
import { setClearGetLinkTree, setGetLinkTreeData } from 'redux/linkTree/linkTree.slice';
import { isOrderUpdated } from 'utils/generators.utils';
import EditSlug from './editSlug';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';

const LinkInBio = () => {
  const { user } = useAppState((state) => state.user)
  const { modal } = useAppState((s) => s.local)
  const { getLinkTree } = useAppState((state) => state.linkTree)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getLinkTreeRequest({
      params: {
        linkTreeId: user?.user?.linktree_id,
        userId: user?.user?.userId
      }
    }))
    return () => dispatcher(setClearGetLinkTree())
  }, [user?.user?.linktree_id])

  useEffect(() => {
    dispatcher(getMasterSocialList())
  }, [])

  const [isLinkOrderUpdated, setIsLinkOrderUpdated] = useState(getLinkTree?.data?.result?.links || [])

  const handleDeleteLink = (id) => {
    dispatcher(deleteLink(id));
  };

  const handleReorder = (newOrder) => {
    if (newOrder) {
      const isOrderModified = isOrderUpdated(getLinkTree?.data?.result?.links, newOrder)
      if (!isOrderModified) {
        setIsLinkOrderUpdated(false)
        return;
      }
      setIsLinkOrderUpdated(true)
      dispatcher(setGetLinkTreeData({
        ...getLinkTree?.data, result: {
          ...getLinkTree?.data.result, links: newOrder
        }
      }))
    }
  };

  const onHandleUpdateReorder = () => {
    if (isLinkOrderUpdated && (getLinkTree?.data?.result?.links?.length > 1)) {
      const reorderedPayload = { order: getLinkTree?.data?.result?.links.map((link) => ({ id: link.id })) };
      dispatcher(reorderLink(getLinkTree?.data?.result?.id, reorderedPayload));
      setIsLinkOrderUpdated(false)
    }
  }

  const handleCreateLinks = () => {
    dispatcher(setModal({
      ...modal,
      [modalConst.CREATE_LINK_HUG_MODAL.key]: {
        ...modal[modalConst.CREATE_LINK_HUG_MODAL.key],
        isVisible: true,
        title: "Create Unique Link",
      }
    }))
  }

  return (
    <>
      {user?.user?.linktree_id ? (
        <div className={'space-y-5 p-0 md:p-5 border rounded'}>
          <div className='flex flex-col items-center'>
            <h2 className='font-medium text-center text-xl font-heading'>Your Default Link</h2>
            <motion.button
              whileHover={{ scale: 1.095 }}
              whileTap={{ scale: 0.9 }}
              onClick={handleCreateLinks}
              className='flex items-center gap-2 px-4 py-2 text-sm  text-text-600 hover:text-secondary-dark ease-in-out duration-200'
            >
              <span>Add New Link</span>
            </motion.button>
          </div>
          <EditSlug linkTree={getLinkTree?.data?.result} />
          <div className={"flex items-center justify-start gap-2"}>
            <ToolTipView content={"Add your important links here to display them on your public profile."}>
              <span className={"inline text-md text-text-900 hover:text-text-900 cursor-pointer"}><BsInfoCircle /></span>
            </ToolTipView>
            <h1 className='font-medium text-text-700'>
              {"Add Your Links"}
            </h1>
          </div>
          <div className='static lg:sticky top-10 z-10 pb-5 pt-2 bg-white'>
            <AddLink id={getLinkTree?.data?.result?.id} />
          </div>
          {getLinkTree?.isLoading ? (
            <>
              {new Array(10).fill("").map((_, index) => (
                <div className='space-y-5'>
                  <LinkTreeCardLoader />
                </div>
              ))}
            </>
          ) : (
            <Reorder.Group className='space-y-5' axis="y" values={getLinkTree?.data?.result?.links || []} onReorder={handleReorder}>
              {getLinkTree?.data?.result?.links.map((item) => (
                <Reorder.Item key={item.id} value={item} onDragEnd={onHandleUpdateReorder}>
                  <LinkTreeCard link={item} onDelete={handleDeleteLink} />
                </Reorder.Item>
              ))}
            </Reorder.Group>
          )}
          {getLinkTree?.data?.result?.links?.length === 0 && (
            <div className='space-y-5 text-sm text-text-600'>
              <img
                className='h-32 aspect-video'
                alt='no item found'
                src={image}
              />
              <p>No Link Added Yet</p>
            </div>
          )}
        </div>
      ) : (
        <CreateLinkHug />
      )}
    </>
  )
}

export default LinkInBio
