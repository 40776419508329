import { ownerTypeEnum, uploadFileTypeEnum } from "redux/storage/storage.const";

export const generateFormData = async ({ files, owner_type = ownerTypeEnum.user.key, user_id = null, fileType = uploadFileTypeEnum?.MEDIA?.value }) => {

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
    }
    formData.append("type", fileType)
    formData.append("owner_type", owner_type)
    if ([ownerTypeEnum.user.key]?.includes(owner_type) && !!user_id) {
        formData.append("user_id", user_id)
    }

    return formData;
}