import { cn } from "utils/cn.utils";

import { MdOutlineLocationOn } from 'react-icons/md';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddUserEventlyDetailPayload } from 'redux/evently/evently.slice';

const EventlyLocation = () => {
    const { addUserEventlyDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()

    const onHandleAddLocation = () => {
        dispatcher(setAddUserEventlyDetailPayload({
            ...addUserEventlyDetail?.payload,
            isShowLocationList: true
        }))
    }

    const onHandleChangeLocation = (event) => {
        dispatcher(setAddUserEventlyDetailPayload({
            ...addUserEventlyDetail?.payload,
            selectedLocation: event?.target?.value
        }))
    }

    return (
        <div className={"w-full grid grid-cols-12 gap-x-2 px-5"}>
            <div className={"col-start-1 col-span-1 self-center"}>
                <MdOutlineLocationOn className={"text-text-700 text-xl"} />
            </div>
            {!addUserEventlyDetail?.payload?.isShowLocationList &&
                <div
                    className={"col-start-2 col-span-full self-center p-2 hover:bg-back-ground-lightBlue cursor-pointer"}
                    onClick={onHandleAddLocation}
                >
                    <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                        {"Add location"}
                    </span>
                </div>
            }
            {addUserEventlyDetail?.payload?.isShowLocationList &&
                <div className={"col-start-2 col-span-full self-center"}>
                    <input
                        type={"text"}
                        className={cn(
                            "flex w-full p-2 focus:outline-none bg-back-ground-light border-b focus:border-primary-main",
                            "font-bodyPri font-normal text-text-900 text-base placeholder:text-text-700 capitalize"
                        )}
                        placeholder={"Enter location"}
                        value={addUserEventlyDetail?.payload?.selectedLocation}
                        onChange={onHandleChangeLocation}
                        autoFocus={true}
                    />
                </div>
            }
        </div>
    )
}

export default EventlyLocation;