import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { pagesInfo } from 'utils/pagesInfo';

import { useAppState } from 'hooks/useStore';
import { GOOGLE_SITE_KEY } from 'const/default.const';
import { useEffect, useState } from 'react';

type Props = {
  children: React.ReactNode
}

const ReCaptchaProvider = ({ children }: Props) => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const [isMount, setIsMount] = useState(false)

  useEffect(() => {
    setIsMount(true)
  }, [])

  if (!isMount) {
    return null
  }

  if (googleReCaptchaPageName?.includes(currentPageInfo?.pageName)) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={GOOGLE_SITE_KEY || ""}
        scriptProps={{
          async: false,
          defer: true,
          appendTo: 'head',
          nonce: undefined
        }}>
        {children}
      </GoogleReCaptchaProvider>
    )
  }

  return (
    <>{children}</>
  );
}

export default ReCaptchaProvider;



export const googleReCaptchaPageName = [
  pagesInfo.LOG_IN.pageName,
  pagesInfo.SIGN_UP.pageName,
  pagesInfo.TEACH_WITH_US.pageName,
  pagesInfo.HOME.pageName,
  pagesInfo.PROMO_SIGN_UP.pageName,
  pagesInfo.GENERAL_SETTINGS.pageName,
  pagesInfo.RESET_PASSWORD.pageName,
  pagesInfo.FEATURE_GET_YOUR_ACCOUNT.pageName,
]