import { useEffect, useCallback } from 'react';

import { cn } from "utils/cn.utils";

import ComponentLoader from 'components/loader/ComponentLoader';
import Pagination from 'components/pagination/Pagination';

import LibraryActionButtons from 'components/modals/lmsModals/attachLibraryModal/components/LibraryActionButtons';
import LMSTable from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTable';

import { myFilesTableHeaderConst, uploadLmsMediaConst } from '../../data';
import MyFilesMembers from './components/MyFilesMembers';

import { getFileList, viewFileDetail } from 'redux/drive/drive.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setLocalTabs } from 'redux/local/local.slice';
import { resetFileList } from 'redux/drive/drive.slice';
import { fileExtensionEnum } from 'redux/drive/drive.const';

import { formatFileSize, removeFileExtensionFromFileName } from 'utils/generators.utils';
import { timeZone, dayjs } from 'utils/dateTime.utils';

const MyFiles = () => {
  const { localTabs } = useAppState((state) => state.local)
  const { fileList, fileView } = useAppState((state) => state.drive)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getFileList({ page: 1, records: 5 }))

    return () => {
      dispatcher(resetFileList())
    }
  }, [])

  const onHandleUploadNewFile = () => {
    dispatcher(setLocalTabs({
      ...localTabs,
      currentActiveTab: uploadLmsMediaConst.UPLOAD.value
    }))
  }

  const onHandleInsertFile = useCallback((fileItem) => {
    if (!fileItem?.id || !fileItem?.file_name) return;

    dispatcher(setLocalTabs({
      ...localTabs,
      tabItem: {
        ...localTabs?.tabItem,
        tabType: uploadLmsMediaConst.MY_FILES.value,
        payload: {
          ...localTabs?.tabItem?.payload,
          fileId: fileItem?.id,
          fileName: removeFileExtensionFromFileName(fileItem?.file_name)
        }
      }
    }))
  }, [localTabs])

  const sortFileList = useCallback((file1, file2) => {
    const file1StartTime = dayjs(file1?.created_at).utc()
    const file2StartTime = dayjs(file2?.created_at).utc()
    return file1StartTime.isSameOrBefore(file2StartTime) ? 1 : -1
  }, [])

  const onHandleChangePage = useCallback((page) => {
    if (fileList?.isLoading) return;

    dispatcher(getFileList({ page: page, records: 5 }))
  }, [])

  const onHandleOpenFile = async (fileId) => {
    if (fileView?.isLoading) return;

    const response = await dispatcher(viewFileDetail(fileId));
    const fileUrl = response.data.file_url;
    window.open(fileUrl, "_blank")
  }

  const myDriveFilesRows = (fileList?.data && fileList?.data?.results?.length > 0) && fileList?.data?.results?.slice(0)?.sort(sortFileList)?.map((fileItems, index) => [
    <div key={index} className="flex items-center justify-start gap-2">
      <div className={cn(
        `w-[35px] h-[35px] rounded-full flex items-center justify-center`,
        fileExtensionEnum[fileItems?.file_name?.split(".")?.pop()?.toUpperCase()]
          ? `bg-${fileExtensionEnum[fileItems?.file_name?.split(".")?.pop()?.toUpperCase()]?.darkColor}`
          : "bg-orange-500"
      )}>
        <p className={`font-bodyPri font-normal text-text-50 text-xs text-center m-0`}>
          {fileItems?.file_name.split(".").pop()?.toLowerCase()}
        </p>
      </div>
      <div
        onClick={() => onHandleOpenFile(fileItems?.id)}
        className={"font-bodyPri font-bold text-primary-dark text-sm tracking-wide text-left cursor-pointer hover:underline"}
      >
        {(fileItems?.file_name?.length > 16)
          ? fileItems?.file_name?.slice(0, 16) + " ..."
          : fileItems?.file_name
        }
      </div>
    </div> || "-",
    <MyFilesMembers
      sharedWithUsers={fileItems?.shared_with}
      sharedByUser={fileItems?.shared_by}
    />,
    formatFileSize(fileItems?.file_size) || "-",
    fileItems?.created_at
      ? dayjs(fileItems?.created_at)?.tz(timeZone)?.format("dddd, DD MMM YYYY ")
      : "-",
    <LibraryActionButtons
      isShowViewBtn={true}
      isShowEditBtn={false}
      viewBtnTooltip={"View Resource"}
      insertBtnTooltip={"Insert Resource"}
      isEnterBtnDisabled={!fileItems?.id || !fileItems?.file_name}
      onHandleViewBtn={() => onHandleOpenFile(fileItems?.id)}
      onHandleInsertBtn={() => onHandleInsertFile(fileItems)}
    />,
  ])

  return (
    <div className={"flex flex-col w-full px-5 py-3 bg-white gap-2"}>
      <div className={"w-full flex items-center justify-between gap-3"}>
        <span className={"font-bodyPri font-medium text-text-800 text-md tracking-wide underline"}>
          {"List of Files"}
        </span>
        <button
          className={cn(
            'w-fit px-5 py-1 flex items-center justify-center border border-back-ground-black rounded ease-in-out duration-200 group cursor-pointer',
            'hover:bg-secondary-dark hover:text-white hover:border-secondary-dark'
          )}
          onClick={onHandleUploadNewFile}
        >
          <span className={"font-bodyPri font-normal text-text-800 text-base group-hover:text-white"}>
            {"Upload new file"}
          </span>
        </button>
      </div>

      {fileList?.isLoading &&
        <ComponentLoader isLoading={fileList?.isLoading} className={"w-full"} />
      }

      {(!fileList?.isLoading && fileList?.data) &&
        <LMSTable
          headers={myFilesTableHeaderConst}
          rows={myDriveFilesRows}
          alternateRowColor={"bg-sky-100"}
          rowHeaderColor={"bg-sky-200"}
        />
      }
      {(!fileList?.isLoading && (fileList?.data?.totalPages > 1)) &&
        <div className={'w-full flex items-center justify-center'}>
          <Pagination
            page={fileList?.data?.page}
            totalPages={fileList?.data?.totalPages}
            onChangePage={(page) => onHandleChangePage(page)}
            isScrollToTop={false}
          />
        </div>
      }

      {fileList?.message &&
        <div className={"flex items-center justify-center"}>
          <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
            {fileList?.message}
          </span>
        </div>
      }
    </div>
  )
}

export default MyFiles;