import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';

import CreateCourseTooltip from 'pages/auth/createCourse/CreateCourseTooltip';
import { ArrowToggleIcon, CollapsableContainer } from 'pages/auth/createCourse/createCourse.style';
import { courseDescriptionToolTip, inputSections } from "pages/auth/createCourse/data";

import { updateCourseDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { courseStatus } from 'redux/course/course.const';
import SmallCourseBtn from 'pages/auth/createCourse/SmallCourseBtn';

const CourseDescriptionSection = ({ clickedItem, setClickedItem }) => {
    const { tutorCourseDetail, addCourseDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [isAddCourseDescriptionEnabled, setIsAddCourseDescriptionEnabled] = useState(tutorCourseDetail?.data?.description ? true : false)
    const [content, setContent] = useState(tutorCourseDetail?.data?.description)


    const isOpen = inputSections?.COURSE_DESCRIPTION?.value === clickedItem;

    const isCourseDescriptionSimilar = JSON.stringify(content) === JSON.stringify(tutorCourseDetail?.data?.description)

    const underReviewOrPublishedStatus = [courseStatus?.UNDER_REVIEW?.value, courseStatus?.PUBLISHED?.value]?.includes(tutorCourseDetail?.data?.status)

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const answerVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    }

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const Editor = {
        modules: {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                [{ 'script': 'sub' }, { 'script': 'super' }],
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image', 'video'],
                [{ 'color': [] }, { 'background': [] }],
                ['clean']
            ],
            clipboard: {
                matchVisual: false,
            }
        },
        theme: 'snow',
        formats: [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video',
            'color', 'background'
        ]
    }

    const onHandleToggle = () => {
        if (!clickedItem || (clickedItem !== inputSections?.COURSE_DESCRIPTION?.value)) {
            setClickedItem(inputSections?.COURSE_DESCRIPTION?.value)
        } else {
            setClickedItem(null)
            setIsAddCourseDescriptionEnabled(false)
        }
    }

    const onHandleSave = () => {
        if (isCourseDescriptionSimilar || addCourseDetail?.isLoading) return;

        if (underReviewOrPublishedStatus) return;

        dispatcher(updateCourseDetail(
            tutorCourseDetail?.data?.id,
            { description: content }
        ))
    }

    return (
        <div className={"w-full h-full select-none"}>
            <div
                className={"flex flex-row items-center justify-between px-5 py-3 bg-back-ground-darkLight rounded-lg cursor-pointer hover:shadow-all-md"}
                onClick={onHandleToggle}
            >

                <div className={"font-bodyPri font-medium text-xl text-text-900"}>
                    {"Description"}
                </div>
                <ArrowToggleIcon
                    variants={arrowToggleVariants}
                    initial={'collapsed'}
                    animate={isOpen ? 'open' : 'collapsed'}
                    transition={transition}
                />
            </div>

            <CollapsableContainer
                isOpen={isOpen}
                variants={answerVariants}
                initial={'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                transition={transition}
            >
                {!tutorCourseDetail?.data?.description &&
                    <div className={cn(
                        "w-full px-3 py-3 my-2 flex items-center justify-center border border-dashed border-text-500 rounded-lg",
                        "font-bodyPri font-normal text-text-900 cursor-pointer"
                    )}
                        onClick={() => setIsAddCourseDescriptionEnabled(true)}
                    >
                        <MdAdd className={"text-xl text-text-900"} />
                        <span className={"text-xl"}>
                            {"Add Description"}
                        </span>
                    </div>
                }
                {(tutorCourseDetail?.data?.description || isAddCourseDescriptionEnabled) &&
                    <div className={"w-full h-full flex flex-col items-start justify-start space-y-5"}>
                        <div className={"flex items-start justify-start gap-1"}>
                            <CreateCourseTooltip tooltip={courseDescriptionToolTip} translateX={0} translateY={0} />

                            <div className={cn(
                                "w-full min-h-[24rem] border border-dashed border-text-500 rounded-lg",
                                "font-bodyPri font-normal text-text-900"
                            )}>
                                <ReactQuill
                                    id={"editor"}
                                    placeholder={"Write something..."}
                                    modules={Editor.modules}
                                    formats={Editor.formats}
                                    theme="snow"
                                    readOnly={underReviewOrPublishedStatus ? true : false}
                                    value={content}
                                    onChange={setContent}
                                />
                            </div>
                        </div>
                        <SmallCourseBtn
                            isLoading={addCourseDetail?.isLoading}
                            isShowCancelBtn={true}
                            isCancelBtnDisabled={isCourseDescriptionSimilar || underReviewOrPublishedStatus}
                            isSaveBtnDisabled={isCourseDescriptionSimilar || underReviewOrPublishedStatus}
                            onSave={onHandleSave}
                            onCancel={() => {
                                if (isCourseDescriptionSimilar || underReviewOrPublishedStatus || addCourseDetail?.isLoading) return;
                                if (!isCourseDescriptionSimilar) {
                                    setContent(tutorCourseDetail?.data?.description)
                                }
                            }}
                        />
                    </div>
                }
            </CollapsableContainer>
        </div>
    )
}

export default CourseDescriptionSection;