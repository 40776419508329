import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PageLoader from 'components/loader/PageLoader';

import { PageContentStyle } from './EditEvently.style';
import EventlyTitleActions from './components/EventlyTitleActions';
import EventlySchedule from './components/EventlySchedule';
import EventlyDetail from './components/EventlyDetail';
import EventlyCtaButtons from './commonComponents/EventlyCtaButtons';
import { JOINED_STATUS } from './data';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getUserEventlyDetail } from 'redux/evently/evently.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetDestroyUserEventlyDetail, resetUserEventlyDetail, setModifyUserEventlyDetailPayload } from 'redux/evently/evently.slice';
import { modifyUserEventlyDetailPayload } from 'redux/evently/evently.const';

import { pagesInfo } from 'utils/pagesInfo';
import { useTitle } from 'hooks/useTitle';

const EditEventlyPage = () => {
    const { currentPageInfo } = useAppState(state => state.pageInfo)
    const { userEventlyDetail, destroyUserEventlyDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const { eventlyId } = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_EVENTLY))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Edulyte Event | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (eventlyId) {
            dispatcher(getUserEventlyDetail(eventlyId))
        }

        return () => {
            dispatcher(resetUserEventlyDetail())
        }
    }, [eventlyId])

    useEffect(() => {
        if (userEventlyDetail?.data) {
            dispatcher(setModifyUserEventlyDetailPayload({
                ...modifyUserEventlyDetailPayload,
                title: userEventlyDetail?.data?.title,
                eventlyEvents: userEventlyDetail?.data?.evently_events,
                selectedGuestsList: userEventlyDetail?.data?.evently_users?.map((eventlyUser) => ({
                    id: eventlyUser?.id,
                    name: eventlyUser?.user?.firstName
                        ? `${eventlyUser?.user?.firstName} ${eventlyUser?.user?.lastName?.charAt(0)?.toUpperCase()}.`
                        : eventlyUser?.user?.email,
                    profilePicUrl: eventlyUser?.user?.profilePicUrl,
                    email: eventlyUser?.user?.email,
                    isPlatformUser: true,
                    status: JOINED_STATUS
                })),
                isSendEmail: userEventlyDetail?.data?.isSendEmail,
                meetingLink: userEventlyDetail?.data?.link,
                selectedLocation: userEventlyDetail?.data?.location,
                description: userEventlyDetail?.data?.description,
                product: userEventlyDetail?.data?.product_evently
            }))
        }
    }, [userEventlyDetail?.data])

    useEffect(() => {
        if (destroyUserEventlyDetail?.data) {
            dispatcher(resetDestroyUserEventlyDetail())
            navigate(location.state?.from?.url || pagesInfo.SCHEDULE.pagePath)
        }
    }, [destroyUserEventlyDetail?.data])

    if (userEventlyDetail?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (userEventlyDetail?.message) {
        return (
            <div className={"w-screen h-screen flex items-center justify-center"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {userEventlyDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <PageContentStyle>
            <div className={"h-screen bg-white"}>
                <div className={"w-full p-3 md:p-5 flex flex-col gap-y-5 md:gap-y-8"}>
                    <EventlyTitleActions />
                    <EventlySchedule />
                    <EventlyDetail />
                    <div className={"flex md:hidden px-5 pt-5"}>
                        <EventlyCtaButtons />
                    </div>
                </div>
            </div>
        </PageContentStyle>
    )
}

export default EditEventlyPage;