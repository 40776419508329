export const ORG_INITIAL_STATE = {
    publicOrgDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    orgThemeDetail: {
        isLoading: false,
        data: null,
        message: null
    },
    orgDomainDetail: {
        isLoading: false,
        data: null,
    }
}