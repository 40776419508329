import { useEffect, useCallback } from 'react';

import { cn } from "utils/cn.utils";

import ComponentLoader from 'components/loader/ComponentLoader';
import StatusButton from 'components/common-components/StatusButton';
import Pagination from 'components/pagination/Pagination';

import { libraryTabConst, lectureTableHeaderConst } from 'components/modals/lmsModals/attachLibraryModal/data';
import LMSTable from "pages/auth/edulyteLms/commonComponents/tableComponent/LMSTable";

import LibraryActionButtons from '../components/LibraryActionButtons';

import { getTutorLmsLectureList } from 'redux/edulyteLms/lmsLecture/lmsLecture.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setLocalTabs, setModal } from 'redux/local/local.slice';
import { resetTutorLmsLectureList } from 'redux/edulyteLms/lmsLecture/lmsLecture.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsLectureStatusEnum } from 'redux/edulyteLms/lmsLecture/lmsLecture.const';
import { lmsResourceTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

import { pagesInfo } from 'utils/pagesInfo';
import { timeZone, dayjs } from 'utils/dateTime.utils';

const LectureTab = () => {
  const { modal, localTabs } = useAppState((state) => state.local)
  const { tutorLmsLectureList } = useAppState((state) => state.lms.lmsLecture)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getTutorLmsLectureList({ page: 1, records: 5 }))

    return () => {
      dispatcher(resetTutorLmsLectureList())
    }
  }, [])

  const onHandleCreateLectureVideo = () => {
    dispatcher(setModal({
      ...modal,
      [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false,
      [modalConst.CREATE_LMS_LECTURE_MODAL.stateKey]: true
    }))
  }

  const onHandleEditLectureVideo = useCallback((lectureItem) => {
    window.open(`${window.location.host}${pagesInfo.EDIT_LMS_LECTURE.pagePath}/${lectureItem?.lecture_id}/edit`, "_self")
  }, [])

  const onHandleInsertLectureVideo = useCallback((lectureItem) => {
    if (![lmsLectureStatusEnum?.PUBLISHED?.value]?.includes(lectureItem?.lecture_setting?.status) || !lectureItem?.title || ((lectureItem?.resource?.type === lmsResourceTypeEnum.YOUTUBE.value) && !lectureItem?.resource?.resource?.resource_link)) return;

    dispatcher(setLocalTabs({
      ...localTabs,
      tabItem: {
        ...localTabs?.tabItem,
        tabType: libraryTabConst.LECTURES.value,
        payload: {
          ...localTabs?.tabItem?.payload,
          lectureId: lectureItem?.lecture_id
        }
      }
    }))
  }, [localTabs])

  const onHandlePageChange = useCallback((page) => {
    if (tutorLmsLectureList?.isLoading) return;

    dispatcher(getTutorLmsLectureList({ page: page, records: 5 }))

  }, [])


  const VideoTitleContainer = ({ video }) => {
    return (
      <div
        className={'flex flex-col items-start justify-start gap-1'}
        title={video?.title && video?.title}
      >
        <span className='font-bodyPri font-normal text-text-900 text-base truncate tracking-wide'>
          {video?.title
            ? (video?.title?.length > 20)
              ? video?.title?.slice(0, 20) + " ..."
              : video?.title
            : "-"
          }
        </span>
      </div>
    )
  }

  const tableRowsConst = tutorLmsLectureList?.data?.results?.map((video) => ([
    <VideoTitleContainer video={video} />,
    <StatusButton
      status={video?.lecture_setting?.status}
      className={`bg-${lmsLectureStatusEnum[video?.lecture_setting?.status?.toUpperCase()]?.lightColor} text-${lmsLectureStatusEnum[video?.lecture_setting?.status?.toUpperCase()]?.darkColor}`}
    />,
    dayjs(video?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY'),
    <LibraryActionButtons
      editBtnTooltip={"Edit Lecture"}
      insertBtnTooltip={"Insert Lecture"}
      isEnterBtnDisabled={![lmsLectureStatusEnum?.PUBLISHED?.value]?.includes(video?.lecture_setting?.status) || !video?.title || ((video?.resource?.type === lmsResourceTypeEnum.YOUTUBE.value) && !video?.resource?.resource?.resource_link)}
      onHandleEditBtn={() => onHandleEditLectureVideo(video)}
      onHandleInsertBtn={() => onHandleInsertLectureVideo(video)}
    />,
  ]))

  return (
    <div className={"flex flex-col w-full px-5 py-3 bg-white gap-2"}>
      <div className='flex items-center justify-between gap-3'>
        <span className={"font-bodyPri font-medium text-text-800 text-md tracking-wide"}>
          {"List of Lectures"}
        </span>
        <div
          className={cn(
            'w-fit px-5 py-1 flex items-center justify-center border border-back-ground-black rounded ease-in-out duration-200 group cursor-pointer',
            'hover:bg-secondary-dark hover:text-white hover:border-secondary-dark'
          )}
          onClick={onHandleCreateLectureVideo}
        >
          <span className={"font-bodyPri font-normal text-text-800 text-base group-hover:text-white"}>
            {"Create new lecture"}
          </span>
        </div>

      </div>
      {tutorLmsLectureList?.isLoading &&
        <ComponentLoader isLoading={tutorLmsLectureList?.isLoading} className={"w-full"} />
      }
      {tutorLmsLectureList?.message &&
        <div className={"flex items-center justify-center"}>
          <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
            {tutorLmsLectureList?.message}
          </span>
        </div>
      }
      {(!tutorLmsLectureList?.isLoading && tutorLmsLectureList?.data) &&
        <LMSTable
          headers={lectureTableHeaderConst}
          rows={tableRowsConst}
          alternateRowColor={"bg-sky-100"}
          rowHeaderColor={"bg-sky-200"}
        />
      }
      {(!tutorLmsLectureList?.isLoading && (tutorLmsLectureList?.data?.records > 0) && (tutorLmsLectureList?.data?.totalPages > 1)) &&
        <div className={'w-full flex items-center justify-center'}>
          <Pagination
            page={tutorLmsLectureList?.data?.page}
            isScrollToTop={false}
            totalPages={tutorLmsLectureList?.data?.totalPages}
            onChangePage={(page) => onHandlePageChange(page)}
          />
        </div>
      }
    </div>
  )
}

export default LectureTab;