import { useEffect } from 'react'
import { motion } from "framer-motion";
import { z } from "zod";
import { useForm, useWatch } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { MdChevronRight } from "react-icons/md";
import { IoArrowBackOutline, IoPersonSharp } from 'react-icons/io5';

import TooltipView from 'components/tooltipView';
import ButtonLoader from "components/loader/button-loader";

import authService from 'redux/auth/auth.service';
import { signupAsEnum } from "redux/auth/auth.const"

import { cn } from "utils/cn.utils";
import { timeZone } from 'utils/dateTime.utils';

export const signupFormSchema = z.object({
    timeZone: z.literal(timeZone),
    first_name: z.string().trim()
        .min(3, { message: "First Name must be greater then 3 character" })
        .max(50, { message: "First Name must be less then 50 character" }),
    last_name: z.string().trim()
        .min(3, { message: "Last Name must be greater than 3 character" })
        .max(50, { message: "Last Name must be less then 50 character" }),
    sign_up_as: z.literal(signupAsEnum.tutor.key)
})

export const defaultSignupFormData = {
    timeZone: timeZone,
    first_name: "",
    last_name: "",
    sign_up_as: signupAsEnum.tutor.key
}


const SignupForm = ({
    identifierData,
    formData = defaultSignupFormData,
    textPlace,
    onHandleUpdateIdentifier = () => { },
    onHandleVerify = () => { },
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isLoading },
        reset,
        setError,
        setValue,
        control,
        setFocus,
    } = useForm({
        mode: "onChange",
        resolver: zodResolver(signupFormSchema),
        defaultValues: formData
    })

    const values = useWatch({ control })

    const { executeRecaptcha } = useGoogleReCaptcha();

    const getRecaptchaToken = async () => {
        if (!executeRecaptcha) return;
        const token = await executeRecaptcha('signup');
        if (token) return token;
        return null
    }

    useEffect(() => {
        setFocus("first_name")
    }, [setFocus])

    const onSubmit = async (data) => {
        try {
            const recaptchaToken = await getRecaptchaToken()
            const requestData = {
                body: { ...identifierData, ...data, recaptcha_token: recaptchaToken, source: window.location.href }
            }
            const response = await authService.signupWithOtp(requestData)
            if (response.status === 200) {
                onHandleVerify(response?.data?.data?.token, identifierData, false, data)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.log({ "error": error?.response?.data?.message || error?.response?.data?.error || error })
            setError("root", { message: error?.response?.data?.message || error?.response?.data?.error || "Oops, Something went wrong!" })
        }
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={'w-full h-full flex flex-col justify-between gap-8 p-5 md:p-10'}
        >
            <div className={"w-full space-y-8"}>
                <div className={"space-y-2"}>
                    <h2 className={'font-bodyPri font-bold  text-lg sm:text-xl md:text-2xl text-text-900 text-left'}>
                        {textPlace?.heading}
                    </h2>
                    <p className={'font-bodyPri font-normal italic text-sm sm:text-base md:text-md text-text-600 text-left'}>
                        {textPlace?.subheading}
                    </p>
                </div>

                <div className={"w-full flex flex-col md:flex-row items-start justify-center md:justify-between gap-3"}>
                    <div className={"w-full space-y-2"}>
                        <div
                            className={cn(
                                "relative group w-full h-14 px-3 flex gap-3 items-center border-b focus-within:shadow",
                                errors?.first_name
                                    ? 'border-secondary-dark focus-within:border-secondary-main shadow shadow-secondary-main/20 focus-within:shadow-secondary-main/20'
                                    : values?.first_name
                                        ? 'border-primary-main focus-within:border-primary-main shadow shadow-primary-main/20 focus-within:shadow-primary-main/20'
                                        : 'border-text-400 focus-within:border-primary-main focus-within:shadow-primary-main/20'
                            )}
                        >
                            <IoPersonSharp size={20} className={cn(
                                errors?.first_name
                                    ? "text-secondary-main group-focus-within:text-secondary-main"
                                    : values?.first_name
                                        ? "text-primary-main group-focus-within:text-primary-main"
                                        : "text-text-600 group-focus-within:text-primary-main"
                            )} />
                            <input
                                type={"text"}
                                placeholder={textPlace?.placeholder?.first_name}
                                className={('w-full h-full bg-transparent outline-none text-base')}
                                {...register("first_name")}
                            />
                        </div>
                        {errors?.first_name && (
                            <span className={"font-bodyPri font-normal text-xs text-secondary-main text-left transition-all ease-in-out duration-200"}>
                                {errors?.first_name?.message}
                            </span>
                        )}
                    </div>
                    <div className={"w-full space-y-2"}>
                        <div
                            className={cn(
                                "relative group w-full h-14 px-3 flex gap-3 items-center border-b focus-within:shadow",
                                errors?.last_name
                                    ? 'border-secondary-dark focus-within:border-secondary-main shadow shadow-secondary-main/20 focus-within:shadow-secondary-main/20'
                                    : values?.last_name
                                        ? 'border-primary-main focus-within:border-primary-main shadow shadow-primary-main/20 focus-within:shadow-primary-main/20'
                                        : 'border-text-400 focus-within:border-primary-main focus-within:shadow-primary-main/20'
                            )}
                        >
                            <IoPersonSharp size={20} className={cn(
                                errors?.last_name
                                    ? "text-secondary-main group-focus-within:text-secondary-main"
                                    : values?.last_name
                                        ? "text-primary-main group-focus-within:text-primary-main"
                                        : "text-text-600 group-focus-within:text-primary-main"
                            )} />
                            <input
                                type={"text"}
                                placeholder={textPlace?.placeholder?.last_name}
                                className={('w-full h-full bg-transparent outline-none text-base')}
                                {...register("last_name")}
                            />
                        </div>
                        {errors?.last_name && (
                            <span className={"font-bodyPri font-normal text-xs text-secondary-main text-left transition-all ease-in-out duration-200"}>
                                {errors?.last_name?.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className={"w-full flex flex-row items-center justify-between gap-3"}>
                <TooltipView content={"Back"}>
                    <motion.button
                        onClick={() => onHandleUpdateIdentifier(identifierData)}
                        className={"group p-3 rounded-full bg-back-ground-darkLight hover:bg-back-ground-lightDark transition-colors duration-100 ease-in-out"}
                    >
                        <IoArrowBackOutline className={"text-2xl text-text-600 group-hover:text-text-50"} />
                    </motion.button>
                </TooltipView>
                <motion.button
                    disabled={isSubmitting || isLoading}
                    whileHover={{ scale: !(isSubmitting || isLoading) ? 1.01 : 1, y: !(isSubmitting || isLoading) ? -3 : 0 }}
                    whileTap={{ scale: 1, y: !(isSubmitting || isLoading) ? 3 : 0 }}
                    className={cn(
                        "w-2/3 md:w-1/3 h-10 md:h-14 flex items-center justify-center border-2",
                        "transition-all ease-in-out duration-100",
                        "font-buttons font-medium text-lg rounded-full",
                        (isSubmitting || isLoading)
                            ? "text-text-50 bg-primary-dark cursor-wait"
                            : "text-primary-dark border-primary-dark hover:text-text-50 hover:bg-primary-dark cursor-pointer"
                    )}
                >
                    {(isSubmitting || isLoading)
                        ? <ButtonLoader />
                        : (
                            <span className={"inline-flex items-center justify-center"}>
                                {'Next'}
                                <MdChevronRight size={28} className={"hidden sm:inline-flex"} />
                            </span>
                        )}
                </motion.button>
            </div>
            <div className={"w-full text-right"}>
                {(errors?.root) && (
                    <span className={"font-bodyPri font-normal text-xs text-secondary-main text-left transition-all ease-in-out duration-200"}>
                        {errors?.root?.message}
                    </span>
                )}
            </div>
        </form>
    )
}

export default SignupForm