import { useMemo } from "react";

import { MdOutlineCake } from "react-icons/md";
import { RiBuilding2Line } from "react-icons/ri";
import { HiOutlineIdentification } from "react-icons/hi";

import { dayjs, timeZone } from "utils/dateTime.utils";

function ContactBasicDetails({ contact }) {

    const isContactBasicDetailAvailable = useMemo(() => (contact?.date_of_birth || contact?.company || contact?.department || contact?.job_title || !!contact?.contact_labels?.length), [contact])

    return isContactBasicDetailAvailable && (
        <div className={"flex flex-col w-full gap-3 bg-back-ground-lightBlue rounded-lg p-5"}>
            {contact?.date_of_birth &&
                <div className="flex gap-2 items-center text-sm text-text-700">
                    <MdOutlineCake className="text-lg mb-0.5" />
                    <p>{"Date of birth: "}</p>
                    <p className="text-text-900">{dayjs(contact?.date_of_birth).tz(timeZone).format("dddd, DD-MMM-YYYY")}</p>
                </div>
            }
            {contact?.company &&
                <div className="flex gap-2 items-center text-sm text-text-700">
                    <RiBuilding2Line className="text-lg mb-0.5" />
                    <p>{"Company: "}</p>
                    <p className="text-text-900">{contact?.company}</p>
                </div>
            }
            {(contact?.department || contact?.job_title) &&
                <div className="flex gap-2 items-center text-sm text-text-700">
                    <HiOutlineIdentification className="text-lg mb-0.5" />
                    <p>{"Job Title: "}</p>
                    <p className="text-text-900">
                        {`${contact?.department}${(contact?.job_title && contact?.department)
                            ? ", " + contact?.job_title
                            : contact?.job_title
                                ? contact?.job_title
                                : ""}`
                        }
                    </p>
                </div>
            }
        </div>
    );
}

export default ContactBasicDetails;