import { memo } from "react";

import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { MdOutlineArrowForwardIos } from 'react-icons/md';

import { searchParamsInfo } from "pages/auth/editAppointment/data";

import { useAppState } from "hooks/useStore";

const ViewAppointmentDetail = memo(({ onHandleActiveSection }) => {
    const { modifyAppointmentDetail } = useAppState((state) => state.appointment)

    return (
        <div
            className={"w-full p-5 h-32 hover:bg-back-ground-lightBlue font-bodyPri cursor-pointer"}
            onClick={() => onHandleActiveSection(searchParamsInfo.action.key, searchParamsInfo.appointment.key)}
        >
            <div className={"w-full flex items-center justify-between gap-3"}>
                <div className={"flex items-start justify-start gap-3"}>
                    <HiOutlineMenuAlt2 className={"text-2xl text-text-600"} />
                    <div className={"flex flex-col gap-3"}>
                        <span className={"font-medium text-text-900 text-md"}>
                            {"Appointment Details"}
                        </span>
                        <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                            {(modifyAppointmentDetail?.payload?.duration >= 60)
                                ? `${parseFloat(modifyAppointmentDetail?.payload?.duration / 60).toFixed(2)} hr`
                                : `${modifyAppointmentDetail?.payload?.duration} min`
                            }
                        </span>
                    </div>
                </div>
                <MdOutlineArrowForwardIos className={"text-3xl text-text-600"} />
            </div>
        </div>
    )
})

export default ViewAppointmentDetail;