import { themeModeConst } from "redux/local/local.const";
import orgService from "redux/org/org.service";

import {
    setPublicOrgDetailLoading,
    setPublicOrgDetailData,
    setPublicOrgDetailMessage,

    setOrgThemeDetailLoading,
    setOrgThemeDetailData,
    setOrgThemeDetailMessage,

    setOrgDomainDetailLoading,
    setOrgDomainDetailData,
    setOrgDomainDetailMessage,
} from "redux/org/org.slice";

import { getOrgThemeColors, setThemeColor } from "utils/theme.utils";

export const getCurrentOrgDetail = () => async (dispatch) => {
    dispatch(setPublicOrgDetailLoading(true))

    try {
        const response = await orgService.getCurrentOrgDetail()
        if (response.status === 200) {
            dispatch(setPublicOrgDetailData(response.data.data))
            dispatch(setOrgThemeDetailData({ result: response.data.data?.result?.theme }))
            dispatch(setOrgDomainDetailData({ result: response.data.data?.result?.domains }))
            setThemeColor(getOrgThemeColors(response.data.data?.result?.theme), themeModeConst.light.key)
            // dispatch(getOrgThemeDetail(response.data.data?.result?.id))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        dispatch(setPublicOrgDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setPublicOrgDetailLoading(false))
    }
}

export const getOrgThemeDetail = (orgId) => async (dispatch) => {
    dispatch(setOrgThemeDetailLoading(true))

    try {
        const requestData = {
            params: { orgId: orgId }
        }
        const response = await orgService.getOrgThemeDetail(requestData)
        if (response.status === 200) {
            dispatch(setOrgThemeDetailData(response.data.data))
            setThemeColor(getOrgThemeColors(response.data.data?.result), themeModeConst.light.key)
        } else {
            throw new Error()
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setOrgThemeDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setOrgThemeDetailLoading(false))
    }
}