import { cn } from "utils/cn.utils";

import { AiOutlineClose } from 'react-icons/ai';

import ButtonLoader from 'components/loader/ButtonLoader';

const CreateSection = ({
  isLoading = false,
  sectionName,
  onClose,
  value,
  onChange,
  placeholder = "Type ...",
  onCreate
}) => {

  return (
    <div className={'flex flex-col w-full justify-center items-center gap-2'}>
      <div className={'flex w-full justify-between md:justify-center items-center'}>
        <p className={"font-bodyPri text-sm"}>
          {sectionName}
        </p>
        <AiOutlineClose
          onClick={onClose}
          size={20}
          className={'text-back-ground-black hover:text-secondary-dark cursor-pointer mr-2 flex md:hidden'}
        />
      </div>
      <div className={"flex flex-col md:flex-row w-full items-center gap-3 md:gap-0 font-bodyPri text-black text-lg"}>
        <AiOutlineClose
          onClick={onClose}
          size={20}
          className={'text-back-ground-black hover:text-secondary-dark cursor-pointer mr-2 hidden md:flex'}
        />
        <input
          type={"text"}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={cn(
            "px-3 h-12 flex w-full focus:outline-none rounded-full md:rounded-r-none",
            "border border-back-ground-lightDark focus:border focus:border-primary-main"
          )}
          autoFocus={true}
        />
        <button
          onClick={onCreate}
          className={cn(
            "bg-primary-main hover:bg-secondary-dark text-white w-full md:w-28 rounded-full md:rounded-l-none",
            "px-10 h-12 flex items-center justify-center ease-in-out duration-100"
          )}
        >
          {isLoading &&
            <ButtonLoader isLoading={isLoading} />
          }
          {!isLoading && "Create"}
        </button>
      </div>
    </div>
  )
}

export default CreateSection;