import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PageLoader from 'components/loader/PageLoader';

import { PageContentStyle } from './ProductPage.style';
import ProductHeader from './components/productHeader/ProductHeader';
import ProductInformation from './components/productInformation/ProductInformation';
import PriceInformation from './components/priceInformation/PriceInformation';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getProductDetail } from 'redux/product/product.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetProductDetail } from "redux/product/product.slice";

import { useTitle } from 'hooks/useTitle';

import { pagesInfo } from 'utils/pagesInfo';

const ProductPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { productDetail } = useAppState((state) => state.product)

    const dispatcher = useAppDispatcher()
    const { productId } = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.PRODUCTS))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Products | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (productId) {
            dispatcher(getProductDetail(productId))
        }

        return () => {
            dispatcher(resetProductDetail())
        }
    }, [productId])

    if (productDetail?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (productDetail?.message) {
        return (
            <div className={"w-screen h-screen flex items-center justify-center"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {productDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <PageContentStyle>
            <div className={"px-5 pb-5 flex flex-1 flex-col gap-3"}>
                <ProductHeader />
                <div className={"w-full h-full mx-auto container flex flex-col gap-3"}>
                    <ProductInformation />
                    <PriceInformation />
                    {/* <ProductSettings /> */}
                </div>
            </div>
        </PageContentStyle>
    )
}

export default ProductPage;