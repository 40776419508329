import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AiOutlineClose } from "react-icons/ai";

import MuiDateTimeSlots from "./components/MuiDateTimeSlots";
// import EventlyGuests from "./components/EventlyGuests";
import EventlyMeetingLink from "./components/EventlyMeetingLink";
import EventlyLocation from "./components/EventlyLocation";
import EventlyDescription from "./components/EventlyDescription";
import EventlyOrganizer from "./components/EventlyOrganizer";
import EventlyFooter from "./components/EventlyFooter";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import {
    resetAddUserEventlyDetail,
    resetAddUserEventlyDetailPayload,
    setAddUserEventlyDetailPayload,
} from "redux/evently/evently.slice";
import { resetAddProductDetail } from "redux/product/product.slice";
import { modalConst } from "redux/local/local.const";

import { cn } from "utils/cn.utils";
import { pagesInfo } from "utils/pagesInfo";
import useAiText from "hooks/useAiText";
import { toast } from "react-toastify";
import AiTextButton from "components/createWithAi/aiTextButton";

const AddEventlySlotPopover = ({ openModal = false, bgColor = "bg-white", maxWidth = "max-w-2xl", onCloseModal }) => {
    const { modal } = useAppState((state) => state.local);
    const { addUserEventlyDetail } = useAppState((state) => state.evently);

    const dispatcher = useAppDispatcher();
    const navigate = useNavigate()

    const [title, setTitle] = useState('');
    const [isShowInput, setIsShowInput] = useState(false)
    const [topic, setTopic] = useState('')

    const { aiText, generateAiText } = useAiText()

    const requestData = {
        purpose: "title", // description or title 
        total_chars: 85,
        topic: topic,
        response_type: "text" // text or html 
    };

    const getAiText = () => {
        if (topic === "") {
            toast.error("Please write ai prompt");
            return
        }
        setIsShowInput(false)
        generateAiText(requestData);
    };

    const handlePrompt = (e) => {
        const value = e.target.value
        setTopic(value)
    }

    useEffect(() => {
        if (aiText?.data?.ai_response) {
            dispatcher(
                setAddUserEventlyDetailPayload({
                    ...addUserEventlyDetail?.payload,
                    title: aiText?.data?.ai_response
                })
            );
        }
    }, [aiText?.data]);

    useEffect(() => {
        if (addUserEventlyDetail?.data) {
            dispatcher(resetAddUserEventlyDetail());
            dispatcher(setModal({
                ...modal,
                [modalConst.addEventlySlotModal.key]: {
                    ...modal[modalConst.addEventlySlotModal.key],
                    isVisible: false,
                    title: "",
                },
            }));
            navigate(`${pagesInfo?.EDIT_EVENTLY?.pagePath}/${addUserEventlyDetail?.data?.id}/edit`)
        }
    }, [addUserEventlyDetail?.data]);

    useEffect(() => {
        return () => {
            dispatcher(resetAddUserEventlyDetail());
            dispatcher(resetAddUserEventlyDetailPayload());
            dispatcher(resetAddProductDetail());
        };
    }, []);

    const onHandleChangeTitle = useCallback(
        (event) => {
            dispatcher(
                setAddUserEventlyDetailPayload({
                    ...addUserEventlyDetail?.payload,
                    title: event?.target?.value,
                })
            );
        },
        [addUserEventlyDetail?.payload]
    );

    return (
        <div
            className={cn(
                "inline-block w-full space-y-5 overflow-hidden font-bodyPri pb-5",
                `transition-all transform ${maxWidth} ${bgColor}`
            )}
        >
            <div className={"flex w-full justify-end items-center bg-text-200 p-3 drop-shadow-sm"}>
                {/* <IoReorderTwoOutline className={"text-text-800 text-lg cursor-pointer"} /> */}
                <AiOutlineClose className={"text-text-800 text-lg cursor-pointer"} onClick={onCloseModal} />
            </div>

            <div
                className={cn(
                    "w-full h-full max-h-[28rem] flex flex-col gap-y-5 overflow-hidden overflow-y-scroll",
                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                    "scrollbar-thumb-divider-lightDark scrollbar-track-divider-darkLight"
                )}
            >
                <div className={"w-full grid grid-cols-12 gap-x-2 px-5"}>
                    <div className={"relative col-start-1 col-span-full md:col-start-2 md:col-span-full"}>
                        <AiTextButton
                            isShowInput={isShowInput}
                            setIsShowInput={setIsShowInput}
                            handlePrompt={handlePrompt}
                            getAiText={getAiText}
                            aiText={aiText}
                        />
                        <input
                            type={"text"}
                            className={cn(
                                "flex w-full p-2 focus:outline-none bg-back-ground-light border-b focus:border-primary-main mt-2",
                                "font-bodyPri text-text-900 text-lg font-medium placeholder:text-text-700 placeholder:font-normal"
                            )}
                            placeholder={"Add title and time"}
                            value={addUserEventlyDetail?.payload?.title}
                            onChange={onHandleChangeTitle}
                            autoFocus={true}
                        />
                    </div>
                </div>

                <MuiDateTimeSlots />

                {/* <EventlyGuests /> */}

                <EventlyMeetingLink />

                <EventlyLocation />

                <EventlyDescription />

                <EventlyOrganizer />
            </div>
            <EventlyFooter />
        </div>
    );
};

export default AddEventlySlotPopover;
