import { useCallback, useState, useMemo, useEffect } from 'react';

import { motion } from "framer-motion";

import { FiChevronRight } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BsCheckLg } from 'react-icons/bs';

import styles from "./styles.module.css";

import ToolTipView from 'components/tooltipView';

import Drag from "pages/auth/edulyteLms/assets/drag.svg";

import SaveAndCancelBtn from "pages/auth/edulyteLms/commonComponents/lmsButtons/SaveAndCancelBtn";

import { deleteLmsCourseLearning, updateLmsCourseLearningDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetModifyLmsCourseLearningDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';

import { cn } from 'utils/cn.utils';

const CourseLearningItem = ({ index, learningItem, }) => {
    const { modifyLmsCourseLearningDetail, deleteLmsCourseLearningDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [clickedItemIndex, setClickedItemIndex] = useState(null);
    const [learningPayload, setLearningPayload] = useState(null);

    useEffect(() => {
        if (modifyLmsCourseLearningDetail?.data) {
            dispatcher(resetModifyLmsCourseLearningDetail())
            setLearningPayload(null)
            setClickedItemIndex(null)
        }
    }, [modifyLmsCourseLearningDetail?.data])

    const onHandleEditBtn = useCallback(() => {
        setLearningPayload(learningItem?.item)
        setClickedItemIndex(index + 1)
    }, [learningItem, clickedItemIndex, learningPayload])

    const onHandleChangeText = useCallback((event) => {
        setLearningPayload(event?.target?.value)
    }, [learningPayload])

    const onHandleSaveCourseLearning = useCallback(() => {
        if (!learningPayload || (learningPayload === learningItem?.item)) return;

        const body = { item: learningPayload }
        dispatcher(updateLmsCourseLearningDetail(learningItem?.id, body))
    }, [learningItem, learningPayload])

    const onHandleResetLearning = useCallback(() => {
        setLearningPayload(null)
        setClickedItemIndex(null)
    }, [learningPayload, clickedItemIndex])

    const onHandleDeleteCourseLearning = useCallback(() => {
        if (deleteLmsCourseLearningDetail?.isLoading) return;

        dispatcher(deleteLmsCourseLearning(learningItem?.id))
    }, [learningItem, deleteLmsCourseLearningDetail?.isLoading])

    return (
        <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.2 }}
            exit={{ height: 0, opacity: 0 }}
            className={"border rounded-sm border-white border-b-divider-medium hover:border-primary-main overflow-hidden cursor-pointer"}
        >
            <div className={"w-full flex items-center justify-between gap-3 hover:bg-back-ground-lightBlue"}>
                <div className={"flex-1 p-3 flex items-center justify-start gap-3 hover:text-primary-dark"}>
                    <div className={""}>
                        <img
                            src={Drag}
                            className={"w-2.5 cursor-grab"}
                        />
                    </div>
                    <div className={"flex flex-1 items-center gap-2.5"}>
                        <BsCheckLg size={14} className={"text-green-500"} />
                        {clickedItemIndex &&
                            <input
                                type={"text"}
                                name={"item"}
                                placeholder={"Write learning here ..."}
                                className={cn(
                                    "flex flex-1 p-3 focus:outline-none focus:!bg-white bg-back-ground-light hover:bg-text-100",
                                    "transition ease-in-out duration-300 overflow-hidden border-b",
                                    "font-bodyPri font-normal text-text-800 text-base tracking-wide placeholder:text-text-500",
                                    !learningPayload && "border-red-500"
                                )}
                                autoFocus={true}
                                value={learningPayload}
                                onChange={onHandleChangeText}
                            />
                        }
                        {!clickedItemIndex &&
                            <p className={"flex-1 font-bodyPri font-semibold text-base"}>
                                {learningItem?.item}
                            </p>
                        }
                    </div>
                </div>

                {!clickedItemIndex &&
                    <div className={'flex justify-end items-center gap-1.5'}>
                        <ToolTipView content={'Edit'}>
                            <button className={"p-2 rounded-full hover:bg-divider-darkLight group"} onClick={onHandleEditBtn}>
                                <MdEdit className={"text-xl text-text-700 group-hover:text-primary-dark cursor-pointer"} />
                            </button>
                        </ToolTipView>
                        <ToolTipView content={"Delete"}>
                            <button className={"p-2 rounded-full hover:bg-divider-darkLight group"} onClick={onHandleDeleteCourseLearning}>
                                <RiDeleteBin6Line className={"text-xl text-text-700 group-hover:text-red-500 cursor-pointer"} />
                            </button>
                        </ToolTipView>
                    </div>
                }
                {clickedItemIndex &&
                    <SaveAndCancelBtn
                        isLoading={modifyLmsCourseLearningDetail?.isLoading}
                        className={"flex items-center justify-start gap-1.5"}
                        onHandleSave={onHandleSaveCourseLearning}
                        onHandleReset={onHandleResetLearning}
                    />
                }
            </div>
        </motion.div>
    )
}

export default CourseLearningItem;