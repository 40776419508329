import { memo, useCallback, useMemo } from "react";

import { cn } from "utils/cn.utils";

import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

import FadedCollapse from "components/fadedCollapse";
import ToolTipView from "components/tooltipView";
import ViewOnlyQuillEditor from "components/textEditor/ViewOnlyQuillEditor";

import Drag from "pages/auth/edulyteLms/assets/drag.svg";

import { deleteLmsQuizCategoryDetail } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import { useAppDispatcher } from "hooks/useStore";

import { pagesInfo } from "utils/pagesInfo";

const QuizSectionArticle = memo(({ quizSectionCategoryItem }) => {

    const dispatcher = useAppDispatcher()

    const contentLength = useMemo(() => {
        const parsedHTML = new DOMParser().parseFromString(`<div>${quizSectionCategoryItem?.article?.article?.content}</div>`, "text/html");
        const textContent = parsedHTML.querySelector("div")?.textContent || '';
        return textContent.length > 200;
    }, [quizSectionCategoryItem?.article?.article?.content]);

    const onHandleEditArticle = useCallback(() => {
        window.open(`${window.location.host}${pagesInfo.EDIT_LMS_ARTICLE.pagePath}/${quizSectionCategoryItem?.article?.article?.article_id}/edit`, "_self")
    }, [quizSectionCategoryItem])

    const onHandleDeleteArticle = useCallback(() => {
        dispatcher(deleteLmsQuizCategoryDetail(quizSectionCategoryItem?.id, quizSectionCategoryItem?.quiz_section))
    }, [quizSectionCategoryItem])

    return quizSectionCategoryItem?.article?.article && (
        <div className={"w-full px-5 py-2.5 bg-white rounded-lg space-y-5 transition-transform ease-in-out duration-300"}>
            <div
                className={cn("w-full flex items-center justify-between gap-5 cursor-grab")}>
                <div className={"flex items-center justify-start gap-5"}>
                    <img
                        src={Drag}
                        className={"w-2.5 cursor-grab"}
                    />
                    <span className={"inline-flex font-bodyPri font-medium text-text-900 text-md sm:text-lg line-clamp-2"}>
                        {quizSectionCategoryItem?.article?.article?.title}
                    </span>
                </div>
                <div className={'flex justify-end items-center gap-1.5'}>
                    <ToolTipView content={'Edit Article'}>
                        <button
                            className={"p-2 rounded-full hover:bg-divider-darkLight"}
                            onClick={onHandleEditArticle}
                        >
                            <MdEdit className={"text-xl text-text-700 cursor-pointer"} />
                        </button>
                    </ToolTipView>
                    <ToolTipView content={"Remove Article"}>
                        <button
                            className={"p-2 rounded-full hover:bg-divider-darkLight"}
                            onClick={onHandleDeleteArticle}
                        >
                            <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
                        </button>
                    </ToolTipView>
                </div>
            </div>
            {!!quizSectionCategoryItem?.article?.article?.content?.length &&
                <FadedCollapse
                    isShowMore={contentLength}
                    maxHeight={contentLength ? "7.5rem" : '3rem'}
                >
                    <div className={"px-5"}>
                        <ViewOnlyQuillEditor
                            editorContent={quizSectionCategoryItem?.article?.article?.content}
                            editorClassName={""}
                        />
                    </div>
                </FadedCollapse>
            }
        </div>
    )
});

export default QuizSectionArticle;