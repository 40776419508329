const Achievement = ({ certificate }) => {

    return (
        <div className='w-[516.1px] h-[729.95px] flex flex-col justify-between bg-white bg-no-repeat bg-contain shadow-all rounded' style={{ backgroundImage: `url(${certificate?.placeholder?.bg_url})` }}>
            <div className="flex flex-col justify-center items-center gap-44 mt-3">
                <img src={certificate?.placeholder?.logo_url} alt="logo" className="h-12 w-44 object-contain" />
                <div className='text-center'>
                    <h2 className="font-serif uppercase text-[#c0944d] tracking-wider text-[40px]">Certificate</h2>
                    <h2 className="uppercase text-[#c0944d] text-[20px]">of Achievement</h2>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-3 px-5 text-center">
                <p className="font-bodyPri font-light">Proudly Presented to</p>
                <p className="text-4xl px-3 font-cursive border-b border-b-back-ground-black text-[#c0944d]">{certificate?.placeholder?.user_name}</p>
                <p className="text-sm font-bodyPri text-gray-500">For their active participation and outstanding performance in</p>
                <p className="font-bodyPri text-text-900 line-clamp-2">{certificate?.placeholder?.title}</p>
            </div>
            <div className="mb-20 flex justify-between items-end gap-3 px-5">
                <div className="flex flex-col justify-center items-center gap-2">
                    <img src={certificate?.placeholder?.sign_url} alt="sign" className="h-10 object-contain" />
                    <p className="font-bodyPri font-medium text-text-800">{certificate?.placeholder?.issued_by}</p>
                    <p className="font-bodyPri text-text-800">Date: {certificate?.placeholder?.issued_date}</p>
                </div>
                <div className="flex flex-col justify-center items-center gap-2">
                    <img src={certificate?.placeholder?.qr_code} alt="qr" className="h-20 w-20 object-contain" />
                    <p className="text-sm font-bodyPri text-gray-500">Scan me</p>
                    <p className="text-sm font-bodyPri text-gray-500">Certificate #: {certificate?.certificate_code}</p>
                </div>
            </div>
        </div>
    )
}

export default Achievement
