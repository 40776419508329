import { createSlice, } from "@reduxjs/toolkit";
import { EDRAZORPAY_INITIAL_STATE } from "redux/edrazorpay/edrazorpay.initialState";


const edrazorpay = createSlice({
    name: "edrazorpay",
    initialState: EDRAZORPAY_INITIAL_STATE,
    reducers: {
        // reducers for edrazorpay order
        setCreateEdrazorpayOrder: (state, { payload }) => {
            state.createEdrazorpayOrder = { ...state.createEdrazorpayOrder, ...payload }
        },
        resetCreateEdrazorpayOrder: (state) => {
            state.createEdrazorpayOrder = EDRAZORPAY_INITIAL_STATE.createEdrazorpayOrder
        },
    }
})

export const {
    setCreateEdrazorpayOrder,
    resetCreateEdrazorpayOrder
} = edrazorpay.actions

export default edrazorpay.reducer