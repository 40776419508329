import { useState, useEffect, memo, useCallback } from "react";

import { cn } from "utils/cn.utils";

import { AiOutlinePlus } from "react-icons/ai";

import ComponentLoader from "components/loader/ComponentLoader";
import InfinitePagination from "components/pagination/InfinitePagination";
import { libraryTabConst } from "components/modals/lmsModals/attachLibraryModal/data";

import { getLmsCoursePageLectureList } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetLocalTabs, setLocalTabs, setModal } from "redux/local/local.slice";
import { resetLmsCoursePageLectureList } from "redux/edulyteLms/lmsCourse/lmsCourse.slice";
import { lmsCourseSectionLessonTypeEnum } from "redux/edulyteLms/lmsCourse/lmsCourse.const";
import { modalConst } from "redux/local/local.const";
import { lmsLectureStatusEnum } from "redux/edulyteLms/lmsLecture/lmsLecture.const";

const LmsLectureListItems = memo(({ lectureSearch }) => {
    const { modal, localTabs } = useAppState((state) => state.local)
    const { lmsCoursePageLectureList } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        const requestDataPayload = {
            page: 1,
            records: 10
        }
        if (lectureSearch) {
            requestDataPayload["search"] = lectureSearch
        }
        dispatcher(getLmsCoursePageLectureList(requestDataPayload))

        return () => {
            dispatcher(resetLmsCoursePageLectureList())
        }
    }, [])

    const fetchMoreData = () => {
        if (lmsCoursePageLectureList?.data?.page === lmsCoursePageLectureList?.data?.totalPages) return;
        const requestDataPayload = {
            page: 1,
            records: lmsCoursePageLectureList?.data?.records
                ? (lmsCoursePageLectureList?.data?.records + 10)
                : lmsCoursePageLectureList?.data?.records || 10
        }
        if (lectureSearch) {
            requestDataPayload["search"] = lectureSearch
        }
        dispatcher(getLmsCoursePageLectureList(requestDataPayload))
    }

    const onHandleDragStart = (event, lectureObject) => {
        if (![lmsLectureStatusEnum?.PUBLISHED?.value]?.includes(lectureObject?.lecture_setting?.status)) return;

        const updatedVideoObject = { lessonType: lmsCourseSectionLessonTypeEnum.LECTURE.value, ...lectureObject }
        event.dataTransfer.setData('application/json', JSON.stringify(updatedVideoObject))
    }

    const onHandleOpenLectureModal = useCallback(() => {
        dispatcher(resetLocalTabs())
        dispatcher(setLocalTabs({
            ...localTabs,
            tabList: Object.values(libraryTabConst),
            activeTabList: [libraryTabConst.LECTURES.value],
            currentActiveTab: libraryTabConst.LECTURES.value,
            tabItem: {
                ...localTabs?.tabItem,
                payload: null
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true,
        }))
    }, [modal, localTabs])

    return (
        <>
            {lmsCoursePageLectureList?.isLoading &&
                <ComponentLoader isLoading={lmsCoursePageLectureList?.isLoading} />
            }
            {lmsCoursePageLectureList?.message &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {lmsCoursePageLectureList?.message || "No Lecture Added"}
                    </span>
                </div>
            }
            {(lmsCoursePageLectureList?.data && (lmsCoursePageLectureList?.data?.results?.length === 0)) &&
                <div className={"w-full flex items-center justify-center"}>
                    <button
                        className={cn(
                            'w-full flex justify-center items-center gap-1 bg-white rounded-lg px-3 py-1.5',
                            'bg-text-200 hover:bg-text-300 ',
                            'font-bodyPri font-normal text-text-900 text-sm tracking-wide whitespace-nowrap'
                        )}
                        onClick={onHandleOpenLectureModal}
                    >
                        <AiOutlinePlus className={''} />
                        <span className={""}>
                            {"Add Lecture"}
                        </span>
                    </button>
                </div>
            }
            {(lmsCoursePageLectureList?.data && (lmsCoursePageLectureList?.data?.results?.length > 0)) &&
                <InfinitePagination
                    scrollableTarget={"scrollableDiv"}
                    dataLength={lmsCoursePageLectureList?.data?.totalRecords}
                    fetchData={fetchMoreData}
                    hasMore={(lmsCoursePageLectureList?.data?.page != lmsCoursePageLectureList?.data?.totalPages)}
                    loader={<ComponentLoader isLoading={lmsCoursePageLectureList?.isLoading} />}
                    isShowEndMessage={false}
                >
                    {lmsCoursePageLectureList?.data?.results?.map((lectureItem) => (
                        <div
                            key={lectureItem?.id}
                            className={cn(
                                'font-bodyPri line-clamp-1 mb-2 hover:bg-back-ground-lightBlue rounded p-1',
                                isDragging ? "cursor-grabbing" : "cursor-grab",
                                ![lmsLectureStatusEnum?.PUBLISHED?.value]?.includes(lectureItem?.lecture_setting?.status) && "!cursor-not-allowed"
                            )}
                            draggable={[lmsLectureStatusEnum?.PUBLISHED?.value]?.includes(lectureItem?.lecture_setting?.status) && true}
                            onDragStart={(event) => onHandleDragStart(event, lectureItem)}
                            onMouseDown={() => setIsDragging(true)}
                            onMouseUp={() => setIsDragging(false)}
                            onMouseMove={() => {
                                if (isDragging) {
                                    setIsDragging(false)
                                }
                            }}
                        >
                            {lectureItem?.lecture_setting?.status &&
                                <span className={`text-xs text-${lmsLectureStatusEnum[lectureItem?.lecture_setting?.status?.toUpperCase()]?.darkColor}`}>
                                    {`(${lmsLectureStatusEnum[lectureItem?.lecture_setting?.status?.toUpperCase()]?.label})`}
                                </span>
                            }
                            <span className={"pl-1"}>{`${lectureItem?.title}`}</span>
                        </div>
                    ))}
                </InfinitePagination>
            }
        </>
    )
});

export default LmsLectureListItems;