import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { tutorFilterConst, getQueryString } from 'pages/global/findTutors/data';
import SortByContainer from 'pages/global/findTutors/components/mobileView/components/SortByContainer';
import StudentLevel from 'pages/global/findTutors/components/mobileView/components/StudentLevel';
import SpeaksFilter from 'pages/global/findTutors/components/mobileView/components/SpeaksFilter';
import AvailabilityFilter from "pages/global/findTutors/components/mobileView/components/AvailabilityFilter";
import TutorIsFromFilter from 'pages/global/findTutors/components/mobileView/components/TutorIsFromFilter';
import PriceFilter from 'pages/global/findTutors/components/mobileView/components/PriceFilter';

import { useAppState } from "hooks/useStore";
import { DEFAULT_TUTOR_PAGE, DEFAULT_TUTOR_RECORDS, tutorFilterProps, tutorSortBy, weekOptions } from "redux/tutor/tutor.const";

import { pagesInfo } from "utils/pagesInfo";

const FilterTutorSideOverContent = ({ onClose }) => {
    const { locals } = useAppState((s) => s.local)
    const { languageList, profList, countryList, currencyRateList } = useAppState((state) => state.master)
    const { filterProps } = useAppState((state) => state.tutor)

    const navigate = useNavigate()

    const [activeTutorFilter, setActiveTutorFilter] = useState(tutorFilterConst?.PRICE?.value)
    const [tutorFilter, setTutorFilter] = useState({
        page: DEFAULT_TUTOR_PAGE,
        records: DEFAULT_TUTOR_RECORDS,
        sortBy: tutorSortBy?.RELEVANCE?.value,
        search: "",
        minHrsPrice: 0,
        maxHrsPrice: 0,
        roleStatus: [],
        language: [],
        languageLevel: [],
        segment: [],
        category: [],
        proficiency: [],
        tutorFrom: [],
        day: []
    })

    useEffect(() => {
        if (filterProps) {
            setTutorFilter(filterProps)
        }
    }, [filterProps])

    const onHandleTutorFilter = (tutorFilterValue) => {
        setActiveTutorFilter(tutorFilterValue)
    }

    const onApplyFilter = () => {
        const query = getQueryString(tutorFilter)
        navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
        onClose(false)
    }

    const onHandleClearAllFilters = () => {
        const query = getQueryString(tutorFilterProps)
        navigate(`${pagesInfo?.FIND_TUTORS?.pagePath}${query}`)
        onClose(false)
    }

    return (
        <div className={"w-screen h-screen flex flex-col lg:hidden"}>
            <div className={"w-full flex grow-0 justify-between items-center gap-3 px-5 py-3"}>
                <div className={"font-bodyPri font-normal text-text-900 text-base tracking-wide text-center"}>
                    {"FILTER TUTORS"}
                </div>
                <button
                    className={cn(
                        "px-2 py-0.5 border border-text-400 focus:outline-none rounded-lg",
                        "font-bodyPri font-normal text-text-800 text-sm",
                        "hover:text-text-50 hover:bg-text-400"
                    )}
                    onClick={() => onClose(false)}
                >
                    {"Close"}
                </button>
            </div>
            <hr className={"border border-divider-medium"} />
            <div className={"w-full h-[80vh] flex items-start justify-start gap-3"}>
                <div className={"w-4/12 sm:w-1/4 h-full bg-divider-medium"}>
                    {Object.values(tutorFilterConst)?.map((tutorFilter) => (
                        <>
                            <div
                                className={cn(
                                    "px-5 py-2 cursor-pointer",
                                    (activeTutorFilter === tutorFilter?.value) && "bg-white"
                                )}
                                onClick={() => onHandleTutorFilter(tutorFilter?.value)}
                            >
                                <span className={cn(
                                    "font-bodyPri font-medium text-text-800 text-base",
                                    (activeTutorFilter === tutorFilter?.value) && "text-primary-dark"
                                )}>
                                    {tutorFilter?.label}
                                </span>
                            </div>
                            <hr className={"border border-divider-darkLight w-full"} />
                        </>
                    ))}
                </div>
                <div className={"w-8/12 sm:w-3/4 h-full mr-5 px-5 py-3"}>
                    {(activeTutorFilter === tutorFilterConst?.PRICE?.value) &&
                        <PriceFilter
                            minValue={tutorFilter?.minHrsPrice || currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")]?.toFixed(2)}
                            maxValue={tutorFilter?.maxHrsPrice || (currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")] * 500)?.toFixed(2)}
                            onHandlePriceRange={(options) => {
                                setTutorFilter({
                                    ...tutorFilter, minHrsPrice: options?.minLocal, maxHrsPrice: options?.maxLocal
                                })
                            }}
                            onClearFilter={() => {
                                setTutorFilter({
                                    ...tutorFilter, minHrsPrice: 0, maxHrsPrice: 0
                                })
                            }}
                        />
                    }
                    {(activeTutorFilter === tutorFilterConst?.AVAILABILITY?.value) &&
                        <div>
                            <div className={"w-full flex items-center justify-between gap-3"}>
                                <div className={"flex items-center justify-start gap-2"}>
                                    <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                                        {"Availability"}
                                    </span>
                                    {tutorFilter?.day?.length > 0 &&
                                        <span className={"w-5 h-5 flex items-center justify-center bg-primary-dark rounded-full text-text-50 text-sm"}>
                                            {tutorFilter?.day?.length}
                                        </span>
                                    }
                                </div>
                                <div
                                    className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                                    onClick={() => {
                                        setTutorFilter({
                                            ...tutorFilter, day: []
                                        })
                                    }
                                    }>
                                    {"Clear"}
                                </div>
                            </div>
                            <AvailabilityFilter
                                weekOptions={Object.values(weekOptions)}
                                selectedAvailabilityList={tutorFilter?.day?.map((items) => ({
                                    label: items,
                                    value: items
                                })) || []}
                                onHandleSelectAvailability={(week) => {
                                    setTutorFilter({
                                        ...tutorFilter, day: week?.map((opt) => opt?.value)
                                    })
                                }}
                            />
                        </div>
                    }
                    {(activeTutorFilter === tutorFilterConst?.TUTOR_IS_FROM?.value) &&
                        <TutorIsFromFilter
                            options={countryList?.countryList?.map((country) => ({
                                label: country?.country,
                                value: country?.country,
                            }))}
                            selectedTutorFromList={tutorFilter?.tutorFrom?.map((items) => ({
                                label: items,
                                value: items
                            })) || []}
                            isLoading={countryList?.isLoading}
                            multipleOptions={true}
                            isShowInputBox={true}
                            isShowCheckBox={true}
                            onHandleSelectTutorFrom={(options) => {
                                setTutorFilter({
                                    ...tutorFilter, tutorFrom: options?.map((op) => op?.value)
                                })
                            }}
                            onClearFilter={() => {
                                setTutorFilter({
                                    ...tutorFilter, tutorFrom: []
                                })
                            }}
                        />
                    }
                    {(activeTutorFilter === tutorFilterConst?.SPEAKS?.value) &&
                        <SpeaksFilter
                            options={languageList?.languageList?.map((language) => ({
                                label: language?.language,
                                value: language?.language
                            }))}
                            selectedSpeaksList={tutorFilter?.language?.map((item) => ({
                                label: item,
                                value: item
                            })) || []}
                            isLoading={languageList?.isLoading}
                            multipleOptions={true}
                            isShowInputBox={true}
                            isShowCheckBox={true}
                            onHandleSelectSpeaks={(options) => {
                                setTutorFilter({
                                    ...tutorFilter, language: options?.map((opt) => opt?.value)
                                })
                            }}
                            onClearFilter={() => {
                                setTutorFilter({
                                    ...tutorFilter, language: []
                                })
                            }}
                        />
                    }

                    {(activeTutorFilter === tutorFilterConst?.STUDENT_LEVEL?.value) &&
                        <StudentLevel
                            options={profList?.profList?.map((proficiency) => ({
                                label: proficiency?.proficiency,
                                value: proficiency?.proficiency
                            }))}
                            isLoading={profList?.isLoading}
                            selectedLevels={tutorFilter?.proficiency?.length > 0 ? tutorFilter?.proficiency?.map((proficiency) => ({
                                label: proficiency,
                                value: proficiency
                            })) : []}
                            onHandleSelectedLevels={(options) => {
                                setTutorFilter({
                                    ...tutorFilter, proficiency: options?.map((opt) => opt?.value)
                                })
                            }}
                            onClearFilter={() => {
                                setTutorFilter({
                                    ...tutorFilter, proficiency: []
                                })
                            }}
                        />
                    }
                    {(activeTutorFilter === tutorFilterConst?.SORT_BY?.value) &&
                        <div>
                            <div className={"w-full flex items-center justify-between gap-3"}>
                                <div className={"flex items-center justify-start gap-2"}>
                                    <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                                        {"Sort By:"}
                                    </span>
                                    <span className={"font-bodyPri font-medium text-text-800 text-base tracking-wide"}>
                                        {tutorSortBy[tutorFilter?.sortBy?.toUpperCase()]?.label}
                                    </span>
                                </div>
                                <div
                                    className={"font-bodyPri font-medium text-primary-dark text-sm underline cursor-pointer"}
                                    onClick={() => setTutorFilter({
                                        ...tutorFilter, sortBy: tutorSortBy?.RELEVANCE?.value
                                    })}
                                >
                                    {"Clear"}
                                </div>
                            </div>
                            <SortByContainer
                                options={Object?.values(tutorSortBy)}
                                selectedSorting={{ label: tutorFilter?.sortBy, value: tutorFilter?.sortBy } || tutorSortBy?.RELEVANCE}
                                handleSelectSorting={(option) => {
                                    setTutorFilter({
                                        ...tutorFilter, sortBy: option?.value
                                    })
                                }}
                                onClearFilter={() => {
                                    setTutorFilter({
                                        ...tutorFilter, sortBy: tutorSortBy?.RELEVANCE?.value
                                    })
                                }}
                            />
                        </div>
                    }
                </div>
            </div>
            <hr className={"border border-divider-medium"} />
            <div className={"w-full flex grow-0 items-center justify-center gap-1 px-1 py-0.5"}>
                <span className={cn(
                    "flex-1 flex items-center justify-center px-4 py-2 rounded font-bodyPri font-medium text-sm text-text-900 bg-text-400 cursor-pointer whitespace-nowrap",
                    "hover:bg-back-ground-medium hover:text-text-800"
                )}
                    onClick={onHandleClearAllFilters}
                >
                    {"Clear All"}
                </span>
                <span className={cn(
                    "flex-1 flex items-center justify-center px-4 py-2 rounded font-bodyPri font-medium text-sm text-text-50 bg-primary-dark cursor-pointer whitespace-nowrap",
                    "hover:bg-primary-main"
                )}
                    onClick={onApplyFilter}
                >
                    {"Apply Filters"}
                </span>
            </div>
        </div>
    )
}

export default FilterTutorSideOverContent