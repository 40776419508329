import { useCallback, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";

import { IoCloseOutline } from 'react-icons/io5';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';

import ButtonLoader from 'components/loader/ButtonLoader';

import { createSendLmsQuizInvitationDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import userService from 'redux/user/user.service';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import {
    resetSendLmsQuizInvitationDetail,
    resetSendLmsQuizInvitationDetailPayload,
    setSendLmsQuizInvitationDetailMessage,
    setSendLmsQuizInvitationDetailPayload
} from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { USER_DEMO_PROFILE } from 'redux/user/user.const';
import { lmsQuizInviteToEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const';

import { validateEmail } from 'utils/validation-functions';

const InviteLmsQuizUserModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { sendLmsQuizInvitationDetail } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const { quizId } = useParams()
    const inputRef = useRef()

    const [emailAddress, setEmailAddress] = useState("")
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        return () => {
            dispatcher(resetSendLmsQuizInvitationDetail())
            dispatcher(resetSendLmsQuizInvitationDetailPayload())
        }
    }, [])

    useEffect(() => {
        if (sendLmsQuizInvitationDetail?.data) {
            dispatcher(resetSendLmsQuizInvitationDetail())
            dispatcher(setModal({
                ...modal,
                [modalConst.inviteLmsQuizUserModal.key]: {
                    ...modal[modalConst.inviteLmsQuizUserModal.key],
                    isVisible: false,
                    title: ""
                }
            }))
        }
    }, [sendLmsQuizInvitationDetail?.data])

    useEffect(() => {
        if (emailAddress && !validateEmail(emailAddress)) {
            setInvalidEmail("Please enter valid email address.")
        }
        if (emailAddress && validateEmail(emailAddress)) {
            setInvalidEmail("")
        }
    }, [emailAddress])

    const onHandleRemoveEmailAddress = useCallback((inviteEmail) => {
        const filteredUsers = sendLmsQuizInvitationDetail?.payload?.invitedUsers?.filter((emailItem) => emailItem?.email != inviteEmail?.email)
        dispatcher(setSendLmsQuizInvitationDetailPayload({
            ...sendLmsQuizInvitationDetail?.payload,
            invitedUsers: filteredUsers
        }))
        setEmailAddress("")
        if (sendLmsQuizInvitationDetail?.message) {
            dispatcher(setSendLmsQuizInvitationDetailMessage(null))
        }
    }, [sendLmsQuizInvitationDetail?.payload, emailAddress])

    const onHandleChangeEmailAddress = (event) => {
        if (sendLmsQuizInvitationDetail?.message) {
            dispatcher(setSendLmsQuizInvitationDetailMessage(null))
        }
        setEmailAddress(event?.target?.value?.toLowerCase())
    }

    const onHandleAddUser = async () => {
        if (emailAddress && !validateEmail(emailAddress)) {
            setInvalidEmail("Please enter valid email address.")
            return;
        }
        if (sendLmsQuizInvitationDetail?.payload?.invitedUsers?.length > 50) {
            setInvalidEmail("Maximum 50 emails can be sent.")
            return;
        }
        if (sendLmsQuizInvitationDetail?.payload?.invitedUsers?.length > 0) {
            const filterDuplicateEmail = sendLmsQuizInvitationDetail?.payload?.invitedUsers?.filter((emailItem) => (emailItem?.email === emailAddress))
            if (filterDuplicateEmail?.length > 0) {
                toast.warn("Duplicate emails are not allowed!")
                return;
            }
        }
        setIsLoading(true)
        let userObject = {
            name: emailAddress,
            profilePicUrl: USER_DEMO_PROFILE,
            email: emailAddress,
            isPlatformUser: false
        }
        const requestPayload = {
            query: { email: emailAddress }
        }
        const response = await userService.getUserList(requestPayload)
        if ((response.status === 200) && (response?.data.data?.records > 0)) {
            userObject = {
                name: `${response?.data.data?.results[0]?.firstName} ${response?.data.data?.results[0]?.lastName?.charAt(0)}`,
                profilePicUrl: response?.data.data?.results[0]?.profilePicUrl,
                email: response?.data?.data?.results[0]?.email,
                isPlatformUser: true
            }
        }
        dispatcher(setSendLmsQuizInvitationDetailPayload({
            ...sendLmsQuizInvitationDetail?.payload,
            invitedUsers: [...sendLmsQuizInvitationDetail?.payload?.invitedUsers, userObject]
        }))
        setIsLoading(false)
        setEmailAddress("")
        inputRef.current.focus()
    }

    const onHandleKeyDown = (e) => {
        if ((e.keyCode === 13) && emailAddress) {
            onHandleAddUser()
        }
        else if ((e.keyCode === 8) && (!emailAddress) && !!sendLmsQuizInvitationDetail?.payload?.invitedUsers) {
            const filteredUsers = sendLmsQuizInvitationDetail?.payload?.invitedUsers?.slice(0, -1)
            dispatcher(setSendLmsQuizInvitationDetailPayload({
                ...sendLmsQuizInvitationDetail?.payload,
                invitedUsers: filteredUsers
            }))
        }
    }

    const onHandleSendInvite = useCallback(() => {
        if (sendLmsQuizInvitationDetail?.isLoading || emailAddress || invalidEmail) return;

        if (!sendLmsQuizInvitationDetail?.payload?.invitedUsers || (sendLmsQuizInvitationDetail?.payload?.invitedUsers?.length === 0)) return;

        const body = {
            quiz_id: quizId,
            emails: sendLmsQuizInvitationDetail?.payload?.invitedUsers?.map((invitedUser) => invitedUser?.email),
            invite_to: lmsQuizInviteToEnum.STUDENT.value,
            invite_email_sent: true,
            sender_user_id: user?.user?.userId
        }
        dispatcher(createSendLmsQuizInvitationDetail(body))
    }, [sendLmsQuizInvitationDetail, emailAddress, invalidEmail])

    return (
        <div className={"space-y-5"}>
            <div className={'min-h-[14rem] block space-y-5'}>
                <div className={"w-full flex items-start justify-start gap-2"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-lg mt-0.5"}>
                        {"To:"}
                    </span>
                    <div className={cn(
                        "w-full inline-flex flex-wrap items-center justify-start gap-2 p-2",
                        "bg-back-ground-light border-b focus-within:border-primary-main"
                    )}
                    >
                        {sendLmsQuizInvitationDetail?.payload?.invitedUsers?.map((userItem, index) => (
                            <div
                                key={index}
                                className={cn(
                                    "w-fit pl-1 pr-2 py-1 flex items-center justify-start gap-1.5 bg-white shadow-sm border rounded-full",
                                    !userItem?.isPlatformUser && "border-accent-main"
                                )}
                            >
                                <div className={"relative w-[1.5rem] h-[1.5rem] rounded-full overflow-hidden"}>
                                    <img
                                        src={userItem?.profilePicUrl}
                                        className={"w-full h-full object-cover"}
                                        alt={""}
                                    />
                                </div>
                                <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wide"}>
                                    {userItem?.name}
                                </span>
                                <IoCloseOutline
                                    className={"text-text-700 hover:text-secondary-main text-md md:text-xl cursor-pointer"}
                                    onClick={() => onHandleRemoveEmailAddress(userItem)}
                                />
                            </div>
                        ))}
                        <div className={"flex flex-1 items-center justify-start gap-1.5"}>
                            <input
                                type={"email"}
                                ref={inputRef}
                                className={cn(
                                    "flex-1 focus:outline-none bg-transparent focus:border-primary-main",
                                    "font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500"
                                )}
                                placeholder={"Enter email address"}
                                value={emailAddress}
                                onChange={onHandleChangeEmailAddress}
                                autoFocus={true}
                                onKeyDown={onHandleKeyDown}
                            />
                            {/* <AiOutlineCheckCircle
                                className={"text-2xl text-text-800 cursor-pointer"}
                                onClick={onHandleAddUser}
                            /> */}
                        </div>
                        {isLoading &&
                            <div className={"flex justify-end"}>
                                <FaSpinner className={"animate-spin text-primary-dark"} />
                            </div>
                        }
                    </div>
                </div>
                <div className={"w-full flex items-center justify-center gap-1 mt-1"}>
                    {((emailAddress?.length > 0) && !!invalidEmail) &&
                        <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                            {invalidEmail}
                        </span>
                    }
                </div>
            </div>

            <div className={"w-full flex items-center justify-center"}>
                {sendLmsQuizInvitationDetail?.message &&
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide text-center"}>
                        {sendLmsQuizInvitationDetail?.message}
                    </span>
                }
            </div>

            <div className={"flex justify-end"}>
                <span
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        !sendLmsQuizInvitationDetail?.isLoading && "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                        sendLmsQuizInvitationDetail?.isLoading && "bg-secondary-main",
                        (!sendLmsQuizInvitationDetail?.payload?.invitedUsers || (sendLmsQuizInvitationDetail?.payload?.invitedUsers?.length === 0) || emailAddress || invalidEmail) && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                    )}
                    onClick={onHandleSendInvite}
                >
                    {sendLmsQuizInvitationDetail?.isLoading &&
                        <ButtonLoader isLoading={sendLmsQuizInvitationDetail?.isLoading} />
                    }
                    {!sendLmsQuizInvitationDetail?.isLoading && "Send"}
                </span>
            </div>
        </div>
    )
}

export default InviteLmsQuizUserModal;