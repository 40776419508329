import { useState, useEffect, memo, useCallback } from "react";

import { cn } from "utils/cn.utils";

import { AiOutlinePlus } from "react-icons/ai";

import ComponentLoader from "components/loader/ComponentLoader";
import InfinitePagination from "components/pagination/InfinitePagination";
import { libraryTabConst } from "components/modals/lmsModals/attachLibraryModal/data";

import { getLmsLecturePageResourceList } from "redux/edulyteLms/lmsLecture/lmsLecture.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetLocalTabs, setLocalTabs, setModal } from "redux/local/local.slice";
import { resetLmsLecturePageResourceList } from "redux/edulyteLms/lmsLecture/lmsLecture.slice";
import { modalConst } from "redux/local/local.const";
import { lmsResourceTypeEnum, resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";

const LmsResourceListItems = memo(({ resourceSearch }) => {
    const { modal, localTabs } = useAppState((state) => state.local)
    const { lmsLecturePageResourceList } = useAppState((state) => state.lms.lmsLecture)

    const dispatcher = useAppDispatcher()

    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        const requestDataPayload = {
            page: 1,
            records: 10,
            file_type: resourceFileTypeEnum?.VIDEO?.value,
            type: lmsResourceTypeEnum?.YOUTUBE?.value
        }
        if (resourceSearch) {
            requestDataPayload["search"] = resourceSearch
        }
        dispatcher(getLmsLecturePageResourceList(requestDataPayload))

        return () => {
            dispatcher(resetLmsLecturePageResourceList())
        }
    }, [])

    const fetchMoreData = () => {
        if (lmsLecturePageResourceList?.data?.page === lmsLecturePageResourceList?.data?.totalPages) return;

        const requestDataPayload = {
            page: 1,
            records: lmsLecturePageResourceList?.data?.records
                ? (lmsLecturePageResourceList?.data?.records + 10)
                : lmsLecturePageResourceList?.data?.records || 10,
            file_type: resourceFileTypeEnum?.VIDEO?.value,
            type: lmsResourceTypeEnum?.YOUTUBE?.value
        }
        if (resourceSearch) {
            requestDataPayload["search"] = resourceSearch
        }
        dispatcher(getLmsLecturePageResourceList(requestDataPayload))
    }

    const onHandleDragStart = (event, resourceObject) => {
        event.dataTransfer.setData('application/json', JSON.stringify(resourceObject))
    }

    const onHandleOpenResourceModal = useCallback(() => {
        dispatcher(resetLocalTabs())
        dispatcher(setLocalTabs({
            ...localTabs,
            tabList: Object.values(libraryTabConst),
            activeTabList: [libraryTabConst.RESOURCES.value],
            currentActiveTab: libraryTabConst.RESOURCES.value,
            tabItem: {
                ...localTabs?.tabItem,
                payload: null
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true,
        }))
    }, [modal, localTabs])


    return (
        <>
            {lmsLecturePageResourceList?.isLoading &&
                <ComponentLoader isLoading={lmsLecturePageResourceList?.isLoading} />
            }
            {lmsLecturePageResourceList?.message &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {lmsLecturePageResourceList?.message}
                    </span>
                </div>
            }
            {lmsLecturePageResourceList?.data && (lmsLecturePageResourceList?.data?.results?.length === 0) &&
                <div className={"w-full flex items-center justify-center"}>
                    <button
                        className={cn(
                            'w-full flex justify-center items-center gap-1 bg-white rounded-lg px-3 py-1.5',
                            'bg-text-200 hover:bg-text-300 ',
                            'font-bodyPri font-normal text-text-900 text-sm tracking-wide whitespace-nowrap'
                        )}
                        onClick={onHandleOpenResourceModal}
                    >
                        <AiOutlinePlus className={''} />
                        <span className={""}>
                            {"Add Resource"}
                        </span>
                    </button>
                </div>
            }
            {(lmsLecturePageResourceList?.data && (lmsLecturePageResourceList?.data?.results?.length > 0)) &&
                <InfinitePagination
                    scrollableTarget={"scrollableDiv"}
                    dataLength={lmsLecturePageResourceList?.data?.totalRecords}
                    fetchData={fetchMoreData}
                    hasMore={(lmsLecturePageResourceList?.data?.page != lmsLecturePageResourceList?.data?.totalPages)}
                    loader={<ComponentLoader isLoading={lmsLecturePageResourceList?.isLoading} />}
                    isShowEndMessage={false}
                >
                    {lmsLecturePageResourceList?.data?.results?.map((resourceItem) => (
                        <div
                            key={resourceItem?.id}
                            className={cn(
                                'font-bodyPri line-clamp-1 mb-2 hover:bg-back-ground-lightBlue rounded p-1',
                                isDragging ? "cursor-grabbing" : "cursor-grab",
                            )}
                            draggable={true}
                            onDragStart={(event) => onHandleDragStart(event, resourceItem)}
                            onMouseDown={() => setIsDragging(true)}
                            onMouseUp={() => setIsDragging(false)}
                            onMouseMove={() => {
                                if (isDragging) {
                                    setIsDragging(false)
                                }
                            }}
                        >
                            <span className={"pl-1"}>{`${resourceItem?.title}`}</span>
                        </div>
                    ))}
                </InfinitePagination>
            }
        </>
    )
});

export default LmsResourceListItems;