import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import {
    PageContentStyle
} from "pages/auth/walletConfirmation/WalletConfirmationPageStyle";

import PageLoader from 'components/loader/PageLoader';

import SuccessWalletConfirmation from 'pages/auth/walletConfirmation/components/SuccessWalletConfirmation';
import FailedWalletConfirmation from 'pages/auth/walletConfirmation/components/FailedWalletConfirmation';
import NotExistWalletConfirmation from 'pages/auth/walletConfirmation/components/NotExistWalletConfirmation';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getWalletConfirmationByVirtualTxnId } from 'redux/wallet/wallet.request';
import { setClearWalletConfirmation } from 'redux/wallet/wallet.slice';
import { setClearModal } from 'redux/local/local.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

import { useTitle } from 'hooks/useTitle';


const walletConfirmationCardAction = {
    SUCCESS: {
        value: 'success'
    },
    FAILED: {
        value: 'failed'
    },
    NOT_EXIST: {
        value: 'not_exist'
    }
}


const WalletConfirmationPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { walletConfirmation } = useAppState((state) => state.wallet)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()
    const [title, setTitle] = useTitle()

    const from = location.state?.from?.url || pagesInfo.DASHBOARD.pagePath;

    const [walletConfirmationAction, setWalletConfirmationAction] = useState('')

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.WALLET_CONFIRMATION))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Transaction Confirmation on Wallet | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(setClearModal())
    }, [])

    useEffect(() => {
        let query = generateQueryParams(location.search)
        query = query?.redirect_status ? query : null

        if (!query) {
            setWalletConfirmationAction(walletConfirmationCardAction.NOT_EXIST.value)
            navigate(from, { replace: true })
        } else if (query?.redirect_status === 'success') {
            if (query?.virtual_txn_id) {
                setWalletConfirmationAction(walletConfirmationCardAction.SUCCESS.value)
                dispatcher(getWalletConfirmationByVirtualTxnId(query?.virtual_txn_id))
            } else {
                setWalletConfirmationAction(walletConfirmationCardAction.NOT_EXIST.value)
            }
        } else if (query?.redirect_status === 'failed') {
            if (Object.values(query).length > 1) {
                setWalletConfirmationAction(walletConfirmationCardAction.NOT_EXIST.value)
            } else {
                setWalletConfirmationAction(walletConfirmationCardAction.FAILED.value)
            }
        } else {
            setWalletConfirmationAction(walletConfirmationCardAction.NOT_EXIST.value)
        }

        return () => {
            dispatcher(setClearWalletConfirmation())
        }

    }, [location.search])

    if (!walletConfirmationAction || walletConfirmation?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (walletConfirmation?.errorMsg) {
        return (
            <PageContentStyle>
                <NotExistWalletConfirmation />
            </PageContentStyle>
        )
    }

    return (
        <PageContentStyle>
            <div className={"my-10 md:my-0"}>
                {walletConfirmationAction === walletConfirmationCardAction.SUCCESS.value &&
                    <SuccessWalletConfirmation />
                }
                {walletConfirmationAction === walletConfirmationCardAction.FAILED.value &&
                    <FailedWalletConfirmation />
                }
                {walletConfirmationAction === walletConfirmationCardAction.NOT_EXIST.value &&
                    <NotExistWalletConfirmation />
                }
            </div>
        </PageContentStyle>
    )
};

export default WalletConfirmationPage;