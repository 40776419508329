import { baseAuthApiInstance } from "apiInstances/axios.apiInstance"
import { url } from "redux/edrazorpay/edrazorpay.const"


class EdrazorpayService {

    static edrazorpayService = new EdrazorpayService()

    createEdrazorpayOrder = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.CREATE_EDRAZORPAY_ORDERS,
            body
        )

        return response;
    }

    verifyEdrazorpayPayment = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.VERIFY_EDRAZORPAY_PAYMENT,
            body
        )

        return response;
    }

}
export default EdrazorpayService.edrazorpayService;