import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";

import { motion } from "framer-motion";

import { FaSpinner } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { MdEdit } from "react-icons/md";

import ButtonLoader from "components/loader/button-loader";
import ToolTipView from "components/tooltipView";

import { validateCreateContactPayload } from "components/modals/crmModals/data";

import { uploadFileToS3 } from "redux/storage/storage.request";
import { createCrmContactDetail } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetAddCrmContactDetail, resetAddCrmContactDetailPayload, setAddCrmContactDetailPayload } from "redux/crm/crm.slice";
import { resetUploadFile } from "redux/storage/storage.slice";
import { USER_DEMO_PROFILE } from "redux/user/user.const";
import { mbToKbConst, profilePicTypeConst } from "redux/storage/storage.const";

function AddContactIdentity({ onClose }) {
    const uploadFile = useAppState((state) => state.storage.uploadFile)
    const user = useAppState((state) => state.user.user)
    const addCrmContactDetail = useAppState((state) => state.crm.addCrmContactDetail)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (uploadFile?.data && uploadFile?.data?.length > 0) {
            dispatcher(setAddCrmContactDetailPayload({
                ...addCrmContactDetail?.payload,
                profilePicInfo: {
                    fileList: [],
                    file: {},
                    fileUrl: "",
                    picture_url: uploadFile?.data[0]?.fileUrl
                }
            }))
            dispatcher(resetUploadFile())
            // toast.success("Profile picture uploaded!")
        }
    }, [uploadFile?.data])

    useEffect(() => {
        if (!!addCrmContactDetail?.payload?.profilePicInfo?.fileList?.length) {
            dispatcher(uploadFileToS3(addCrmContactDetail?.payload?.profilePicInfo?.fileList, user?.user?.userId))
        }
    }, [addCrmContactDetail?.payload?.profilePicInfo?.fileList])

    const onHandleSelectImage = useCallback((event) => {
        if (uploadFile?.isLoading) return;

        const files = event.target.files;
        if (!files || files.length === 0) {
            alert("Image not selected!")
            return;
        }

        const file = files[0];
        if (file?.size <= (2 * mbToKbConst)) {
            if (profilePicTypeConst?.includes(file?.type)) {
                dispatcher(setAddCrmContactDetailPayload({
                    ...addCrmContactDetail?.payload,
                    profilePicInfo: {
                        ...addCrmContactDetail?.payload?.profilePicInfo,
                        fileList: event?.target?.files,
                        file: file,
                        fileUrl: URL.createObjectURL(file)
                    }
                }))
            } else {
                toast.warn("Please select a valid document!")
            }
        } else {
            toast.error("File size is too large!")
        }
    }, [uploadFile])

    const onHandleCloseModal = useCallback(() => {
        dispatcher(resetAddCrmContactDetail())
        dispatcher(resetAddCrmContactDetailPayload())
        onClose()
    }, [])

    const onHandleCreateNewContact = async () => {
        if (addCrmContactDetail?.isLoading) return;

        const { errorMsg, requestPayload } = await validateCreateContactPayload(addCrmContactDetail?.payload)
        if (errorMsg) {
            toast.warn(errorMsg)
        }
        if (!errorMsg && requestPayload) {
            const requestDataPayload = { owner_user_id: user?.user?.userId, ...requestPayload }

            dispatcher(createCrmContactDetail(requestDataPayload))
        }
    }

    return (
        <div className="flex flex-col-reverse gap-5 sm:flex-row justify-center sm:justify-between items-center sm:items-start">
            <div className="w-28 aspect-square rounded-full border border-primary-main relative">
                <img
                    src={addCrmContactDetail?.payload?.profilePicInfo?.picture_url || USER_DEMO_PROFILE}
                    alt={`${addCrmContactDetail?.payload?.title}. ${addCrmContactDetail?.payload?.first_name} ${addCrmContactDetail?.payload?.last_name}`}
                    className={"w-full h-full rounded-full object-cover"}
                />
                <label htmlFor={"file"} className="absolute -bottom-1 -right-1 w-8 h-8 flex justify-center items-center bg-primary-light rounded-full border-2 border-white cursor-pointer hover:text-secondary-dark">
                    <input
                        className={"w-full"}
                        type={"file"}
                        id={"file"}
                        name={"file"}
                        style={{ display: "none" }}
                        multiple={false}
                        accept={"image/x-png, image/png, image/jpg, image/jpeg, image/gif"}
                        onChange={onHandleSelectImage}
                    />
                    {uploadFile?.isLoading && <FaSpinner className={"animate-spin text-primary-dark text-md"} />}
                    {!uploadFile?.isLoading && <MdEdit />}
                </label>
            </div>
            <div className="flex items-center gap-3">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ duration: 0.3 }}
                    className="flex justify-center items-center h-8 px-5 text-white bg-primary-dark focus:outline-none hover:bg-secondary-dark rounded ease-in-out duration-200"
                    onClick={onHandleCreateNewContact}
                >
                    {addCrmContactDetail?.isLoading &&
                        <ButtonLoader isLoading={addCrmContactDetail?.isLoading} />
                    }
                    {!addCrmContactDetail?.isLoading && "Save"}
                </motion.button>
                <ToolTipView content={"Close"}>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ duration: 0.3 }}
                        className="flex justify-center items-center h-8 w-8 text-lg hover:bg-back-ground-light rounded-full ease-in-out duration-200"
                        onClick={onHandleCloseModal}
                    >
                        <AiOutlineClose />
                    </motion.button>
                </ToolTipView>
            </div>
        </div>
    );
}

export default AddContactIdentity;