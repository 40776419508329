import { toast } from 'react-toastify';

import linkTreeService from './linkTree.service';
import {
    setAddLinkInBioLoading,
    setAddLinkInBioData,
    seAddLinkInBioErrorMsg,

    setUpdateLinkLoading,
    setUpdateLinkData,
    seUpdateLinkErrorMsg,

    setDeleteLinkLoading,
    seDeleteLinkErrorMsg,

    setAddLinkLoading,
    setAddLinkData,
    seAddLinkErrorMsg,

    setReorderLinkLoading,
    seReorderLinkErrorMsg,

    setGetLinkTreeLoading,
    setGetLinkTreeData,
    seGetLinkTreeMessage,

    setUpdateLinkTreeLoading,
    setUpdateLinkTreeData,
    seUpdateLinkTreeErrorMsg,
} from 'redux/linkTree/linkTree.slice';

export const createLinkInBio = (body) => async (dispatch) => {
    dispatch(setAddLinkInBioLoading(true))

    try {
        const requestData = {
            body
        }
        const response = await linkTreeService.createLinkInBio(requestData)
        if (response.status === 201) {
            dispatch(setAddLinkInBioData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        dispatch(seAddLinkInBioErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setAddLinkInBioLoading(false))
    }
}

export const getLinkTreeRequest = (request) => async (dispatch) => {
    dispatch(setGetLinkTreeLoading(true))

    try {
        const response = await linkTreeService.getLinkTree(request)
        if (response.status === 200) {
            dispatch(setGetLinkTreeData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(seGetLinkTreeMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setGetLinkTreeLoading(false))
    }
}

export const updateLink = (linkId, body) => async (dispatch, getState) => {
    dispatch(setUpdateLinkLoading(true))

    try {
        const { createLinkTree } = getState().linkTree
        const requestData = {
            params: { linkId: linkId },
            body: body
        }
        const response = await linkTreeService.updateLink(requestData)
        if (response.status === 200) {
            dispatch(setUpdateLinkData(response.data.data))
            const links = createLinkTree?.data?.result?.links?.map(link => link.id === linkId ? response.data.data?.result : link)
            dispatch(setAddLinkInBioData({
                ...createLinkTree?.data,
                result: { ...createLinkTree?.data?.result, links }
            }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(seUpdateLinkErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setUpdateLinkLoading(false))
    }
}

export const deleteLink = (linkId) => async (dispatch, getState) => {
    dispatch(setDeleteLinkLoading(true))

    try {
        const { getLinkTree } = getState().linkTree
        const requestData = {
            params: { linkId: linkId }
        }
        const response = await linkTreeService.deleteLink(requestData)
        if (response.status === 200) {
            const links = getLinkTree?.data?.result?.links?.filter(link => link.id !== linkId)
            dispatch(setGetLinkTreeData({
                ...getLinkTree?.data,
                result: { ...getLinkTree?.data?.result, links }
            }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(seDeleteLinkErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setDeleteLinkLoading(false))
    }
}

export const addNewLink = (body) => async (dispatch, getState) => {
    dispatch(setAddLinkLoading(true))

    try {
        const { getLinkTree } = getState().linkTree
        const requestData = {
            body
        }
        const response = await linkTreeService.addLink(requestData)
        if (response.status === 201) {
            dispatch(setAddLinkData(response?.data?.data?.result))
            const links = [...getLinkTree?.data?.result?.links, response?.data?.data?.result]
            dispatch(setGetLinkTreeData({
                ...getLinkTree?.data,
                result: { ...getLinkTree?.data?.result, links }
            }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        dispatch(seAddLinkErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setAddLinkLoading(false))
    }
}

export const reorderLink = (id, body) => async (dispatch, getState) => {
    dispatch(setReorderLinkLoading(true))

    try {
        const requestData = {
            params: { id: id },
            body: body
        }
        const response = await linkTreeService.reorderLink(requestData)
        if (response.status === 200) {
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(seReorderLinkErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setReorderLinkLoading(false))
    }
}

export const updateLinkTreeRequest = (linkTreeId, body) => async (dispatch) => {
    dispatch(setUpdateLinkTreeLoading(true))

    try {
        const requestData = {
            params: { linkTreeId },
            body: body
        }
        const response = await linkTreeService.updateLinktree(requestData)
        if (response.status === 200) {
            dispatch(setUpdateLinkTreeData(response?.data?.data?.result))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(seUpdateLinkTreeErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setUpdateLinkTreeLoading(false))
    }
}


