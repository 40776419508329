import { Bars } from "react-loader-spinner";

const PageLoader = ({ className = "" }) => {
    return (
        <div className={`w-full h-screen bg-back-ground-light ${className}`}>
            <div className={"w-full h-full flex justify-center items-center"}>
                <Bars heigth="100" width="100" color="grey" ariaLabel="loading-indicator" />
            </div>
        </div>
    )
}

export default PageLoader