import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import SearchIcon from '@mui/icons-material/Search';

import {
    PageContentStyle,
    SearchDiv,
    Section,
    Container
} from "pages/global/segments/style";
import { popularCategoriesStats } from "pages/global/segments/data";

import PageLoader from 'components/loader/PageLoader';
import Footer1 from 'components/footer/footer1/Footer1';

import { pagesInfo } from 'utils/pagesInfo';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getAllSegmentCategoryList } from 'redux/segment/segment.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setFilterProps, setClearSegmentList, setClearFilterProps } from 'redux/segment/segment.slice';
import { filterName } from "redux/segment/segment.const";

import { useTitle } from 'hooks/useTitle';

const CategorySegments = () => {
    const { segmentList, filterProps } = useAppState((s) => s.segment)

    const dispatcher = useAppDispatcher()

    const [selectedSegment, setSelectedSegment] = useState("")

    useEffect(() => {
        dispatcher(getAllSegmentCategoryList())

        return () => {
            dispatcher(setClearSegmentList())
            dispatcher(setClearFilterProps())
        }
    }, [])

    if (segmentList?.isLoading) {
        return <PageLoader />
    }

    return (
        <PageContentStyle>
            <Section>
                <Container>
                    <div className='flex flex-col md:flex-row justify-between items-start gap-5'>
                        <div className='flex flex-col justify-start gap-3'>
                            <h1 className='font-tagLine font-bold text-2xl text-text-900'>
                                {"Explore topics and skills"}
                                <br />
                                <p className='font-bodyPri font-normal text-base text-text-800'>
                                    {"Learn live from verified tutors and get ahead in life"}
                                </p>
                            </h1>
                        </div>
                        <div className={"flex flex-col items-end justify-start gap-3"}>
                            <Link
                                to={pagesInfo?.TEACH_WITH_US.pagePath}
                                className={cn(
                                    "w-60 px-3 py-1 border-2 border-secondary-main rounded-xl",
                                    "bg-secondary-dark text-text-50 hover:bg-text-50 hover:text-secondary-dark",
                                    'font-bodyPri font-bold text-lg text-text-900 text-center'
                                )}>
                                {"Become Our Tutor"}
                            </Link>
                            <SearchDiv className={""}>
                                <input
                                    className={"focus:outline-none"}
                                    onChange={(e) =>
                                        dispatcher(setFilterProps({
                                            filterProps: { ...filterProps, searchText: e.target.value },
                                            list: { popularList: popularCategoriesStats, segmentList: segmentList?.segmentList },
                                            filterName: filterName.SEGMENT.value
                                        }))}
                                    placeholder={'Search by keyword'}
                                />
                                <SearchIcon />
                            </SearchDiv>
                        </div>
                    </div>
                </Container>
            </Section>
            <Section>
                <Container>
                    <div className={"space-y-5"}>
                        <div className='font-bold font-bodyPri tracking-wide text-text-900 text-xl'>
                            {"Popular topics"}
                        </div>
                        <div className={"grid grid-cols-12 gap-2"}>
                            {popularCategoriesStats?.map((item, index) => (
                                <Link to={item?.url} key={index}
                                    className={cn(
                                        "col-span-4 sm:col-span-3 lg:col-span-2 w-28 h-28 md:w-36 md:h-36 flex flex-col justify-center items-center gap-3 cursor-pointer",
                                        "bg-white border-2 rounded-lg group",
                                        "border-primary-light hover:border-secondary-main",
                                    )}>
                                    <span className={"w-16 h-16 overflow-hidden rounded-full"}>
                                        <img src={item.imageUrl} alt={"category-icon"} className={"w-16 h-16 object-cover"} />
                                    </span>
                                    <span className={"font-bodyPri font-medium text-xs lg:text-base text-text-900 text-center truncate group-hover:text-secondary-main"}>
                                        {item.label}
                                    </span>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <hr className={"w-full border border-divider-medium"} />
                    <div className={"space-y-5"}>
                        <div className='font-bold font-bodyPri tracking-wide text-text-900 text-xl'>
                            {"Featured topics"}
                        </div>
                        <div className='grid grid-cols-12 gap-y-8 gap-x-5'>
                            {segmentList?.filteredSegmentList && segmentList?.filteredSegmentList?.map((items, index) => {
                                const lastIndex = items?.segment === selectedSegment ? items?.categories?.length : 5
                                return (
                                    <div key={index} className="overflow-hidden col-span-6 md:col-span-4 lg:col-span-3 flex flex-col justify-start gap-y-3">
                                        <span className='font-semibold font-bodyPri text-text-800 whitespace-nowrap truncate'>
                                            {items.segment}
                                        </span>
                                        <div className='flex flex-col gap-y-2 overflow-hidden'>
                                            {items?.categories?.slice(0, lastIndex)?.map((item, index) => (
                                                <Link to={`${pagesInfo?.FIND_COURSES?.pagePath}/${item?.category?.replaceAll(" ", "-")?.toLowerCase()?.toString()}`} key={index}
                                                    className='font-normal font-bodyPri text-base text-primary-dark underline whitespace-nowrap truncate cursor-pointer'
                                                >
                                                    {item?.category}
                                                </Link>
                                            ))}
                                            {items?.categories?.length >= 5 &&
                                                <span onClick={() => setSelectedSegment((prevValue) => prevValue === items?.segment ? "" : items?.segment)}
                                                    className='text-blue-700 underline whitespace-nowrap truncate cursor-pointer'>
                                                    {items?.segment === selectedSegment ? "See less..." : "See more..."}
                                                </span>}
                                        </div>
                                    </div>
                                )
                            })}
                            {(segmentList?.length === 0 || segmentList?.errorMsg) &&
                                <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                                    {segmentList?.errorMsg}
                                </div>
                            }
                        </div>
                    </div>
                </Container>
            </Section>
        </PageContentStyle>
    )
}

const Segments = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.SEGMENTS))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Explore Popular Topics and Skills | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    return (
        <>
            <CategorySegments />
            <Footer1 />
        </>
    )
}

export default Segments;