import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MdAdd } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import ToolTipView from 'components/tooltipView';
import Pagination from 'components/pagination/Pagination';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import TableComponent from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTableComponent';
import { quizStudentHeaderConst, quizStudentMenuItemsConst, quizParticipantsNavigationOptions, lmsQuizTooltips } from '../../../editLMSQuiz.data';

import { deleteLmsQuizStudentDetail, getLmsQuizStudentList, updateLmsQuizStudentDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetLmsQuizStudentList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';
import { lmsQuizStudentStatusEnum, lmsQuizJoiningFlowEnum, lmsQuizStudentIsActiveEnum, lmsQuizStatusEnum } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';

const RECORDS = 10;

const JoinedParticipants = () => {
    const { lmsQuizDetail, lmsQuizStudentList } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            dispatcher(resetLmsQuizStudentList())
        }
    }, [])

    useEffect(() => {
        if (lmsQuizDetail?.data?.id) {
            const query = {
                page: 1,
                records: RECORDS,
                quiz_id: lmsQuizDetail?.data?.id
            }
            dispatcher(getLmsQuizStudentList(query))
        }
    }, [lmsQuizDetail?.data?.id])

    const onHandleSelectMenu = useCallback((menuItem, studentItem) => {
        switch (menuItem?.value) {
            case quizStudentMenuItemsConst?.ACTIVE?.value:
                if (quizStudentMenuItemsConst?.ACTIVE?.value === studentItem?.status) return;
                dispatcher(updateLmsQuizStudentDetail(studentItem?.id, { added_by: studentItem?.added_by?.userId, is_active: lmsQuizStudentIsActiveEnum.ACTIVE.value }))
                break;
            case quizStudentMenuItemsConst?.INACTIVE?.value:
                if (quizStudentMenuItemsConst?.INACTIVE?.value === studentItem?.status) return;
                dispatcher(updateLmsQuizStudentDetail(studentItem?.id, { added_by: studentItem?.added_by?.userId, is_active: lmsQuizStudentIsActiveEnum.INACTIVE.value }))
                break;
            case quizStudentMenuItemsConst?.BLOCKED?.value:
                if (quizStudentMenuItemsConst?.BLOCKED?.value === studentItem?.status) return;
                dispatcher(updateLmsQuizStudentDetail(studentItem?.id, { added_by: studentItem?.added_by?.userId, is_active: lmsQuizStudentIsActiveEnum.BLOCKED.value }))
                break;
            case quizStudentMenuItemsConst?.DELETE?.value:
                dispatcher(deleteLmsQuizStudentDetail(studentItem?.id))
        }
    }, [lmsQuizDetail?.data])

    const onHandleChangePage = useCallback((page) => {
        const query = {
            page: page,
            records: RECORDS,
            quiz_id: lmsQuizDetail?.data?.id
        }
        dispatcher(getLmsQuizStudentList(query))
    }, [lmsQuizDetail?.data])

    const onHandleInviteParticipants = useCallback(() => {
        if (lmsQuizDetail?.data?.quiz_setting?.status !== lmsQuizStatusEnum?.PUBLISHED?.value) return;

        navigate(`${pagesInfo?.EDIT_LMS_QUIZ?.pagePath}/${lmsQuizDetail?.data?.id}/edit/${quizParticipantsNavigationOptions?.INVITED?.to}?action=invite-participants`)
    }, [lmsQuizDetail?.data])

    const StudentImageContainer = ({ profilePicUrl }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={"relative w-10 h-10 overflow-hidden rounded-full"}>
                    <img
                        src={profilePicUrl}
                        className={"w-full h-full object-cover"}
                    />
                </div>
            </div>
        )
    }

    const quizStudentRows = lmsQuizStudentList?.data?.results?.map((student) => [
        <StudentImageContainer profilePicUrl={student?.student?.user?.profile_pic_url} />,
        student?.student?.user?.userId || "-",
        student?.student?.user?.first_name + " " + student?.student?.user?.last_name?.charAt(0) + "." || "-",
        lmsQuizJoiningFlowEnum[student?.joining_flow?.toUpperCase()]?.label,
        lmsQuizStudentStatusEnum[student?.status?.toUpperCase()]?.label || "-",
        lmsQuizStudentIsActiveEnum[student?.is_active?.toUpperCase()]?.label || "-",
        dayjs(student?.joined_at)?.tz(timeZone)?.format("ddd, MMM D, YYYY") || "-",
        <div className={"flex items-center justify-center"}>
            <ThreeDotMenu
                menuItems={Object.values(quizStudentMenuItemsConst)}
                onHandleSelect={(menuItem) => onHandleSelectMenu(menuItem, student)}
            />
        </div>
    ])

    return (
        <div className={"px-5 space-y-3"}>
            {lmsQuizStudentList?.isLoading &&
                <ComponentLoader isLoading={lmsQuizStudentList?.isLoading} />
            }
            {(!lmsQuizStudentList?.isLoading && lmsQuizStudentList?.data?.records > 0) &&
                <TableComponent
                    headers={Object.values(quizStudentHeaderConst)}
                    subRowHeader={[]}
                    rows={(lmsQuizStudentList?.data?.results?.length > 0) ? quizStudentRows : []}
                    rowHeaderColor={"bg-back-ground-medium"}
                    alternateRowColor={"bg-primary-light"}
                />
            }
            {(lmsQuizStudentList?.data && (lmsQuizStudentList?.data?.totalPages > 1)) &&
                <div className={"flex justify-center items-center"}>
                    <Pagination
                        page={lmsQuizStudentList?.data?.page}
                        totalPages={lmsQuizStudentList?.data?.totalPages}
                        onChangePage={onHandleChangePage}
                    />
                </div>
            }
            {(lmsQuizStudentList?.data && (lmsQuizStudentList?.data?.records === 0)) &&
                <div className={'w-full h-full flex flex-col items-center justify-center gap-1 text-center text-base font-bodyPri tracking-wide'}>
                    <div className={"w-[16rem] h-[16rem] overflow-hidden"}>
                        <img
                            src={"https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/lms-quiz/quiz-student-not-found.jpg"}
                            alt={"no-student-found"}
                            className={"w-full h-full object-cover"}
                        />
                    </div>
                    <ToolTipView
                        content={(lmsQuizDetail?.data?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value)
                            ? lmsQuizTooltips?.publishedInviteBtnTooltip
                            : lmsQuizTooltips?.disabledInviteBtnTooltip
                        }
                    >
                        <div
                            className={cn(
                                "flex items-center justify-center gap-1 py-1 px-3 rounded-lg",
                                (lmsQuizDetail?.data?.quiz_setting?.status === lmsQuizStatusEnum?.PUBLISHED?.value)
                                    ? "border border-primary-dark text-primary-dark cursor-pointer hover:bg-primary-dark group"
                                    : "border border-divider-medium bg-text-300 text-text-400 cursor-not-allowed"
                            )}
                            onClick={onHandleInviteParticipants}
                        >
                            <MdAdd className={"text-lg group-hover:text-text-50"} />
                            <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                                {"Invite someone"}
                            </span>
                        </div>
                    </ToolTipView>
                </div>
            }
            {(!lmsQuizDetail?.isLoading && lmsQuizDetail?.message) &&
                <div className={"flex items-center justify-start gap-1"}>
                    <span className={"font-bodyPri font-medium text-red-500 text-base tracking-wide"}>
                        {lmsQuizDetail?.message}
                    </span>
                </div>
            }
        </div>
    )
}

export default JoinedParticipants;