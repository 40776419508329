export const url = {
    UPLOAD_FILE_TO_S3: "/storages/upload"
}

export const uploadFileTypeEnum = {
    STATIC: {
        label: "Static",
        value: "static"
    },
    MEDIA: {
        label: "Media",
        value: "media"
    },
    DRIVE: {
        label: "Drive",
        value: "drive"
    }
}

export const ownerTypeEnum = Object.freeze({
    org: {
        key: "org",
        label: "Org"
    },
    user: {
        key: "user",
        label: "User"
    }
})

export const mbToKbConst = 1048576;
export const GBToBConst = 1073741824;

export const profilePicTypeConst = ["image/x-png", "image/png", "image/jpg", "image/jpeg", "image/gif"]

export const videoFileTypeConst = ["video/mp4", "video/x-m4v", "video/*"]

export const documentFileTypeConst = ["application/pdf", "application/msword", "image/x-png", "image/png", "image/jpg", "image/jpeg"]

export const fileCategories = {
    INTRO_VIDEO: {
        value: "intro_video"
    },
    courseThumbnail: {
        value: "courseThumbnail"
    },
    profilePicture: {
        value: "profilePicture"
    },
}

export const documentFileTypeEnum = {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": ".pptx",
    "application/pdf": ".pdf",
    "application/rtf": ".rtf",
    "image/jpeg": ".jpg",
    "image/png": ".png",
    "image/gif": ".gif",
    "image/bmp": ".bmp",
    "image/svg+xml": ".svg",
    "image/tiff": ".tiff",
}

export const videoFileTypeEnum = {
    "video/mp4": ".mp4",
    "video/x-msvideo": ".avi",
    "video/quicktime": ".mov",
    "video/x-ms-wmv": ".wmv",
    "video/x-matroska": ".mkv",
    "video/x-flv": ".flv",
    "video/webm": ".webm",
}

export const imageFileTypeEnum = {
    "image/jpeg": ".jpg",
    "image/png": ".png",
    "image/gif": ".gif",
    "image/bmp": ".bmp",
    "image/svg+xml": ".svg",
    "image/tiff": ".tiff",
    "image/vnd.adobe.photoshop": ".psd",
}