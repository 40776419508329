import { CredentialResponse, useGoogleOneTapLogin } from "@react-oauth/google";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { useEffect, useState } from "react";

import { createOneTapGoogleLogin } from "redux/auth/auth.request";

import { timeZone } from "utils/dateTime.utils";


type Props = {
    children: React.ReactNode
}

const GoogleOneTapProvider = ({ children }: Props) => {
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()
    const [isMount, setIsMount] = useState(false)

    useEffect(() => {
        setIsMount(true)
    }, [])

    const onHandleSuccess = (credentialResponse: CredentialResponse): void => {
        const requestPayload = {
            timeZone: timeZone,
            credentials: credentialResponse?.credential,
            source: window.location.href
        }
        dispatcher(createOneTapGoogleLogin(requestPayload))
    }

    useGoogleOneTapLogin({
        disabled: (session?.isLoggedIn || session?.isLoading || user?.isLoading),
        onSuccess: onHandleSuccess,
        onError: () => {
            console.log('Login Failed');
        },
        auto_select: true,
        use_fedcm_for_prompt: true
    })

    if (!isMount) {
        return null
    }

    return (
        <>{children}</>
    );
};

export default GoogleOneTapProvider;
