import { useState } from 'react';
import { MdClose } from "react-icons/md";
import { motion } from 'framer-motion';

import primary from 'assets/image/help.gif'
import disconnect from 'assets/image/disconnect.png'
import error from 'assets/image/alert-icon.png'
import success from 'assets/image/success-icon.png'

import { Button } from '@mui/material';

export const useConfirm = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => { });

    const confirm = (message, confirmAction) => {
        return new Promise((resolve) => {
            setMessage(message);
            setIsOpen(true);
            setOnConfirm(() => () => {
                resolve(true);
                setIsOpen(false);
                if (confirmAction) {
                    confirmAction()
                }
            });
        });
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const ConfirmDialog = ({ confirmBtnText = "Confirm", title = "", color = "error" }) => (

        isOpen && (
            <motion.div
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 font-bodyPri"
                onClick={handleCancel}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <motion.div
                    className="relative flex flex-col items-center gap-10 w-72 font-bodyPri bg-white px-5 py-8 rounded-lg shadow-lg text-center"
                    onClick={(e) => e.stopPropagation()}
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0.8 }}
                    transition={{ duration: 0.3 }}
                >
                    <h3 className='font-medium text-lg text-text-900'>{title}</h3>
                    <img
                        src={color === "primary" ? primary : color === "warning" ? error : color === "success" ? success : disconnect}
                        className='w-20 aspect-square object-contain'
                        alt={title || 'disconnect'}
                    />
                    <p className="text-sm text-back-ground-black">{message}</p>
                    <Button
                        variant='contained'
                        color={color}
                        onClick={onConfirm}
                    >
                        {confirmBtnText}
                    </Button>
                    <button
                        onClick={handleCancel}
                        className="absolute top-3 right-3 text-lg text-text-700 hover:text-secondary-dark"
                    >
                        <MdClose />
                    </button>
                </motion.div>
            </motion.div>
        )
    );

    return { confirm, ConfirmDialog };
};
