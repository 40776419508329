import AiTextButton from 'components/createWithAi/aiTextButton';
import useAiText from 'hooks/useAiText';
import { memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";
import { debounce } from 'utils/generators.utils';

const UpdateLmsInputText = memo(({
    isLoading = false,
    containerClassName = "",
    inputClassName = "",
    title = "",
    minLength = 5,
    maxLength = 128,
    titleInputStatus,
    setTitleInputStatus,
    inputLabel = "Title",
    isShowInputLabel = false,
    inputLabelClassName = "",
    onHandleSaveTitle,
    isShowAiBtn = false
}) => {

    const [titleInput, setTitleInput] = useState(title)
    const [isShowInput, setIsShowInput] = useState(false)
    const [topic, setTopic] = useState('')

    const { aiText, generateAiText } = useAiText()

    const requestData = {
        purpose: "title", // description or title 
        total_chars: 85,
        topic: topic,
        response_type: "text" // text or html 
    };

    const getAiText = () => {
        if (topic === "") {
            toast.error("Please write ai prompt");
            return
        }
        setIsShowInput(false)
        generateAiText(requestData);
    };

    const handlePrompt = (e) => {
        const value = e.target.value
        setTopic(value)
    }

    useEffect(() => {
        if (aiText?.data?.ai_response) {
            setTitleInput(aiText.data.ai_response);
        }
    }, [aiText?.data]);

    const onHandleChangeInputTitle = (e) => {
        const value = e.target.value
        setTitleInputStatus("Typing...")
        setTitleInput(value)

        if ((value?.length < minLength) || (value?.length > maxLength)) {
            setTitleInputStatus("Typing...")
            return;
        }

        const delayFunction = debounce.debounce(onHandleSaveTitle)
        delayFunction(value)
    }

    return (
        <div className={cn("flex-1 w-full transition-transform ease-in-out duration-300 editTitle", containerClassName)}>
            <div className={"relative w-full group flex items-center justify-start"}>
                <div className='flex gap-1 w-full'>
                    <input
                        type={"text"}
                        className={cn(
                            "relative translate-y-1 flex w-full p-3 focus:outline-none focus:!bg-back-ground-lightBlue bg-white hover:bg-back-ground-lightBlue",
                            "transition ease-in-out duration-300 overflow-hidden",
                            ((titleInput?.trim()?.length < minLength) || (titleInput?.trim()?.length > maxLength)) ? "focus:border-red-500" : "focus:border-primary-dark",
                            "placeholder:text-text-700", inputClassName
                        )}
                        id={"Title"}
                        name={"Title"}
                        value={titleInput}
                        minLength={minLength}
                        maxLength={maxLength}
                        onChange={onHandleChangeInputTitle}
                        disabled={(titleInput?.length > maxLength)}
                    />
                    {isShowAiBtn && (
                        <div className='relative mt-1 flex justify-end'>
                            <AiTextButton
                                containerClassName="top-0 right-0"
                                btnClassName="rounded h-6 addTitleWithAi"
                                btnTitle=''
                                tooltip='Create title with AI'
                                isShowInput={isShowInput}
                                setIsShowInput={setIsShowInput}
                                handlePrompt={handlePrompt}
                                getAiText={getAiText}
                                aiText={aiText}
                            />
                        </div>
                    )}
                </div>
                {isShowInputLabel &&
                    <label for={"Title"} className={cn(
                        "absolute z-1 left-3 whitespace-nowrap cursor-text px-2 rounded",
                        "transition ease-in-out duration-300",
                        "font-bodyPri font-normal text-sm text-text-500",
                        "group-focus-within:-translate-y-6 group-focus-within:-translate-x-1 group-focus-within:scale-75 group-focus-within:text-primary-dark",
                        "group-focus-within:bg-back-ground-light group-focus-within:py-0 group-focus-within:cursor-default", inputLabelClassName,
                        titleInput && "-translate-y-6 -translate-x-1 scale-75 !text-primary-dark",
                        titleInput && "bg-white py-0 cursor-default",
                        (titleInput && ((titleInput?.trim()?.length < minLength) || (titleInput?.trim()?.length > maxLength))) && "group-focus-within:!text-red-500",
                        (titleInput && ((titleInput?.trim()?.length < minLength) || (titleInput?.trim()?.length > maxLength))) && "!text-red-500",
                    )}>
                        {inputLabel}
                    </label>
                }
            </div>
            <div className={"h-2 md:h-3"}>
                {((titleInputStatus === "Typing...") || isLoading) && (
                    <div className={"w-full flex items-center justify-between gap-3 my-2"}>
                        <div className={""}>
                            {((titleInput?.trim()?.length < minLength) || (titleInput?.trim()?.length > maxLength)) &&
                                <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                    {`Please enter between ${minLength} and ${maxLength} characters`}
                                </span>
                            }
                        </div>
                        <div className={"flex flex-row items-center justify-end gap-3"}>
                            {!isLoading &&
                                <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                    {`${titleInput?.trim()?.length}/${maxLength}`}
                                </span>
                            }
                            <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                {titleInputStatus}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
})

export default UpdateLmsInputText;