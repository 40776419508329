import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import { Checkbox } from "@mui/material";

import { MdEdit, MdLocalPhone, MdOutlineEmail } from "react-icons/md";
import { FaEye } from "react-icons/fa";

import ToolTipView from "components/tooltipView";

import { searchParamsInfo } from "pages/auth/crm/data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetModifyCrmContactDetailPayload, setModifyCrmBulkContactListPayload, setModifyCrmContactDetailPayload } from "redux/crm/crm.slice";
import { modifyCrmContactDetailPayload } from "redux/crm/crm.const";
import { USER_DEMO_PROFILE } from "redux/user/user.const";

import { cn } from "utils/cn.utils";

function ContactCard({ contact, category, dragItem = null }) {
    const { modifyCrmBulkContactList } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate();
    const location = useLocation();

    const [mouseHovered, setMouseHovered] = useState(null)

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search]);

    const onHandleViewContact = (e) => {
        e.stopPropagation()
        searchQueryParams.set(searchParamsInfo.contact.key, contact?.id);
        navigate(`?${searchQueryParams.toString()}`);
    };

    const onHandleSelectContactItem = useCallback(() => {
        if (!!modifyCrmBulkContactList?.payload?.selectedContactList?.length) {
            const isContactAlreadySelected = modifyCrmBulkContactList?.payload?.selectedContactList?.filter((item) => (item === contact?.id))
            if (!!isContactAlreadySelected?.length) {
                dispatcher(setModifyCrmBulkContactListPayload({
                    ...modifyCrmBulkContactList?.payload,
                    status: {},
                    category: category,
                    selectedContactList: modifyCrmBulkContactList?.payload?.selectedContactList?.filter((item) => item !== contact?.id)
                }))
            } else {
                dispatcher(setModifyCrmBulkContactListPayload({
                    ...modifyCrmBulkContactList?.payload,
                    status: {},
                    category: category,
                    selectedContactList: [...modifyCrmBulkContactList?.payload?.selectedContactList, contact?.id]
                }))
            }
        } else {
            dispatcher(setModifyCrmBulkContactListPayload({
                ...modifyCrmBulkContactList?.payload,
                status: { label: contact?.status?.name, value: contact?.status?.key },
                category: category,
                selectedContactList: [...modifyCrmBulkContactList?.payload?.selectedContactList, contact?.id]
            }))
        }
    }, [modifyCrmBulkContactList?.payload])

    const onHandleEditContact = useCallback((event) => {
        event.stopPropagation()
        dispatcher(resetModifyCrmContactDetailPayload())
        dispatcher(setModifyCrmContactDetailPayload({
            ...modifyCrmContactDetailPayload,
            isEditEnabled: true
        }))
        searchQueryParams.set(searchParamsInfo.contact.key, contact?.id);
        navigate(`?${searchQueryParams.toString()}`);
    }, [contact])

    return (
        <div
            className={`relative w-[300px] font-bodyPri bg-white p-3 rounded border hover:border-primary-dark cursor-pointer grid grid-cols-3 gap-3 divide-x ${dragItem ? "rotate-6" : ""}`}
            onClick={onHandleViewContact}
            onMouseOver={() => setMouseHovered(contact?.id)}
            onMouseOut={() => setMouseHovered(null)}
        >
            <div className="col-span-1 flex flex-col gap-2 contact-center">
                <div className={cn("relative w-20 aspect-square border rounded-full border-primary-main overflow-hidden")}>
                    <img className={"w-full h-full object-cover"}
                        src={contact?.picture_url || USER_DEMO_PROFILE}
                        alt={contact?.first_name}
                    />
                    {((mouseHovered === contact?.id) || modifyCrmBulkContactList?.payload?.selectedContactList?.includes(contact?.id)) &&
                        <div className={"absolute top-0 w-full h-full bg-white/70"}>
                            <div className={"w-full h-full flex items-center justify-center"}>
                                <Checkbox
                                    checked={modifyCrmBulkContactList?.payload?.selectedContactList?.includes(contact?.id)}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        onHandleSelectContactItem(event)
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
                <p className="text-xs text-text-600 text-center line-clamp-1">
                    {contact?.company}
                </p>
            </div>
            <div className="col-span-2 pl-3 space-y-0.5">
                <div className={"flex justify-between gap-2"}>
                    <span className="w-[300px] font-medium truncate hover:text-primary-dark">
                        {`${contact?.title && contact?.title + "."} ${contact?.first_name} ${contact?.last_name}`}
                    </span>
                    {(mouseHovered === contact?.id) &&
                        <div className={"absolute top-1 right-1 flex items-center justify-start gap-0.5 bg-white"}>
                            <ToolTipView content={"Edit Contact"}>
                                <button
                                    className={"p-1.5 rounded-full hover:bg-divider-darkLight group"}
                                    onClick={onHandleEditContact}
                                >
                                    <MdEdit className={"text-lg text-text-700 group-hover:text-primary-dark cursor-pointer"} />
                                </button>
                            </ToolTipView>
                            <ToolTipView content={"View Contact"}>
                                <button
                                    className={"p-1.5 rounded-full hover:bg-divider-darkLight group"}
                                    onClick={onHandleViewContact}
                                >
                                    <FaEye className={"text-lg text-text-700 group-hover:text-red-500 cursor-pointer"} />
                                </button>
                            </ToolTipView>
                        </div>
                    }
                </div>
                <span className={"w-fit text-sm text-text-600 hover:text-primary-dark truncate line-clamp-1 cursor-pointer"}>
                    {contact?.email?.email ? (contact?.email?.email?.length > 20)
                        ? contact?.email?.email?.slice(0, 20) + "..."
                        : contact?.email?.email
                        : ""
                    }
                </span>
                <p className={""}>
                    {(contact?.phone?.isd && contact?.phone?.phone) &&
                        <p className={"text-sm text-text-600 hover:text-primary-dark line-clamp-1 cursor-pointer"}>
                            {`+${contact?.phone?.isd}-${contact?.phone?.phone}`}
                        </p>
                    }
                </p>
                <div className={"flex items-center gap-2 pt-2"}>
                    {contact?.phone?.phone &&
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ duration: 0.3 }}
                            className="w-8 h-8 flex justify-center items-center rounded-full shadow-md bg-back-ground-lightBlue hover:bg-white text-primary-main"
                            onClick={(event) => {
                                event.stopPropagation();
                                window.open(`tel:${contact?.phone?.phone}`, "_self");
                            }}
                        >
                            <MdLocalPhone size={18} />
                        </motion.button>
                    }
                    {contact?.email?.email &&
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ duration: 0.3 }}
                            className="w-8 h-8 flex justify-center items-center rounded-full shadow-md bg-back-ground-lightBlue hover:bg-white text-primary-main"
                            onClick={(event) => {
                                event.stopPropagation();
                                window.open(`mailto:${contact?.email?.email}`);
                            }}
                        >
                            <MdOutlineEmail size={18} />
                        </motion.button>
                    }
                </div>
            </div>
        </div>
    );
}

export default ContactCard;