import { useCallback } from 'react';

import { cn } from "utils/cn.utils";

import { AiOutlineLink, AiOutlineYoutube } from 'react-icons/ai';

import ResourceFileFilters from './ResourceFileFilters';

import { uploadLmsMediaConst } from 'components/modals/lmsModals/uploadLmsMediaModal/data';

import { getLmsResourceList } from 'redux/edulyteLms/lmsResource/lmsResource.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setLocalTabs, setModal } from 'redux/local/local.slice';
import { setFilterProps } from 'redux/edulyteLms/lmsResource/lmsResource.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsResourceTypeEnum } from 'redux/edulyteLms/lmsResource/lmsResource.const';

const ResourceHeader = () => {
    const { modal, localTabs } = useAppState((state) => state.local)
    const { filterProps } = useAppState((state) => state.lms.lmsResource)

    const dispatcher = useAppDispatcher()

    const onHandleCreateResource = useCallback(() => {
        dispatcher(setLocalTabs({
            ...localTabs,
            modalTitle: "Attach an Item",
            tabList: Object.values(uploadLmsMediaConst),
            activeTabList: Object.values(uploadLmsMediaConst)?.map((tabItem) => tabItem?.value),
            currentActiveTab: uploadLmsMediaConst.MY_FILES.value,
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: false,
            [modalConst.UPLOAD_LMS_MEDIA_MODAL.stateKey]: true
        }))
    }, [])

    const onHandleSelectResourceType = useCallback((selectedType) => {
        if ((filterProps?.disabledFilters?.length > 0) && filterProps?.disabledFilters?.includes(selectedType)) return;

        if (selectedType === lmsResourceTypeEnum.FILE.value) return;

        dispatcher(setFilterProps({
            filterProps: { ...filterProps, resourceType: selectedType, resourceFileType: "" }
        }))
        dispatcher(getLmsResourceList({ page: 1, records: 5, type: selectedType }))

    }, [filterProps])

    return (
        <div className={'flex items-center justify-between gap-5'}>
            <div className={"flex items-center justify-start gap-3"}>
                {Object.values(lmsResourceTypeEnum)?.map((resourceType) => (
                    <div
                        className={cn(
                            "px-3 py-0.5 border border-divider-lightDark text-text-700 rounded-lg cursor-pointer",
                            "flex items-center justify-center gap-2 cursor-pointer",
                            ((filterProps?.disabledFilters?.length > 0) && filterProps?.disabledFilters?.includes(resourceType?.value)) && "!border !border-text-200 !bg-text-200 !text-text-400 cursor-not-allowed",
                            (filterProps?.resourceType === resourceType?.value) && "bg-divider-lightDark !text-text-50 cursor-default"
                        )}
                        onClick={() => onHandleSelectResourceType(resourceType?.value)}
                    >
                        {[lmsResourceTypeEnum.FILE.value]?.includes(resourceType?.value) &&
                            <ResourceFileFilters />
                        }
                        {[lmsResourceTypeEnum.YOUTUBE.value]?.includes(resourceType?.value) &&
                            <AiOutlineYoutube className={"text-base"} />
                        }
                        {[lmsResourceTypeEnum.LINK.value]?.includes(resourceType?.value) &&
                            <AiOutlineLink className={"text-base"} />
                        }
                        {![lmsResourceTypeEnum.FILE.value]?.includes(resourceType?.value) &&
                            <span className={"font-bodyPri font-normal text-sm tracking-wide"}>
                                {resourceType?.label}
                            </span>
                        }
                    </div>
                ))}
            </div>
            <button
                className={cn(
                    'w-fit px-5 py-1 flex items-center justify-center border border-back-ground-black rounded ease-in-out duration-200 group cursor-pointer',
                    'hover:bg-secondary-dark hover:text-white hover:border-secondary-dark'
                )}
                onClick={onHandleCreateResource}
            >
                <span className={"font-bodyPri font-normal text-text-800 text-base group-hover:text-white"}>
                    {"Create new resource"}
                </span>
            </button>
        </div>
    )
}

export default ResourceHeader;