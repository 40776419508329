import { toast } from 'react-toastify';

import { EDULYTE_ADMIN_DOMAIN_URL } from 'const/default.const'

import authService from './auth.service';
import {
    setSessionLoading,
    setSessionLoggedIn,
    setAccessToken,

    setLogInLoading,
    setLogInMessage,

    setSignUpLoading,
    setSignUpMessage,

    setSignupWithTokenLoading,
    setSignupWithTokenData,
    setSignupWithTokenMessage,

    setVerifySignupLoading,
    setVerifySignupData,
    setVerifySignupMessage,

    setSignupS2Loading,
    setSignupS2Data,
    setSignupS2Message,

    setVerifyReferralLoading,
    setVerifyReferral,
    setVerifyReferralErrorMsg,

    setLogoutLoading,
    setLogout,
    setLogoutErrorMsg,

    setAddResetPasswordLoading,
    setAddResetPassword,
    setAddResetPasswordErrorMsg,

    setAddUserAuthLoading,
    setAddUserAuth,
    setAddUserAuthErrorMsg,

    setAddUserPasswordLoading,
    setAddUserPassword,
    setAddUserPasswordErrorMsg,

    setVerifyAuthLoading,
    setVerifyAuth,
    setVerifyAuthErrorMsg,

    setVerifyUserLoading,
    setVerifyUser,
    setVerifyUserErrorMsg,

    setPasswordResetLoading,
    setPasswordResetData,
    setPasswordResetMessage,

    setAddPasswordResetLoading,
    setAddPasswordResetData,
    setAddPasswordResetMessage,

    setForgetPasswordLinkLoading,
    setForgetPasswordLinkMessage,

    setLogoutCurrentUserAndSwitchToAdmin,
} from 'redux/auth/auth.slice';

import { getMyProfile } from 'redux/user/user.request';
import { setClearUser, setUser } from 'redux/user/user.slice';
import { timeZone } from 'utils/dateTime.utils';

export const getToken = () => async (dispatch) => {
    dispatch(setSessionLoading(true));

    try {
        const response = await authService.getToken()
        if (response.status === 200) {
            dispatch(setAccessToken(response.data.data.accessToken))
            dispatch(getMyProfile())
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
    } finally {
        dispatch(setSessionLoading(false));
    }
}

export const createLogIn = (user) => async (dispatch) => {
    dispatch(setLogInLoading(true));

    try {
        const requestData = {
            body: user
        }
        const response = await authService.createLogIn(requestData)
        if (response.status === 201) {
            dispatch(setAccessToken(response.data.data.accessToken))
            dispatch(getMyProfile())
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setLogInMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setLogInLoading(false));
    }
}

export const createGoogleLogin = (code) => async (dispatch) => {
    dispatch(setLogInLoading(true));
    try {
        const requestData = {
            body: {
                timeZone: timeZone,
                code: code
            }
        }
        const response = await authService.createGoogleLogIn(requestData)
        if (response.status === 201) {
            dispatch(setAccessToken(response.data.data.accessToken))
            dispatch(getMyProfile())
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setLogInMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setLogInLoading(false));
    }
}

export const createOneTapGoogleLogin = (body) => async (dispatch) => {
    dispatch(setLogInLoading(true));
    try {
        const requestData = {
            body: body
        }
        const response = await authService.createOneTapGoogleLogIn(requestData)
        if (response.status === 201) {
            dispatch(setAccessToken(response.data.data.accessToken))
            dispatch(getMyProfile())
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setLogInMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setLogInLoading(false));
    }
}

export const createSignUp = (body) => async (dispatch) => {
    dispatch(setSignUpLoading(true));

    try {
        const requestData = {
            body: body
        }
        const response = await authService.createSignUp(requestData)
        if (response.status === 201) {
            dispatch(setAccessToken(response.data.data.accessToken))
            dispatch(getMyProfile())
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setSignUpMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setSignUpLoading(false))
    }
}


export const signupWithToken = (body) => async (dispatch) => {
    dispatch(setSignupWithTokenLoading(true));

    try {
        const requestData = {
            body: body
        }
        const response = await authService.signUpWithToken(requestData)
        if (response.status === 200) {
            dispatch(setSignupWithTokenData(response.data.message));
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setSignupWithTokenMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setSignupWithTokenLoading(false));
    }
}


export const verifySignup = (query) => async (dispatch) => {
    dispatch(setVerifySignupLoading(true));

    try {
        const requestData = {
            query
        }
        const response = await authService.verifySignup(requestData)
        if (response.status === 200) {
            dispatch(setVerifySignupData(response.data.data))
        } else if (response.status === 201) {
            dispatch(setSignupS2Data(response.data.data))
            dispatch(setAccessToken(response.data.data.accessToken))
            dispatch(getMyProfile())
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setVerifySignupMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setVerifySignupLoading(false))
    }
}


export const signupS2 = (body) => async (dispatch) => {
    dispatch(setSignupS2Loading(true));

    try {
        const requestData = {
            body
        }
        const response = await authService.signupS2(requestData)
        if (response.status === 201) {
            dispatch(setSignupS2Data(response.data.data))
            dispatch(setAccessToken(response.data.data.accessToken))
            dispatch(getMyProfile())
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setSignupS2Message(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setSignupS2Loading(false))
    }
}



export const createVerifyReferral = (referralCode) => async (dispatch) => {
    dispatch(setVerifyReferralLoading(true))

    try {
        const requestData = {
            params: { referralCode: referralCode }
        }
        const response = await authService.createVerifyReferral(requestData)
        if (response.status === 200) {
            dispatch(setVerifyReferral(response.data.data))
            // toast.success(response.data.message)
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setVerifyReferralErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setVerifyReferralLoading(false))
    }
}

export const createLogout = () => async (dispatch) => {
    dispatch(setLogoutLoading(true))

    try {
        const response = await authService.logout()
        if (response.status === 200) {
            dispatch(setLogout(response.data))
            dispatch(setAccessToken(null))
            dispatch(setSessionLoggedIn({
                isLoading: false,
                isLoggedIn: false
            }))
            dispatch(setClearUser())
            toast.success(response.data.message)
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setLogoutErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setLogoutLoading(false))
    }
}

export const updateResetPassword = (body) => async (dispatch) => {
    dispatch(setAddResetPasswordLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await authService.updateResetPassword(requestData)
        if (response.status === 200) {
            dispatch(setAddResetPassword(response.data.data))
            dispatch(setAccessToken(response.data.data.accessToken))
            dispatch(getMyProfile())
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddResetPasswordErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddResetPasswordLoading(false))
    }
}

export const updateUserAuthByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddUserAuthLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await authService.updateUserAuthByUserId(requestData)
        if (response.status === 200) {
            dispatch(setAddUserAuth(response.data.data))
            dispatch(setUser(response.data.data))
            // toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserAuthErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserAuthLoading(false))
    }
}

export const updateUserPasswordByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddUserPasswordLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await authService.updateUserPasswordByUserId(requestData)
        if (response.status === 200) {
            dispatch(setAddUserPassword(response.data.data))
            dispatch(setUser(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserPasswordErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserPasswordLoading(false))
    }
}

export const verifyAuthorizedUser = (body) => async (dispatch) => {
    dispatch(setVerifyAuthLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await authService.verifyAuthorizedUser(requestData)
        if (response.status === 200) {
            dispatch(setVerifyAuth(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setVerifyAuthErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setVerifyAuthLoading(false))
    }
}

export const createVerifyUser = (body) => async (dispatch) => {
    dispatch(setVerifyUserLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await authService.createVerifyUser(requestData)
        if (response.status === 200) {
            dispatch(setVerifyUser(response.data.data))
            // toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setVerifyUserErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        // toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setVerifyUserLoading(false))
    }
}

export const getPasswordReset = (query) => async (dispatch) => {
    dispatch(setPasswordResetLoading(true))
    try {
        const requestData = {
            query: query
        }
        const response = await authService.getPasswordReset(requestData)
        if (response.status === 200) {
            dispatch(setPasswordResetData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setPasswordResetMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setPasswordResetLoading(false))
    }
}

export const createPasswordReset = (body) => async (dispatch) => {
    dispatch(setAddPasswordResetLoading(true))
    try {
        const requestData = {
            body: body
        }
        const response = await authService.createPasswordReset(requestData)
        if (response.status === 201) {
            dispatch(setAddPasswordResetData(response?.data?.data))
            dispatch(setAccessToken(response.data.data.accessToken))
            dispatch(getMyProfile())
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddPasswordResetMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddPasswordResetLoading(false))
    }
}

export const sendForgetPasswordLink = (body) => async (dispatch) => {
    dispatch(setForgetPasswordLinkLoading(true))
    try {
        const requestData = {
            body: body
        }
        const response = await authService.sendForgetPasswordLink(requestData)
        if (response.status === 200) {
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong")
        dispatch(setForgetPasswordLinkMessage(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setForgetPasswordLinkLoading(false))
    }
}

export const logoutCurrentUserAndSwitchToAdmin = () => async (dispatch, getState) => {
    dispatch(setLogoutCurrentUserAndSwitchToAdmin(true))

    try {

        const getDefaultRedirectUrl = (domainDetail) => {
            const domainObject = domainDetail?.data?.result
            if (!!domainObject?.custom_domain) {
                return domainObject?.custom_domain?.web?.origin + "/console/users"
            }
            return domainObject?.sub_domain?.web?.origin + '/console/users'
        }

        const { orgDomainDetail } = getState().org
        const defaultRedirectUrl = getDefaultRedirectUrl(orgDomainDetail)
        const response = await authService.logoutCurrentUserAndSwitchToAdmin()
        if (response.status === 200) {
            toast.success(response.data.message)
            const redirectUrl = response.data?.source_url || defaultRedirectUrl
            window.open(`${redirectUrl}`, "_self")
        } else {
            throw new Error(response)   
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong")
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setLogoutCurrentUserAndSwitchToAdmin(false))
    }
}

// const requestData = {
//     headers: {token: userAuth.tokens.accessToken},
//     params: {tutorId: "1234"},
//     query: {status: "active", verified: "true"},
//     body: {
//         email: "",
//         password: ""
//     }
//    for a delete request body should be inside an object with key data. {data: body }
// }