import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { gatewayProviderEnum } from 'redux/payment/payment.const';
import { setClearModal, setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import { cn } from "utils/cn.utils";

import { createEdrazorpayOrder as addCreateEdrazorpayOrder } from 'redux/edrazorpay/edrazorpay.request';
import { resetCreateEdrazorpayOrder } from "redux/edrazorpay/edrazorpay.slice";
import edrazorpayService from "redux/edrazorpay/edrazorpay.service";
import { setClearMakePayment, setMakePaymentPayload } from "redux/payment/payment.slice";
import format from "string-format";
import { useNavigate } from "react-router-dom";
import ToolTipView from "components/tooltipView";

const initialDataState = {
    isLoading: false,
    data: null,
    message: null
}

const walletConfirmationUrl = "/wallet/confirmation?virtual_txn_id={virtual_txn_id}&redirect_status={redirect_status}"


const GatewayModal = () => {
    const { modal } = useAppState(s => s.local);
    const { user } = useAppState(s => s.user);
    const [virtualTxnId, setVirtualTxnId] = useState(null);
    const { createEdrazorpayOrder } = useAppState(s => s.edrazorpay)
    const [checkoutEdrazorpay, setCheckoutEdrazorpay] = useState(initialDataState)
    const [selectedGateway, setSelectedGateway] = useState('primary');
    const { makePayment } = useAppState(s => s.payment)

    const [isNextButtonEnable, setIsNextButtonEnable] = useState(false);

    const makePaymentPayload = makePayment?.makePaymentPayload
    
    const dispatcher = useAppDispatcher();
    const navigate = useNavigate()
    
    const gateways = useMemo(() => ({
        primary: gatewayProviderEnum[user?.user?.gateways?.primary?.provider_type],
            secondary: gatewayProviderEnum[user?.user?.gateways?.secondary?.provider_type],
    }), [user?.user?.gateways?.primary?.provider_type]);

    useEffect(() => {
        if (virtualTxnId) {
            const query = {
                virtual_txn_id: virtualTxnId,
                redirect_status: "success"
            };

            setVirtualTxnId(null);
            navigate(format(walletConfirmationUrl, query), { replace: true })
        }
    }, [virtualTxnId]); 


    useEffect(() => {
        const processingFeeAmount = Math.ceil(makePaymentPayload?.amountToPay * user?.user?.processingFeePct / 100)
        const totalAmount = Math.ceil(makePaymentPayload?.amountToPay + processingFeeAmount)
        dispatcher(setMakePaymentPayload({
            ...makePaymentPayload,
            email: user?.user?.email || '',
            firstName: user?.user?.firstName || '',
            lastName: user?.user?.lastName || '',
            processingFeePct: user?.user?.processingFeePct,
            processingFee: processingFeeAmount,
            totalAmountToPay: totalAmount,
        }))
    }, [user?.user])

    const handleToggle = (gateway) => {
        setSelectedGateway(gateway.key);
        setIsNextButtonEnable(true);
    };

    const handleButtonNext = () => {
        if (selectedGateway === 'stripe') {
            dispatcher(setModal({ 
                ...modal,
                [modalConst.SELECT_GATEWAY_MODAL.stateKey]: false,
                [modalConst.PAYMENT_METHOD_MODAL.stateKey]: true 
            }));
        } else if (selectedGateway === 'razorpay') {
            let requestPayload = {
                body: {
                    currency: makePaymentPayload?.currency.toUpperCase(),
                    amount: parseInt(makePayment?.makePaymentPayload?.totalAmountToPay),
                    metadata: {
                        processingFee: Math.ceil(makePaymentPayload?.amountToPay * user?.user?.processingFeePct / 100),
                        requiredAmount: makePayment?.makePaymentPayload?.amountToPay,
                    }
                }
            }
            dispatcher(addCreateEdrazorpayOrder(requestPayload))
        }
    }

    useEffect(() => {
        if (createEdrazorpayOrder?.result) {

            handleEdrazorpay({ checkout_option: createEdrazorpayOrder?.result?.checkout_option })
            dispatcher(resetCreateEdrazorpayOrder())
        }
    }, [createEdrazorpayOrder?.result])

    const handleEdrazorpay = async ({ checkout_option}) => {
        setCheckoutEdrazorpay({ ...initialDataState, isLoading: true })
        try {
            const razorpayOptions = {
                    ...checkout_option,
                    modal: {
                        animation: true,
                        ondismiss: () => {
                            if (window.confirm("Are you sure, you want to close the form?")) {
                                setCheckoutEdrazorpay(initialDataState);
                            } else {
                                setCheckoutEdrazorpay(initialDataState);
                            }
                        }
                    },
                    handler: (response) => handleEdrazorpayNext(response)
                };
            const razorpayInstance = new window.Razorpay(razorpayOptions)
            razorpayInstance.on("payment.failed", (response) => {
                setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false, message: response?.error?.description })
            })
            razorpayInstance.open()
        } catch (error) {
            console.error(error)
            setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false, message: "Something went wrong!" })
        }
    }

    const handleEdrazorpayNext = async (response) => {
        const body = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
        };
        try {
            const response = await edrazorpayService.verifyEdrazorpayPayment({ body })
            if (response.status === 200) {
                setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false })
                // dispatcher(getBookingDetailConfirmation(bookingId))
                setVirtualTxnId(response?.data?.data?.result?.wallet_virtual_txn_id)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            setCheckoutEdrazorpay({ ...checkoutEdrazorpay, error: error?.response.data.error || "Something went wrong!" })
            console.error(error?.response.data.error || error)
        }
        setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false })
    }

    const GatewayOption = ({ gateway }) => {
        if (!gateway) return null;

        const isSelected = selectedGateway === gateway.key;


        return (
            <div className="p-2 flex gap-3">
                <div className="mt-5" onClick={() => handleToggle(gateway)}>
                    {isSelected ? (
                        <MdOutlineRadioButtonChecked className={cn("text-primary-dark text-2xl cursor-pointer")} />
                    ) : (
                        <MdOutlineRadioButtonUnchecked className={cn("text-primary-dark text-2xl cursor-pointer")} />
                    )}
                </div>
                <div className="flex flex-row gap-2">
                    <img src={gateway.image} alt="gateway" className={cn("w-28 aspect-video object-contain")} />
                    <p className={cn("pl-2 font-medium text-muted-foreground/70")}>
                        {gateway.info}
                    </p>
                </div>
            </div>
        );
    };


    return (
        <>
        <div className="w-full">
            <div className="w-full space-y-2 border divide-y">
                <GatewayOption gateway={gateways.primary} />
                <GatewayOption gateway={gateways.secondary} />
            </div>
            {isNextButtonEnable && (<div className="flex items-center justify-end mt-4 w-full">
                <div
                    onClick={handleButtonNext}
                    className="bg-primary-dark px-8 py-4 rounded-xl text-white font-bodyPri cursor-pointer"
                >
                    {"Next"}
                </div>
            </div>)}
        </div>
        </>
    );
};

export default GatewayModal;
