import { Fragment, memo } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { motion } from "framer-motion";

import { AiOutlineClose } from "react-icons/ai";

import Plans from "components/plans";
import ToolTipView from "components/tooltipView";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

import { cn } from "utils/cn.utils";


const PlanTableModal = memo(({
    openModal = false,
    maxWidth = "max-w-6xl",
    bgColor = "bg-white"
}) => {
    const { modal } = useAppState((state) => state.local)
    const userPlans = modal.planTableModal?.userPlans

    const dispatcher = useAppDispatcher()

    const closeModal = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.planTableModal.key]: {
                ...modalConst.planTableModal,
                isVisible: false
            },
        }))
    }

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className={"fixed max-h-screen inset-0 z-50 overflow-y-auto"}
                open={openModal}
                onClose={closeModal}
            >
                <div className={"p-0 md:p-5 text-center"}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className={"fixed inset-0 bg-black opacity-50"} />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden={"true"} className={"inline-block h-screen align-middle"}>
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className={cn(
                                "inline-block w-full shadow-xl rounded-lg space-y-5 overflow-hidden",
                                `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                            )}
                        >
                            <Dialog.Description
                                as="div"
                                className={cn(
                                    "w-full h-full overflow-x-hidden overflow-y-scroll min-h-[400px]",
                                    "scrollbar-thin"
                                )}
                            >
                                <div className={"w-full my-8"}>
                                    <h1 className={"font-heading font-semibold text-2xl md:text-3xl text-primary-dark text-center"}>
                                        {modal.planTableModal?.title}
                                    </h1>
                                </div>
                                <Plans planData={userPlans} />
                                <div className={"absolute top-5 right-5"}>
                                    <ToolTipView content={"Close"}>
                                        <motion.button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.9 }}
                                            transition={{ duration: 0.3 }}
                                            className={"flex justify-center items-center h-8 w-8 text-lg hover:bg-back-ground-light rounded-full ease-in-out duration-200"}
                                            onClick={closeModal}
                                        >
                                            <AiOutlineClose />
                                        </motion.button>
                                    </ToolTipView>
                                </div>
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
})

export default PlanTableModal