import { useMemo } from 'react';
import {
    // Container,
    AnchorLink,
    LogInCardContent,
    LogInCardHeader,
    Heading,
    SubHeading,
    LogInFormWrapper,
    DividerWrapper,
    SocialLogInWrapper,
} from './LogInCardStyle';
import LogInForm from 'components/logInForm/LogInForm';
import { GoogleLogin } from '@react-oauth/google';
import HDividerWithText from 'components/hDividerWithText/HDividerWithText';

import { createLogIn, createOneTapGoogleLogin } from "redux/auth/auth.request";

import useWindowSize from 'hooks/useWindowSize';
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { environmentEnum } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';
import { timeZone } from "utils/dateTime.utils";

import { ENVIRONMENT } from 'const/default.const';

const pattern = /[a-zA-z]{1}/;

const LogInCard = () => {
    // const { publicOrgDetail } = useAppState((state) => state.org)

    const dispatcher = useAppDispatcher();
    const { width } = useWindowSize()

    const CURRENT_ACTIVE_DOMAIN = useMemo(() => {
        if (ENVIRONMENT === environmentEnum.local.key) {
            return 'localhost'
        } else if (ENVIRONMENT === environmentEnum.dev.key) {
            return 'edulyte.net'
        } else {
            return 'edulyte.com'
        }
    }, [ENVIRONMENT])

    const formSubmit = async (userData) => {
        if (pattern?.test(userData.emailOrPhone) && userData?.password) {
            const user = {
                // org_id: publicOrgDetail?.data?.result?.id,
                email: userData.emailOrPhone,
                password: userData.password,
            }
            dispatcher(createLogIn(user))
            return;
        }
        if (pattern?.test(userData.emailOrPhone) && userData?.otp) {
            const user = {
                // org_id: publicOrgDetail?.data?.result?.id,
                email: userData.emailOrPhone,
                otpId: userData?.otpId,
                otp: String(userData?.otp)
            }
            dispatcher(createLogIn(user))
            return;
        }
    }

    const onHandleSuccess = (credentialResponse) => {
        const requestPayload = {
            // org_id: publicOrgDetail?.data?.result?.id,
            timeZone: timeZone,
            credentials: credentialResponse?.credential,
            source: window.location.href
        }
        dispatcher(createOneTapGoogleLogin(requestPayload))
    }

    return (
        <>
            <LogInCardContent>
                <LogInCardHeader>
                    <Heading>{'Log in'}</Heading>
                    <SubHeading>
                        {`Don't have an account?`}
                        <AnchorLink to={pagesInfo.SIGN_UP.pagePath}>{'Sign up'}</AnchorLink>
                    </SubHeading>
                </LogInCardHeader>
                <LogInFormWrapper>
                    <LogInForm formSubmit={formSubmit} />
                </LogInFormWrapper>
                <DividerWrapper>
                    <HDividerWithText text={'or continue with'} />
                </DividerWrapper>

                <SocialLogInWrapper className={'flex items-center justify-center w-full'}>
                    <GoogleLogin
                        onSuccess={onHandleSuccess}
                        onError={(error) => {
                            console.log('Login Failed: ', error);
                        }}
                        ux_mode={'popup'}
                        width={(width > 768) ? "380" : '340'}
                        size={'large'}
                        theme={'outline'}
                        shape={"rectangular"}
                        auto_select={true}
                        state_cookie_domain={CURRENT_ACTIVE_DOMAIN}
                        use_fedcm_for_prompt={true}
                    />
                </SocialLogInWrapper>
            </LogInCardContent>
        </>
    )
}

export default LogInCard;