import { cn } from "utils/cn.utils";

import { BiRefresh } from "react-icons/bi";

import ToolTipView from "components/tooltipView";

const RefreshButton = ({ content = "Refresh", onHandleRefreshButton }) => {
    return (
        <ToolTipView content={content}>
            <button
                className={cn(
                    "p-2 flex items-center justify-center rounded-full",
                    "bg-back-ground-light hover:bg-back-ground-darkLight text-text-600 ease-in-out duration-200 shadow-sm"
                )}
                onClick={onHandleRefreshButton}
            >
                <BiRefresh className={"text-xl cursor-pointer"} />
            </button>
        </ToolTipView>
    );
};

export default RefreshButton;
