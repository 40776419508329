import { cn } from "utils/cn.utils";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import ButtonLoader from "components/loader/ButtonLoader";

import { getMasterLanguageList, getMasterProficiencyList } from "redux/master/master.request";
import { updateCourseDetail, createCourseCommentDetail } from "redux/course/course.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearLanguageList, setClearProfList } from "redux/master/master.slice";
// import { courseType } from "redux/course/course.const";
import { modalConst } from "redux/local/local.const";
import { courseStatus } from "redux/course/course.const";

import { pagesInfo } from 'utils/pagesInfo';

const MIN_CHARACTER_LENGTH = 20
const MAX_CHARACTER_LENGTH = 100

const SubmitCourseModal = () => {
    const { modal } = useAppState((s) => s.local)
    const { user } = useAppState((state) => state.user)
    const { tutorCourseDetail, addCourseDetail, addCourseCommentDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [comments, setComments] = useState('')
    const [characterLength, setCharacterLength] = useState(0)

    useEffect(() => {
        dispatcher(getMasterProficiencyList())
        dispatcher(getMasterLanguageList())

        return () => {
            dispatcher(setClearProfList())
            dispatcher(setClearLanguageList())
        }
    }, [])

    useEffect(() => {
        if (tutorCourseDetail?.data && [courseStatus.UNDER_REVIEW.value, courseStatus.PUBLISHED.value].includes(tutorCourseDetail?.data?.status)) {
            dispatcher(setModal({
                ...modal,
                [modalConst.SUBMIT_COURSE_MODAL.stateKey]: false
            }))
            navigate(`${pagesInfo.TUTOR_PRODUCTS.pagePath}/live-classes`, { replace: true })
        }
    }, [tutorCourseDetail?.data])

    const handleOnChange = (event) => {
        const myText = event.target.value
        setComments(myText)
        setCharacterLength(myText?.length)
    }

    const handleSubmitButton = (event) => {
        event.preventDefault()

        if (addCourseDetail?.isLoading || addCourseCommentDetail?.isLoading) return;

        if (characterLength < MIN_CHARACTER_LENGTH || characterLength > MAX_CHARACTER_LENGTH) return;


        const body = {
            userId: user?.user?.userId,
            courseId: tutorCourseDetail?.data?.id,
            comment: comments,
        }
        dispatcher(createCourseCommentDetail(body))

        dispatcher(updateCourseDetail(
            tutorCourseDetail?.data?.id,
            {
                step: 5,
                // comment: comments,
                status: courseStatus.UNDER_REVIEW.value
            }))
    }

    return (
        <form className={"space-y-5 overflow-x-hidden"} onSubmit={handleSubmitButton}>
            <div className={"min-h-[12rem] block space-y-5 pt-5"}>
                <div className={"flex flex-col items-start gap-3"}>
                    <div className={"w-full font-bodyPri font-semibold text-text-900 text-md tracking-wide"}>
                        {"Are you sure?"}
                    </div>
                    <div className={"w-full font-bodyPri font-normal text-text-900 text-sm tracking-wide"}>
                        <span>{"Please write your comments/requests for the reviewer."}</span>
                    </div>
                    <div className={"w-full"}>
                        <textarea
                            className={cn(
                                "w-full px-5 py-3 bg-back-ground-light rounded-lg resize-none",
                                "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                                "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                            )}
                            type={"text"}
                            placeholder="Write your comments ...."
                            value={comments}
                            onChange={(event) => handleOnChange(event)}
                            rows={4}
                            minLength={MIN_CHARACTER_LENGTH}
                            maxLength={MAX_CHARACTER_LENGTH}
                            autoFocus={true}
                            required={true}
                        />
                        <div className={cn("flex justify-end",
                            (characterLength < MIN_CHARACTER_LENGTH || characterLength === MAX_CHARACTER_LENGTH) && "w-full flex justify-between items-center"
                        )}>
                            {(characterLength < MIN_CHARACTER_LENGTH || characterLength === MAX_CHARACTER_LENGTH) &&
                                <span className={"font-bodyPri font-normal text-sm text-red-500"}>
                                    {"Please Write your comments in between 20-100 characters..."}
                                </span>
                            }
                            <span className={"font-bodyPri font-normal text-text-800 text-xs"}>
                                {characterLength + "/" + MAX_CHARACTER_LENGTH}
                            </span>
                        </div>
                    </div>
                    {tutorCourseDetail?.message &&
                        <span className={"font-bodyPri font-normal text-base text-red-500"}>
                            {tutorCourseDetail?.message}
                        </span>
                    }
                </div>
            </div>
            <div className={"flex justify-end"}>
                <button
                    type={"submit"}
                    className={cn(
                        "px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        (characterLength > MIN_CHARACTER_LENGTH && characterLength < MAX_CHARACTER_LENGTH) && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                        (characterLength < MIN_CHARACTER_LENGTH || characterLength > MAX_CHARACTER_LENGTH) && "bg-text-300 text-text-50 hover:bg-text-300 hover:border-text-300",
                        (addCourseDetail?.isLoading || addCourseCommentDetail?.isLoading) && "bg-secondary-dark"
                    )}
                >
                    {(addCourseDetail?.isLoading || addCourseCommentDetail?.isLoading) && <ButtonLoader isLoading={addCourseDetail?.isLoading || addCourseCommentDetail?.isLoading} />}
                    {(!addCourseDetail?.isLoading && !addCourseCommentDetail?.isLoading) && "Submit"}
                </button>
            </div>
        </form >
    )
}

export default SubmitCourseModal