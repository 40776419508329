import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { FaPlus } from "react-icons/fa";
import { AiOutlineLink } from "react-icons/ai";

import ComponentLoader from "components/loader/ComponentLoader";
import { OptionSelector } from "components/common-components/Select";

import BankTransferDetails from "components/modals/withdrawMoneyModal/BankTransferDetails";
import UPITransferDetails from "components/modals/withdrawMoneyModal/UPITransferDetails";
import PayIDTransferDetails from "components/modals/withdrawMoneyModal/PayIDTransferDetails";
import PayPalIDTransferDetails from "components/modals/withdrawMoneyModal/PayPalIDTransferDetails";

import { getUserPayoutListByUserId } from "redux/payout/payout.request";
import { getUserWithdrawalListByUserId } from "redux/withdrawal/withdrawal.request";
import { getUserDefaultDetailsByUserId } from "redux/default/default.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from "redux/local/local.slice";
import { setClearUserPayoutList } from "redux/payout/payout.slice";
import { setClearUserWithdrawalList, setAddUserWithdrawalDetailPayload } from "redux/withdrawal/withdrawal.slice";
import { setClearUserDefaultDetail } from "redux/default/default.slice";
import { modalConst } from "redux/local/local.const";
import { payoutType } from 'redux/payout/payout.const';
import { withdrawalStatus } from "redux/withdrawal/withdrawal.const";

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";
import { masterCurrencyCodeEnum } from "redux/master/master.const";

const WithdrawMoneyModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userPayoutList } = useAppState((state) => state.payout)
    const { userDefaultDetail } = useAppState((state) => state.userDefault)
    const { userWithdrawalList, addUserWithdrawalDetail } = useAppState((state) => state.withdrawal)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [withdrawalAmountError, setWithdrawalAmountError] = useState("")
    const [withdrawMethodError, setWithdrawMethodError] = useState("")

    useEffect(() => {
        dispatcher(getUserPayoutListByUserId(user?.user?.userId))
        dispatcher(getUserWithdrawalListByUserId(
            user?.user?.userId,
            { status: withdrawalStatus?.PENDING?.value }
        ))
        dispatcher(getUserDefaultDetailsByUserId(user?.user?.userId))


        return () => {
            dispatcher(setClearUserPayoutList())
            dispatcher(setClearUserWithdrawalList())
            dispatcher(setClearUserDefaultDetail())
        }
    }, [])

    useEffect(() => {
        if (addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount) {
            if ([masterCurrencyCodeEnum?.USD?.value]?.includes(addUserWithdrawalDetail?.userWithdrawalDetailPayload?.currencyCode)) {
                if (addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount < (user?.user?.minWithdrawalAmount / 100)) {
                    setWithdrawalAmountError(`Minimum withdrawal amount is USD ${user?.user?.minWithdrawalAmount / 100}`)
                }
                if ((addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount >= (user?.user?.minWithdrawalAmount / 100)) && (addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount <= (user?.user?.netWalletCredit / 100))) {
                    setWithdrawalAmountError("")
                }
                if (addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount > user?.user?.netWalletCredit / 100) {
                    setWithdrawalAmountError("Amount exceeds the available balance")
                }
            }
            if ([masterCurrencyCodeEnum?.AUD?.value]?.includes(addUserWithdrawalDetail?.userWithdrawalDetailPayload?.currencyCode)) {
                if (addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount < user?.user?.minWithdrawalAudAmount / 100) {
                    setWithdrawalAmountError(`Minimum withdrawal amount is AUD ${user?.user?.minWithdrawalAudAmount / 100}`)
                }
                if ((addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount >= (user?.user?.minWithdrawalAudAmount / 100)) && (addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount <= (user?.user?.netAudWalletCredit / 100))) {
                    setWithdrawalAmountError("")
                }
                if (addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount > user?.user?.netAudWalletCredit / 100) {
                    setWithdrawalAmountError("Amount exceeds the available balance")
                }
            }
            if ([masterCurrencyCodeEnum?.INR?.value]?.includes(addUserWithdrawalDetail?.userWithdrawalDetailPayload?.currencyCode)) {
                if (addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount < user?.user?.minWithdrawalInrAmount / 100) {
                    setWithdrawalAmountError(`Minimum withdrawal amount is INR ${user?.user?.minWithdrawalInrAmount / 100}`)
                }
                if ((addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount >= (user?.user?.minWithdrawalInrAmount / 100)) && (addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount <= (user?.user?.netInrWalletCredit / 100))) {
                    setWithdrawalAmountError("")
                }
                if (addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount > user?.user?.netInrWalletCredit / 100) {
                    setWithdrawalAmountError("Amount exceeds the available balance")
                }
            }
        }
    }, [addUserWithdrawalDetail?.userWithdrawalDetailPayload, user?.user])

    const handlePayout = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.WITHDRAW_MONEY_MODAL.stateKey]: false
        }))
        navigate(`${pagesInfo?.SETTINGS?.pagePath}/general/payout`)
    }

    const handleCloseButton = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.WITHDRAW_MONEY_MODAL.stateKey]: false
        }))
    }

    const handleNextButton = () => {
        if (userPayoutList?.userPayoutList?.length === 0) return;
        if (!addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount) {
            setWithdrawalAmountError("Please enter amount to withdraw money")
            return;
        }
        if (!withdrawalAmountError && addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount) {
            dispatcher(setAddUserWithdrawalDetailPayload({
                ...addUserWithdrawalDetail?.userWithdrawalDetailPayload,
                withdrawMethod: userPayoutList?.userPayoutList?.find((userPayout) => userPayout?.userPayoutId === userDefaultDetail?.userDefaultDetail?.userPayoutId)
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.WITHDRAW_MONEY_MODAL.stateKey]: false,
                [modalConst.CONFIRM_PAYOUT_MODAL.stateKey]: true
            }))
        }
    }

    if (userWithdrawalList?.isLoading) {
        return (
            <ComponentLoader isLoading={userWithdrawalList?.isLoading} />
        )
    }
    if (userWithdrawalList?.filteredUserWithdrawalList?.results?.length > 0) {
        return (
            <div className={"space-y-5"}>
                <div className={"w-full min-h-[16rem] flex items-center justify-center"}>
                    <div className={"flex flex-col items-start justify-start gap-8"}>
                        <div className={"w-full px-3 py-3 flex flex-col items-start justify-start gap-2 bg-accent-light rounded-full"}>
                            <span className={"w-full font-bodyPri font-normal text-text-800 text-base text-center"}>
                                {"Your payout request for "}
                                <span className={"font-bodyPri font-semibold text-text-900 text-md"}>
                                    {userWithdrawalList?.filteredUserWithdrawalList?.results[0]?.masterCurrency?.code + " " + parseFloat(userWithdrawalList?.filteredUserWithdrawalList?.results[0]?.amount / 100)?.toFixed(2)}
                                </span>
                                {" is currently pending"}
                            </span>
                        </div>
                        <div className={"w-full flex items-center justify-center gap-2 font-bodyPri font-normal text-text-800 text-base"}>
                            {/* <span className={""}>
                                {`Status: ${withdrawalStatus[userWithdrawalList?.filteredUserWithdrawalList?.results[0]?.status?.toUpperCase()]?.label}`}
                            </span> */}
                            <span className={""}>
                                {"Requested" + " " + dayjs(userWithdrawalList?.filteredUserWithdrawalList?.results[0]?.createdAt).tz(timeZone).fromNow()}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"flex justify-end"}>
                    <span
                        className={cn(
                            "px-5 py-1 flex justify-center items-center rounded-full cursor-pointer",
                            "font-bodyPri font-normal text-base text-secondary-main",
                            "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                        )}
                        onClick={handleCloseButton}
                    >
                        {"Close"}
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[24rem] block space-y-5 pt-5"}>
                <div className={"flex flex-col items-start justify-center gap-2.5"}>
                    <div className={"flex items-center justify-start gap-5"}>
                        <span className={"font-bodyPri font-semibold text-text-900 text-lg"}>
                            {"Amount"}
                        </span>
                        <div className={"flex flex-col sm:flex-row sm:items-center sm:justify-start gap-1.5"}>
                            <OptionSelector
                                options={Object.values(masterCurrencyCodeEnum)}
                                className={""}
                                value={addUserWithdrawalDetail?.userWithdrawalDetailPayload?.currencyCode}
                                onChange={(option) => dispatcher(setAddUserWithdrawalDetailPayload({
                                    ...addUserWithdrawalDetail?.userWithdrawalDetailPayload,
                                    currencyCode: option?.value
                                }))}
                            />
                            <input
                                type={"number"}
                                placeholder={"e.g. 100.00"}
                                className={cn(
                                    "w-full px-2 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-lg",
                                    "placeholder:text-text-500 placeholder:text-md"
                                )}
                                autoFocus={true}
                                value={addUserWithdrawalDetail?.userWithdrawalDetailPayload?.amount}
                                onChange={(event) => dispatcher(setAddUserWithdrawalDetailPayload({
                                    ...addUserWithdrawalDetail?.userWithdrawalDetailPayload,
                                    amount: event?.target?.value
                                }))}
                            />
                        </div>
                    </div>
                    <span className={"w-full font-bodyPri font-normal text-red-500 text-sm text-center"}>
                        {withdrawalAmountError}
                    </span>
                </div>
                <hr className={"border border-text-300"} />
                <div className={"flex items-center justify-center gap-2"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-md"}>
                        {"Available Balance"}
                    </span>
                    <span className={"font-bodyPri font-medium text-text-900 text-md"}>
                        {addUserWithdrawalDetail?.userWithdrawalDetailPayload?.currencyCode}
                    </span>
                    {[masterCurrencyCodeEnum?.USD?.value]?.includes(addUserWithdrawalDetail?.userWithdrawalDetailPayload?.currencyCode) &&
                        <span className={"font-bodyPri font-medium text-text-900 text-md"}>
                            {parseFloat(user?.user?.netWalletCredit / 100).toFixed(2)}
                        </span>
                    }
                    {[masterCurrencyCodeEnum?.AUD?.value]?.includes(addUserWithdrawalDetail?.userWithdrawalDetailPayload?.currencyCode) &&
                        <span className={"font-bodyPri font-medium text-text-900 text-md"}>
                            {parseFloat(user?.user?.netAudWalletCredit / 100).toFixed(2)}
                        </span>
                    }
                    {[masterCurrencyCodeEnum?.INR?.value]?.includes(addUserWithdrawalDetail?.userWithdrawalDetailPayload?.currencyCode) &&
                        <span className={"font-bodyPri font-medium text-text-900 text-md"}>
                            {parseFloat(user?.user?.netInrWalletCredit / 100).toFixed(2)}
                        </span>
                    }
                </div>
                <hr className={"border border-text-300"} />

                <div className={"flex items-center justify-between py-5"}>
                    <span className={"font-bodyPri font-normal text-text-900 text-md"}>
                        {"Withdraw method"}
                    </span>
                    <div
                        className={"flex items-center justify-center gap-1 cursor-pointer group"}
                        onClick={handlePayout}
                    >
                        <span className={"font-bodyPri font-normal text-primary-dark text-md group-hover:text-secondary-main"}>
                            {"Payout Methods"}
                        </span>
                        <AiOutlineLink className={"text-lg text-primary-dark group-hover:text-secondary-main"} />
                    </div>
                </div>
                {userPayoutList?.isLoading &&
                    <ComponentLoader isLoading={userPayoutList?.isLoading} />
                }
                {userPayoutList?.userPayoutList?.length > 0 &&
                    <div className={"flex flex-col items-start justify-start gap-5"}>
                        {userPayoutList?.userPayoutList?.filter((payout) => (payout?.payoutType === payoutType?.BANK?.value))?.map((userPayout) => (
                            <BankTransferDetails userPayout={userPayout} />
                        ))}
                        {userPayoutList?.userPayoutList?.filter((payout) => (payout?.payoutType === payoutType?.UPI?.value))?.map((userPayout) => (
                            <UPITransferDetails userPayout={userPayout} />
                        ))}
                        {userPayoutList?.userPayoutList?.filter((payout) => (payout?.payoutType === payoutType?.PAY?.value))?.map((userPayout) => (
                            <PayIDTransferDetails userPayout={userPayout} />
                        ))}
                        {userPayoutList?.userPayoutList?.filter((payout) => (payout?.payoutType === payoutType?.PAYPAL?.value))?.map((userPayout) => (
                            <PayPalIDTransferDetails userPayout={userPayout} />
                        ))}
                    </div>
                }
                {userPayoutList?.userPayoutList?.length === 0 &&
                    <div className={"flex items-center justify-center gap-1.5 group transition delay-150 duration-150 ease-in-out hover:scale-105 cursor-pointer"}>
                        <FaPlus className={"text-md text-text-500 group-hover:text-text-600"} />
                        <div
                            className={cn(
                                "font-bodyPri font-normal text-text-600 text-base",
                                "group-hover:text-text-800"
                            )}
                            onClick={handlePayout}
                        >
                            {"Add Payout Method"}
                        </div>
                    </div>
                }
                {userPayoutList?.errorMsg &&
                    <div className={"flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                        {userPayoutList?.errorMsg}
                    </div>
                }
                {withdrawMethodError &&
                    <div className={"flex items-center justify-center font-bodyPri font-normal text-red-500 text-sm"}>
                        {withdrawMethodError}
                    </div>
                }
            </div>
            <div className={"flex justify-end"}>
                <span
                    className={cn(
                        "px-5 py-1 flex justify-center items-center rounded-full",
                        "font-bodyPri font-normal text-base",
                        userPayoutList?.userPayoutList?.length > 0 && "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer",
                        userPayoutList?.userPayoutList?.length === 0 && "text-text-500 border border-text-300"
                    )}
                    onClick={handleNextButton}
                >
                    {"Next"}
                </span>
            </div>
        </div>
    )
}

export default WithdrawMoneyModal