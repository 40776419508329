import { useCallback, useState } from 'react';

import { cn } from "utils/cn.utils";

import { BsImage } from "react-icons/bs";
import { RiDeleteBin6Line } from 'react-icons/ri';

import Drag from 'pages/auth/edulyteLms/assets/drag.svg';

import ToolTipView from 'components/tooltipView';

import { debounce } from 'utils/generators.utils';

const QuestionOptionContainer = ({
    index = 0,
    isLoading = false,
    inputCheckbox,
    optionLabel = "",
    containerClassName = "",
    inputClassName = "",
    optionInput = "",
    optionInputStatus,
    setOptionInputStatus,
    onHandleUpdateOption,
    minLength = 1,
    maxLength = 150,
    deleteBtnToolTip = "Delete Option",
    attachBtnToolTip = "Attach Image",
    onHandleAttachResource,
    onHandleDeleteOption,
}) => {
    const [optionTitle, setOptionTitle] = useState(optionInput)

    const onHandleChangeInputTitle = useCallback((event) => {
        setOptionInputStatus("Typing...")
        setOptionTitle(event?.target?.value)

        const delayFunction = debounce.debounce(onHandleUpdateOption)
        delayFunction(event?.target?.value)
    }, [optionTitle, optionInputStatus])

    return (
        <div className={"w-full flex flex-col md:flex-row items-start justify-start gap-3 cursor-grab"}>
            <div className={"w-full flex items-start justify-start gap-3 md:gap-5"}>
                <div className={"flex items-center justify-start gap-3 mt-1.5"}>
                    <div className={'w-5 h-5 cursor-grab'}>
                        <img src={Drag} className={"w-full h-full"} />
                    </div>
                    {inputCheckbox}
                    <span className={"font-bodyPri font-medium text-base text-text-800"}>{String.fromCharCode(index + 65)}</span>
                </div>
                <div className={cn("flex-1 space-y-1 transition-transform ease-in-out duration-300", containerClassName)}>
                    <div className={"relative w-full group flex items-center justify-start"}>
                        <input
                            type={"text"}
                            className={cn(
                                "relative flex w-full p-3 focus:outline-none focus:bg-white bg-back-ground-light hover:bg-text-100",
                                "transition ease-in-out duration-300 overflow-hidden",
                                "border-b focus:border-primary-dark",
                                "placeholder:text-text-500", inputClassName
                            )}
                            id={"Option"}
                            name={"Option"}
                            value={optionTitle}
                            minLength={minLength}
                            maxLength={maxLength}
                            onChange={onHandleChangeInputTitle}
                            placeholder={optionLabel}
                        />
                    </div>

                    <div className={"h-1 md:h-2.5"}>
                        {((optionInputStatus === "Typing...") || isLoading) && (
                            <div className={"flex flex-row items-center justify-end gap-3"}>
                                {!isLoading &&
                                    <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                        {`${optionTitle?.trim()?.length}/${maxLength}`}
                                    </span>
                                }
                                <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                    {optionInputStatus}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={"w-full md:w-fit flex items-center justify-end md:justify-start gap-1 mt-1.5"}>
                <ToolTipView content={attachBtnToolTip}>
                    <button
                        className={"p-2 rounded-full hover:bg-divider-darkLight"}
                        onClick={onHandleAttachResource}
                    >
                        <BsImage className={"text-2xl text-text-700 hover:opacity-90 cursor-pointer"} />
                    </button>
                </ToolTipView>
                <ToolTipView content={deleteBtnToolTip}>
                    <button
                        className={"p-2 rounded-full hover:bg-divider-darkLight"}
                        onClick={onHandleDeleteOption}
                    >
                        <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
                    </button>
                </ToolTipView>
            </div>
        </div>
    )
}

export default QuestionOptionContainer;