import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { MdAdd } from "react-icons/md";

import InfinitePagination from "components/pagination/InfinitePagination";

import ContactCardLoader from "./ContactCardLoader";
import ContactCard from "./contactCard";
import Drag from "./dragAndDrop/Drag";
import { searchParamsInfo } from "../data";

import crmService from "redux/crm/crm.service";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
    setAddCrmContactDetailPayload,
    resetAddCrmContactDetailPayload,
    resetModifyCrmBulkContactList,
    resetDestroyCrmBulkContactList,
    resetModifyCrmBulkContactListPayload,
} from "redux/crm/crm.slice";
import { addCrmContactDetailPayload } from "redux/crm/crm.const";

const crmContactInitialState = {
    isLoading: false,
    data: null,
    message: null
}

const DEFAULT_PAGE_COUNT = 1
const DEFAULT_PAGE_RECORDS = 10

function CrmContactCardView({
    category,
    contactStatus,
    buttonTitle = "Add Contact",
    listIndex = 0,
    activeItem = null,
    activeType = null,
    isDragging = false
}) {
    const { user } = useAppState((state) => state.user)
    const { addCrmContactDetail, modifyCrmContactDetail, destroyCrmContactDetail, modifyCrmBulkContactList, destroyCrmBulkContactList } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const [crmContactList, setCrmContactList] = useState(crmContactInitialState)

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search), [location.search])

    useEffect(() => {
        const requestDataPayload = {
            status: contactStatus?.key,
            category: category,
            page: DEFAULT_PAGE_COUNT,
            records: DEFAULT_PAGE_RECORDS
        }
        getContactList(requestDataPayload)
    }, [contactStatus])

    useEffect(() => {
        if (modifyCrmBulkContactList?.data || destroyCrmBulkContactList?.data) {
            const requestDataPayload = {
                status: contactStatus?.key,
                category: category,
                page: DEFAULT_PAGE_COUNT,
                records: DEFAULT_PAGE_RECORDS
            }
            if (modifyCrmBulkContactList?.data) {
                const statusCategory = modifyCrmBulkContactList?.data[0]?.category
                if (statusCategory === category) {
                    getContactList(requestDataPayload)
                }
            } else if (destroyCrmBulkContactList?.data) {
                getContactList(requestDataPayload)
            }
            dispatcher(resetModifyCrmBulkContactList())
            dispatcher(resetModifyCrmBulkContactListPayload())
            dispatcher(resetDestroyCrmBulkContactList())
        }
    }, [modifyCrmBulkContactList?.data, destroyCrmBulkContactList?.data])

    useEffect(() => {
        if (addCrmContactDetail?.data || modifyCrmContactDetail?.data || destroyCrmContactDetail?.data) {
            getUpdatedContactList()
        }
    }, [addCrmContactDetail?.data, modifyCrmContactDetail?.data, destroyCrmContactDetail?.data])

    const getContactList = async (query) => {
        setCrmContactList(prev => ({ ...prev, isLoading: true }))

        try {
            const requestData = {
                params: { userId: user?.user?.userId },
                query: query
            }
            const response = await crmService.getCrmContactList(requestData)
            if (response.status === 200) {
                setCrmContactList(prev => ({
                    ...prev,
                    data: response.data.data
                }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            setCrmContactList(prev => ({
                ...prev,
                message: error.response.data.message || error.response.data.error || "Something went wrong!"
            }))
            // toast.error(error.response.data.message || error.response.data.error || error)
        } finally {
            setCrmContactList(prev => ({ ...prev, isLoading: false }))
        }
    }

    const getUpdatedContactList = () => {
        if (addCrmContactDetail?.data && (contactStatus?.key === addCrmContactDetail?.data?.status?.key)) {
            setCrmContactList(prev => ({
                ...prev,
                data: {
                    ...prev?.data,
                    results: [...crmContactList?.data?.results, addCrmContactDetail?.data],
                    records: prev?.data?.records + 1,
                    totalRecords: prev?.data?.totalRecords + 1
                }
            }))
        } else if (modifyCrmContactDetail?.data) {
            const filteredContactList = crmContactList?.data?.results?.filter((item) => item?.id !== modifyCrmContactDetail?.data?.id)
            if ((contactStatus?.key === modifyCrmContactDetail?.data?.status?.key)) {
                setCrmContactList(prev => ({
                    ...prev,
                    data: {
                        ...prev?.data,
                        results: [modifyCrmContactDetail?.data, ...filteredContactList]
                    }
                }))
            } else {
                setCrmContactList(prev => ({
                    ...prev,
                    data: {
                        ...prev?.data,
                        results: filteredContactList
                    }
                }))
            }
        } else if (destroyCrmContactDetail?.data) {
            const contactId = searchQueryParams?.get(searchParamsInfo.contact.key)
            setCrmContactList(prev => ({
                ...prev,
                data: {
                    ...prev?.data,
                    results: crmContactList?.data?.results?.filter((item) => (item?.id != contactId))
                }
            }))
        }
    }

    const fetchMoreData = async () => {
        if (crmContactList?.data?.page === crmContactList?.data?.totalPages) return;

        const requestDataPayload = {
            status: contactStatus?.key,
            category: category,
            page: crmContactList?.data?.page
                ? crmContactList?.data?.page + 1
                : DEFAULT_PAGE_COUNT,
            records: DEFAULT_PAGE_RECORDS
        }
        setCrmContactList(prev => ({ ...prev, isLoading: true }))

        try {
            const requestData = {
                params: { userId: user?.user?.userId },
                query: requestDataPayload
            }
            const response = await crmService.getCrmContactList(requestData)
            if (response.status === 200) {
                setCrmContactList(prev => ({
                    ...prev,
                    data: prev?.data ? {
                        ...prev?.data,
                        results: [...prev?.data?.results, ...response?.data.data?.results],
                        records: prev?.data?.records + response.data.data.records,
                        totalPages: response.data.data.totalPages,
                        totalRecords: response.data.data.totalRecords,
                        page: response.data.data.page
                    } : response.data.data
                }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
            setCrmContactList(prev => ({
                ...prev,
                message: error.response.data.message || error.response.data.error || "Something went wrong!"
            }))
            // toast.error(error.response.data.message || error.response.data.error || error)
        } finally {
            setCrmContactList(prev => ({ ...prev, isLoading: false }))
        }
    }

    const onHandleCreateNewContact = useCallback(() => {
        dispatcher(resetAddCrmContactDetailPayload())
        dispatcher(setAddCrmContactDetailPayload({
            ...addCrmContactDetailPayload,
            category: category,
            status: { label: contactStatus?.name, value: contactStatus?.key }
        }))
        searchQueryParams.set(searchParamsInfo.action.key, searchParamsInfo.createContact.key);
        navigate(`?${searchQueryParams.toString()}`);
    }, [contactStatus, category])

    return (
        <div className={"min-w-[300px] font-bodyPri flex flex-col mb-3 pb-2 bg-back-ground-light rounded-lg cursor-pointer select-none"}>
            <div
                className="flex justify-between items-center py-2 px-3 rounded-t-lg font-bodyPri"
                style={{ backgroundColor: contactStatus?.color ? contactStatus?.color : "#9e9e9e" }}
            >
                <h2 className="font-medium text-white">
                    {(contactStatus?.name === "undefined") ? "Unknown" : contactStatus?.name}
                </h2>
                {!!crmContactList?.data?.results?.length &&
                    <p className="text-sm text-text-200">
                        {crmContactList?.data?.results?.filter((i) => i?.status?.key === contactStatus?.key)?.length}
                    </p>
                }
            </div>
            <div
                id={"scrollableDiv"}
                style={{ maxHeight: "calc(100vh - 16rem)" }}
                className={"flex flex-col gap-2 p-2 overflow-y-auto scrollbar-thin"}
            >
                {!!crmContactList?.data?.results?.length &&
                    <InfinitePagination
                        scrollableTarget={"scrollableDiv"}
                        inverse={true}
                        dataLength={crmContactList?.data?.totalRecords}
                        fetchData={fetchMoreData}
                        hasMore={(crmContactList?.data?.page != crmContactList?.data?.totalPages)}
                        loader={<ContactCardLoader isLoading={crmContactList?.isLoading} />}
                        isShowEndMessage={false}
                    >
                        {crmContactList?.data?.results?.filter((item) => (item?.status?.key === contactStatus?.key))?.map((item, cardIndex) => (
                            <Drag.DropZones key={item?.id} dropStatus={contactStatus} prevId={`${listIndex}-${cardIndex}`} nextId={`${listIndex}-${cardIndex + 1}`} dropType={"card"} remember={true}>
                                <Drag.DropGuide dropId={`${listIndex}-${cardIndex}`} className={"rounded-lg bg-gray-200 h-[8rem]"} dropType={"card"} remember={true} />
                                <Drag.DragItem
                                    key={item?.id}
                                    dragId={item}
                                    dragType={"card"}
                                    className={`w-full cursor-pointer ${((activeItem?.id === item?.id) && (activeType === "card") && isDragging) ? "hidden" : "translate-x-0"}`}
                                >
                                    <ContactCard
                                        key={cardIndex}
                                        contact={item}
                                        category={category}
                                        dragItem={(activeItem?.id === item?.id) && (activeType === "card")}
                                    />
                                </Drag.DragItem>
                            </Drag.DropZones>
                        ))}
                    </InfinitePagination>
                }
                <Drag.DropZone dropStatus={contactStatus} dropId={`${listIndex}-${crmContactList?.data?.results?.length}`} dropType={"card"} remember={true}>
                    {!crmContactList?.data?.results?.length &&
                        <div className={"h-[1.5rem]"}></div>
                    }
                    <Drag.DropGuide dropId={`${listIndex}-${crmContactList?.data?.results?.length}`} className={"rounded-lg bg-gray-200 h-[8rem]"} dropType={"card"} />
                </Drag.DropZone>
                {new Array(DEFAULT_PAGE_RECORDS).fill("").map((_, index) => (
                    <ContactCardLoader key={index} isLoading={crmContactList?.isLoading} />
                ))}
            </div>
            <div className={`px-3 ${!!crmContactList?.data?.results?.length && "mt-3"}`}>
                <button
                    className={"flex w-full items-center gap-2 px-3 py-1 hover:bg-primary-light rounded-xl font-bodyPri text-sm"}
                    onClick={onHandleCreateNewContact}
                >
                    <MdAdd />
                    {buttonTitle}
                </button>
            </div>
        </div>
    );
}

export default CrmContactCardView;