import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { motion, AnimatePresence } from "framer-motion";

import ButtonLoader from 'components/loader/ButtonLoader';

import { MdArrowDropDown } from 'react-icons/md';

import { eventActionBtnConst } from '../data';

import { deleteUserEventlyDetail, getUserEventlyDetail, updateUserEventlyDetail } from 'redux/evently/evently.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetEventlyInvitationList } from 'redux/evently/evently.slice';

import { cn } from 'utils/cn.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import { getCustomHostPath } from 'utils/generators.utils';

const EventlyCtaButtons = ({ containerClassName = ""}) => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { user } = useAppState((state) => state.user)
    const { userEventlyDetail, addUserEventlyDetail, modifyUserEventlyDetail, destroyUserEventlyDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher()
    const { eventlyId } = useParams()

    const dropdownRef = useRef(null)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)

    const isMeetingOwner = (userEventlyDetail?.data?.owner_user?.userId === user?.user?.userId)

    const onHandleCloseDropdown = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event?.target))
            setDropdownOpen(false)
    }

    useEffect(() => {
        document.addEventListener("click", onHandleCloseDropdown)

        return () => {
            document.removeEventListener("click", onHandleCloseDropdown)
        }
    }, [])

    const onHandleSave = () => {
        if (addUserEventlyDetail?.isLoading || modifyUserEventlyDetail?.isLoading || destroyUserEventlyDetail?.isLoading) return;
        const body = {
            title: modifyUserEventlyDetail?.payload?.title,
        }
        // if (!!modifyUserEventlyDetail?.payload?.selectedGuestsList) {
        //     body["emails"] = modifyUserEventlyDetail?.payload?.selectedGuestsList?.filter((guestUser) => (guestUser?.email != user?.user?.email))?.map((userEmail) => userEmail?.email)
        // }
        if (modifyUserEventlyDetail?.payload?.meetingLink) {
            body["link"] = modifyUserEventlyDetail?.payload?.meetingLink
        }
        if (modifyUserEventlyDetail?.payload?.selectedLocation) {
            body["location"] = modifyUserEventlyDetail?.payload?.selectedLocation
        }
        if (modifyUserEventlyDetail?.payload?.isSendEmail) {
            body["isSendEmail"] = modifyUserEventlyDetail?.payload?.isSendEmail
        }
        if (modifyUserEventlyDetail?.payload?.description) {
            body["description"] = modifyUserEventlyDetail?.payload?.description
        }
        dispatcher(updateUserEventlyDetail(userEventlyDetail?.data?.id, body))
        dispatcher(resetEventlyInvitationList())
    }

    const onHandleSelectOption = (actionBtn) => {
        if (addUserEventlyDetail?.isLoading || modifyUserEventlyDetail?.isLoading || destroyUserEventlyDetail?.isLoading || !isMeetingOwner) return;

        setSelectedOption(actionBtn?.value)
        setDropdownOpen(false)
        if (actionBtn?.value === eventActionBtnConst.VIEW.value) {
            // window.open(`${EDULYTE_WEB_DOMAIN_URL}/evently/${userEventlyDetail?.data?.id}`, "_blank")
            window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/evently/${userEventlyDetail?.data?.id}` })}`, "_blank")
        } else if (actionBtn?.value === eventActionBtnConst.RESET.value) {
            dispatcher(getUserEventlyDetail(eventlyId))
        } else if (actionBtn?.value === eventActionBtnConst.DELETE.value) {
            dispatcher(deleteUserEventlyDetail(userEventlyDetail?.data?.id))
        }
    }

    return (
        <div className={"flex items-center justify-start gap-2"}>
            <button
                onClick={onHandleSave}
                className={cn(
                    "w-fit px-5 py-2 flex items-center justify-center rounded-md",
                    "bg-primary-dark hover:opacity-90 cursor-pointer"
                )}
            >
                {modifyUserEventlyDetail?.isLoading &&
                    <ButtonLoader isLoading={modifyUserEventlyDetail?.isLoading} className={"w-fit"} />
                }
                {!modifyUserEventlyDetail?.isLoading &&
                    <span
                        className={"font-bodyPri font-medium text-text-50 text-base tracking-wide"}>
                        {"Save"}
                    </span>
                }
            </button>
            {isMeetingOwner &&
                <div
                    ref={dropdownRef}
                    className={"relative flex flex-col gap-1.5"}
                >
                    <button
                        className={cn(
                            "px-4 py-2 w-fit flex items-center justify-between rounded-md",
                            "font-bodyPri font-normal text-text-900 text-base tracking-wide",
                            "bg-text-100 ease-in-out duration-300",
                            (addUserEventlyDetail?.isLoading || destroyUserEventlyDetail?.isLoading) && "!bg-text-400"
                        )}
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                        {(addUserEventlyDetail?.isLoading || destroyUserEventlyDetail?.isLoading) &&
                            <ButtonLoader
                                isLoading={addUserEventlyDetail?.isLoading || destroyUserEventlyDetail?.isLoading}
                                className={"w-16"}
                            />
                        }
                        {((!addUserEventlyDetail?.isLoading && !destroyUserEventlyDetail?.isLoading) && selectedOption)
                            && eventActionBtnConst[selectedOption?.toUpperCase()]?.label
                        }
                        {((!addUserEventlyDetail?.isLoading && !destroyUserEventlyDetail?.isLoading) && !selectedOption)
                            && "More actions"
                        }
                        <MdArrowDropDown
                            className={`text-lg transform ease-in-out duration-200 transition-transform ${dropdownOpen ? 'rotate-180' : ''}`}
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                        />
                    </button>
                    <AnimatePresence>
                        {dropdownOpen && (
                            <ul
                                className={cn(
                                    "w-full absolute z-20 top-[2.5rem] right-0 py-1 rounded-md",
                                    "drop-shadow-md bg-white border border-gray-300",
                                    "transition-transform ease-in-out duration-200 delay-150"
                                )}>
                                <motion.div
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: "auto", opacity: 1 }}
                                    exit={{ height: 0, opacity: 0 }}
                                >
                                    {Object.values(eventActionBtnConst)?.map((actionBtn, index) => (
                                        <li
                                            key={index}
                                            className={"w-full py-2 px-3 hover:bg-text-200 cursor-pointer"}
                                            onClick={() => onHandleSelectOption(actionBtn)}>
                                            <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                                {actionBtn?.label}
                                            </span>
                                        </li>
                                    ))}
                                </motion.div>
                            </ul>
                        )}
                    </AnimatePresence>
                </div>
            }
        </div>
    )
}

export default EventlyCtaButtons;