import { useEffect, useMemo, useState } from 'react';
import { cn } from "utils/cn.utils";

import { BsCreditCard2Back } from 'react-icons/bs';
import { ImCheckboxChecked } from 'react-icons/im';
import { ImCheckboxUnchecked } from 'react-icons/im';
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import { BiCreditCard } from 'react-icons/bi';

import { Toggle } from "components/common-components/Toggle";
import ComponentLoader from "components/loader/ComponentLoader";

import { getUserPaymentMethodList } from 'redux/payment/payment.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { setAddTokensPayload } from 'redux/wallet/wallet.slice';
import { setAddPaymentIntentDetailPayload } from 'redux/payment/payment.slice';
import { paymentFundingEnum, gatewayProviderEnum } from 'redux/payment/payment.const';
import { modalConst } from 'redux/local/local.const';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';

const PaymentMethodSection = ({ handleWalletToggle }) => {
    const { modal } = useAppState(s => s.local)
    const { user } = useAppState(s => s.user)
    const { userPaymentMethodList, addPaymentIntentDetail } = useAppState((state) => state.payment)
    const { addTokens } = useAppState(s => s.wallet)
    const [selectedGateway, setSelectedGateway] = useState(null);

    const dispatcher = useAppDispatcher()

    const gateways = useMemo(() => {
        let gatewayList = {};
        if (user?.user?.gateways?.primary?.provider_type) {
            gatewayList = {
                primary: gatewayProviderEnum[user?.user?.gateways?.primary?.provider_type],
                secondary: gatewayProviderEnum[user?.user?.gateways?.secondary?.provider_type],
            }
        }
        return gatewayList;
    }, [user?.user?.gateways?.primary?.provider_type, user?.user?.gateways?.secondary?.provider_type]);

    useEffect(() => {
        if (!!gateways) {
            for (const key in gateways) {
                if (key === 'primary') {
                    setSelectedGateway(gateways[key]?.key)
                }
            }
        }
    }, [gateways])

    const userWallet = useMemo(() => ({
        'USD': user?.user?.netWalletCredit,
        'INR': user?.user?.netInrWalletCredit,
        'AUD': user?.user?.netAudWalletCredit,
    }), [user?.user?.netWalletCredit, user?.user?.netInrWalletCredit, user?.user?.netAudWalletCredit])

    const currentMasterCurrency = useMemo(() => addTokens?.addTokenPayload?.currencyCode, [addTokens?.addTokenPayload?.currencyCode])
    let isGatewayIncluded = addTokens?.addTokenPayload?.payThroughGateWay

    useEffect(() => {
        dispatcher(getUserPaymentMethodList(user?.user?.userId))
    }, [])

    useEffect(() => {
        if (isGatewayIncluded) {
            if (currentMasterCurrency === masterCurrencyCodeEnum.INR.value) { 
                selectGateway(gatewayProviderEnum.razorpay)
            } else {
                selectGateway(gatewayProviderEnum.stripe)
            }
        }
    }, [currentMasterCurrency, isGatewayIncluded])

    useEffect(() => {
        if (userPaymentMethodList?.data && (userPaymentMethodList?.data?.length > 0)) {
            selectPaymentMethod(userPaymentMethodList?.data[0])
        }
    }, [userPaymentMethodList?.data])

    const selectGateway = (gateway) => {
        dispatcher(setAddTokensPayload({
            ...addTokens?.addTokenPayload,
            paymentMethod: null
        }))

        dispatcher(setAddPaymentIntentDetailPayload({
            ...addPaymentIntentDetail?.payload,
            paymentMethodId: null,
            gatewayName: gateway.key
        }))
    }

    const addPaymentMethod = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.PAYMENT_CARD_MODAL.stateKey]: true
        }))
    }

    useEffect(() => {
        if (addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.stripe.key) {
            dispatcher(getUserPaymentMethodList(user?.user?.userId))
        }
    }, [addPaymentIntentDetail?.payload?.gatewayName])

    const selectPaymentMethod = (selectedPaymentMethod) => {

        dispatcher(setAddTokensPayload({
            ...addTokens?.addTokenPayload,
            paymentMethod: selectedPaymentMethod
        }))

        dispatcher(setAddPaymentIntentDetailPayload({
            ...addPaymentIntentDetail?.payload,
            paymentMethodId: selectedPaymentMethod?.id
        }))
    }


    const GatewayOption = ({ gateway }) => {
        if (!gateway) return null;

        const isSelected = selectedGateway === gateway.key;
        
        const handleToggle = (gateway) => {
            setSelectedGateway(gateway.key);
            if (isGatewayIncluded) selectGateway(gateway);
        };


        return (
            <div className="p-2 flex gap-3">
                <div className="mt-5" onClick={() => handleToggle(gateway)}>
                    {isSelected ? (
                        <MdOutlineRadioButtonChecked className={cn("text-primary-dark text-2xl cursor-pointer")} />
                    ) : (
                        <MdOutlineRadioButtonUnchecked className={cn("text-primary-dark text-2xl cursor-pointer")} />
                    )}
                </div>
                <div className="flex flex-row gap-2">
                    <img src={gateway.image} alt="gateway" className={cn("w-28 aspect-video object-contain")} />
                    <p className={cn("pl-2 font-medium text-muted-foreground/70")}>
                        {gateway.info}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div className={"space-y-3"}>
            <div className={"font-bodyPri font-medium text-lg text-text-800 space-x-3"}>
                <span>{"2"}</span>
                <span>{"Payment Method"}</span>
            </div>
            <div className={"W-full rounded-lg bg-white shadow-all p-5 space-y-3"}>

                <div className={"w-full flex items-center justify-between gap-3"}>
                    <span className={"font-bodyPri font-normal text-base text-text-800 truncate whitespace-nowrap"}>
                        {"My available balance"}
                    </span>
                    <div className={"flex items-center justify-end gap-2"}>
                        <div className={"flex items-center justify-start gap-1 font-bodyPri font-medium text-base text-text-900"}>
                            <span>{addTokens?.addTokenPayload?.currencyCode}</span>
                            <span>{parseFloat(userWallet[addTokens?.addTokenPayload?.currencyCode] / 100).toFixed(2)}</span>
                        </div>
                        <Toggle
                            value={addTokens?.addTokenPayload?.payThroughWallet}
                            onChange={handleWalletToggle}
                        />
                    </div>
                </div>

                <hr className={"h-0.5 bg-divider-medium w-full rounded-full"} />

                {/* show gateways here */}
                <div className="w-full">
                    {(gateways?.primary || gateways?.secondary)&&
                        <div className="w-full space-y-2 border divide-y">
                            <GatewayOption gateway={gateways.primary} />
                            <GatewayOption gateway={gateways.secondary} />
                        </div>
                    }
                    {(!gateways?.primary && !gateways?.secondary)&&
                        <div className='font-bodyPrimary font-normal text-base w-full text-center'>
                            {"No payment gateway available"}
                        </div>
                    }
                </div>

                {(isGatewayIncluded && addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.stripe.key) && (<div className={"w-full flex flex-col items-start gap-5"}>
                    <div className={"w-full flex items-center justify-between gap-2"}>
                        <div className={"flex items-center gap-5"}>
                            <BsCreditCard2Back className={"text-text-700 text-xl"} />
                            <span className={"font-bodyPri font-medium text-md text-text-800"}>{"Credit/Debit Card Detail"}</span>
                        </div>
                        <div
                            className={cn(
                                "px-3 py-1 font-bodyPri font-normal text-sm text-primary-dark bg-primary-light hover:bg-primary-dark hover:text-text-50 hover:opacity-90 cursor-pointer rounded-md",
                                !isGatewayIncluded && "!bg-back-ground-darkLight !text-text-50 cursor-not-allowed"
                            )}
                            onClick={() => {
                                if (isGatewayIncluded) addPaymentMethod()
                            }}
                        >
                            {"Add Payment Method"}
                        </div>
                    </div>

                    <div className={"w-full flex flex-col items-start gap-3 px-10"}>
                        <ComponentLoader isLoading={userPaymentMethodList?.isLoading} className={"h-40"} />
                        {userPaymentMethodList?.data?.map((paymentMethod, index) => {
                            let isSelected = (paymentMethod?.id === addTokens?.addTokenPayload?.paymentMethod?.id)

                            return (
                                <div className={"w-full md:w-[40%]  flex gap-3 items-start"}>
                                    <div onClick={() => selectPaymentMethod(paymentMethod)}>
                                        {isSelected && <ImCheckboxChecked className={cn("mt-3 text-primary-dark cursor-pointer", !isGatewayIncluded && "text-text-100 cursor-default")} />}
                                        {!isSelected && <ImCheckboxUnchecked className={cn("mt-3 text-text-500 cursor-pointer", !isGatewayIncluded && "text-text-100 cursor-default")} />}
                                    </div>
                                    <div key={index} className={cn(
                                        "w-full flex flex-col items-start gap-1 p-3 border-2 border-text-300 rounded-lg",
                                        isSelected && "border-primary-dark",
                                        !isGatewayIncluded && "border-text-100"
                                    )}>
                                        <span className={cn(
                                            "w-full flex items-center justify-between gap-2"
                                        )}>
                                            <span className={"flex items-center justify-start gap-2 font-bodyPri font-medium text-base text-text-900"}>
                                                <BiCreditCard />
                                                {`${paymentMethod?.card?.brand?.toUpperCase().replace("_", " ")} ${paymentFundingEnum[paymentMethod?.card?.funding?.toUpperCase()].label} Card`}
                                            </span>
                                        </span>
                                        <span className={cn(
                                            "font-bodyPri font-medium text-md text-text-900"
                                        )}>
                                            {`xxxx xxxx xxxx ${paymentMethod?.card?.last4}`}
                                        </span>
                                        <span className={cn(
                                            "font-bodyPri font-medium text-md text-text-900"
                                        )}>
                                            {`${paymentMethod?.card?.exp_month}/${paymentMethod?.card?.exp_year}  `}
                                            <span className={cn(
                                                "font-bodyPri font-normal text-base text-text-800"
                                            )}>
                                                {`(${paymentMethod?.card?.country}) ${paymentMethod?.billing_details?.name ? paymentMethod?.billing_details?.name : ""}`}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>)}

            </div>
        </div>
    )
}

export default PaymentMethodSection;