import { memo, useCallback, useState } from "react";

import { cn } from "utils/cn.utils";
import { motion, AnimatePresence } from "framer-motion";
import { FiChevronsDown } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

import ToolTipView from "components/tooltipView";

import { libraryTabConst } from 'components/modals/lmsModals/attachLibraryModal/data';

import Drag from "pages/auth/edulyteLms/assets/drag.svg";
import UpdateLmsTextInputText from "pages/auth/edulyteLms/commonComponents/UpdateLmsInputText";
import LmsIconButtons from "pages/auth/edulyteLms/commonComponents/lmsButtons/LmsIconButtons";

import { quizSectionIconList } from "../../../editLMSQuiz.data";
import LmsQuizSectionCategoryList from "./LmsQuizSectionCategoryList";

import { deleteLmsQuizSectionDetail, updateLmsQuizSectionDetail } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetLocalTabs, setLocalTabs, setModal } from "redux/local/local.slice";
import { resetAddLmsQuizSectionPayload, setAddLmsQuizSectionPayload, setLmsQuizDetailData } from "redux/edulyteLms/lmsQuiz/lmsQuiz.slice";
import { resetFilterProps, setFilterProps } from "redux/edulyteLms/lmsResource/lmsResource.slice";
import { modalConst } from "redux/local/local.const";
import { addLmsQuizSectionPayload, lmsQuizCategoryEnum } from "redux/edulyteLms/lmsQuiz/lmsQuiz.const";
import { lmsResourceTypeEnum, resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";

const LMSQuizSectionItem = memo(({ quizSectionItem }) => {
    const { modal, localTabs } = useAppState((state) => state.local)
    const { lmsQuizDetail, addLmsQuizSection, updateLmsQuizSection, deleteLmsQuizSection } = useAppState((state) => state.lms.lmsQuiz)
    const { filterProps } = useAppState((state) => state.lms.lmsResource)

    const dispatcher = useAppDispatcher()

    const [sectionTitleStatus, setSectionTitleStatus] = useState("")

    const onHandleToggle = (quizSectionId) => {
        dispatcher(resetLocalTabs())
        if (addLmsQuizSection?.payload?.sectionId === quizSectionId) {
            dispatcher(setAddLmsQuizSectionPayload({
                ...addLmsQuizSectionPayload,
                sectionId: null
            }))
        } else {
            dispatcher(setAddLmsQuizSectionPayload({
                ...addLmsQuizSectionPayload,
                sectionId: quizSectionId
            }))
        }
    }

    const onHandleSaveSectionTitle = (editableSectionTitle) => {
        if (editableSectionTitle === quizSectionItem?.title) return;
        setSectionTitleStatus("Saving...")

        const body = {
            title: editableSectionTitle
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsQuizSectionDetail(quizSectionItem?.id, body, payload))

        setTimeout(() => {
            setSectionTitleStatus("")
        }, 1000)
    }

    const onHandleDeleteCourseSection = () => {
        if (addLmsQuizSection?.isLoading || updateLmsQuizSection?.isLoading || deleteLmsQuizSection?.isLoading) return;

        dispatcher(deleteLmsQuizSectionDetail(quizSectionItem?.id))
    }
    const onHandleSelectItem = useCallback((selectedItem) => {
        dispatcher(resetAddLmsQuizSectionPayload())
        if ([quizSectionIconList.BULK_QUESTION.value].includes(selectedItem?.value)) {
            dispatcher(setAddLmsQuizSectionPayload({
                ...addLmsQuizSectionPayload,
                sectionId: quizSectionItem?.id,
                quizCategoryType: selectedItem,
            }))

            dispatcher(setModal({
                ...modal,
                [modalConst.CREATE_BULK_QUESTIONS.stateKey]: true

            }))
            return;
        }
        dispatcher(resetLocalTabs())
        dispatcher(setAddLmsQuizSectionPayload({
            ...addLmsQuizSectionPayload,
            sectionId: quizSectionItem?.id,
            quizCategoryType: selectedItem,
        }))

        if ([lmsQuizCategoryEnum?.QUESTION?.value]?.includes(selectedItem?.value)) {
            dispatcher(setLocalTabs({
                ...localTabs,
                modalTitle: "Library",
                tabList: Object.values(libraryTabConst),
                activeTabList: [libraryTabConst.QUESTIONS.value],
                currentActiveTab: libraryTabConst.QUESTIONS.value,
            }))
        }
        if ([lmsQuizCategoryEnum?.ARTICLE?.value]?.includes(selectedItem?.value)) {
            dispatcher(setLocalTabs({
                ...localTabs,
                modalTitle: "Library",
                tabList: Object.values(libraryTabConst),
                activeTabList: [libraryTabConst.ARTICLES.value],
                currentActiveTab: libraryTabConst.ARTICLES.value,
            }))
        }
        if ([lmsQuizCategoryEnum?.IMAGE?.value, lmsQuizCategoryEnum?.VIDEO?.value]?.includes(selectedItem?.value)) {
            dispatcher(resetFilterProps())
            dispatcher(setLocalTabs({
                ...localTabs,
                modalTitle: "Library",
                tabList: Object.values(libraryTabConst),
                activeTabList: [libraryTabConst.RESOURCES.value],
                currentActiveTab: libraryTabConst.RESOURCES.value,
            }))
            if ([lmsQuizCategoryEnum?.IMAGE?.label]?.includes(selectedItem?.label)) {
                dispatcher(setFilterProps({
                    filterProps: {
                        ...filterProps,
                        resourceType: lmsResourceTypeEnum.FILE.value,
                        resourceFileType: resourceFileTypeEnum.IMAGE.value,
                        disabledFilters: [lmsResourceTypeEnum.YOUTUBE.value, lmsResourceTypeEnum.LINK.value]
                    }
                }))
            } else {
                dispatcher(setFilterProps({
                    filterProps: {
                        ...filterProps,
                        resourceType: lmsResourceTypeEnum.FILE.value,
                        resourceFileType: resourceFileTypeEnum.VIDEO.value,
                        disabledFilters: [lmsResourceTypeEnum.YOUTUBE.value, lmsResourceTypeEnum.LINK.value]
                    }
                }))
            }
        }
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true
        }))
    }, [modal, localTabs, lmsQuizDetail?.data])

    return (
        <div className={cn(
            'w-full border rounded-lg',
            (addLmsQuizSection?.payload?.sectionId === quizSectionItem?.id)
                ? 'border-primary-main'
                : 'border-back-ground-darkLight'
        )}>
            <div
                className={cn(
                    'w-full flex flex-col px-4 py-2 md:flex-row md:items-center md:justify-between rounded-t-lg',
                    'transition-transform ease-in-out delay-200 duration-700 drop-shadow-sm',
                    (addLmsQuizSection?.payload?.sectionId === quizSectionItem?.id)
                        ? 'bg-primary-light'
                        : 'bg-white rounded-lg'
                )}
            >
                <div className={cn('flex flex-1 gap-1 justify-start items-center cursor-grab')}>
                    <img
                        src={Drag}
                        className={"w-2.5 cursor-grab"}
                    />
                    <UpdateLmsTextInputText
                        isLoading={updateLmsQuizSection?.isLoading}
                        isShowInputLabel={false}
                        inputClassName={cn(
                            "font-bodyPri font-medium text-black text-lg hover:!bg-text-100 focus:!border-b focus:!border-primary-dark",
                            (addLmsQuizSection?.payload?.sectionId === quizSectionItem?.id) && "!bg-primary-light"
                        )}
                        title={quizSectionItem?.title}
                        titleInputStatus={sectionTitleStatus}
                        setTitleInputStatus={setSectionTitleStatus}
                        onHandleSaveTitle={onHandleSaveSectionTitle}
                    />
                </div>
                <div className={"w-full md:w-fit flex justify-end md:justify-start items-center gap-2"}>
                    <LmsIconButtons
                        iconButtonList={Object.values(quizSectionIconList)}
                        onHandleIconButton={onHandleSelectItem}
                    />
                    <ToolTipView content={"Delete Section"}>
                        <button
                            className={"p-2 rounded-full shadow-sm hover:bg-divider-darkLight group"}
                            onClick={onHandleDeleteCourseSection}
                        >
                            <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
                        </button>
                    </ToolTipView>
                    <button
                        className={cn(
                            'text-black cursor-pointer ease-in-out duration-300 delay-200',
                            "p-2 rounded-full shadow-sm hover:bg-divider-darkLight",
                            (addLmsQuizSection?.payload?.sectionId === quizSectionItem?.id) ? 'rotate-180 ease-in-out duration-300' : ''
                        )}
                        onClick={() => onHandleToggle(quizSectionItem?.id)}
                    >
                        <FiChevronsDown size={22} />
                    </button>
                </div>
            </div>
            <AnimatePresence initial={addLmsQuizSection?.payload?.sectionId}>
                {(addLmsQuizSection?.payload?.sectionId === quizSectionItem?.id) &&
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="overflow-hidden"
                    >
                        <LmsQuizSectionCategoryList quizSectionItem={quizSectionItem} />
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
});

export default LMSQuizSectionItem;