import { useCallback, useEffect, useMemo, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import { MdAdd } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import Pagination from 'components/pagination/Pagination';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import DownloadTable from 'pages/auth/editDownload/commonComponents/DownloadTable';
import DownloadTooltip from '../../commonComponents/DownloadTooltip';
import { downloadParticipantsNavigationOptions, downloadStudentInvitationHeaderConst, downloadStudentInviteMenuItemsConst, downloadTooltips } from '../../data';

import { deleteDownloadInvitationDetail, getDownloadInvitationList } from 'redux/downloads/downloads.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetDownloadInvitationList, resetSendDownloadInvitationDetail, resetSendDownloadInvitationDetailPayload } from 'redux/downloads/downloads.slice';
import { modalConst } from 'redux/local/local.const';
import { downloadInviteStatusEnum, downloadStatusEnum } from 'redux/downloads/downloads.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { generateQueryParams } from 'utils/generators.utils';
import { pagesInfo } from 'utils/pagesInfo';

const RECORDS = 10;

const Invited = () => {
    const { modal } = useAppState((state) => state.local)
    const { ownerUserDownloadDetail, downloadInvitationList, sendDownloadInvitationDetail } = useAppState((state) => state.downloads)
    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const query = useMemo(() => generateQueryParams(location.search), [location.search])

    useEffect(() => {
        return () => {
            dispatcher(resetDownloadInvitationList())
        }
    }, [])

    useEffect(() => {

        return () => {
            dispatcher(resetSendDownloadInvitationDetail())
            dispatcher(resetSendDownloadInvitationDetailPayload())
            dispatcher(setModal({
                ...modal,
                [modalConst.inviteDownloadUserModal.key]: {
                    ...modal[modalConst.inviteDownloadUserModal.key],
                    isVisible: false,
                    title: "Invite User"
                }
            }))
        }
    }, [query?.action])

    useEffect(() => {
        if (ownerUserDownloadDetail?.data) {
            if (query && (query?.action && (query?.action === "invite-participants"))) {
                if (ownerUserDownloadDetail?.data?.status === downloadStatusEnum?.PUBLISHED?.value) {
                    dispatcher(resetSendDownloadInvitationDetail())
                    dispatcher(resetSendDownloadInvitationDetailPayload())
                    dispatcher(setModal({
                        ...modal,
                        [modalConst.inviteDownloadUserModal.key]: {
                            ...modal[modalConst.inviteDownloadUserModal.key],
                            isVisible: true,
                            title: "Invite User"
                        }
                    }))
                } else {
                    navigate(`${pagesInfo?.EDIT_DOWNLOAD?.pagePath}/${ownerUserDownloadDetail?.data?.id}/edit/${downloadParticipantsNavigationOptions?.INVITED?.to}`)
                }
            }
        }
    }, [ownerUserDownloadDetail?.data, query?.action]);

    useEffect(() => {
        if (sendDownloadInvitationDetail?.data) {
            navigate(`${pagesInfo?.EDIT_DOWNLOAD?.pagePath}/${ownerUserDownloadDetail?.data?.id}/edit/${downloadParticipantsNavigationOptions?.INVITED?.to}`)
        }
    }, [sendDownloadInvitationDetail?.data])


    useEffect(() => {
        if (ownerUserDownloadDetail?.data?.id) {
            const query = {
                page: 1,
                records: RECORDS,
                downloadable_id: ownerUserDownloadDetail?.data?.id
            }
            dispatcher(getDownloadInvitationList(query))
        }
    }, [sendDownloadInvitationDetail?.data, ownerUserDownloadDetail?.data?.id])

    const onHandleInviteUser = useCallback(() => {
        if (ownerUserDownloadDetail?.data?.status !== downloadStatusEnum?.PUBLISHED?.value) return;

        navigate(`${pagesInfo?.EDIT_DOWNLOAD?.pagePath}/${ownerUserDownloadDetail?.data?.id}/edit/${downloadParticipantsNavigationOptions?.INVITED?.to}?action=invite-participants`)

    }, [ownerUserDownloadDetail?.data])

    const onHandleChangePage = useCallback((page) => {
        const query = {
            page: page,
            records: RECORDS,
            lms_course_id: ownerUserDownloadDetail?.data?.id
        }
        dispatcher(getDownloadInvitationList(query))
    }, [ownerUserDownloadDetail?.data])

    const onHandleSelectMenu = useCallback((menuItem, downloadInvitation) => {
        switch (menuItem?.value) {
            case downloadStudentInviteMenuItemsConst?.DELETE?.value:
                dispatcher(deleteDownloadInvitationDetail(downloadInvitation?.id))
                break;
            default:
                break;
        }
    }, [downloadInvitationList?.data])

    const StudentImageContainer = memo(({ profilePicUrl }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={"relative w-10 h-10 overflow-hidden rounded-full"}>
                    <img
                        src={profilePicUrl}
                        className={"w-full h-full object-cover"}
                        alt={"profile-pic"}
                    />
                </div>
            </div>
        )
    })

    const downloadInvitationRows = useMemo(() => {
        if (!!downloadInvitationList?.data?.result?.length) {
            return downloadInvitationList?.data?.result?.map((downloadInvitation) => [
                <StudentImageContainer profilePicUrl={downloadInvitation?.user?.profile_pic_url} />,
                downloadInvitation?.user?.email ? `${downloadInvitation?.user?.email}` : "-",
                downloadInvitation?.user?.first_name ? `${downloadInvitation?.user?.first_name} ${downloadInvitation?.user?.last_name?.charAt(0)}.` : "-",
                downloadInviteStatusEnum[downloadInvitation?.status?.toUpperCase()]?.label || "-",
                dayjs(downloadInvitation?.created_at)?.tz(timeZone)?.format("ddd, MMM D, YYYY") || "-",
                <div className={"flex items-center justify-center"}>
                    <ThreeDotMenu
                        menuItems={Object.values(downloadStudentInviteMenuItemsConst)}
                        onHandleSelect={(menuItem) => onHandleSelectMenu(menuItem, downloadInvitation)}
                    />
                </div>
            ])
        } else return [];
    }, [downloadInvitationList?.data?.result])

    return (
        <div className={"flex flex-col gap-5"}>
            <div className={"w-full flex items-center justify-end gap-3"}>
                <DownloadTooltip
                    tooltip={(ownerUserDownloadDetail?.data?.status === downloadStatusEnum?.PUBLISHED?.value)
                        ? downloadTooltips?.publishedInviteBtnTooltip
                        : downloadTooltips?.disabledInviteBtnTooltip
                    }
                    children={
                        <div
                            className={cn(
                                "flex items-center justify-center gap-1 py-1 px-3 rounded-lg",
                                (ownerUserDownloadDetail?.data?.status === downloadStatusEnum?.PUBLISHED?.value)
                                    ? "border border-primary-dark text-primary-dark cursor-pointer hover:bg-primary-dark group"
                                    : "border border-divider-medium bg-text-300 text-text-400 cursor-not-allowed"
                            )}
                            onClick={onHandleInviteUser}
                        >
                            <MdAdd className={"text-lg group-hover:text-text-50"} />
                            <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                                {"Invite someone"}
                            </span>
                        </div>
                    }
                />
            </div>

            <div className={"px-5 space-y-3"}>
                {downloadInvitationList?.isLoading &&
                    <ComponentLoader isLoading={downloadInvitationList?.isLoading} />
                }
                {(!downloadInvitationList?.isLoading && downloadInvitationList?.message) &&
                    <div className={"flex items-center justify-start gap-1"}>
                        <span className={"font-bodyPri font-medium text-red-500 text-base tracking-wide"}>
                            {downloadInvitationList?.message}
                        </span>
                    </div>
                }
                {(!downloadInvitationList?.isLoading && downloadInvitationList?.data) &&
                    <DownloadTable
                        headers={Object.values(downloadStudentInvitationHeaderConst)}
                        rows={downloadInvitationRows}
                        rowHeaderColor={"bg-back-ground-medium"}
                        alternateRowColor={"bg-primary-light"}
                    />
                }
                {(downloadInvitationList?.data && (downloadInvitationList?.data?.pagination?.totalPages > 1)) &&
                    <div className={"flex justify-center items-center"}>
                        <Pagination
                            page={downloadInvitationList?.data?.pagination?.page}
                            totalPages={downloadInvitationList?.data?.pagination?.totalPages}
                            onChangePage={onHandleChangePage}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default Invited;