import { useEffect, useState } from 'react';
import { cn } from 'utils/cn.utils';
import { MdArrowDropDown } from "react-icons/md";
import { motion, Reorder } from 'framer-motion';
import image from 'assets/image/no-item-found.png'
import ToolTipView from 'components/tooltipView';
import EditSlug from 'pages/auth/profile/linkInBio/editSlug';
import { BsInfoCircle } from 'react-icons/bs';
import AddLink from 'pages/auth/profile/linkInBio/addLink';
import LinkTreeCard, { LinkTreeCardLoader } from 'pages/auth/profile/linkInBio/linkTreeCard';
import { deleteLink, getLinkTreeRequest, reorderLink, updateLinkTreeRequest } from 'redux/linkTree/linkTree.request';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { isOrderUpdated } from 'utils/generators.utils';
import { setClearUpdateLinkTree, setGetLinkTreeData } from 'redux/linkTree/linkTree.slice';

const Accordion = ({ data, getLinkTreeList }) => {
    const { getLinkTree, updateLinkTree } = useAppState((state) => state.linkTree)
    const [active, setActive] = useState(null);
    const [isLinkOrderUpdated, setIsLinkOrderUpdated] = useState(getLinkTree?.data?.result?.links || [])

    const dispatcher = useAppDispatcher();

    const toggleAccordion = (item) => {
        setActive(active?.id === item?.id ? null : item);
        dispatcher(getLinkTreeRequest({
            params: {
                linkTreeId: item?.id,
                userId: item?.user?.userId
            }
        }))
    };

    useEffect(() => {
        if (!!data) {
            data?.sort((a, b) => b.is_default - a.is_default);
            const item = data?.find(item => !!item?.is_default)
            toggleAccordion(item)
        }
    }, [data]);


    useEffect(() => {
        if (updateLinkTree?.data) {
            getLinkTreeList()
            dispatcher(setClearUpdateLinkTree())
        }
    }, [updateLinkTree?.data]);

    const handleDeleteLink = (id) => {
        dispatcher(deleteLink(id));
    };

    const handleReorder = (newOrder) => {
        if (newOrder) {
            const isOrderModified = isOrderUpdated(getLinkTree?.data?.result?.links, newOrder)
            if (!isOrderModified) {
                setIsLinkOrderUpdated(false)
                return;
            }
            setIsLinkOrderUpdated(true)
            dispatcher(setGetLinkTreeData({
                ...getLinkTree?.data, result: {
                    ...getLinkTree?.data.result, links: newOrder
                }
            }))
        }
    };

    const onHandleUpdateReorder = () => {
        if (isLinkOrderUpdated && (getLinkTree?.data?.result?.links?.length > 1)) {
            const reorderedPayload = { order: getLinkTree?.data?.result?.links.map((link) => ({ id: link.id })) };
            dispatcher(reorderLink(getLinkTree?.data?.result?.id, reorderedPayload));
            setIsLinkOrderUpdated(false)
        }
    }

    const handleChangeDefault = (linkTreeId) => {
        dispatcher(updateLinkTreeRequest(linkTreeId, {
            is_default: true,
        }))
    }

    return (
        <div className="space-y-5">
            {data?.map((item) => (
                <div key={item?.id}
                    className={cn("rounded-lg overflow-hidden",
                        active?.id === item?.id ? 'shadow' : ''
                    )}
                >
                    <div onClick={() => toggleAccordion(item)}
                        className={cn("cursor-pointer h-14 px-5 flex justify-between items-center",
                            active?.id === item?.id ? 'text-white bg-primary-main' : 'text-text-900 bg-primary-light'
                        )}
                    >
                        <h2 className="font-medium capitalize">{item?.slug}</h2>
                        <div className='flex items-center juc gap-5'>
                            <button
                                className={cn('text-sm font-medium', item?.is_default ? "" : "hover:text-secondary-main")}
                                type="button"
                                disabled={item?.is_default || updateLinkTree?.isLoading}
                                onClick={(e) => { e.stopPropagation(); handleChangeDefault(item?.id) }}>
                                {item?.is_default ? "Default Link" : "Make Default"}
                            </button>
                            <MdArrowDropDown className={cn("text-2xl", active?.id === item?.id ? 'text-white rotate-180' : 'text-text-900')} />
                        </div>
                    </div>

                    {active?.id === item?.id && (
                        <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: 'auto' }}
                            exit={{ height: 0 }}
                            transition={{ duration: 0.3 }}
                            className="bg-white">
                            <div className='px-5 py-3 space-y-5'>
                                <EditSlug linkTree={item} isShowViewAllLinks={false} />
                                <div className={"flex items-center justify-start gap-2"}>
                                    <ToolTipView content={"Add your important links here to display them on your public profile."}>
                                        <span className={"inline text-md text-text-900 hover:text-text-900 cursor-pointer"}><BsInfoCircle /></span>
                                    </ToolTipView>
                                    <h1 className='font-medium text-text-700'>
                                        {"Add Your Links"}
                                    </h1>
                                </div>
                                <div className='static lg:sticky top-10 z-10 pb-5 pt-2 bg-white'>
                                    <AddLink id={item?.id} />
                                </div>

                                {getLinkTree?.isLoading ? (
                                    <>
                                        {new Array(10).fill("").map((_, index) => (
                                            <div className='space-y-5'>
                                                <LinkTreeCardLoader />
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <Reorder.Group className='space-y-5' axis="y" values={getLinkTree?.data?.result?.links || []} onReorder={handleReorder}>
                                        {getLinkTree?.data?.result?.links.map((item) => (
                                            <Reorder.Item key={item.id} value={item} onDragEnd={onHandleUpdateReorder}>
                                                <LinkTreeCard link={item} onDelete={handleDeleteLink} />
                                            </Reorder.Item>
                                        ))}
                                    </Reorder.Group>
                                )}
                                {getLinkTree?.data?.result?.links?.length === 0 && (
                                    <div className='space-y-5 text-sm text-text-600'>
                                        <img
                                            className='h-32 aspect-video'
                                            alt='no item found'
                                            src={image}
                                        />
                                        <p>No Link Added Yet</p>
                                    </div>
                                )}

                            </div>
                        </motion.div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Accordion;
