import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { cn } from "utils/cn.utils";

import { AiOutlineClose } from "react-icons/ai";

import DescriptionInputText from 'pages/auth/edulyteLms/commonComponents/DescriptionInputText';

// import { createLmsTopicDetail } from "redux/edulyteLms/lmsTopic/lmsTopic.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";

const CreateLmsTopicModal = ({
    openModal = false,
    onCloseModal,
    onBackModal,
    maxWidth = "max-w-2xl",
    bgColor = "bg-white",
}) => {
    const { user } = useAppState((state) => state.user)
    // const { addLmsTopicDetail } = useAppState((state) => state.lms.lmsTopic)

    const dispatcher = useAppDispatcher()

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const closeModal = () => {
        onCloseModal(false)
        setTitle('')
        setDescription('')
    }

    const onHandleCreateTopic = () => {
        const body = {
            title: title,
            owner_user_id: user?.user?.userId
        }
        if (description) {
            body["description"] = description
        }
        // dispatcher(createLmsTopicDetail(body))
    }

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed max-h-screen inset-0 z-50 overflow-y-auto"
                open={openModal}
                onClose={closeModal}
            >
                <div className="p-5 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden="true" className="inline-block h-screen align-middle">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={cn(
                            "inline-block w-full shadow-xl rounded-lg space-y-5 overflow-hidden",
                            `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                        )}>
                            <Dialog.Description as="div">
                                <div className={cn(
                                    "w-full h-full overflow-hidden overflow-y-scroll",
                                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                )}>
                                    <div className="flex w-full justify-between items-center bg-back-ground-light h-16 px-5 drop-shadow-sm">
                                        <p className="font-bodyPri font-medium text-text-900 text-lg">
                                            {"Create a Topic"}
                                        </p>
                                        <AiOutlineClose onClick={closeModal} className='cursor-pointer' size={20} />
                                    </div>
                                    <div className="flex flex-col gap-5 bg-white p-10">
                                        <input
                                            className={cn(
                                                "px-3 h-12 flex w-full focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main",
                                                "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                                            )}
                                            type={"text"}
                                            placeholder={'Add Topic Title'}
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                        <DescriptionInputText
                                            descriptionText={description}
                                            onHandleChangeDescription={(newDescription) => setDescription(newDescription)}
                                        />
                                        {/* <div className={"flex items-center justify-center"}>
                                            {addLmsTopicDetail?.message &&
                                                <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                                                    {addLmsTopicDetail?.message}
                                                </span>
                                            }
                                        </div>
                                        <button className={cn(
                                            'rounded-full py-2 w-36 ease-in-out duration-100',
                                            addLmsTopicDetail?.isLoading && "bg-primary-dark",
                                            !addLmsTopicDetail?.isLoading && "bg-primary-dark hover:bg-secondary-dark text-white"
                                        )}
                                            onClick={onHandleCreateTopic}
                                        >
                                            {addLmsTopicDetail?.isLoading &&
                                                <ButtonLoader isLoading={addLmsTopicDetail?.isLoading} />
                                            }
                                            {!addLmsTopicDetail?.isLoading && "Create"}
                                        </button> */}
                                    </div>
                                </div>
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default CreateLmsTopicModal;