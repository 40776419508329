import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import React, { useState } from "react";

export default function StripeConnectedAccountPage() {
    const [accountCreatePending, setAccountCreatePending] = useState(false);
    const [accountLinkCreatePending, setAccountLinkCreatePending] = useState(false);
    const [error, setError] = useState(false);
    const [connectedAccountId, setConnectedAccountId] = useState();
    
    const api = baseAuthApiInstance();

    const createAccount = async () => {
        setAccountCreatePending(true);
        setError(false);
        try {
            const response = await api.post('/payments/org/1/connected-accounts');
            
            const { data, error } = response.data;

            if (data.result.connected_account_id) {
                setConnectedAccountId(data.result.connected_account_id);
            }

            if (error) {
                setError(true);
            }
        } catch (err) {
            console.error(err);
            setError(true);
        } finally {
            setAccountCreatePending(false);
        }
    };

    const createAccountLink = async () => {
        setAccountLinkCreatePending(true);
        setError(false);
        try {
            const response = await api.post('/payments/org/1/connected-accounts/links', {
                account: connectedAccountId,
            });
            const { data, error } = response.data;

            if (data.result.account_link) {
                window.location.href = data.result.account_link;
            }

            if (error) {
                setError(true);
            }
        } catch (err) {
            console.error(err);
            setError(true);
        } finally {
            setAccountLinkCreatePending(false);
        }
    };

    return (
        <div className="container mx-auto p-6">
            <div className="bg-blue-600 text-white text-center py-4">
                <h2 className="text-3xl font-bold">Edulyte</h2>
            </div>
            <div className="mt-8">
                {!connectedAccountId && <h2 className="text-2xl font-semibold">Get ready for take off</h2>}
                {!connectedAccountId && <p className="mt-2 text-gray-700">Edulyte is the world's leading air travel platform: join our team of pilots to help people travel faster.</p>}
                {connectedAccountId && <h2 className="text-2xl font-semibold">Add information to start accepting money</h2>}
                {connectedAccountId && <p className="mt-2 text-gray-700">Matt's Mats partners with Stripe to help you receive payments and keep your personal bank and details secure.</p>}

                {!accountCreatePending && !connectedAccountId && (
                    <button
                        className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none"
                        onClick={createAccount}
                    >
                        Create an account!
                    </button>
                )}
                
                {connectedAccountId && !accountLinkCreatePending && (
                    <button
                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
                        onClick={createAccountLink}
                    >
                        Add information
                    </button>
                )}
                
                {error && <p className="mt-4 text-red-500">Something went wrong!</p>}
                
                {(connectedAccountId || accountCreatePending || accountLinkCreatePending) && (
                    <div className="mt-4 p-4 border border-gray-300 rounded bg-gray-100">
                        {connectedAccountId && <p>Your connected account ID is: <code className="font-bold">{connectedAccountId}</code></p>}
                        {accountCreatePending && <p>Creating a connected account...</p>}
                        {accountLinkCreatePending && <p>Creating a new Account Link...</p>}
                    </div>
                )}

                <div className="mt-6 p-4 border border-gray-300 rounded bg-gray-100">
                    <p>
                        This is a sample app for Stripe-hosted Connect onboarding. <a href="https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=hosted" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">View docs</a>
                    </p>
                </div>
            </div>
        </div>
    );
}