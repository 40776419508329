import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { cn } from "utils/cn.utils";
import { motion, AnimatePresence } from "framer-motion"
import { toast } from "react-toastify";
import { TextField } from '@mui/material';
import { RotatingLines } from "react-loader-spinner";
import { FiSave } from "react-icons/fi";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import SelectMui from "../../../../../../components/Select/SelectMui";
import { getTokenCountWithSeparator, gptAiLevels } from "../lmsQuiz.data";
import AiQuizHeader from "./aiQuizHeader/AiQuizHeader";
import AiQuestionSections from "./aiQuestionSections";
import { InputSwitch, TextArea } from "pages/auth/edulyteLms/commonComponents/lmsInputSwitch/InputSwitch";

import { pagesInfo } from 'utils/pagesInfo';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { createAIlmsQuizDetail } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetLmsAiQuizDetail } from "redux/edulyteLms/lmsQuiz/lmsQuiz.slice";
import { lmsQuestionTypeEnum } from "redux/edulyteLms/lmsQuestion/lmsQuestion.const";
import { setUser } from "redux/user/user.slice";
import lmsQuizService from "redux/edulyteLms/lmsQuiz/lmsQuiz.service";
import PageLoader from "components/loader/PageLoader";

const quizTitleStyle = "text-2xl text-primary-dark font-medium w-full px-3 py-2 focus:border-primary-main bg-primary-light rounded outline-none border border-primary-light hover:border-primary-main"
const quizDescriptionStyle = "w-full px-1 py-1 text-text-800 text-base outline-none focus:border-b-primary-main border bg-transparent border-transparent hover:border-primary-main"

const QuizAIPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo);
    const { user } = useAppState((state) => state.user)
    const { lmsAiQuizDetail } = useAppState((state) => state.lms.lmsQuiz);
    const [isNotFilled, setIsNotFilled] = useState(false);
    const [aiQuestion, setAiQuestion] = useState({ isLoading: false, data: null, error: null })

    const location = useLocation()
    const navigate = useNavigate()
    const dispatcher = useAppDispatcher();

    const [addAiQuiz, setAddAiQuiz] = useState({
        isLoading: false,
        error: null,
    })
    const [aiQuiz, setAiQuiz] = useState({
        isLoading: false,
        data: null,
        error: null,
    })

    const { isLoading, data, error } = aiQuiz

    const querySearchParam = new URLSearchParams(location.search)

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.CREATE_AI_QUIZ))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (querySearchParam?.get('token')) {
            const token = querySearchParam?.get('token')
            const fetchData = async () => {
                setAiQuiz(s => ({ ...s, isLoading: true }))
                try {
                    const request = {
                        query: {
                            token: token
                        }
                    }
                    const response = await lmsQuizService.getAiLmsQuiz(request)
                    if (response.status === 200) {
                        const data = response.data
                        let jsonObject = JSON.parse(data?.ai_response)
                        jsonObject = {
                            ...jsonObject,
                            sections: jsonObject?.sections?.map((section) => ({
                                ...section,
                                questions: section?.questions?.map((question, index) => ({
                                    ...question,
                                    questionId: (index + 1),
                                    options: question?.options?.map((option, index) => ({
                                        ...option,
                                        id: index + 1
                                    }))
                                }))
                            }))
                        }
                        setAiQuiz(s => ({ ...s, data: jsonObject }))
                        dispatcher(setUser({
                            ...user?.user,
                            gpt_user_token: {
                                ...user?.user?.gpt_user_token,
                                total_tokens: data?.token_balance
                            }
                        }))
                    } else {
                        throw new Error(response)
                    }
                } catch (error) {
                    console.error(error?.response?.data?.message || error?.response?.data?.error || error)
                    setAiQuiz(s => ({ ...s, error: 'Something went wrong' }))
                    toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
                } finally {
                    setAiQuiz(s => ({ ...s, isLoading: false }))
                }
            }

            fetchData()
        }
    }, [location.search])

    useEffect(() => {
        if (lmsAiQuizDetail?.data) {
            let lmsAiQuizId = lmsAiQuizDetail?.data?.id
            dispatcher(resetLmsAiQuizDetail())
            navigate(`${pagesInfo.EDIT_LMS_QUIZ.pagePath}/${lmsAiQuizId}/edit`)
        }
    }, [lmsAiQuizDetail?.data])

    const [requestData, setRequestData] = useState({
        topic: '',
        question_type: lmsQuestionTypeEnum?.SINGLE_CHOICE?.value,
        no_of_question: 5,
        student_level: "intermediate",
    })

    const isBlankField = useMemo(() => !aiQuiz?.data?.sections.every(section => (
        !!section?.section_title &&
        !!section?.section_description &&
        section?.questions.every(question => (
            !!question?.title &&
            !!question?.feedback &&
            question?.options?.every(option => !!option?.title) &&
            question?.options?.some(option => option?.is_correct)
        ))
    )), [aiQuiz]);

    const handleCreateQuiz = () => {
        if (isBlankField) {
            toast.error("Please fill all required fields");
            setIsNotFilled(true)
            return;
        }
        if (addAiQuiz?.isLoading || lmsAiQuizDetail?.isLoading || isBlankField || !aiQuiz?.data?.sections[0].questions.length) return;
        const requestBody = {
            quiz: data
        }
        dispatcher(createAIlmsQuizDetail(requestBody))
    }

    const handleGenerateResponse = async () => {
        if (!requestData?.topic) {
            toast.warn("Please enter topic!")
            return;
        }
        if (!requestData?.no_of_question) {
            toast.warn("Please enter no of question!")
            return;
        }
        if (querySearchParam?.get('token') && data) {
            const userConfirmed = window.confirm("A quiz has already been generated. Continuing will discard the previous data. Would you like to proceed with regeneration?");
            if (!userConfirmed) {
                return;
            }
        }

        setAddAiQuiz(s => ({ ...s, isLoading: true }))

        try {
            const requestBody = {
                body: JSON.stringify(requestData)
            }
            const response = await lmsQuizService.createAiLmsQuiz(requestBody)
            if (response.status === 201) {
                setAddAiQuiz({
                    isLoading: false,
                    data: null,
                    error: null,
                })
                navigate(`${location.pathname}?token=${response?.data?.token}`)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error)
            setAddAiQuiz(s => ({ ...s, error: 'Something went wrong' }))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        } finally {
            setAddAiQuiz(s => ({ ...s, isLoading: false }))
        }
    };

    const handleOnchange = (e) => setRequestData({ ...requestData, topic: e.target.value })

    const characterCount = requestData.topic.length;
    const maxCharacterLimit = 300;

    const updateQuiz = (field, value) => {
        setAiQuiz(s => ({ ...s, data: { ...s.data, [field]: value } }));
    };

    const updateSection = (sectionIndex, field, value) => {
        let sections = data.sections.map((sec, index) => (index === sectionIndex) ? { ...sec, [field]: value } : sec)
        setAiQuiz(s => ({ ...s, data: { ...s.data, sections: sections } }));
    };

    if (aiQuiz?.isLoading) {
        return (
            <PageLoader />
        )
    }

    return (
        <AnimatePresence>
            <AiQuizHeader onClick={handleCreateQuiz} isShowBtn={!!data === true} disable={addAiQuiz?.isLoading || aiQuestion?.isLoading}>
                {lmsAiQuizDetail?.isLoading ? (
                    <RotatingLines
                        visible={lmsAiQuizDetail?.isLoading}
                        height="20"
                        width="20"
                        strokeWidth="5"
                        animationDuration="0.75"
                    />
                ) : (
                    <FiSave size={16} className="-mt-0.5" />
                )}
                {lmsAiQuizDetail?.isLoading ? 'Saving...' : 'Save & Next'}
            </AiQuizHeader>
            <div className={cn("flex-1 bg-gradient-to-r from-primary-light via-back-ground-lightBlue to-primary-light")}>
                <div
                    className={cn(
                        "flex flex-col items-center w-full mx-auto max-w-[1200px] gap-3 lg:gap-5 p-3 lg:p-5 font-bodyPri",
                        !!querySearchParam?.get('token') ? "lg:flex-row items-start relative" : ""
                    )}>
                    {!!querySearchParam?.get('token') ? (
                        null
                    ) : (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="flex flex-col justify-center items-center pt-5 md:pt-10 pb-5 gap-3 text-primary-dark text-center">
                            <h1 className="font-black md:text-6xl text-5xl drop-shadow-lg"><span className="text-secondary-dark">AI</span> Quiz Builder</h1>
                            <p className="text-sm md:text-base text-text-900">Type Your Quiz Topic to Begin Instantly</p>
                            <p className="text-sm text-text-500">{`AI Tokens Available ${getTokenCountWithSeparator(user?.user?.gpt_user_token?.total_tokens)}`}</p>
                        </motion.div>
                    )}
                    <motion.div
                        initial={!!querySearchParam?.get('token') ? { y: 0, x: -100, opacity: 0 } : { y: 100, x: 0, opacity: 0 }}
                        animate={!!querySearchParam?.get('token') ? { y: 0, x: 0, opacity: 1 } : { y: 0, x: 0, opacity: 1 }}
                        transition={{ duration: 0.4 }}
                        className={cn(
                            "flex",
                            !!querySearchParam?.get('token') ? "lg:sticky lg:top-[85px] lg:w-1/3 w-full" : "md:w-[40rem] w-full"
                        )}>
                        <div className="w-full">
                            <div className="flex flex-col w-full bg-white rounded-lg gap-5 p-5 shadow">
                                <div className="flex flex-col gap-2">
                                    <div className="flex justify-end text-text-800 text-sm">
                                        <p className={characterCount >= maxCharacterLimit ? 'text-secondary-dark' : ''}>
                                            {`${characterCount} / ${maxCharacterLimit}`}
                                        </p>
                                    </div>
                                    <TextField
                                        id="outlined-basic" label="Topic" variant="outlined"
                                        placeholder={"AI prompt to generate quiz"}
                                        value={requestData.topic}
                                        onChange={handleOnchange}
                                        required={true}
                                    />
                                </div>
                                <SelectMui
                                    label={"Type of Question"}
                                    defaultValue={requestData.question_type}
                                    options={Object.values(lmsQuestionTypeEnum)?.filter((questionType) => [lmsQuestionTypeEnum.SINGLE_CHOICE.value, lmsQuestionTypeEnum.MULTIPLE_CHOICE.value]?.includes(questionType.value))}
                                    onChange={(e) => setRequestData({ ...requestData, question_type: e.target.value })}
                                />
                                <TextField
                                    id={"outlined-basic"}
                                    label={"No of questions"}
                                    variant={"outlined"}
                                    type={"number"}
                                    value={requestData?.no_of_question}
                                    onChange={(e) => setRequestData({ ...requestData, no_of_question: (e.target.value < 0) ? 1 : e.target.value > 5 ? 5 : e.target.value })}
                                />
                                <SelectMui
                                    label={"Level"}
                                    options={gptAiLevels}
                                    defaultValue={requestData.student_level}
                                    onChange={(e) => setRequestData({ ...requestData, student_level: e.target.value })}
                                />
                                <div className={"w-full flex flex-col items-center justify-center gap-2"}>
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        transition={{ duration: 0.3 }}
                                        disabled={aiQuestion?.isLoading || addAiQuiz?.isLoading || lmsAiQuizDetail?.isLoading}
                                        className={cn(
                                            "flex items-center justify-center gap-3 py-2 px-5 text-white rounded ease-in-out duration-200 bg-primary-dark hover:bg-blue-900 border border-primary-dark",
                                            !!querySearchParam?.get('token') ? "w-full" : "w-fit self-center",
                                            addAiQuiz?.isLoading && "bg-white text-green-500 border-green-500 hover:bg-white",
                                            lmsAiQuizDetail?.isLoading || aiQuestion?.isLoading ? "text-text-300 bg-white border-text-300 hover:bg-white cursor-wait" : ""
                                        )}
                                        onClick={handleGenerateResponse}
                                    >
                                        {addAiQuiz?.isLoading ? (
                                            <RotatingLines
                                                visible={addAiQuiz?.isLoading}
                                                height="20"
                                                width="20"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                            />
                                        ) : (
                                            <AutoFixHighIcon style={{ fontSize: 18 }} className={cn('animate-pulse text-yellow-200',
                                                addAiQuiz?.isLoading || lmsAiQuizDetail?.isLoading ? 'text-text-300' : ' ',
                                            )} />

                                        )}
                                        {addAiQuiz?.isLoading ? 'Generating quiz...' : 'Generate Quiz'}
                                    </motion.button>
                                    {!!querySearchParam?.get('token') && !!data &&
                                        <p className="text-xs text-text-600">{`AI Tokens Available ${getTokenCountWithSeparator(user?.user?.gpt_user_token?.total_tokens)}`}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    {!!querySearchParam?.get('token') && (
                        <motion.div
                            initial={{ x: 100, opacity: 0 }}
                            animate={data ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="flex w-full">
                            <div className="w-full">
                                <div className="flex flex-col w-full bg-white rounded-lg gap-3 p-5 shadow">
                                    <InputSwitch
                                        inputClassName={quizTitleStyle}
                                        textClassName={quizTitleStyle}
                                        placeholder="Quiz Title"
                                        value={data?.quiz_title}
                                        text={<h3>{data?.quiz_title}</h3>}
                                        onChange={(e) => updateQuiz('quiz_title', e.target.value)}
                                    />
                                    <TextArea
                                        inputClassName={quizDescriptionStyle}
                                        textClassName={quizDescriptionStyle}
                                        placeholder="Quiz Description"
                                        value={data?.quiz_description}
                                        text={<h3>{data?.quiz_description}</h3>}
                                        onChange={(e) => updateQuiz('quiz_description', e.target.value)}
                                    />

                                    <AiQuestionSections
                                        data={data}
                                        aiQuiz={aiQuiz}
                                        setAiQuiz={setAiQuiz}
                                        lmsAiQuizDetail={lmsAiQuizDetail}
                                        updateSection={updateSection}
                                        handleCreateQuiz={handleCreateQuiz}
                                        isNotFilled={isNotFilled}
                                        isBlankField={isBlankField}
                                        addAiQuiz={addAiQuiz}
                                        aiQuestion={aiQuestion}
                                        setAiQuestion={setAiQuestion}
                                    />
                                </div>
                            </div>
                        </motion.div>
                    )}
                </div>
            </div>
        </AnimatePresence>
    );
};

export default QuizAIPage;


