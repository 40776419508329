import { contactLabelIcons, sortDefaultContact } from "components/modals/crmModals/data";

const ContactAddresses = ({ contact }) => {

    return !!contact?.contact_addresses?.length && (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-5 font-bodyPri">
            {contact?.contact_addresses?.slice(0)?.sort((item1, item2) => sortDefaultContact(item1?.id, item2?.id, contact?.address?.id))?.map((address, index) => (
                <div key={index} className="w-full flex gap-5 p-5 bg-back-ground-lightBlue">
                    <div className="flex gap-3">
                        {(() => {
                            const Icon = contactLabelIcons[address?.label].icon;
                            return <Icon className="mt-0.5 text-text-700 text-lg" />;
                        })()}
                        <div className="space-y-0.5 text-sm">
                            <p>{`${address?.street_line_1} ${(address?.id === contact?.address?.id) ? "(Default)" : ""}`}</p>
                            <p>{address?.street_line_2}</p>
                            <p>{address?.city}</p>
                            <p>
                                <span>{address?.state}</span> &#x2022;
                                <span>{` ${address?.country} ${address?.zip && " - " + address?.zip}`}</span>
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ContactAddresses;