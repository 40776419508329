import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import format from "string-format"

import { MdIosShare, MdVerified } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import ToolTipView from "components/tooltipView";
import Icon from "components/Icon";
import RatingStars from "components/ratingStars/RatingStars";

import { createFavTutorUserDetailByUserId, deleteFavTutorUserDetailByUserId } from 'redux/tutor/tutor.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal, setShareSocial } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { blueTickEnum } from "redux/user/user.const";
import { userWsUrl } from "redux/user/user.const";

import { pagesInfo } from "utils/pagesInfo";
import { cn } from "utils/cn.utils";

import { edulyteWebSocket } from "websocket/edulyte.websocket";

const Profile = ({ tutor }) => {
  const { session } = useAppState(s => s.auth)
  const { user } = useAppState((s) => s.user)
  const { addFavTutor } = useAppState((state) => state.tutor)
  const { modal, shareSocial } = useAppState((s) => s.local)

  const dispatcher = useAppDispatcher()
  const { userId } = useParams()

  const [userDetail, setUserDetail] = useState(null)

  useEffect(() => {
    let publicUserDetailWebSocket = null

    const getPublicUserDetail = async () => {
      const wsConfig = { url: format(userWsUrl.PUBLIC_USER_DETAIL, { username: tutor?.username }) }
      publicUserDetailWebSocket = await edulyteWebSocket(wsConfig)

      publicUserDetailWebSocket.onmessage = async (event) => {
        setUserDetail(JSON.parse(event?.data))
      }
    }

    if (tutor?.username) {
      getPublicUserDetail()
    }

    return () => {
      if (publicUserDetailWebSocket?.readyState === WebSocket.OPEN) {
        publicUserDetailWebSocket?.close()
      }
    }
  }, [tutor?.username])

  const TUTOR_PROFILE_URL = `${window.location.host + pagesInfo?.TUTOR?.pagePath}/${userId}`

  const filteredFavorite = user?.user?.favoriteTutors?.filter((element) => element?.tutorId === tutor?.tutorId)?.length > 0

  const NEW_TUTOR_PUBLIC_PROFILE_RATING = 2;

  const handleFavTutor = () => {
    if (addFavTutor?.isLoading) return;

    if (filteredFavorite) {
      dispatcher(deleteFavTutorUserDetailByUserId(Number(userId), { tutorId: tutor?.tutorId }))
    }
    else {
      dispatcher(createFavTutorUserDetailByUserId(Number(userId), { tutorId: tutor?.tutorId }))
    }
  }

  const handleShareProfile = () => {
    dispatcher(setShareSocial({
      ...shareSocial,
      socialAction: "",
      url: TUTOR_PROFILE_URL
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
    }))
  }

  return (
    <div className={"w-full p-2 md:p-6"}>
      <div className={"grid grid-cols-12 sm:place-items-start gap-5 py-1 md:py-3"}>
        <div className={"col-start-1 col-span-12 sm:col-start-1 sm:col-span-4 flex flex-col items-center justify-center gap-3"}>
          <div className={"relative w-40 h-40 rounded-full sm:w-36 sm:h-36"}>
            <img
              src={tutor?.profilePicUrl}
              alt={"profileImg"}
              className={"w-full h-full rounded-full p-2 object-cover"}
            />
            <div className={"relative has-tooltip"}>
              <div className={'absolute -top-9 bottom-5 right-5 w-6 h-6 rounded-full overflow-hidden border-2 border-white'}>
                <img
                  src={`https://flagcdn.com/16x12/${tutor?.fromCountry?.countryDomain}.png`.toLowerCase()}
                  alt={"country-flag"}
                  className='w-full h-full object-cover'
                />
              </div>
              <span
                className={cn(
                  "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-2 -right-0",
                  "flex flex-col items-center justify-start overflow-hidden"
                )}
              >
                {tutor?.fromCountry?.country}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center gap-1">
            {tutor?.rating?.avgRating > NEW_TUTOR_PUBLIC_PROFILE_RATING && (
              <>
                <RatingStars
                  rating={tutor.rating?.avgRating}
                  fullRating={5}
                />
                <div className="font-normal text-xs text-accent-main">
                  {tutor?.rating?.avgRating?.toFixed(1)} ({tutor?.rating?.totalStars?.count})
                </div>
              </>
            )}
          </div>
        </div>
        <div className="w-full col-start-1 col-span-12 sm:col-start-5 sm:col-span-8 flex flex-col space-y-5 p-2">
          <div className="flex flex-col gap-2">
            <div className="w-full flex justify-between items-center">
              <div className={"flex gap-3 text-2xl font-bodyPri tracking-wide"}>
                <div className={"flex items-center gap-2 capitalize"}>
                  {tutor?.firstName} {tutor?.lastName?.charAt(0) + "."}
                  {/* {(tutor?.onlineStatus === "online") */}
                  {(userDetail?.online_status === "online") &&
                    <div className={"w-3 h-3 rounded-full bg-green-600"}></div>
                  }
                  {(tutor?.blueTick === blueTickEnum?.YES?.value) &&
                    <div className='relative has-tooltip cursor-pointer'>
                      <MdVerified className={"text-lg text-primary-dark"} />
                      <span className='tooltip w-fit px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap -translate-x-[50%] -translate-y-[150%] md:-translate-y-[190%]'>
                        {"Verified Account"}
                      </span>
                    </div>
                  }
                </div>
                {tutor?.instantBooking &&
                  <div className="">
                    <Icon type="light" />
                  </div>
                }
              </div>

              <div className={"flex items-center justify-start gap-3"}>
                {addFavTutor?.isLoading &&
                  <FaSpinner className={"animate-spin text-lg text-primary-dark"} />
                }
                {(!addFavTutor?.isLoading && session?.isLoggedIn) &&
                  <ToolTipView content={filteredFavorite ? "Favorite" : "Make Favorite"}>
                    <div className={"cursor-pointer"} onClick={handleFavTutor}>
                      {filteredFavorite
                        ? <FavoriteIcon className={'text-red-500'} style={{ fontSize: "1.8rem" }} />
                        : <FavoriteBorderIcon className={'text-text-500'} style={{ fontSize: "1.8rem" }} />
                      }
                    </div>
                  </ToolTipView>
                }
                <ToolTipView content={"Share"}>
                  <div className={""}>
                    <MdIosShare className={"text-text-500 text-2xl cursor-pointer"} onClick={handleShareProfile} />
                  </div>
                </ToolTipView>
              </div>
            </div>
            <div className="font-bodyPri font-normal text-lg">
              {tutor?.elevatorPitch}
            </div>
            <div className='h-1 w-5 bg-secondary-main rounded-lg'></div>
          </div>

          <div className='flex flex-wrap gap-2 items-center'>
            {tutor?.badges?.map((badge, index) => (
              <div
                key={index}
                className='relative has-tooltip w-8 h-8 rounded-full border border-text-500 p-0.5 cursor-pointer'
              >
                <img
                  src={badge?.badge?.badgePicUrl}
                  alt={badge?.badge?.tooltip}
                  className={"w-full h-full object-cover"}
                />
                <span className='tooltip top-8 -left-5 w-fit px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap'>
                  {badge?.badge?.tooltip}
                </span>
              </div>
            ))}
          </div>

          <div className={"flex flex-col items-start justify-start gap-3"}>
            <div className={"flex text-base font-bodyPri tracking-wide gap-2"}>
              <Icon type={"comment"} />
              <span className={"text-text-600"}>
                {"Speaks:"}
              </span>
              {tutor?.languages.slice(0, 2).map((language, index) => (
                <span
                  key={index}
                  className={"flex justify-center items-center gap-1 text-medium font-medium"}
                >
                  {index !== 0 &&
                    <p>{"|"}</p>
                  }
                  {language?.language}
                </span>
              ))}
            </div>
            <div className={"flex text-base font-bodyPri tracking-wide gap-2 overflow-hidden"}>
              <Icon type={"teach"} />
              <span className={"text-text-600"}>
                {"Teaches:"}
              </span>
              <span className={"text-medium font-medium"}>
                {tutor?.categories.slice(0, 2).join(" | ")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Profile;
