import storageService from "redux/storage/storage.service";
import {
    setUploadFileLoading,
    setUploadFileData,
    setUploadFileMessage
} from "redux/storage/storage.slice";
import { generateFormData } from "./storage.utils";

export const uploadFileToS3 = (fileDetail, fileOwnerId) => async (dispatch) => {
    dispatch(setUploadFileLoading(true));

    try {
        const formData = await generateFormData({ files: fileDetail, user_id: fileOwnerId })

        const requestData = {
            body: formData
        }
        const response = await storageService.uploadFileToS3(requestData)
        if (response.status === 200) {
            dispatch(setUploadFileData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUploadFileMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setUploadFileLoading(false));
    }
}