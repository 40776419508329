import { useState } from 'react';
import { Toggle } from 'components/common-components/Toggle';
import { motion } from 'framer-motion';
import { cn } from 'utils/cn.utils';
import { toast } from 'react-toastify';
import ProductsCertificateAutoSettings from 'pages/auth/certificate/productsCertificateAutoSettings';

import { updateLmsQuizDetail } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';
import { useAppDispatcher, useAppState } from 'hooks/useStore';

const QuizCertificate = () => {
    const { lmsQuizDetail, updateLmsQuiz } = useAppState((s) => s.lms.lmsQuiz)
    const { user } = useAppState((state) => state.user)
    const [show, setShow] = useState(lmsQuizDetail?.data?.quiz_setting?.auto_generate_certificate)
    const [selectedTemplate, setSelectedTemplate] = useState(lmsQuizDetail?.data?.quiz_setting?.certificate_template)

    const dispatcher = useAppDispatcher()

    const handleToggle = () => {
        if (show) {
            setShow(false);
            const body = {
                auto_generate_certificate: false,
            };
            dispatcher(updateLmsQuizDetail(lmsQuizDetail?.data?.id, body));
        } else {
            setShow(true);
        }
    };

    const handleSave = (template) => {
        if (user?.user?.userConfig?.logo_url?.length === 0 || user?.user?.userConfig?.sign_url?.length === 0) {
            toast.warn("Please Upload Sign and Company Logo to continue")
        } else {
            const body = {
                auto_generate_certificate: true,
                template_id: template?.id
            }
            dispatcher(updateLmsQuizDetail(lmsQuizDetail?.data?.id, body))
        }
    }

    return (
        <div className='font-bodyPri space-y-8 p-3'>
            <div className='space-y-1'>
                <h3 className='font-bold text-text-900 text-lg tracking-wide'>Auto Certificate Settings</h3>
                <p className='text-sm text-text-600'>Select a template and configure settings to automatically issue certificates upon quiz completion.</p>
            </div>
            <div className='flex items-center gap-5'>
                <p>Auto Issue Certificate</p>
                <Toggle value={show} onChange={handleToggle} />
            </div>
            {show &&
                <ProductsCertificateAutoSettings
                    handleSave={handleSave}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                />
            }
            <div className='flex justify-end'>
                <motion.button
                    whileHover={{ scale: 1.09 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleSave(selectedTemplate)}
                    disabled={!selectedTemplate?.id}
                    className={cn('px-5 py-2 rounded-full ease-in-out duration-200 text-white',
                        lmsQuizDetail?.data?.quiz_setting?.certificate_template?.id === selectedTemplate?.id ? "bg-back-ground-darkLight" : "bg-primary-dark hover:bg-secondary-dark",
                    )}
                >
                    {updateLmsQuiz?.isLoading ? "Saving..." : "Save"}
                </motion.button>
            </div>
        </div>
    );
};

export default QuizCertificate;
