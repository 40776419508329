import tw, { styled } from 'twin.macro';
import { Star, StarHalf, StarBorder } from '@mui/icons-material'

export const StarsContainer = tw.span`
    flex flex-row gap-[0.5] px-1 py-0.5
`;

export const FullStar = styled(Star)`
    font-size: ${({ size }) => size}rem;
    ${tw`
        text-[#fb923c]
    `}
`;

export const HalfStar = styled(StarHalf)`
    font-size: ${({ size }) => size}rem;
    ${tw`
        text-[#fb923c]
    `}
`;

export const EmptyStar = styled(StarBorder)`
    font-size: ${({ size }) => size}rem;
    ${tw`
        text-[#fb923c]
    `}
`;