import { useCallback } from 'react';
import { Link } from 'react-router-dom';

// import { cn } from "utils/cn.utils";

// import { MdAdd } from 'react-icons/md';

import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import LMSTableComponent from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTableComponent';
import { articleInstructorHeaderConst, articleInstructorMenuItemConst } from '../../data';

import { deleteLmsArticleInstructorDetail, updateLmsArticle, updateLmsArticleInstructorDetail } from 'redux/edulyteLms/lmsArticle/lmsArticle.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import {
    resetAddLmsArticleInstructorDetail,
    resetAddLmsArticleInstructorDetailPayload,
    resetModifyLmsArticleInstructorDetail
} from 'redux/edulyteLms/lmsArticle/lmsArticle.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsArticleInstructorPermissionEnum } from 'redux/edulyteLms/lmsArticle/lmsArticle.const';

import { pagesInfo } from 'utils/pagesInfo';

const ArticleInstructor = () => {
    const { modal } = useAppState((state) => state.local)
    const { lmsArticleDetail } = useAppState((state) => state.lms.lmsArticle)

    const dispatcher = useAppDispatcher()

    const onHandleAddInstructor = useCallback(() => {
        dispatcher(resetAddLmsArticleInstructorDetail())
        dispatcher(resetModifyLmsArticleInstructorDetail())
        dispatcher(resetAddLmsArticleInstructorDetailPayload())
        dispatcher(setModal({
            ...modal,
            [modalConst.addLmsArticleInstructorModal.key]: {
                ...modal[modalConst.addLmsArticleInstructorModal.key],
                isVisible: true,
                title: "Add Instructor"
            }
        }))
    }, [modal])

    const sortArticleInstructor = (instructor1, instructor2) => {
        if (instructor1?.id === lmsArticleDetail?.data?.article_setting?.default_owner?.id) {
            return -1;
        } else if (instructor2?.id === lmsArticleDetail?.data?.article_setting?.default_owner?.id) {
            return 1;
        } else {
            return instructor1 - instructor2;
        }
    }

    const onHandleSelectMenuItem = (menuItem, articleInstructorItem) => {
        switch (menuItem.value) {
            case articleInstructorMenuItemConst?.MAKE_DEFAULT?.value:
                dispatcher(updateLmsArticle(lmsArticleDetail?.data?.article_id, { owner_user_id: articleInstructorItem?.id }))
                return;
            case articleInstructorMenuItemConst?.VIEWER?.value:
                if (articleInstructorMenuItemConst?.VIEWER?.value === articleInstructorItem?.permission) return;
                dispatcher(updateLmsArticleInstructorDetail(articleInstructorItem?.id, { user_id: articleInstructorItem?.user?.userId, permission: articleInstructorMenuItemConst?.VIEWER?.value }))
                break;
            case articleInstructorMenuItemConst?.EDITOR?.value:
                if (articleInstructorMenuItemConst?.EDITOR?.value === articleInstructorItem?.permission) return;
                dispatcher(updateLmsArticleInstructorDetail(articleInstructorItem?.id, { user_id: articleInstructorItem?.user?.userId, permission: articleInstructorMenuItemConst?.EDITOR?.value }))
                break;
            case articleInstructorMenuItemConst?.OWNER?.value:
                if (articleInstructorMenuItemConst?.OWNER?.value === articleInstructorItem?.permission) return;
                dispatcher(updateLmsArticleInstructorDetail(articleInstructorItem?.id, { user_id: articleInstructorItem?.user?.userId, permission: articleInstructorMenuItemConst?.OWNER?.value }))
                break;
            case articleInstructorMenuItemConst?.DELETE?.value:
                dispatcher(deleteLmsArticleInstructorDetail(articleInstructorItem?.id))
                break;

            default:
                break;
        }
    }

    const ArticleTutorProfileImageContainer = ({ articleInstructor }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={'relative w-12 h-12 rounded-full'}>
                    <Link to={`${pagesInfo.TUTOR.pagePath}/${articleInstructor?.user?.userId}`}>
                        <img
                            src={articleInstructor?.user?.profile_pic_url}
                            alt={"profile-pic"}
                            className={"w-full h-full rounded-full object-cover"}
                        />
                    </Link>
                </div>
            </div>
        )
    }

    const ArticleTutorNameContainer = ({ articleInstructor }) => {
        return (
            <div className={'flex items-center justify-center gap-1'}>
                <a
                    href={`${window.location.host}${pagesInfo.TUTOR.pagePath}/${articleInstructor?.user?.userId}`}
                    target={"_blank"}
                    className={"w-fit"}
                >
                    <span className={'font-bodyPri font-normal text-base tracking-wide truncate capitalize'}>
                        {articleInstructor?.user?.first_name} {articleInstructor?.user?.last_name.charAt(0) + "."}
                    </span>
                </a>
                {(lmsArticleDetail?.data?.article_setting?.default_owner?.id === articleInstructor?.article_user_id) &&
                    <span className={"font-bodyPri font-medium text-green-500 text-sm"}>
                        {"(Primary)"}
                    </span>
                }
            </div>
        )
    }

    const articleInstructorRows = lmsArticleDetail?.data?.article_users && lmsArticleDetail?.data?.article_users?.slice(0)?.sort(sortArticleInstructor)?.map((articleInstructor) => [
        <ArticleTutorProfileImageContainer articleInstructor={articleInstructor} />,
        <ArticleTutorNameContainer articleInstructor={articleInstructor} />,
        lmsArticleInstructorPermissionEnum[articleInstructor?.permission?.toUpperCase()]?.label,
        (lmsArticleDetail?.data?.article_setting?.default_owner?.id != articleInstructor?.id)
            ? <div className={"w-full flex items-center justify-center"}>
                <ThreeDotMenu
                    menuItems={Object.values(articleInstructorMenuItemConst)}
                    onHandleSelect={(menuItem) => onHandleSelectMenuItem(menuItem, articleInstructor)}
                />
            </div>
            : "-"
    ])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full flex items-center justify-between gap-3"}>
                <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                    {"Instructor(s)"}
                </span>
                {/* <div
                    className={cn(
                        "flex items-center justify-center gap-1 py-1 px-3",
                        "border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"
                    )}
                    onClick={onHandleAddInstructor}
                >
                    <MdAdd className={"text-lg group-hover:text-text-50"} />
                    <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                        {"Add Instructor"}
                    </span>
                </div> */}
            </div>

            <div className={"w-full px-5"}>
                <LMSTableComponent
                    headers={Object.values(articleInstructorHeaderConst)}
                    subRowHeader={[]}
                    rows={(lmsArticleDetail?.data?.article_users?.length > 0) ? articleInstructorRows : []}
                    rowHeaderColor={"bg-back-ground-medium"}
                    alternateRowColor={"bg-primary-light"}
                />
            </div>
        </div>
    )
}

export default ArticleInstructor;