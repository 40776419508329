import { memo, useState, useRef } from 'react';

import { cn } from "utils/cn.utils";

import { BsChevronDown, BsSearch } from 'react-icons/bs';

import ToolTipView from 'components/tooltipView';

const SideBarExpendable = memo(({
  className = "",
  disabledClassName = "",
  category,
  placeholder,
  isExpended,
  isSearchable = true,
  isDisabled = false,
  contentTooltip,
  children,
  onChange,
  expendHeight
}) => {

  const [isOpen, setIsOpen] = useState(isDisabled ? false : isExpended);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const inputRef = useRef(null);

  const handleToggle = () => {
    if (isDisabled) return;

    setIsOpen(!isOpen);
    setIsSearchOpen(false)
  };

  const handleSearchToggle = () => {
    if (!isSearchable || isDisabled) return;

    setIsSearchOpen(!isSearchOpen);
    setIsOpen(true)
  };

  return (
    <ToolTipView content={contentTooltip} disabled={!isDisabled}>
      <div className={cn('flex w-full flex-col font-bodyPri', className, isDisabled && disabledClassName)}>
        <div className={cn(
          'flex w-full border items-center justify-between px-4 h-10 drop-shadow-sm',
          isDisabled
            ? `border border-back-ground-darkLight bg-back-ground-darkLight rounded-full ${disabledClassName}`
            : isOpen
              ? 'border-back-ground-lightDark rounded-t-lg border-b-back-ground-light bg-back-ground-light'
              : 'border-back-ground-lightDark rounded-full hover:bg-back-ground-lightBlue'
        )}>
          <div className={"flex justify-between w-full items-center gap-2"}>
            <div
              className={cn(
                isDisabled ? "text-text-500" : 'text-black cursor-pointer'
              )}
              onClick={handleSearchToggle}
            >
              <BsSearch size={15} className={""} />
            </div>
            {isSearchOpen ? (
              <input
                ref={inputRef}
                className={'w-full px-2 h-8 rounded outline-none border'}
                placeholder={placeholder}
                onChange={onChange}
              />
            ) : (
              <p className={cn(
                isDisabled ? "text-text-500" : 'text-back-ground-black'
              )}>
                {category}
              </p>
            )}
            <div
              className={cn(
                'text-black cursor-pointer ease-in-out duration-300 hidden md:flex',
                isOpen && "rotate-180 ease-in-out duration-300",
                isDisabled && "!text-text-500"
              )}
              onClick={handleToggle}
            >
              <BsChevronDown size={15} />
            </div>
          </div>
        </div>
        {isOpen && (
          <div className={cn(
            "border border-back-ground-lightDark border-t-white rounded-b-lg p-3 overflow-hidden overflow-y-scroll transition ease-in-out delay-150 duration-300",
            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-light",
          )}
            style={{ maxHeight: expendHeight }}>
            {children}
          </div>
        )}
      </div>
    </ToolTipView>
  );
});

export default SideBarExpendable;