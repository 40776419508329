import { useEffect } from 'react';

import { BsFillCameraFill } from 'react-icons/bs';
import { FaInfoCircle, FaSpinner } from 'react-icons/fa';
import { BiUserCircle } from "react-icons/bi";

import ToolTipView from 'components/tooltipView';

import { profileEditBtn } from 'pages/auth/profile/data';
import image from 'pages/auth/profile/image/user-profile-cover.png'

import { updateUserDetailByUserId } from 'redux/user/user.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { setClearProfilePageDocumentPayload, setProfilePageDocumentPayload } from 'redux/tutor/tutor.slice';
import { resetUploadFile, setUploadFilePayload } from 'redux/storage/storage.slice';
import { modalConst } from 'redux/local/local.const';
import { fileCategories } from 'redux/storage/storage.const';

import { cn } from 'utils/cn.utils';
import { getCustomHostPath } from 'utils/generators.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const NameDetails = () => {
  const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
  const { user, userDetail, addUserDetail } = useAppState((s) => s.user)
  const { profilePageDocumentUpload } = useAppState((state) => state.tutor)
  const { modal } = useAppState((s) => s.local)
  const { uploadFile } = useAppState((state) => state.storage)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    if ((uploadFile?.data?.length > 0) && profilePageDocumentUpload?.profilePageDocumentUploadPayload?.isProfilePictureUploadEnabled) {
      onSubmit(profileEditBtn.profilePicUrl.value, uploadFile?.data[0]?.fileUrl)
      dispatcher(setClearProfilePageDocumentPayload())
    }

    return () => {
      dispatcher(resetUploadFile())
    }
  }, [uploadFile?.data])

  const handleOnSelectImage = () => {
    dispatcher(resetUploadFile())
    dispatcher(setProfilePageDocumentPayload({
      isProfilePictureUploadEnabled: true,
      isVideoUploadEnabled: false
    }))
    dispatcher(setUploadFilePayload({
      ...uploadFile?.payload,
      fileCategory: fileCategories.profilePicture.value
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.IMAGE_PICKER_MODAL.stateKey]: true
    }))
  }

  const onSubmit = (key, value) => {
    dispatcher(updateUserDetailByUserId(user?.user?.userId, { [key]: value }))
  }

  return (
    <>
      <section
        className="h-40 bg-cover bg-bottom-center bg-no-repeat bg-primary-light bg-blend-overlay bg-opacity-90 p-5"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className='flex items-center gap-2'>
          <h2 className="font-subHeading font-medium text-base md:text-2xl text-text--800 drop-shadow-lg text-primary-dark">
            {"My Profile"}
          </h2>
          <ToolTipView content='Inputs for your public profile. Click on View Profile button for public view.'>
            <div><FaInfoCircle className="text-md text-blue-400 hover:text-primary-dark cursor-pointer" /></div>
          </ToolTipView>
        </div>

      </section>
      <div className='flex justify-between px-5 md:px-10'>
        <div className={"flex flex-col gap-3 -mt-20 relative"}>
          <div className='flex flex-col items-center self-start gap-5'>
            <div className="relative w-32 h-32 xl:w-36 xl:h-36 rounded-full">
              <img
                src={userDetail?.userDetail?.profilePicUrl}
                alt={"my-profile"}
                className={"w-full h-32 xl:h-36 rounded-full object-cover border"}
              />
              <div className="text-xl bg-white hover:text-secondary-dark p-1 bg-opacity-50 text-text-800 absolute right-3 bottom-2 cursor-pointer rounded-full">
                <BsFillCameraFill onClick={handleOnSelectImage} />
              </div>
            </div>
            <div className='flex items-center gap-3'>
              <ToolTipView content={"View User Profile"}>
                <a
                  className={"text-xl text-text-900 font-medium font-bodyPri tracking-wide capitalize hover:text-secondary-dark hover:underline cursor-pointer"}
                  // href={`${EDULYTE_WEB_DOMAIN_URL}/user/${user?.user?.username}`}
                  href={`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/user/${user?.user?.username}` })}`}
                  target={"_blank"}
                >
                  {userDetail?.userDetail?.firstName + " " + userDetail?.userDetail?.lastName.charAt(0) + "."}
                </a>
              </ToolTipView>
            </div>
          </div>
          {addUserDetail?.isLoading &&
            <div className={"flex gap-0.5 absolute right-5 top-24"}>
              <FaSpinner className={"inline-flex text-primary-dark animate-spin"} />
            </div>
          }
        </div>
        <div className='-mt-5'>
          <ToolTipView content='View profile'>
            <a
              // href={`${EDULYTE_WEB_DOMAIN_URL}/user/${user?.user?.username}`}
              href={`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/user/${user?.user?.username}` })}`}
              target='_black'
              className={cn(
                "bg-white text-primary-dark hover:text-secondary-dark ease-in-out duration-200 h-10 w-10 flex justify-center items-center rounded-full shadow",
              )}
            >
              <BiUserCircle size={20} />
            </a>
          </ToolTipView>
        </div>
      </div>
    </>
  );
};

export default NameDetails;