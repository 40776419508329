import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";

import { DateCalendar, PickersDay } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { FaSpinner } from "react-icons/fa"
import { AiTwotoneCalendar } from "react-icons/ai";

import availabilityService from "redux/availability/availability.service";

import { useAppState } from "hooks/useStore";
import { bookingOfferingTypeEnum } from "redux/booking/booking.const";

import { dayjs, getTimeZoneOffset, timeZone } from "utils/dateTime.utils"
import { cn } from "utils/cn.utils";

const initialState = { isLoading: false, data: null, message: null }
const searchParamsInfo = {
  selectedDate: {
    key: "selected_date"
  }
}

const AppointmentCalendar = ({ appointment, selectedDate, setSelectedDate, onHandleNext }) => {
  const { session } = useAppState(s => s.auth)
  const { user } = useAppState(s => s.user)

  const timeZoneOffset = getTimeZoneOffset(timeZone)

  const [availableSlotDetail, setAvailableSlotDetail] = useState(initialState)
  const [availableDateList, setAvailableDateList] = useState([])
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([])
  const [dateTimeSlot, setDateTimeSlot] = useState({
    date: null,
    timeSlots: []
  })

  const navigate = useNavigate()
  const location = useLocation()

  const availableSlot = useMemo(() => availableSlotDetail?.data?.result, [availableSlotDetail?.data])
  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  const getAvailableSlotDetail = async (availabilityId, query) => {
    try {
      setAvailableSlotDetail(s => ({ ...s, isLoading: true }))

      const requestData = {
        params: { id: availabilityId },
        query: query
      }
      const response = await availabilityService.getAvailableSlotDetail(requestData)
      if (response.status === 200) {
        setAvailableSlotDetail(s => ({ ...s, data: response.data.data, message: null }))
      } else {
        throw new Error(response)
      }
    } catch (error) {
      console.error(error.response.data.message || error.response.data.error || "Something Went Wrong!")
      setAvailableSlotDetail(s => ({ ...s, message: error.response.data.message || error.response.data.error || "Something Went Wrong!", data: null }))
    } finally {
      setAvailableSlotDetail(s => ({ ...s, isLoading: false }))
    }
  }

  useEffect(() => {
    if (appointment) {
      const query = { timeZone: timeZone, duration: appointment?.appointment_availability?.duration }
      getAvailableSlotDetail(appointment?.appointment_availability?.availability?.id, query)
    }

    return () => {
      setAvailableSlotDetail(initialState)
    }
  }, [appointment])

  useEffect(() => {
    if (!!availableSlot?.availableSlots) {
      const availDateObjList = availableSlot?.availableSlots?.map(avail => dayjs(avail?.date + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone))
      setAvailableDateList(availDateObjList)
    }
  }, [availableSlot?.availableSlots, timeZoneOffset])

  useEffect(() => {
    if (!!availableDateList?.length && !!searchQueryParams.get(searchParamsInfo.selectedDate.key)) {
      const queryDate = searchQueryParams.get(searchParamsInfo.selectedDate.key)
      const parsedQueryDayjs = dayjs(queryDate, 'DD-MM-YYYY', true)
      if (parsedQueryDayjs.isValid()) {
        const newSelectedDayjs = dayjs(parsedQueryDayjs.format('YYYY-MM-DD') + timeZoneOffset, 'YYYY-MM-DDZ').tz(timeZone)
        onHandleDateSelection(newSelectedDayjs)
      } else {
        console.error('Invalid date format:', queryDate)
        searchQueryParams.delete(searchParamsInfo.selectedDate.key)
        navigate(`${location.pathname}`, { replace: true })
      }
    }
  }, [availableDateList, location.pathname, navigate, searchQueryParams, timeZoneOffset])

  const isDateAllowed = useCallback((date) => availableDateList?.some(allowedDate => date.isSame(allowedDate, 'day')), [availableDateList]);

  const onHandleDateSelection = useCallback((newValue) => {
    const isAllowedDate = availableDateList?.some(allowedDate => newValue.isSame(allowedDate, 'day'))
    if (!isAllowedDate) {
      searchQueryParams.delete(searchParamsInfo.selectedDate.key)
      navigate(`${location.pathname}`, { replace: true })
      return;
    }
    const selectedSlot = availableSlot?.availableSlots?.find(avail => dayjs(avail?.date + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone).isSame(newValue))
    setSelectedTimeSlots(selectedSlot?.timeSlots || [])
    setSelectedDate(newValue)
    setDateTimeSlot({
      date: null,
      timeSlots: []
    })
  }, [availableDateList, availableSlot?.availableSlots, location.pathname, navigate, searchQueryParams, setSelectedDate, timeZoneOffset])

  const handleSelectDate = useCallback((newValue) => {
    const newSelectedDate = dayjs(newValue.format("YYYY-MM-DD") + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone).format("DD-MM-YYYY")
    searchQueryParams.set(searchParamsInfo.selectedDate.key, newSelectedDate)
    navigate(`${location.pathname}?${searchQueryParams.toString()}`)
  }, [location.pathname, navigate, searchQueryParams, timeZoneOffset])

  function CustomDay(props) {
    const { day, selectedDay, ...other } = props;
    const isSelected = day.isSame(selectedDay, 'day');
    const isAllowed = isDateAllowed(day);

    return (
      <PickersDay
        {...other}
        day={day}
        sx={{
          ...(isAllowed && !isSelected && {
            backgroundColor: 'rgba(0, 123, 255, 0.1)',
            color: '#0741ad',
            '&:hover': {
              backgroundColor: 'rgba(0, 123, 255, 0.2)',
            },
          }),
          ...(isSelected && {
            backgroundColor: '#0741ad',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#0741ad',
            },
          }),
        }}
      />
    );
  }

  const TimeSlot = ({ timeSlot }) => {

    let isSelected = useMemo(() => dayjs(selectedDate.format("YYYY-MM-DD") + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
      .isSame(dayjs(dateTimeSlot?.date + " " + dateTimeSlot?.timeSlots[0]?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)), [timeSlot])

    const handleSelect = () => {
      if (!timeSlot?.isAvailable) return;

      setDateTimeSlot(s => ({
        ...s,
        date: selectedDate.format("YYYY-MM-DD"),
        timeSlots: [{
          startTime: timeSlot?.startTime,
          endTime: timeSlot?.endTime
        }]
      }))
    }

    const handleNext = () => {
      if (!timeSlot?.isAvailable || !isSelected) return;

      if (!session?.isLoggedIn || !user?.user) {
        window.open(`${window.location.host}/log-in?action_window=login_popup`, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=600,width=600,height=600")
        return;
      }

      onHandleNext({
        userId: user?.user?.userId,
        offeringType: bookingOfferingTypeEnum.APPOINTMENT.value,
        appointment: {
          appointmentId: appointment.id,
          timeZone: timeZone,
          schedule: [dateTimeSlot],
        }
      })
    }

    return (
      <div className={"w-full flex flex-row items-center justify-center gap-2"}>
        <button onClick={handleSelect} className={cn(
          "flex-1 px-2 py-2.5 border cursor-pointer border-primary-dark rounded",
          "font-buttons font-medium text-base text-primary-dark text-center whitespace-nowrap",
          "transition-all ease-in-out duration-300 delay-75",
          isSelected ? "bg-primary-dark text-text-50" : "hover:bg-primary-light",
          !timeSlot?.isAvailable && "text-primary-light border-primary-light cursor-not-allowed hover:bg-white"
        )}
          disabled={!timeSlot?.isAvailable}
        >
          {dayjs(selectedDate.format("YYYY-MM-DD") + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
        </button>
        {isSelected && (
          <button onClick={handleNext} className={cn(
            "flex-1 px-2 py-2.5 cursor-pointer border-secondary-dark rounded",
            "font-buttons font-medium text-base text-secondary-dark text-center whitespace-nowrap",
            "transition-all ease-in-out duration-300 delay-75",
            isSelected ? "bg-secondary-dark text-text-50" : ""
          )}
            disabled={!isSelected || !timeSlot?.isAvailable}
          >
            {"Next"}
          </button>
        )}
      </div>
    )
  }

  return (
    <div className={"w-full space-y-5"}>
      <div className={"w-full pr-8 flex flex-col md:flex-row items-start md:items-center justify-between gap-2"}>
        <span className={"font-bodyPri font-medium text-lg text-text-900 text-left"}>
          {"Select a Date & Time"}
        </span>
        <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
          {dayjs().tz(timeZone).format(`(z, zzz)`)}
        </span>
      </div>

      <div className={cn("w-full flex flex-col sm:flex-row gap-2")}>

        {/* Calendar */}
        <div className={cn("relative w-full transition-all ease-in-out duration-300", selectedDate && "sm:w-3/5")}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              minDate={dayjs().tz(timeZone)}
              maxDate={availableDateList?.length > 0 ? availableDateList[availableDateList?.length - 1] : dayjs().tz(timeZone)}
              shouldDisableDate={(date) => !isDateAllowed(date)}
              onChange={handleSelectDate}
              slots={{ day: CustomDay }}
              slotProps={{
                day: {
                  selectedDay: selectedDate
                },
              }}
              views={['day']}
              openTo="day"
              sx={{
                width: '100%',
                height: 'auto',
                '.MuiDayCalendar-header': {
                  display: 'grid',
                  gridTemplateColumns: 'repeat(7, 1fr)'
                },
                '.MuiDayCalendar-weekContainer': {
                  display: 'grid',
                  gridTemplateColumns: 'repeat(7, 1fr)',
                },
                '.MuiDayCalendar-weekDayLabel': {
                  fontWeight: "800"
                },
                '.MuiPickersDay-root': {
                  fontWeight: "800"
                },
              }}
            />
          </LocalizationProvider>
          {(!!availableSlotDetail?.isLoading) && (
            <>
              <div className={"absolute z-40 inset-0 bg-white/50 blur-lg"} />
              <div className={"absolute z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"}>
                <div className={"bg-primary-light rounded-full shadow-all-md px-5 py-3"}>
                  <FaSpinner className={"text-4xl text-text-700 animate-spin"} />
                </div>
              </div>
            </>
          )}
          {!availableSlotDetail?.isLoading && (!!availableSlotDetail?.message || (!!availableSlotDetail?.data && !availableSlot?.availableSlots?.length)) && (
            <>
              <div className={"absolute z-40 inset-0 bg-white/50 blur-lg"} />
              <div className={"absolute z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"}>
                <div className={"bg-primary-light rounded-full shadow-all-md px-5 py-3"}>
                  <div className={"w-full font-bodyPri font-medium text-base text-primary-dark text-center whitespace-nowrap"}>
                    {availableSlotDetail?.message || "No Available Slots"},
                    <br />
                    {"please contact the organiser!"}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className={cn("w-full sm:w-2/5 pt-5 space-y-5 transition-all ease-in-out duration-300 delay-300 opacity-0 hidden", selectedDate && "opacity-100 block")}>
          <div className={"w-full flex items-center justify-start gap-1"}>
            <AiTwotoneCalendar className={"text-lg text-text-700"} />
            <span className={"font-bodyPri font-normal text-base text-text-900"}>
              {selectedDate?.format("dddd, DD MMM")}
            </span>
          </div>

          <div className={"space-y-3 w-full h-full lg:h-[58vh] overflow-y-auto lg:pr-2 scrollbar-thin"}>
            {selectedTimeSlots?.map((timeSlot, index) => (
              <TimeSlot key={index} timeSlot={timeSlot} />
            ))}
          </div>
        </div>

      </div>
    </div>
  )
}

export default AppointmentCalendar