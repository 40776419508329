import { useAppState } from 'hooks/useStore';
import { productPriceModelEnum, productTypeEnum } from 'redux/product/product.const';

import { pagesInfo } from 'utils/pagesInfo';
import { getCustomHostPath } from 'utils/generators.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

const OrderItemProductItemsSection = ({ orderProductItem }) => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)

    const onHandleNavigateToProduct = () => {
        if ([productTypeEnum.LMS_COURSE.value]?.includes(orderProductItem?.order_item_product_prices[0]?.product_price?.product?.type)) {
            // window.open(`${EDULYTE_WEB_DOMAIN_URL}/course/${orderProductItem?.order_item_product_prices[0]?.product_price?.product?.lms_course?.active_slug}`)
            window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/course/${orderProductItem?.order_item_product_prices[0]?.product_price?.product?.lms_course?.active_slug}` })}`)
        } else {
            window.open(`${pagesInfo.COURSE.pagePath}/${orderProductItem?.order_item_product_prices[0]?.product_price?.product?.live_course?.active_slug}`)
        }    }

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full flex items-start justify-start gap-5"}>
                <div
                    className={"w-20 h-20 overflow-hidden rounded-md"}
                    onClick={onHandleNavigateToProduct}
                >
                    <img
                        src={orderProductItem?.order_item_product_prices[0]?.product_price?.product?.thumbnail_pic_url}
                        className={"w-full h-full object-cover"}
                        alt={"order-item-thumbnail"}
                    />
                </div>
                <div className={"flex flex-col gap-1.5"}>
                    <span
                        className={"font-bodyPri font-semibold text-text-900 text-lg tracking-wide truncate line-clamp-1 cursor-pointer hover:!text-secondary-dark hover:underline"}
                        onClick={onHandleNavigateToProduct}
                    >
                        {orderProductItem?.order_item_product_prices[0]?.product_price?.product?.title}
                    </span>
                    <div className={"inline-flex space-x-2 font-bodyPri text-text-900 text-sm tracking-wide whitespace-nowrap"}>
                        <span className={""}>
                            {"Price Model:"}
                        </span>
                        <span className="font-bold capitalize">
                            {productPriceModelEnum[orderProductItem?.order_item_product_prices[0]?.price_model?.toUpperCase()]?.label}
                        </span>
                    </div>
                    <div className={"inline-flex space-x-2 font-bodyPri text-text-900 text-sm tracking-wide whitespace-nowrap"}>
                        <span className={""}>
                            {"Price:"}
                        </span>
                        <span className="font-bold capitalize">
                            {orderProductItem?.order_item_product_prices[0]?.master_currency?.code?.toUpperCase()} {parseFloat("" + (orderProductItem?.order_item_product_prices[0]?.total_amount / 100)).toFixed(2)}
                            {/* {currencyRateConversion(locals?.currencyCode, orderProductItem?.order_item_product_prices[0]?.total_amount)} */}
                        </span>
                    </div>
                </div>
            </div>
            <div className={"w-full h-0.5 bg-divider-medium"} />
        </div>
    )
}

export default OrderItemProductItemsSection;