import { memo, useCallback, useState } from "react";

import { cn } from "utils/cn.utils";

import { RiDeleteBin6Line } from "react-icons/ri";
import { FiChevronsDown } from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import ToolTipView from "components/tooltipView";

import { libraryTabConst } from 'components/modals/lmsModals/attachLibraryModal/data';

import Drag from "pages/auth/edulyteLms/assets/drag.svg";
import LmsIconButtons from "pages/auth/edulyteLms/commonComponents/lmsButtons/LmsIconButtons";
import UpdateLmsTextInputText from "pages/auth/edulyteLms/commonComponents/UpdateLmsInputText";

import { courseSectionIconList } from "../../../editLMSCourse.data";
import CourseSectionLesson from "./CourseSectionLesson";

import { deleteLmsCourseSection, updateLmsCourseSection } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetLocalTabs, setLocalTabs, setModal } from "redux/local/local.slice";
import { resetAddLmsCourseSectionDetailPayload, setAddLmsCourseSectionDetailPayload } from "redux/edulyteLms/lmsCourse/lmsCourse.slice";
import { modalConst } from "redux/local/local.const";
import { lmsCourseSectionLessonTypeEnum } from "redux/edulyteLms/lmsCourse/lmsCourse.const";

const LMSCourseSectionItem = memo(({ courseSectionItem }) => {
    const { modal, localTabs } = useAppState((state) => state.local)
    const { addLmsCourseSectionDetail, updateLmsCourseSectionDetail, deleteLmsCourseSectionDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [sectionTitleStatus, setSectionTitleStatus] = useState("")

    const onHandleSelectOption = useCallback((option) => {
        dispatcher(resetAddLmsCourseSectionDetailPayload())
        dispatcher(resetLocalTabs())
        dispatcher(setAddLmsCourseSectionDetailPayload({
            ...addLmsCourseSectionDetail?.payload,
            sectionId: courseSectionItem?.id
        }))
        let activeTab = null

        if (option?.value === lmsCourseSectionLessonTypeEnum?.LECTURE?.value) {
            activeTab = libraryTabConst.LECTURES.value
        }
        if (option?.value === lmsCourseSectionLessonTypeEnum?.ARTICLE?.value) {
            activeTab = libraryTabConst.ARTICLES.value
        }
        if (option?.value === lmsCourseSectionLessonTypeEnum?.QUIZ?.value) {
            activeTab = libraryTabConst.QUIZZES.value
        }
        dispatcher(setLocalTabs({
            ...localTabs,
            modalTitle: "Library",
            tabList: Object.values(libraryTabConst),
            activeTabList: [activeTab],
            currentActiveTab: activeTab,
            tabItem: {
                ...localTabs?.tabItem,
                payload: { sectionId: courseSectionItem?.id }
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ATTACH_LIBRARY_MODAL.stateKey]: true
        }))
    }, [modal, localTabs, courseSectionItem, addLmsCourseSectionDetail?.payload])

    const onHandleToggle = () => {
        dispatcher(resetLocalTabs())
        if (addLmsCourseSectionDetail?.payload?.sectionId === courseSectionItem?.id) {
            dispatcher(setAddLmsCourseSectionDetailPayload({
                ...addLmsCourseSectionDetail?.payload,
                sectionId: null
            }))
        } else {
            dispatcher(setAddLmsCourseSectionDetailPayload({
                ...addLmsCourseSectionDetail?.payload,
                sectionId: courseSectionItem?.id
            }))
        }
    }

    const onHandleSaveSectionTitle = (editableSectionTitle) => {
        if (editableSectionTitle === courseSectionItem?.title) return;
        setSectionTitleStatus("Saving...")

        const body = {
            title: editableSectionTitle
        }
        const payload = {
            isShowToast: false
        }
        dispatcher(updateLmsCourseSection(courseSectionItem?.id, body, payload))

        setTimeout(() => {
            setSectionTitleStatus("")
        }, 1000)
    }

    const onHandleDeleteCourseSection = () => {
        if (addLmsCourseSectionDetail?.isLoading || updateLmsCourseSectionDetail?.isLoading || deleteLmsCourseSectionDetail?.isLoading) return;

        dispatcher(deleteLmsCourseSection(courseSectionItem?.id))
    }

    return (
        <div className={cn(
            'w-full border rounded-lg',
            (addLmsCourseSectionDetail?.payload?.sectionId === courseSectionItem?.id)
                ? 'border-primary-main bg-primary-light'
                : 'border-back-ground-darkLight'
        )}>
            <div
                className={cn(
                    'w-full flex flex-col px-4 py-2 md:flex-row md:items-center md:justify-between rounded-t-lg',
                    'transition-transform ease-in-out delay-200 duration-700 drop-shadow-sm',
                    (addLmsCourseSectionDetail?.payload?.sectionId === courseSectionItem?.id)
                        ? 'bg-primary-light'
                        : 'bg-white rounded-lg'
                )}
            >
                <div className={cn('flex flex-1 gap-1 justify-start items-center cursor-grab')}>
                    <img
                        src={Drag}
                        className={"w-2.5 cursor-grab"}
                    />
                    <UpdateLmsTextInputText
                        isLoading={updateLmsCourseSectionDetail?.isLoading}
                        isShowInputLabel={false}
                        inputClassName={cn(
                            "font-bodyPri font-medium text-black text-lg hover:!bg-text-100 focus:!border-b focus:!border-primary-dark",
                            (addLmsCourseSectionDetail?.payload?.sectionId === courseSectionItem?.id) && "!bg-primary-light"
                        )}
                        title={courseSectionItem?.title}
                        titleInputStatus={sectionTitleStatus}
                        setTitleInputStatus={setSectionTitleStatus}
                        onHandleSaveTitle={onHandleSaveSectionTitle}
                    />
                </div>
                <div className={"w-full md:w-fit flex justify-end md:justify-start items-center gap-2"}>
                    <LmsIconButtons
                        iconButtonList={Object.values(courseSectionIconList)}
                        onHandleIconButton={onHandleSelectOption}
                    />
                    <ToolTipView content={"Delete Section"}>
                        <button
                            className={"p-2 rounded-full hover:bg-divider-darkLight shadow-sm"}
                            onClick={onHandleDeleteCourseSection}
                        >
                            <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
                        </button>
                    </ToolTipView>

                    <button
                        className={cn(
                            'text-black cursor-pointer ease-in-out duration-300 delay-200',
                            "p-2 rounded-full shadow-sm hover:bg-divider-darkLight",
                            (addLmsCourseSectionDetail?.payload?.sectionId === courseSectionItem?.id) ? 'rotate-180 ease-in-out duration-300' : ''
                        )}
                        onClick={() => onHandleToggle(courseSectionItem?.id)}
                    >
                        <FiChevronsDown size={22} />
                    </button>
                </div>
            </div>

            <AnimatePresence initial={addLmsCourseSectionDetail?.payload?.sectionId}>
                {(addLmsCourseSectionDetail?.payload?.sectionId === courseSectionItem?.id) &&
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="overflow-hidden"
                    >
                        <div className={"pl-0 md:pl-4"}>
                            <CourseSectionLesson courseSectionItem={courseSectionItem} />
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
});

export default LMSCourseSectionItem;