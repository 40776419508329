import { useEffect } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PageContentStyle } from './CheckoutTokenStyle';
import HeaderSection from './components/HeaderSection';
import TokenItemSection from "./components/TokenItemSection";
import PaymentMethodSection from "./components/PaymentMethodSection";
import PaymentSummerySection from "./components/PaymentSummarySection";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getMyProfile } from 'redux/user/user.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAddTokensPayload } from "redux/wallet/wallet.slice";
import { setAddPaymentIntentDetailPayload } from 'redux/payment/payment.slice';
import { paymentTypeEnum } from 'redux/payment/payment.const';
import { masterCurrencyCodeEnum } from "redux/master/master.const";

import { pagesInfo } from 'utils/pagesInfo';

const currencyObj = {
    INR: 200,
    USD: 20000,
    AUD: 10000
}

const CheckoutTokenPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { locals } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { addTokens } = useAppState(s => s.wallet)
    const { addPaymentIntentDetail } = useAppState((state) => state.payment)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.CHECKOUT_TOKEN))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(getMyProfile())
    }, [])


    useEffect(() => {
        onHandleSelectCurrency()
    }, [])

    const onHandleSelectCurrency = (option) => {
        let masterCurrency = option
            ? option
            : [masterCurrencyCodeEnum.AUD.value, masterCurrencyCodeEnum.INR.value]?.includes(locals?.currencyCode)
                ? locals?.currencyCode
                : masterCurrencyCodeEnum.USD.value

        let walletCredit = [masterCurrencyCodeEnum.USD.value]?.includes(masterCurrency)
            ? user?.user?.netWalletCredit
            : [masterCurrencyCodeEnum.AUD.value]?.includes(masterCurrency)
                ? user?.user?.netAudWalletCredit
                : user?.user?.netInrWalletCredit

        let tokenAmount = 10000
        let gatewayAmount = Math.ceil(tokenAmount - walletCredit)

        dispatcher(setAddTokensPayload({
            ...addTokens?.addTokenPayload,
            currencyCode: masterCurrency,
            amount: tokenAmount,
            total_tokens: (tokenAmount * currencyObj[masterCurrency]) / 100,
            payThroughGateWay: gatewayAmount > 0,
            payThroughWallet: true
        }))

        if (gatewayAmount > 0) {
            let amountToPay = tokenAmount
            let processingFeeAmount = Math.ceil(amountToPay * user?.user?.processingFeePct / 100)
            let totalAmountToPay = Math.ceil(amountToPay + processingFeeAmount)
            dispatcher(setAddPaymentIntentDetailPayload({
                ...addPaymentIntentDetail?.payload,
                paymentType: paymentTypeEnum.GPT_TOKEN.value,
                email: user?.user?.email,
                name: `${user?.user?.firstName} ${user?.user?.lastName}`,
                description: paymentTypeEnum.GPT_TOKEN.value,
                gatewayName: addTokens?.addTokenPayload?.gatewayName,
                currency: masterCurrency?.toLowerCase(),
                amountToPay: amountToPay,
                processingFeePct: user?.user?.processingFeePct,
                processingFee: processingFeeAmount,
                totalAmountToPay: totalAmountToPay,
            }))
        }
    }

    const handleWalletToggle = (isToggle) => {
        let walletAmountToPay = 0

        let gatewayAmountToPay = 0

        let masterCurrency = addTokens?.addTokenPayload?.currencyCode

        let walletCredit = [masterCurrencyCodeEnum.USD.value]?.includes(masterCurrency)
            ? user?.user?.netWalletCredit
            : [masterCurrencyCodeEnum.AUD.value]?.includes(masterCurrency)
                ? user?.user?.netAudWalletCredit
                : user?.user?.netInrWalletCredit

        if (isToggle) {
            if (walletCredit > 0) {
                let gatewayAmount = Math.ceil(addTokens?.addTokenPayload?.amount - walletCredit)
                if ((gatewayAmount > 0)) {
                    walletAmountToPay = walletCredit
                    gatewayAmountToPay = gatewayAmount
                } else {
                    walletAmountToPay = addTokens?.addTokenPayload?.amount
                    gatewayAmountToPay = 0
                }
            } else {
                walletAmountToPay = 0
                gatewayAmountToPay = addTokens?.addTokenPayload?.amount
            }
        } else {
            walletAmountToPay = 0
            gatewayAmountToPay = addTokens?.addTokenPayload?.amount
        }
        dispatcher(setAddTokensPayload({
            ...addTokens?.addTokenPayload,
            payThroughWallet: isToggle,
            amount: addTokens?.addTokenPayload?.amount,
            payThroughGateWay: gatewayAmountToPay > 0,
            currencyCode: masterCurrency
        }))

        if (gatewayAmountToPay > 0) {
            let amountToPay = (gatewayAmountToPay >= 100 ? gatewayAmountToPay : 100)
            let processingFeeAmount = Math.ceil(amountToPay * user?.user?.processingFeePct / 100)

            let totalAmountToPay = Math.ceil(amountToPay + processingFeeAmount)

            dispatcher(setAddPaymentIntentDetailPayload({
                ...addPaymentIntentDetail?.payload,
                paymentType: paymentTypeEnum.GPT_TOKEN.value,
                email: user?.user?.email,
                name: `${user?.user?.firstName} ${user?.user?.lastName}`,
                description: paymentTypeEnum.GPT_TOKEN.value,
                gatewayName: addTokens?.addTokenPayload?.gatewayName,
                currency: masterCurrency?.toLowerCase(),
                amountToPay: amountToPay,
                processingFeePct: user?.user?.processingFeePct,
                processingFee: processingFeeAmount,
                totalAmountToPay: totalAmountToPay,
            }))
        }
    }
    
    const setStripeAccount = () => {
        let account_id = "";

        if (!!user?.user?.gateways?.primary && user?.user?.gateways?.primary?.provider_type === 'stripe') {
            account_id = user?.user?.gateways?.primary?.account_id
        } else if (!!user?.user?.gateways?.secondary && user?.user?.gateways?.secondary?.provider_type === 'stripe') {
            account_id = user?.user?.gateways?.primary?.account_id
        }
        return account_id 
    }

    return (
        <PageContentStyle>

            <HeaderSection />

            <div className={"container mx-auto px-8 py-5 grid grid-cols-12 gap-5"}>

                <div className={"col-start-1 col-span-full lg:col-span-8 space-y-5"}>
                    <TokenItemSection onHandleSelectCurrency={onHandleSelectCurrency} />
                    <PaymentMethodSection handleWalletToggle={handleWalletToggle} />
                </div>

                <div className={"relative col-start-1 col-span-full lg:col-start-9 lg:col-span-4"}>
                    <div className={"lg:sticky lg:top-[1rem] lg:mt-10"}>
                        <Elements stripe={loadStripe(user?.user?.stripePk, {
                            stripeAccount: setStripeAccount()
                        })}>
                            <PaymentSummerySection />
                        </Elements>
                    </div>
                </div>
            </div>
        </PageContentStyle>
    )
}

export default CheckoutTokenPage;