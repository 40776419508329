import { useCallback, useEffect, useMemo, useState } from 'react';

import { cn } from "utils/cn.utils";

import { MdEdit } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import ViewOnlyQuillEditor from 'components/textEditor/ViewOnlyQuillEditor';
import QuillEditor from 'components/textEditor/QuillEditor';

import AddBorderButton from 'pages/auth/edulyteLms/commonComponents/AddBorderButton';
import SaveAndCancelBtn from "pages/auth/edulyteLms/commonComponents/lmsButtons/SaveAndCancelBtn";
import PreviousNextButtons from 'pages/auth/edulyteLms/commonComponents/lmsButtons/PreviousNextButtons';
import { courseDetailBtnConst, courseDetailNavigationOptions, lmsCourseTooltips } from '../../../../editLMSCourse.data';

import { updateLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddLmsCourseDetailPayload } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';
import AiTextButton from 'components/createWithAi/aiTextButton';
import { toast } from 'react-toastify';
import useAiText from 'hooks/useAiText';

const Editor = {
    modules: {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link'],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    },
    theme: 'snow',
    formats: [
        'header', 'font', 'size',
        'bold', 'italic', 'underline',
        'list', 'bullet', 'indent',
        'link',
        'color', 'background'
    ]
}

const CourseDescription = () => {
    const { lmsCourseDetail, addLmsCourseDetail, modifyLmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()

    const [isMouseHover, setIsMouseHover] = useState(false)
    const [editBtn, setEditBtn] = useState(null)
    const [isShowAddDescription, setIsShowAddDescription] = useState(!lmsCourseDetail?.data?.description)
    const [shouldUpdateDescription, setShouldUpdateDescription] = useState(false)
    const [isShowInput, setIsShowInput] = useState(false)
    const [topic, setTopic] = useState('')

    const { aiText, generateAiText } = useAiText()

    const requestData = {
        purpose: "description", // description or title 
        total_chars: 250,
        topic: topic,
        response_type: "html" // text or html 
    };

    const getAiText = () => {
        if (topic === "") {
            toast.error("Please write ai prompt");
            return
        }
        setIsShowInput(false)
        generateAiText(requestData);
    };

    const handlePrompt = (e) => {
        const value = e.target.value
        setTopic(value)
    }

    useEffect(() => {
        if (aiText?.data?.ai_response) {
            dispatcher(updateLmsCourseDetail(
                lmsCourseDetail?.data?.id,
                {
                    description: aiText.data.ai_response
                })
            )
        }
    }, [aiText?.data]);

    const has_content = useMemo(() => (!!new DOMParser()?.parseFromString(`<div>${lmsCourseDetail?.data?.description}</div>`, "text/html")?.querySelector("div")?.textContent), [lmsCourseDetail?.data?.description])

    const onHandleAddDescription = useCallback(() => {
        setIsShowAddDescription(false)
        setEditBtn(courseDetailBtnConst.DESCRIPTION.value)
    }, [isShowAddDescription, editBtn])

    const onHandleEditDescription = useCallback(() => {
        setIsMouseHover(false)
        dispatcher(setAddLmsCourseDetailPayload({
            ...addLmsCourseDetail?.payload,
            description: lmsCourseDetail?.data?.description
        }))
        setEditBtn(courseDetailBtnConst.DESCRIPTION.value)
    }, [addLmsCourseDetail?.payload, lmsCourseDetail?.data])

    const onHandleChangeDescription = useCallback((content) => {
        setShouldUpdateDescription(true)
        dispatcher(setAddLmsCourseDetailPayload({
            ...addLmsCourseDetail?.payload,
            description: content
        }))
    }, [addLmsCourseDetail?.payload, shouldUpdateDescription])

    const onHandleSaveDescription = useCallback(() => {
        if (lmsCourseDetail?.data?.description === addLmsCourseDetail?.payload?.description) {
            setEditBtn(null)
            return;
        }
        dispatcher(updateLmsCourseDetail(
            lmsCourseDetail?.data?.id,
            {
                description: addLmsCourseDetail?.payload?.description
            })
        )
        setEditBtn(null)
    }, [lmsCourseDetail?.data?.description, addLmsCourseDetail?.payload?.description, editBtn])

    const onHandleResetDescription = useCallback(() => {
        setEditBtn(null)
    }, [editBtn])

    return (
        <div className={"w-full flex flex-col justify-between gap-5 rounded-lg overflow-hidden p-5 bg-white"}>
            <div className={"w-full flex flex-col gap-5"}>
                <div className={"w-full flex justify-between items-center px-3 py-2 bg-back-ground-lightBlue rounded-lg"}>
                    <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                        {"Description"}
                    </span>
                    <div className='relative mt-1 flex justify-end'>
                        <AiTextButton
                            containerClassName="top-0 right-0"
                            btnClassName="rounded h-6"
                            btnTitle=''
                            tooltip='Create description with AI'
                            isShowInput={isShowInput}
                            setIsShowInput={setIsShowInput}
                            handlePrompt={handlePrompt}
                            getAiText={getAiText}
                            aiText={aiText}
                        />
                    </div>
                </div>

                <div className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                    {lmsCourseTooltips?.description}
                </div>
                {(!modifyLmsCourseDetail?.isLoading && isShowAddDescription) &&
                    <AddBorderButton
                        btnTitle={"Add Description"}
                        onHandleAdd={onHandleAddDescription}
                    />
                }

                {modifyLmsCourseDetail?.isLoading &&
                    <ComponentLoader isLoading={modifyLmsCourseDetail?.isLoading} />
                }

                {(!modifyLmsCourseDetail?.isLoading && !isShowAddDescription) &&
                    <div className={"w-full flex flex-col gap-3 px-5"}>
                        {/* description */}
                        <div
                            className={cn("grid grid-cols-12 gap-2 items-start", !editBtn && "cursor-pointer")}
                            onMouseOver={() => setIsMouseHover(true)}
                            onMouseOut={() => setIsMouseHover(false)}
                        >
                            <span className={"h-5 col-span-8 order-1 w-fill flex items-center justify-start font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                                <span>{""}</span>
                                <span>{""}</span>
                            </span>
                            {(editBtn !== courseDetailBtnConst.DESCRIPTION.value) &&
                                <div
                                    className={"relative w-full col-span-full order-3 justify-self-stretch px-2 py-2 rounded-lg hover:bg-text-100"}
                                    onClick={onHandleEditDescription}
                                >
                                    {has_content &&
                                        <ViewOnlyQuillEditor
                                            editorContent={lmsCourseDetail?.data?.description}
                                            editorClassName={"placeholder:text-text-500 placeholder:text-lg"}
                                        />
                                    }
                                    {!has_content &&
                                        <span className={"font-bodyPri font-normal text-text-700 text-base italic"}>
                                            {"Start Typing"}
                                        </span>
                                    }
                                    {isMouseHover &&
                                        <span className={"absolute top-1 right-2"}>
                                            <MdEdit className={"text-xl md:text-2xl text-text-800 cursor-pointer"}
                                            />
                                        </span>
                                    }
                                </div>
                            }
                            {(editBtn === courseDetailBtnConst.DESCRIPTION.value) &&
                                <div className={"relative col-span-full order-3 justify-self-stretch"}>
                                    <div className={"absolute right-3 flex items-center justify-start gap-0.5"}>
                                        <SaveAndCancelBtn
                                            onHandleSave={onHandleSaveDescription}
                                            onHandleReset={onHandleResetDescription}
                                        />
                                    </div>
                                    <QuillEditor
                                        editorToolModules={Editor?.modules}
                                        editorToolFormats={Editor?.formats}
                                        editorText={addLmsCourseDetail?.payload?.description}
                                        onHandleChangeText={onHandleChangeDescription}
                                        placeholder={"Start Typing"}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            <PreviousNextButtons
                shouldUpdateDescription={shouldUpdateDescription}
                body={{ description: addLmsCourseDetail?.payload?.description }}
                previousBtn={courseDetailNavigationOptions?.GENERAL?.to}
                nextBtn={courseDetailNavigationOptions?.SKILLS_YOU_WILL_LEARN?.to}
            />
        </div>
    )
}

export default CourseDescription;