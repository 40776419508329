import { useCallback } from "react";

import { contactLabelIcons, sortDefaultContact } from "components/modals/crmModals/data";

const ContactNumbers = ({ contact }) => {

    const onHandleOpenPhone = useCallback((cellNumber) => {
        window.open(`tel:+${cellNumber.isd} ${cellNumber.phone}`, "_self")
    }, [contact?.contact_phones])

    const onHandleOpenEmail = useCallback((email) => {
        window.open(`mailto:${email?.email}`, "_self")
    }, [contact?.contact_emails])

    return (!!contact?.contact_phones?.length || !!contact?.contact_emails?.length) && (
        <div className="flex flex-col md:flex-row w-full gap-5 text-sm">
            {!!contact?.contact_phones?.length &&
                <div className="w-full space-y-3 bg-back-ground-lightBlue rounded-lg p-5">
                    {contact?.contact_phones?.slice(0)?.sort((item1, item2) => sortDefaultContact(item1?.id, item2?.id, contact?.phone?.id))?.map((cellNumber, index) => (
                        <div key={index} className={"flex items-center gap-3 group cursor-pointer"}>
                            {(() => {
                                const Icon = contactLabelIcons[cellNumber.label].icon;
                                return <Icon className={"text-text-700 text-lg group-hover:text-primary-dark"} />;
                            })()}
                            <p
                                className={"font-bodyPri font-normal group-hover:underline group-hover:text-primary-dark cursor-pointer"}
                                onClick={() => onHandleOpenPhone(cellNumber)}
                            >
                                {`+${cellNumber.isd}-${cellNumber.phone} ${(cellNumber?.id === contact?.phone?.id) ? "(Default)" : ""
                                    }`}
                            </p>
                        </div>
                    ))}
                </div>
            }
            {!!contact?.contact_emails?.length &&
                <div className="w-full space-y-3 bg-back-ground-lightBlue rounded-lg p-5">
                    {contact?.contact_emails?.slice(0)?.sort((item1, item2) => sortDefaultContact(item1?.id, item2?.id, contact?.email?.id))?.map((email, index) => (
                        <div key={index} className={"flex items-center gap-3 group"}>
                            {(() => {
                                const Icon = contactLabelIcons[email.label].icon;
                                return <Icon className={"text-text-700 text-lg group-hover:text-primary-dark"} />;
                            })()}
                            <p
                                className={"font-bodyPri font-normal group-hover:underline group-hover:text-primary-dark cursor-pointer"}
                                onClick={() => onHandleOpenEmail(email)}
                            >
                                {`${email.email} ${(email?.id === contact?.email?.id) ? "(Default)" : ""}`}
                            </p>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};

export default ContactNumbers;