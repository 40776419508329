import { useEffect, useState, memo, useMemo } from 'react';

import { IoIosArrowDown } from 'react-icons/io';
import { FaInfoCircle } from 'react-icons/fa';
// import { StarBorder } from '@mui/icons-material';

import ButtonLoader from 'components/loader/ButtonLoader';
import ToolTipView from 'components/tooltipView';

import { getSessionStatusMessage, statusToolTip } from 'components/modals/sessionDetailModal/sessionDetailModal.data';

import { completeSessionUser } from 'redux/session/session.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import {
    resetStartSessionUserDetail,
    resetAcceptSessionUserDetail,
    resetDeclineSessionUserDetail,
    resetRescheduleSessionUserDetail,
    resetCompleteSessionUserDetail,
    resetAddSessionUserDetailPayload
} from 'redux/session/session.slice';
// import { setAddUserReviewDetailPayload, setClearAddUserReviewDetail } from 'redux/review/review.slice';
import { modalConst } from 'redux/local/local.const';
import { sessionStatusEnum, sessionTypeEnum, courseSessionStatusActions, appointmentSessionStatusActions, interviewSessionStatusActions } from 'redux/session/session.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { cn } from "utils/cn.utils";

const SessionDetailStatus = memo(({ sessionUserInfo }) => {
    const { modal } = useAppState(s => s.local)
    const { user } = useAppState(s => s.user)
    const { sessionUserDetail, completeSessionUserDetail } = useAppState(s => s.session)
    // const { addUserReviewDetail } = useAppState((state) => state.review)

    const dispatcher = useAppDispatcher()

    const [isStatusChangeable, setIsStatusChangeable] = useState(false)
    const [isSessionStarted, setIsSessionStarted] = useState(false)
    const [isSessionEnded, setIsSessionEnded] = useState(false)

    useEffect(() => {
        if (completeSessionUserDetail?.data?.result) {
            dispatcher(resetCompleteSessionUserDetail())
        }
    }, [completeSessionUserDetail?.data?.result])

    useEffect(() => {
        if (sessionUserDetail?.data?.result && sessionUserInfo) {
            if (sessionUserDetail?.data?.result?.type === sessionTypeEnum.COURSE.value) {
                const sessionStatusActionsObject = courseSessionStatusActions[sessionUserInfo?.userType?.toUpperCase()][sessionUserInfo?.status?.toUpperCase()]
                setIsStatusChangeable(sessionStatusActionsObject && Object.values(sessionStatusActionsObject)?.length > 1 ? true : false)
            }
            if (sessionUserDetail?.data?.result?.type === sessionTypeEnum.APPOINTMENT.value) {
                const sessionStatusActionsObject = appointmentSessionStatusActions[sessionUserInfo?.userType?.toUpperCase()][sessionUserInfo?.status?.toUpperCase()]
                setIsStatusChangeable(sessionStatusActionsObject && Object.values(sessionStatusActionsObject)?.length > 1 ? true : false)
            }
            if (sessionUserDetail?.data?.result?.type === sessionTypeEnum.INTERVIEW.value) {
                const sessionStatusActionsObject = interviewSessionStatusActions[sessionUserInfo?.userType?.toUpperCase()][sessionUserInfo?.status?.toUpperCase()]
                setIsStatusChangeable(sessionStatusActionsObject && Object.values(sessionStatusActionsObject)?.length > 1 ? true : false)
            }
        }
    }, [sessionUserDetail?.data?.result, sessionUserInfo])

    useEffect(() => {
        let interval
        if (sessionUserDetail?.data?.result) {
            const nowTimeStamp = parseInt(dayjs().tz(timeZone).valueOf() / 1000)
            const startTimeStamp = parseInt(dayjs(sessionUserDetail?.data?.result?.startDateTime).tz(timeZone).valueOf() / 1000)
            const endTimeStamp = parseInt(dayjs(sessionUserDetail?.data?.result?.endDateTime).tz(timeZone).valueOf() / 1000)

            const deltaTimeStampToStart = startTimeStamp - nowTimeStamp
            const deltaTimeStampToEnd = endTimeStamp - nowTimeStamp
            const deltaTimeStamp15MinBeforeStart = startTimeStamp - (60 * 15) - nowTimeStamp

            setIsSessionStarted(deltaTimeStampToStart < 0)
            setIsSessionEnded(deltaTimeStampToEnd < 0)

            interval = setInterval(() => {
                if (sessionUserInfo?.status === sessionStatusEnum.SCHEDULED.value && !isSessionEnded) {
                    setIsStatusChangeable(deltaTimeStamp15MinBeforeStart > 0)
                }
            }, 1000)
        }

        return () => {
            clearInterval(interval)
        }
    })

    const sessionLogStatusObject = useMemo(() => sessionUserDetail?.data?.result?.session_logs?.find(session => session?.status === sessionUserInfo?.status), [sessionUserDetail?.data?.result?.session_logs, sessionUserInfo?.status])

    const sessionStatusMessage = useMemo(() => getSessionStatusMessage(sessionUserDetail?.data?.result?.type, sessionUserInfo?.userType, sessionUserInfo?.status, isSessionEnded), [sessionUserDetail?.data?.result, sessionUserInfo, isSessionEnded])

    const onHandleModal = () => {
        if (!isStatusChangeable) return;

        dispatcher(resetStartSessionUserDetail())
        dispatcher(resetAcceptSessionUserDetail())
        dispatcher(resetDeclineSessionUserDetail())
        dispatcher(resetRescheduleSessionUserDetail())
        dispatcher(resetAddSessionUserDetailPayload())
        dispatcher(setModal({
            ...modal,
            [modalConst.SESSION_DETAIL_MODAL.stateKey]: false,
            [modalConst.SESSION_ACTION_MODAL.stateKey]: true,
        }))
    }

    const handleOnComplete = () => {

        if (!window.confirm("Mark as completed?")) return;

        if (completeSessionUserDetail?.isLoading) return;

        dispatcher(completeSessionUser(sessionUserDetail?.data?.result?.id))
    }

    // const handleOnReview = () => {
    //     dispatcher(setClearAddUserReviewDetail())
    //     dispatcher(setAddUserReviewDetailPayload({
    //         ...addUserReviewDetail?.addUserReviewDetailPayload,
    //         userId: sessionUserDetail?.owner_user?.id,
    //         receiverUserId: sessionUserDetail?.owner_user?.id,
    //         receiverRole: sessionUserDetail?.owner_user?.role
    //     }))
    //     dispatcher(setModal({
    //         ...modal,
    //         [modalConst.SESSION_DETAIL_MODAL.stateKey]: false,
    //         [modalConst.ADD_REVIEW_MODAL.stateKey]: true
    //     }))
    // }
    
    return sessionUserInfo?.status ? (
        <div className={"flex flex-col gap-3"}>
            <div className={"flex items-center justify-between gap-1"}>
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-base text-text-800"}>
                        {"Status: "}
                    </span>
                    <div
                        className={cn(
                            "flex items-center justify-center gap-1 border-2 rounded-lg px-2 py-0.5",
                            "font-bodyPri font-medium text-base",
                            `border-${sessionStatusEnum[sessionUserInfo?.status?.toUpperCase()].darkColor}`,
                            `text-${sessionStatusEnum[sessionUserInfo?.status?.toUpperCase()].darkColor}`,
                            isStatusChangeable && "cursor-pointer",
                        )}
                        onClick={onHandleModal}
                    >
                        <span className={""}>
                            {sessionStatusEnum[sessionUserInfo?.status?.toUpperCase()].label}
                        </span>
                        {isStatusChangeable &&
                            <IoIosArrowDown className={"text-xl"} />
                        }
                    </div>
                    <ToolTipView
                        content={statusToolTip[sessionUserInfo?.status?.toUpperCase()]}
                        disabled={!sessionUserInfo?.status}
                    >
                        <button className={"cursor-pointer"}>
                            <FaInfoCircle className={"inline text-md text-text-500 hover:text-text-700"} />
                        </button>
                    </ToolTipView>
                </div>

                <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                    {dayjs(sessionLogStatusObject?.createdAt).tz(timeZone).fromNow()}
                </span>
            </div>
            
            {sessionStatusMessage &&
                <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                    {sessionStatusMessage}
                </span>
            }

            {(isSessionEnded && (sessionUserInfo?.status === sessionStatusEnum.ON_GOING.value) && (sessionUserDetail?.data?.result?.owner_user?.id === user?.user?.userId)) &&
                <div
                    onClick={handleOnComplete}
                    className={cn(
                        "flex items-center justify-center px-3 py-0.5",
                        "rounded-lg border-2 border-primary-dark",
                        "font-buttons font-medium text-base cursor-pointer",
                        !completeSessionUserDetail?.isLoading && "text-primary-dark hover:text-text-50 hover:bg-primary-dark",
                        completeSessionUserDetail?.isLoading && "bg-primary-dark"
                    )}
                >
                    {completeSessionUserDetail?.isLoading && <ButtonLoader isLoading={completeSessionUserDetail?.isLoading} />}
                    {!completeSessionUserDetail?.isLoading &&
                        <span>
                            {!completeSessionUserDetail?.data && "Mark Complete"}
                        </span>
                    }
                </div>
            }
            {/* {((sessionUserDetail?.data?.result?.status === sessionStatusEnum.COMPLETED.value) && (sessionUserDetail?.data?.result?.type === sessionTypeEnum?.COURSE?.value) && (sessionUserTypeEnum.PARTICIPANT.value === sessionUserInfo?.userType)) &&
                <div
                    onClick={handleOnReview}
                    className={cn(
                        "flex items-center justify-center gap-1.5 px-3 py-0.5",
                        "rounded-lg border-2 border-orange-400",
                        "font-buttons font-medium text-base text-orange-400",
                        "hover:text-text-50 hover:bg-orange-400 group cursor-pointer"
                    )}
                >
                    <StarBorder
                        style={{ fontSize: '15px' }}
                        className={"text-orange-400 cursor-pointer group-hover:text-text-50"}
                    />
                    <span className={""}>
                        {"Leave a review"}
                    </span>
                </div>
            } */}
        </div>
    ) : null
})

export default SessionDetailStatus;