import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import { data } from "pages/global/tutor/data";

import { PageContentStyle } from 'pages/global/tutor/TutorPage.style';

import PageLoader from 'components/loader/PageLoader';
import Footer1 from 'components/footer/footer1/Footer1';

import Profile from "pages/global/tutor/Profile";
import AboutMe from "pages/global/tutor/AboutMe";
import Tabs from "pages/global/tutor/Tabs";
import Appointments from 'pages/global/tutor/Appointments';
import Skills from "pages/global/tutor/Skills";
import TeachingStyle from 'pages/global/tutor/TeachingStyle';
import Qualifications from 'pages/global/tutor/Qualifications';
import Topics from "pages/global/tutor/Topics";
import Courses from "pages/global/tutor/Courses";
import Reviews from "pages/global/tutor/Reviews";
import MobileCard from "pages/global/tutor/MobileCard";
import StickyCard from 'pages/global/tutor/StickyCard';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getTutorUserPublicProfileByUserId } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearTutorPublicProfile } from "redux/tutor/tutor.slice";

import { pagesInfo } from 'utils/pagesInfo';
import { useTitle } from 'hooks/useTitle';

const ABOUT_ME = "ABOUT_ME";
const APPOINTMENTS = "APPOINTMENTS";
const SKILLS = "SKILLS";
const TEACHING_STYLE = "TEACHING_STYLE";
const QUALIFICATIONS = "QUALIFICATIONS";
const TOPICS = "TOPICS";
const CLASSES = "CLASSES";
const REVIEWS = "REVIEWS";


const TutorPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { tutorPublicProfile } = useAppState((s) => s.tutor)

  const dispatcher = useAppDispatcher()
  const { userId } = useParams()
  const [title, setTitle] = useTitle()

  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.TUTOR))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    if (tutorPublicProfile?.tutorPublicProfile) {
      setTitle({
        ...title,
        title: `${tutorPublicProfile?.tutorPublicProfile?.firstName} | Tutor | ${tutorPublicProfile?.tutorPublicProfile?.elevatorPitch.charAt(0).toUpperCase() + tutorPublicProfile?.tutorPublicProfile?.elevatorPitch.slice(1)} | Edulyte`,
        description: tutorPublicProfile?.tutorPublicProfile?.elevatorPitch
      })
    }
  }, [tutorPublicProfile?.tutorPublicProfile])


  useEffect(() => {
    window.addEventListener("scroll", handleScrollListener);

    return () => {
      window.removeEventListener("scroll", handleScrollListener);
    };
  });

  const handleScrollListener = () => {
    setScrollValue(window.scrollY);
  };

  useEffect(() => {
    if (userId) {
      dispatcher(getTutorUserPublicProfileByUserId(Number(userId)))
    }

    return () => {
      dispatcher(setClearTutorPublicProfile())
    }
  }, [userId])

  if (tutorPublicProfile?.isLoading) {
    return (
      <PageLoader />
    )
  }

  if (tutorPublicProfile?.errorMsg) {
    return (
      <div className={"w-screen h-screen flex justify-center items-center gap-1 font-bodyPri font-semibold text-text-800 text-md"}>
        {"Tutor not found, please go to"}
        <Link to={pagesInfo.HOME.pagePath} className={"text-primary-dark underline"}>
          {"homePage"}
        </Link>
      </div>
    )
  }

  return (
    <PageContentStyle>
      <div className={"w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl mx-auto p-5"}>
        <div className={"grid grid-cols-12 gap-3"}>
          <div className={"col-start-1 col-span-12 lg:col-start-1 lg:col-span-8 space-y-3"}>
            <div className={"hidden lg:block shadow bg-white rounded-t-xl"}>
              <Profile
                tutor={tutorPublicProfile?.tutorPublicProfile}
                scrollValue={scrollValue}
              />
            </div>
            <div className="block lg:hidden bg-white">
              <MobileCard tutor={tutorPublicProfile?.tutorPublicProfile} />
            </div>
            <div className="sticky top-[4rem] z-20 shadow bg-white">
              <Tabs
                data={data}
                containerIDs={[ABOUT_ME, APPOINTMENTS, SKILLS, TEACHING_STYLE, QUALIFICATIONS, TOPICS, CLASSES, REVIEWS]}
              />
            </div>
            <div className='md:block shadow bg-white'>
              <AboutMe tutor={tutorPublicProfile?.tutorPublicProfile} />
            </div>
            <div className='md:block shadow bg-white'>
              <Appointments />
            </div>
            <div className='md:block shadow bg-white'>
              <Skills tutor={tutorPublicProfile?.tutorPublicProfile} />
            </div>
            <div className='md:block shadow bg-white'>
              <TeachingStyle tutor={tutorPublicProfile?.tutorPublicProfile} />
            </div>
            <div className='md:block shadow bg-white'>
              <Qualifications />
            </div>
            <div className='md:block shadow bg-white'>
              <Topics tutor={tutorPublicProfile?.tutorPublicProfile} />
            </div>
            <div className='md:block shadow bg-white'>
              <Courses />
            </div>
            <div className='md:block shadow bg-white rounded-b-xl'>
              <Reviews />
            </div>
          </div>
          <div className={"hidden lg:block lg:col-start-9 lg:col-span-4"}>
            <StickyCard tutor={tutorPublicProfile?.tutorPublicProfile} />
          </div>
        </div>
      </div>
      <div className={""}>
        <Footer1 />
      </div>
    </PageContentStyle>
  )
};

export default TutorPage;
