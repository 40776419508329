import { Link } from 'react-router-dom';

import { AiFillLock } from "react-icons/ai";

import { pagesInfo } from 'utils/pagesInfo'

const HeaderSection = () => {

    return (
        <div className={"w-full bg-gradient-to-t from-back-ground-lightDark to-white h-16 flex item-center"}>
            <div className={"container mx-auto flex items-center justify-between px-8"}>
                <Link to={pagesInfo.HOME.pagePath}>
                    <span className={"font-bodyPri text-3xl text-text-800 tracking-wide"}>Edulyte</span>
                </Link>
                <span className={"font-bodyPri font-medium text-2xl text-text-800 tracking-wide"}>Set New Password</span>
                <AiFillLock className={"text-2xl text-text-600"} />
            </div>
        </div >
    )
}

export default HeaderSection