import { useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from "framer-motion"
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdPlayLesson } from 'react-icons/md'
import ToolTipView from 'components/tooltipView';
import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';

const AiQuizHeader = ({ onClick, children, isShowBtn = false, disable = false }) => {

    const navigate = useNavigate()
    const onHandleBack = useCallback(() => {
        navigate(-1)
    }, [navigate])

    return (
        <div className={"sticky top-0 z-50 h-16 bg-white flex items-center justify-between drop-shadow-sm px-5"}>
            <div className={"h-10 flex justify-center items-center gap-2"}>
                <ToolTipView content={"Back"}>
                    <button className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={onHandleBack}>
                        <IoMdArrowRoundBack
                            className={'text-back-ground-dark'}
                            size={25}
                        />
                    </button>
                </ToolTipView>
                <Link to={pagesInfo.TUTOR_PRODUCTS.pagePath + "/quizzes"} className={"flex items-center justify-center gap-2  group"}>
                    <MdPlayLesson
                        size={30}
                        className={"text-primary-dark group-hover:text-secondary-dark"}
                    />
                    <p className={'font-bodyPri font-normal text-lg md:text-2xl text-text-900 group-hover:text-secondary-dark group-hover:underline'}>
                        {"Quiz"}
                    </p>
                </Link>
            </div>
            {isShowBtn && (
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.3 }}
                    disabled={disable}
                    className={cn('flex items-center justify-center gap-2 py-2 px-5 text-sm md:text-base rounded ease-in-out duration-200 font-bodyPri shadow-sm',
                        disable ? "bg-white text-text-300 hover:bg-white cursor-wait" : "bg-primary-dark hover:bg-blue-900 text-white"
                    )}
                    onClick={onClick}
                >
                    {children}
                </motion.button>
            )}
        </div>
    )
}

export default AiQuizHeader