import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, Link } from "react-router-dom";

import { IoMdArrowRoundBack } from "react-icons/io";
import { MdPlayLesson } from 'react-icons/md';

import ToolTipView from 'components/tooltipView';

import TopTabNavigation from 'pages/auth/edulyteLms/commonComponents/navigations/TopTabNavigation';
import RefreshButton from 'pages/auth/edulyteLms/commonComponents/lmsButtons/RefreshButton';
import PublishBtn from 'pages/auth/edulyteLms/commonComponents/lmsButtons/PublishBtn';

import { lmsCourseTabConst } from '../../editLMSCourse.data';

import { getLmsCourseDetail, updateLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetLocalTabs } from 'redux/local/local.slice';
import { resetAddLmsCourseSectionDetail, resetAddLmsCourseSectionDetailPayload, setAddLmsCourseDetailPayload } from 'redux/edulyteLms/lmsCourse/lmsCourse.slice';
import { lmsCourseStatusEnum } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';
import { pagesInfo } from 'utils/pagesInfo';

const CourseHeader = () => {
    const { lmsCourseDetail, modifyLmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onHandleBack = useCallback(() => {
        navigate(-1)
    }, [])

    const onHandleRefreshCourse = () => {
        if (lmsCourseDetail?.isLoading) return;

        dispatcher(resetAddLmsCourseSectionDetail())
        dispatcher(resetAddLmsCourseSectionDetailPayload())
        dispatcher(resetLocalTabs())
        dispatcher(getLmsCourseDetail(lmsCourseDetail?.data?.id))
    }

    const onHandleUpdateStatus = (courseStatusType) => {
        if (lmsCourseDetail?.isLoading || modifyLmsCourseDetail?.isLoading) return;

        if (courseStatusType?.value === lmsCourseStatusEnum.DRAFT.value) {
            if (!window.confirm("Are you sure?. You want to save it as draft course.")) return;
        }
        if (courseStatusType?.value === lmsCourseStatusEnum.PUBLISHED.value) {
            if (!lmsCourseDetail?.data?.title) {
                toast.warn("Please add title to publish this course.")
                return;
            }
            if (!window.confirm("Are you sure?. You want to publish this course.")) return;
        }
        if (courseStatusType?.value === lmsCourseStatusEnum.INACTIVE.value) {
            if (!window.confirm("Are you sure?. You want to deactivate this course.")) return;
        }
        dispatcher(updateLmsCourseDetail(lmsCourseDetail?.data?.id, { status: courseStatusType?.value }))
    };

    return (
        <>
            <div className={"sticky top-0 z-50 h-16 bg-white flex items-center justify-between drop-shadow-sm px-5"}>
                <div className={"h-10 flex justify-center items-center gap-2"}>
                    <ToolTipView content={"Back"}>
                        <button className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={onHandleBack}>
                            <IoMdArrowRoundBack
                                className={'text-back-ground-dark'}
                                size={25}
                            />
                        </button>
                    </ToolTipView>
                    <Link to={pagesInfo.TUTOR_PRODUCTS.pagePath + "/courses"} className={"flex items-center justify-center gap-2 group"}>
                        <MdPlayLesson
                            size={30}
                            className={"text-primary-dark group-hover:text-secondary-dark"}
                        />
                        <p className={'font-bodyPri font-normal text-lg md:text-2xl text-text-900 group-hover:text-secondary-dark group-hover:underline'}>
                            {"Course"}
                        </p>
                    </Link>
                </div>
                <div className={"hidden md:flex items-center"}>
                    <TopTabNavigation tabs={Object.values(lmsCourseTabConst)} />
                </div>
                <div className={'flex items-center justify-start gap-5'}>
                    <RefreshButton onHandleRefreshButton={onHandleRefreshCourse} />
                    <PublishBtn
                        isLoading={modifyLmsCourseDetail?.isLoading}
                        loaderBtnClassName={`bg-${lmsCourseStatusEnum[lmsCourseDetail?.data?.course_setting?.status?.toUpperCase()]?.darkColor}`}
                        selectedBtnClassName={`text-white bg-${lmsCourseStatusEnum[lmsCourseDetail?.data?.course_setting?.status?.toUpperCase()]?.darkColor}`}
                        dropdownBtnClassName={`text-white bg-${lmsCourseStatusEnum[lmsCourseDetail?.data?.course_setting?.status?.toUpperCase()]?.darkColor}`}
                        optionsList={Object.values(lmsCourseStatusEnum)?.filter((statusItem) => (statusItem?.value != lmsCourseDetail?.data?.course_setting?.status))}
                        selectedOption={lmsCourseStatusEnum[lmsCourseDetail?.data?.course_setting?.status?.toUpperCase()]?.label}
                        onHandleUpdateStatus={onHandleUpdateStatus}
                    />
                </div>
            </div>
            <div className={"flex md:hidden justify-center items-center w-full rounded-lg"}>
                <TopTabNavigation tabs={Object.values(lmsCourseTabConst)} />
            </div>
        </>
    )
}

export default CourseHeader;