import { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { BsInfoCircleFill } from "react-icons/bs";

import CoursePublicFilters from 'pages/global/findCourses/components/courseFilter/CourseFilter';
import CourseCard from 'components/courseCard/CourseCard';
import CourseCardLoader from 'components/loader/CourseCardLoader';
import Pagination from 'components/pagination/Pagination';
import Footer1 from 'components/footer/footer1/Footer1';

import { PageContentStyle } from 'pages/global/findCourses/FindCourses.style';
import Header from 'pages/global/findCourses/components/Header';
import { pageHeading, data, getQueryString } from "pages/global/findCourses/data";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getAllCategoryList } from 'redux/category/category.request';
import { getMasterCountryList, getMasterLanguageList, getMasterProficiencyList } from 'redux/master/master.request';
import {
    getPublicCourseList,
    createCourseFavoriteDetail,
    deleteCourseFavoriteDetail,
} from "redux/course/course.request";

import { useTitle } from 'hooks/useTitle';
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearCategoryList } from 'redux/category/category.slice';
import { setClearCountryList, setClearLanguageList, setClearProfList } from 'redux/master/master.slice';
import { setClearFilterProps, setClearPublicCourseList, setFilterProps } from 'redux/course/course.slice';
import { DEFAULT_COURSE_PAGE, DEFAULT_COURSE_RECORDS, courseFilterProps, courseMessageType } from 'redux/course/course.const';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';
import { generateLocalWeekDays, getTimeZoneOffset, timeZone, dayjs } from "utils/dateTime.utils"

const FindCoursesPage = () => {
    const { locals } = useAppState((state) => state.local)
    const { user } = useAppState((s) => s.user)
    const { currencyRateList } = useAppState((state) => state.master)
    const { publicCourseList, addCourseFavDetail, deleteCourseFavDetail, filterProps } = useAppState((s) => s.course)
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [title, setTitle] = useTitle()


    const [dropDownButton, setDropDownButton] = useState(null)
    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.FIND_COURSES))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Face-to-Face Online Classes | Find Your Lesson | One-on-One & Group Classes | Edulyte",
        })
    }, [publicCourseList])

    useEffect(() => {
        dispatcher(getAllCategoryList())
        dispatcher(getMasterLanguageList())
        dispatcher(getMasterCountryList())
        dispatcher(getMasterProficiencyList())

        return () => {
            dispatcher(setClearCategoryList())
            dispatcher(setClearLanguageList())
            dispatcher(setClearCountryList())
            dispatcher(setClearProfList())
        }
    }, [])

    useEffect(() => {
        if (currencyRateList?.currencyRateList) {
            const queryObject = generateQueryParams(location.search)
            const category = params["*"]

            let targetCurrencyRate = currencyRateList?.currencyRateList[(locals?.currencyCode || "USD")]

            let timeZoneOffset = getTimeZoneOffset(timeZone)
            let localWeekDays = generateLocalWeekDays(timeZone)

            let queryParamPayload = {}
            let myFilterProps = { ...courseFilterProps }

            myFilterProps["page"] = queryObject?.pn || DEFAULT_COURSE_PAGE
            queryParamPayload["page"] = queryObject.pn || DEFAULT_COURSE_PAGE

            myFilterProps["records"] = DEFAULT_COURSE_RECORDS
            queryParamPayload["records"] = DEFAULT_COURSE_RECORDS

            if (queryObject?.search) {
                myFilterProps["search"] = queryObject?.search?.replaceAll("-", " ")?.toString()
                queryParamPayload["search"] = queryObject.search?.replaceAll("-", " ")?.toString()
            }
            if (queryObject?.sortBy) {
                myFilterProps["sortBy"] = queryObject?.sortBy
                queryParamPayload["sortBy"] = queryObject.sortBy
            }
            if (queryObject?.level) {
                myFilterProps["proficiency"] = queryObject?.level?.split(",")?.map((items) => items?.split("-")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))
                queryParamPayload["proficiency"] = queryObject?.level?.split(",")?.map((items) => items?.split("-")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
            }
            if (queryObject?.classType) {
                myFilterProps["courseType"] = queryObject?.classType?.split(",")
                queryParamPayload["type"] = queryObject?.classType?.toString()
            }
            if (queryObject?.speak) {
                myFilterProps["speak"] = queryObject?.speak?.replaceAll("-", " ")?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))
                queryParamPayload["speak"] = queryObject?.speak?.replaceAll("-", " ")?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
            }
            if (queryObject?.tutorFrom) {
                myFilterProps["tutorFrom"] = queryObject?.tutorFrom?.split(",")?.map((items) => items?.split("-")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))
                queryParamPayload["tutorFrom"] = queryObject?.tutorFrom?.split(",")?.map((item) => item?.split("-")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
            }
            if (queryObject?.day) {
                myFilterProps["day"] = queryObject?.day?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))
                let dayList = queryObject?.day?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
                queryParamPayload["day"] = dayList?.split(",")?.map((localDay) => dayjs(localWeekDays[localDay.toUpperCase()].date + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone)?.utc()?.format("dddd")?.toString())?.toString()
            }
            if (queryObject?.priceType) {
                myFilterProps["priceType"] = queryObject?.priceType?.replaceAll("-", " ")?.toString()
                queryParamPayload["priceType"] = queryObject.priceType?.replaceAll("-", " ")?.toString()
            }
            if (queryObject?.minPrice) {
                let minPrice = ((parseFloat(queryObject?.minPrice) || 0) / targetCurrencyRate) * 100
                myFilterProps["minPrice"] = queryObject?.minPrice
                queryParamPayload["minPrice"] = minPrice
            }
            if (queryObject?.maxPrice) {
                let maxPrice = ((parseFloat(queryObject?.maxPrice) || 0) / targetCurrencyRate) * 100
                myFilterProps["maxPrice"] = queryObject?.maxPrice
                queryParamPayload["maxPrice"] = maxPrice
            }
            if (queryObject?.segment) {
                queryParamPayload["segment"] = queryObject?.segment?.replaceAll("-", " ")?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
            }
            if (queryObject?.skill) {
                queryParamPayload["tag"] = queryObject?.skill?.replaceAll("-", " ")?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))?.toString()
            }
            if (category) {
                myFilterProps["category"] = category?.split("-")?.map((item) => item?.charAt(0)?.toUpperCase() + item.slice(1))?.join(' ')?.split(",")
                queryParamPayload["category"] = category?.replaceAll("-", " ")?.toString()
            }
            if (queryObject?.category) {
                let query = []
                if (!queryParamPayload["category"]) {
                    query = []
                } else {
                    query = queryParamPayload["category"]?.split(",")
                }
                queryParamPayload["category"] = [...query, ...queryObject?.category?.replaceAll("-", " ")?.split(",")?.map((items) => items?.split(" ")?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.join(" "))]?.toString()
            }

            dispatcher(getPublicCourseList(queryParamPayload))
            dispatcher(setFilterProps({ filterProps: myFilterProps }))
        }

        return () => {
            dispatcher(setClearPublicCourseList())
            dispatcher(setClearFilterProps())
        }

    }, [location.search, params, currencyRateList?.currencyRateList])

    window.onscroll = () => {
        var filterElement = document.getElementById('filter')
        if (filterElement.offsetTop > 0) {
            setIsSticky(true)
            // document.getElementById("dropdown").style.display = "none";
        } else {
            setIsSticky(false)
            // document.getElementById("dropdown").style.display = "block";
        }
    }

    const onHandlePageChange = async (page) => {
        const query = await getQueryString({ ...filterProps, page: page, records: DEFAULT_COURSE_RECORDS })
        navigate(`${pagesInfo?.FIND_COURSES?.pagePath}${query}`)
    }

    // [#FFFAFA]
    return (
        <PageContentStyle>
            <Header pageHeading={pageHeading} />
            <div className={'relative'}>
                <div id={"filter"} className={cn(
                    "sticky top-0 z-30 select-none -mt-10 lg:-mt-10 py-2",
                    "bg-primary-light shadow transition-all delay-20 duration-100 ease-in-out"
                    // isSticky && "bg-primary-light shadow transition-all delay-20 duration-100 ease-in-out"
                )}>
                    <CoursePublicFilters props={data} dropDownButton={dropDownButton} setDropDownButton={setDropDownButton} />
                </div>

                {/* Courses List */}
                <div className='flex justify-center items-center pb-5 mt-6'>
                    <div className='max-w-sm md:max-w-3xl lg:max-w-6xl w-full space-y-5'>
                        {publicCourseList?.data?.message &&
                            <div className={"w-full px-2 py-2 sm:py-4 flex items-center justify-center gap-3 bg-white rounded-lg"}>
                                {publicCourseList?.data?.message?.type === courseMessageType?.INFO?.value &&
                                    <span className={cn(`text-${courseMessageType?.INFO?.darkColor}`)}>
                                        <BsInfoCircleFill className={"text-xl sm:text-2xl"} />
                                    </span>
                                }
                                <span className={"font-bodyPri font-normal text-text-900 text-base lg:text-lg"}>
                                    {publicCourseList?.data?.message?.text}
                                </span>
                            </div>
                        }
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 lg:gap-5'>
                            {publicCourseList?.data?.results?.map((course, idx) => (
                                <div key={idx} className='col-span-full md:col-span-1 flex items-center justify-center'>
                                    <CourseCard
                                        course={course}
                                        isFav={user?.user?.favoriteCourses?.filter((favCourse) =>
                                            favCourse.courseId === course.id).length > 0 ? true : false}
                                        handleFav={(courseId) => {
                                            if (addCourseFavDetail?.isLoading || deleteCourseFavDetail?.isLoading) return;
                                            const favoriteCourses = user?.user?.favoriteCourses?.filter((favCourse) =>
                                                favCourse.courseId === courseId)
                                            if (favoriteCourses?.length > 0) {
                                                dispatcher(deleteCourseFavoriteDetail({ courseId: courseId, userId: user?.user?.userId }))
                                            }
                                            else {
                                                dispatcher(createCourseFavoriteDetail({ courseId: courseId, userId: user?.user?.userId }))
                                            }
                                        }}
                                    />
                                </div>
                            ))}
                            {new Array(DEFAULT_COURSE_RECORDS).fill("").map((_, index) => (<CourseCardLoader key={index} isLoading={publicCourseList?.isLoading} />))}
                        </div>
                        {(publicCourseList?.data?.records === 0 || publicCourseList.message) &&
                            <span className='w-full h-full col-span-10 text-center text-base font-bodyPri tracking-wide pt-5'>
                                {publicCourseList.message}
                            </span>
                        }
                        {(publicCourseList?.data && publicCourseList?.data?.totalPages > 1) && (
                            <div className="w-full flex justify-center items-center">
                                <Pagination
                                    page={publicCourseList?.data?.page}
                                    totalPages={publicCourseList?.data?.totalPages}
                                    onChangePage={(page) => onHandlePageChange(page)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer1 />
        </PageContentStyle>
    );
}

export default FindCoursesPage