import React from "react";
import bigStar from "./icons/bigStar.svg";
import smallStar from "./icons/smallStar.svg";
import { motion } from "framer-motion";

const CreateWithAiBtn = ({ title = "Create with AI", onHandleClickBtn }) => {
    return (
        <motion.button
            onClick={onHandleClickBtn}
            className={"flex items-center rounded-full px-4 py-1 shadow"}
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
            whileTap={{ scale: 0.95 }}
        >
            <motion.img
                src={bigStar}
                className="w-6 h-6"
                animate={{ x: [null, -10, 10, -10, 0] }}
                transition={{ ease: "linear", duration: 5, repeat: Infinity }}
            />
            <div className="space-y-1">
                <motion.img
                    src={smallStar}
                    className="w-3 h-3"
                    animate={({ x: [null, -5, 5, -5, 0] }, { scale: [null, -2, 2, -2, 0] })}
                    transition={{ ease: "linear", duration: 10, repeat: Infinity }}
                />
                <motion.img
                    src={smallStar}
                    className="w-3 h-3"
                    animate={{ scale: [null, -2, 2, -2, 0] }}
                    transition={{ ease: "linear", delay: 2, duration: 10, repeat: Infinity }}
                />
            </div>
            <span className="font-semibold text-back-ground-black ml-0.5 whitespace-nowrap">{title}</span>
        </motion.button>
    );
};

export default CreateWithAiBtn;