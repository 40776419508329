import { toast } from 'react-toastify';
import { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from "utils/cn.utils"

import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import ComponentLoader from "components/loader/ComponentLoader";

import { TutorPreferenceConst } from "pages/auth/tutorOnboard/TutorOnboardData";

import { getOnboardingTutorPrefDetailByOnboardingId, updateOnboardingTutorPrefDetailByOnboardingId } from 'redux/onboarding/onboarding.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearOnboardingTutorPrefDetail } from 'redux/onboarding/onboarding.slice';
import { setLocals } from "redux/local/local.slice";
import { localsConst } from 'redux/local/local.const';
import { userRoles } from 'redux/auth/auth.const';

const TutorPreferenceBox = () => {
    const { locals } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { onboardingTutorPrefDetail } = useAppState((state) => state.onboarding)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const { onboardingId } = useParams()

    useEffect(() => {
        if (onboardingId) {
            dispatcher(getOnboardingTutorPrefDetailByOnboardingId(Number(onboardingId)))
        }

        return () => {
            dispatcher(setClearOnboardingTutorPrefDetail())
        }
    }, [onboardingId])

    const handleSwitchRole = (role) => {
        if (!user?.user?.roles?.find((role) => role.role === userRoles?.TUTOR?.value)) {
            alert("You do not have the tutor role yet!")
            return;
        }

        if (role !== locals.userRole) {
            dispatcher(setLocals({
                ...locals,
                [localsConst.USER_ROLE.value]: role
            }))
        }
    }

    const onHandleUpdate = (preference, role) => {
        if (locals.userRole !== role) {
            toast.warn("Please switch to tutor role")
            return;
        }
        if (!onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value]) {
            dispatcher(updateOnboardingTutorPrefDetailByOnboardingId(
                Number(onboardingId),
                {
                    [preference?.value]: true
                }
            ))
        }
        navigate(preference?.link)
    }

    if (onboardingTutorPrefDetail?.isLoading) {
        return (
            <ComponentLoader isLoading={onboardingTutorPrefDetail?.isLoading} />
        )
    }

    return (
        <div className={"space-y-5 pl-10"}>
            {locals.userRole !== userRoles?.TUTOR?.value &&
                <div className={"flex justify-end"}>
                    <div
                        className={cn(
                            "flex items-center gap-1 rounded-lg px-2 py-1",
                            "cursor-pointer group bg-accent-light"
                        )}
                        onClick={() => handleSwitchRole(userRoles?.TUTOR?.value)}
                    >
                        <PublishedWithChangesIcon style={{ fontSize: "18px" }} className={"text-text-800 group-hover:text-secondary-main"} />
                        <span className={cn(
                            "font-bodyPri font-normal text-text-800 text-sm group-hover:text-secondary-main"
                        )}>
                            {"Switch to Tutor Role"}
                        </span>
                    </div>
                </div>
            }
            {Object?.values(TutorPreferenceConst)?.map((preference) => (
                <div className={"flex items-center justify-between gap-5 border border-text-300 rounded-lg px-5 py-3"}>
                    <div className={"flex justify-start items-center gap-5"}>
                        <span className={cn(
                            "w-4 h-4 rounded-full",
                            !onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value] && " border border-text-500 bg-text-50",
                            onboardingTutorPrefDetail?.onboardingTutorPrefDetail?.[preference?.value] && "bg-green-500"
                        )}>
                            {""}
                        </span>
                        <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                            {preference?.label}
                        </span>
                    </div>
                    <div
                        className={cn(
                            "rounded-md px-2 py-0.5 cursor-pointer",
                            "border border-primary-main bg-primary-main",
                            "font-bodyPri font-normal text-text-50 text-sm text-center hover:text-text-50 hover:bg-secondary-main hover:border hover:border-secondary-main"
                        )}
                        onClick={() => onHandleUpdate(preference, userRoles?.TUTOR?.value)}
                    >
                        {"Update"}
                    </div>

                </div>
            ))}
        </div>
    )
}

export default TutorPreferenceBox