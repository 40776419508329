import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

import format from 'string-format';

import { useStripe } from '@stripe/react-stripe-js';

import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';

import ButtonLoader from 'components/loader/button-loader';
import ToolTipView from 'components/tooltipView';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { createPaymentIntentDetail, createTokenThroughWalletDetail } from 'redux/payment/payment.request';
import { resetAddTokenThroughWalletDetail, setAddPaymentIntentDetailData } from 'redux/payment/payment.slice';

import { cn } from "utils/cn.utils";
import { gatewayProviderEnum } from 'redux/payment/payment.const';
import { createEdrazorpayOrder as addCreateEdrazorpayOrder } from 'redux/edrazorpay/edrazorpay.request';
import { resetCreateEdrazorpayOrder } from 'redux/edrazorpay/edrazorpay.slice';
import edrazorpayService from 'redux/edrazorpay/edrazorpay.service';


const paymentConfirmationUrl = "/token/payment/confirmation?payment_intent={payment_intent}&payment_intent_client_secret={payment_intent_client_secret}&redirect_status={redirect_status}";
const walletConfirmationUrl = "/token/confirmation?virtual_txn_id={virtual_txn_id}&redirect_status={redirect_status}";

const initialStateConfirmCardPayment = {
    isLoading: false,
    data: null,
    message: null
}

const initialDataState = {
    isLoading: false,
    data: null,
    message: null
}

const PaymentSummerySection = () => {
    const { addPaymentIntentDetail } = useAppState(s => s.payment)
    const { user } = useAppState(s => s.user)
    const { addTokenThroughWalletDetail } = useAppState((state) => state.payment)
    const { addTokens } = useAppState(s => s.wallet)
    const [checkoutEdrazorpay, setCheckoutEdrazorpay] = useState(initialDataState)
    const { createEdrazorpayOrder } = useAppState(s => s.edrazorpay)
    const [virtualTxnId, setVirtualTxnId] = useState(null);


    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const stripe = useStripe();

    const [confirmCardPayment, setConfirmCardPayment] = useState(initialStateConfirmCardPayment)

    useEffect(() => {
        if (addPaymentIntentDetail?.data) {
            let clientSecret = addPaymentIntentDetail?.data?.client_secret

            handlePayment({ clientSecret })

            dispatcher(setAddPaymentIntentDetailData(null))
        }
    }, [addPaymentIntentDetail?.data])

    const handlePayment = async ({ clientSecret }) => {
        setConfirmCardPayment({ ...confirmCardPayment, isLoading: true })
        try {
            const paymentIntentConfirmation = await stripe.confirmCardPayment(clientSecret, {
                payment_method: addPaymentIntentDetail?.payload?.paymentMethodId
            })
            if (paymentIntentConfirmation?.paymentIntent) {
                await handleNext(paymentIntentConfirmation?.paymentIntent)
            } else if (paymentIntentConfirmation?.error) {
                setConfirmCardPayment({ ...confirmCardPayment, message: paymentIntentConfirmation?.error?.message })
                toast.error(paymentIntentConfirmation?.error?.message)
            } else {
                throw new Error("Something went wrong!")
            }
        } catch (error) {
            console.error(error)
            setConfirmCardPayment({ ...confirmCardPayment, message: "Something went wrong!" })
        }
        setConfirmCardPayment({ ...confirmCardPayment, isLoading: false })
    }

    const handleNext = async (paymentIntent) => {
        const query = {
            payment_intent: paymentIntent.id,
            payment_intent_client_secret: paymentIntent.client_secret,
            redirect_status: paymentIntent.status
        }
        navigate(format(paymentConfirmationUrl, query), { replace: true })
    }


    useEffect(()=> {
        if (virtualTxnId) {
            const query = {
                virtual_txn_id: virtualTxnId,
                redirect_status: 'success',
            }
            dispatcher(resetAddTokenThroughWalletDetail())
            navigate(format(walletConfirmationUrl, query), { replace: true })
        }
    }, [virtualTxnId])

    useEffect(() => {
        if (addTokenThroughWalletDetail?.data) {
            const query = {
                virtual_txn_id: addTokenThroughWalletDetail?.data?.wallet_txn?.virtualTxnId,
                redirect_status: 'success',
            }
            dispatcher(resetAddTokenThroughWalletDetail())
            navigate(format(walletConfirmationUrl, query), { replace: true })
        }
    }, [addTokenThroughWalletDetail?.data])

    let payableAmount = 0
    let payButtonText = ""
    let isPayButtonEnable = false
    if (addTokens?.addTokenPayload?.payThroughGateWay) {
        payableAmount = addTokens?.addTokenPayload?.amount
        payButtonText = "Add & Pay"
        isPayButtonEnable = ( 
            addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.razorpay.key ||
            (addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.stripe.key && addTokens?.addTokenPayload?.paymentMethod && addTokens?.addTokenPayload?.amount)
        ) && !(
            addPaymentIntentDetail?.isLoading ||
            confirmCardPayment?.isLoading ||
            checkoutEdrazorpay?.isLoading
        )

    } else {
        payableAmount = addTokens?.addTokenPayload?.amount
        payButtonText = "Pay From Wallet"
        isPayButtonEnable = !addTokenThroughWalletDetail?.loading && addTokens?.addTokenPayload?.amount
    }

    const handlePay = () => {
        if (addTokenThroughWalletDetail?.isLoading || !isPayButtonEnable) return;

        if (addTokens?.addTokenPayload?.payThroughGateWay && addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.stripe.key) {
            let requestPayload = {
                email: addPaymentIntentDetail?.payload?.email,
                name: addPaymentIntentDetail?.payload?.name,
                currency: addPaymentIntentDetail?.payload?.currency,
                amount: addPaymentIntentDetail?.payload?.totalAmountToPay,
                description: addPaymentIntentDetail?.payload?.description,
                metadata: {
                    processingFee: addPaymentIntentDetail?.payload?.processingFee,
                    requiredAmount: addPaymentIntentDetail?.payload?.amountToPay,
                    paymentType: addPaymentIntentDetail?.payload?.paymentType,
                }
            }
            dispatcher(createPaymentIntentDetail(requestPayload))
        } else if (addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.razorpay.key) {
            let requestPayload = {
                body: {
                    currency: addPaymentIntentDetail?.payload?.currency?.toUpperCase(),
                    amount: addPaymentIntentDetail?.payload?.totalAmountToPay,
                    metadata: {
                        processingFee: addPaymentIntentDetail?.payload?.processingFee,
                        requiredAmount: addPaymentIntentDetail?.payload?.amountToPay,
                        orderType: addPaymentIntentDetail?.payload?.paymentType,
                    }
                }
            }
                dispatcher(addCreateEdrazorpayOrder(requestPayload)) 
            }
        else {
            const requestData = {
                currency_code: addTokens?.addTokenPayload?.currencyCode,
                amount: addTokens?.addTokenPayload?.amount,
                owner_type: "user",
                user_id: user?.user?.userId
            }
            dispatcher(createTokenThroughWalletDetail(requestData))
        }
    }

    useEffect(() => {
        if (createEdrazorpayOrder?.result) {
            let bookingId = createEdrazorpayOrder?.result?.order?.notes?.bookingId

            handleEdrazorpay({ checkout_option: createEdrazorpayOrder?.result?.checkout_option, bookingId })
            dispatcher(resetCreateEdrazorpayOrder())
        }
    }, [createEdrazorpayOrder?.result])

    const handleEdrazorpay = async ({ checkout_option}) => {
        setCheckoutEdrazorpay({ ...initialDataState, isLoading: true })
        try {
            const razorpayOptions = {
                    ...checkout_option,
                    modal: {
                        animation: true,
                        ondismiss: () => {
                            if (window.confirm("Are you sure, you want to close the form?")) {
                                setCheckoutEdrazorpay(initialDataState);
                            } else {
                                setCheckoutEdrazorpay(initialDataState);
                            }
                        }
                    },
                    handler: (response) => handleEdrazorpayNext(response)
                };
            const razorpayInstance = new window.Razorpay(razorpayOptions)
            razorpayInstance.on("payment.failed", (response) => {
                setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false, message: response?.error?.description })
            })
            razorpayInstance.open()
        } catch (error) {
            console.error(error)
            setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false, message: "Something went wrong!" })
        }
    }

    const handleEdrazorpayNext = async (response) => {
        const body = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
        };
        try {
            const response = await edrazorpayService.verifyEdrazorpayPayment({ body })
            if (response.status === 200) {
                setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false })
                setVirtualTxnId(response?.data?.data?.result?.wallet_virtual_txn_id)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            setCheckoutEdrazorpay({ ...checkoutEdrazorpay, error: error?.response.data.error || "Something went wrong!" })
            console.error(error?.response.data.error || error)
        }
        setCheckoutEdrazorpay({ ...checkoutEdrazorpay, isLoading: false })
    }


    return (
        <div className={"W-full bg-white shadow-all rounded-lg p-5 space-y-5"}>
            <div className={"w-full flex items-center justify-center"}>
                <span className={"text-center font-bodyPri font-semibold text-lg text-text-800"}>
                    {"Summary"}
                </span>
            </div>

            <div className={"space-y-1"}>
                <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                    <span>
                        {"Price:"}
                    </span>
                    <span>
                        {`${addTokens?.addTokenPayload?.currencyCode} ${addTokens?.addTokenPayload?.amount ? parseFloat(addTokens?.addTokenPayload?.amount / 100).toFixed(2) : "0.00"}`}
                    </span>
                </div>

                {(addTokens?.addTokenPayload?.amount !== 0) &&
                    <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>
                }

                <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                    <span>
                        {"Total:"}
                    </span>
                    <span>
                        {addTokens?.addTokenPayload?.currencyCode} {addTokens?.addTokenPayload?.amount ? parseFloat(addTokens?.addTokenPayload?.amount / 100).toFixed(2) : "0.00"}
                    </span>
                </div>

                {(addTokens?.addTokenPayload?.amount !== 0) &&
                    <div className={"h-0.5 bg-divider-darkLight w-full rounded-full"}></div>
                }

                <div className={"flex items-center justify-between font-bodyPri font-normal text-sm text-text-700"}>
                    <span>
                        {"Wallet used:"}
                    </span>
                    <span className={""}>
                        {addTokens?.addTokenPayload?.currencyCode} {!addTokens?.addTokenPayload?.payThroughGateWay
                            ? addTokens?.addTokenPayload?.amount
                                ? parseFloat(addTokens?.addTokenPayload?.amount / 100).toFixed(2)
                                : "0.00"
                            : "0.00"
                        }
                    </span>
                </div>

            </div>

            <div className={"flex flex-col"}>
                <div className={"w-full flex items-center justify-between gap-2"}>
                    <span className={"font-bodyPri font-medium text-md text-primary-dark"}>
                        {"Sub Total:"}
                    </span>
                    <span className={"font-bodyPri font-semibold text-md text-primary-dark"}>
                        {addTokens?.addTokenPayload?.currencyCode} {payableAmount ? parseFloat(payableAmount / 100).toFixed(2) : "0.00"}
                    </span>
                </div>
                {/* <div className={"w-full flex justify-end"}>
                    <span className={"whitespace-nowrap flex items-center gap-1 font-bodyPri font-normal text-sm text-text-800"}>
                        {"Approx"} {currencyRateConversion(locals?.currencyCode, payableAmount)}
                    </span>
                </div> */}
            </div>

            {addTokens?.addTokenPayload?.payThroughGateWay &&
                <div className={"w-full rounded-lg border-2 border-text-300 space-y-2 p-5 transition-all ease-in-out delay-100 duration-150"}>
                    <div className={"w-full flex items-center justify-center"}>
                        <span className={"font-bodyPri font-medium text-md text-text-800 text-center"}>
                            {"Card Payment"}
                        </span>
                    </div>
                    <div className={"flex items-center justify-between font-bodyPri font-normal text-base text-text-900"}>
                        <span>{"Amount:"}</span>
                        <span>
                            {addTokens?.addTokenPayload?.currencyCode} {addPaymentIntentDetail?.payload?.amountToPay ? parseFloat(addPaymentIntentDetail?.payload?.amountToPay / 100).toFixed(2) : "0.00"}
                        </span>
                    </div>
                    <div className={"flex items-center justify-between font-bodyPri font-normal text-base text-text-900"}>
                        <div className={"flex items-center justify-start gap-1"}>
                            <span>{"Processing fee:"}</span>
                            <ToolTipView content={"Processing fees are charged by the third party payment providers, we recommend purchasing more tokens at one time to reduce the processing fee."}>
                                <button className={""}>
                                    <AiOutlineQuestionCircle className={"text-text-700"} />
                                </button>
                            </ToolTipView>
                        </div>
                        <span className={""}>
                            {addTokens?.addTokenPayload?.currencyCode} {addPaymentIntentDetail?.payload?.processingFee ? parseFloat(addPaymentIntentDetail?.payload?.processingFee / 100).toFixed(2) : "0.00"}
                        </span>
                    </div>
                    <div className={"flex item-start justify-between font-bodyPri font-normal text-base text-text-900"}>
                        <span className={""}>
                            {"Total amount:"}
                        </span>
                        <div className={"flex flex-col items-end justify-start"}>
                            <span className={""}>
                                {addTokens?.addTokenPayload?.currencyCode} {addPaymentIntentDetail?.payload?.totalAmountToPay ? parseFloat(addPaymentIntentDetail?.payload?.totalAmountToPay / 100).toFixed(2) : "0.00"}
                            </span>
                            {/* <span className={"text-sm text-text-800"}>
                                {"Approx"} {currencyRateConversion(locals?.currencyCode, addPaymentIntentDetail?.payload?.totalAmountToPay)}
                            </span> */}
                        </div>
                    </div>
                </div>
            }

            <ToolTipView
                content={"Please check payment method"}
                disabled={isPayButtonEnable}
            >
                <div
                    className={cn(
                        "w-full px-4 py-2 rounded-md bg-secondary-dark hover:opacity-90 cursor-pointer",
                        "text-center font-buttons font-medium text-base text-text-50 whitespace-nowrap",
                        !isPayButtonEnable && "!bg-secondary-light !cursor-not-allowed"
                    )}
                    onClick={handlePay}
                >
                    {addTokenThroughWalletDetail?.isLoading &&
                        <ButtonLoader isLoading={addTokenThroughWalletDetail?.isLoading} />
                    }
                    {!addTokenThroughWalletDetail?.isLoading &&
                        <span>
                            {payButtonText}
                        </span>
                    }
                </div>
            </ToolTipView>
            {(addPaymentIntentDetail?.isLoading || confirmCardPayment?.isLoading) &&
                <div className={cn(
                    "w-full font-bodyPri font-medium text-base text-text-800 text-start flex flex-row gap-1 items-center justify-center"
                )}>
                    <span>{"Please wait. Loading..."}</span>
                    <FaSpinner className={"text-lg text-primary-dark animate-spin"} />
                </div>
            }
            {(addPaymentIntentDetail?.message || confirmCardPayment?.message) &&
                <div className={cn(
                    "w-full font-bodyPri font-normal text-xs text-red-500 text-center"
                )}>
                    <span>{addPaymentIntentDetail?.message || confirmCardPayment?.message}</span>
                </div>
            }
        </div>
    )
}

export default PaymentSummerySection;