import tw, { styled } from "twin.macro";

export const StyledChatViewSendMessage = styled.div`
  ${tw`flex items-center gap-3 absolute bottom-0 left-0 w-full px-2 mb-2.5 z-10`}
`;

export const StyledChatViewSendMessageInput = styled.input(
  ({ textMessageError }) => [
    tw`
      relative outline-none border-2 border-gray-300 rounded-md p-2 w-full
      focus:(border-divider-darkLight shadow-[0 0 5px 1px rgba(0, 0, 0, 0.1)] bg-back-ground-light)
      hover:(border-divider-darkLight shadow-[0 0 5px 1px rgba(0, 0, 0, 0.1)] bg-back-ground-light)
    `,
    textMessageError && tw`border-secondary-dark focus:(border-2) hover:(border-2)`,
  ]
);

export const StyledChatViewSendMessageTextArea = styled.textarea(
  ({ textMessageError }) => [
    tw`
      relative outline-none border-2 border-gray-300 rounded-md p-2 w-full resize-none overflow-auto
      scrollbar scrollbar-track-back-ground-light scrollbar-thumb-rounded-full
      hover:scrollbar-thumb-divider-lightDark
      focus:(border-divider-medium shadow-[0 0 5px 1px rgba(0, 0, 0, 0.1)] bg-back-ground-light)
      hover:(border-divider-medium shadow-[0 0 5px 1px rgba(0, 0, 0, 0.1)] bg-back-ground-light)
    `,
    textMessageError && tw`border-secondary-dark focus:(border-2) hover:(border-2)`,
  ]
);

export const StyledChatViewSendMessageTyping = styled.p`
  ${tw`absolute bottom[100%] z-50 text-xs font-semibold pl-0.5 text-gray-400`}
`;

export const StyledChatViewSendMessageAttachInput = styled.input`
  ${tw`hidden height[0px] width[0px]`}
`;

export const StyledChatViewSendMessageAttachLabel = styled.label`
  ${tw`w-[fit-content] flex cursor-pointer`}
`;
