import { useState } from "react";
import { toast } from "react-toastify";

import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FiMessageSquare } from 'react-icons/fi';
import { IoIosArrowForward } from 'react-icons/io';
import { MdContentCopy } from 'react-icons/md';
import { FacebookMessengerIcon, WhatsappIcon } from "react-share";

import { useAppState } from 'hooks/useStore';
import { cn } from "utils/cn.utils";

const ManualMobileVerificationModal = () => {
    const { user } = useAppState((state) => state.user)

    const [isURLCopied, setIsURLCopied] = useState(false)

    const smsPhoneNumber = "+61480080151";
    const phoneNumber = "61480080151";
    const message = `Please verify my phone:

my registered email is: ${user?.user?.email}`;

    // const whatsappUrl = `send?phone=${phoneNumber}?text=${encodeURIComponent(message)}`
    const testNewLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`

    const onHandleCopyText = () => {
        navigator.clipboard.writeText(message);
        setIsURLCopied(true);
        toast.success("Copied!")
        setTimeout(() => {
            setIsURLCopied(false);
        }, 2000);
    }

    const onHandleOpenWhatsapp = () => {
        const windowWidth = 600;
        const windowHeight = 300;
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;

        const leftPosition = (screenWidth - windowWidth) / 2;
        const topPosition = (screenHeight - windowHeight) / 2;

        window.open(testNewLink,
            "_blank",
            `toolbar=yes,scrollbars=yes,resizable=yes,top=${topPosition},left=${leftPosition},width=${windowWidth},height=${windowHeight}`
        )
    }

    return (
        <div className={"w-full space-y-5 overflow-x-hidden"}>
            <div className={'w-full min-h-[32rem] space-y-5 pt-5 flex justify-center'}>
                <div className={"w-full md:w-[32rem] space-y-3"}>
                    <div className={"w-full p-3 md:p-5 bg-orange-200 rounded-lg flex items-center justify-center"}>
                        <span className={"w-full font-bodyPri font-normal text-text-900 text-base tracking-wide text-center"}>
                            {"Sorry, we are unable to send code to this number."}
                        </span>
                    </div>
                    <div className={"w-full p-3 md:p-5 bg-green-200 rounded-lg flex flex-col items-center justify-center"}>
                        <span className={"w-full font-bodyPri font-bold text-green-800 text-xl tracking-wide text-center"}>
                            {"Solution:"}
                        </span>
                        <span className={"w-full font-bodyPri font-normal text-green-800 text-base tracking-wide text-center"}>
                            {"Verify by texting or using WhatsApp to +61 480 080 151 from the phone you want to link"}
                        </span>
                    </div>
                    <div className={"w-full h-96 flex flex-col items-start justify-between gap-3 border-2 border-divider-darkLight shadow shadow-back-ground-lightDark rounded-lg"}>
                        <div className={"w-full flex flex-col space-y-3"}>
                            <div className={"w-full p-3 bg-divider-light border-b border-divider-medium flex items-center justify-start gap-3"}>
                                <div className={"w-full flex items-center justify-center"}>
                                    <span className={"w-full font-bodyPri font-medium text-text-900 text-base tracking-wide text-center"}>
                                        {"New Message"}
                                    </span>
                                </div>
                                <div className={"flex justify-end"}>
                                    <span className={"font-bodyPri font-normal text-primary-main text-base tracking-wide"}>
                                        {"Cancel"}
                                    </span>
                                </div>
                            </div>
                            <div className={"flex items-center justify-start gap-2 px-5 py-2 border-b border-divider-medium"}>
                                <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                                    {"To:"}
                                </span>
                                <span className={"font-bodyPri font-normal text-green-500 text-base tracking--wide"}>
                                    {"+61 480 080 151"}
                                </span>
                            </div>
                        </div>
                        <div className={"w-full px-2 md:px-5 py-1 flex items-end justify-between gap-3"}>
                            <div className={"hidden md:block p-1.5 bg-divider-lightDark rounded-lg"}>
                                <IoIosArrowForward className={"text-lg text-text-50"} />
                            </div>
                            <div className={"w-full p-3 border border-divider-medium rounded-lg space-y-5"}>
                                <span className={"font-bodyPri font-normal text-text-900 text-sm tracking-wide"}>
                                    {"Please verify my phone."}
                                </span>
                                <div className={"flex flex-col items-start justify-start space-y-1"}>
                                    <span className={"font-bodyPri font-normal text-text-900 text-sm tracking-wide"}>
                                        {"My registered email:"}
                                    </span>
                                    <span className={"font-bodyPri font-medium text-text-900 text-sm tracking-wide"}>
                                        {user?.user?.email}
                                    </span>
                                </div>
                                <div className={"flex items-center justify-between gap-5"}>
                                    <span className={"font-bodyPri font-normal text-primary-main text-lg"}>
                                        {"|"}
                                    </span>
                                    <span
                                        className={"w-[2rem] h-[2rem] flex items-center justify-center bg-primary-dark cursor-pointer rounded-lg"}
                                        onClick={onHandleCopyText}
                                    >
                                        {!isURLCopied
                                            ? <MdContentCopy className={"text-2xl text-text-50"} />
                                            : <AiOutlineCheckCircle className={"text-2xl text-text-50"} />
                                        }
                                    </span>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className={"w-full p-2 border-2 border-divider-darkLight bg-divider-darkLight rounded-lg flex flex-col items-center justify-center md:flex-row md:items-center md:justify-center gap-5"}>
                        <div
                            className={cn(
                                "relative has-tooltip border-2 border-divider-lightDark bg-text-100 rounded-lg w-10/12 md:w-5/12 px-12 py-3 flex flex-col items-center justify-center gap-2",
                                "rounded-xl text-primary-dark hover:border-primary-dark hover:bg-primary-light cursor-pointer group",
                            )}
                            onClick={onHandleOpenWhatsapp}
                        >
                            <div className={""}>
                                <WhatsappIcon size={50} />
                            </div>
                            <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide group-hover:text-primary-dark"}>
                                {"WhatsApp"}
                            </span>
                            <span className={cn(
                                'tooltip w-fit px-2 py-1 bg-black text-white rounded-lg',
                                "font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap",
                                "-translate-x-1 -translate-y-16"
                            )}>
                                {"WhatsApp to +61 480 080 151"}
                            </span>
                        </div>
                        <a
                            // href={`sms:${phoneNumber}&body=${encodeURIComponent(message)}`}
                            href={`sms:${smsPhoneNumber}`}
                            className={cn(
                                "relative has-tooltip border-2 border-divider-lightDark bg-text-100 rounded-lg px-12 py-3 w-10/12 md:w-5/12 flex flex-col items-center justify-center gap-2 focus:outline-none",
                                "rounded-xl text-primary-dark hover:border-primary-dark hover:bg-primary-light group",
                            )}>
                            <div className={""}>
                                <FiMessageSquare size={50} className={"text-[#25D366]"} />
                            </div>
                            <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide whitespace-nowrap group-hover:text-primary-dark"}>
                                {"Text Message"}
                            </span>

                            <span className={cn(
                                'tooltip w-fit px-2 py-1 bg-black text-white rounded-lg',
                                "font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap",
                                "-translate-x-1 -translate-y-16"
                            )}>
                                {"Text Message to +61 480 080 151"}
                            </span>
                        </a>
                    </div>
                    <div className={"w-full flex items-center justify-center"}>
                        <span className={"w-full font-bodyPri font-normal text-text-700 text-base tracking-wide text-center"}>
                            {"Allow 1-24 hours for validation. You'll get confirmation"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManualMobileVerificationModal