import { memo } from "react";

import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineArrowForwardIos } from 'react-icons/md';

import { searchParamsInfo } from "pages/auth/editAppointment/data";

import { useAppState } from "hooks/useStore";
import { appointmentPriceModelEnum } from "redux/appointment/appointment.const";

const ViewPriceDetail = memo(({ onHandleActiveSection }) => {
    const { modifyAppointmentDetail } = useAppState((state) => state.appointment)

    return (
        <div
            className={"w-full p-5 h-32 hover:bg-back-ground-lightBlue font-bodyPri cursor-pointer"}
            onClick={() => onHandleActiveSection(searchParamsInfo.action.key, searchParamsInfo.price.key)}
        >
            <div className={"w-full flex items-center justify-between gap-3"}>
                <div className={"flex items-start justify-start gap-3"}>
                    <IoPricetagsOutline className={"text-2xl text-text-600"} />
                    <div className={"flex flex-col gap-3"}>
                        <span className={"font-medium text-text-900 text-md"}>
                            {"Price Details"}
                        </span>
                        <div className={"space-y-1"}>
                            {(modifyAppointmentDetail?.payload?.price_model === appointmentPriceModelEnum.FLAT_FEE.value) && (
                                <div className={'space-y-1'}>
                                    <span className={'font-bodyPri font-normal text-base text-text-700'}>
                                        {modifyAppointmentDetail?.payload?.masterCurrency?.code + " " + parseFloat((modifyAppointmentDetail?.payload?.price - ((modifyAppointmentDetail?.payload?.price * modifyAppointmentDetail?.payload?.discount_pct) / 100)) / 100).toFixed(2)}
                                    </span>
                                    {((modifyAppointmentDetail?.payload?.discount_pct > 0) && (modifyAppointmentDetail?.payload?.discount_pct < 100)) && (
                                        <span className='flex flex-row items-start justify-start gap-2'>
                                            <del className='font-bodyPri font-normal text-base text-text-700'>
                                                {modifyAppointmentDetail?.payload?.masterCurrency?.code + " " + parseFloat(modifyAppointmentDetail?.payload?.price / 100).toFixed(2)}
                                            </del>
                                            <span className={"font-bodyPri font-normal text-base text-green-500"}>
                                                {modifyAppointmentDetail?.payload?.discount_pct + "% off"}
                                            </span>
                                        </span>
                                    )}
                                </div>
                            )}
                            {(modifyAppointmentDetail?.payload?.price_model === appointmentPriceModelEnum.FREE.value) && (
                                <div className='space-y-1'>
                                    <span className={'font-bodyPri font-normal text-base text-text-700'}>
                                        {appointmentPriceModelEnum.FREE.label}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <MdOutlineArrowForwardIos className={"text-3xl text-text-600"} />
            </div>
        </div>
    )
})

export default ViewPriceDetail;