import { useEffect, useCallback } from "react";

import { motion, AnimatePresence } from "framer-motion";

import PageLoader from "components/loader/PageLoader";

import Drag from "../commonComponents/dragAndDrop/Drag";
import CrmContactCardView from "pages/auth/crm/commonComponents/CrmContactCardView";

import { getCrmContactStatusList, updateCrmContactDetail } from "redux/crm/crm.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetCrmContactList, resetCrmContactStatusList } from "redux/crm/crm.slice";
import { crmContactCategoryEnum } from "redux/crm/crm.const";

const CrmContactsTileView = () => {
    const { crmContactStatusList } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(getCrmContactStatusList({ category: crmContactCategoryEnum.CONTACT.value }))

        return () => {
            dispatcher(resetCrmContactStatusList())
            dispatcher(resetCrmContactList())
        }
    }, [])

    const onHandleDrop = useCallback(({ dragItem, dragType, status }) => {
        if (dragType === "card") {
            if (!dragItem?.status?.key || !status?.key || (dragItem?.status?.key === status?.key)) return;

            dispatcher(updateCrmContactDetail(dragItem?.id, { status: status?.key }))
        }
    }, [crmContactStatusList?.data])

    if (crmContactStatusList?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (crmContactStatusList?.message) {
        return (
            <div className={"w-full h-full p-5 bg-white flex items-center justify-center"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base"}>
                    {crmContactStatusList?.message}
                </span>
            </div>
        )
    }

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className={"space-y-5"}
            >
                {!!crmContactStatusList?.data?.length &&
                    <Drag handleDrop={onHandleDrop}>
                        {({ activeItem, activeType, isDragging }) => (
                            <Drag.DropZone className={"flex gap-3 overflow-hidden overflow-x-auto h-full"}>
                                {crmContactStatusList?.data?.map((contactStatus, index) => {
                                    return (
                                        <div key={index} className={""}>
                                            <CrmContactCardView
                                                key={index}
                                                category={crmContactCategoryEnum.CONTACT.value}
                                                contactStatus={contactStatus}
                                                buttonTitle={"Add Contact"}
                                                listIndex={index}
                                                activeItem={activeItem}
                                                activeType={activeType}
                                                isDragging={isDragging}
                                            />
                                        </div>
                                    )
                                })}
                            </Drag.DropZone>
                        )}
                    </Drag>
                }
            </motion.div>
        </AnimatePresence>
    )
}

export default CrmContactsTileView;