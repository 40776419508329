import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { AiOutlineRight } from "react-icons/ai";
import { ImCross } from "react-icons/im";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import PageLoader from "components/loader/PageLoader";

import CreateCourseTooltip from "pages/auth/createCourse/CreateCourseTooltip";
import { classTemplateTooltip, classTypeTooltip } from "pages/auth/createCourse/data";

import { getCourseTemplateDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearCourseTemplateDetail } from "redux/course/course.slice";
import { courseType } from "redux/course/course.const";

import { pagesInfo } from "utils/pagesInfo";

const SelectTemplatePage = () => {
    const { tutorCourseDetail, courseTemplateDetail } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (tutorCourseDetail?.data) {
            dispatcher(getCourseTemplateDetail(tutorCourseDetail?.data?.courseTemplate))
        }

        return () => {
            dispatcher(setClearCourseTemplateDetail())
        }
    }, [tutorCourseDetail?.data])

    if (tutorCourseDetail?.isLoading || courseTemplateDetail?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (courseTemplateDetail?.errorMsg) {
        return (
            <div className={"w-full h-full flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                {courseTemplateDetail?.errorMsg}
            </div>
        )
    }

    return (
        <>
            <Modal
                className={"relative bg-black w-3/4 min-h-screen overflow-hidden self-center justify-self-center mx-auto my-auto border border-white"}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={cn(
                    "absolute inset-0 w-full min-h-[24rem] rounded-lg overflow-y-scroll bg-white focus:outline-none",
                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                )}>
                    <div className={""}>
                        <img
                            src={courseTemplateDetail?.courseTemplateDetail?.previewPicUrl}
                            className={"w-full h-full object-cover"}
                            alt={'template-preview'}
                        />
                    </div>
                    <span className={"fixed top-0 sm:top-3 right-2 sm:right-12 md:right-24 lg:right-48 flex justify-end bg-white p-1 rounded-lg"}>
                        <ImCross
                            onClick={handleClose}
                            className={"text-2xl text-divider-dark hover:text-text-900 cursor-pointer"}
                        />
                    </span>
                </Box>
            </Modal>

            <div className={cn(
                "w-full h-full bg-white rounded-lg select-none"
            )}>
                <div className={"w-full px-5 py-3 bg-back-ground-darkLight rounded-lg"}>

                    <span className={"font-bodyPri font-medium text-xl text-text-900"}>
                        {"Class Template"}
                    </span>
                </div>

                <div className={"w-full min-h-[60vh] flex flex-col items-center justify-center gap-5"}>

                    {/* class type */}
                    <div className={"w-full flex items-center justify-center gap-3"}>
                        <div className={"flex items-center justify-start gap-1"}>
                            <CreateCourseTooltip tooltip={classTypeTooltip} />
                            <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                                {"Class Type:"}
                            </span>
                        </div>
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {courseType[courseTemplateDetail?.courseTemplateDetail?.type?.toUpperCase()]?.label}
                        </span>
                    </div>

                    {/* template title */}
                    <div className={"w-full flex items-center justify-center gap-3"}>
                        <div className={"flex items-center justify-start gap-1"}>
                            <CreateCourseTooltip tooltip={classTemplateTooltip} />
                            <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                                {"Template Title:"}
                            </span>
                        </div>
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {courseTemplateDetail?.courseTemplateDetail?.title}
                        </span>
                    </div>

                    <div className={cn(
                        "mx-auto w-full md:w-[25%] relative flex flex-col items-center justify-center rounded-lg shadow-lg bg-black aspect-video cursor-pointer",
                        "hover:scale-105 hover:transition duration-200 hover:shadow-lg",
                        "ring-offset-2 ring-4 ring-white"
                    )}>
                        <img
                            src={courseTemplateDetail?.courseTemplateDetail?.thumbnailPicUrl}
                            className="object-cover w-full h-full rounded-lg opacity-90"
                            alt="template-thumbnail"
                        />
                        <div
                            className={"absolute w-full h-full rounded-lg overflow-hidden flex justify-center items-center"}>
                            <span
                                className={"bg-black bg-opacity-50 px-4 py-2 rounded-sm text-text-50 font-bodyPri font-normal text-base"}
                                onClick={handleOpen}
                            >
                                {"Preview"}
                            </span>
                        </div>
                    </div>

                    <div className={"w-full flex items-center justify-center gap-1.5"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                            {"Go to"}
                        </span>
                        <AiOutlineRight className={"text-md text-text-900"} />
                        <Link
                            to={pagesInfo?.TEMPLATE?.pagePath}
                            className={"font-bodyPri font-normal text-primary-dark text-sm tracking-wide hover:underline"}
                        >
                            {"All Templates,"}
                        </Link>
                        <Link
                            to={`${pagesInfo.TUTOR_PRODUCTS.pagePath}/live-classes`}
                            className={"font-bodyPri font-normal text-primary-dark text-sm tracking-wide hover:underline"}
                        >
                            {"Your classes,"}
                        </Link>
                        <Link
                            to={pagesInfo?.DASHBOARD?.pagePath}
                            className={"font-bodyPri font-normal text-primary-dark text-sm tracking-wide hover:underline"}
                        >
                            {"Dashboard"}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectTemplatePage;