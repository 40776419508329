import { useEffect, useState } from "react";
import { cn } from "utils/cn.utils";

import { FiEdit } from "react-icons/fi";
import { MdAdd } from "react-icons/md";

import { companyEditBtn } from "pages/auth/editCompany/data";
import EdiPeopleCard from "pages/auth/editCompany/components/editPeople/EditPeopleCard";

import { getEmployeeListByCompanyId } from "redux/company/company.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { setClearEmployeeList, setEmployeeDetailPayload } from "redux/company/company.slice";

const itemsPerPage = 4
const profilePictureUrl = "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw"

const EditPeople = ({ editBtnConst, setEditBtnConst }) => {
    const { modal } = useAppState((state) => state.local)
    const { companyPublicProfile, employeeList } = useAppState((state) => state.company)

    const dispatcher = useAppDispatcher()

    const [activePage, setActivePage] = useState(1)

    useEffect(() => {
        if (companyPublicProfile?.companyPublicProfile?.companyId)
            dispatcher(getEmployeeListByCompanyId({ activePage: activePage, itemsPerPage: itemsPerPage }, companyPublicProfile?.companyPublicProfile?.companyId))

        return () => {
            dispatcher(setClearEmployeeList())
        }
    }, [activePage, companyPublicProfile?.companyPublicProfile?.companyId])

    const handleAddPeople = () => {
        dispatcher(setEmployeeDetailPayload({
            employeeId: null,
            firstName: "",
            lastName: "",
            profilePicUrl: profilePictureUrl,
            about: "",
            designation: ""
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.COMPANY_PEOPLE_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"PEOPLE"}>
            <div className={"relative max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"space-y-3 p-5 bg-white"}>
                    <div className={"flex justify-start items-center gap-5"}>
                        <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                            {"People"}
                        </span>
                        {!editBtnConst &&
                            <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                                onClick={() => setEditBtnConst(companyEditBtn.People.value)}
                            />
                        }
                        {editBtnConst === companyEditBtn.People.value &&
                            <div className={"flex justify-center items-center gap-5"}>
                                <div
                                    className={cn(
                                        "flex item-center justify-center px-2 py-0.5 rounded-md cursor-pointer",
                                        "font-buttons font-normal text-base text-text-50 bg-primary-main",
                                        "hover:bg-primary-dark"
                                    )}
                                    onClick={handleAddPeople}
                                >
                                    <MdAdd className={"text-xl"} />
                                    <span className={"whitespace-nowrap"}>
                                        {"Add People"}
                                    </span>
                                </div>
                                <div
                                    className={cn(
                                        "flex item-center justify-center px-2 py-0.5 rounded-md cursor-pointer",
                                        "font-buttons font-normal text-base text-text-800 bg-back-ground-darkLight",
                                        "hover:bg-back-ground-medium hover:text-text-900"
                                    )}
                                    onClick={() => setEditBtnConst(null)}
                                >
                                    <span className={""}>
                                        {"Cancel"}
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={cn(
                        "w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-8 p-5"
                    )}>
                        {employeeList?.employeeList?.list?.map((employee, index) => (
                            <div className={"col-span-1"}>
                                <EdiPeopleCard
                                    key={index}
                                    employee={employee}
                                    editBtnConst={editBtnConst}
                                />
                            </div>
                        ))}
                    </div>
                    {employeeList?.employeeList?.totalPages !== activePage &&
                        <div
                            className={cn(
                                "w-full flex justify-center items-center border border-text-500 p-2 rounded",
                                "font-bodyPri font-medium text-text-800 text-base cursor-pointer",
                                "hover:bg-back-ground-medium"
                            )}
                            onClick={() => setActivePage(activePage + 1)}
                        >
                            {"Show more"}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EditPeople;