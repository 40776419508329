import { cn } from "utils/cn.utils";
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import PageLoader from "components/loader/PageLoader";

import "pages/auth/editCourse/Components/sticky.css";
import { PageContentStyle } from "pages/auth/editCourse/EditCoursePage.style";

import { CategorySelectionModal } from "pages/auth/editCourse/Components/CategorySelectionModal/CategorySelectionModal";
import ProgressIndicatorV2 from "pages/auth/editCourse/Components/ProgressIndicatorV2";
import SubmitFormBar from "pages/auth/editCourse/Components/SubmitFormBar";
import { SegmentCategoryView } from "pages/auth/editCourse/Components/CategorySelectionModal/SegmentCategoryView";
import TitleEditor from "pages/auth/editCourse/Components/TitleEditor";
import { AuthorProfileSection } from "pages/auth/editCourse/Components/AuthorProfileSection";
import HorizontalStickyCard from "./Components/HorizontalStickyCard";
import VerticalStickyCard from "pages/auth/editCourse/Components/VerticalStickyCard";
import EditCourseImageBar from "pages/auth/editCourse/Components/editCourseImageBar/EditCourseImageBar";
import { SkillsSection } from "pages/auth/editCourse/Components/SkillsSection";
import { CourseDescriptionEditor } from "pages/auth/editCourse/Components/CourseDescriptionEditor";
import SelectThumbnails from 'pages/auth/editCourse/Components/SelectThumbnails';

import { pagesInfo } from 'utils/pagesInfo';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getOfferingByCourseId } from "redux/offering/offering.request";
import { updateOfferingByCourseId } from "redux/offering/offering.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearUploadDocument } from "redux/document/document.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { setClearOfferingCourse } from 'redux/offering/offering.slice';
import { offeringStatus } from 'redux/offering/offering.const';

import { useTitle } from "hooks/useTitle";

export default function CreatePageEditor() {
  const { modal } = useAppState((state) => state.local)
  const { uploadDocument } = useAppState((state) => state.document)
  const { offeringCourse, addOfferingCourse } = useAppState(s => s.offering)
  const { currentPageInfo } = useAppState(s => s.pageInfo)

  const [showSegmentSelectionModal, setShowSegmentSelectionModal] = useState(false);

  const { courseId } = useParams()
  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const [title, setTitle] = useTitle()

  const [editBtn, setEditBtn] = useState(null)


  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.EDIT_COURSE))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    setTitle({
      ...title,
      title: "Draft Class | Work in Progress | Edulyte"
    })
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    if (courseId) {
      dispatcher(getOfferingByCourseId(courseId))
    }

    return () => {
      dispatcher(setClearOfferingCourse())
    }
  }, [courseId])

  useEffect(() => {
    if (offeringCourse?.offeringCourse && [offeringStatus.UNDER_REVIEW.value, offeringStatus.PUBLISHED.value].includes(offeringCourse?.offeringCourse?.status)) {
      navigate(`${pagesInfo.TUTOR_PRODUCTS.pagePath}/live-classes`, { replace: true })
    }
  }, [offeringCourse?.offeringCourse])

  useEffect(() => {
    if (uploadDocument?.uploadDocument && uploadDocument?.uploadDocumentPayload?.fileCategory === "intro_video") {
      dispatcher(updateOfferingByCourseId(offeringCourse?.offeringCourse?.courseId,
        {
          courseType: offeringCourse?.offeringCourse?.courseType,
          videoUrl: uploadDocument?.uploadDocument?.documentUrl
        }
      ))
      dispatcher(setModal({
        ...modal,
        [modalConst.UPLOAD_VIDEO_MODAL.stateKey]: false,
        [modalConst.RECORD_VIDEO_MODAL.stateKey]: false
      }))
      dispatcher(setClearUploadDocument())
    }

  }, [uploadDocument?.uploadDocument])

  if (offeringCourse?.isLoading || addOfferingCourse?.isLoading) {
    return <PageLoader />
  }

  if (offeringCourse?.errorMsg) {
    return (
      <div className={"w-screen h-screen flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
        {offeringCourse?.errorMsg}
      </div>
    )
  }

  return (
    <PageContentStyle>
      {showSegmentSelectionModal &&
        <CategorySelectionModal
          show={showSegmentSelectionModal}
          onClose={() => setShowSegmentSelectionModal(false)}
        />
      }
      <div className="flex items-center justify-center py-2">
        <ProgressIndicatorV2 />
      </div>
      <div className="relative">
        <SubmitFormBar editBtn={editBtn} />

        <div className="">
          <div className="w-full bg-back-ground-dark pt-10 pb-8">
            {/* Course Header */}
            <div className="relative max-w-5xl px-4 mx-auto">
              <div className="max-w-2xl pr-4 mx-auto lg:mx-0 space-y-5">
                <div className={"flex justify-start items-center gap-2"}>
                  <span className={"font-bodyPri font-normal text-text-50 text-sm tracking-wide"}>
                    {"Class ID:"}
                  </span>
                  <span className={"font-bodyPri font-medium text-base text-text-50"}>
                    {offeringCourse?.offeringCourse?.courseId}
                  </span>
                </div>
                <SegmentCategoryView onEdit={() => setShowSegmentSelectionModal(true)} editBtn={editBtn} />
                <TitleEditor editBtn={editBtn} setEditBtn={setEditBtn} />
                <AuthorProfileSection />
              </div>
              <div className="max-w-2xl mx-auto">
                <div
                  className={cn(
                    "relative hidden lg:block max-w-md mt-8 lg:mt-0",
                    "lg:absolute lg:top-[4.5rem] lg:right-10 lg:w-[290px]"
                  )}
                >
                  <VerticalStickyCard
                    editBtn={editBtn}
                    setEditBtn={setEditBtn}
                  />
                </div>
              </div>
            </div>
            {/* Course Header */}
          </div>
          <div className="max-w-5xl px-4 mx-auto">
            <div className="max-w-2xl pt-6 pr-4 mx-auto space-y-3 lg:mx-0">
              <EditCourseImageBar />
              <div className="block lg:hidden">
                <HorizontalStickyCard
                  editBtn={editBtn}
                  setEditBtn={setEditBtn}
                />
              </div>
              <div className="">
                <SkillsSection editBtn={editBtn} setEditBtn={setEditBtn} />
              </div>
              <div className="mb-4 ">
                <CourseDescriptionEditor editBtn={editBtn} setEditBtn={setEditBtn} />
              </div>
              <div className='mb-3'>
                <SelectThumbnails />
              </div>
            </div>
            <div className="w-full mt-6">
              <SubmitFormBar />
            </div>
          </div>
        </div>
      </div>
    </PageContentStyle>
  );
}

/**
 * 
 * <iframe src="https://player.vimeo.com/video/557134644?h=763119e73b&color=ffffff&title=0&byline=0&portrait=0&badge=0" width="640" height="268" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
<p><a href="https://vimeo.com/557134644">Pantser (Shielded) (2021, Jan Verdijk)</a> from <a href="https://vimeo.com/dpplr">DPPLR</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
 * 
 */
