import { useEffect, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from 'framer-motion';

import PageLoader from "components/loader/PageLoader";
import Pagination from "components/pagination/Pagination";

import ContactTable from "../commonComponents/ContactTable";

import { dropdownMenuItemsConst, getSearchQueryString, menuItemsConst, searchParamsInfo } from "pages/auth/crm/data";

import { deleteCrmContactDetail, getCrmContactList } from "redux/crm/crm.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
    setCrmContactListData,
    resetModifyCrmBulkContactListPayload,
    setModifyCrmBulkContactListPayload,
    resetModifyCrmBulkContactList,
    resetDestroyCrmBulkContactList,
    resetCrmContactList
} from "redux/crm/crm.slice";
import { crmContactCategoryEnum } from "redux/crm/crm.const";

const CrmContactsListView = () => {
    const { user } = useAppState((state) => state.user)
    const {
        crmContactList,
        modifyCrmBulkContactList,
        destroyCrmBulkContactList,
        addCrmContactDetail,
        modifyCrmContactDetail,
        destroyCrmContactDetail
    } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    const isAllContactsChecked = useMemo(() => {
        if (!!crmContactList?.data?.results?.length && !!modifyCrmBulkContactList?.payload?.selectedContactList?.length) {
            crmContactList?.data?.results?.every((item) => modifyCrmBulkContactList?.payload?.selectedContactList?.includes(item?.id))
            return true
        } else return false;
    }, [crmContactList?.data, modifyCrmBulkContactList?.payload?.selectedContactList])

    const getContactList = async () => {
        const queryParamPayload = await getSearchQueryString(searchQueryParams, crmContactCategoryEnum.CONTACT.value)

        dispatcher(getCrmContactList(user?.user?.userId, { ...queryParamPayload }))
    }

    useEffect(() => {
        return () => {
            dispatcher(resetCrmContactList())
        }
    }, [])

    useEffect(() => {
        getContactList()
    }, [searchQueryParams.get(searchParamsInfo.page.key)])

    useEffect(() => {
        if (modifyCrmBulkContactList?.data || destroyCrmBulkContactList?.data) {
            dispatcher(resetModifyCrmBulkContactList())
            dispatcher(resetModifyCrmBulkContactListPayload())
            dispatcher(resetDestroyCrmBulkContactList())
            getContactList()
        }
    }, [modifyCrmBulkContactList?.data, destroyCrmBulkContactList?.data])

    useEffect(() => {
        if (addCrmContactDetail?.data || modifyCrmContactDetail?.data) {
            if (addCrmContactDetail?.data) {
                dispatcher(setCrmContactListData({
                    ...crmContactList?.data,
                    results: [...crmContactList?.data?.results, addCrmContactDetail?.data],
                    records: crmContactList?.data?.records + 1,
                    totalRecords: crmContactList?.data?.totalRecords + 1
                }))
            } else if (modifyCrmContactDetail?.data) {
                dispatcher(setCrmContactListData({
                    ...crmContactList?.data,
                    results: crmContactList?.data?.results?.map((item) => (
                        (item?.id === modifyCrmContactDetail?.data?.id) ? modifyCrmContactDetail?.data : item
                    ))
                }))
            }
        }
    }, [addCrmContactDetail?.data, modifyCrmContactDetail?.data])

    const handelViewContact = useCallback((contact) => {
        searchQueryParams.set(searchParamsInfo.contact.key, contact?.id)

        navigate(`?${searchQueryParams.toString()}`)
    }, [searchQueryParams])

    const handleOptionSelect = async (option, contactId) => {
        if (destroyCrmContactDetail?.isLoading) return;

        switch (option?.value) {
            case menuItemsConst.VIEW.value:
                searchQueryParams.set(searchParamsInfo.contact.key, contactId)
                navigate(`?${searchQueryParams.toString()}`)
                break;

            case menuItemsConst.DELETE.value:
                if (!window.confirm("Are you sure?. You want to delete contact.")) return;
                dispatcher(deleteCrmContactDetail(contactId))
                break;

            default:
                break;
        }
    }

    const onHandleContactSelection = useCallback((option) => {
        switch (option?.value) {
            case dropdownMenuItemsConst.SELECT_ALL.value:
                dispatcher(setModifyCrmBulkContactListPayload({
                    ...modifyCrmBulkContactList?.payload,
                    selectedContactList: crmContactList?.data?.results?.map((contactItem) => contactItem?.id)
                }))
                break;
            case dropdownMenuItemsConst.NONE.value:
                dispatcher(setModifyCrmBulkContactListPayload({
                    ...modifyCrmBulkContactList?.payload,
                    selectedContactList: []
                }))
                break;
            default:
                break;
        }
    }, [modifyCrmBulkContactList?.payload])

    const onHandleSelectAllContacts = (event) => {
        const isChecked = event?.target?.checked
        if (isChecked) {
            dispatcher(setModifyCrmBulkContactListPayload({
                ...modifyCrmBulkContactList?.payload,
                category: crmContactCategoryEnum.CONTACT.value,
                selectedContactList: crmContactList?.data?.results?.map((contactItem) => contactItem?.id)
            }))
        } else {
            dispatcher(setModifyCrmBulkContactListPayload({
                ...modifyCrmBulkContactList?.payload,
                selectedContactList: []
            }))
        }
    }

    const onHandleSelectContactItem = useCallback((event, contactItem) => {
        event.stopPropagation();

        if (!!modifyCrmBulkContactList?.payload?.selectedContactList?.length) {
            const isContactAlreadySelected = modifyCrmBulkContactList?.payload?.selectedContactList?.filter((item) => (item === contactItem?.id))
            if (!!isContactAlreadySelected?.length) {
                dispatcher(setModifyCrmBulkContactListPayload({
                    ...modifyCrmBulkContactList?.payload,
                    status: {},
                    category: crmContactCategoryEnum.CONTACT.value,
                    selectedContactList: modifyCrmBulkContactList?.payload?.selectedContactList?.filter((item) => item !== contactItem?.id)
                }))
            } else {
                dispatcher(setModifyCrmBulkContactListPayload({
                    ...modifyCrmBulkContactList?.payload,
                    status: {},
                    category: crmContactCategoryEnum.CONTACT.value,
                    selectedContactList: [...modifyCrmBulkContactList?.payload?.selectedContactList, contactItem?.id]
                }))
            }
        } else {
            dispatcher(setModifyCrmBulkContactListPayload({
                ...modifyCrmBulkContactList?.payload,
                status: { label: contactItem?.status?.name, value: contactItem?.status?.key },
                category: crmContactCategoryEnum.CONTACT.value,
                selectedContactList: [...modifyCrmBulkContactList?.payload?.selectedContactList, contactItem?.id]
            }))
        }
    }, [modifyCrmBulkContactList?.payload])

    const onHandlePageChange = async (page) => {
        dispatcher(resetModifyCrmBulkContactList())
        dispatcher(resetModifyCrmBulkContactListPayload())
        searchQueryParams.set(searchParamsInfo.page.key, page)
        const queryParamPayload = await getSearchQueryString(searchQueryParams)
        Object.entries(queryParamPayload).forEach(([key, value]) => {
            if (((key !== searchParamsInfo.records.key) && (key !== searchParamsInfo.category.key)) && value) {
                searchQueryParams.set(key, value);
            }
        })
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`)
    }

    if (crmContactList?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (crmContactList?.message) {
        return (
            <div className={"flex items-center justify-center"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base"}>
                    {crmContactList?.message}
                </span>
            </div>
        )
    }

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className="flex flex-col w-full font-bodyPri text-text-900 rounded-lg overflow-hidden overflow-x-auto shadow text-sm md:text-base space-y-5">
                    <ContactTable
                        isLoading={crmContactList?.isLoading}
                        rows={crmContactList?.data?.results}
                        isAllContactsChecked={isAllContactsChecked}
                        dropdownMenuItemsConst={Object.values(dropdownMenuItemsConst)}
                        menuItemsConst={Object.values(menuItemsConst)}
                        selectedContactList={modifyCrmBulkContactList?.payload?.selectedContactList}
                        onHandleSelectAllContacts={onHandleSelectAllContacts}
                        handleOptionSelect={handleOptionSelect}
                        handelViewContact={handelViewContact}
                        onHandleSelectContactItem={onHandleSelectContactItem}
                        onHandleContactSelection={onHandleContactSelection}
                    />
                    {(!crmContactList?.isLoading && (crmContactList?.data?.totalPages > 1)) &&
                        <div className={'w-full flex items-center justify-center'}>
                            <Pagination
                                page={crmContactList?.data?.page}
                                totalPages={crmContactList?.data?.totalPages}
                                onChangePage={onHandlePageChange}
                            />
                        </div>
                    }
                </div>
            </motion.div>
        </AnimatePresence>
    )
}

export default CrmContactsListView;