import { useCallback, useMemo } from "react";

import { MdLocalPhone, MdOutlineEmail } from "react-icons/md";

import FloatingLabelSelect from "components/floating/floatingLabelSelect";

import { contactLabelIcons } from "components/modals/crmModals/data";
import ContactSocials from "./contactSocials";

import { cn } from "utils/cn.utils";

const ContactActions = ({ contact }) => {

    const onHandleSelectPhone = useCallback((cellNumber) => {
        window.open(`tel:+${cellNumber.isd} ${cellNumber.phone}`, "_self")
    }, [contact?.contact_phones])

    const onHandleSelectEmail = useCallback((email) => {
        window.open(`mailto:${email?.email}`, "_self")
    }, [contact?.contact_emails])

    const phoneOptions = useMemo(() => {
        if (!!contact?.contact_phones?.length) {
            const phoneOptionList = contact?.contact_phones.map((cellNumber) => ({
                label: `${cellNumber?.isd && "+" + cellNumber?.isd}-${cellNumber?.phone}`,
                value: cellNumber,
            }))

            return phoneOptionList;
        } else return []
    }, [contact?.contact_phones])

    const emailOptions = useMemo(() => {
        if (!!contact?.contact_emails?.length) {
            const emailOptionList = contact?.contact_emails.map((email) => ({
                label: email?.email,
                value: email,
            }))
            return emailOptionList;
        } else return []
    }, [contact?.contact_emails])

    return (
        <div className="flex flex-col sm:flex-row items-center gap-5">
            <div className="flex items-center gap-4">
                {!!phoneOptions?.length &&
                    <FloatingLabelSelect
                        showLabel={false}
                        showIcon={false}
                        customBtnStyle={"bg-back-ground-lightBlue shadow hover:bg-white text-primary-main flex item-center justify-center border-back-ground-lightBlue h-12 w-12 border rounded-full focus-within:border-primary-main"}
                        dropdownWrapperClassName={"w-48 !top-14 !right-0"}
                        value={<MdLocalPhone size={20} className="w-12" />}
                        options={phoneOptions}
                        onSelect={onHandleSelectPhone}
                        OptionChild={({ option }) => (
                            <div
                                className="flex items-center gap-3 px-3 py-2 hover:bg-gray-100"
                                onClick={() => onHandleSelectPhone(option.value)}
                            >
                                {(() => {
                                    const Icon = contactLabelIcons[option.value.label].icon;
                                    return (
                                        <div>
                                            <Icon className="text-text-700 text-lg" />{" "}
                                        </div>
                                    );
                                })()}
                                <p className={"font-bodyPri font-normal hover:underline hover:text-primary-dark cursor-pointer"}>
                                    {option.label}
                                </p>
                            </div>
                        )}
                    />

                }
                {!!emailOptions?.length &&
                    <FloatingLabelSelect
                        showLabel={false}
                        showIcon={false}
                        customBtnStyle={cn(
                            "flex item-center justify-center border border-back-ground-lightBlue h-12 w-12 rounded-full",
                            "bg-back-ground-lightBlue shadow hover:bg-white text-primary-main focus-within:border-primary-main"
                        )}
                        dropdownWrapperClassName={"w-fit !top-14 !-left-14 md:!left-0"}
                        value={<MdOutlineEmail size={20} className="w-12" />}
                        options={emailOptions}
                        onSelect={onHandleSelectEmail}
                        OptionChild={({ option }) => (
                            <div
                                className="flex items-center gap-3 px-3 py-2 hover:bg-gray-100"
                                onClick={() => onHandleSelectEmail(option.value)}
                            >
                                {(() => {
                                    const Icon = contactLabelIcons[option.value.label].icon;
                                    return (
                                        <div>
                                            <Icon className="text-text-700 text-lg" />{" "}
                                        </div>
                                    );
                                })()}
                                <p className={"font-bodyPri font-normal hover:underline hover:text-primary-dark cursor-pointer"}>
                                    {option.label}
                                </p>
                            </div>
                        )}
                    />
                }
            </div>
            <div className="h-[1px] w-full bg-back-ground-darkLight rounded-full"></div>
            <ContactSocials contact={contact} />
        </div>
    );
};

export default ContactActions;