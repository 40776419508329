export const url = {
    CREATE_LINK_IN_BIO: "linktrees/linktrees",
    UPDATE_LINK: "/linktrees/links/{linkId}",
    DELETE_LINK: "/linktrees/links/{linkId}",
    ADD_LINK: "linktrees/links",
    REORDER_LINK: "linktrees/links/{id}/order",
    GET_LINKTREE: "linktrees/linktrees/{linkTreeId}/users/{userId}",
    DELETE_LINKTREE: "/linktrees/linktrees/{linkTreeId}",
    UPDATE_LINKTREE: "/linktrees/linktrees/{linkTreeId}",
    GET_LINKS: "/linktrees/linktrees/users/{userId}",
}