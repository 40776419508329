import { cn } from "utils/cn.utils";
import ComponentLoader from "components/loader/ComponentLoader";

import { MdAdd } from 'react-icons/md'

const AddBorderButton = ({ isLoading = false, btnTitle = "", onHandleAdd }) => {
    return (
        <div className={cn(
            "w-full px-3 py-3 my-2 flex items-center justify-center border border-dashed border-text-500 rounded-lg",
            "font-bodyPri font-normal cursor-pointer hover:bg-back-ground-lightBlue"
        )}
            onClick={onHandleAdd}
        >
            {isLoading &&
                <ComponentLoader isLoading={isLoading} className={"h-8"} />
            }
            {!isLoading &&
                <>
                    <MdAdd className={"text-xl text-text-900"} />
                    <span className={"text-xl text-text-900"}>
                        {btnTitle}
                    </span>
                </>
            }
        </div>
    )
}

export default AddBorderButton