import { MdOutlineTouchApp } from "react-icons/md";
import { MdTouchApp } from "react-icons/md";

import SingleChoiceView from './singleChoice';
import MultipleChoiceView from './multipleChoice';
import ShortAnswerView from './shortAnswer';
import ParagraphView from './paragraph';

import { lmsQuestionTypeEnum } from "redux/edulyteLms/lmsQuestion/lmsQuestion.const";
import { lmsResourceTypeEnum, resourceFileTypeEnum } from "redux/edulyteLms/lmsResource/lmsResource.const";
import ToolTipView from "components/tooltipView";


const QuestionView = ({ question, quiz_response }) => {

  const question_response = quiz_response?.quiz_question_responses?.find(question_response => question_response?.question === question?.id)

  let has_question_resource = !!(question?.question_resources?.filter(question_resource => (
    lmsResourceTypeEnum.FILE.value === question_resource?.resource?.type &&
    [resourceFileTypeEnum.IMAGE.value, resourceFileTypeEnum.VIDEO.value].includes(question_resource?.resource?.resource?.type)
  ))?.length)

  const instructionText = new DOMParser()?.parseFromString(`<div>${question?.instruction || ""}</div>`, "text/html")?.querySelector("div")?.textContent
  const descriptionText = new DOMParser()?.parseFromString(`<div>${question?.description || ""}</div>`, "text/html")?.querySelector("div")?.textContent

  return (
    <div className={"w-full flex flex-col gap-3"}>

      {/* heading */}
      <div className={"space-y-1"}>
        <div className={"flex items-center justify-between gap-5"}>
          <span className={"grow font-bodyPri font-normal text-base text-primary-dark"}>
            {`Question ${question?.questionNo}:`}
          </span>
          <div className={"flex items-center justify-end gap-3"}>
            {(() => {
              if (!question?.question_setting?.correct_marks) return null
              return (
                <div className={"flex items-center justify-center gap-1 font-bodyPri font-normal text-text-800"}>
                  <span>{"Score:"}</span>
                  <span>
                    {question_response?.obtain_marks && <span>{question_response?.obtain_marks} / </span>}
                    <span>{question_response?.total_marks || question?.question_setting?.correct_marks}</span>
                  </span>
                </div>
              )
            })()}
            <ToolTipView content={question_response ? "Answered" : "Not Attempted"}>
              {question_response
                ? <span className={"p-2 rounded-full hover:bg-primary-light cursor-pointer"}>
                  <MdTouchApp className={"text-lg text-primary-dark"} />
                </span>
                : <span className={"p-2 rounded-full hover:bg-back-ground-darkLight cursor-pointer"}>
                  <MdOutlineTouchApp className={"text-lg text-text-700"} />
                </span>
              }
            </ToolTipView>
          </div>
        </div>
        <h4 className={"font-bodyPri font-medium text-lg text-text-900"}>{question?.title}</h4>
        {!!instructionText && (
          <div className={"block mt-3"} dangerouslySetInnerHTML={{ __html: question?.instruction }}></div>
        )}
        {!!descriptionText && (
          <div className={"block mt-3"} dangerouslySetInnerHTML={{ __html: question?.description }}></div>
        )}
        {has_question_resource && (
          <div className={"flex flex-wrap gap-2"}>
            {question?.question_resources?.filter(question_resource =>
              lmsResourceTypeEnum.FILE.value === question_resource?.resource?.type &&
              [resourceFileTypeEnum.IMAGE.value].includes(question_resource?.resource?.resource?.type)
            )?.map((question_resource, index) => (
              <div key={index}
                className={"w-72 aspect-video border-2 border-text-40 overflow-hidden rounded-lg"}
              >
                <img
                  src={question_resource?.resource?.resource?.file?.file_url}
                  alt={question_resource?.resource?.title}
                  className={"w-full h-full object-cover"}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {/* option or answer input */}
      {question?.type === lmsQuestionTypeEnum.SINGLE_CHOICE.value && (
        <SingleChoiceView question={question} quiz_response={quiz_response} />
      )}
      {question?.type === lmsQuestionTypeEnum.MULTIPLE_CHOICE.value && (
        <MultipleChoiceView question={question} quiz_response={quiz_response} />
      )}
      {question?.type === lmsQuestionTypeEnum.SHORT_ANSWER.value && (
        <ShortAnswerView question={question} quiz_response={quiz_response} />
      )}
      {question?.type === lmsQuestionTypeEnum.PARAGRAPH.value && (
        <ParagraphView question={question} quiz_response={quiz_response} />
      )}

    </div>
  )
}

export default QuestionView