import {
    DashboardCustomize,
    EventNoteTwoTone,
    AccountBalanceWallet,
    Message,
    People,
    LibraryBooks,
    LocalOffer,
    School,
    Subscriptions,
    Settings,
    Support,
    Logout,
} from '@mui/icons-material';

import { userRoles } from 'redux/auth/auth.const';

export const HamburgerMenuItems = [
    // menu bar for both role
    {
        icon: DashboardCustomize,
        name: 'Dashboard',
        value: 'dashboard',
        path: '/dashboard',
        pathType: 'to',
        roles: [userRoles?.STUDENT?.value, userRoles.TUTOR.value],
        subMenu: []
    },
    {
        icon: EventNoteTwoTone,
        name: 'Calendar',
        value: 'calendar',
        path: '/calendar',
        pathType: 'to',
        roles: [userRoles?.STUDENT?.value, userRoles.TUTOR.value],
        subMenu: []
    },
    {
        icon: Message,
        name: 'Live Chat',
        value: 'live_chat',
        path: '/messages',
        pathType: 'to',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
        subMenu: []
    },

    // menu bar for student role
    {
        icon: People,
        name: 'My Tutors',
        path: '/my-tutors',
        pathType: 'to',
        roles: [userRoles.STUDENT.value]
    },
    {
        icon: LibraryBooks,
        name: 'My Classes',
        path: '/my-classes',
        pathType: 'to',
        roles: [userRoles.STUDENT.value]
    },
    {
        icon: LibraryBooks,
        name: 'My Courses',
        path: '/my-courses',
        pathType: 'to',
        roles: [userRoles.STUDENT.value]
    },
    {
        icon: LibraryBooks,
        name: 'My Quizzes',
        path: '/my-quizzes',
        pathType: 'to',
        roles: [userRoles.STUDENT.value]
    },
    {
        icon: School,
        name: 'Apply to Teach',
        path: '/teach-with-us',
        pathType: 'to',
        roles: [userRoles.STUDENT.value]
    },

    // menu bar for tutor role
    {
        icon: School,
        name: 'List on Marketplace',
        path: '/tutor-onboarding',
        pathType: 'to',
        roles: [userRoles.TUTOR.value]
    },
    {
        icon: People,
        name: 'CRM',
        value: 'crm',
        path: '/crm',
        pathType: 'to',
        roles: [userRoles.TUTOR.value],
        subMenu: [
            {
                icon: "",
                name: 'Leads',
                value: 'leads',
                path: '/crm/leads',
                pathType: 'to',
                roles: [userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Contacts',
                value: 'contacts',
                path: '/crm/contacts',
                pathType: 'to',
                roles: [userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Clients',
                value: 'clients',
                path: '/crm/clients',
                pathType: 'to',
                roles: [userRoles.TUTOR.value],
            },
        ]
    },
    {
        icon: LocalOffer,
        name: 'Products',
        value: 'products',
        path: '/products',
        pathType: 'to',
        roles: [userRoles.TUTOR.value],
        subMenu: [
            {
                icon: "",
                name: 'Courses',
                value: 'courses',
                path: '/products/courses',
                pathType: 'to',
                roles: [userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Group Classes',
                value: 'live-classes',
                path: '/products/live-classes',
                pathType: 'to',
                roles: [userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Events',
                value: 'evently',
                path: '/products/evently',
                pathType: 'to',
                roles: [userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Appointment pages',
                value: 'appointments',
                path: '/products/appointments',
                pathType: 'to',
                roles: [userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Quizzes',
                value: 'quizzes',
                path: '/products/quizzes',
                pathType: 'to',
                roles: [userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Downloads',
                value: 'downloads',
                path: '/products/downloads',
                pathType: 'to',
                roles: [userRoles.TUTOR.value],
            },
        ]
    },

    // menu bar for tutor role and student role
    {
        icon: AccountBalanceWallet,
        name: 'Finance',
        value: 'finance',
        path: '/finance',
        pathType: 'to',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
        subMenu: [
            {
                icon: "",
                name: 'Wallet',
                value: 'wallet',
                path: '/finance/wallet',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Booking',
                value: 'booking',
                path: '/finance/bookings',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Orders',
                value: 'orders',
                path: '/finance/orders',
                pathType: 'to',
                roles: [userRoles.STUDENT.value],
            },
            {
                icon: "",
                name: 'Subscriptions',
                value: 'subscriptions',
                path: '/finance/subscriptions',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
        ]
    },
    {
        icon: Subscriptions,
        name: 'Resources',
        value: 'resources',
        path: '/resources',
        pathType: 'to',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
        subMenu: [
            {
                icon: "",
                name: 'FileBox',
                value: 'fileBox',
                path: '/resources/fileBox',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Questions',
                value: 'questions',
                path: '/resources/questions',
                pathType: 'to',
                roles: [userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Tools',
                value: 'tools',
                path: '/resources/tools',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'English Teaching',
                value: 'english-teaching',
                path: 'https://www.edulyte.com/english-teaching-resources/',
                pathType: 'href',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Partner Offers',
                value: 'partner-offers',
                path: '/resources/partner-offers',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Knowledge Base',
                value: 'knowledge-base',
                path: 'https://support.edulyte.com/portal/en/kb/tutor-helpdesk',
                pathType: 'href',
                roles: [userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Knowledge Base',
                value: 'knowledge-base',
                path: 'https://support.edulyte.com/portal/en/kb/student-helpdesk',
                pathType: 'href',
                roles: [userRoles.STUDENT.value],
            },
        ]
    },
    {
        icon: School,
        name: 'Marketing',
        value: 'marketing',
        path: '/marketing',
        pathType: 'to',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
        subMenu: [
            {
                icon: "",
                name: 'My Links',
                value: 'my-links',
                path: '/marketing/my-links',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Referral',
                value: 'referral',
                path: '/marketing/referral',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Marketing Services',
                value: 'marketing-services',
                path: 'https://www.edulyte.com/exclusive-marketing-solutions-for-educators',
                pathType: 'href',
                roles: [userRoles.TUTOR.value],
            },
        ]
    },
    {
        icon: Settings,
        name: 'Settings',
        value: 'settings',
        path: '/settings',
        pathType: 'to',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
        subMenu: [
            {
                icon: "",
                name: 'Profile',
                value: 'profile',
                path: '/settings/profile',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Preferences',
                value: 'preferences',
                path: '/settings/preferences/',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'General Settings',
                value: 'general-settings',
                path: '/settings/general/',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
            {
                icon: "",
                name: 'Integrations',
                value: 'integrations',
                path: '/settings/integrations/',
                pathType: 'to',
                roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
            },
        ]
    },
    {
        icon: Support,
        name: 'Support',
        value: 'support',
        path: '/support',
        pathType: 'to',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: Logout,
        name: 'Log Out',
        path: '#',
        pathType: 'to',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },

]